import ExcelJs from "exceljs";
import { MessageError, openModal } from '../../../../_layouts/components/modals/Modals';
import { GET_SCR_LECTURER_SECTION, GET_SECTION } from '../../apis/node-target-data/AcademicApi';
import { BlobImg } from "../../../../_layouts/components/images/Logos";

const DownloadScoreLetter = async (section_id, setStatus) => {
    try {
        setStatus(true);
        const param = { section_id: section_id };
        const result = await GET_SECTION(param);

        if (result.data && Object.values(result.data).length > 0) {
            const result_score = await GET_SCR_LECTURER_SECTION(param);
            if (result_score.data && Object.values(result_score.data).length > 0) {
                ScoreDownload(result_score.data, result.data[0]);
            } else {
                openModal({ message: <MessageError message={result_score.message} /> })
            }
        } else {
            openModal({ message: <MessageError message={result.message} /> })
        }

        setStatus(false);
    } catch (error) {
        console.log(error);
        openModal({message:<MessageError message={"Failed to download. Please try again later."} />})
        setStatus(false);
    }
}

const ScoreDownload = (score, section) => {
    const wb = new ExcelJs.Workbook();
    wb.creator = "UMS-SGU";
    wb.lastModifiedBy = "UMS-SGU-System";
    wb.created = new Date();
    wb.modified = new Date();
    wb.lastPrinted = new Date();

    //body here
    SheetScore(wb, score, section);
    //end body here

    const writeFile = (fileName, content) => {
        const link = document.createElement("a");
        const blob = new Blob([content], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        });
        link.download = fileName;
        link.href = URL.createObjectURL(blob);
        link.click();
    };

    wb.xlsx.writeBuffer().then((buffer) => {
        writeFile(
            "Report Score-" + section.section_id,
            buffer
        );
    });
};

const RowHeader = (rowHeader, i, value) => {
    rowHeader.getCell(i).value = value;
    rowHeader.getCell(i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
    };
    rowHeader.getCell(i).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "191970" },
    };
    rowHeader.getCell(i).alignment = {
        vertical: "middle",
        horizontal: "center",
    };
    rowHeader.getCell(i).font = {
        bold: true,
        size: 11,
        color: { argb: "FFFFFF" },
    };
}

const SheetScore = (wb, score, section) => {
    const course = section.course;
    const course_title = course.coursetitle.replace(/[^a-zA-Z0-9 ]/g, '');
    const ws = wb.addWorksheet("Score " + section.section_id);

    const HeaderXLS = BlobImg();
    const imageId1 = wb.addImage({
        fileName: "logo-1",
        extension: "png",
        base64: HeaderXLS.imglogo,
    });
    const imageId2 = wb.addImage({
        fileName: "logo-2",
        extension: "png",
        base64: HeaderXLS.imglogoName,
    });

    ws.addImage(imageId1, "A1:B2");
    ws.addImage(imageId2, "E2:F2");

    let rowNumber = 4;

    ws.getRow(rowNumber).getCell("A").value = "Assessment Report for Section " + section.section_id;
    ws.getRow(rowNumber).getCell("A").font = {
        bold: true,
        size: 16,
    };
    ws.getRow(rowNumber).getCell("A").border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
    };
    ws.getRow(rowNumber).getCell("A").alignment = { horizontal: "center" };
    ws.mergeCells("A"+rowNumber+":F"+rowNumber);
    rowNumber = rowNumber + 1;
    
    ws.getRow(rowNumber).getCell("A").value = "Academic Term: " + (section.atp_term && (section.atp_term.name || "-")) ;
    ws.mergeCells("A"+rowNumber+":C"+rowNumber);
    rowNumber = rowNumber + 1;    

    ws.getRow(rowNumber).getCell("A").value = "Course Title: " + course_title;
    ws.mergeCells("A"+rowNumber+":C"+rowNumber);
    rowNumber = rowNumber + 1;

    ws.getRow(rowNumber).getCell("A").value = "Course Code: " + course.code;
    ws.mergeCells("A"+rowNumber+":C"+rowNumber);
    rowNumber = rowNumber + 1;

    ws.getRow(rowNumber).getCell("A").value = "Credit: " + course.credit + " SKS";
    ws.mergeCells("A"+rowNumber+":C"+rowNumber);
    rowNumber = rowNumber + 1;

    const totalLecturer = Object.values(section.lecturer).length;
    let lecturerName = "";
    if(totalLecturer > 0){
        section.lecturer.map(item => {
            lecturerName += (item.emp && item.emp.fullname || "-") + "; ";
        })
    }

    ws.getRow(rowNumber).getCell("A").value = "Lecturer: " + lecturerName;
    ws.mergeCells("A"+rowNumber+":C"+rowNumber);
    rowNumber = rowNumber + 2;

    const rowHeader = ws.getRow(rowNumber);
    RowHeader(rowHeader, 1, "No")
    RowHeader(rowHeader, 2, "Student ID")
    RowHeader(rowHeader, 3, "Student Name")
    RowHeader(rowHeader, 4, "Student Email")
    RowHeader(rowHeader, 5, "Department")
    RowHeader(rowHeader, 6, "Score")
    RowHeader(rowHeader, 7, "Grade")
    RowHeader(rowHeader, 8, "Remark")
    RowHeader(rowHeader, 9, "Final Result")

    rowNumber = rowNumber + 1;
    var num = 1;
    score.student.map((v) => {
        const row = ws.getRow(rowNumber);
        row.getCell(1).value = num++;
        row.getCell(2).value = v.studentid;
        row.getCell(3).value = v.studentdata ? (v.studentdata.fullname ? v.studentdata.fullname : "-") : "-";
        row.getCell(4).value = v.studentdata ? (v.studentdata.sgumail ? v.studentdata.sgumail : "-") : "-";
        row.getCell(5).value = v.clu ? (v.clu.name ? v.clu.name : "-") : "-";
        row.getCell(6).value = v.score_letter;
        row.getCell(7).value = v.grade_fin;
        row.getCell(8).value = v.remark;
        row.getCell(9).value = v.course_status;
        rowNumber++;
    });

    ws.columns.forEach((column, index) => {
        if (index === 0) {
            column.width = 5;
        }else if(index === 1){
            column.width = 15;
        } else {
            let maxColumnLength = 0;
            column.eachCell({ includeEmpty: true }, cell => {
                let cellLength = cell.value ? cell.value.toString().length : 0;
                if (cellLength > maxColumnLength) {
                    maxColumnLength = cellLength;
                }
            });
            column.width = maxColumnLength < 10 ? 10 : maxColumnLength;
        }
    });

};

export { DownloadScoreLetter }