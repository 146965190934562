import React from "react";
import { ListMenu } from "../menu-widgets";
import WActivityLogin from "../management-users/components/WActivityLogin";
import WTreeList from "../menu-widgets/components/WTreeList";
import { useProvider } from "../../../_layouts/UMSLayoutProvider";
import PackageJson from "../../../../package.json"
import { SGUCardFlush } from "../../../_layouts/components/cards/CardUI";
import DecodePassword from "./DecodePassword";

export function DevelopmentMode() {
  const { env, account } = useProvider();

  return (
    <div id="development-mode">
      <div className="row mb-5">
        <div className="col-lg-6">
          <SGUCardFlush>
            <SGUCardFlush.Header>
              <span className="card-label fw-bold fs-3 mb-1">
                UMS Information
              </span>
            </SGUCardFlush.Header>
            <SGUCardFlush.Body>
              <div className="mb-5 d-flex justify-content-between">
                <span className="text-muted">App Version</span>
                <span className="fw-bold">{PackageJson.version}</span>
              </div>
              <div className="mb-5 d-flex justify-content-between">
                <span className="text-muted">Consumer Key</span>
                <span className="fw-bold">{account.typeCK}</span>
              </div>
              <div className="mb-5 d-flex justify-content-between">
                <span className="text-muted">App Env</span>
                <span className="fw-bold">{env.appenv}</span>
              </div>
              <div className="mb-5 d-flex justify-content-between">
                <span className="text-muted">Base URI</span>
                <span className="fw-bold">{env.baseuri}</span>
              </div>
              <div className="mb-5 d-flex justify-content-between">
                <span className="text-muted">Backend URI</span>
                <span className="fw-bold">{env.backenduri}</span>
              </div>
            </SGUCardFlush.Body>
          </SGUCardFlush>
          <DecodePassword />
        </div>
        <div className="col-lg-6">
          <WActivityLogin />
        </div>
      </div>
      <div className="row">
        <div className={"col-lg-6"}>
          <ListMenu />
        </div>
        <div className="col-lg-6">
          <WTreeList />
        </div>
      </div>
    </div>
  );
}
