import { CandidatePreview } from "../../components/TCUI";
import { PMatriculations } from "./details";

export default function PreviewProposal({
  data,
  candidate,
  univ,
  univprodi,
  target_dept,
  total_credit,
  doc_date
}) {
  
  return (
    <div style={{ width: "530px", border: "0px solid red" }}>
      <CandidatePreview
        candidate={candidate}
        doc_date={doc_date}
        univ={univ}
        univprodi={univprodi}
        target_dept={target_dept}
      />
      <PMatriculations data={data} t_credit={total_credit.totalCredit} t_credit_ext={total_credit.totalCreditExt} />
    </div>
  );
}
