import React, { useEffect, useState } from "react";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  CREATE_INSTITUTIONHIST,
  DELETE_INSTITUTIONHIST,
} from "../../../../apis/node-target-data/IdentityApi";
import { SGUCard } from "../../../../../../_layouts/components/cards/CardUI";
import {
  LoadingSkeleton,
  SpinnerLoad,
} from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../../_layouts/components/alerts/AlertsUI";
import {
  ButtonIcon,
  ButtonPrimary,
  ButtonTersier,
} from "../../../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { MessageSuccess } from "../../../../admissions/components/Modals";
import {
  MessageError,
  openModal,
} from "../../../../../../_layouts/components/modals/Modals";
import { UnivLookUp } from "../../../../components/forms/SelectBox";
import { RequiredMsg } from "../../../../../../_layouts/_core/ErrorMessage";
import { RemoveEmptyValue } from "../../../../../../_layouts/_helpers/Global";
import { handleKeyDown } from "../../../../components/forms/InputText";

export default function MTXInstitutionHist({ institute, setInstitute, setFormMatric, stdBio, ReloadInstitute }) {
  const [formAdd, setFormAdd] = useState(false);
  const [univ, setUniv] = useState(0);
  const [yeargraduation, setYearGraduation] = useState(moment().toDate());
  const [lastsemester, setLastsemester] = useState(0);
  const param_ins = { reffid: stdBio.reff_ins_id };

  const HandlerRemove = async (institutionhist_id) => {
    if (confirm("Are you sure want to remove?") === true) {
      setInstitute({ loading: true, message: "", data: [] });
      const param = { institutionhist_id: institutionhist_id };
      const result = await DELETE_INSTITUTIONHIST(param);

      if (result) {
        openModal({
          message: <MessageSuccess message={"Successfully removed"} />,
        });
        ReloadInstitute(param_ins);
      } else {
        setInstitute({ loading: false, message: result.message, data: [] });
      }
    }
  };

  const HandlerOpenForm = () => {
    setFormAdd(true);
  };

  const [submit, setSubmit] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const SubmitData = async () => {
    const post_param = {
      reff_id: stdBio.reff_ins_id,
      university_id: univ,
      yeargraduation: moment(yeargraduation).format("YYYY"),
      lastsemester,
      institution_type: "identity.ins.type.university",
    };
    if (
      (post_param.reff_id &&
        post_param.yeargraduation &&
        post_param.university_id,
        post_param.lastsemester)
    ) {
      setSubmit({ loading: true, message: "", data: [] });
      const param = RemoveEmptyValue(post_param);
      const result = await CREATE_INSTITUTIONHIST(param);
      setSubmit(result);
      ReloadInstitute(param_ins);
      setFormAdd(false);
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  };

  return (
    <SGUCard>
      <SGUCard.Body className={"pt-8 pb-2"}>
        <div className="table-responsive mb-5">
          <table className="table table-row-dashed border-gray-300 align-middle gy-2">
            <thead>
              <tr className="fw-bold text-uppercase">
                <th>Institution</th>
                <th width="15%">Last Semester</th>
                <th width="20%">Year Graduate</th>
                <th width="15%">Action</th>
              </tr>
            </thead>
            <tbody>
              {institute.loading ? (
                <tr>
                  <td colSpan={4}>
                    <LoadingSkeleton />
                  </td>
                </tr>
              ) : institute.message ? (
                <tr>
                  <td colSpan={4}>
                    <AlertNotif messages={institute.message} />
                  </td>
                </tr>
              ) : (
                Object.values(institute.data).length > 0 ? (
                  institute.data.map((v, index) => (
                    <tr key={index}>
                      <td>
                        {v.university
                          ? v.university.name
                            ? v.university.name
                            : ""
                          : ""}
                      </td>
                      <td>Semester {v.lastsemester}</td>
                      <td>{v.yeargraduation}</td>
                      <td>
                        <ButtonIcon
                          type="button"
                          className="btn-sm"
                          title="Remove"
                          onClick={() => HandlerRemove(v.id)}
                        >
                          <KTIcon iconName="trash" className="fs-2 text-danger" />
                        </ButtonIcon>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <AlertNotif messages={"Please provide the institution information first."} title={"Empty institutions"} />
                    </td>
                  </tr>
                )
              )}
              {formAdd && (
                <tr>
                  <td>
                    <UnivLookUp data={univ} setData={setUniv} required />
                  </td>

                  <td>
                    <input
                      type="text"
                      onKeyDown={handleKeyDown}
                      className="form-control form-control-sm"
                      name="last_semester"
                      defaultValue={lastsemester}
                      onChange={(e) =>
                        setLastsemester(parseInt(e.target.value))
                      }
                    />
                  </td>
                  <td>
                    <DatePicker
                      className="form-control form-control-sm"
                      isClearable
                      showYearPicker
                      selected={yeargraduation}
                      onChange={(date) => setYearGraduation(date)}
                      dateFormat="yyyy"
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </td>
                  <td>
                    <ButtonIcon
                      type="button"
                      className="btn-sm btn-primary"
                      title="Save"
                      onClick={() => SubmitData()}
                      disabled={submit.loading}
                    >
                      {submit.loading ? (
                        <SpinnerLoad />
                      ) : (
                        <KTIcon iconName="check" className="fs-2" />
                      )}
                    </ButtonIcon>
                    <ButtonIcon
                      type="button"
                      className="btn-sm btn-light-danger ms-2"
                      title="Remove"
                      onClick={() => setFormAdd(false)}
                      disabled={submit.loading}
                    >
                      <KTIcon iconName="cross" className="fs-2" />
                    </ButtonIcon>
                  </td>
                </tr>
              )}
            </tbody>
            {Object.values(institute.data).length === 0 && (
              !formAdd && (
                <tfoot>
                  <tr>
                    <td colSpan={4} className="text-center">
                      {!institute.loading && (
                        <ButtonTersier
                          type="button"
                          className="btn-sm"
                          onClick={() => HandlerOpenForm()}
                        >
                          <KTIcon iconName="plus" className="fs-2" />
                          Add New Institution
                        </ButtonTersier>
                      )}
                    </td>
                  </tr>
                </tfoot>
              )
            )}
          </table>
        </div>
        {!institute.loading && (
          <div className="text-end mb-5">
            {Object.values(institute.data).length > 0 && (
              <ButtonPrimary
                className="btn-lg fw-bold"
                type="button"
                onClick={()=>setFormMatric(true)}
              >
                Next to Matriculation Submission
                <KTIcon iconName="arrow-right" className="fs-2 ms-2" />
              </ButtonPrimary>
            )}
          </div>
        )}
      </SGUCard.Body>
    </SGUCard>
  );
}
