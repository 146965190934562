import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ButtonIcon } from "../../../../_layouts/components/buttons/ButtonUI";
import FormCourse from "./components/FormCourse";
import {
  NoRecordFound,
} from "../../../../_layouts/components/loadbars/LoadingBars";
import CourseActivityType from "./components/CourseActivityType";
import CourseVersion from "./components/CourseVersion";
import { Error404 } from "../../errors/components/Error404";

export function DetailCourse() {
  const params = useParams();
  const token = params.course;
  try {
    const decodeToken = atob(token);
    const data = JSON.parse(decodeToken);
    return <DetailPage data={data} />;
  } catch (error) {
    return <Error404 />;
  }
}

const DetailPage = ({ data }) => {
  const navigate = useNavigate();
  const courses = data.course;
  const param = data.param;

  const Back2Page = () => {
    navigate("/course-managements/master-course", {
      state: { param: param },
    });
  };

  useEffect(() => {
    //BACK HANDLER
    const handleBackButton = (event) => {
      event.preventDefault();
      Back2Page();
    };

    window.history.pushState(null, null, window.location.href);

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
    //END BACK
  }, [navigate]);

  const navigations = [
    { id: 1, name: "Information", goto: "#course-info" },
    { id: 2, name: "Activity", goto: "#course-activity" },
    { id: 3, name: "Version", goto: "#course-version" },
  ];

  return (
    <div className="row">
      <div className="col-xl-2">
        <div className="border rounded bg-white">
          <div className="bg-dark rounded-top d-flex flex-stack ps-4 pe-5 py-5">
            <div className="d-flex align-items-center">
              <ButtonIcon
                className="btn-active-color-primary btn-sm"
                type="button"
                onClick={() => Back2Page()}
              >
                <i className="ki-outline ki-left fs-2"></i>
              </ButtonIcon>
              <span
                className="fs-lg text-white fw-bold cursor-pointer"
                onClick={() => Back2Page()}
              >
                Back
              </span>
            </div>
          </div>
          <div className="hover-scroll-y scroll-ps m-2">
            <h5 className="my-3">Navigation</h5>
            <ul className="nav nav-pills nav-line-pills flex-column align-items-start ">
              {navigations.map((v, index) => (
                <li className="nav-item w-100" key={index}>
                  <a
                    data-bs-toggle="tab"
                    href={v.goto}
                    className={
                      "btn fw-semibold nav-link btn-color-gray-500 py-4 px-5 fs-5 btn-active-light text-active-primary text-start " +
                      (index === 0 && "active")
                    }
                  >
                    <span className="nav-text fw-bold mb-3">{v.name}</span>
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <div className="col-xl-8">
        {Object.values(courses).length > 0 ? (
          <div className="tab-content">
            <div className="tab-pane blockui fade show active" id="course-info">
              <FormCourse data={courses.detail} />
            </div>
            <div className="tab-pane blockui fade show" id="course-activity">
              <CourseActivityType data={courses.detail} />
            </div>
            <div className="tab-pane blockui fade show" id="course-version">
              <CourseVersion data={courses.detail} />
            </div>
          </div>
        ) : (
          <NoRecordFound />
        )}
      </div>
    </div>
  );
};
