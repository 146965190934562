import React, { useEffect, useState } from 'react'
import { SGUCard } from '../../../../../../_layouts/components/cards/CardUI'
import { useOutletContext } from 'react-router-dom';
import { LectProgress } from '../SectionUI';
import TableAttd from '../../../../attendances/lecturers/components/TableAttd';
import { GET_WIDGET_BY_GROUP } from '../../../../apis/module/BackendRequest';
import { ExtractWidgetIds } from '../../../../../../_layouts/_helpers/Global';
import { AuthDecodeToken } from '../../../../../reducer/AuthRedux';
import { LoadingSkeleton } from '../../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../../../_layouts/components/alerts/AlertsUI';

export function AttendanceInfo() {
    var Token = AuthDecodeToken();
    const context = useOutletContext();
    const data = context.data;
    const lecturers = data.lecturer;

    const [widgets, setWidgets] = useState({ loading: false, data: [], message: "" });

    const FetchWidgets = async () => {
        setWidgets({ loading: true, data: [], message: "" });
        const param = {
            group_id: Token.identity.group_id,
            menu_id: 9,
        }
        const result = await GET_WIDGET_BY_GROUP(param);

        if (result.data && Object.values(result.data).length > 0) {
            const isenabled = result.data.find((item) => item.is_enabled === 1);
            const data = ExtractWidgetIds(isenabled);
            setWidgets({ loading: false, message: "", data: data });
        } else {
            setWidgets(result);
        }
    }

    useEffect(() => {
        FetchWidgets();
    }, [])

    if (widgets.loading) {
        return <LoadingSkeleton />;
    } else if (widgets.message) {
        return <AlertNotif messages={widgets.message} />
    } else if (Object.values(widgets.data).length > 0) {
        const widget_33 = widgets.data.find(item => item.widget_id === 33 && item.is_enabled === 1);

        return (
            <div>
                <div className="list-lecturer mb-5">
                    <div className="row">
                        {Object.values(lecturers).length > 0 && (
                            lecturers.map((v, index) => (
                                <div className="col-auto" key={index}>
                                    <LectProgress data={v} />
                                </div>
                            ))
                        )}
                    </div>
                </div>

                <div className="list-data">
                    <TableAttd data={data} section_id={data.section_id} widget_33={widget_33} />
                </div>
            </div>
        )
    } else {
        return "";
    }
}
