import React, { useEffect, useState } from 'react'
import { GET_ACTIVE_ACAD, SUBMIT_RESIGN_STUDENT } from '../../../../../apis/node-target-data/AcademicApi';
import { LoadingBars } from '../../../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif, AlertRetry } from '../../../../../../../_layouts/components/alerts/AlertsUI';

export default function ResignStd({ stdAcad }) {
  var studentid = stdAcad.studentid;

  const [activeCal, setActiveCal] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const GET_ACTIVE_ACAD_LOOK_UP = async () => {
    setActiveCal({ loading: true, data: [], message: '' })
    const result = await GET_ACTIVE_ACAD();
    if (result.data && Object.values(result.data).length > 0) {
      var selectIndex = result.data.findIndex(
        (v) => v.atp_group_type.id === stdAcad.atp_group_type
      );
      setActiveCal({
        loading: false,
        data: result.data[selectIndex],
        message: "",
      });
    } else {
      setActiveCal(result);
    }

  }

  useEffect(() => {
    GET_ACTIVE_ACAD_LOOK_UP();
  }, []);

  const paramObj = {
    "flag": 0,
    "note": "",
    "studentid": studentid.toString()
  }
  const [paramResign, setParamResign] = useState(paramObj);
  const [submitResign, setSubmitResign] = useState({ loading: false, data: [], message: "" });

  const HandlerSubmitResign = async () => {
    const param = paramResign;
    param.term_id = activeCal.data && activeCal.data.atp_term.id;

    setSubmitResign({ loading: true, data: [], message: "" });
    const result = await SUBMIT_RESIGN_STUDENT(param);
    if (result.data && Object.values(result.data).length > 0) {
      alert("Success submited");
      setInterval(() => {
        window.location.reload();
      }, 1000);
    } else {
      const msg = result.message.includes("00000");
      if(msg){
        setSubmitResign({loading:false, data:[], message:"Submission failed due to unauthorized data ownership. Please try again later."});
      }else{
        setSubmitResign(result);
      }
      
    }
  }

  return (
    <div className="d-flex flex-column flex-center">
      {activeCal.loading ? <LoadingBars /> :
        activeCal.message ? <AlertRetry messages={activeCal.message} Reload={GET_ACTIVE_ACAD_LOOK_UP} /> :
          Object.values(activeCal.data).length > 0 && (
            <>
              <div className="w-100">
                <div className="mb-5">
                  <label className="required">Academic Term</label>
                  <input type="text" className="form-control" disabled value={activeCal.data.atp_term.name} />
                </div>
                <div className="mb-5">
                  <label className="required">Reason</label>
                  <input type="text" name="note" className="form-control" defaultValue={decodeURIComponent(paramResign.note)} onChange={(e) => setParamResign({ ...paramResign, note: encodeURIComponent(e.target.value) })} />
                  <span className="text-muted fst-italic">Type minimum 15 character</span>
                </div>
              </div>

              <div className="d-grid mb-5">
                <button
                  className="btn btn-danger text-uppercase fw-bolder px-9 py-4"
                  type="button"
                  disabled={submitResign.loading}
                  onClick={() => HandlerSubmitResign()}
                >
                  {submitResign.loading ? "Processing..." : "Submit"}
                </button>
              </div>
            </>
          )}

      {submitResign.message ? <AlertNotif color={Object.values(submitResign.data).length > 0 ? "info" : "danger"} messages={submitResign.message} /> : ""}
    </div>
  )
}
