import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import PackageJSON from "../../../../../package.json";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import {
  AccessInvalid,
  RequiredMsg,
} from "../../../../_layouts/_core/ErrorMessage";
import FilterUI from "../../components/filters/FilterUI";
import { GET_RPT_TEACHINGLOAD } from "../../apis/node-target-data/AcademicApi";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";
import {
  MessageError,
  openModal,
} from "../../../../_layouts/components/modals/Modals";
import {
  AcadTerm,
  CourseOwner,
  Department,
  EmployeeSelect,
} from "../../components/forms/SelectBox";
import { LectLookUp, Semester } from "../../components/forms/InputText";
import {
  LoadingSkeleton,
} from "../../../../_layouts/components/loadbars/LoadingBars";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import TableData from "./TableData";

export function AttendanceLect() {
  const context = useOutletContext();
  const widget_29 = context.widgets.find(
    (item) => item.widget_id === 29 && item.is_enabled === 1
  );

  const [cost_center, setCost_center] = useState(0);
  const [term_id, setTerm_id] = useState(PackageJSON.system_param.ActiveAcad);
  const [month, setMonth] = useState(0);
  const currYear = new Date().getFullYear();
  const [year, setYear] = useState(currYear);
  const [emp_id, setEmp_id] = useState(0);
  const [semester, setSemester] = useState("");
  const [clu_id, setCluId] = useState("");
  const [offset, setOffset] = useState(1);
  const limit = 10;

  const monthNames = [
    { id: "1", name: "January" },
    { id: "2", name: "February" },
    { id: "3", name: "March" },
    { id: "4", name: "April" },
    { id: "5", name: "May" },
    { id: "6", name: "June" },
    { id: "7", name: "July" },
    { id: "8", name: "August" },
    { id: "9", name: "September" },
    { id: "10", name: "October" },
    { id: "11", name: "November" },
    { id: "12", name: "December" },
  ];
  const years = Array.from(new Array(3), (val, index) => currYear - index);

  const postParam = {
    cost_center,
    term_id,
    month,
    year,
    emp_id,
    semester,
    clu_id,
  };
  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const ReloadData = async (postParam) => {
    setResults({ loading: true, data: [], message: "" });

    const param = RemoveEmptyValue(postParam);
    const result = await GET_RPT_TEACHINGLOAD(param);
    setResults(result);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const SubmitFilter = (e) => {
    e.preventDefault();
    if (term_id && month && year) {
      setOffset(1);
      const param = { ...postParam };
      param.offset = 1;
      param.limit = 10;
      ReloadData(param);
    } else {
      openModal({
        message: <MessageError message={RequiredMsg} />,
      });
    }
  };

  const HandlerPage = (type) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let current_offset = offset;
    if (type == 1) {
      current_offset = offset - 1;
    } else {
      current_offset = offset + 1;
    }
    setOffset(current_offset);
    const param = { ...postParam };
    param.offset = current_offset;
    param.limit = 10;
    ReloadData(param);
  };

  return widget_29 ? (
    <div id="approve-lecturer">
      <div className="row">
        <div className="col-lg-3 mb-5">
          <FilterUI title="Attendance" onSubmit={SubmitFilter} isload={results.loading}>
            <AcadTerm data={term_id} setData={setTerm_id} required />
            <div className="form-group mb-5">
              <label className="required fw-bold">Month Period</label>
              <div className="input-group mb-3">
                <select
                  name="year_period"
                  className="form-select form-select-sm"
                  required
                  defaultValue={year}
                  onChange={(e) => setYear(e.target.value)}
                >
                  {years.map((v, index) => (
                    <option value={v} key={index}>
                      {v}
                    </option>
                  ))}
                </select>
                <select
                  name="month_period"
                  className="form-select form-select-sm"
                  required
                  defaultValue={month}
                  onChange={(e) => setMonth(e.target.value)}
                >
                  <option value="0">Choose month</option>
                  {monthNames.map((v, index) => (
                    <option value={v.id} key={index}>
                      {v.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <EmployeeSelect data={emp_id} setData={setEmp_id} title={"Lecturer"} />
            <Department data={clu_id} setData={setCluId} />
            <Semester data={semester} setData={setSemester} />
            <CourseOwner data={cost_center} setData={setCost_center} />
            
          </FilterUI>
        </div>
        <div className="col-lg-9 mb-5">
          <SGUCard>
            <SGUCard.Header>
              <span className="card-label fw-bolder text-dark">
                Browse Attendance Lecturer
              </span>
              <span className="text-muted mt-1 fw-bold fs-7">
                List of attendance by filter
              </span>
            </SGUCard.Header>
            <SGUCard.Body>
              {results.loading ? (
                <LoadingSkeleton />
              ) : results.message ? (
                <AlertNotif messages={results.message} />
              ) : (
                <div className="d-flex flex-column justify-content-between h-100">
                  <div className="w-100">
                    <TableData results={results} ReloadData={SubmitFilter} />
                  </div>
                  <div className="w-100">
                    {Object.values(results.data).length > 0 ? (
                      <div className="footer">
                        <ul className="pagination">
                          <li
                            className={
                              "page-item " +
                              (offset !== 1 ? "cursor-pointer" : "disabled")
                            }
                          >
                            <span
                              className="page-link"
                              onClick={() => HandlerPage(1)}
                            >
                              <span>‹ Prev</span>
                            </span>
                          </li>
                          <li className="page-item active">
                            <span className="page-link text-white">
                              Page {offset}
                            </span>
                          </li>
                          <li
                            className={
                              "page-item " +
                              (Object.values(results.data).length === limit
                                ? "cursor-pointer"
                                : "disabled")
                            }
                          >
                            <span
                              className="page-link"
                              onClick={() => HandlerPage(2)}
                            >
                              <span>Next ›</span>
                            </span>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </SGUCard.Body>
          </SGUCard>
        </div>
      </div>
    </div>
  ) : (
    <AlertNotif messages={AccessInvalid} />
  );
}
