import React, { useState } from 'react'
import { SGUCardFlush } from '../../../../../../../_layouts/components/cards/CardUI';
import { useOutletContext } from 'react-router-dom';
import { DELETE_SECTION } from '../../../../../apis/node-target-data/AcademicApi';
import { ButtonIcon } from '../../../../../../../_layouts/components/buttons/ButtonUI';
import { NoRecordFound } from '../../../../../../../_layouts/components/loadbars/LoadingBars';
import UpdateLecturerAssign from './UpdateLecturerAssign';

export function CardLecturerAssign() {
    const context = useOutletContext();
    const data = context.data;
    const widgets = context.widget;
    const widget_12 = widgets.find(
        (item) => item.widget_id === 12 && item.is_enabled === 1
    );
    const widget_13 = widgets.find(
        (item) => item.widget_id === 13 && item.is_enabled === 1
    );

    const [isopen, setIsopen] = useState(false);

    const lecturers = data.lecturer;
    const totalLect = Object.values(lecturers).length;

    const RemoveData = async (id) => {
        const paramRemove = {
            deletetype: "emp",
            reff_id: id,
            section_id: data.section_id,
        };
        const results = await DELETE_SECTION(paramRemove);

        if (results) {
            setInterval(() => {
                window.location.reload();
            }, 100);
        }
    }

    const HandlerRemove = (id) => {
        if (window.confirm("Are you sure want to remove this item ?") === true) {
            RemoveData(id);
        }
    }

    return (
        <SGUCardFlush>
            <SGUCardFlush.Header>
                <span className="card-label fw-bold text-gray-800">Lecturer Assignment</span>
            </SGUCardFlush.Header>
            {widget_12 && (
                <SGUCardFlush.Toolbar>
                    <ButtonIcon title="Add New" onClick={() => setIsopen(!isopen)} >
                        <i className={"ki-outline ki-notepad-edit fs-3"}></i>
                    </ButtonIcon>
                </SGUCardFlush.Toolbar>
            )}
            <SGUCardFlush.Body>
                {totalLect > 0 ? (
                    lecturers.map((v, index) => (
                        <ItemLect key={index} data={v} widget_13={widget_13} HandlerRemove={HandlerRemove} />
                    ))
                ) : <NoRecordFound />}

                {isopen && <UpdateLecturerAssign section_id={data.section_id} setIsopen={setIsopen} />}
            </SGUCardFlush.Body>
        </SGUCardFlush>
    )
}

const ItemLect = ({ data, widget_13, HandlerRemove }) => {
    return (
        <>
            <div className="d-flex flex-stack">
                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                    <div className="flex-grow-1 me-2 w-50">
                        <span className="text-primary fs-6 fw-bold">
                            {data.emp && (data.emp.fullname || "")}
                        </span>
                        <div>
                            <span className="text-muted fw-semibold d-block fs-7">
                                Max Session: {data.emp_session}
                            </span>
                        </div>
                    </div>
                    {widget_13 && (
                        <ButtonIcon title="Remove" className="btn-sm btn-light btn-active-light-danger" onClick={() => HandlerRemove(data.id)}>
                            <i className="ki-outline ki-trash fs-5 m-0"></i>
                        </ButtonIcon>
                    )}
                </div>
            </div>
            <div className="separator separator-dashed my-3"></div>
        </>
    )
}
