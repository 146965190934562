import React, { useState } from 'react'
import { LectLookUp } from '../../../../../components/forms/InputText';
import { ButtonPrimary, ButtonTersier } from '../../../../../../../_layouts/components/buttons/ButtonUI';
import { MessageError, openModal } from '../../../../../../../_layouts/components/modals/Modals';
import { RequiredMsg } from '../../../../../../../_layouts/_core/ErrorMessage';
import { RemoveEmptyValue } from '../../../../../../../_layouts/_helpers/Global';
import { APPEND_SECTION_EMP } from '../../../../../apis/node-target-data/AcademicApi';

export default function UpdateLecturerAssign({ section_id, setIsopen }) {
    const [lecturer, setLecturer] = useState("");
    const [emp_id, setEmp_id] = useState(0);
    const [emp_session, setEmp_session] = useState(0);

    let paramPost = {
        emp_id,
        emp_session,
        section_id: section_id
    }
    const [submit, setSubmit] = useState(false);
    const SubmitHandler = async (e) => {
        e.preventDefault();
        if (paramPost.emp_id) {
            setSubmit(true);
            //const param = RemoveEmptyValue(paramPost);
            const result = await APPEND_SECTION_EMP(paramPost);

            if (result.data && Object.values(result.data).length > 0) {
                setSubmit(false);
                setInterval(() => {
                    window.location.reload();
                }, 100);
            } else {
                setSubmit(false);
                openModal({ message: <MessageError message={result.message} /> });
            }
        } else {
            openModal({ message: <MessageError message={RequiredMsg} /> });
        }
    }
    return (
        <div className='bg-light p-4 rounded'>
            <h4 className='text-start mb-5'>
                <i className="ki-outline ki-notepad-edit fs-4 me-1 text-danger"></i>
                Add Lecturer Assignment
            </h4>
            <form method="post" autoComplete='off' onSubmit={(e) => SubmitHandler(e)}>
                <LectLookUp lecturer={lecturer} setLecturer={setLecturer} setEmp_id={setEmp_id} />
                <div className="form-group mb-5 w-100px">
                    <label className="fw-bolder required">
                        Max Session
                    </label>
                    <input type="text" required className="form-control form-control-sm" defaultValue={emp_session} onChange={(e) => e.target.value ? setEmp_session(parseFloat(e.target.value)) : setEmp_session(0)} />
                </div>
                <div className="text-end">
                    <ButtonTersier type="button" className="btn-sm fw-bold me-3" onClick={() => setIsopen(false)}>
                        Cancel
                    </ButtonTersier>
                    <ButtonPrimary type="submit" className="btn-sm fw-bold" disabled={submit}>
                        {submit ? (
                            <>
                                <span
                                    className="spinner-grow spinner-grow-sm"
                                    aria-hidden="true"
                                ></span>
                                <span className="visually-hidden" role="status">
                                    Loading...
                                </span>
                            </>
                        ) : "Save changes"}

                    </ButtonPrimary>
                </div>
            </form>
        </div>
    )
}

