import React, { useEffect, useState } from 'react'
import { GET_PROFILE } from '../../../apis/node-target-data/AcademicApi';
import { SGUCard } from '../../../../../_layouts/components/cards/CardUI';
import { AuthDecodeToken } from '../../../../reducer/AuthRedux';
import { AvaBlank, AvaBoy, AvaGirl } from '../../../../../_layouts/components/images/Avatars';
import moment from 'moment';
import { MessageErrorConnection, openModal } from '../../../../../_layouts/components/modals/Modals';
import { useNavigate } from 'react-router-dom';

export function WProfile() {
    const navigate = useNavigate()

    const current_account = AuthDecodeToken()
    const [profile, setProfile] = useState({ loading: false, message: "", data: [] });
    const ReloadData = async () => {
        setProfile({ loading: true, message: "", data: [] })
        const result = await GET_PROFILE()

        if (result.data && Object.values(result.data).length > 0) {
            setProfile({ loading: false, message: "", data: result.data });
        } else if (result.message !== "") {
            const is_failed_connenction = result.message.includes("900901")
            if (is_failed_connenction) {
                openModal({ message: <MessageErrorConnection message={result.message} /> })
                setTimeout(() => {
                    navigate('/logout')
                }, 2000);
            }
        } else {
            setProfile(result)
        }
    }
    useEffect(() => {
        ReloadData();
    }, [])


    return (
        <SGUCard>
            <SGUCard.Body className={"pb-0"}>
                <div className="d-flex flex-column justify-content-between h-100">
                    <div className="w-100">
                        <div className="d-flex flex-wrap flex-sm-nowrap pt-8 mb-5">
                            <div className="me-3">
                                <div className="symbol symbol-40px symbol-circle">
                                    {profile.loading ? <AvaBlank className="bg-light-info p-1" /> :
                                        profile.data && (
                                            (profile.data.gender === "M") ? (
                                                <AvaBoy className="bg-light-info p-1" />
                                            ) : (<AvaGirl className="bg-light-info p-1" />)
                                        )}
                                </div>
                            </div>
                            <div className="flex-grow-1">
                                <div className="d-flex flex-column">
                                    <h3 className="text-gray-900 fw-bold me-1 mb-0">
                                        {current_account.identity.given_name} {current_account.identity.family_name}
                                    </h3>
                                    <span className="d-flex align-items-center text-gray-400 me-5 mb-2 fs-7">
                                        {current_account.identity.email}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="info mb-8">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="emp text-center">
                                    <p className="text-muted mb-0 fs-7">Employee ID</p>
                                    <p className="fs-4 mb-0">{current_account.identity.empid}</p>
                                </div>
                                <div className="emp text-center">
                                    <p className="text-muted mb-0 fs-7">ID</p>
                                    <p className="fs-4 mb-0">{current_account.identity.entityid}</p>
                                </div>
                                <div className="emp text-center">
                                    <p className="text-muted mb-0 fs-7">Group</p>
                                    <p className="fs-4 mb-0">{current_account.identity.group_name}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-100">
                        <div className="session-info">
                            <div className="alert alert-warning p-5 border-dashed">
                                <div className="fs-4 fw-bolder text-gray-700">
                                    <span>Session will time out at</span>
                                </div>
                                <div className="fw-bold text-muted">
                                    {moment(current_account.expiredSession).format('Do MMMM YYYY HH:mm')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SGUCard.Body>
        </SGUCard>
    )
}

