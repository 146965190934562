import { useState } from "react";
import { RequiredMsg } from "../../../../../../../_layouts/_core/ErrorMessage";
import { AlertNotif } from "../../../../../../../_layouts/components/alerts/AlertsUI";
import { ButtonPrimary, ButtonTersier } from "../../../../../../../_layouts/components/buttons/ButtonUI";
import { openModal } from "../../../../../../../_layouts/components/modals/Modals";
import { Corporate, CourseOwner } from "../../../../../components/forms/SelectBox";
import { UPDATE_SECTION } from "../../../../../apis/node-target-data/AcademicApi";
import { RemoveEmptyValue } from "../../../../../../../_layouts/_helpers/Global";

const UpdateSection = ({ data, setIsOpen }) => {
    const [cost_center_id, setcost_center_id] = useState(data.cost_center ? data.cost_center.id : 0);
    const [corporate_id, setcorporate_id] = useState(data.corporate ? data.corporate.id : 0);
    const paramObj = {
        corporate_id: corporate_id,
        max_seat: data.max_seat ? data.max_seat : 32,
        parent_section: data.parent_section ? data.parent_section : "",
        section_id: data.section_id,
        cost_center_id: cost_center_id,
    };
    const [paramSection, setParamSection] = useState(paramObj);
    const [updateSection, setPostSection] = useState({
        loading: false,
        data: [],
        message: "",
    });

    const HandlerSubmitSection = async (e) => {
        e.preventDefault();
        if (paramSection.max_seat) {
            setPostSection({
                loading: true,
                data: [],
                message: "",
            })
            var param = RemoveEmptyValue(paramSection);
            const results = await UPDATE_SECTION(param);
            
            setPostSection(results)
        } else {
            openModal({
                message: RequiredMsg,
            });
        }
    };

    return (
        <div className="update-section">
            <form
                autoComplete="off"
                method="post"
                onSubmit={(e) => HandlerSubmitSection(e)}
            >
                <CourseOwner data={cost_center_id} setData={setcost_center_id} />

                <div className="mb-5">
                    <label className="required fw-bolder">Max Number of Student</label>
                    <input
                        type="number"
                        className="form-control form-control-sm w-80px"
                        required
                        min={1}
                        defaultValue={paramSection.max_seat}
                        onChange={(e) =>
                            setParamSection({
                                ...paramSection,
                                max_seat: parseInt(e.target.value),
                            })
                        }
                    />
                </div>
                <div className="mb-5">
                    <label className="fw-bolder">Section Parent</label>
                    <input
                        type="text"
                        name="section_id"
                        className="form-control form-control-sm cursor-pointer"
                        placeholder="Click here"
                        value={paramSection.parent_section}
                        onChange={(e)=>setParamSection({...paramSection, parent_section:e.target.value})}
                    />
                </div>

                <Corporate data={corporate_id} setData={setcorporate_id} />

                <div className="text-end">
                    <div className="mb-5 btn-group">
                        <ButtonTersier className="btn-sm fw-bold"
                            type="button"
                            onClick={() => setIsOpen(false)}  >
                            Cancel
                        </ButtonTersier>
                        <ButtonPrimary className="btn-sm fw-bold" type="submit" disabled={updateSection.loading}>
                            {updateSection.loading ? "Processing" : "Save changes"}
                        </ButtonPrimary>
                    </div>
                </div>

                {updateSection.message && (
                    <AlertNotif
                        color={
                            Object.values(updateSection.data).length > 0 ? "info" : "danger"
                        }
                        message={updateSection.message}
                    />
                )}
            </form>
        </div>
    );
};

export { UpdateSection }