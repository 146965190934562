import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderStd from "./componensts/HeaderStd";
import Navigations from "./componensts/Navigations";
import { SGUCardFlush } from "../../../../_layouts/components/cards/CardUI";
import {
  GET_STUDENT_DATA_M,
  GET_STUDENT_STATUS,
} from "../../apis/node-target-data/AcademicApi";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif, AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import { Error404 } from "../../errors/components/Error404";
import MTXInstitutionHist from "./componensts/institute-hist";
import MatriculationPCF from "./componensts/matriculation-pcf";
import { GET_INSTITUTIONHIST_BY_REFF } from "../../apis/node-target-data/IdentityApi";
import { MessageError, openModal } from "../../../../_layouts/components/modals/Modals";

export function MTXDetailStudent() {
  const params = useParams();
  const token = params.student_id;

  const navigate = useNavigate();
  const location = useLocation();
  const state_data = location.state;

  const Back2Page = () => {
    const uribackto = "/students/matriculation-data-submission";
    if (state_data) {
      navigate(uribackto, {
        state: { param: state_data.param },
      });
    } else {
      navigate(uribackto);
    }
  };

  useEffect(() => {
    //BACK HANDLER
    const handleBackButton = (event) => {
      event.preventDefault();
      Back2Page();
    };

    // Refresh handler
    const handleBeforeUnload = (event) => {
      const message = "Are you sure you want to leave this page? Your changes may not be saved.";
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    window.history.pushState(null, null, window.location.href);

    window.addEventListener("popstate", handleBackButton);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    //END BACK
  }, [navigate]);

  try {
    const decodeToken = atob(token);
    const data = JSON.parse(decodeToken);
    return <DetailPage data={data} Back2Page={Back2Page} />;
  } catch (error) {
    return <Error404 />;
  }
}

const DetailPage = ({ data, Back2Page }) => {
  const student_id = data.student_id;
  const istransfer = data.is_transfer;

  const [is_transfer, setIs_transfer] = useState(istransfer);
  const [formMatric, setFormMatric] = useState(false);

  const [stdBio, setStdBio] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [stdStatus, setStdStatus] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadData = async () => {
    setStdStatus({ loading: true, data: [], message: "" });
    setStdBio({ loading: true, data: [], message: "" });

    const param = { studentid: student_id };
    const std_bio = await GET_STUDENT_DATA_M(param);
    const std_acad = await GET_STUDENT_STATUS(param);
    if ((std_bio.data && Object.values(std_bio.data).length > 0) && (std_acad.data && Object.values(std_acad.data).length > 0)) {
      setStdStatus(std_acad);
      setStdBio(std_bio);
      if (is_transfer === 1) {
        const param = { reffid: std_bio.data.reff_ins_id };
        ReloadInstitute(param)
      }
    } else {
      setStdStatus(std_acad);
      setStdBio(std_bio);
    }
  };

  useEffect(() => {
    ReloadData();
    if(istransfer !== 1) {
      setFormMatric(true);
    }
    setIs_transfer(istransfer);
    
  }, [student_id, istransfer]);

  const [institute, setInstitute] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadInstitute = async (param) => {
    setInstitute({ loading: true, message: "", data: [] });
    const result = await GET_INSTITUTIONHIST_BY_REFF(param);
    if (result.data && Object.values(result.data).length > 0) {
      setInstitute({ loading: false, message: "", data: result.data });
    } else {
      setInstitute({
        loading: false,
        message: result.message,
        data: [],
      });
    }
  };


  return (
    <div>
      {stdStatus.loading || stdBio.loading ? (
        <LoadingSkeleton />
      ) : stdStatus.message || stdBio.message ? (
        <AlertRetry
          messages={stdStatus.message || stdBio.message}
          Reload={ReloadData}
        />
      ) : Object.values(stdBio.data).length > 0 &&
        Object.values(stdStatus.data).length > 0 ? (
        <>
          <SGUCardFlush>
            <SGUCardFlush.Body className={"px-0"}>
              <HeaderStd stdBio={stdBio} stdStatus={stdStatus} is_transfer={is_transfer} setIs_transfer={setIs_transfer} />
            </SGUCardFlush.Body>
          </SGUCardFlush>

          <div className="render-tab-selected">
            {formMatric ? (
              <MatriculationPCF stdStatus={stdStatus.data} is_transfer={is_transfer} Back2Page={Back2Page} />
            ) : (
              is_transfer === 1 && (
                <MTXInstitutionHist stdBio={stdBio.data} setFormMatric={setFormMatric} institute={institute} setInstitute={setInstitute} ReloadInstitute={ReloadInstitute} />
              )
            )}
          </div>
        </>
      ) : (
        <NoRecordFound />
      )}
    </div>
  );
}
