import moment from 'moment';
import { KTIcon } from '../../../../../_metronic/helpers';

const RowSchd = ({ v }) => {
    return (
        <div>
            {v.is_cancel === 1 ? (
                <span className="d-block badge badge-danger">CANCELED</span>
            ) : ""}
            <span className="d-block fw-bolder">
                {moment(v.start_dt_formatted).format(
                    "MMMM Do YYYY"
                )}
            </span>
            <span className="">
                {moment(v.start_dt_formatted).format(
                    "hh:mm a"
                )}
                -
                {moment(v.end_dt_formatted).format(
                    "hh:mm a"
                )}
            </span>
        </div>
    )

}

const RowRoom = ({ v }) => {
    return (
        <div className="d-flex justify-content-start flex-column">
            {v.event_room ? (
                v.event_room.map((r, index) => (
                    (r.room) ? (
                        (r.room.g_resourceName) ? (
                            <div key={index}>
                                <span className="me-2 fw-bolder">
                                    Room {(r.room) ? r.room.g_resourceName + "(" + r.room.g_capacity + ")" : "-"}
                                </span>
                            </div>
                        ) : "-"
                    ) : "-"
                ))
            ) : (
                "-"
            )}
            <span className="d-block">
                Makeup :{" "}
                <span className="text-primary">
                    {v.is_makeup === 1 ? "Yes" : "No"}
                </span>
            </span>
            <span className="d-block">
                Activity :{" "}
                <span className="text-primary d-block">
                    {v.activity_type}
                </span>
            </span>
        </div>
    )
}

const RowLecturer = ({ v }) => {
    return (v.atd_lecturer) && (
        <>
            <span className="fw-bolder">
                {v.atd_lect_name}
            </span>
            <div className="fs-8 text-dark-50">
                <span className="fw-bold d-block">Teaching Topic:</span>
                {v.atd_teaching_topic && (v.atd_teaching_topic || "")}
            </div>
        </>
    )
}

const RowStatus = ({ v }) => {
    return (v.atd_lecturer) && (
        <>
            <span className="fw-bolder">
                {v.atd_status}
            </span>
            <div className="fs-8 text-dark-50">
                <span className="fw-bold d-block">Submited date:</span>
                {moment(v.atd_lec_time).format(
                    "MMMM Do YYYY HH:mm"
                )}
            </div>
        </>
    )
}

const RowCalendar = ({ v }) => {
    const typeGoogleCal = [{ id: 1, type: "classroom", name:"Google Classroom" }, { id: 2, type: "dept", name:"Calendar" }];

    return Object.values(v.event_calendar).length > 0 && (
        <div className="timeline timeline-border-dashed">
            {typeGoogleCal.map((m, index) => {
                var isClassroom = v.event_calendar.find(item => item.type === ("google.calendar.type." + m.type));
                var isDept = v.event_calendar.find(item => item.type === ("google.calendar.type." + m.type));

                return (
                    <div className="timeline-item" key={index}>
                        <div className="timeline-line"></div>
                        <div className="timeline-icon">
                            {(isClassroom || isDept) ? (
                                <KTIcon iconName='check-circle' className='text-success fs-2' />
                            ) : (
                                <KTIcon iconName='cross-circle' className='text-danger fs-2' />
                            )}
                        </div>
                        <div className="timeline-content mb-5 ">
                            <span className="fs-7 text-gray-500 fw-semibold d-block">{m.name}</span>
                            <span className="fs-6 fw-bold text-gray-800 text-uppercase">{m.type}</span>
                        </div>
                    </div>
                )
            })}
        </div>
    )
}

export {RowCalendar, RowLecturer, RowRoom, RowSchd, RowStatus}