import React, { useEffect, useState } from "react";
import { CLU_LOOK_UP } from "../../../apis/node-target-data/AcademicApi";
import { ButtonPrimary, ButtonTersier } from "../../../../../_layouts/components/buttons/ButtonUI";

export function FormClass({
  wizardActv,
  setWizardActv,
  paramSection,
  setParamSection,
}) {
  const prodyParam = { clu_id: 0, semester: 1, std_class: "A" };
  const [rowPrody, setRowPrody] = useState([]);

  const [clu, setCLU] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const [cluSpec, setCluSpec] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const ReloadData = async () => {
    setCLU({ loading: true, message: "", data: [] });
    setCluSpec({ loading: true, message: "", data: [] });
    const param_dept = { clu_type: "asc.clu.type.Major" };
    const clu_result = await CLU_LOOK_UP(param_dept);
    setCLU(clu_result);

    const param_dept_spec = { clu_type: "asc.clu.type.Spec" };
    const clu_spec_result = await CLU_LOOK_UP(param_dept_spec);
    setCluSpec(clu_spec_result);
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const FieldRowPrody = ({ clu }) => {
    return Object.values(rowPrody).length > 0 ? (
      rowPrody.map((v, index) =>
        v ? (
          <tr key={index}>
            <td>
              <select
                name="clu_id"
                className="form-select form-select-lg"
                value={v.clu_id}
                onChange={(e) =>
                  setRowPrody((prev) => {
                    const newData = [...prev];
                    newData[index].clu_id = parseInt(e.target.value);
                    return newData;
                  })
                }
              >
                <option value="0">Choose one</option>
                {clu.data.map((p, index) => (
                  <option value={p.id} key={index}>
                    {p.name}
                  </option>
                ))}
              </select>
            </td>

            <td>
              <select
                name="clu_spec_id"
                className="form-select form-select-lg"
                value={v.clu_spec_id}
                onChange={(e) =>
                  setRowPrody((prev) => {
                    if (e.target.value) {
                      const newData = [...prev];
                      newData[index].clu_spec_id = parseInt(e.target.value);
                      return newData;
                    } else {
                      const newData = [...prev];
                      delete newData[index].clu_spec_id;
                      return newData;
                    }
                  })
                }
              >
                <option value="">Choose one</option>
                {cluSpec.data.map((p, index) => (
                  <option value={p.id} key={index}>
                    {p.name}
                  </option>
                ))}
              </select>
            </td>

            <td>
              <input
                type="number"
                name="semester"
                className="form-control form-control-lg mb-3 mb-lg-0"
                maxLength={2}
                min={1}
                value={v.semester}
                onChange={(e) =>
                  setRowPrody((prev) => {
                    const newData = [...prev];
                    newData[index].semester = parseInt(e.target.value);
                    return newData;
                  })
                }
              />
            </td>

            <td>
              <input
                type="text"
                name="std_class"
                className="form-control form-control-lg mb-3 mb-lg-0"
                value={v.std_class}
                onChange={(e) =>
                  setRowPrody((prev) => {
                    const newData = [...prev];
                    newData[index].std_class = e.target.value;
                    return newData;
                  })
                }
              />
            </td>

            <td>
              <button
                className="btn btn-icon btn-sm btn-light-danger"
                type="button"
                onClick={(e) =>
                  setRowPrody((prev) => {
                    const newData = [...prev];
                    delete newData[index];
                    return newData;
                  })
                }
              >
                <i className="bi bi-trash"></i>
              </button>
            </td>
          </tr>
        ) : (
          ""
        )
      )
    ) : (
      <tr>
        <td colspan="5">No record found for program study</td>
      </tr>
    );
  };

  const handlerNext = () => {
    var currPrody = rowPrody.filter((item) => {
      if (item) return item;
    });
    setParamSection({ ...paramSection, prereq: [] });
    const isemptydata = currPrody.filter((item) => {
      if (item) {
        return item.semester === 0 || item.clu_id === 0;
      }
    });
    let sumSemes = currPrody.reduce(function(prev, current) {
      return prev + +current.semester;
    }, 0);

    if (currPrody.length > 1) {
      //console.log("banyak");
      if (isemptydata.length === 0) {
        //keisi
        //console.log("append");
        setParamSection({ ...paramSection, prereq: currPrody });
        setWizardActv(wizardActv + 1);
      } else {
        openModal({
          header: "Please check again your field",
          message: (
            <p className="text-dark">
              Please fill up the form of Program Study with correctly
            </p>
          ),
          open: true,
        });
      }
    } else {
      if (isemptydata.length === 0) {
        //keisi
        setParamSection({ ...paramSection, prereq: currPrody });
        setWizardActv(wizardActv + 1);
      } else {
        const isFillEmp = currPrody.filter((item) => {
          if (item) {
            return item.clu_id !== 0;
          }
        });
        if (isFillEmp.length === 0) {
          setParamSection({ ...paramSection, prereq: [] });
          setWizardActv(wizardActv + 1);
        } else {
          if (sumSemes > 0) {
            setParamSection({ ...paramSection, prereq: currPrody });
            setWizardActv(wizardActv + 1);
          } else {
            openModal({
              header: "Error",
              message: (
                <p className="text-dark">
                  Please fill out all required fields.
                </p>
              ),
              open: true,
            });
          }
        }
      }
    }
  };

  return (
    <div className="field-prody">
      <div id="frm-add-class" className="table-responsive">
        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
          <thead>
            <tr className="fw-bolder text-dark">
              <th>
                <label className="required">Program Study</label>
              </th>
              <th>Specialization</th>
              <th>
                <label className="required">Semester</label>
              </th>
              <th>Class Group</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <FieldRowPrody clu={clu} />
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={5}>
                <ButtonPrimary
                  className="btn-sm fw-bold"
                  type="button"
                  onClick={() =>
                    setRowPrody((prev) => {
                      return [...prev, prodyParam];
                    })
                  }
                >
                  <i className="bi bi-plus"></i>
                  Add new Row
                </ButtonPrimary>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>

      <div className="text-end">
        <div className="btn-group">
          <ButtonTersier
            className="btn-lg fw-bold"
            type="button"
            onClick={() => setWizardActv(wizardActv - 1)}
          >
            Back
          </ButtonTersier>
          <ButtonPrimary
            className="btn-lg fw-bold"
            type="button"
            onClick={() => handlerNext()}
          >
            <span className="me-2">Next </span>
            <i className="bi bi-arrow-right"></i>
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
}
