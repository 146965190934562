import React, { useMemo, useState } from "react";
import {
  LoadingSkeleton,
  NoRecordFound,
  SpinnerLoad,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import { KTIcon } from "../../../../../_metronic/helpers";
import {
  ConfirmRemove,
  MessageError,
  MessageSuccess,
  openModal,
} from "../../../../../_layouts/components/modals/Modals";
import {
  ButtonDropdown,
  ButtonIcon,
} from "../../../../../_layouts/components/buttons/ButtonUI";
import moment from "moment";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import { useOutletContext } from "react-router-dom";
import { DELETE_REQUEST_DOC, DOWNLOAD_REQUEST_DOC } from "../../../apis/module/JupiterRequest";
import { RSAencryptData } from "../../../../../_layouts/_helpers/RSAEnc";
import {
  ButtonItemRefresh,
  PaginationComponent,
  SearchBarTable,
} from "../../../../../_layouts/components/datatables/MYDatatables";
import { ConvertBytes } from "../../../../../_layouts/_helpers/Global";
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../../../../_layouts/components/popovers/PopOvers";
import { ColorStatusIcon } from "./FileHelpers";
import { useProvider } from "../../../../../_layouts/UMSLayoutProvider";

export default function TableData({
  results,
  Reload,
  open3rdColl,
  setOpen3rdColl,
  setIsOpenForm
}) {
  const { account } = useProvider();
  const context = useOutletContext();
  const widget_105 = context.widgets.find(
    (item) => item.widget_id === 105 && item.is_enabled === 1
  );
  const [search, setSearch] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5;

  const ResultData = useMemo(() => {
    let computedData = [];
    if (Object.values(results.data).length > 0) {
      computedData = results.data.map((v) => {
        let obj = {};
        const filetypeArr = v.doc_type.split(".");
        const filetype = filetypeArr[Object.values(filetypeArr).length - 1];
        obj.file_type = filetype;

        const statusArr = v.status.split(".");
        const status = statusArr[Object.values(statusArr).length - 1];
        obj.status_file = status;

        obj.id = v.id;
        obj.doc_type = v.doc_type || "";
        obj.filename = v.filename || "";
        obj.size = v.size || "";
        obj.createdby = v.createdby || "";
        obj.created = v.created || "";
        obj.editedby = v.editedby || "";
        obj.edited = v.edited || "";
        obj.file_id = v.file_id || "";
        obj.mimetype = v.mimetype || "";
        obj.owner = v.owner || "";
        obj.path = v.path || "";
        obj.public_url = v.public_url || "";

        return obj;
      });
    }
    computedData.sort((a, b) => (a.id < b.id ? 1 : -1));

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key]
            .toString()
            .toLowerCase()
            .includes(search)
        );
      });
    }
    setTotalItems(computedData.length);

    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [results.data, search, currentPage]);

  const HandlerDeleteFile = async (data) => {
    const param = {
      file_id: data.file_id,
      user: account.identity.email,
    };
    const objData = JSON.stringify(param);
    const rsaEnc = RSAencryptData(objData);

    const formData = new FormData();
    formData.append("data", rsaEnc);
    const result = await DELETE_REQUEST_DOC(formData);
    if (result.data && Object.values(result.data).length > 0) {
      Reload();
      openModal({
        message: <MessageSuccess message={"Successfully removed"} />,
      });
    } else {
      openModal({ message: <MessageError message={result.message} /> });
    }
  };

  return (
    <div>
      <div className="d-flex justify-content-between align-items-center px-8">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <ButtonItemRefresh totalItems={totalItems} onClick={(e) => Reload(e)} />
      </div>
      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable table-hover">
          <thead>
            <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
              <th className="px-3">Doc File</th>
              <th>File Size</th>
              <th>Owner File</th>
              <th>Last Modify</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {results.loading ? (
              <tr>
                <td colSpan={9} className="px-5">
                  <LoadingSkeleton />
                </td>
              </tr>
            ) : results.message ? (
              <tr>
                <td colSpan={9} className="px-5">
                  <AlertNotif messages={results.message} />
                </td>
              </tr>
            ) : ResultData.length > 0 ? (
              ResultData.map((v, index) => (
                <tr
                  key={index}
                  className={`fs-7 ${v.file_id === open3rdColl.data.file_id &&
                    "bg-light"}`}
                >
                  <td className="fs-7 ps-3">
                    <IconFile data={v} />
                  </td>
                  <td>
                    <span className="d-block text-muted">
                      {ConvertBytes(v.size)}
                    </span>
                    <span className="text-muted">{v.mimetype}</span>
                  </td>
                  <td>
                    <span className="d-block fw-bold">{v.owner}</span>
                  </td>
                  <td className="fs-7">
                    {v.edited ? (
                      <>
                        <span className="d-block fw-bold">{v.editedby}</span>
                        <span className="text-muted">
                          {moment(v.edited).format("DD MMM YYYY, hh:mm A")}
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="d-block fw-bold">{v.createdby}</span>
                        <span className="text-muted">
                          {moment(v.created).format("DD MMM YYYY, hh:mm A")}
                        </span>
                      </>
                    )}
                  </td>
                  <td className="text-center">
                    <div className="btn-group">
                      {v.status_file === "uploaded" && widget_105 ? (
                        <ButtonDropdown
                          icon={"dots-circle-vertical"}
                          className="btn btn-icon btn-sm bg-white no-caret"
                        >
                          <div
                            className="dropdown-item cursor-pointer"
                            onClick={() =>
                              setOpen3rdColl({ open: true, data: v })
                            }
                          >
                            <span className="text-dark">Information</span>
                          </div>
                          <div className="dropdown-item cursor-pointer" onClick={()=>setIsOpenForm({open:true, data:v})}>
                            <span className="text-dark">Update</span>
                          </div>
                          <div className="dropdown-item bg-light-danger">
                            <div
                              className="cursor-pointer"
                              onClick={() =>
                                openModal({
                                  message: (
                                    <ConfirmRemove
                                      handler={() => HandlerDeleteFile(v)}
                                      message={"Are you sure want to remove?"}
                                    />
                                  ),
                                })
                              }
                            >
                              <span className="text-danger fw-bold">
                                Delete
                              </span>
                            </div>
                          </div>
                        </ButtonDropdown>
                      ) : (
                        <ButtonIcon
                          type="button"
                          className="btn-sm"
                          title="Detail File"
                          onClick={() =>
                            setOpen3rdColl({ open: true, data: v })
                          }
                        >
                          <KTIcon
                            iconName="information-2"
                            className="fs-2 text-dark text-hover-primary"
                          />
                        </ButtonIcon>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9} className="px-5">
                  <NoRecordFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {!results.loading && (
          <PaginationComponent
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </div>
    </div>
  );
}

const IconFile = ({ data }) => {
  let color = ColorStatusIcon(data).color;
  let icon = ColorStatusIcon(data).icon;
  const [isLoading, setIsLoading] = useState(false);

  const HandlerViewFile = async (data) => {
    setIsLoading(true);
    const objData = JSON.stringify({
      file_id: data.file_id,
      user: data.createdby,
    });
    const rsaEnc = RSAencryptData(objData);
    
    const formData = new FormData();
    formData.append("data", rsaEnc);
    formData.append("file", []);
    const download = await DOWNLOAD_REQUEST_DOC(formData);
    if(download.status === 200){
      setIsLoading(false);
      const url = URL.createObjectURL(download.data)
      if(download.data.type.startsWith("image/")){
        openModal({header: "Preview File "+data.file_type,message: <img src={url} className="w-100 h-100" />, size:"lg"})
      }else if(download.data.type.startsWith("application/")){
        openModal({header: "Preview File "+data.file_type,message: <iframe src={url} className="w-100 rounded-lg" style={{ objectFit: "cover", height: "500px" }} />, size:"lg"})
      }
    }else{
      setIsLoading(false);
      openModal({message: <MessageError message={"Failed to preview file"} />})
    }
  };

  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement="top"
      overlay={renderTooltip({ title: "View File" })}
    >
      <div
        className="d-flex align-items-center cursor-pointer"
        onClick={() => HandlerViewFile(data)}
      >
        <i className={"ki-outline fs-1 ki-" + icon + " text-" + color}></i>
        <span className="d-block fw-bold text-uppercase mx-3">
          {data.file_type}
        </span>
        {isLoading && <SpinnerLoad /> }
      </div>
    </OverlayTrigger>
  );
};

