import React, { useState } from 'react'
import { CityLookUp, CountryLookUp } from '../../components/forms/SelectBox';
import { ButtonPrimary, ButtonTersier } from '../../../../_layouts/components/buttons/ButtonUI';
import { MessageSuccess, openModal } from '../../../../_layouts/components/modals/Modals';
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';
import { RequiredMsg } from '../../../../_layouts/_core/ErrorMessage';
import { CREATE_COMPANY, UPDATE_COMPANY } from '../../apis/node-target-data/IdentityApi';
import { RemoveEmptyValue } from '../../../../_layouts/_helpers/Global';

export default function FormCompany({ReloadData, data}) {
    const [postData, setPostData] = useState({
        name: data ? data.name : "",
        contactperson: data ? data.contactperson : ""
    })
    const [address, setAddress] = useState(data ? (data.address && (data.address.address || "")) : "");
    const [email, setEmail] = useState(data ? (data.address && (data.address.email || "")) : "");
    const [cellular1, setCellular1] = useState(data ? (data.address && (data.address.cellular1 || "")) : "");
    const [cellular2, setCellular2] = useState(data ? (data.address && (data.address.cellular2 || "")) : "");
    const [phone1, setPhone1] = useState(data ? (data.address && (data.address.phone1 || "")) : "");
    const [phone2, setPhone2] = useState(data ? (data.address && (data.address.phone2 || "")) : "");
    const [city_id, setCity_id] = useState(data ? (data.address && (data.address.city_id || "")) : "");
    const [country_id, setCountry_id] = useState(data ? (data.address && (data.address.country_id || "")) : "");
    const reff_address = data ? data.reff_address : "";

    const [submit, setSubmit] = useState({loading:false, data:[], message:""});

    const SubmitForm = async (e) =>{
        e.preventDefault();
        if(postData.name && city_id && country_id){
            setSubmit({loading:true, data:[], message:""});
            
            let paramAddr = {
                address:encodeURIComponent(address),
                email,
                cellular1,
                cellular2,
                phone1,
                phone2,
                city_id,
                country_id,
                reff_id:reff_address
            }
            let param = {
                name: postData.name,
                contactperson: postData.contactperson,
                address:[RemoveEmptyValue(paramAddr)]
            }
            let result;
            if(reff_address){
                param.reff_address = reff_address;
                result = await UPDATE_COMPANY(RemoveEmptyValue(param));
                
            }else{
                result = await CREATE_COMPANY(RemoveEmptyValue(param));
            }

            if(result.data && Object.values(result.data).length > 0){
                setSubmit({loading:false, data:result.data, message:""});
                openModal({message:<MessageSuccess message={"Successfully saved"} />});
                ReloadData({ name:postData.name, offset:1, limit:10 });
            }else{
                setSubmit(result);
            }
        }else{
            setSubmit({loading:false, data:[], message:RequiredMsg})
        }
    } 
  return (
    <div>
        <h3 className='mb-8'>
            <span className='d-block'>Form Company</span>
            <span className="text-muted fs-7">Please fill up this form with correctly</span>
        </h3>
        <form method="post" autoComplete='off' onSubmit={(e)=>SubmitForm(e)}>
            <div className="row">
                <div className="col-12">
                    <div className="form-group mb-8">
                        <label className="fw-bold required">
                            Company Name
                        </label>
                        <input type="text" required className="form-control form-control-sm" defaultValue={postData.name} onChange={(e)=>setPostData({...postData, name:e.target.value})} />
                    </div>
                </div>
                <div className="col-12">
                    <div className="form-group mb-8">
                        <label className="fw-bold">
                            Contact Person (PIC)
                        </label>
                        <input type="text" className="form-control form-control-sm" defaultValue={postData.contactperson} onChange={(e)=>setPostData({...postData, contactperson:e.target.value})} />
                    </div>
                    
                    <div className="form-group mb-8">
                        <label className="fw-bold">Company Email</label>
                        <input type="text" name="email" className="form-control form-control-sm" defaultValue={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="form-group mb-8">
                        <label className="fw-bold">
                            Mobile Phone 1
                        </label>
                        <input type="text" className="form-control form-control-sm" defaultValue={cellular1} onChange={(e)=>setCellular1(e.target.value)} />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group mb-8">
                        <label className="fw-bold">
                            Mobile Phone 2
                        </label>
                        <input type="text" className="form-control form-control-sm" defaultValue={cellular2} onChange={(e)=>setCellular2(e.target.value)} />
                    </div>
                </div>

                <div className="col-12">
                    <div className="form-group mb-8">
                        <label className="fw-bold">
                            Address
                        </label>
                        <input type="text" className="form-control form-control-sm" defaultValue={decodeURIComponent(address)} onChange={(e)=>setAddress(e.target.value)} />
                    </div>
                </div>

                <div className="col-lg-6">
                    <CityLookUp data={data ? (data.address && (data.address.city && (data.address.city.name || city_id))) : city_id} setData={setCity_id} required />
                </div>
                <div className="col-lg-6">
                    <CountryLookUp data={data ? (data.address && (data.address.country && (data.address.country.name || country_id))) : country_id} setData={setCountry_id} required />
                </div>

                <div className="col-lg-6">
                    <div className="form-group mb-8">
                        <label className="fw-bold">
                            Company Phone 1
                        </label>
                        <input type="text" className="form-control form-control-sm" defaultValue={phone1} onChange={(e)=>setPhone1(e.target.value)} />
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group mb-8">
                        <label className="fw-bold">
                            Company Phone 2
                        </label>
                        <input type="text" className="form-control form-control-sm" defaultValue={phone2} onChange={(e)=>setPhone2(e.target.value)} />
                    </div>
                </div>
            </div>

            {submit.message && (
                <div className="my-5">
                    <AlertNotif messages={submit.message} color='danger' />
                </div>
            )}

            <div className="text-end">
                <ButtonTersier className="btn-lg fw-bold me-3" type="button" onClick={()=>openModal({open:false})}>
                    Cancel
                </ButtonTersier>
                <ButtonPrimary className="btn-lg fw-bold" type="submit" disabled={submit.loading}>
                    {submit.loading ? "Processing...":"Save Changes"}
                </ButtonPrimary>
            </div>
        </form>
    </div>
  )
}
