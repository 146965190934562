// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollbar-holder::-webkit-scrollbar {
    width: 12px;
}
.scrollbar-holder::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.scrollbar-holder::-webkit-scrollbar-thumb {
    background: #a4a3a3;
    border-radius: 5px;
}
.scrollbar-holder::-webkit-scrollbar-thumb:hover {
    background: #555;
}

#table-component-score .freeze-column {
    position: sticky;
    left: 0;
    z-index: 1;
}
#table-component-score td.freeze-column{background-color: white;}
#table-component-score .active {
    background-color: #dceaf8;
}
#table-component-score th.active{
    border-top: 1px solid #8950fc;
    border-left: 1px solid #8950fc;
    border-right: 1px solid #8950fc;
}
#table-component-score td.active{
    border-left: 1px solid #8950fc;
    border-right: 1px solid #8950fc;
}
#table-component-score tfoot > tr > td.active{
    border-bottom: 1px solid #8950fc;
    border-top: 0px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/scores/classrooms/components/TableStudentScore.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,mBAAmB;IACnB,kBAAkB;AACtB;AACA;IACI,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,OAAO;IACP,UAAU;AACd;AACA,wCAAwC,uBAAuB,CAAC;AAChE;IACI,yBAAyB;AAC7B;AACA;IACI,6BAA6B;IAC7B,8BAA8B;IAC9B,+BAA+B;AACnC;AACA;IACI,8BAA8B;IAC9B,+BAA+B;AACnC;AACA;IACI,gCAAgC;IAChC,eAAe;AACnB","sourcesContent":[".scrollbar-holder::-webkit-scrollbar {\n    width: 12px;\n}\n.scrollbar-holder::-webkit-scrollbar-track {\n    background: #f1f1f1;\n}\n.scrollbar-holder::-webkit-scrollbar-thumb {\n    background: #a4a3a3;\n    border-radius: 5px;\n}\n.scrollbar-holder::-webkit-scrollbar-thumb:hover {\n    background: #555;\n}\n\n#table-component-score .freeze-column {\n    position: sticky;\n    left: 0;\n    z-index: 1;\n}\n#table-component-score td.freeze-column{background-color: white;}\n#table-component-score .active {\n    background-color: #dceaf8;\n}\n#table-component-score th.active{\n    border-top: 1px solid #8950fc;\n    border-left: 1px solid #8950fc;\n    border-right: 1px solid #8950fc;\n}\n#table-component-score td.active{\n    border-left: 1px solid #8950fc;\n    border-right: 1px solid #8950fc;\n}\n#table-component-score tfoot > tr > td.active{\n    border-bottom: 1px solid #8950fc;\n    border-top: 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
