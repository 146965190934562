import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Error404 } from "../../../errors/components/Error404";
import { ButtonPrimary, ButtonTersier } from "../../../../../_layouts/components/buttons/ButtonUI";
import { GET_ENROLLMENT_PROCESS } from "../../../apis/node-target-data/MarketingAPI";
import {
  GET_UNIV_BY_ID,
  GET_UNIV_PRODI_BY_ID,
} from "../../../apis/node-target-data/IdentityApi";
import {
  MessageError,
  MessageSuccess,
  openModal,
} from "../../../../../_layouts/components/modals/Modals";
import { LoadingSkeleton } from "../../../../../_layouts/components/loadbars/LoadingBars";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import PreviewProposal from "./componensts/PreviewProposal";
import { ExportProposalPDF } from "../components/ExportProposalPDF";
import { GET_STUDENT, GET_STUDENT_DATA_M, SUBMIT_TRANSFER_PROPOSAL } from "../../../apis/node-target-data/AcademicApi";
import { StudentIDFormat } from "../../../../../_layouts/_helpers/Global";

export function PreviewProposalCredit() {
  const navigate = useNavigate();
  const location = useLocation();
  const data = location.state?.data;
  const target_dept = location.state?.data?.target_dept;
  const mycourses = location.state?.mycourses;
  const courseData = location.state?.courseData;
  const result_draft = location.state?.result_draft;
  const proposal_id = location.state?.proposal_id;
  const total_credit = data?.total_credit;

  if (!data) return <Error404 />;
  else {
    const [loadData, setLoadData] = useState(false);
    const [candidate, setCandidate] = useState("");
    const [university, setUniversity] = useState("");
    const [universityProdi, setUniversityProdi] = useState("");
    const [statusProposal, setStatusProposal] = useState(result_draft && 'status' in result_draft ? result_draft.status : "DRAFT");
    
    const Back2Page = () => {
      const uribackto = "/course-managements/transfer-credit-proposal/form";
      let payload = { data: data, mycourses: mycourses, courseData: courseData }
      if(proposal_id){
        payload.proposal_id = proposal_id;
      }
      navigate(uribackto, {
        state: payload,
      });
    };
    const Back2FirstPage = () => {
      const uribackto = "/course-managements/transfer-credit-proposal";
      navigate(uribackto);
    };

    useEffect(() => {
      //BACK HANDLER
      const handleBackButton = (event) => {
        console.log(proposal_id);
        event.preventDefault();
        if (proposal_id && statusProposal === "SUBMITTED") {
          Back2FirstPage();
        } else {
          Back2Page();
        }
      };

      // Refresh handler
      const handleBeforeUnload = (event) => {
        const message =
          "Are you sure you want to leave this page? Your changes may not be saved.";
        event.preventDefault();
        event.returnValue = message;
        return message;
      };

      window.history.pushState(null, null, window.location.href);

      window.addEventListener("popstate", handleBackButton);
      window.addEventListener("beforeunload", handleBeforeUnload);

      return () => {
        window.removeEventListener("popstate", handleBackButton);
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
      //END BACK
    }, [navigate]);

    const GetDetailExistingStudent = async (param) =>{
      let results = { loading: false, message: "", data: [] };
      const result_student = await GET_STUDENT(param);
      const result_student_bio = await GET_STUDENT_DATA_M(param);
      if ((result_student_bio.data && Object.values(result_student_bio.data).length > 0) && (result_student.data && result_student.data.length > 0)) {
        const student_data = result_student.data[0];
        const student_bio_data = result_student_bio.data;
        const combined_student_data = {
            ...student_bio_data,
            ...student_data
        };

        results = { loading: false, message: "", data: combined_student_data };
      }

      return results;
    }

    const ReloadCandidate = async () => {
      setLoadData(true);
      try {
        let candidateData={};
        
        // First, try to get data from GET_ENROLLMENT_PROCESS
        const resultCandidate = await GET_ENROLLMENT_PROCESS({ candidate_id: data.candidates });
        
        if (resultCandidate.data && Object.values(resultCandidate.data).length > 0) {
          candidateData.name = resultCandidate.data[0]?.regstudent?.fullname || "-";
          candidateData.candidate_id = resultCandidate.data[0].candidate_id ? StudentIDFormat(resultCandidate.data[0].candidate_id) : "-";
          
        } else {
          // If GET_ENROLLMENT_PROCESS returns empty, use GetDetailExistingStudent
          const existingStudentData = await GetDetailExistingStudent({ studentid: data.candidates });
          if (existingStudentData.data && Object.values(existingStudentData.data).length > 0) {
            candidateData.name = existingStudentData.data?.fullname || "-";
            candidateData.candidate_id = existingStudentData.data?.studentid ? StudentIDFormat(existingStudentData.data?.studentid) : "-";
          }
        }
    
        // Proceed with getting university and university prodi data
        const [resultUniv, resultUnivPrody] = await Promise.all([
          GET_UNIV_BY_ID({ id: data.university_id }),
          GET_UNIV_PRODI_BY_ID({ id: data.university_prodi }),
        ]);
    
        if (
          Object.values(candidateData).length > 0 &&
          resultUniv.data &&
          Object.values(resultUniv.data).length > 0 &&
          resultUnivPrody.data &&
          Object.values(resultUnivPrody.data).length > 0
        ) {
          setCandidate({
            loading: false,
            data: candidateData,
            message: "",
          });
          setUniversity(resultUniv);
          setUniversityProdi(resultUnivPrody);
        } else {
          const errorMessage = "No data available for candidate, university, or university program";
          openModal({ message: <MessageError message={errorMessage} /> });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        openModal({
          message: (
            <MessageError message="An error occurred while fetching data" />
          ),
        });
      } finally {
        setLoadData(false);
      }
    };

    useEffect(() => {
      ReloadCandidate();
    }, [data]);

    const [isdownload, setDownload] = useState(false);
    const [submit, setSubmit] = useState({ loading: false, data: [], message: "" });
    
    const SubmitProposal = async () => {
      setSubmit({ loading: true, data: [], message: "" });
      const proposal_id = result_draft?.id ?? result_draft?.proposal_id ?? null;
      if (proposal_id === null) {
        openModal({ message: <MessageError message="Proposal ID not found" /> });
        setSubmit({ loading: false, data: [], message: "Proposal ID not found" });
        return;
      }

      const param = { proposal_id: proposal_id, flag: 1 }
      const result = await SUBMIT_TRANSFER_PROPOSAL(param);
      if (result.data && Object.values(result.data).length > 0) {
        try {
          Export2PDF();
          setStatusProposal("SUBMITTED");
          openModal({ message: <MessageSuccess message={"Data has been successfully created, and the file has been successfully downloaded."} /> })
        } catch (error) {
          console.error("Error exporting PDF:", error);
          openModal({ message: <MessageError message="Error exporting PDF" /> });
        }
      } else {
        openModal({ message: <MessageError message={result.message} /> });
      }
      setSubmit(result);
    }

    const Export2PDF = async () => {
      await ExportProposalPDF(setDownload, mycourses, candidate, university, universityProdi, target_dept, total_credit, data.doc_date);
    }

    return loadData ? (
      <LoadingSkeleton />
    ) : (
      (Object.values(candidate).length > 0 && Object.values(university).length > 0 && Object.values(universityProdi).length > 0) && (
        <div className="prev-data">
          <div className="w-50 m-auto">
            <SGUCard>
              <SGUCard.Header>
                <span className="card-label fw-bold text-gray-800">
                  Preview Transfer Credit Proposal
                </span>
              </SGUCard.Header>
              <SGUCard.Body className={"px-0 m-auto"}>
                <PreviewProposal data={mycourses} candidate={candidate} univ={university} univprodi={universityProdi} target_dept={target_dept} total_credit={total_credit} doc_date={data.doc_date} />

                <div className="mt-8 text-center">
                  {statusProposal === "DRAFT" && (
                    <ButtonPrimary type="button" className="btn-sm" onClick={() => SubmitProposal()} disabled={submit.loading || isdownload}>
                      {submit.loading ? "Processing..." :
                        isdownload ? "Exporting..." : "Submit and Download"}
                    </ButtonPrimary>
                  )}

                  {statusProposal === "SUBMITTED" && (
                    <ButtonPrimary type="button" className="btn-sm" disabled={isdownload} onClick={() => Export2PDF()}>
                      {isdownload ? "Exporting..." : "Export to PDF"}
                    </ButtonPrimary>
                  )}

                  <ButtonTersier type="button" className="btn-sm ms-2" onClick={() => Back2FirstPage()}>
                    Cancel
                  </ButtonTersier>
                </div>
              </SGUCard.Body>
            </SGUCard>
          </div>
        </div>
      )
    );
  }
}
