import { useEffect, useState } from "react";
import {
  ButtonTersier,
} from "../../../../../_layouts/components/buttons/ButtonUI";
import { openModal } from "../../../../../_layouts/components/modals/Modals";
import { ConvertToRupiah } from "../../Helper";
import moment from "moment";
import { StatusColor } from "../../components/Helper";

const ModalDetailBilling = ({ main }) => {
  const [data, setData] = useState();
  const detailData = (dat) => {
    let newData = { ...main };
    if (dat.fin_tr.length > 0) {
      dat.fin_tr.map((x) => {
        if (x.trans_type.includes("regfee")) {
          if (x.is_active === 1) {
            newData.reg_fee_data = x;
          } else {
            newData.reg_fee_data = {};
          }
        } else if (x.trans_type.includes("tuitionfee")) {
          if (x.is_active === 1) {
            newData.tuition_fee_data = x;
          } else {
            newData.tuition_fee_data = {};
          }
        }
      });
    }
    delete newData.fin_tr;
    setData(newData);
  };

  useEffect(() => {
    if (main) {
      detailData(main);
    }
  }, [main]);
  return (
    <div>
      {data?.reg_fee_data && (
        <div className="ms-5">
          <h5 className="text-primary">{data.name}</h5>
          <h5 className="text-muted">{data.complete.candidate_id}</h5>

          <div className="mt-10">
            <h5>Registration Fee</h5>
            <div className="ms-5">
              {data.reg_fee_status !== "CANCELED" && (
                <>
                  <tr className="border-0">
                    <td className="pe-5">Trans ID</td>
                    <td className="fw-bold">: {data.reg_fee_data?.trans_id}</td>
                  </tr>
                  {data.reg_fee_data.coupon_no !== null && (
                    <>
                      <tr className="border-0">
                        <td className="pe-5">Coupon</td>
                        <td className="fw-bold">
                          : {data.reg_fee_data.coupon_no ?? "-"}
                        </td>
                      </tr>
                      <tr className="border-0">
                        <td className="pe-5">Discount</td>
                        <td className="fw-bold">
                          : {data.reg_fee_data.discount ? ConvertToRupiah(data.reg_fee_data.discount) : "-"}
                        </td>
                      </tr>
                    </>
                  )}
                  <tr className="border-0">
                    <td className="pe-5">Nett Amount</td>
                    <td className="fw-bold">
                      :{" "}
                      {data.reg_fee_data.nett_amount
                        ? ConvertToRupiah(data.reg_fee_data.nett_amount)
                        : "-"}
                    </td>
                  </tr>
                  <tr className="border-0">
                    <td className="pe-5">Created</td>
                    <td className="fw-bold">
                      :{" "}
                      {moment(data.reg_fee_data.created).format(
                        "DD MMM YYYY hh:mm A"
                      ) ?? "-"}
                    </td>
                  </tr>
                  {data.reg_fee_data.paid_date !== null && (
                    <>
                      <tr className="border-0">
                        <td className="pe-5">Paid Date</td>
                        <td className="fw-bold">
                          :{" "}
                          {moment(data.reg_fee_data.paid_date).format(
                            "DD MMM YYYY hh:mm A"
                          ) ?? "-"}
                        </td>
                      </tr>
                      <tr className="border-0">
                        <td className="pe-5">Paid By</td>
                        <td className="fw-bold">
                          : {data.reg_fee_data.paidby ?? "-"}
                        </td>
                      </tr>
                    </>
                  )}
                </>
              )}
              <tr>
                <td className="pe-5">Status</td>
                <td className="fw-bold d-flex">
                  : <StatusColor data={data.reg_fee_status} />
                </td>
              </tr>
            </div>
          </div>

          <div className="mt-5">
            <h5>Tuition Fee</h5>
            <div className="ms-5">
              {data.tuition_status !== "CANCELED" && (
                <>
                  <tr className="border-0">
                    <td className="pe-5">Trans ID</td>
                    <td className="fw-bold">
                      : {data.tuition_fee_data.trans_id}
                    </td>
                  </tr>
                  {data.tuition_fee_data.coupon_no !== null && (
                    <>
                      <tr className="border-0">
                        <td className="pe-5">Coupon</td>
                        <td className="fw-bold">
                          : {data.tuition_fee_data.coupon_no ?? "-"}
                        </td>
                      </tr>
                      <tr className="border-0">
                        <td className="pe-5">Discount</td>
                        <td className="fw-bold">
                          : {data.tuition_fee_data.discount ?? "-"}
                        </td>
                      </tr>
                    </>
                  )}
                  <tr className="border-0">
                    <td className="pe-5">Nett Amount</td>
                    <td className="fw-bold">
                      :{" "}
                      {data.tuition_fee_data.nett_amount
                        ? ConvertToRupiah(data.tuition_fee_data.nett_amount)
                        : "-"}
                    </td>
                  </tr>
                  <tr className="border-0">
                    <td className="pe-5">Created</td>
                    <td className="fw-bold">
                      :{" "}
                      {moment(data.tuition_fee_data.created).format(
                        "DD MMM YYYY hh:mm A"
                      ) ?? "-"}
                    </td>
                  </tr>
                  {data.tuition_fee_data.paid_date !== null && (
                    <>
                      <tr className="border-0">
                        <td className="pe-5">Paid Date</td>
                        <td className="fw-bold">
                          :{" "}
                          {moment(data.tuition_fee_data.paid_date).format(
                            "DD MMM YYYY hh:mm A"
                          ) ?? "-"}
                        </td>
                      </tr>
                      <tr className="border-0">
                        <td className="pe-5">Paid By</td>
                        <td className="fw-bold">
                          : {data.tuition_fee_data.paidby ?? "-"}
                        </td>
                      </tr>
                    </>
                  )}
                </>
              )}
              <tr>
                <td className="pe-5">Status</td>
                <td className="fw-bold d-flex">
                  : <StatusColor data={data.tuition_status} />
                </td>
              </tr>
            </div>
          </div>

          <div className="mt-5">
            <tr className="border-0">
              <td className="pe-5">
                <h5>Registration Status</h5>
              </td>
              <td>
                :{" "}
                <span className={`fw-bold`}>
                  <StatusColor data={data.reg_status} />
                </span>
              </td>
            </tr>
          </div>
        </div>
      )}
      <div class="separator my-10"></div>
      <div className="d-flex justify-content-end">
        <ButtonTersier onClick={() => openModal({ open: false })}>
          Close
        </ButtonTersier>
      </div>
    </div>
  );
};

export { ModalDetailBilling };
