import React, { useState } from "react";
import { GET_REQUEST_DOC } from "../../../apis/module/JupiterRequest";
import { Encryptor } from "../../components/Helper";

export default function UploadFiles() {
  const handleEncrypt = async () => {
    const datadict = {
      doc_type: "strg.doc.type.pasfoto",
    };

    const encrypted = Encryptor(datadict);

    const formData = new FormData();
    formData.append("data", encrypted);

    const aha = await GET_REQUEST_DOC(formData);
    console.log("aha ", aha);
  };

  return (
    <div>
      <button onClick={() => handleEncrypt()}>Encript</button>
    </div>
  );
}
