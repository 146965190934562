import { ReqAPIEstes, RequestData } from "../ApiBackend";

const GET_ENROLLMENT_PROCESS = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_ENROLLMENT_PROCESS",
  };
  // console.log("payload ", payload);
  const result = await ReqAPIEstes(payload);
  // console.log("enroll list ", result)

  return result;
};

const UPDATE_CANDIDATE = async (param) => {
  const payload = {
    parameters: param,
    target_data: "UPDATE_CANDIDATE",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const UPDATE_ENROLLMENT = async (param) => {
  const payload = {
    parameters: param,
    target_data: "UPDATE_ENROLLMENT",
  };
  console.log(payload);
  const result = await ReqAPIEstes(payload);

  return result;
};

const SUBMIT_MKT_FIN_TRANS = async (param) => {
  const payload = {
    parameters: param,
    target_data: "SUBMIT_MKT_FIN_TRANS",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const SUBMIT_MKT_FIN_TRANS_OFFLINE = async (param) => {
  const payload = {
    parameters: param,
    target_data: "SUBMIT_MKT_FIN_TRANS_OFFLINE",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const FLAG_MKT_FIN_TRANS = async (param) => {
  const payload = {
    parameters: param,
    target_data: "FLAG_MKT_FIN_TRANS",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const POST_ENROLLMENT_REGISTER = async (param) => {
  const payload = {
    parameters: param,
    target_data: "POST_ENROLLMENT_REGISTER",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_DEPARTMENT = async () => {
  const payload = {
    target_data: "GET_DEPARTMENT",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_YEARINTAKE = async () => {
  const payload = {
    target_data: "GET_YEARINTAKE",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const CREATE_MKT_COUPON = async (param) => {
  const payload = {
    parameters: param,
    target_data: "CREATE_MKT_COUPON",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_MKT_COUPON = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_MKT_COUPON",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const DELETE_MKT_COUPON = async (param) => {
  const payload = {
    parameters: param,
    target_data: "DELETE_MKT_COUPON",
  };
  console.log(payload);
  const result = await ReqAPIEstes(payload);
  console.log(result);

  return result;
};

const CANCEL_MKT_FIN_TRANS = async (param) => {
  const payload = {
    parameters: param,
    target_data: "CANCEL_MKT_FIN_TRANS",
  };
  console.log(payload);
  const result = await ReqAPIEstes(payload);
  console.log(result);

  return result;
};

const CREATE_MKT_FIN_TRANS = async (param) => {
  const payload = {
    parameters: param,
    target_data: "CREATE_MKT_FIN_TRANS",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const UPLOAD_MKT_DOC = async (param) => {
  const payload = {
    parameters: param,
    target_data: "UPLOAD_MKT_DOC",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const DELETE_MKT_DOC = async (param) => {
  const payload = {
    parameters: param,
    target_data: "DELETE_MKT_DOC",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const VERIFY_MKT_DOC = async (param) => {
  const payload = {
    parameters: param,
    target_data: "VERIFY_MKT_DOC",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const UPDATE_ENROLLPROC_DOC_STATUS = async (param) => {
  const payload = {
    parameters: param,
    target_data: "UPDATE_ENROLLPROC_DOC_STATUS",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const CREATE_NOTIFICATION = async (param) => {
  const payload = {
    parameters: param,
    target_data: "CREATE_NOTIFICATION",
  };
  console.log(payload);
  const result = await ReqAPIEstes(payload);
  console.log(result);

  return result;
};

const CREATE_STUDENT_FROM_ENROLLPROC = async (param) => {
  const payload = {
    parameters: param,
    target_data: "CREATE_STUDENT_FROM_ENROLLPROC",
  };
  // console.log(payload);
  const result = await ReqAPIEstes(payload);
  // console.log(result);

  return result;
};

const GET_ENROLLDATA = async (param) => {
  const payload = {
    // parameters: param,
    target_data: "GET_ENROLLDATA",
  };
  // console.log(payload);
  const result = await ReqAPIEstes(payload);
  // console.log(result);

  return result;
};

//template acceptance letter
const GET_TEMPLATE_ACPTL_BY_TYPE = async (payload) => {
  const param = {
    method: "post",
    pathuri: "api/sys-param/fetch-by-tipe",
    payload: payload,
  };
  const result = await RequestData(param);

  if (result.data && Object.values(result.data).length > 0) {
    return { loading: false, message: "", data: result.data };
  } else {
    return result;
  }
};

const GET_SCHOOL_CURR = async (param) => {
  const payload = {
    // parameters: param,
    target_data: "GET_SCHOOL_CURR",
  };
  // console.log(payload);
  const result = await ReqAPIEstes(payload);
  // console.log(result);

  return result;
};

export {
  GET_ENROLLMENT_PROCESS,
  UPDATE_CANDIDATE,
  UPDATE_ENROLLMENT,
  SUBMIT_MKT_FIN_TRANS,
  SUBMIT_MKT_FIN_TRANS_OFFLINE,
  POST_ENROLLMENT_REGISTER,
  FLAG_MKT_FIN_TRANS,
  GET_DEPARTMENT,
  GET_YEARINTAKE,
  CREATE_MKT_COUPON,
  GET_MKT_COUPON,
  DELETE_MKT_COUPON,
  CANCEL_MKT_FIN_TRANS,
  CREATE_MKT_FIN_TRANS,
  UPLOAD_MKT_DOC,
  DELETE_MKT_DOC,
  VERIFY_MKT_DOC,
  UPDATE_ENROLLPROC_DOC_STATUS,
  CREATE_NOTIFICATION,
  CREATE_STUDENT_FROM_ENROLLPROC,
  GET_TEMPLATE_ACPTL_BY_TYPE,
  GET_ENROLLDATA,
  GET_SCHOOL_CURR,
};
