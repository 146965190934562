import React from "react";
import GMailGroups from "./groupmails";
import GClassrooms from "./classrooms";
import { useOutletContext } from "react-router-dom";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import GRooms from "./rooms";

export function GoogleManagement() {
  const context = useOutletContext();
  const widget_95 = context.widgets.find(
    (item) => item.widget_id === 95 && item.is_enabled === 1
  );
  return widget_95 ? (
    <div id="google-manage" className="row">
      <div className="col-lg-6 mb-8">
        <GClassrooms />
      </div>
      <div className="col-lg-6 mb-8">
        <GMailGroups />
      </div>
      <div className="col-12">
        <GRooms />
      </div>
    </div>
  ) : <AlertNotif messages={AccessInvalid} />;
}
