import { GET_THS } from "../../apis/node-target-data/AcademicApi";
import {
  MessageError,
  openModal,
} from "../../../../_layouts/components/modals/Modals";
import moment from "moment";
import Export2PDF from "../../components/export-pdf/Export2PDF";
import { StudentIDFormat } from "../../../../_layouts/_helpers/Global";

const DownloadAttdSchd = async (param, filename, setStatus) => {
  try {
    setStatus(true);
    const result = await GET_THS(param);

    if (result.data && Object.values(result.data).length > 0) {
      const r_schd = result.data[0];
      const jsxContent = <ScheduleContent data={r_schd} />;
      //await GenerateAndDownloadPDF(jsxContent, filename);
      await Export2PDF(filename, jsxContent);
    } else {
      openModal({ message: <MessageError message={result.message} /> });
    }
    setStatus(false);
  } catch (error) {
    console.log(error);
    openModal({
      message: (
        <MessageError message={"Failed to download. Please try again later."} />
      ),
    });
    setStatus(false);
  }
};

const ScheduleContent = ({ data }) => {
  return (
    <div style={styles.container}>
      <h6 style={styles.title}>Thesis Defense Information</h6>
      <TableProfile data={data} />
      <h6 style={{ ...styles.title, marginBottom:"10px" }}>Thesis Defense Attendance</h6>
      <TableAttd data={data} />
    </div>
  );
};

const TableProfile = ({ data }) => (
  <table style={styles.table}>
    <tbody>
      <tr>
        <td colSpan={2}>
          <table style={styles.table}>
            <tbody>
              <tr style={styles.profile}>
                <td width="20%">Student Name</td>
                <td>
                  : {data.studentdata && (data.studentdata.fullname || "")}
                </td>
              </tr>
              <tr style={styles.profile}>
                <td width="20%">Student ID</td>
                <td>: {StudentIDFormat(data.studentid) || ""}</td>
              </tr>
              <tr style={styles.profile}>
                <td width="20%">Department</td>
                <td>
                  :{" "}
                  {data.studentdata &&
                    data.studentdata.clu &&
                    (data.studentdata.clu.name || "")}
                </td>
              </tr>
              <tr style={styles.profile}>
                <td width="20%">Thesis Title</td>
                <td>
                  <div style={{ width: "350px", wordWrap: "inherit" }}>
                    : {data.final_title || ""}
                  </div>
                </td>
              </tr>
              <tr style={styles.profile}>
                <td width="20%">Defense Date and Time</td>
                <td>
                  :{" "}
                  {data.defensedate &&
                    (moment(data.defensedate + " " + data.defensetime).format(
                      "DD MMM YYYY, hh:mm A"
                    ) ||
                      "")}
                </td>
              </tr>
              <tr style={styles.profile}>
                <td width="20%">Room</td>
                <td>: {data.room && (data.room.name || "")}</td>
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
);

const TableAttd = ({ data }) => {
  const excludedEmpIds = [86,910,1146,927,1294,857,2041];
  const examiners = data.ths_main_ex.filter(item => !excludedEmpIds.includes(item.emp_id));
  return (
    <table style={styles.table_border} border={1}>
      <thead>
        <tr>
          <th width="50%" style={{ ...styles.c_name, height:"0 !important" }}>
            <span >Name</span>
          </th>
          <th style={{ ...styles.c_name, height:"0 !important" }}>
            <span >Signature</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style={styles.c_name}>{data.studentdata && (data.studentdata.fullname || "")}</td>
          <td style={styles.c_name}></td>
        </tr>
        {examiners.map((v,index)=>(
            <tr key={index}>
                <td style={styles.c_name}>{v.emp && (v.emp.fullname || "")}</td>
                <td style={styles.c_name}></td>
            </tr>
        ))}
      </tbody>
    </table>
  );
};

const styles = {
  container: { width: "595px" },
  table: { width: "595px", borderCollapse: "unset" },
  profile: { fontSize: "10px !important" },
  title: { letterSpacing: "normal", wordSpacing: "normal", fontWeight: "bold" },
  table_border: { width: "500px", borderCollapse: "collapse", border: "1px solid black" },
  c_name: { fontSize: "10px !important", height: "80px", verticalAlign: "middle", border: "1px solid black", padding:"5px" },
};

export { DownloadAttdSchd };
