import { KTIcon } from "../../../../../_metronic/helpers";

const FeeData = ({ className, title }) => {
  const top = Math.floor(Math.random() * 101)
  const right = 100 - Math.floor(Math.random() * 101)

  return (
    <div className={`card ${className} position-relative overflow-hidden`}>
      {/* begin::Beader */}
      <div className="card-header border-0 py-5" style={{ zIndex: 999 }}>
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">{title}</span>

          <span className="text-muted fw-semibold fs-7">2025</span>
        </h3>
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div
        className="card-body p-0 d-flex flex-column "
        style={{ zIndex: 999 }}
      >
        {/* begin::Stats */}
        <div className="card-px pt-5 pb-10 flex-grow-1">
          {/* begin::Row */}
          <div className="row g-0 mt-5 mb-10">
            {/* begin::Col */}
            <div className="col">
              <div className="d-flex align-items-center me-2">
                {/* begin::Symbol */}
                <div className="symbol symbol-50px me-3">
                  <div className="symbol-label bg-light-info">
                    <KTIcon iconName="wallet" className="fs-1 text-info" />
                  </div>
                </div>
                {/* end::Symbol */}

                {/* begin::Title */}
                <div>
                  <div className="fs-4 text-gray-900 fw-bold">340</div>
                  <div className="fs-7 text-muted fw-semibold">Unpaid</div>
                </div>
                {/* end::Title */}
              </div>
            </div>
            {/* end::Col */}

            {/* begin::Col */}
            <div className="col">
              <div className="d-flex align-items-center me-2">
                {/* begin::Symbol */}
                <div className="symbol symbol-50px me-3">
                  <div className="symbol-label bg-light-success">
                    <KTIcon iconName="dollar" className="fs-1 text-success" />
                  </div>
                </div>
                {/* end::Symbol */}

                {/* begin::Title */}
                <div>
                  <div className="fs-4 text-gray-900 fw-bold">706</div>
                  <div className="fs-7 text-muted fw-semibold">Paid</div>
                </div>
                {/* end::Title */}
              </div>
            </div>
            {/* end::Col */}
          </div>
          {/* end::Row */}
        </div>
        {/* end::Stats */}
      </div>
      {/* end::Body */}

      <div
        className="position-absolute w-100 h-100 z-0 opacity-25"
        style={{ top, right }}
      >
        <svg
          id="sw-js-blob-svg"
          viewBox="0 0 80 80"
          xmlns="http://www.w3.org/2000/svg"
        >
          <defs>
            <linearGradient id="sw-gradient" x1="0" x2="1" y1="1" y2="0">
              <stop
                id="stop1"
                stop-color="rgba(248, 117, 55, 1)"
                offset="0%"
              ></stop>
              <stop
                id="stop2"
                stop-color="rgba(251, 168, 31, 1)"
                offset="100%"
              ></stop>
            </linearGradient>
          </defs>
          <path
            fill="url(#sw-gradient)"
            d="M23.8,-28.5C29.3,-23.8,31.1,-14.8,30.5,-7.1C29.8,0.7,26.7,7.1,23.9,15.5C21.1,23.8,18.6,33.9,12.3,38.1C6,42.2,-4,40.3,-12.1,36.1C-20.2,31.9,-26.4,25.3,-30.1,17.9C-33.9,10.4,-35.1,2,-33.7,-6C-32.3,-13.9,-28.3,-21.4,-22.2,-26C-16.2,-30.7,-8.1,-32.4,0.5,-33.1C9.2,-33.7,18.3,-33.2,23.8,-28.5Z"
            width="100%"
            height="100%"
            transform="translate(50 50)"
            strokeWidth="0"
            style={{ transition: "0.3s" }}
          ></path>
        </svg>
      </div>
    </div>
  );
};

export { FeeData };
