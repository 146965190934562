import React, { useEffect, useState } from 'react'
import { LoadingBars, NoRecordFound } from '../../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertRetry } from '../../../../../../_layouts/components/alerts/AlertsUI';
import { GET_INSTITUTIONHIST_BY_REFF } from '../../../../apis/node-target-data/IdentityApi';
import { ButtonPrimary } from '../../../../../../_layouts/components/buttons/ButtonUI';
import { FormNewInstitution, RowSchool, RowUniversity } from './InstComponents';
import { openModal } from '../../../../../../_layouts/components/modals/Modals';


export default function FormInstitution({ stdBio }) {
    const reff_ins_id = stdBio.reff_ins_id ? stdBio.reff_ins_id : '';
    const [institute, setInstitute] = useState({ loading: false, data: [], message: "" })
    const ReloadData = async () => {
        setInstitute({ loading: true, message: "", data: [] })
        const param = { reffid: reff_ins_id }
        const result = await GET_INSTITUTIONHIST_BY_REFF(param);
        setInstitute(result);
    }

    useEffect(() => {
        ReloadData();
    }, []);

    return (
        <div id='student-institute'>
            <div className="table-responsive">
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                        <tr className='fw-bolder text-muted'>
                            <th>No</th>
                            <th>Type</th>
                            <th>Institution</th>
                            <th>Graduation Year</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {institute.loading ?
                            <tr><td colSpan={5}><LoadingBars /></td></tr> :
                            institute.message ?
                                <tr><td colSpan={5}><AlertRetry messages={institute.message} Reload={ReloadData} /></td></tr> :
                                Object.values(institute.data).length > 0 ? (
                                    institute.data.map((v, index) => (
                                        (v.institution_type === "identity.ins.type.university") ? <RowUniversity Reload={ReloadData} data={v} index={index} key={index} /> : 
                                        (v.institution_type === "identity.ins.type.school") ? <RowSchool Reload={ReloadData} data={v} index={index} key={index} /> : ""
                                    ))
                                ) : (
                                    <tr><td colSpan={5}><NoRecordFound /></td></tr>
                                )}
                    </tbody>
                </table>
                <div className="text-center">
                    <ButtonPrimary type="button" className="btn-sm fw-bold" onClick={()=>openModal({message:<FormNewInstitution ReloadData={ReloadData} reff_id={reff_ins_id} />})}>
                        Add New Institution
                    </ButtonPrimary>
                </div>
            </div>
        </div>
    )
}
