import { useEffect, useState } from "react";
import { KTIcon } from "../../../../_metronic/helpers";

const icons = {
  success: "check-circle",
  danger: "cross-circle",
};

const Toaster = ({ type = "success", message }) => {
  return (
    <div
      className={`py-3 px-4 me-5 rounded-md text-white d-flex align-items-center alert alert-${type}`}
      style={{     
        zIndex: 999,
        boxShadow: "0 3px 8px rgba(0, 0, 0, 0.3)",
      }}
    >
      <KTIcon iconName={icons[type]} className={`text-${type} fs-2x me-3`} />
      <div className="d-flex flex-column">
        <span className={`text-${type} fs-5`}>{message}</span>
      </div>
    </div>
  );
};

export default Toaster;
