import moment from "moment";

const GroupByKey = (list, key) => {
  var result = list.reduce(
    (hash, obj) => ({
      ...hash,
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    }),
    {}
  );
  return result;
};

const RemoveFirstItemWithKey = (objArr, key) => {
  var objResult = [...objArr];
  const indexToRemove = objResult.findIndex((item) => item.type === key);
  let newData;
  if (indexToRemove !== -1) {
    newData = [...objArr];
    newData.splice(indexToRemove, 1);
  }

  return newData;
};

const FormatRupiah = (angka, prefix) => {
  var number_string = angka
      .toString()
      .replace(/[^,\d]/g, "")
      .toString(),
    split = number_string.split(","),
    sisa = split[0].length % 3,
    rupiah = split[0].substr(0, sisa),
    ribuan = split[0].substr(sisa).match(/\d{3}/gi);

  var separator = "";
  if (ribuan) {
    separator = sisa ? "." : "";
    rupiah += separator + ribuan.join(".");
  }

  rupiah = split[1] != undefined ? rupiah + "," + split[1] : rupiah;
  return prefix == undefined ? rupiah : rupiah ? "" + rupiah : "";
};

const RemoveEmptyValue = (dataArr) => {
  const newData = { ...dataArr };

  for (const key in newData) {
    if (newData[key] === "" || newData[key] === 0 || newData[key] === null) {
      delete newData[key];
    }
  }

  return newData;
};

const LoopWeekDay = (now) => {
  var weekdays = [];
  for (let i = 1; i <= 6; i++) {
    const day = now.clone().weekday(i);
    weekdays.push({
      name: day.format("DD ddd"), // Format the day name (e.g., Monday)
      date: day.format("YYYY-MM-DD"), // Format the date (e.g., 2023-09-01)
    });
  }

  return weekdays;
};

const LoopDays = (start, end) => {
  const start_dt = moment(start, "YYYY-MM-DD");
  const end_dt = moment(end, "YYYY-MM-DD");
  var days = [];

  while (start_dt.isSameOrBefore(end_dt)) {
    //console.log("date:",start_dt.format('YYYY-MM-DD'));
    days.push(start_dt.format("DD MMM YYYY"));
    start_dt.add(1, "day");
  }
  return days;
};

const ExtractWidgetIds = (data) => {
  const widgetIds = [];

  const traverse = (node) => {
    if (node.widget_id) {
      widgetIds.push({
        widget_id: node.widget_id,
        is_enabled: node.is_enabled,
      });
    }

    if (node.children && node.children.length > 0) {
      node.children.forEach((child) => traverse(child));
    }
  };
  traverse(data);
  return widgetIds;
};

const IsNumber = (evt) => {
  evt = evt ? evt : window.event;
  var charCode = evt.which ? evt.which : evt.keyCode;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
};

const CalculateDate = (dateString) => {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
};

const CleansingJSON = (data) => {
  let cleansedData = data.replace(/([{,]\s*)(\w+)\s*:/g, '$1"$2":');
  cleansedData = JSON.parse(cleansedData);
  return cleansedData;
};

const ConvertBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

const StudentIDFormat = (number) => {
  if (number && number.length === 9) {
    return number.toString().replace(/(\d+)(\d{3})(\d{3})$/, "$1-$2-$3");
  } else {
    return number;
  }
};

const EnrollmentStdType = (typeid) => {
  let enrollType = "";
  if (typeid === "mkt.enrollment.type.transfer.in.external") {
    enrollType = "Transfer";
  } else {
    var enrollment = typeid;
    var enrollmentArr = enrollment.split(".");
    enrollType = enrollmentArr[Object.values(enrollmentArr).length - 1];
  }
  return enrollType;
};

export {
  GroupByKey,
  RemoveFirstItemWithKey,
  FormatRupiah,
  RemoveEmptyValue,
  LoopWeekDay,
  LoopDays,
  ExtractWidgetIds,
  IsNumber,
  CalculateDate,
  CleansingJSON,
  ConvertBytes,
  StudentIDFormat,
  EnrollmentStdType
};
