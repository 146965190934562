import React, { useState } from "react";
import FilterUI from "../../components/filters/FilterUI";
import {
  AcadTerm,
  Department,
  EmployeeSelect,
  StudentActivity,
} from "../../components/forms/SelectBox";
import { StudentID } from "../../components/forms/InputText";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";
import { MessageError, openModal } from "../../../../_layouts/components/modals/Modals";
import { AccessInvalid, RequiredMsg } from "../../../../_layouts/_core/ErrorMessage";
import { GET_STUDENT_ACTIVITY } from "../../apis/node-target-data/AcademicApi";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import { LoadingSkeleton, NoRecordFound } from "../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import TableData from "./TableData";
import { useOutletContext } from "react-router-dom";
import PackageJSON from "../../../../../package.json";

export function AcadActivity() {
  const context = useOutletContext();
  const widget_25 = context.widgets.find(
    (item) => item.widget_id === 25 && item.is_enabled === 1
  );
  const [activity_type, setActivity_type] = useState("");
  const [studentid, setStudentid] = useState("");
  const [clu_id, setClu_id] = useState("");
  const [atpid, setAtpid] = useState(PackageJSON.system_param.ActiveAcad);
  const [emp_id, setEmp_id] = useState(0);

  const postParam = {
    studentid,
    atpid,
    clu_id,
    activity_type,
    emp_id,
  };

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadData = async () => {
    setResults({loading:true, message:"", data:[]})
    const param = RemoveEmptyValue(postParam);
    const result = await GET_STUDENT_ACTIVITY(param);

    if(result.data && Object.values(result.data).length > 0){
      const row_result = result.data.filter(item => item.student_activity.id === activity_type);
      setResults({loading:false, message:"", data:row_result});
    }else{
      setResults(result);
    }

    
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const SubmitFilter = (e) => {
    e.preventDefault();
    if(postParam.activity_type && postParam.atpid){
      ReloadData();
    }else{
      openModal({message:<MessageError message={RequiredMsg} />})
    }
  };

  return widget_25 ? (
    <div className="row">
      <div className="col-lg-3">
        <FilterUI title="Student Activity" onSubmit={SubmitFilter} isload={results.loading}>
          <StudentActivity data={activity_type} setData={setActivity_type} required={true} />
          <AcadTerm data={atpid} setData={setAtpid} required={true} />
          <StudentID data={studentid} setData={setStudentid} />
          <Department data={clu_id} setData={setClu_id} />
          <EmployeeSelect data={emp_id} setData={setEmp_id} title={"Lecturer"} />
        </FilterUI>
      </div>
      <div className="col-lg-9">
      <SGUCard>
          <SGUCard.Header>
            <span className="card-label fw-bold text-gray-800">
              Browse Student Activity
            </span>
          </SGUCard.Header>
          <SGUCard.Body>
            {results.loading ? (
              <LoadingSkeleton />
            ) : results.message ? (
              <AlertNotif messages={results.message} />
            ) : Object.values(results.data).length > 0 ? (
              <TableData data={results.data} ReloadData={ReloadData} />
            ) : (
              <NoRecordFound />
            )}
          </SGUCard.Body>
        </SGUCard>
      </div>
    </div>
  ) : <AlertNotif messages={AccessInvalid} />;
}
