import React, { useEffect, useState } from 'react'
import { SGUCard } from '../../../../../_layouts/components/cards/CardUI';
import { ButtonItemRefresh } from '../../../../../_layouts/components/datatables/MYDatatables';
import { ButtonDownload, ButtonPrimary, ButtonTersier } from '../../../../../_layouts/components/buttons/ButtonUI';
import { AlertNotif } from '../../../../../_layouts/components/alerts/AlertsUI';
import { MessageError, MessageSuccess, openModal } from '../../../../../_layouts/components/modals/Modals';
import { RequiredMsg } from '../../../../../_layouts/_core/ErrorMessage';
import { SUBMIT_SCR_STD_BY_LEC_SCR_COM } from '../../../apis/node-target-data/AcademicApi';
import { DownloadScoreLetter } from '../DownloadScoreLetter';
import { LinkBtn } from '../../../../../_layouts/components/buttons/LinkUI';
import { StudentIDFormat } from '../../../../../_layouts/_helpers/Global';

export default function TableScoreLetter({ scores, section, ReloadData, widget_38 }) {
  const [scoreStudent, setScoreStudent] = useState([]);
  const [totalStudent, setTotalStudent] = useState(0);
  useEffect(() => {
    const initialScores = scores.student.map((student) => ({
      atd: student.atd || 0,
      remark: student.remark || "",
      studentid: student.studentid,
      fullname: student.studentdata
        ? student.studentdata.fullname
          ? student.studentdata.fullname
          : "-"
        : "-",
      score_letter: student.score_letter || "",
      course_status: student.course_status || "",
    }));

    const ascStudent = initialScores
      .sort((a, b) =>
        a.fullname > b.fullname ? 1 : -1
      );

    setTotalStudent(Object.values(ascStudent).length)
    setScoreStudent(ascStudent);
  }, [scores]);

  const isreadonly = scores.allowed === 1 ? false : true;
  //const isreadonly = false;

  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const [isDownload, setIsDownload] = useState(false);
  const HandlerDownload = () => {
    DownloadScoreLetter(section.section_id, setIsDownload)
  }

  const handleScoreChange = (studentId, status) => {
    setScoreStudent((prevScores) =>
      prevScores.map((student) =>
        student.studentid === studentId
          ? { ...student, score_letter: status }
          : student
      )
    );
  };

  const HandlerSubmit = async (e) => {
    e.preventDefault();
    const allStatusFilled = scoreStudent.every(
      (student) => student.score_letter !== ""
    );
    if (!allStatusFilled) {
      openModal({ message: <MessageError message={RequiredMsg} /> })
    } else {
      setSubmit({loading:true, message:"", data:[]});
      const objStd = scoreStudent.map((v) => {
        let obj = {};
        obj.studentid = v.studentid;
        obj.score_letter = v.score_letter;
        return obj;
      });
      const param = {
        section_id: section.section_id,
        student: objStd,
      };
      
      const result = await SUBMIT_SCR_STD_BY_LEC_SCR_COM(param);
      if(result.data && Object.values(result.data).length > 0){
        openModal({message:<MessageSuccess message={"Successfully saved"} />})
      }

      setSubmit(result);
    }
  }

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bold fs-3 mb-1">
          Submit Score Student
        </span>
        <span className="text-muted mt-1 fw-semibold fs-7">
          Student list for section {section.section_id}
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        <div className="d-flex">
          <div className="me-2">
            <ButtonItemRefresh
              totalItems={totalStudent}
              onClick={() => ReloadData()}
            />
          </div>
          {totalStudent > 0 && (
            <ButtonDownload disabled={isDownload} type="button" className="btn-sm" onClick={() => HandlerDownload()}>
              {isDownload ? "Processing..." : "Export to excel"}
            </ButtonDownload>
          )}
        </div>
      </SGUCard.Toolbar>
      <SGUCard.Body className={"px-0"}>
        {isreadonly && (
          <div className="px-8 mt-5">
            <AlertNotif
              title={"Grading Period Closed"}
              messages={"Grade submission has been closed. No further entries or edits can be made. Please contact the administration if you have any questions or require assistance."}
            />
          </div>
        )}

        <form method="post" onSubmit={(e) => HandlerSubmit(e)}>
          <div className="table-responsive">
            <table
              className="table table-row-bordered gy-4 gs-9 align-middle "
              id="table-component-score"
            >
              <thead>
                <tr className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bold text-uppercase">
                  <th>Student</th>
                  <th colSpan={2}>Score</th>
                  <th>Absent</th>
                  <th>Remark</th>
                  <th>Final Result</th>
                </tr>
              </thead>
              <tbody>
                {totalStudent > 0 ? (
                  scoreStudent.map((v, index) => (
                    <tr key={index} className={v.atd > 25 ? "bg-light-danger" : ""}>
                      <td>
                        <LinkBtn to={"/students/search/" + v.studentid} target="_blank" title="View Student">
                          <span className="d-block font-weight-bolder h5 text-dark text-hover-primary">
                            {v.fullname}
                          </span>
                          <span className="text-muted">{StudentIDFormat(v.studentid)}</span>
                        </LinkBtn>
                      </td>

                      {isreadonly || !widget_38 ? (
                        <td colSpan={2}>{v.score_letter}</td>
                      ) : (
                        <>
                          <td width={"10%"}>
                            <label>
                              <input
                                type="radio"
                                name={"score_" + v.studentid}
                                value={"PASS"}
                                checked={v.score_letter === "PASS"}
                                disabled={(v.atd > 25 ? true : false)}
                                onChange={() =>
                                  handleScoreChange(v.studentid, "PASS")
                                }
                              />{" "}
                              PASS
                            </label>
                          </td>
                          <td width={"10%"}>
                            <label>
                              <input
                                type="radio"
                                name={"score_" + v.studentid}
                                value={"FAIL"}
                                disabled={(v.atd > 25 ? true : false)}
                                checked={v.score_letter === "FAIL"}
                                onChange={() =>
                                  handleScoreChange(v.studentid, "FAIL")
                                }
                              />{" "}
                              FAIL
                            </label>
                          </td>
                        </>
                      )}

                      <td>{v.atd}%</td>
                      <td>{v.remark ? v.remark : ""}</td>
                      <td>{v.course_status}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={6}>No record found</td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                {submit.message && (
                  <tr>
                    <td colSpan={6}>
                      <AlertNotif messages={submit.message} />
                    </td>
                  </tr>
                )}
                <tr>
                  <td colSpan={6} className='text-end'>
                    {(!isreadonly && widget_38) && (
                    <div className="btn-group">
                      <ButtonTersier type="button" className="btn-lg" onClick={() => HandlerCancel()}>
                        Cancel
                      </ButtonTersier>
                      <ButtonPrimary type="submit" className="btn-lg" disabled={submit.loading}>
                        {submit.loading ? "Processing..." : "Save Changes"}
                      </ButtonPrimary>
                    </div>
                    )}
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </form>
      </SGUCard.Body>
    </SGUCard>
  )
}

const HandlerCancel = () => {
  if (confirm("Are you sure want to leave?") === true) {
    window.close();
  }
}
