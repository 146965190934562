import React, { useState } from 'react'
import { SGUCardFlush } from '../../../../../../../_layouts/components/cards/CardUI';
import { useOutletContext } from 'react-router-dom';
import { ButtonIcon } from '../../../../../../../_layouts/components/buttons/ButtonUI';
import { DELETE_SECTION } from '../../../../../apis/node-target-data/AcademicApi';
import UpdateStudyProgram from './UpdateStudyProgram';
import { NoRecordFound } from '../../../../../../../_layouts/components/loadbars/LoadingBars';

export function CardStudyProgram() {
    const context = useOutletContext();
    const data = context.data;
    const widgets = context.widget;
    const widget_10 = widgets.find(
        (item) => item.widget_id === 10 && item.is_enabled === 1
    );
    const widget_11 = widgets.find(
        (item) => item.widget_id === 11 && item.is_enabled === 1
    );

    const prody = data.prereq;
    const totalPrody = Object.values(prody).length;

    const [isopen, setIsopen] = useState(false);

    const RemoveData = async (id) => {
        const paramRemove = {
            deletetype: "prereq",
            reff_id: id,
            section_id: data.section_id,
        };
        const results = await DELETE_SECTION(paramRemove);

        if (results) {
            setInterval(() => {
                window.location.reload();
            }, 100);
        }
    }

    const HandlerRemove = (id) => {
        if (window.confirm("Are you sure want to remove this item ?") === true) {
            RemoveData(id);
        }
    }

    return (
        <SGUCardFlush>
            <SGUCardFlush.Header>
                <span className="card-label fw-bold text-gray-800">Study Program Qualification</span>
            </SGUCardFlush.Header>
            {widget_10 && (
                <SGUCardFlush.Toolbar>
                    <ButtonIcon title="Add New" onClick={()=>setIsopen(!isopen)} >
                        <i className={"ki-outline ki-notepad-edit fs-3"}></i>
                    </ButtonIcon>
                </SGUCardFlush.Toolbar>
            )}
            <SGUCardFlush.Body>
                {totalPrody > 0 ? (
                    prody.map((v, index) => (
                        <ItemPrody key={index} v={v} widget_11={widget_11} HandlerRemove={HandlerRemove} />
                    ))
                ) : <NoRecordFound />}

            {isopen &&  <UpdateStudyProgram section_id={data.section_id} setIsopen={setIsopen} /> }
            </SGUCardFlush.Body>
        </SGUCardFlush>
    )
}

const ItemPrody = ({ v, widget_11, HandlerRemove }) => {
    return (
        <>
            <div className="d-flex flex-stack">
                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                    <div className="flex-grow-1 me-2 w-50">
                        <span className="text-primary fs-6 fw-bold">
                            {v.clu && (v.clu.name || "")}
                        </span>
                        <div>
                            {v.clu_spec && (
                                <span className="text-muted fw-semibold d-block fs-7">
                                    {v.clu_spec && (v.clu_spec.name || "")}
                                </span>
                            )}
                            <span className="text-muted fw-semibold d-block fs-7">
                                Semester {v.semester} ({v.std_class})
                            </span>
                        </div>
                    </div>
                    {widget_11 && (
                        <ButtonIcon title="Remove" className="btn-sm btn-light btn-active-light-danger" onClick={() => HandlerRemove(v.id)}>
                            <i className="ki-outline ki-trash fs-5 m-0"></i>
                        </ButtonIcon>
                    )}
                </div>
            </div>
            <div className="separator separator-dashed my-3"></div>
        </>
    )
}