import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { KTIcon } from "../../../../../_metronic/helpers";
import {
  ButtonPrimary,
  ButtonTersier,
} from "../../../../../_layouts/components/buttons/ButtonUI";
import {
  DELETE_REQUEST_DOC,
  DOWNLOAD_REQUEST_DOC,
  GET_REQUEST_DOC,
  POST_REQUEST_DOC,
  POST_REQUEST_DOC_MULTIPLE,
} from "../../../apis/module/JupiterRequest";
import { Encryptor } from "../../components/Helper";
import Skeleton from "react-loading-skeleton";
import {
  LoadingSkeleton,
  NoRecordFound,
  SpinnerLoad,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import { useProvider } from "../../../../../_layouts/UMSLayoutProvider";
import { DoctypeIdentifier } from "./Helper";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { renderTooltip } from "../../../../../_layouts/components/popovers/PopOvers";

const ModalUploadDoc = ({ registered_id, triggerNotification }) => {
  const { account } = useProvider();
  const [previews, setPreviews] = useState({}); // preview file sblm upload
  const [zoom, setZoom] = useState({ state: false, seeker: "up" });
  const [docType, setDocType] = useState("");
  const [progressUploading, setProgressUploading] = useState(0);
  const [isSubmit, setIsSubmit] = useState(false);
  const [indexing, setIndexing] = useState({});
  const [acceptedFilesCopy, setAcceptedFilesCopy] = useState([]);
  const [previewFiles, setPreviewFiles] = useState(null); // preview file online
  const [isFileTooLarge, setIsFileTooLarge] = useState({
    state: false,
    message: "",
  });

  const [fileList, setFileList] = useState({
    loading: false,
    message: "",
    data: [],
  });

  // const onDrop = (acceptedFiles) => {
  //   // Handle setiap file yang diupload
  //   setAcceptedFilesCopy(acceptedFiles);
  // };
  const onDrop = useCallback((acceptedFiles) => {
    const validFiles = acceptedFiles.filter((file) => {
      const isValidType = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ].includes(file.type);

      const isValidSize = file.size <= 2 * 1024 * 1024; // 2MB

      if (!isValidType) {
        setIsFileTooLarge({
          state: true,
          message: `File type is not valid. Only jpg, png, jpeg, and pdf.`,
        });
        setTimeout(() => {
          setIsFileTooLarge({ state: false, message: "" });
        }, 5000);

        return false;
      } else if (!isValidSize) {
        setIsFileTooLarge({
          state: true,
          message: `File size is too large. Maximum file size is 2MB.`,
        });
        setTimeout(() => {
          setIsFileTooLarge({ state: false, message: "" });
        }, 5000);
        return false;
      }

      return true;
    });

    setAcceptedFilesCopy(validFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    // accept: {
    //   "application/pdf": [".pdf"],
    //   "image/jpeg": [".jpeg", ".jpg"],
    //   "image/png": [".png"],
    // },
  });

  useEffect(() => {
    if (acceptedFilesCopy) {
      acceptedFilesCopy.forEach((file) => {
        // Generate preview untuk setiap file
        generatePreview(file);
      });
    }
  }, [acceptedFilesCopy]);

  const generatePreview = (file) => {
    // Jika file adalah gambar
    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviews({
          [file.name]: {
            type: "image",
            url: reader.result,
          },
        });
      };
      reader.readAsDataURL(file);
    }
    // Untuk file PDF
    else if (file.type === "application/pdf") {
      const url = URL.createObjectURL(file);
      setPreviews({
        [file.name]: {
          type: "pdf",
          url: url,
        },
      });
    }
  };

  const PreviewComponent = ({
    file = { name: "img" },
    preview,
    zoom = false,
  }) => {
    switch (preview.type) {
      case "image":
        return (
          <img
            src={preview.url}
            alt={file.name}
            className="w-100 rounded"
            style={{ objectFit: "cover", height: zoom ? "auto" : 250 }}
          />
        );
      case "pdf":
        return (
          <iframe
            src={preview.url}
            className="w-100 rounded-lg"
            style={{ objectFit: "cover", height: zoom ? "100%" : 250 }}
            title={file.name}
          />
        );
      default:
        return null;
    }
  };

  const GetFileList = async () => {
    setFileList({ loading: true, message: "", data: [] });

    const encrypted = Encryptor({
      owner: registered_id,
    });

    const formData = new FormData();
    formData.append("data", encrypted);

    const lst = await GET_REQUEST_DOC(formData);
    const availableFiles = lst.data.filter(
      (f) => !f.status.includes("deleted")
    );
    setFileList({
      loading: false,
      message: lst.msg ?? "",
      data: availableFiles,
    });
    console.log("list -> ", lst);
  };

  const UploadFile = async () => {
    const encrypted = Encryptor({
      doc_type: `strg.doc.type.${docType}`,
      user: account.identity.email,
      owner: registered_id,
    });

    const formData = new FormData();
    formData.append("data", encrypted);
    formData.append("file", acceptedFilesCopy[0]);

    const up = await POST_REQUEST_DOC_MULTIPLE(formData, (progress) => {
      if (progress < 95) {
        setProgressUploading(progress);
      }
    });

    console.log("upload -> ", up);
    if (up.data?.result === "success") {
      triggerNotification({
        title: "Success",
        type: "success",
        message: "Success upload file",
        duration: 3000,
      });
      setProgressUploading(100);
      setAcceptedFilesCopy([]);
      setPreviews({});
      setIsSubmit(false);
      setDocType("");
      GetFileList();
    } else {
      triggerNotification({
        title: "Failed!",
        type: "danger",
        message: up?.error?.msg,
        duration: 3000,
      });
      setProgressUploading(0);
      setIsSubmit(false);
    }
  };

  const DownloadFile = async (index, file_name, file_id) => {
    setIndexing({ index: index, download: true });

    const encrypted = Encryptor({
      file_id: file_id,
      user: account.identity.email,
    });

    const formData = new FormData();
    formData.append("data", encrypted);
    formData.append("file", acceptedFilesCopy[0]);

    const down = await DOWNLOAD_REQUEST_DOC(formData);
    console.log("down -> ", down);
    setIndexing({ index: index, download: false });

    const url = URL.createObjectURL(down.data);
    const a = document.createElement("a"); // Membuat elemen <a> untuk download
    a.href = url;
    a.download = `${file_name}.jpg`; // Nama file saat diunduh
    document.body.appendChild(a); // Menambahkan elemen ke DOM
    a.click(); // Memicu download
    document.body.removeChild(a); // Menghapus elemen setelah download
  };

  const PreviewFile = async (index, file_id) => {
    setIndexing({ index: index, preview: true });

    const encrypted = Encryptor({
      file_id: file_id,
      user: account.identity.email,
    });

    const formData = new FormData();
    formData.append("data", encrypted);
    formData.append("file", acceptedFilesCopy[0]);

    const down = await DOWNLOAD_REQUEST_DOC(formData);
    console.log("down -> ", down);
    setIndexing({ index: index, preview: false });
    const url = URL.createObjectURL(down.data); // Membuat URL untuk blob
    console.log(url);
    if (down.data.type.startsWith("image/")) {
      setPreviewFiles({
        type: "image",
        url: url,
      });
    } else {
      setPreviewFiles({
        type: "pdf",
        url: url,
      });
    }
    setZoom({ state: true, seeker: "prev" });
  };

  const DeleteFile = async (data) => {
    console.log("object deleted ", data);
    setIndexing({ index: data.index, delete: true });
    const encrypted = Encryptor({
      file_id: data.file_id,
      user: account.identity.email,
    });

    const formData = new FormData();
    formData.append("data", encrypted);

    const del = await DELETE_REQUEST_DOC(formData);
    console.log("del -> ", del);

    triggerNotification({
      title: del.msg !== null ? "Failed!" : "Success",
      type: del.msg !== null ? "danger" : "success",
      message: del.msg !== null || "Success delete file",
      duration: 3000,
    });

    setIndexing({ index: data.index, delete: false });
    if (del.result === "success") {
      GetFileList();
    }
  };

  const handleSubmit = () => {
    setIsSubmit(true);
    UploadFile();
  };

  useEffect(() => {
    GetFileList();
  }, []);

  useEffect(() => {
    if (acceptedFilesCopy.length > 0) {
      const type = acceptedFilesCopy[0].name.toLowerCase();
      if (type.includes("ktp")) {
        setDocType("KTP");
      } else {
        setDocType("");
      }
    }
  }, [acceptedFilesCopy]);

  const DeleteConfirmation = ({ iconName, data }) => {
    return (
      <OverlayTrigger
        trigger="click"
        placement="right"
        rootClose
        overlay={
          <Popover className="p-3">
            <Popover.Body className="p-0">
              <p className="fw-bold">
                <span className="text-danger">*</span>Deleted file can not be
                restore
              </p>
              <div
                className="cursor-pointer bg-hover-light-danger text-hover-danger ps-3 pe-5 py-3 rounded d-flex align-items-center"
                onClick={() => DeleteFile(data)}
              >
                <KTIcon iconName="information-5" className="fs-3 me-2" />
                <span>Confirm Delete</span>
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <div>
          <KTIcon
            iconName={iconName}
            className={`fs-1 cursor-pointer text-hover-danger me-3`}
          />
        </div>
      </OverlayTrigger>
    );
  };

  const MenuDocList = ({ iconName, title, data }) => {
    return (
      <OverlayTrigger
        trigger="hover"
        placement="top"
        overlay={renderTooltip({ title })}
      >
        <div>
          {iconName === "trash" ? (
            <DeleteConfirmation iconName={iconName} data={data} />
          ) : (
            <KTIcon
              iconName={iconName}
              className={`fs-1 cursor-pointer text-hover-${
                iconName === "trash" ? "danger" : "primary"
              } me-3`}
            />
          )}
        </div>
      </OverlayTrigger>
    );
  };

  return (
    <div>
      <div
        className="accordion accordion-icon-collapse"
        id="kt_accordion_list_doc"
      >
        <div className="mb-5">
          <div
            className="accordion-header py-3 d-flex collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#kt_accordion_3_item_1"
          >
            <span className="accordion-icon">
              <i className="ki-duotone ki-plus-square fs-3 accordion-icon-off">
                <span className="path1"></span>
                <span className="path2"></span>
                <span className="path3"></span>
              </i>
              <i className="ki-duotone ki-minus-square fs-3 accordion-icon-on">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
            </span>
            <h3 className="fs-4 fw-semibold mb-0 ms-4">Document List</h3>
          </div>

          <div
            id="kt_accordion_3_item_1"
            className="fs-6 collapse ps-10 pe-5"
            data-bs-parent="#kt_accordion_list_doc"
          >
            <div className="row overflow-auto" style={{ maxHeight: 250 }}>
              {fileList.loading ? (
                <LoadingSkeleton />
              ) : fileList.message ? (
                <AlertNotif messages={fileList.message} color="danger" />
              ) : fileList.data.length > 0 ? (
                <div className="col-12 mb-5">
                  <div className="mt-5">
                    <div className="space-y-1">
                      {fileList.data.map((file, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center justify-content-between bg-gray-50 rounded-lg mb-3 border-bottom"
                        >
                          {/* icon */}
                          <div className="d-flex">
                            <div className="mt-2 me-2 fs-3 text-muted">
                              {index + 1}
                            </div>
                            <div
                              className="position-relative me-3"
                              style={{ width: 40, height: 40 }}
                            >
                              <KTIcon
                                iconName="folder"
                                className={`fs-3x ${
                                  file.filename
                                    .split(".")
                                    .at(-1)
                                    .toLowerCase() === "pdf"
                                    ? "text-info"
                                    : "text-primary"
                                }`}
                              />
                              <p
                                className="text-white"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              >
                                {file.filename.split(".").at(-1)}
                              </p>
                            </div>
                            {/* file name & size */}
                            <div className="d-flex flex-column">
                              <span className="fs-5 fw-bold">
                                {file.filename.slice(0, 10)}
                                {file.filename.length > 10 ? "..." : ""}
                              </span>
                              <span className="text-gray-500">
                                {DoctypeIdentifier(file.doc_type)}
                              </span>
                            </div>
                          </div>
                          {/* icons & type */}
                          <div className="d-flex align-items-center">
                            <div
                              onClick={() =>
                                DownloadFile(index, file.filename, file.file_id)
                              }
                            >
                              {indexing.index === index && indexing.download ? (
                                <div className="me-5">
                                  <SpinnerLoad />
                                </div>
                              ) : (
                                <MenuDocList
                                  iconName="folder-down"
                                  title="Download"
                                />
                              )}
                            </div>
                            <div
                              onClick={() => PreviewFile(index, file.file_id)}
                              className="me-3"
                            >
                              {indexing.index === index && indexing.preview ? (
                                <div className="me-5 ms-1">
                                  <SpinnerLoad />
                                </div>
                              ) : (
                                <MenuDocList iconName="size" title="Preview" />
                              )}
                            </div>
                            <div>
                              {indexing.index === index && indexing.delete ? (
                                <div className="me-5 ms-1">
                                  <SpinnerLoad />
                                </div>
                              ) : (
                                <MenuDocList
                                  iconName="trash"
                                  title="Delete"
                                  data={{ index: index, file_id: file.file_id }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <NoRecordFound />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="separator border-primary mb-5"></div>

      {/* load file and preview */}
      {Object.keys(previews).length > 0 ? (
        <div className="">
          {Object.entries(previews).map(([fileName, preview]) => {
            const file = { name: fileName };
            return (
              <div
                key={fileName}
                className="bg-gray-50 rounded-lg position-relative"
              >
                <div
                  className="cursor-pointer position-absolute d-flex align-items-center justify-content-center"
                  style={{
                    right: 10,
                    top: 10,
                    borderRadius: 49,
                    height: 35,
                    width: 35,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
                  title="close"
                  onClick={() => {
                    setPreviews({});
                    setAcceptedFilesCopy([]);
                    setDocType("");
                  }}
                >
                  <i class="ki-duotone ki-cross-circle fs-2x">
                    <span class="path1 text-white"></span>
                    <span class="path2 text-white"></span>
                  </i>
                </div>
                <div
                  className="cursor-pointer position-absolute"
                  style={{
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
                  title="Enlarge"
                  onClick={() => {
                    setZoom({ state: true, seeker: "up" });
                  }}
                >
                  <KTIcon
                    iconName="arrow-two-diagonals"
                    className="fs-5x text-white"
                  />
                </div>
                <div className="w-100 h-100">
                  <PreviewComponent file={file} preview={preview} />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={`border-2 border-dashed rounded p-8 text-center cursor-pointer
          ${
            isDragActive
              ? "border-blue-500 bg-blue-50"
              : "border-gray-300 hover:border-gray-400"
          }`}
        >
          <input {...getInputProps()} />

          <i
            class={`ki-duotone ki-folder-up ${
              isDragActive ? "text-primary" : "text-gray-500"
            }`}
            style={{ fontSize: 120 }}
          >
            <span class="path1"></span>
            <span class="path2"></span>
          </i>

          <div className="text-lg font-medium text-muted mt-5">
            {isDragActive ? (
              <h5>Drop it!</h5>
            ) : isFileTooLarge.state ? (
              <AlertNotif messages={isFileTooLarge.message} color="danger" />
            ) : (
              <>
                <h5>Drag & drop or click to browse your file</h5>
                <span className="fs-6 fw-light">
                  Supports: JPG, JPEG, PNG & PDF. Max size 2MB
                </span>
              </>
            )}
          </div>
        </div>
      )}

      {/* selected file */}
      {acceptedFilesCopy.length > 0 && (
        <div className="mt-5">
          <div className="space-y-1">
            {acceptedFilesCopy.map((file, index) => (
              <div
                key={index}
                className="d-flex items-center justify-between bg-gray-50 rounded-lg"
              >
                <div
                  className="position-relative me-3"
                  style={{ width: 40, height: 40 }}
                >
                  <KTIcon
                    iconName="folder"
                    className={`fs-3x ${
                      file.name
                        .split(".")
                        .at(-1)
                        .toLowerCase() === "pdf"
                        ? "text-info"
                        : "text-primary"
                    }`}
                  />
                  <p
                    className="text-white"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {file.name.split(".").at(-1)}
                  </p>
                </div>
                <div className="d-flex flex-column">
                  <span className="fs-5 fw-bold">
                    {file.name.slice(0, 10)}
                    {file.name.length > 10 ? "..." : ""}
                  </span>
                  <span className="text-gray-500">
                    {(file.size / (1024 * 1024)).toFixed(2)} MB
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* type */}
      <div className="mt-5">
        <p className="fw-bold m-0">Doc type:</p>
        <div className="d-flex">
          <div className="form-check form-check-sm form-check-custom form-check-solid me-5">
            <input
              className={`form-check-input cursor-pointer`}
              type="checkbox"
              checked={docType === "ktp"}
              id="ktp"
              onClick={() => setDocType("ktp")}
            />
            <label className="form-check-label" for="ktp">
              KTP
            </label>
          </div>
          <div className="form-check form-check-sm form-check-custom form-check-solid me-5">
            <input
              className={`form-check-input cursor-pointer`}
              type="checkbox"
              checked={docType === "kk"}
              id="kk"
              onClick={() => setDocType("kk")}
            />
            <label className="form-check-label" for="kk">
              KK
            </label>
          </div>
          <div className="form-check form-check-sm form-check-custom form-check-solid me-5">
            <input
              className={`form-check-input cursor-pointer`}
              type="checkbox"
              checked={docType === "ijazah"}
              id="ijazah"
              onClick={() => setDocType("ijazah")}
            />
            <label className="form-check-label" for="ijazah">
              IJAZAH
            </label>
          </div>
          <div className="form-check form-check-sm form-check-custom form-check-solid me-5">
            <input
              className={`form-check-input cursor-pointer`}
              type="checkbox"
              checked={docType === "rapor"}
              id="rapor"
              onClick={() => setDocType("rapor")}
            />
            <label className="form-check-label" for="rapor">
              RAPOR
            </label>
          </div>
          <div className="form-check form-check-sm form-check-custom form-check-solid me-5">
            <input
              className={`form-check-input cursor-pointer`}
              type="checkbox"
              checked={docType === "other"}
              id="other"
              onClick={() => setDocType("other")}
            />
            <label className="form-check-label" for="other">
              <input
                type="text"
                placeholder="Other type"
                onFocus={() => setDocType("other")}
                className="w-100 form-control form-control-sm"
              />
            </label>
          </div>
        </div>
      </div>

      {/* button submit and loading */}
      <div
        className={`card-body d-flex align-items-end pt-0 mt-5 ${
          docType === ""
            ? "opacity-50"
            : !isSubmit
            ? "opacity-100 cursor-pointer"
            : "opacity-100 cursor-not-allowed"
        }`}
        onClick={() => (!isSubmit && docType !== "" ? handleSubmit() : null)}
      >
        <div className="d-flex align-items-center flex-column mt-3 w-100 position-relative">
          <div
            className={`d-flex justify-content-between align-items-center mt-auto mb-2 fs-4 position-absolute ${
              progressUploading > 45 ? "text-white" : "text-white"
            } ${
              isSubmit
                ? progressUploading < 50
                  ? "text-gray-700"
                  : "text-white"
                : "text-white"
            }`}
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {isSubmit ? <span>{progressUploading}%</span> : <span>Submit</span>}
          </div>

          <div
            className="mx-3 w-100 bg-primary bg-opacity-25 rounded"
            style={{ height: 45 }}
          >
            <div
              className="bg-primary rounded"
              role="progressbar"
              style={{
                width: `${isSubmit ? progressUploading : 100}%`,
                height: 45,
              }}
              aria-valuenow={50}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
        </div>
      </div>

      {/* large preview */}
      {zoom.state && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            // display: previewModal? "block" : "none",
            zIndex: 100,
          }}
        >
          <div
            style={{
              width: "90%",
              height: "90%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "10px",
            }}
          >
            {previewFiles || zoom.seeker === "up" ? (
              <div className="bg-gray-50 rounded-lg position-relative w-100 h-100">
                <div
                  className="cursor-pointer position-absolute d-flex align-items-center justify-content-center"
                  style={{
                    right: 10,
                    top: 10,
                    borderRadius: 49,
                    height: 40,
                    width: 40,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
                  title="close"
                  onClick={() => {
                    setZoom({ state: false, seker: "up" });
                  }}
                >
                  <i class="ki-duotone ki-cross-circle fs-2x">
                    <span class="path1 text-white"></span>
                    <span class="path2 text-white"></span>
                  </i>
                </div>
                <div
                  className="w-100 h-100"
                  style={{ overflowY: "auto", scrollbarWidth: "none" }}
                >
                  {zoom.seeker === "up" ? (
                    Object.entries(previews).map(([fileName, preview]) => {
                      const file = { name: fileName };
                      return (
                        <PreviewComponent
                          file={file}
                          preview={preview}
                          zoom={zoom.state}
                        />
                      );
                    })
                  ) : (
                    <PreviewComponent
                      preview={previewFiles}
                      zoom={zoom.state}
                    />
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalUploadDoc;
