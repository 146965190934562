import { useEffect, useState } from "react";
import Select from "react-select";

import { LoadingBars } from "../../../../_layouts/components/loadbars/LoadingBars";
import {
  AlertNotif,
  AlertRetry,
} from "../../../../_layouts/components/alerts/AlertsUI";
import {
  GET_DEPARTMENT,
  GET_ENROLLMENT_PROCESS,
  GET_YEARINTAKE,
} from "../../apis/node-target-data/MarketingAPI";
import {
  GET_LOOK_UP_COUNTRY,
  GET_PROVINCE_LOOKUP,
  GET_UNIV_LOOKUP,
} from "../../apis/node-target-data/IdentityApi";
import { GET_ACAD_LOOKUP } from "../../apis/node-target-data/AcademicApi";

const customStyles = {
  menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

const GenderList = ({ data, setData, disabled = false }) => {
  const list = [
    {
      value: "p",
      label: "Male",
    },
    {
      value: "w",
      label: "Female",
    },
  ];
  const [selected, setSelected] = useState(
    list.find((v) => v.value === data) || ""
  );

  const HandlerChange = (value) => {
    setSelected(value);
    setData({
      e: { target: { name: "gender", value: value.value } },
      state: "biodata",
    });
  };

  return (
    <div className="form-group mb-5">
      <Select
        name="course_owner"
        classNamePrefix="react-select"
        value={selected}
        onChange={HandlerChange}
        isDisabled={disabled}
        options={list}
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
    </div>
  );
};

const ReligionList = ({ data, setData, disabled = false }) => {
  let religion_list = [
    {
      value: 1,
      label: "Islam",
    },
    {
      value: 2,
      label: "Catholic",
    },
    {
      value: 3,
      label: "Christian",
    },
    {
      value: 4,
      label: "Hindu",
    },
    {
      value: 5,
      label: "Buddist",
    },
    {
      value: 7,
      label: "Konghuchu",
    },
    {
      value: 6,
      label: "Other",
    },
  ];
  const [selected, setSelected] = useState(
    religion_list.find((v) => v.value === data) || ""
  );

  const HandlerChange = (value) => {
    setSelected(value);
    setData({
      e: { target: { name: "religion", value: value.value } },
      state: "biodata",
    });
  };

  return (
    <div className="form-group mb-5">
      <Select
        name="course_owner"
        classNamePrefix="react-select"
        value={selected}
        onChange={HandlerChange}
        isDisabled={disabled}
        options={religion_list}
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
    </div>
  );
};

const YearIntakeList = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(
    list.find((v) => v.value === data) || {
      value: 27,
      label: 2025,
    }
  );

  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const getListYearIntake = async () => {
    setResult({ loading: true, message: "", data: [] });
    const List = await GET_YEARINTAKE();
    setResult(List);
    if (List.message === "") {
      const option_result = List.data.map((v, i) => ({
        value: v.id,
        label: v.year,
      }));
      setList(option_result);
    }
  };

  const CustomOption = ({ innerProps, data, selectProps }) => (
    <div
      {...innerProps}
      className={`d-flex justify-content-between px-3 py-2 ${
        selectProps.value.value === data.value
          ? "bg-primary"
          : "bg-hover-light-primary"
      }`}
    >
      <span
        className={`${selectProps.value.value === data.value && "text-white"}`}
      >
        {data.label}
      </span>
    </div>
  );

  useEffect(() => {
    getListYearIntake();
  }, []);

  useEffect(() => {
    if (list.length > 0) {
      setSelected(
        list.find((v) => v.value === data) || {
          value: 27,
          label: 2025,
        }
      );
    }
  }, [list]);

  const HandlerChange = (value) => {
    setSelected(value);
    setData(value.value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Year Intake"}
      </label>
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry
          messages={result.message}
          Reload={() => getListYearIntake()}
        />
      ) : (
        <Select
          name="year_intake"
          classNamePrefix="react-select"
          value={selected}
          onChange={HandlerChange}
          isDisabled={disabled}
          options={list}
          placeholder="Choose a year intake"
          components={{ Option: CustomOption }}
        />
      )}
    </div>
  );
};

const YearIntakeListFilter = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(
    list.find((v) => v.value === data) || {
      value: 27,
      label: 2025,
    }
  );

  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });

  // Fungsi untuk membuat delay
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const total = async (data) => {
    const startYear = 26;
    const endYear = data[0].id;

    const dynamicData = [];
    for (let year = startYear; year <= endYear; year++) {
      dynamicData.push(year);
    }

    const listTotal = [];
    for (const x of dynamicData) {
      await delay(100);
      const aha = await GET_ENROLLMENT_PROCESS({ yearintake: x });
      listTotal.push({ yearintake: x, total: aha.data.length });
    }

    return listTotal.reverse();
  };

  const getListYearIntake = async () => {
    setResult({ loading: true, message: "", data: [] });
    const List = await GET_YEARINTAKE();
    if (List.message === "") {
      const a = await total(List.data);
      console.log("a ", a);
      setResult(List);

      const option_result = List.data.map((v, i) => ({
        value: v.id,
        label: v.year,
        emoji:
          <span className="badge badge-light-primary">{a[i]?.total}</span> ??
          "",
      }));
      setList(option_result);
    }
  };

  const CustomOption = ({ innerProps, data, selectProps }) => (
    <div
      {...innerProps}
      className={`d-flex justify-content-between px-3 py-2 ${
        selectProps.value.value === data.value
          ? "bg-primary"
          : "bg-hover-light-primary"
      }`}
    >
      <span
        className={`${selectProps.value.value === data.value && "text-white"}`}
      >
        {data.label}
      </span>
      <span>{data.emoji}</span>
    </div>
  );

  useEffect(() => {
    getListYearIntake();
  }, []);

  useEffect(() => {
    if (list.length > 0) {
      setSelected(
        list.find((v) => v.value === data) || {
          value: 27,
          label: 2025,
        }
      );
    }
  }, [list]);

  const HandlerChange = (value) => {
    setSelected(value);
    setData(value.value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Year Intake"}
      </label>
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry
          messages={result.message}
          Reload={() => getListYearIntake()}
        />
      ) : (
        <Select
          name="year_intake"
          classNamePrefix="react-select"
          value={selected}
          onChange={HandlerChange}
          isDisabled={disabled}
          options={list}
          placeholder="Choose a year intake"
          components={{ Option: CustomOption }}
        />
      )}
    </div>
  );
};

const ProvinceList = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [inputValue, setInputValue] = useState(data || "");
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async (param) => {
    console.log("province param", param);
    const result = await GET_PROVINCE_LOOKUP(param);
    console.log("province ", result);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id,
        label: v.name,
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  const FindData = (inputValue) => {
    if (inputValue.length >= 3) {
      ReloadData({ search: inputValue });
    }
    setInputValue(inputValue);
  };

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Province"}
      </label>
      {result.message && <AlertNotif messages={result.message} />}
      <Select
        name="country_id"
        classNamePrefix="react-select"
        value={selectedOption}
        onChange={HandleChange}
        isDisabled={disabled}
        options={options}
        placeholder="Type to find province list"
        onInputChange={FindData}
        inputValue={inputValue}
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
    </div>
  );
};

const CountryCitizenshipList = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
  type = "id",
}) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [inputValue, setInputValue] = useState(data || "");
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async (param) => {
    const result = await GET_LOOK_UP_COUNTRY(param);
    console.log("countries ", result);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id,
        label: title !== "Citizenship" ? v.name : v.citizenship,
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  const FindData = (inputValue) => {
    if (inputValue.length >= 3) {
      ReloadData({ search: inputValue });
    }
    setInputValue(inputValue);
  };

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(type === "id" ? option.value : option.label);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Country"}
      </label>
      {result.message && <AlertNotif messages={result.message} />}
      <Select
        name="country_id"
        classNamePrefix="react-select"
        value={selectedOption}
        onChange={HandleChange}
        isDisabled={disabled}
        options={options}
        placeholder="Type to find country list"
        onInputChange={FindData}
        inputValue={inputValue}
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
    </div>
  );
};

const DepartmentEnrollment = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });
    const result = await GET_DEPARTMENT();

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data
        .filter((v) => v.isactive)
        .map((v) => ({
          value: v.id,
          label: v.name,
        }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandleChange = (option) => {
    if (option === null) {
      setSelectedOption(null);
      setSelected(null);
      setData(null);
    } else {
      setSelectedOption(option);
      setSelected(option.value);
      setData(option.value);
    }
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Student Department"}
      </label>
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <Select
            name="std_dept_enroll"
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={HandleChange}
            isDisabled={disabled}
            options={options}
            isClearable
            placeholder="Choose Department"
          />
        )
      )}
    </div>
  );
};

const UnivList = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [inputValue, setInputValue] = useState(data || "");
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async (param) => {
    const result = await GET_UNIV_LOOKUP(param);

    console.log("univ ", result);

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.id,
        label: v.name,
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  const FindData = (inputValue) => {
    if (inputValue.length >= 3) {
      ReloadData({ search: inputValue });
    }
    setInputValue(inputValue);
  };

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "University Name"}
      </label>
      {result.message && <AlertNotif messages={result.message} />}
      <Select
        name="univ_name"
        classNamePrefix="react-select"
        value={selectedOption}
        onChange={HandleChange}
        isDisabled={disabled}
        options={options}
        placeholder="Select a University"
        onInputChange={FindData}
        inputValue={inputValue}
        styles={customStyles}
        menuPortalTarget={document.body}
        menuPosition="fixed"
      />
    </div>
  );
};

const AcadList = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const [list, setList] = useState([]);
  const [selected, setSelected] = useState(
    list.find((v) => v.value === data) || {}
  );

  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const getListYearIntake = async () => {
    setResult({ loading: true, message: "", data: [] });
    const List = await GET_ACAD_LOOKUP();
    setResult(List);
    if (List.message === "") {
      const option_result = List.data.map((v, i) => ({
        value: v.id,
        label: v.name,
      }));
      setList(option_result);
    }
  };

  const CustomOption = ({ innerProps, data, selectProps }) => (
    <div
      {...innerProps}
      className={`d-flex justify-content-between px-3 py-2 ${
        selectProps.value.value === data.value
          ? "bg-primary"
          : "bg-hover-light-primary"
      }`}
    >
      <span
        className={`${selectProps.value.value === data.value && "text-white"}`}
      >
        {data.label}
      </span>
    </div>
  );

  useEffect(() => {
    getListYearIntake();
  }, []);

  useEffect(() => {
    if (list.length > 0) {
      setSelected(list.find((v) => v.value === data) || {});
    }
  }, [list]);

  const HandlerChange = (value) => {
    setSelected(value);
    setData(value.value);
  };

  return (
    <div className="form-group w-100 h-100 me-3">
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry
          messages={result.message}
          Reload={() => getListYearIntake()}
        />
      ) : (
        <Select
          name="year_intake"
          classNamePrefix="react-select"
          value={selected}
          onChange={HandlerChange}
          isDisabled={disabled}
          options={list}
          placeholder="Choose a year intake"
          components={{ Option: CustomOption }}
          menuPortalTarget={document.body} // Menggunakan portal untuk menempatkan dropdown di body
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }), // Menetapkan z-index agar dropdown di atas elemen lain
          }}
        />
      )}
    </div>
  );
};

export {
  GenderList,
  ReligionList,
  YearIntakeList,
  YearIntakeListFilter,
  CountryCitizenshipList,
  DepartmentEnrollment,
  ProvinceList,
  UnivList,
  AcadList,
};
