import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Error404 } from "../../../errors/components/Error404";
import { DataProposalSample } from "../components/ConstantData";
import { GET_ENROLLMENT_PROCESS } from "../../../apis/node-target-data/MarketingAPI";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import Forms from "./components/Forms";
import { LinkBtn } from "../../../../../_layouts/components/buttons/LinkUI";
import { KTIcon } from "../../../../../_metronic/helpers";
import { GET_STUDENT, GET_STUDENT_DATA_M, GET_TRANSFER_PROPOSAL } from "../../../apis/node-target-data/AcademicApi";

export function FormProposalRegis() {
  const params = useParams();
  const token = params.proposal_id;
  const navigate = useNavigate();

  const Back2Page = () => {
    const uribackto = "/course-managements/transfer-credit-registration";
    navigate(uribackto);
  };

  useEffect(() => {
    //BACK HANDLER
    const handleBackButton = (event) => {
      event.preventDefault();
      Back2Page();
    };

    // Refresh handler
    const handleBeforeUnload = (event) => {
      const message =
        "Are you sure you want to leave this page? Your changes may not be saved.";
      event.preventDefault();
      event.returnValue = message;
      return message;
    };

    window.history.pushState(null, null, window.location.href);

    window.addEventListener("popstate", handleBackButton);
    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    //END BACK
  }, [navigate]);

  try {
    const proposal_id = atob(token);
    return <DetailPage proposal_id={proposal_id} />;
  } catch (error) {
    return <Error404 />;
  }
}

const DetailPage = ({ proposal_id }) => {
  const [proposal, setProposal] = useState({ loading: true, data: {}, message: "" });
  const ReloadProposal = async () => {
    setProposal({ loading: true, data: [], message: "" });
    const param = { proposal_id:parseInt(proposal_id) };
    const result = await GET_TRANSFER_PROPOSAL(param);
    if (result.data && Object.values(result.data).length > 0) {
      const findProposalByid = result.data.find((r) => r.id === parseInt(proposal_id));
      setProposal({ loading: false, data: findProposalByid, message: "" });
      const candidate_id = findProposalByid.candidate_id;
      console.log(candidate_id);
      ReloadDataCandidate(candidate_id);      
    } else {
      setProposal(result);
    }
  }

  const [candidates, setCandidates] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadDataCandidate = async (candidate_id) => {
    setCandidates({ loading: true, data: [], message: "" });
    const param = { candidate_id };
    const result = await GET_ENROLLMENT_PROCESS(param);
    if (result.data && Object.values(result.data).length > 0) {
      const r_data = result.data[0];
      const data_candidate = {
        candidate_name: r_data?.regstudent?.fullname || "-",
        registered_id: r_data?.candidate_id || "-",
        last_semester: r_data?.regstudent?.university_last_semester || "-",
        enroll_semester: r_data?.enroll_semester || "-",
        create_student: r_data?.create_student || 0,
        target_department: r_data?.target_department?.name
      }
      setCandidates({ loading: false, data: data_candidate, message: "" });
    } else {
      const param_std = { studentid: candidate_id };
      const result_student = await GET_STUDENT(param_std);
      const result_student_bio = await GET_STUDENT_DATA_M(param_std);
      
      if((result_student.data && Object.values(result_student.data).length > 0) && (result_student_bio.data && Object.values(result_student_bio.data).length > 0)) {
        const student_data = result_student.data[0];
        const student_bio_data = result_student_bio.data;
        const combined_student_data = {
            ...student_bio_data,
            ...student_data
        };
        let last_semester = 0;
        if(combined_student_data.reff_ins){
          const find_prev_univ = combined_student_data.reff_ins.find(item => Object.values(item.university).length > 0);
          last_semester = find_prev_univ?.lastsemester || 0;
        }
        const data_candidate = {
          candidate_name: combined_student_data?.fullname || "-",
          registered_id: combined_student_data?.studentid || "-",
          last_semester: last_semester,
          enroll_semester: combined_student_data?.semester || "-",
          create_student: 1,
          target_department: combined_student_data?.clu?.name || "-"
        }
        setCandidates({ loading: false, data: data_candidate, message: "" });
      }
      
    }
  };

  useEffect(() => {
    ReloadProposal();
  }, [proposal_id]);

  return proposal.loading ? <LoadingSkeleton /> :
    proposal.message ? <AlertNotif messages={proposal.message} /> :
      Object.values(proposal.data).length > 0 ? (
        <div>
          <div className="page-title d-flex flex-column justify-content-center flex-wrap mb-8 ">
            <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
              Transfer Credit Registration
            </h1>

            <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
              <li className="breadcrumb-item text-muted">
                <LinkBtn
                  to={"/course-managements/transfer-credit-registration"}
                  className="d-flex align-items-center justify-content-center text-muted text-hover-primary"
                >
                  <KTIcon iconName="arrow-left" className="fs-3 me-1" />
                  Back
                </LinkBtn>
              </li>
            </ul>
          </div>
          {candidates.loading ? (
            <LoadingSkeleton />
          ) : candidates.message ? (
            <AlertNotif messages={candidates.message} />
          ) : Object.values(candidates.data).length > 0 ? (
            <Forms candidate={candidates.data} proposal={proposal.data} />
          ) : (
            <NoRecordFound />
          )}
        </div>
      ) : <NoRecordFound />
};
