import { Link } from "react-router-dom"
import { SGUCardFlush } from "../../../../../_layouts/components/cards/CardUI"
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI"

const CardTitle = ({data}) =>{
    return (
        <SGUCardFlush>
            <SGUCardFlush.Body className={"pt-8"}>
                {data.final_title ? (
                    <h2>{data.final_title}</h2>
                ) : (
                    <AlertNotif messages={"No thesis title submitted yet."} color="primary" />
                )}
                <div className="d-flex justify-content-between align-items-center">
                    {data.advisor && (
                        <div className="m-0">
                            <span className="fw-semibold text-gray-500 d-block fs-8">Advisor</span>
                            <Link to={"/employee/"+btoa(data.advisor.id)} className="fw-bold text-gray-800 text-hover-primary fs-5">{data.advisor && (data.advisor.fullname || "")}</Link>
                        </div>
                    )}

                    {data.coadvisor && (
                        <div className="m-0">
                            <span className="fw-semibold text-gray-500 d-block fs-8">Co-Advisor</span>
                            <Link to={"/employee/"+btoa(data.coadvisor.id)} className="fw-bold text-gray-800 text-hover-primary fs-5">{data.coadvisor && (data.coadvisor.fullname || "")}</Link>
                        </div>
                    )}
                </div>
            </SGUCardFlush.Body>
        </SGUCardFlush>
    )
}

export {CardTitle}