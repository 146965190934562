import forge from "node-forge";

const publicKeyRSA = process.env.REACT_APP_RSA_PUBLIC_KEY;
const publicKeyPEM = `
      -----BEGIN PUBLIC KEY-----
${publicKeyRSA}
      -----END PUBLIC KEY-----
    `;

const RSAencryptData = (data) => {
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPEM);

    const encrypted = publicKey.encrypt(
      forge.util.encodeUtf8(data),
      "RSA-OAEP",
      {
        md: forge.md.sha256.create(),
        mgf1: {
          md: forge.md.sha256.create(),
        },
      }
    );
    const encryptedBase64 = forge.util.encode64(encrypted);

    return encryptedBase64;
};

export {RSAencryptData}