import { useCallback, useState } from "react";
import Toaster from "./Toaster";

const useToaster = () => {
  const [toaster, setToaster] = useState([]);

  const triggerNotification = useCallback((notificationProps) => {
    const id = Date.now();
    setToaster((prevToaster) => {
      const updatedToaster = [
        ...(prevToaster || []),
        { ...notificationProps, id },
      ];
      return updatedToaster.sort((a, b) => b.id - a.id);
    });

    setTimeout(() => {
      setToaster((prevToaster) => {
        const updatedToaster = prevToaster?.filter((toast) => toast.id !== id);
        return updatedToaster?.length ? updatedToaster : null;
      });
      if (notificationProps.onFinished) {
        notificationProps.onFinished();
      }
    }, notificationProps.duration || 3000);
  }, []);

  const ToasterComponent = toaster ? (
    <div
      className={`position-fixed end-0 d-flex flex-column flex-end`}
      style={{ top: 20, zIndex: 9999, width: "fit-content" }}
    >
      {toaster?.map((toast) => (
        <Toaster {...toast} />
      ))}
    </div>
  ) : null;

  return { ToasterComponent, triggerNotification };
};

export default useToaster;
