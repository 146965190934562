/* eslint-disable no-prototype-builtins */
import { FC, useEffect } from 'react'
import { ILayout, useLayout } from '../../core'
import Breadcrumb from '../../../../_layouts/components/breadcrumb/Breadcrumb'
import { KTIcon } from '../../../helpers'
import { useProvider } from '../../../../_layouts/UMSLayoutProvider'

const Header: FC = () => {
  const { config } = useLayout()
  const { account } = useProvider();
  useEffect(() => {
    updateDOM(config)
  }, [config])

  return (
    <div
      className='
        menu
        menu-rounded
        menu-column
        menu-lg-row
        my-5
        my-lg-0
        align-items-stretch
        fw-semibold
        px-2 px-lg-0
    '
      id='kt_app_header_menu'
      data-kt-menu='true'
    >
      <Breadcrumb />
      {account.typeCK === "development" && (
      <div className="d-flex align-items-center">
        <div className='badge badge-light-primary'>
          <KTIcon iconName='data' className='text-primary' />
          <span className="text-uppercase ms-1">{account.typeCK}</span>
        </div>
      </div>
      )}
    </div>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.header?.default?.fixed?.desktop) {
    document.body.setAttribute('data-kt-app-header-fixed', 'true')
  }

  if (config.app?.header?.default?.fixed?.mobile) {
    document.body.setAttribute('data-kt-app-header-fixed-mobile', 'true')
  }

  if (config.app?.header?.default?.stacked) {
    document.body.setAttribute('data-kt-app-header-stacked', 'true')
  }

  const appHeaderDefaultStickyEnabled = config.app?.header?.default?.sticky?.enabled
  let appHeaderDefaultStickyAttributes: { [attrName: string]: string } = {}
  if (appHeaderDefaultStickyEnabled) {
    appHeaderDefaultStickyAttributes = config.app?.header?.default?.sticky?.attributes as {
      [attrName: string]: string
    }
  }

  const appHeaderDefaultMinimizeEnabled = config.app?.header?.default?.minimize?.enabled
  let appHeaderDefaultMinimizeAttributes: { [attrName: string]: string } = {}
  if (appHeaderDefaultMinimizeEnabled) {
    appHeaderDefaultMinimizeAttributes = config.app?.header?.default?.minimize?.attributes as {
      [attrName: string]: string
    }
  }

  setTimeout(() => {
    const headerElement = document.getElementById('kt_app_header')
    // header
    if (headerElement) {
      if (appHeaderDefaultStickyEnabled) {
        for (const key in appHeaderDefaultStickyAttributes) {
          if (appHeaderDefaultStickyAttributes.hasOwnProperty(key)) {
            headerElement.setAttribute(key, appHeaderDefaultStickyAttributes[key])
          }
        }
      }

      if (appHeaderDefaultMinimizeEnabled) {
        for (const key in appHeaderDefaultMinimizeAttributes) {
          if (appHeaderDefaultMinimizeAttributes.hasOwnProperty(key)) {
            headerElement.setAttribute(key, appHeaderDefaultMinimizeAttributes[key])
          }
        }
      }
    }
  }, 0)
}

export { Header }
