import React, { useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import { PrevNextWeekButton, SchdBoxCall } from './components/CalendarCompImDt';
import { NameOfMonth } from '../../academic-milestone/helpers';

export function ImDtCalendarWeeks({data}) {
    const calendarRef = useRef();
    const monday = moment().startOf("weeks").format("YYYY-MM-DD");
    const sunday = moment().endOf("weeks").format("YYYY-MM-DD");

    const [postParam, setPostParam] = useState({ tgl: monday, tglend: sunday });
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center px-8">
                <div className="fs-1">
                    <NameOfMonth start_dt={postParam.tgl} end_dt={postParam.tglend} />
                </div>

                <PrevNextWeekButton postParam={postParam} setPostParam={setPostParam} />
            </div>
            <FullCalendar
                headerToolbar={{ start: '', center: '', end: '' }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin,]}
                initialView="timeGridWeek"
                slotMinTime="07:00"
                slotMaxTime="22:00"
                handleWindowResize={false}
                allDaySlot={false}
                weekends={true}
                weekNumbers={false}
                dayHeaderFormat={{ weekday: 'short', month: 'short', day: '2-digit', omitCommas: false }}
                ref={calendarRef}
                nowIndicator={false}
                expandRows={true}
                showNonCurrentDates={false}
                events={data}
                eventColor="black"
                eventBackgroundColor="white"
                eventContent={(info) => SchdBoxCall(info)}
                editable={false}
                droppable={false}
                initialDate={postParam.tgl}
                validRange={{ start: postParam.tgl, end: moment(postParam.tglend).add(1, 'day').startOf('day').format('YYYY-MM-DD') }}
                hiddenDays={[0]}
            />
        </div>
    )
}
