import React, { useRef } from 'react'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import { EventBoxCall } from './CalendarAtributes';
import './FullCalendar.css';

export function FullCalendarWeek({ data, startDT, endDT, title, createNewClass }) {
    const calendarRef = useRef();

    return (
        <div className=''>
            <div className="px-5 pt-5 d-flex justify-content-between align-items-center">
                <h3 className='mb-0'>
                    <span className="d-block">Swiss German University</span>
                    {title && <span className='fs-6'>{title}</span> }
                </h3>
                <h3>Week {moment(startDT).format("MMM DD")} - {moment(endDT).format("MMM DD")} {moment(endDT).format("YYYY")}</h3>
            </div>
            <FullCalendar
                headerToolbar={{ start: '', center: '', end: '' }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin,]}
                initialView="timeGridWeek"
                slotMinTime="07:00"
                slotMaxTime="22:00"
                handleWindowResize={false}
                allDaySlot={false}
                weekends={true}
                weekNumbers={false}
                dayHeaderFormat={{ weekday: 'long', month: '2-digit', day: '2-digit', omitCommas: true }}
                ref={calendarRef}
                initialDate={startDT}
                goToDate={startDT}
                setDates={startDT}
                select={[startDT, endDT]}
                events={data}
                validRange={{ start: startDT, end: moment(endDT).add(1, 'day').startOf('day').format('YYYY-MM-DD') }}
                nowIndicator={false}
                expandRows={true}
                showNonCurrentDates={false}
                eventColor="black"
                eventBackgroundColor="white"
                eventContent={(info) => EventBoxCall(info)}
                editable={false}
                droppable={false}
                dateClick={(info) => createNewClass(info)}
                hiddenDays={[0]}
                className={{ border: '1px solid #dee2e6' }}
            />
        </div>
    )
}
