import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import { AlertNotif, AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import CalendarImportanceDate from "./importance-date/CalendarImportanceDate";
import PackageJSON from "../../../../../package.json";
import { GET_ACAD_MSTONE_BY_ACAD } from "../../apis/node-target-data/AcademicApi";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import { AcadTerm } from "../../components/forms/SelectBox";
import { openModal } from "../../../../_layouts/components/modals/Modals";
import { KTIcon } from "../../../../_metronic/helpers";
import { ButtonPrimary } from "../../../../_layouts/components/buttons/ButtonUI";
import { LoadingSkeleton } from "../../../../_layouts/components/loadbars/LoadingBars";
import TableData from "./TableData";
import FormSchedule from "./FormSchedule";

export function AcademicAccessWindow() {
  const context = useOutletContext();
  const widget_54 = context.widgets.find(
    (item) => item.widget_id === 54 && item.is_enabled === 1
  );
  const widget_55 = context.widgets.find(
    (item) => item.widget_id === 55 && item.is_enabled === 1
  );

  const [atpid, setAtpid] = useState(PackageJSON.system_param.ActiveAcad);
  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadData = async () => {
    setResults({ loading: true, data: [], message: "" });
    const param = { atpid: atpid, view_all: 1 };
    const result = await GET_ACAD_MSTONE_BY_ACAD(param);
    setResults(result);
  };

  useEffect(() => {
    ReloadData();
  }, [atpid]);

  return widget_54 ? (
    <div id="academic-access-window">
      <SGUCard>
        <SGUCard.Body className={"pt-8"}>
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <div className="w-300px">
              <AcadTerm
                data={atpid}
                setData={setAtpid}
                title={"Choose term:"}
              />
            </div>

            {widget_54 && (
              <ButtonPrimary
                type="button"
                className="btn-sm fw-bold ms-3"
                onClick={() =>
                  openModal({
                    message: (
                      <FormSchedule atpid={atpid} ReloadData={ReloadData} />
                    ),
                  })
                }
              >
                <KTIcon iconName="plus" />
                Add New
              </ButtonPrimary>
            )}
          </div>
          {results.loading ? (
            <LoadingSkeleton />
          ) : results.message ? (
            <AlertRetry messages={results.message} Reload={ReloadData} />
          ) : (
            <TableData
              data={results.data}
              ReloadData={ReloadData}
              atpid={atpid}
            />
          )}
        </SGUCard.Body>
      </SGUCard>

      <div className="my-8">
        <CalendarImportanceDate />
      </div>
    </div>
  ) : (
    <AlertNotif messages={AccessInvalid} />
  );
}
