import React, { useCallback, useState } from "react";
import { debounce } from "lodash";
import { GET_STUDENT } from "../../../../apis/node-target-data/AcademicApi";
import { LoadingSkeleton } from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../../_layouts/components/alerts/AlertsUI";
import TableData from "./TableData";
import { InputKeyword } from "../components/InputText";

export default function FindStudent() {
  const [students, setStudents] = useState({
    message: "",
    data: [],
    loading: false,
  });
  const minimKeyword = 3;

  const ReloadData = async (param) => {
    setStudents({ message: "", data: [], loading: true });
    const result = await GET_STUDENT(param);
    setStudents(result);
  };

  const [search, setSearch] = useState("");
  const FindDataDebounced = useCallback(
    debounce((param) => {
      ReloadData(param);
    }, 500),
    []
  );

  const HandlerSearch = (value) => {
    if (value.length >= minimKeyword) {
      setSearch(value);
      let param = {};

      if (!isNaN(value) && value.trim() !== "") {
        param = { studentid: value.trim() };
      } else {
        param = { studentname: value.trim() };
      }
      FindDataDebounced(param);
    } else {
      setStudents({ loading: false, message: "", data: [] });
    }
  };

  return (
    <div className="find-student">
      <div className="d-flex align-items-center justify-content-between">
        <div style={{ width: "100%" }}>
          <InputKeyword
            title={"Studentid or student name"}
            defaultValue={search}
            onChange={(e) => HandlerSearch(e.target.value)}
            results={students}
            autoComplete="off"
          />
        </div>
      </div>

      {students.loading ? (
        <LoadingSkeleton />
      ) : students.message ? (
        <AlertNotif messages={students.message} color="danger" />
      ) : (
        <TableData data={students.data} />
      )}
    </div>
  );
}
