import React, { useEffect, useState } from "react";
import { SGUCard } from "../../../../../../_layouts/components/cards/CardUI";
import {
  ButtonPrimary,
} from "../../../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { StudentIDFormat } from "../../../../../../_layouts/_helpers/Global";
import { PMatriculations } from "../../proposal-form/componensts/details";
import {
  MessageError,
  MessageSuccess,
  openModal,
} from "../../../../../../_layouts/components/modals/Modals";
import { BtnPrevFile } from "../../../../master-data-managements/storage-files/components/StorageUI";
import { LinkBtn } from "../../../../../../_layouts/components/buttons/LinkUI";
import { RequiredMsg } from "../../../../../../_layouts/_core/ErrorMessage";
import { useProvider } from "../../../../../../_layouts/UMSLayoutProvider";
import { RSAencryptData } from "../../../../../../_layouts/_helpers/RSAEnc";
import {
  DELETE_REQUEST_DOC,
  POST_REQUEST_DOC_MULTIPLE,
} from "../../../../apis/module/JupiterRequest";
import { AlertNotif } from "../../../../../../_layouts/components/alerts/AlertsUI";
import { ProgressBar } from "react-bootstrap";
import { RenderFileDoc } from "./FormUI";
import {
  DELETE_ASC_DOC,
  GET_ASC_DOC,
  SUBMIT_TRANSFER_PROPOSAL,
  UPLOAD_ASC_DOC,
} from "../../../../apis/node-target-data/AcademicApi";
import {
  LoadingBars,
} from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { useNavigate } from "react-router-dom";

export default function Forms({ candidate, proposal }) {
  const navigate = useNavigate();
  const [candidateData, setCandidateData] = useState(candidate);
  const last_semester = candidate.last_semester;
  const accepted_semester = candidate?.enroll_semester;
  const [courses, setCourses] = useState([]);
  const [total_credit, setTotalCredit] = useState(0);
  const [total_credit_ext, setTotalCreditExt] = useState(0);
  const transid = proposal.transid || "";
  const [regstudentID, setRegstudentID] = useState(candidate.registered_id);
  const isStudent = candidate.create_student;
  const [showTrans, setShowTrans] = useState(false);
  const doc_type = "strg.doc.type.decreetransfercredit";

  useEffect(() => {
    setRegstudentID(candidate.registered_id);
    if (candidate.registered_id) {
      LookUpfileOwner(candidate.registered_id);
    }
    setCandidateData(candidate);
    const course_dt = proposal.transfer_proposal_dt.map((item) => ({
      ...item,
      id: item.course_id,
      coursetitle: item.course.coursetitle,
      code: item.course.code,
      credit: item.course.credit,
    }));
    setCourses(course_dt);
    const total_credit = proposal.transfer_proposal_dt
      ? proposal.transfer_proposal_dt.reduce(
        (sum, item) => sum + (parseFloat(item.course.credit) || 0),
        0
      )
      : 0;
    setTotalCredit(total_credit);
    const total_credit_ext = proposal.transfer_proposal_dt
      ? proposal.transfer_proposal_dt.reduce(
        (sum, item) => sum + (parseFloat(item.credit_ext) || 0),
        0
      )
      : 0;
    setTotalCreditExt(total_credit_ext);
  }, [candidate, proposal]);

  const [existFile, setExistFile] = useState({
    loading: false,
    data: [],
    message: "",
  });
  //const []
  const LookUpfileOwner = async () => {
    setExistFile({ loading: true, data: [], message: "" });
    const param = { reff_id: transid };
    const result = await GET_ASC_DOC(param);
    if (result.data && Object.values(result.data).length > 0) {
      setExistFile({ loading: false, data: result.data[0], message: "" });
    } else {
      setExistFile({ loading: false, data: [], message: result.message });
    }
  };

  const [file, setFile] = useState(null);
  const [isExtension, setIsExtension] = useState(false);
  const [isSize, setIsSize] = useState(false);

  const { account } = useProvider();
  const [uploadFile, setUploadFile] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const [uploadProgress, setUploadProgress] = useState({});
  let currentProgress = 2;

  const validateFile = (file) => {
    const validExtensions = ["pdf"];
    const maxSize = 2 * 1024 * 1024; // 2MB in bytes

    const fileExtension = file.name
      .split(".")
      .pop()
      .toLowerCase();
    const fileSize = file.size;

    setIsExtension(validExtensions.includes(fileExtension));
    setIsSize(fileSize <= maxSize);

    return validExtensions.includes(fileExtension) && fileSize <= maxSize;
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      if (validateFile(selectedFile)) {
        setFile(selectedFile);
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [selectedFile.name]: currentProgress,
        }));
        setUploadFile({ loading: true, data: [], message: "" });
        setTimeout(() => {
          HandlerUploadFile(selectedFile);
        }, 2000);
      } else {
        setFile(null);
        const messageError =
          "Invalid file type or size. Please choose a file with a valid extension PDF and size less than 2MB.";
        openModal({ message: <MessageError message={messageError} /> });
      }
    }
  };

  const isImageFile = (file) => {
    return file.type.startsWith("image/");
  };

  const HandlerUploadFile = async (selectedFile) => {
    if (!selectedFile) {
      openModal({
        message: <MessageError message="Please select a file to upload." />,
      });
      return;
    }

    const param = {
      user: account.identity.email,
      doc_type: doc_type,
      owner: regstudentID,
      reff_id: transid,
    };

    const objData = JSON.stringify(param);
    const rsaEnc = RSAencryptData(objData);

    try {
      const formData = new FormData();
      formData.append("data", rsaEnc);
      formData.append("file", selectedFile);

      const result = await POST_REQUEST_DOC_MULTIPLE(formData, (progress) => {
        currentProgress += 2;
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [selectedFile.name]: Math.min(currentProgress, 100),
        }));
      });

      if (result.result === "success") {
        const param_doc = {
          ...param,
          file_id: result.data?.data?.file_id,
        };
        console.log(param_doc);
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [selectedFile.name]: 95,
        }));
        const result_doc = await UPLOAD_ASC_DOC(param_doc);
        if (result_doc.message) {
          openModal({
            message: (
              <MessageError
                message={
                  "Successfully uploaded to storage, but failed to save the data"
                }
              />
            ),
          });
        } else {
          setUploadProgress((prevProgress) => ({
            ...prevProgress,
            [selectedFile.name]: 100,
          }));
        }
        setUploadFile({
          loading: false,
          data: [result.data?.data],
          message: "",
        });
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [selectedFile.name]: 0,
        }));
      } else {
        openModal({
          message: (
            <MessageError
              message={"Failed to upload file. Please try again."}
            />
          ),
        });
        setUploadProgress((prevProgress) => ({
          ...prevProgress,
          [selectedFile.name]: 0,
        }));
        setUploadFile({
          loading: false,
          data: [],
          message: "Failed to upload file. Please try again.",
        });
      }
    } catch (error) {
      console.log(error);
      setUploadFile({
        loading: false,
        data: [],
        message:
          "An error occurred during the upload process. " + error.message,
      });
      setUploadProgress((prevProgress) => ({
        ...prevProgress,
        [selectedFile.name]: 0,
      }));
    }
  };

  const [submit, setSubmit] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const SubmitProposal = async (e) => {
    e.preventDefault();
    if (
      accepted_semester &&
      (file || Object.values(existFile.data).length > 0) &&
      transid &&
      candidateData &&
      Object.values(courses).length > 0
    ) {
      setSubmit({ loading: true, message: "", data: [] });
      //update flag
      const param = { proposal_id: proposal.id, flag: 2 };
      const result_flag = await SUBMIT_TRANSFER_PROPOSAL(param);
      if (result_flag.data && Object.values(result_flag.data).length > 0) {
        openModal({
          message: <MessageSuccess message={"Successfully saved"} />,
        });
        setSubmit(result_flag);
        setTimeout(() => {
          navigate("/course-managements/transfer-credit-registration");
        }, 2000);
      } else {
        const message_error = result_flag.message.includes("02001");
        let msg = "";
        if (message_error) {
          msg = "";
        } else {
          msg = result_flag.message;
        }
        openModal({
          message: <MessageError message={msg} />,
        });
        setSubmit({ loading: false, message: msg, data: [] });
      }
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  };

  const HandlerDeleteFile = async (fileid) => {
    const param = {
      file_id: fileid,
      user: account.identity.email,
    };
    const objData = JSON.stringify(param);
    const rsaEnc = RSAencryptData(objData);

    openModal({
      message: (
        <div className="text-center">
          <LoadingBars /> Processing...
        </div>
      ),
    });
    const formData = new FormData();
    formData.append("data", rsaEnc);
    const result = await DELETE_REQUEST_DOC(formData);

    if (result.data && Object.values(result.data).length > 0) {
      const remove_asc_doc = await DELETE_ASC_DOC({ file_id: fileid });
      if (
        remove_asc_doc.data &&
        Object.values(remove_asc_doc.data).length > 0
      ) {
        LookUpfileOwner();
        openModal({
          message: <MessageSuccess message={"Successfully removed"} />,
        });
      }
      setFile(null);
      setUploadFile({ loading: false, data: [], message: "" });
    } else {
      openModal({ message: <MessageError message={result.message} /> });
    }
  };

  const HandlerReject = async () => {
    //openModal({message:<div className="text-center fs-4"><LoadingProgressBar /> Processing...</div>})
    openModal({ open: false });
    setSubmit({ loading: true, message: "", data: [] });
    const param = { proposal_id: proposal.id, flag: 3 };
    const result_flag = await SUBMIT_TRANSFER_PROPOSAL(param);
    if (result_flag.data && Object.values(result_flag.data).length > 0) {
      openModal({
        message: (
          <MessageSuccess message={"Successfully rejected the proposal"} />
        ),
      });
      navigate("/course-managements/transfer-credit-registration");
    }
    setSubmit(result_flag);
  };

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bold text-gray-800">
          Student's Proposal Form
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        <div className="d-flex align-items-center">
          <span className="rounded rounded-circle bg-light-success p-2">
            <KTIcon iconName="abstract-18" className="fs-2x text-success" />
          </span>
          <span className="text-capitalize fw-bold text-success ms-2">
            {proposal?.status}
          </span>
        </div>
      </SGUCard.Toolbar>
      <SGUCard.Body>
        {isStudent !== 1 && (
          <div className="mb-5">
            <AlertNotif title={"Cannot be approved"} messages={"The selected person has not completed the admission process. Admission completion requires confirmation from Admission, Finance, and AAO. Please contact the relevant parties to finalize the process."} />
          </div>
        )}
        <form
          autoComplete="off"
          method="post"
          onSubmit={(e) => SubmitProposal(e)}
        >
          <div className="row mb-5 align-items-center">
            <label className="col-lg-3 fw-semibold text-muted required">
              Student:
            </label>
            <div className="col-lg-9">
              <span className="text-dark fw-bold">
                {StudentIDFormat(candidateData.registered_id) + " · " + candidateData.candidate_name}
              </span>
            </div>
          </div>
          <div className="row mb-5 align-items-center">
            <label className="col-lg-3 fw-semibold text-muted required">
              Target Department:
            </label>
            <div className="col-lg-9">
              <span className="text-dark fw-bold">
                {candidateData.target_department}
              </span>
            </div>
          </div>

          <div className="row mb-5 align-items-center">
            <label className="col-lg-3 fw-semibold text-muted required">
              Previous University:
            </label>
            <div className="col-lg-9">
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control form-control-sm fw-bold"
                      readOnly
                      value={proposal.university_name || "-"}
                    />
                    <label className="fw-semibold text-muted">
                      Institution Name
                    </label>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control form-control-sm fw-bold"
                      readOnly
                      value={proposal.university_prodi_name || "-"}
                    />
                    <label className="fw-semibold text-muted">
                      Major at prev insitution
                    </label>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="form-floating">
                    <input
                      type="text"
                      className="form-control form-control-sm fw-bold"
                      readOnly
                      value={last_semester}
                    />
                    <label className="fw-semibold text-muted">
                      Last semester
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mb-5 align-items-center">
            <label className="col-lg-3 fw-semibold text-muted required">
              Accepted at semester:
            </label>
            <div className="col-lg-9">
              <div className="fw-bold">{accepted_semester}</div>
            </div>
          </div>

          <div className="row mb-5 align-items-start">
            <label className="col-lg-3 fw-semibold text-muted required">
              Upload Decree:
            </label>

            {existFile.loading ? (
              <div className="col-auto">
                <LoadingBars />
              </div>
            ) : Object.values(existFile.data).length > 0 ? (
              <div className="col-auto">
                <RenderFileDoc
                  file_id={existFile.data.file_id}
                  data={existFile.data}
                  HandlerDeleteFile={HandlerDeleteFile}
                  filename={"Decree Transfer Credit"}
                />
              </div>
            ) : Object.values(uploadFile.data).length > 0 ? (
              uploadFile.data.map((v, index) => (
                <div className="col-auto" key={index}>
                  <RenderFileDoc
                    data={v}
                    file_id={v.file_id}
                    HandlerDeleteFile={HandlerDeleteFile}
                    filename={file.name || v.filename}
                  />
                </div>
              ))
            ) : (
              <div className="col-lg-9">
                <div className="input-group">
                  <input
                    type="file"
                    className="form-control"
                    onChange={handleFileChange}
                    accept=".pdf"
                  />
                  {file && (
                    <div className="input-group-text p-0">
                      {isImageFile(file) ? (
                        <BtnPrevFile file={file} />
                      ) : (
                        <button
                          type="button"
                          className="btn btn-icon btn-sm"
                          onClick={() =>
                            window.open(URL.createObjectURL(file), "_blank")
                          }
                        >
                          <KTIcon iconName="document" className="fs-1" />
                        </button>
                      )}
                    </div>
                  )}
                </div>

                <div className="notes fs-8 my-2 d-flex align-items-center justify-content-between">
                  <ul className="nav">
                    <li>
                      <span className="fw-bold">Notes:</span>
                    </li>
                    <li className="mx-2 d-flex align-items-center">
                      <KTIcon
                        iconName={
                          (isExtension ? "check" : "cross") + "-circle"
                        }
                        className={
                          "fs-1x me-1 text-" +
                          (isExtension ? "success" : "danger")
                        }
                      />
                      Only <span className="fw-bold mx-1">PDF</span> files are
                      allowed.
                    </li>
                    <li className="d-flex align-items-center">
                      <KTIcon
                        iconName={(isSize ? "check" : "cross") + "-circle"}
                        className={
                          "fs-1x me-1 text-" + (isSize ? "success" : "danger")
                        }
                      />
                      Maximum file size is{" "}
                      <span className="fw-bold ms-1">2 MB</span>.
                    </li>
                  </ul>
                  {transid && (
                    <div className="text-end">
                      <span
                        className="badge badge-light-info cursor-pointer"
                        title="Trans ID"
                        onClick={() => setShowTrans(!showTrans)}
                      >
                        <KTIcon iconName="code" className="fs-1x text-info" />
                        {showTrans && <span className="ms-1">{transid}</span>}
                      </span>
                    </div>
                  )}
                </div>

                {uploadFile.message && (
                  <AlertNotif
                    title={"Failed Upload"}
                    messages={uploadFile.message}
                    color={uploadFile.data.length > 0 ? "success" : "danger"}
                  />
                )}

                {Object.entries(uploadProgress).map(
                  ([fileName, progress]) =>
                    progress > 0 && (
                      <div
                        key={fileName}
                        className="d-flex align-items-center mt-2"
                      >
                        <div className="w-100">
                          <ProgressBar
                            striped
                            animated
                            variant="success"
                            now={progress}
                            max="100"
                          />
                        </div>
                        <span className="text-success ms-2">
                          ({Math.round(progress)}%)
                        </span>
                      </div>
                    )
                )}
              </div>
            )}
          </div>

          <p className="fw-semibold text-muted required">
            Transferable Credits:
          </p>
          {Object.values(courses).length > 0 && (
            <PMatriculations
              data={courses}
              t_credit={total_credit}
              t_credit_ext={total_credit_ext}
            />
          )}

          <div className="text-end mt-8">
            <div className="d-flex align-items-center justify-content-between">
              <LinkBtn
                to={"/course-managements/transfer-credit-registration"}
                className="btn btn-sm btn-light me-2"
              >
                Cancel
              </LinkBtn>
              {isStudent === 1 && (
                <div>
                  {/* <ButtonDanger
                    type="button"
                    className="btn-sm mx-3 fw-bold"
                    disabled={uploadFile.loading || submit.loading}
                    onClick={() =>
                      openModal({
                        message: (
                          <ConfirmRemove
                            message={"Are you sure want to reject this proposal?"}
                            handler={HandlerReject}
                          />
                        ),
                      })
                    }
                  >
                    {uploadFile.loading || submit.loading
                      ? "Processing..."
                      : "Reject Proposal"}
                  </ButtonDanger> */}
                  <ButtonPrimary
                    type="submit"
                    className="btn-sm px-8 fw-bold"
                    disabled={uploadFile.loading || submit.loading}
                  >
                    {uploadFile.loading || submit.loading
                      ? "Processing..."
                      : "Approve Proposal"}
                  </ButtonPrimary>
                </div>
              )}
            </div>
          </div>
        </form>
      </SGUCard.Body>
    </SGUCard >
  );
}
