import React, { useEffect, useState } from 'react'
import { GET_SCR_STUDENT } from '../../../../../apis/node-target-data/AcademicApi';
import { SGUCard } from '../../../../../../../_layouts/components/cards/CardUI';
import { LoadingSkeleton } from '../../../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertRetry } from '../../../../../../../_layouts/components/alerts/AlertsUI';
import TableData from '../../../../../scores/students/TableData';

export default function StudentScore({ section }) {
    const [results, setResults] = useState({
        loading: false,
        data: [],
        message: "",
    });
    const param = { section_id: section.section_id, offset: 1, limit: section.max_seat };
    const ReloadData = async () => {
        setResults({ loading: true, data: [], message: "" });
        const result = await GET_SCR_STUDENT(param);
        setResults(result);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        ReloadData();
    },[])

    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bolder text-dark">
                    Student Scores
                </span>
            </SGUCard.Header>
            <SGUCard.Body>
                {results.loading ? (
                    <LoadingSkeleton />
                ) : results.message ? (
                    <AlertRetry messages={results.message} Reload={ReloadData} />
                ) : (
                    <div className="d-flex flex-column justify-content-between h-100">
                        <div className="w-100">
                            <TableData results={results} ReloadData={ReloadData} />
                        </div>
                        <div className="w-100">
                            {Object.values(results.data).length > 0 ? (
                                <div className="footer">
                                    <ul className="pagination">
                                        <li
                                            className={
                                                "page-item " +
                                                (param.offset !== 1 ? "cursor-pointer" : "disabled")
                                            }
                                        >
                                            <span
                                                className="page-link"
                                                onClick={() => HandlerPage(1)}
                                            >
                                                <span>‹ Prev</span>
                                            </span>
                                        </li>
                                        <li className="page-item active">
                                            <span className="page-link text-white">
                                                Page {param.offset}
                                            </span>
                                        </li>
                                        <li
                                            className={
                                                "page-item " +
                                                (Object.values(results.data).length === param.limit
                                                    ? "cursor-pointer"
                                                    : "disabled")
                                            }
                                        >
                                            <span
                                                className="page-link"
                                                onClick={() => HandlerPage(2)}
                                            >
                                                <span>Next ›</span>
                                            </span>
                                        </li>
                                    </ul>
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    </div>
                )}
            </SGUCard.Body>
        </SGUCard>
    )
}
