import React, { useState } from "react";
import FilterUI from "../../components/filters/FilterUI";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";
import {
  MessageError,
  openModal,
} from "../../../../_layouts/components/modals/Modals";
import { AccessInvalid, RequiredMsg } from "../../../../_layouts/_core/ErrorMessage";
import { GET_STUDENT } from "../../apis/node-target-data/AcademicApi";
import {
  StudentID,
  StudentName,
  YearGraduate,
} from "../../components/forms/InputText";
import {
  AcadProgram,
  AcadTerm,
  Corporate,
  Department,
  StudentStatus,
} from "../../components/forms/SelectBox";
import TableData from "./TableData";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import { useOutletContext } from "react-router-dom";

export function Students() {
  const context = useOutletContext();
  const widget_24 = context.widgets.find(
    (item) => item.widget_id === 24 && item.is_enabled === 1
  );

  const [expand, setExpand] = useState(false);
  const [studentname, setStudentname] = useState("");
  const [studentid, setStudentid] = useState("");
  const [clu_id, setClu_id] = useState("");
  const [status, setStatus] = useState("");
  const [corporate_id, setCorporate_id] = useState(0);
  const [term_intake_id, setTerm_intake_id] = useState(0);
  const [term_id, setTerm_id] = useState(0);
  const [yudisium_year, setYudisium_year] = useState(0);
  const [program_id, setProgram_id] = useState(0);

  const postParam = {
    studentname,
    studentid,
    clu_id,
    status,
    corporate_id,
    term_id,
    term_intake_id,
    yudisium_year,
    program_id,
  };

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadData = async () => {
    setResults({ loading: true, message: "", data: [] })
    const param = RemoveEmptyValue(postParam);
    const result = await GET_STUDENT(param);

    setResults(result);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const SubmitFilter = (e) => {
    e.preventDefault();
    const param = RemoveEmptyValue(postParam);
    if (Object.keys(param).length > 0) {
      ReloadData();
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  };

  return widget_24 ? (
    <div className="row">
      <div className="col-lg-3">
        <FilterUI title="Student" onSubmit={SubmitFilter} isload={results.loading}>
          <StudentName data={studentname} setData={setStudentname} />
          <StudentID data={studentid} setData={setStudentid} />
          <Department data={clu_id} setData={setClu_id} />
          <AcadTerm data={term_id} setData={setTerm_id} />
          <AcadTerm data={term_intake_id} setData={setTerm_intake_id} title="Term Intake" />
          <StudentStatus data={status} setData={setStatus} />
          <p
            className="text-end mb-0 cursor-pointer mb-5"
            onClick={() => setExpand(!expand)}
          >
            {!expand ? (
              <>
                <span className="text-primary me-2">Show more</span>
                <i className="bi bi-chevron-down text-primary"></i>
              </>
            ) : (
              <>
                <span className="text-primary me-2">Show less</span>
                <i className="bi bi-chevron-up text-primary"></i>
              </>
            )}
          </p>
          <div className={"expand-filter " + (!expand ? "d-none" : "")}>
            <AcadProgram data={program_id} setData={setProgram_id} />
            <Corporate data={corporate_id} setData={setCorporate_id} />
            <YearGraduate data={yudisium_year} setData={setYudisium_year} />
          </div>
        </FilterUI>
      </div>
      <div className="col-lg-9">
        <SGUCard>
          <SGUCard.Header>
            <span className="card-label fw-bold text-gray-800">
              Browse Student Data
            </span>
          </SGUCard.Header>
          <SGUCard.Body>
            {results.loading ? (
              <LoadingSkeleton />
            ) : results.message ? (
              <AlertNotif messages={results.message} />
            ) : Object.values(results.data).length > 0 ? (
              <TableData data={results.data} ReloadData={ReloadData} />
            ) : (
              <NoRecordFound />
            )}
          </SGUCard.Body>
        </SGUCard>
      </div>
    </div>
  ) : <AlertNotif messages={AccessInvalid} />;
}
