import React from 'react'
import { SGUCard } from '../../../../../../_layouts/components/cards/CardUI'

export default function Scholarshiplist({ stdAcad }) {
    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bolder fs-3 mb-1">Scholarship History</span>
                <span className="text-muted mt-1 fw-bold fs-7">Scholarship by term</span>
            </SGUCard.Header>
            <SGUCard.Body>
                {stdAcad
                    ? stdAcad.scholarship_hist.map((s, index) => (
                        <div key={index}>
                            <div className="d-flex flex-stack">
                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                    <div className="flex-grow-1 me-2">
                                        <span className="text-gray-800 fs-6 fw-bold">
                                            {s.atp_term
                                                ? s.atp_term.name
                                                    ? s.atp_term.name
                                                    : s.atp_term.id
                                                : ""}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div className="separator separator-dashed my-4"></div>
                        </div>
                    ))
                    : ""}
            </SGUCard.Body>
        </SGUCard>
    )
}
