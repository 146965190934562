import React from "react";

export default function Navigations({ navTabs, active, HandlerTabNav }) {
  return (
    <div>
      <div className="separator"></div>
      <div className="px-8">
        <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
          {navTabs.map((v, index) => (
            <li className="nav-item" key={index}>
              <a
                target={`#${v.target}`}
                className={
                  "cursor-pointer nav-link text-active-primary py-5 me-6 " +
                  (active === v.id && "active")
                }
                onClick={() => HandlerTabNav(v.id)}
              >
                {v.name}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
