export {MasterDataLayout} from "./MasterDataLayout"

export {MAcademicCalendar} from "./academic-calendar"

export {GoogleManagement} from "./google-management"

export {RegionalManagement} from "./regional-management"

export {CompanyManagement} from "./company-management"

export {SchoolManagement} from "./school-management"

export {MStorageFiles} from "./storage-files"