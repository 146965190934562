import React, { useState } from "react";
import { FilterCalendar } from "../components";
import { MessageError, openModal } from "../../../../_layouts/components/modals/Modals";
import { RequiredMsg } from "../../../../_layouts/_core/ErrorMessage";
import moment from "moment";
import { LectLookUp } from "../../components/forms/InputText";
import { EmployeeSelect } from "../../components/forms/SelectBox";

export default function Filter({ isload, setPostParam }) {
  const [empid, setEmp_id] = useState(0);

  const HandlerFilter = (e) => {
    e.preventDefault();
    if (empid) {
      const monday = moment().startOf("weeks").format("YYYY-MM-DD");
      const sunday = moment().endOf("weeks").format("YYYY-MM-DD");
      setPostParam(prev => {
        const data = {...prev};
        data.empid = empid;
        data.start_dt = monday;
        data.end_dt = sunday;
        return data;
      })
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  }

  return (
    <FilterCalendar onSubmit={HandlerFilter} isload={isload}>
      <div className="w-400px me-3" style={{marginBottom:"-15px"}}>
        <EmployeeSelect data={empid} setData={setEmp_id} showtitle={false} />
      </div>
    </FilterCalendar>
  );
}
