import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import {
  ButtonIcon,
  ButtonLong,
} from "../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../_metronic/helpers";
import { doc_type } from "../../../../_layouts/_core/ConstantData";
import { useProvider } from "../../../../_layouts/UMSLayoutProvider";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { OverlayTrigger, Popover } from "react-bootstrap";
import {
  MessageError,
  MessageSuccess,
  openModal,
} from "../../../../_layouts/components/modals/Modals";
import { RequiredMsg } from "../../../../_layouts/_core/ErrorMessage";
import { RSAencryptData } from "../../../../_layouts/_helpers/RSAEnc";
import { POST_REQUEST_DOC } from "../../apis/module/JupiterRequest";
import { LoadingProgressBarComplete } from "../../../../_layouts/components/loadbars/LoadingBars";

export default function TFormUploadFile({ setShowUploadForm }) {
  const { account } = useProvider();
  const [postParam, setPostParam] = useState({
    user: account.identity.email,
    doc_type: "",
    owner: account.identity.email,
  });

  const [files, setFiles] = useState([]);
  const [error, setError] = useState("");
  const [fileProgress, setFileProgress] = useState(false);
  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const onDrop = useCallback((acceptedFiles) => {
    const validFiles = acceptedFiles.filter((file) => {
      // Check file type
      const isValidType = ["image/jpeg", "image/png", "image/jpg"].includes(
        file.type
      );

      // Check file size (2MB = 2 * 1024 * 1024 bytes)
      const isValidSize = file.size <= 2 * 1024 * 1024;

      if (!isValidType) {
        setError(
          `${file.name} is not a valid file type. Only jpg, png, and jpeg are allowed.`
        );
      } else if (!isValidSize) {
        setError(`${file.name} is too large. Maximum file size is 2MB.`);
      }

      return isValidType && isValidSize;
    });

    setFiles(validFiles.slice(0, 1));
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
    },
    maxSize: 2 * 1024 * 1024, // 2MB
  });

  const handleUpload = () => {
    if (files.length === 0) {
      setError("Please select valid files to upload.");
      return;
    }
    console.log("Files to upload:", files);
  };

  const handleDeleteFile = (fileToDelete) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };

  const SubmitFile = async (e) => {
    e.preventDefault();

    if (postParam.doc_type) {
      const objData = JSON.stringify(postParam);
      const rsaEnc = RSAencryptData(objData);
      setSubmit({ loading: true, data: [], message: "" });

      const formData = new FormData();
      formData.append("data", rsaEnc);
      formData.append("file", files[0]);

      try {
        setFileProgress(true);
        const result = await POST_REQUEST_DOC(formData);

        if (result.data && Object.values(result.data).length > 0) {
          setSubmit({ loading: false, data: result.data, message: "" });
        } else {
          setFileProgress(false);
          openModal({ message: <MessageError message={result.message} /> });
          setSubmit(result);
          setError(result.message);
        }
      } catch (error) {
        openModal({ message: <MessageError message={error.message} /> });
        setSubmit({ loading: false, data: [], message: error.message });
        setError(error.message);
      }
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  };

  return (
    <SGUCard>
      <SGUCard.Body className={"pt-8"}>
        <div className="d-flex align-items-center mb-8">
          <ButtonIcon
            className="btn-sm text-hover-primary"
            type="button"
            onClick={() => setShowUploadForm(false)}
            title="Back"
          >
            <KTIcon iconName="left" className="fs-1" />
          </ButtonIcon>
          <span className="fs-3 fw-bold">Upload Files</span>
        </div>

        {fileProgress ? (
          <div>
            {files.length > 0 && (
              <div className="my-5 dropzone dropzone-queue">
                {files.map((file, index) => (
                  <div className="dropzone-items wm-200px" key={index}>
                    <div className="dropzone-item dz-image-preview">
                      <div className="dropzone-file">
                        <div className="dropzone-filename text-gray-900">
                          <span className="fw-bold me-2">{file.name}</span>
                          <span className="fw-bold">({file.size} bytes)</span>
                        </div>
                      </div>
                    </div>

                    <div className="">
                      <LoadingProgressBarComplete
                        start={true}
                        setProgressBar={setFileProgress}
                        onComplete={() => {
                          setFileProgress(false);
                          openModal({
                            message: (
                              <MessageSuccess
                                message={"Successfully uploaded"}
                              />
                            ),
                          });
                          setShowUploadForm(false);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ) : (
          <form
            method="post"
            autoComplete="off"
            onSubmit={(e) => SubmitFile(e)}
          >
            <>
              <div className="form-group mb-5">
                <label className="form-label fw-bold required">
                  Document Type
                </label>
                <select
                  name="doc_type"
                  defaultValue={postParam.doc_type}
                  className="form-select form-select-solid"
                  required
                  onChange={(e) =>
                    setPostParam({ ...postParam, doc_type: e.target.value })
                  }
                >
                  <option value="">Choose One</option>
                  {doc_type.map((v, index) => (
                    <option key={index} value={v.id}>
                      {v.name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group mb-5">
                <label className="fw-bold required">Choose File:</label>
                <div {...getRootProps()} className="dropzone mt-2">
                  <input {...getInputProps()} />
                  {isDragActive ? (
                    <div className="d-flex align-items-center justify-content-center py-8">
                      <KTIcon iconName="folder-up" className="fs-4x" />
                      <div className="text-dark text-start ms-2">
                        <span className="d-block fw-bold fs-4">
                          Drop the files here...
                        </span>
                      </div>
                    </div>
                  ) : (
                    <div className="d-flex align-items-center justify-content-center py-8">
                      <KTIcon iconName="folder-up" className="fs-4x" />
                      <div className="text-dark text-start ms-2">
                        <span className="d-block fw-bold fs-4">
                          Drop anywhere to upload
                        </span>
                        <span className="text-muted fs-7">
                          or select <span className="text-primary">files</span>
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>

            {files.length > 0 && (
              <div className="my-5 dropzone dropzone-queue">
                {files.map((file, index) => (
                  <div className="dropzone-items wm-200px" key={index}>
                    <div className="dropzone-item dz-image-preview">
                      <div className="dropzone-file">
                        <div className="dropzone-filename text-gray-900">
                          <span className="fw-bold me-2">{file.name}</span>
                          <span className="fw-bold">({file.size} bytes)</span>
                        </div>
                      </div>

                      <div className="dropzone-toolbar">
                        <BtnPrevFile file={file} />
                        <ButtonIcon
                          type="button"
                          className="btn-sm"
                          onClick={() => handleDeleteFile(file)}
                        >
                          <KTIcon
                            iconName="cross-circle"
                            className="fs-1 text-danger"
                          />
                        </ButtonIcon>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {error && (
              <div className="my-5">
                <AlertNotif messages={error} />
              </div>
            )}

            <ButtonLong
              type="submit"
              className="btn btn-lg btn-primary fw-bold"
              onClick={handleUpload}
              disabled={files.length === 0}
            >
              <div className="d-flex align-items-center justify-content-center">
                Submit
              </div>
            </ButtonLong>
          </form>
        )}
      </SGUCard.Body>
    </SGUCard>
  );
}

const BtnPrevFile = ({ file }) => {
  const [previewImageUrl, setPreviewImageUrl] = useState(null);

  const handlePreviewClick = useCallback(() => {
    setPreviewImageUrl(URL.createObjectURL(file));
  }, [file]);

  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      rootClose
      trigger="click"
      overlay={
        <Popover className="p-3">
          <Popover.Body className="p-0">
            <PrevFile image={previewImageUrl} />
          </Popover.Body>
        </Popover>
      }
      onToggle={(nextShow) => {
        if (nextShow) {
          handlePreviewClick();
        }
      }}
    >
      <button
        type="button"
        className="btn btn-icon btn-sm"
        onClick={(e) => handlePreviewClick(e, file)}
      >
        <KTIcon iconName="picture" className="fs-1" />
      </button>
    </OverlayTrigger>
  );
};

const PrevFile = ({ image }) => {
  return (
    <div className="bg-white rounded">
      <img
        src={image}
        alt="prev-file"
        style={{ maxWidth: "200px", maxHeight: "200px" }}
      />
    </div>
  );
};
