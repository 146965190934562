import React, { useEffect, useMemo, useState } from 'react'
import { SGUCard } from '../../../../../_layouts/components/cards/CardUI'
import { ButtonIcon, ButtonPrimary } from '../../../../../_layouts/components/buttons/ButtonUI'
import { KTIcon } from '../../../../../_metronic/helpers'
import { RequestData } from '../../../apis/ApiBackend';
import { ButtonItemRefresh, SearchBarTable } from '../../../../../_layouts/components/datatables/MYDatatables';
import { AlertNotif } from '../../../../../_layouts/components/alerts/AlertsUI';
import { LoadingBars, NoRecordFound } from '../../../../../_layouts/components/loadbars/LoadingBars';
import { MessageError, MessageSuccess, openModal } from '../../../../../_layouts/components/modals/Modals';
import FormUpdate from './FormUpdate';
import { useOutletContext } from 'react-router-dom';
import { GET_USER_SYS } from '../../../apis/module/BackendRequest';

export default function UserList({ activeGroup }) {
    const context = useOutletContext();
    const widget_4= context.widgets.find(
        (item) => item.widget_id === 4 && item.is_enabled === 1
    );
    const widget_5= context.widgets.find(
        (item) => item.widget_id === 5 && item.is_enabled === 1
    );

    const [member, setMember] = useState({ loading: false, data: [], message: "" });
    const ReloadData = async () => {
        setMember({ loading: true, data: [], message: "" })
        const param = { group_id: activeGroup.group_id };
        const result = await GET_USER_SYS(param);

        setMember(result);
    }

    useEffect(() => {
        ReloadData();
    }, [activeGroup])

    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");

    const ResultData = useMemo(() => {
        let computedData = member.data;

        if (search) {
            computedData = computedData.filter((listData) => {
                return Object.keys(listData).some((key) =>
                    listData[key].toString().toLowerCase().includes(search)
                );
            });
        }
        setTotalItems(computedData.length);
        return computedData;
    }, [member.data, search])
    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bold fs-3 mb-1">Membership of group <span className="text-primary">{activeGroup.name}</span></span>
                <span className="text-muted mt-1 fw-semibold fs-7">Over {totalItems} member</span>
            </SGUCard.Header>
            {widget_4 && (
                <SGUCard.Toolbar>
                    <ButtonPrimary className="btn-sm" onClick={() => openModal({ header: "Add Member to " + activeGroup.name, message: <FormUpdate group_id={activeGroup.group_id} ReloadData={ReloadData} HandlerRemove={HandlerRemove} /> })}>
                        <KTIcon iconName='plus' className='fs-3' />
                        Add member
                    </ButtonPrimary>
                </SGUCard.Toolbar>
            )}
            <SGUCard.Body>
                <div className="list-data">
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <SearchBarTable size="w-250px" onChange={(e) => setSearch(e.target.value)} />
                        <ButtonItemRefresh totalItems={totalItems} onClick={() => ReloadData()} />
                    </div>

                    {member.loading ? <LoadingBars /> :
                        member.message ? <AlertNotif messages={member.message} color='danger' /> :
                            ResultData.length > 0 ? (
                                ResultData.map((v, index) => (
                                    <MItem data={v} widget_5={widget_5} key={index} group_id={activeGroup.group_id} ReloadData={ReloadData} />
                                ))
                            ) : <NoRecordFound />}
                </div>
            </SGUCard.Body>
        </SGUCard>
    )
}

const HandlerRemove = async (user_id, group_id, ReloadData, is_feedback = true) => {
    const payload = { user_id, group_id };
    const param = {
        method: "post",
        pathuri: "api/group/member/remove",
        payload: payload
    }
    const result = await RequestData(param);
    if (result.data && Object.values(result.data).length > 0) {
        ReloadData();
        if (is_feedback) {
            openModal({ message: <MessageSuccess message={"Successfully removed"} /> })
        }
    } else {
        if (is_feedback) {
            openModal({ message: <MessageError message={result.message} /> })
        } else {
            alert(result.message);
        }
    }

    return result;
}

const MItem = ({ data, widget_5, group_id, ReloadData }) => {
    return (
        <div className={"d-flex flex-stack p-4 "}>
            <div className="d-flex align-items-center">
                <div className="symbol symbol-45px symbol-circle">
                    {data.is_active === 1 ? (
                        <span className="symbol-label fw-bolder bg-light-success">
                            <KTIcon iconName='shield-tick' className='text-success fs-2x' />
                        </span>
                    ) : (
                        <span className="symbol-label fw-bolder bg-light-danger">
                            <KTIcon iconName='shield-cross' className='text-danger fs-2x' />
                        </span>
                    )}

                </div>
                <div className="ms-5">
                    <span className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2">
                        {data.email}
                    </span>
                    <div className="fw-bold text-gray-500">
                        {data.employeeid}
                    </div>
                </div>
            </div>
            {widget_5 && (
                <div className="btn-group ms-2">
                    <ButtonIcon className="btn-sm btn-light fw-bolder" type="button" title="Remove" onClick={() => HandlerRemove(data.user_id, group_id, ReloadData)}>
                        <KTIcon iconName='trash' className='text-danger fs-2x' />
                    </ButtonIcon>
                </div>
            )}
        </div>
    )
}