import React, { useEffect, useState } from "react";
import { GET_ACTIVE_ACAD, SUBMIT_LEAVE_STUDENT_ACTIVECALENDAR } from "../../../../../apis/node-target-data/AcademicApi";
import { LoadingBars } from "../../../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif, AlertRetry } from "../../../../../../../_layouts/components/alerts/AlertsUI";

export default function FormLeave({ stdAcad, stdBio }) {
  const [activeCal, setActiveCal] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const GET_ACTIVE_ACAD_LOOK_UP = async () => {
    setActiveCal({ loading: true, data: [], message: '' })
    const result = await GET_ACTIVE_ACAD();
    if (result.data && Object.values(result.data).length > 0) {
      var selectIndex = result.data.findIndex(
        (v) => v.atp_group_type.id === stdAcad.atp_group_type
      );
      setActiveCal({
        loading: false,
        data: result.data[selectIndex],
        message: "",
      });
    } else {
      setActiveCal(result);
    }

  }

  useEffect(() => {
    GET_ACTIVE_ACAD_LOOK_UP();
  }, []);


  var studentid = stdAcad.studentid;
  var firstname = stdBio.firstname;
  const objParam = {
    cellular: stdBio ? stdBio.cellular : "",
    note: "",
    studentid: studentid,
  };
  const [postLeave, setPostLeave] = useState(objParam);
  const [isConfirm, setConfirm] = useState(false);
  const [submitLeave, setSubmitLeave] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const handlerSubmit = (e) => {
    setSubmitLeave({ loading: false, data: [], message: "" });
    e.preventDefault();
    if (
      postLeave.cellular &&
      postLeave.note &&
      Object.values(postLeave.note).length > 15
    ) {
      setConfirm(true);
    } else {
      setSubmitLeave({
        loading: false,
        data: [],
        message: "Please fill out this field correctly",
      });
    }
  };

  const ConfirmDialog = () => {
    return (
      <div className="confirm text-center">
        <p className="font-weight-bolder h3 mb-10">
          Are you sure want to submit ?
        </p>
        <button
          className="btn btn-light btn-lg font-weight-bolder me-3"
          type="button"
          onClick={() => setConfirm(false)}
        >
          CANCEL
        </button>
        <button
          className="btn btn-primary btn-lg font-weight-bolder"
          type="button"
          onClick={() => SUBMIT_LEAVE_STUDENT()}
        >
          SUBMIT
        </button>
      </div>
    );
  };

  const SUBMIT_LEAVE_STUDENT = async () => {
    setSubmitLeave({ loading: true, data: [], message: "" });
    //openModal({ open: false });
    console.log(postLeave);
    const param = postLeave;
    const result = await SUBMIT_LEAVE_STUDENT_ACTIVECALENDAR(param);

    if(result.data && Object.values(result.data).length > 0){
      alert("Success updated.")
      setSubmitLeave({ loading: false, data: result.data, message: "Success updated." });
      setInterval(() => {
        window.location.reload();
      }, 1000);
    }else{
      setSubmitLeave(result);
    }
  };

  return (
    <div className="leave-semes">
      {isConfirm ? (
        <ConfirmDialog />
      ) : (
        <>
          <h3 className="mb-5 d-flex align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">
              Make {firstname}'s{" "}
              <span className="text-warning">Leave Semester</span>
            </span>
            <span className="text-muted mt-1 fw-bold fs-7">
              Please fill up the form with correctly
            </span>
          </h3>
          <form
            method="post"
            autoComplete="off"
            onSubmit={(e) => handlerSubmit(e)}
          >
            <div className="row">
              {activeCal.loading ? <LoadingBars /> :
                activeCal.message ? <AlertRetry messages={activeCal.message} Reload={GET_ACTIVE_ACAD_LOOK_UP} /> :
                  (Object.values(activeCal.data).length > 0) && (
                    <div className="col">
                      <div className="mb-7">
                        <label className="fw-bolder required">
                          Academic Term
                        </label>
                        <input type="text" className="form-control" value={activeCal.data.atp_term && (activeCal.data.atp_term.name || "-")} readOnly />
                      </div>
                    </div>
                  )}
              <div className="col">
                <div className="mb-7">
                  <label className="fw-bolder required">
                    Student Mobile Phone Number
                  </label>
                  <input
                    type="text"
                    name="phone"
                    required
                    className="form-control"
                    placeholder="081xxx"
                    onChange={(e) =>
                      setPostLeave({
                        ...postLeave,
                        cellular: e.target.value.replace(/\D/g, ""),
                      })
                    }
                    defaultValue={postLeave.cellular}
                  />
                </div>
              </div>
            </div>
            <div className="mb-7">
              <label className="fw-bolder required">Reason</label>
              <input
                type="text"
                name="note"
                required
                className="form-control"
                maxLength={255}
                minLength={15}
                onChange={(e) =>
                  setPostLeave({ ...postLeave, note: e.target.value })
                }
                defaultValue={postLeave.note}
              />
              <span className="text-muted">Type minimum 15 character</span>
            </div>
            <div className="d-grid">
              <button
                className="btn btn-primary fw-bolder text-uppercase px-9 py-4 btn-warning"
                type="submit"
                disabled={submitLeave.loading}
              >
                {submitLeave.loading ? "Processing..." : "Submit"}
              </button>
            </div>
          </form>
        </>
      )}

      {submitLeave.message ? (
        <div className="my-8">
          <AlertNotif messages={submitLeave.message} color={"info"} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
