import React, { useEffect, useState } from 'react'
import Moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AccessInvalid, RequiredMsg } from '../../../../../_layouts/_core/ErrorMessage';
import { AlertNotif } from '../../../../../_layouts/components/alerts/AlertsUI';
import { ButtonDanger, ButtonPrimary, ButtonTersier } from '../../../../../_layouts/components/buttons/ButtonUI';
import { RoomMultiple, SectionSelect } from '../../../components/forms/SelectBox';
import { CREATE_SCHD_CLASS, GET_COURSE, GET_SCHD, GET_SECTION } from '../../../apis/node-target-data/AcademicApi';
import { LoadingBars, LoadingSkeleton } from '../../../../../_layouts/components/loadbars/LoadingBars';
import { handleKeyDown } from '../../../components/forms/InputText';
import { MessageError, MessageSuccess, openModal } from '../../../../../_layouts/components/modals/Modals';
import moment from 'moment';
import { ExistSchedule, MessageSuccessSubmit } from './TimetableComp';
import { GET_WIDGET_BY_GROUP } from '../../../apis/module/BackendRequest';
import { ExtractWidgetIds } from '../../../../../_layouts/_helpers/Global';
import { AuthDecodeToken } from '../../../../reducer/AuthRedux';
import { useProvider } from '../../../../../_layouts/UMSLayoutProvider';

export default function CreateTimetable({ iscustom = false, date, room_id, ReloadData, section_id, isstyle }) {
    //isstyle = { maxHeight: "300px", overflowX: "hidden", overflowY: "auto" }
    const {account} = useProvider();
    const Token = AuthDecodeToken();
    const [widgets, setWidgets] = useState({ loading: false, data: [], message: "" });
    const widget_49 = (Object.values(widgets.data).length > 0) && widgets.data.find(item => item.widget_id === 49 && item.is_enabled === 1)

    useEffect(()=>{
        FetchWidgets({ group_id: Token.identity.group_id, menu_id: 16 }, setWidgets);
    },[]);
    
    const [section, setSection] = useState({ loading: false, data: [], message: "" });
    const [course, setCourse] = useState({ loading: false, data: [], message: "" });
    const [course_act, setCourseAct] = useState([]);
    const [section_selected, setSectionSelected] = useState({value:section_id, label:section_id});
    const GET_SECTION_SELECTED = async (section_id) => {
        if(section_id){
            setCourse({ loading: true, data: [], message: "" });
            setSection({ loading: true, data: [], message: "" });
            const param = { section_id: section_id };
            const result = await GET_SECTION(param);
            if (result.data && Object.values(result.data).length > 0) {
                const r_data = result.data[0];
                const param_course = { courseid: (r_data.course && r_data.course.id || "") }
                const result_course = await GET_COURSE(param_course);
                const c_data = result_course.data[0];

                setSectionSelected({ value: r_data.section_id, label: (r_data.section_id + ": " + (c_data.coursetitle || "") + "(" + (c_data.credit || "-") + " SKS)") });
                setCourse({ loading: false, data: c_data, message: "" });
                setCourseAct(c_data.course_act ? c_data.course_act : []);
                setSection({ loading: false, data: r_data, message: "" })
            } else {
                setSection(result);
                setCourse(result);
                setCourseAct([]);
                setSectionSelected({value:"", label:""});
            }
        }
    }

    useEffect(() => {
        if (section_id) {
            GET_SECTION_SELECTED(section_id);
        }
    }, [section_id])

    useEffect(() => {
        if (section_selected.value !== section_id) {
            GET_SECTION_SELECTED(section_selected.value);
        }
    }, [section_selected.value]);

    const [time_gap, setTime_gap] = useState(10);
    const [bulk_session, setBulk_session] = useState(1);
    const [is_makeup, setIs_makeup] = useState(0);
    const [label, setLabel] = useState("");

    const [start_dt, setStart_dt] = useState(date);
    const HandlerStartDate = (date) => {
        setStart_dt(date)
        createTimesLoops(date, duration, time_gap, bulk_session);
    }

    const [activity_type, setActivity_type] = useState("");
    const [duration, setDuration] = useState(0);
    const handlerActivityType = (e) => {
        var index = e.target.selectedIndex;
        var optionElement = e.target.childNodes[index];
        var duration = optionElement.getAttribute("data-duration");
        var value = e.target.value;
        setActivity_type(value);
        setDuration(duration);
        createTimesLoops(start_dt, duration, time_gap, bulk_session);
    };

    const handlerBulkTime = (value) => {
        setBulk_session(value);
        createTimesLoops(start_dt, duration, time_gap, value);
    };

    const handlerBreakMinutes = (value) => {
        setTime_gap(value);
        createTimesLoops(start_dt, duration, value, bulk_session);
    };

    const [listTime, setListTime] = useState([]);
    const [end_dttime, setEndDtTime] = useState();
    const createTimesLoops = (start_dt, duration, time_gap, bulk_session) => {
        var results = [], endDttime = "";
        setListTime([]);
        var start_dt_fin = start_dt;
        for (let index = 1; index <= bulk_session; index++) {
            var end_dt = Moment(start_dt_fin)
                .add(duration, "m")
                .toDate();
            let st_time_format = Moment(start_dt_fin).format("hh:mm A");
            let ed_time_format = Moment(end_dt).format("hh:mm A");
            results.push(st_time_format + "-" + ed_time_format);
            endDttime = ed_time_format;
            var consecutive = Moment(end_dt)
                .add(time_gap, "m")
                .toDate();
            start_dt_fin = consecutive;
        }

        setListTime(results);
        const start_dt_clone = {...start_dt};
        const start_date = moment(start_dt_clone).format("YYYY-MM-DD");
        const end_date = moment(start_date+" "+endDttime).toDate();
        setEndDtTime(end_date);
    };

    const [rooms, setRooms] = useState([]);

    const [existSchd, setExistSchd] = useState([]);
    const [submit, setSubmit] = useState({loading:false, data:[], message:""});
    const HandlerSubmitSchd = async (e) => {
        e.preventDefault();
        setExistSchd([]);
        if((rooms && Object.values(rooms).length > 0) && (start_dt && activity_type && bulk_session && time_gap && section_selected.value)){
            setSubmit({loading:true, data:[], message:""});
            const start_date = moment(start_dt).format("YYYY-MM-DD");
            const endDttime = moment(end_dttime).format("HH:mm:ss");
            const end_date = moment(start_date+" "+endDttime).format("YYYY-MM-DD HH:mm:ss");
            let param = {
                activity_type,
                bulk_session,
                is_makeup,
                label,
                section_id:section_selected.value,
                start_dt:moment(start_dt).format("YYYY-MM-DD HH:mm:ss"),
                end_dt:end_date,
                time_gap
            }
            param.room = rooms.map(v=>{
                let obj={};
                obj.room_id = v.value
                return obj;
            });
            
            //check existing schd by room
            const result = await CheckExisitngSCHD(param);
            console.log("existing:",result);
            if(result.type && (result.type === "exist" && Object.values(result.data).length > 0)){
                setExistSchd({data:result.data, param:param});
                setSubmit({loading:false, data:[], message:""});
            }else{
                setSubmit(result);
            }
            //end check
        }else{
            setSubmit({loading:false, data:[], message:RequiredMsg});
        }
    }

    const CheckExisitngSCHD = async (param) =>{
        let room_id = "";
        param.room.map((v,index)=>{
            room_id += v.room_id + ((Object.values(param.room).length - 1) > index ? "," : "");
        });

        let param_schd = {
            start_dt: moment(param.start_dt).format("YYYY-MM-DDTHH:mm:ss"),
            end_dt: moment(param.end_dt).format("YYYY-MM-DDTHH:mm:ss"),
            rooms: room_id,
            grouped: "True"
        }
        
        if(account.typeCK === "development"){
            param_schd.between = 1
        }


        let result = await GET_SCHD(param_schd);
        if(result.data && Object.values(result.data).length > 0){
            result.type = "exist";
            return result;
        }else{
            return CREATE_SCEDULE(param);  
            //openModal({message:<MessageError message={result.message} />});
            // alert(result.message);
            // return result;
        }
    }

    const CREATE_SCEDULE = async (param) =>{
        setExistSchd([]);
        setSubmit({loading:true, data:[], message:""});

        const result = await CREATE_SCHD_CLASS(param);
        if(result.data && Object.values(result.data).length > 0){
            openModal({message:iscustom ? <MessageSuccess message={"Successfully saved"} /> : <MessageSuccessSubmit />});
            setStart_dt("");
            setTime_gap(10);
            setBulk_session(1);
            setIs_makeup(0);
            setLabel("");
            setActivity_type("");
            setRooms([]);
            setListTime([]);
            
            if(ReloadData){
                ReloadData();
            }else{
                if(section_id){
                    setSectionSelected({value:section_id, label:section_id});
                    GET_SECTION_SELECTED(section_id);
                }
            }
        }else{
            openModal({message:<MessageError message={result.message} />});
        }
        setSubmit(result);
        return result
    }

    return widgets.loading ? <LoadingSkeleton />:
    widgets.message ? <AlertNotif messages={widgets.message} /> :
    widget_49 ? (
        <form
            method="post"
            id="pop-up-session"
            autoComplete="off"
            onSubmit={(e) => HandlerSubmitSchd(e)}
            className="form-schedule"
        >
            <>
                <div style={isstyle}>
                    <div className="row">
                        <div className="col-lg-12">
                            {section_id ? (
                                <div className="mb-5">
                                    <label className="fw-bold">Section Course:</label>
                                    <p className="mb-0 border rounded p-5 bg-light">
                                        {section_selected.label}
                                    </p>
                                </div>
                            ) : (
                                <>
                                    <SectionSelect data={section_selected} setData={setSectionSelected} title={"Find Section Course"} required />
                                </>
                            )}
                            
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto">
                            <div className="form-group mb-5">
                                <label className="fw-bold required">Activity Type</label>
                                {course.loading ? <LoadingBars /> :
                                    course.message ? <AlertNotif messages={course.message} /> :
                                        <select name="act_id" required className="form-select form-select-sm" tabIndex={1} defaultValue={activity_type} onChange={(e) => handlerActivityType(e)}>
                                            <option value="">Choose One</option>
                                            {Object.values(course_act).length > 0 && (
                                                course_act.map((v, index) => (
                                                    <option value={v.activity_type ? v.activity_type.id : 0} data-duration={v.duration_schd} key={index}>
                                                        {v.activity_type ? v.activity_type.name : "-"}
                                                        (Duration {v.duration_schd}m & Session {v.weight})
                                                    </option>
                                                ))
                                            )}
                                        </select>
                                }
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="form-group mb-5">
                                <label className="fw-bold">Is Makeup Class</label>
                                <select name="is_makeup" className="form-select form-select-sm" tabIndex={2} defaultValue={is_makeup} onChange={(e) => setIs_makeup(parseInt(e.target.value))}>
                                    <option value="0">No</option>
                                    <option value="1">Yes</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-auto">
                            <div className="form-group mb-5">
                                <label className="required fw-bold">Start Date</label>
                                <div>
                                    <DatePicker className="form-control form-control-sm" dateFormat={"yyyy-MM-dd hh:mm aa"}
                                        showTimeSelect
                                        timeIntervals={5}
                                        readOnly={iscustom}
                                        tabIndex={3}
                                        placeholderText='Enter date' selected={start_dt} onChange={(date) => HandlerStartDate(date)}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }} />
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-2">
                            <div className="form-group mb-5">
                                <label className="required fw-bold">
                                    Break Minutes
                                </label>
                                <input type="text" tabIndex={4} className="form-control form-control-sm w-100px text-center" defaultValue={time_gap} onChange={(e) => handlerBreakMinutes(parseInt(e.target.value))} onKeyDown={handleKeyDown} />
                                <span className="fs-8 text-danger">
                                    *)Type zero (0) if no break time
                                </span>
                            </div>

                        </div>
                        <div className="col-auto">
                            <div className="d-flex justify-content-start">
                                <div className="form-group mb-5">
                                    <label className="required fw-bold">
                                        Consecutive Session
                                    </label>
                                    <input type="text" tabIndex={5} className="form-control form-control-sm w-80px text-center" defaultValue={bulk_session} onChange={(e) => handlerBulkTime(parseInt(e.target.value))} onKeyDown={handleKeyDown} />
                                </div>

                                <div className='ms-8 d-flex flex-column'>
                                    {Object.values(listTime).length > 0 && (
                                        <>
                                            <span className="required fw-bold text-dark d-block">Times loops</span>
                                            {listTime.map((v, index) => (
                                                <span
                                                    className="badge badge-light-primary fs-base me-2 mb-2"
                                                    key={index}
                                                >
                                                    {" "}
                                                    <i className="bi bi-clock me-2"></i> {v}
                                                </span>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <RoomMultiple data={rooms} setData={setRooms} required room_id={room_id} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="form-group mb-5">
                                <label className="fw-bold">Label</label>
                                <input type="text" tabIndex={6} className="form-control form-control-sm" placeholder='exp: Event MIT / Thesis Defense' value={label} onChange={(e) => setLabel(e.target.value)} />
                            </div>
                        </div>
                        <div className="col-lg-12"></div>
                    </div>
                </div>
            

                {submit.message && (
                    <div className="my-5">
                        <AlertNotif messages={submit.message} />
                    </div>
                )}

                {Object.values(existSchd).length > 0 ? (
                    <div className="my-5">
                        <ExistSchedule schd={existSchd} CREATE_SCEDULE={CREATE_SCEDULE} setExistSchd={setExistSchd} />
                    </div>
                ):(
                    <div className="text-end">
                        {!iscustom && (
                            <ButtonTersier type="button" className="btn-lg me-3 fw-bold" onClick={()=>{openModal({header:"Confirmation",message:<ConfirmLeave />})}}>
                                Cancel
                            </ButtonTersier>
                        )}
                        <ButtonPrimary type="submit" className="btn-lg fw-bold" disabled={submit.loading}>
                            {submit.loading ? "Processing...":"Save Changes"}
                        </ButtonPrimary>
                    </div>
                )}
            </>
        </form>
    ) : <AlertNotif messages={AccessInvalid} />
}

const ConfirmLeave = () =>{
    return (
        <div className='text-center'>
            <p className="fs-3">Are you sure want to leave this page?</p>
            <div className="btn-group">
                <ButtonTersier className="btn-lg fw-bold" onClick={()=>openModal({open:false})}>No</ButtonTersier>
                <ButtonDanger className="btn-lg fw-bold" onClick={()=>window.close()}>Leave this page</ButtonDanger>
            </div>
        </div>
    )
}


const FetchWidgets = async (param, setWidgets) => {
    setWidgets({ loading: true, data: [], message: "" });
    const result = await GET_WIDGET_BY_GROUP(param);

    if (result.data && Object.values(result.data).length > 0) {
        const isenabled = result.data.find((item) => item.is_enabled === 1);
        const data = ExtractWidgetIds(isenabled);
        setWidgets({ loading: false, message: "", data: data });
    } else {
        setWidgets(result);
    }
}