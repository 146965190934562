import { useEffect, useState } from "react";

export function PMatriculations({ data, t_credit, t_credit_ext }) {
  
  const theadtitle = [
    { name: "course", width: 0 },
    { name: "credit", width: 80 },
    { name: "score", width: 80 },
  ];
  return (
    <table style={styles.table_border}>
      <thead>
        <tr style={styles.thead}>
          <th
            width="50%"
            style={styles.c_name}
            colSpan={3}
          >
            SGU Equivalent Course
          </th>
          <th
            width="50%"
            style={styles.c_name}
            colSpan={3}
          >
            Previous Institution
          </th>
        </tr>
        <tr style={styles.thead}>
          {theadtitle.concat(theadtitle).map((v, index) => (
            <th
              key={index}
              style={styles.c_name}
            >
              {v.name}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((v, index) => (
          <tr key={index}>
            <td style={styles.c_name}>{v.course ? (v.course?.coursetitle) : v.coursetitle}</td>
            <td style={{ ...styles.c_name, textAlign: "center" }}>{v.course ? (v.course?.credit) : v.credit} SKS</td>
            <td style={{ ...styles.c_name, textAlign: "center" }}>{v.score}</td>
            <td style={styles.c_name}>{v.course_ext}</td>
            <td style={{ ...styles.c_name, textAlign: "center" }}>{v.credit_ext} SKS</td>
            <td style={{ ...styles.c_name, textAlign: "center" }}>{v.score_ext}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={6} height={"15px"}></td>
        </tr>
        <tr style={styles.thead}>
          <td style={{ ...styles.c_name, fontWeight:"bold"}}>Total</td>
          <td style={{ ...styles.c_name, textAlign: "center", fontWeight:"bold" }}>{t_credit} SKS</td>
          <td style={styles.c_name}></td>
          <td style={styles.c_name}></td>
          <td style={{ ...styles.c_name, textAlign: "center", fontWeight:"bold" }}>{t_credit_ext} SKS</td>
          <td style={styles.c_name}></td>
        </tr>
      </tfoot>
    </table>
  );
}

const styles = {
  container: { width: "595px" },
  thead: { backgroundColor: "#F6C000", fontWeight: "bold", fontSize: "0.75rem", textAlign: "center", textTransform: "capitalize" },
  title: { letterSpacing: "normal", wordSpacing: "normal", fontWeight: "bold" },
  table_border: { borderCollapse: "collapse", border: "1px solid black", letterSpacing: "normal", width: "100%" },
  c_name: { verticalAlign: "middle", border: "1px solid black", fontSize: "0.75rem" },
};
