import React, { useEffect, useState } from "react";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { ButtonIcon, ButtonPrimary } from "../../../../../../_layouts/components/buttons/ButtonUI";
import { GET_COUNSELOR, UPDATE_COUNSELOR } from "../../../../apis/node-target-data/AcademicApi";
import { LoadingBars } from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../../../_layouts/components/alerts/AlertsUI";
import { useOutletContext } from "react-router-dom";
import Select from "react-select";
import { GET_EMPLOYEE_LOOK_UP } from "../../../../apis/node-target-data/HRAPI";
import { MessageError, MessageSuccess, openModal } from "../../../../../../_layouts/components/modals/Modals";
import { RequiredMsg } from "../../../../../../_layouts/_core/ErrorMessage";

export default function CardCounselor({ studentid }) {
  const context = useOutletContext();
  const widget_74 = context.widgets.find(
    (item) => item.widget_id === 74 && item.is_enabled === 1
  );
  const [openform, setOpenForm] = useState(false);
  const [results, setResults] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const postParam = {
    studentid: studentid,
  };

  const ReloadData = async () => {
    setOpenForm(false);
    setResults({ loading: true, message: "", data: [] });
    const result = await GET_COUNSELOR(postParam);

    setResults(result);
  };

  useEffect(() => {
    ReloadData();
  }, []);
  return (
    <div className="card h-100" style={{ backgroundColor: "rgb(28, 50, 94)" }}>
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="text-danger fs-2x">Counselor</h3>
          {widget_74 && (
            <ButtonIcon
              className="btn-sm btn-clear"
              type="button"
              title="Update Counselor"
              onClick={() => setOpenForm(!openform)}
            >
              <KTIcon
                iconName={!openform ? "notepad-edit" : "cross-square"}
                className="text-warning fs-1"
              />
            </ButtonIcon>
          )}
        </div>

        {results.loading ? (
          <LoadingBars />
        ) : results.message ? (
          <AlertRetry messages={results.message} Reload={ReloadData} />
        ) : Object.values(results.data).length > 0 ? (
          openform ? (
            <FormCounselor studentid={studentid} ReloadData={ReloadData} />
          ) : (
            results.data.map(
              (v, index) =>
                v.emp && (
                  <div key={index} className="text-dark">
                    <p className="fs-4 fw-bolder text-white">
                      <span className="d-block">{v.emp.fullname}</span>
                      <span className="text-muted">{v.emp.email}</span>
                    </p>
                  </div>
                )
            )
          )
        ) : (
          <p className="text-white text-center">-</p>
        )}
      </div>
    </div>
  );
}

const FormCounselor = ({ studentid, ReloadData }) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState([]);
  const [selectedValue, setSelectedValue] = useState([]);
  const [submitCounselor, setSubmitCounselor] = useState({
    emp_id: 0,
    replace: true,
    students: studentid,
  });

  const FindData = async (inputValue) => {
    if (inputValue.length >= 3) {
        const param = { search: inputValue };
        const result = await GET_EMPLOYEE_LOOK_UP(param);

        if(result.data && Object.values(result.data).length > 0){
            const options = result.data.map((item) => ({
                value: item.id,
                label: item.name_related,
            }));
            setOptions(options);
        }
        
    }
    setInputValue(inputValue);
  };

  const handleSelected = (selectedValue) => {
    setSubmitCounselor({ ...submitCounselor, emp_id: selectedValue.value });
    setSelectedValue(selectedValue);
  };

  const [result, setResult] = useState({loading:"", message:"", data:[]});
  const HandlerSubmit = async (e) => {
    e.preventDefault();
    console.log(submitCounselor);
    if(submitCounselor.emp_id){
        setResult({loading:true, message:"", data:[]});
        const param = submitCounselor;
        const result = await UPDATE_COUNSELOR(param);
        
        if(result.data && Object.values(result.data).length > 0){
            setResult({loading:false, message:"Counselor updated successfully", data:result.data});
            openModal({message:<MessageSuccess message={"Counselor updated successfully"} />})
            ReloadData();
        }else{
            setResult({loading:false, message:result.message, data:[]});
            openModal({message:<MessageError message={result.message} />});
        }
    }else{
        openModal({message:<MessageError message={RequiredMsg} />});
    }
  };

  return (
    <form method="post" autoComplete="off" onSubmit={(e)=>HandlerSubmit(e)}>
      <div className="mb-5">
        <label className="required fw-bolder text-white">Find Counselor</label>
        <Select
          placeholder={"minim 3 character"}
          options={options}
          onInputChange={FindData}
          inputValue={inputValue}
          onChange={handleSelected}
          value={selectedValue}
        />
      </div>

      <div className="d-grid mb-5">
        <ButtonPrimary
          className="btn-sm fw-bolder text-uppercase px-9 py-4"
          type="submit"
          disabled={result.loading}
        >
          {result.loading ? "Processing..." : "Save Changes"}
        </ButtonPrimary>
      </div>
    </form>
  );
};
