import React, { useState } from 'react'
import { Department, DepartmentSpec } from '../../../../../components/forms/SelectBox'
import { Semester } from '../../../../../components/forms/InputText';
import { ButtonPrimary, ButtonTersier } from '../../../../../../../_layouts/components/buttons/ButtonUI';
import { MessageError, openModal } from '../../../../../../../_layouts/components/modals/Modals';
import { RequiredMsg } from '../../../../../../../_layouts/_core/ErrorMessage';
import { RemoveEmptyValue } from '../../../../../../../_layouts/_helpers/Global';
import { APPEND_SECTION_PREREQ } from '../../../../../apis/node-target-data/AcademicApi';

export default function UpdateStudyProgram({ section_id, setIsopen }) {
    const [clu_id, setClu_id] = useState(0);
    const [clu_spec_id, setClu_spec_id] = useState(0);
    const [semester, setSemester] = useState("");
    const [std_class, setStd_class] = useState("A");

    let paramPost = {
        clu_id,
        section_id: section_id,
        semester,
        std_class
    }
    const [submit, setSubmit] = useState(false);
    const SubmitHandler = async (e) => {
        e.preventDefault();
        if (paramPost.clu_id && paramPost.semester && paramPost.std_class) {
            setSubmit(true);
            const param = RemoveEmptyValue(paramPost);
            const result = await APPEND_SECTION_PREREQ(param);

            if (result.data && Object.values(result.data).length > 0) {
                setSubmit(false);
                setInterval(() => {
                    window.location.reload();
                }, 100);
            } else {
                setSubmit(false);
                openModal({ message: <MessageError message={result.message} /> });
            }
        } else {
            openModal({ message: <MessageError message={RequiredMsg} /> });
        }
    }
    return (
        <div className='bg-light p-4 rounded'>
            <h4 className='text-start mb-5'>
                <i className="ki-outline ki-notepad-edit fs-4 me-1 text-danger"></i>
                Add Study Program Qualification
            </h4>
            <form method="post" autoComplete='off' onSubmit={(e) => SubmitHandler(e)}>
                <Department data={clu_id} setData={setClu_id} required={true} />
                <DepartmentSpec data={clu_spec_id} setData={setClu_spec_id} />
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <Semester data={semester} setData={setSemester} required={true} />
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="form-group mb-5">
                            <label className="fw-bolder required">
                                Class Group
                            </label>
                            <input type="text" className="form-control form-control-sm" defaultValue={std_class} onChange={(e) => setStd_class(e.target.value)} />
                        </div>
                    </div>
                </div>
                <div className="text-end">
                    <ButtonTersier type="button" className="btn-sm fw-bold me-3" onClick={() => setIsopen(false)}>
                        Cancel
                    </ButtonTersier>
                    <ButtonPrimary type="submit" className="btn-sm fw-bold" disabled={submit}>
                        {submit ? (
                            <>
                                <span
                                    className="spinner-grow spinner-grow-sm"
                                    aria-hidden="true"
                                ></span>
                                <span className="visually-hidden" role="status">
                                    Loading...
                                </span>
                            </>
                        ) : "Save changes"}

                    </ButtonPrimary>
                </div>
            </form>
        </div>
    )
}

