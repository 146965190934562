import { OverlayTrigger, Tooltip } from "react-bootstrap";

const AlertNotif = ({ title, messages, color = "danger" }) => {
  return (
    <div
      className={"alert alert-" + color + " d-flex align-items-center p-5 mb-5"}
    >
      <span className={"svg-icon svg-icon-2hx svg-icon-" + color + " me-3"}>
        <i
          className={"bi bi-shield-fill-exclamation fs-2x text-" + color + ""}
        ></i>
      </span>

      <div className="d-flex flex-column">
        <h5 className="mb-1">{title}</h5>
        <span>{messages}</span>
      </div>
    </div>
  );
};

const AlertRetry = ({ title, messages, color = "danger", Reload }) => {
  return (
    <div
      className={"alert alert-" + color + " d-flex align-items-center p-5 mb-5"}
    >
      <span className={"svg-icon svg-icon-2hx svg-icon-" + color + " me-3"}>
        <i
          className={"bi bi-shield-fill-exclamation fs-2x text-" + color + ""}
        ></i>
      </span>

      <div className="d-flex flex-column">
        {title && <h5 className="mb-1">{title}</h5>}
        <span>{messages}</span>
        <button
          className="btn btn-sm btn-danger fw-bold"
          type="button"
          onClick={Reload}
        >
          <i className="bi bi-arrow-clockwise text-white"></i>
          Retry here
        </button>
      </div>
    </div>
  );
};

const AlertFailed = ({ title, messages, color = "danger", Reload }) => {
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Click to reload
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <div
        className={
          "alert alert-" +
          color +
          " d-flex align-items-center p-5 mb-5 cursor-pointer"
        }
        onClick={(e) => Reload(e)}
      >
        <span className={"svg-icon svg-icon-2hx svg-icon-" + color + " me-3"}>
          <i
            className={"bi bi-shield-fill-exclamation fs-2x text-" + color + ""}
          ></i>
        </span>
        <div className="d-flex flex-column">
          <h5 className="mb-1">{title}</h5>
          <span>{messages}</span>
        </div>
      </div>
    </OverlayTrigger>
  );
};

export { AlertNotif, AlertRetry, AlertFailed };
