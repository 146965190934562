import React, { useRef } from 'react'
import Moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { openModal } from '../../../../../../_layouts/components/modals/Modals';
import { Link } from 'react-router-dom';

export default function FullCalendarStdSchd(props) {
    const calendarRef = useRef();

    const eventRender = (info) => {
        let item = info.event;
        const groupData = JSON.parse(item.groupId);
        return (
            <div className={"event-item w-100 cursor-pointer border rounded px-1 " + (groupData.is_cancel ? "bg-danger border-danger" : "bg-light-primary border-primary")} onClick={() => detailEvent(item)}>
                <p className="fs-8 mb-0 text-wrap">
                    <span className={"fw-bold " + (groupData.is_cancel ? "text-white" : "")}>{Moment(item.start).format("H:mm")} -{" "}{Moment(item.end).format("H:mm")}</span>
                    <span className={"fw-bolder d-block " + (groupData.is_cancel ? "text-white" : "")}>{(item.title.length > 20) ? item.title.substring(0, 20) + "..." : item.title}</span>
                    {(Object.values(groupData.event_room).length > 0) ? (
                        <span className={"d-block " + (groupData.is_cancel ? "text-white" : "")}>
                            Room: {" "}
                        {groupData.event_room.map((v, index) => (
                            <span key={index}>{v.room ? v.room.g_resourceName + "(" + v.room.g_capacity + ")" : "-"}</span>
                        ))}
                        </span>
                    ) : ""}
                </p>
            </div>

        );
    };

    const detailEvent = (data) => {
        openModal({
            message: <DetailSchedule data={data} />,
            header: "Selected Schedule",
        });
    };

    const DetailSchedule = ({ data }) => {
        const groupData = JSON.parse(data.groupId);
        console.log(groupData);
        return (
            <div className="detail-schd table-responsive">
                <table className="table">
                    <tbody>
                        {groupData.is_cancel ? (
                            <tr>
                                <td colSpan={3} className='bg-danger text-white text-center'>CLASS IS CANCEL</td>
                            </tr>
                        ) : ""}
                        {data.id ? (
                            <>
                                <tr>
                                    <td className='fw-bolder' width={"15%"}>
                                        Section ID
                                    </td>
                                    <td width={"2%"}>:</td>
                                    <td>
                                        <Link to={"course-managements/section/"+data.id+"/detail"} className='text-dark text-hover-primary'>{data.id}</Link>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='fw-bolder'>
                                        Course
                                    </td>
                                    <td>:</td>
                                    <td>
                                        <Link to={"course-managements/section/"+data.id+"/detail"} className='text-dark text-hover-primary'>{data.title}</Link>
                                    </td>
                                </tr>
                            </>
                        ) : (
                            <tr>
                                <td className='fw-bolder'>
                                    Event
                                </td>
                                <td>:</td>
                                <td>
                                    {data.title}
                                </td>
                            </tr>
                        )}
                        
                        <tr>
                            <td className='fw-bolder'>
                                Room
                            </td>
                            <td>:</td>
                            <td>
                                {(groupData.event_room) ? (
                                    groupData.event_room.map((v, index) => (
                                        <span key={index}>{v.room ? v.room.g_resourceName + "(" + v.room.g_capacity + ")" : "-"} {v.room ? (v.room.g_floorName ? ("Floor " + v.room.g_floorName) : "") : ""},</span>
                                    ))
                                ) : ""}
                            </td>
                        </tr>
                        <tr>
                            <td className='fw-bolder'>
                                Date and Time
                            </td>
                            <td>:</td>
                            <td>
                                <span className="me-2">{Moment(data.start).format('D MMMM YYYY')},</span>
                                <span>{Moment(data.start).format('H:mm')} - {Moment(data.end).format('H:mm')}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className='fw-bolder'>
                                Department
                            </td>
                            <td>:</td>
                            <td>
                                {(groupData.section_pre) ? (
                                    groupData.section_pre.map((d, index) => (
                                        <div className="text-dark" key={index} title={(d.clu) ? d.clu.name : ""}>
                                            <span>{(d.clu) ? d.clu.code : ""}</span>
                                            <span>{(d.clu_spec) ? ("-" + d.clu_spec.code) : ""}</span>
                                            <span>-Semester {d.semester}</span>
                                        </div>
                                    ))
                                ) : ""}
                            </td>
                        </tr>
                        {(groupData.atd_lecturer) ? (
                            <tr>
                                <td className='fw-bolder'>Teaching Topic</td>
                                <td>:</td>
                                <td>{(groupData.atd_lecturer.teaching_topic) ? groupData.atd_lecturer.teaching_topic : "-"}</td>
                            </tr>
                        ) : ""}

                        {(groupData.atd_lecturer) ? (
                            <tr>
                                <td className='fw-bolder'>Submit at</td>
                                <td>:</td>
                                <td>{(groupData.atd_lecturer.submit_formatted) ? groupData.atd_lecturer.submit_formatted : "-"}</td>
                            </tr>
                        ) : ""}
                    </tbody>
                </table>
            </div>
        )
    }

    return (
        <div>
            <FullCalendar
                ref={calendarRef}
                initialView="dayGridMonth"
                headerToolbar={{ start: '', center: '', end: '' }}
                plugins={[dayGridPlugin]}
                initialDate={props.initialDate}
                validRange={{ start: props.paramDate.tgl, end: props.paramDate.tglend }}
                events={Object.values(props.data).length > 0 ? props.data : []}
                eventContent={eventRender}
                eventColor='#000'
                eventTextColor='yellow'
                eventDidMount={(info) => {
                    if (info.event.extendedProps.background) {
                        info.el.style.background = info.event.extendedProps.background;
                    }
                    if (info.event.extendedProps.border) {
                        info.el.style.borderColor = info.event.extendedProps.border;
                    }
                }}
            />
        </div>
    )
}
