import React, { useEffect, useMemo, useState } from "react";
import { ButtonItemRefresh, HeaderDatatables, PaginationComponent } from "../../../../../_layouts/components/datatables/MYDatatables";
import { LoadingSkeleton, NoRecordFound, SpinnerLoad } from "../../../../../_layouts/components/loadbars/LoadingBars";
import { SearchBarTable } from "../components/TCUI";
import { ButtonDropdown, ButtonIcon, ButtonTersier } from "../../../../../_layouts/components/buttons/ButtonUI";
import moment from "moment";
import { StudentIDFormat } from "../../../../../_layouts/_helpers/Global";
import { KTIcon } from "../../../../../_metronic/helpers";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import { useNavigate } from "react-router-dom";
import { GET_ENROLLMENT_PROCESS } from "../../../apis/node-target-data/MarketingAPI";
import { MessageError, openModal } from "../../../../../_layouts/components/modals/Modals";
import { GET_TRANSFER_PROPOSAL } from "../../../apis/node-target-data/AcademicApi";

export default function TableData({ is_choosen = false, HandlerAddStudentCopy }) {

  const [results, setResults] = useState({ loading: false, message: "", data: [] });

  const ReloadData = async () => {
    setResults({ loading: true, message: "", data: [] });
    const param = { "": "" };
    const result = await GET_TRANSFER_PROPOSAL(param);
    if (result.data && Object.values(result.data).length > 0) {
      const remove_status = result.data.filter(item => item.status === "DRAFT" || item.status === "SUBMITTED");
      setResults({ loading: false, message: "", data: remove_status });
    } else {
      setResults(result);
    }
  }

  useEffect(() => {
    ReloadData();
  }, []);

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 20;

  const headers = [
    { name: "No", field: "id", sortable: false },
    { name: "Reg ID", field: "registration_id", sortable: true },
    { name: "Name", field: "candidatename", sortable: true },
    { name: "Institution", field: "institution", sortable: true },
    { name: "Total Credit", field: "total_credit", sortable: false },
    { name: "Status", field: "status", sortable: true },
    { name: "Created Date", field: "created_at", sortable: false },
    { name: "Actions", field: "id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (Object.values(results.data).length > 0) {
      computedData = results.data.map(v => {
        let obj = {};
        obj.candidate_id = v.candidate_id || "";
        obj.candidatename = v.fullname || "";
        obj.id = v.id || "";
        obj.status = v.status || "";
        obj.doc_date = v.doc_date || "";
        obj.audit_created = v.audit_created || "";
        obj.audit_createdby = v.audit_createdby || "";
        obj.university_name = v.university_name || "";
        obj.university_prodi_name = v.university_prodi_name || "";
        obj.transfer_proposal_dt = v.transfer_proposal_dt || "";
        obj.total_credit = v.transfer_proposal_dt ?
          v.transfer_proposal_dt.reduce((sum, item) => sum + (parseFloat(item.course.credit) || 0), 0)
          : 0;
        obj.total_credit_ext = v.transfer_proposal_dt ?
          v.transfer_proposal_dt.reduce((sum, item) => sum + (parseFloat(item.credit_ext) || 0), 0)
          : 0;
        return obj;
      })
    }

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key]
            .toString()
            .toLowerCase()
            .includes(search)
        );
      });
    }
    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [results.data, search, sorting, currentPage]);

  return (
    <div>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          placeholder="Find here"
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex">
          <div className="mx-2">
            <ButtonItemRefresh totalItems={totalItems} onClick={() => ReloadData()} />
          </div>
        </div>
      </div>

      <div className="table-responsives">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {results.loading ? (
              <tr>
                <td colSpan={8}><LoadingSkeleton /></td>
              </tr>
            ) : results.message ? (
              <tr>
                <td colSpan={8}><AlertNotif messages={results.message} /></td>
              </tr>
            ) :
              ResultData.length > 0 ? (
                ResultData.map((v, index) => (
                  <tr key={index}>
                    <td className="fw-bold fs-7">{index + 1}</td>
                    <td className="fw-bold fs-7">
                      {StudentIDFormat(v.candidate_id)}
                    </td>
                    <td className="fw-bold fs-7">{v.candidatename}</td>
                    <td className="fw-bold fs-7">{v.university_name}</td>
                    <td className="fw-bold fs-7">
                      <span title="Prev Institute">{v.total_credit_ext} SKS</span>
                      <span className="mx-2">·</span>
                      <span title="SGU Equivalent">{v.total_credit} SKS</span>
                      </td>
                    <td className="fw-bold fs-7">
                      {v.status === "DRAFT" && (
                        <span className="badge badge-light">
                          <KTIcon iconName="file-right" className="fs-1x" />
                          <span className="fs-8 ms-1">{v.status}</span>
                        </span>
                      )}

                      {v.status === "SUBMITTED" && (
                        <span className="badge badge-light-success">
                          <KTIcon iconName="abstract-18" className="fs-1x text-success" />
                          <span className="fs-8 ms-1">{v.status}</span>
                        </span>
                      )}

                    </td>
                    <td>
                      <span className="d-block fw-bold fs-7">
                        {v.audit_created
                          ? moment(v.audit_created).format("DD MMM YYYY hh:mm A")
                          : ""}
                      </span>
                      <span className="text-muted fs-8">{v.audit_createdby}</span>
                    </td>
                    <td>
                      {is_choosen ? <ButtonChoose data={v} action={HandlerAddStudentCopy} /> : <ButtonActions data={v} />}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={7}>
                    <NoRecordFound />
                  </td>
                </tr>
              )}
          </tbody>
        </table>

        {totalItems > 0 && (
          <div className="footer">
            <PaginationComponent
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

const ButtonActions = ({ data }) => {
  const navigate = useNavigate();
  const [candidates, setCandidates] = useState({ loading: false, data: [], message: "" });
  const DetailCandidate = async (candidate_id, proposal_id, action = "") => {
    setCandidates({ loading: true, data: [], message: "" })
    const param = { candidate_id: candidate_id };
    const result = await GET_ENROLLMENT_PROCESS(param);
    if (result.data && result.data.length > 0) {
      const data_candidate = result.data[0];
      const dataParam = {
        candidates: data_candidate.candidate_id,
        target_dept: data_candidate.target_department?.name,
        university_id: data_candidate.regstudent?.university_id,
        university_prodi: data_candidate.regstudent?.university_prodi_id,
        courses: data.transfer_proposal_dt,
        doc_date:data.doc_date,
        total_credit: { totalCredit: data.total_credit, totalCreditExt: data.total_credit_ext },
      };

      if (proposal_id && action === "update") {
        const mcourse = data.transfer_proposal_dt.map(item => ({ ...item, id: item.course_id, coursetitle: item.course.coursetitle, code: item.course.code, credit: item.course.credit, course_dt_id: item.id }));
        navigate('/course-managements/transfer-credit-proposal/form', {
          state: {
            data: dataParam,
            proposal_id: data.id,
            mycourses: mcourse,
            courseData: mcourse
          }
        });
      } else if (proposal_id && action === "download") {
        navigate('/course-managements/transfer-credit-proposal/preview', {
          state: {
            data: dataParam,
            proposal_id: data.id,
            mycourses: data.transfer_proposal_dt,
            result_draft: data
          }
        });
      } else {
        navigate('/course-managements/transfer-credit-proposal/preview', {
          state: {
            data: dataParam,
            proposal_id: data.id,
            mycourses: data.transfer_proposal_dt,
            result_draft: data
          }
        });
      }
    } else {
      openModal({ message: <MessageError message={result.message} /> })
    }
    setCandidates(result);
  }

  if (data.status === "DRAFT") {
    return candidates.loading ? (
      <ButtonIcon type="button" className="btn-sm btn-light">
        <SpinnerLoad />
      </ButtonIcon>
    ) : (
      <ButtonDropdown
        icon={"setting-2"}
        className="btn btn-icon btn-sm no-caret"
      >
        <div className="dropdown-item cursor-pointer" onClick={() => DetailCandidate(data.candidate_id)}>
          <span className="text-success">Submit Proposal</span>
        </div>
        <div className="dropdown-item cursor-pointer" onClick={() => DetailCandidate(data.candidate_id, data.id, "update")}>
          <span className="text-dark">Update</span>
        </div>
        <div className="dropdown-item bg-light-danger cursor-pointer">
          <div className="cursor-pointer">
            <span className="text-danger fw-bold">
              Delete
            </span>
          </div>
        </div>
      </ButtonDropdown>
    )
  } else {
    return (
      <ButtonTersier className="btn-sm" type="button" title="Download" disabled={candidates.loading} onClick={() => DetailCandidate(data.candidate_id, data.id, "download")} >
        {candidates.loading ? <SpinnerLoad /> : (
          <>
            <KTIcon iconName="download" className="fs-2" />
            Download Proposal
          </>
        )}
      </ButtonTersier>
    )
  }
}

const ButtonChoose = ({ data, action }) => {
  return (
    <ButtonIcon className="btn-sm" type="button" title="Copy data" onClick={() => action(data)}>
      <KTIcon iconName="plus-circle" className="fs-2" />
    </ButtonIcon>
  )
}