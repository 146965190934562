import React from 'react'
import { NoRecordFound } from '../../../../../../_layouts/components/loadbars/LoadingBars'
import ActivityList from './ActivityList'
import Scholarshiplist from './Scholarshiplist'

export function ActivityHistory({ stdAcad, stdBio }) {
  return (
    (Object.values(stdAcad).length > 0 && Object.values(stdBio).length > 0) ? (
        <div className='row gx-5 gx-xl-10'>
            <div className="col-xl-8 mb-10">
                <ActivityList name={stdBio.firstname} stdAcad={stdAcad} />
            </div>
            <div className="col-xl-4 mb-10">
                <Scholarshiplist stdAcad={stdAcad} />
            </div>
        </div>
    ) : (
        <NoRecordFound />
    )
  )
}
