import { ReqAPIEstes } from "../ApiBackend";

const GET_ADDRESS_BY_REFF = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_ADDRESS_BY_REFF",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_LOOK_UP_COUNTRY = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_LOOK_UP_COUNTRY",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_LOOK_UP_CITY = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_LOOK_UP_CITY",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const CREATE_ADDRESS = async (param) => {
  const payload = {
    parameters: param,
    target_data: "CREATE_ADDRESS",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const UPDATE_ADDRESS = async (param) => {
  const payload = {
    parameters: param,
    target_data: "UPDATE_ADDRESS",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const DELETE_ADDRESS = async (param) => {
  const payload = {
    parameters: param,
    target_data: "DELETE_ADDRESS",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_FAMILY_BY_REFF = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_FAMILY_BY_REFF",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const CREATE_FAMILY = async (param) => {
  const payload = {
    parameters: param,
    target_data: "CREATE_FAMILY",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_PROFESSION_LOOKUP = async () => {
  const payload = {
    target_data: "GET_PROFESSION_LOOKUP",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_INSTITUTIONHIST_BY_REFF = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_INSTITUTIONHIST_BY_REFF",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_LOOK_UP_SCHOOL = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_LOOK_UP_SCHOOL",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_SCHOOL_PROGRAM_LOOKUP = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_SCHOOL_PROGRAM_LOOKUP",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_UNIV_LOOKUP = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_UNIV_LOOKUP",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_UNIV_PRODI_LOOKUP = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_UNIV_PRODI_LOOKUP",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const CREATE_INSTITUTIONHIST = async (param) => {
  const payload = {
    parameters: param,
    target_data: "CREATE_INSTITUTIONHIST",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const UPDATE_INSTITUTIONHIST = async (param) => {
  const payload = {
    parameters: param,
    target_data: "UPDATE_INSTITUTIONHIST",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const DELETE_INSTITUTIONHIST = async (param) => {
  const payload = {
    parameters: param,
    target_data: "DELETE_INSTITUTIONHIST",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_COMPANY = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_COMPANY",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const CREATE_COMPANY = async (param) => {
  const payload = {
    parameters: param,
    target_data: "CREATE_COMPANY",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const UPDATE_COMPANY = async (param) => {
  const payload = {
    parameters: param,
    target_data: "UPDATE_COMPANY",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const CREATE_COUNTRY = async (param) => {
  const payload = {
    parameters: param,
    target_data: "CREATE_COUNTRY",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_PROVINCE_LOOKUP = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_PROVINCE_LOOKUP",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const CREATE_PROVINCE = async (param) => {
  const payload = {
    parameters: param,
    target_data: "CREATE_PROVINCE",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const CREATE_CITY = async (param) => {
  const payload = {
    parameters: param,
    target_data: "CREATE_CITY",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};


const GET_COUNTRY_BY_ID = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_COUNTRY_BY_ID",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_PROVINCE_BY_ID = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_PROVINCE_BY_ID",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const CREATE_SCHOOL = async (param) => {
  const payload = {
    parameters: param,
    target_data: "CREATE_SCHOOL",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const CREATE_UNIV = async (param) => {
  const payload = {
    parameters: param,
    target_data: "CREATE_UNIV",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const CREATE_UNIV_PRODI = async (param) => {
  const payload = {
    parameters: param,
    target_data: "CREATE_UNIV_PRODI",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const DELETE_UNIV_PRODI = async (param) => {
  const payload = {
    parameters: param,
    target_data: "DELETE_UNIV_PRODI",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const UPDATE_UNIV_PRODI = async (param) => {
  const payload = {
    parameters: param,
    target_data: "UPDATE_UNIV_PRODI",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const UPDATE_SCHOOL_PROGRAM = async (param) => {
  const payload = {
    parameters: param,
    target_data: "UPDATE_SCHOOL_PROGRAM",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const UPDATE_UNIV = async (param) => {
  const payload = {
    parameters: param,
    target_data: "UPDATE_UNIV",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const DELETE_UNIVERSITY = async (param) => {
  const payload = {
    parameters: param,
    target_data: "DELETE_UNIVERSITY",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const UPDATE_COUNTRY = async (param) => {
  const payload = {
    parameters: param,
    target_data: "UPDATE_COUNTRY",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const UPDATE_CITY = async (param) => {
  const payload = {
    parameters: param,
    target_data: "UPDATE_CITY",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const DELETE_CITY = async (param) => {
  const payload = {
    parameters: param,
    target_data: "DELETE_CITY",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const UPDATE_PROVINCE = async (param) => {
  const payload = {
    parameters: param,
    target_data: "UPDATE_PROVINCE",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_UNIV_BY_ID = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_UNIV_BY_ID",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

const GET_UNIV_PRODI_BY_ID = async (param) => {
  const payload = {
    parameters: param,
    target_data: "GET_UNIV_PRODI_BY_ID",
  };
  const result = await ReqAPIEstes(payload);

  return result;
};

export {
  GET_ADDRESS_BY_REFF,
  GET_LOOK_UP_COUNTRY,
  GET_LOOK_UP_CITY,
  CREATE_ADDRESS,
  UPDATE_ADDRESS,
  DELETE_ADDRESS,
  GET_FAMILY_BY_REFF,
  CREATE_FAMILY,
  GET_PROFESSION_LOOKUP,
  GET_INSTITUTIONHIST_BY_REFF,
  GET_LOOK_UP_SCHOOL,
  GET_SCHOOL_PROGRAM_LOOKUP,
  GET_UNIV_LOOKUP,
  GET_UNIV_PRODI_LOOKUP,
  CREATE_INSTITUTIONHIST,
  UPDATE_INSTITUTIONHIST,
  DELETE_INSTITUTIONHIST,
  GET_COMPANY,
  CREATE_COMPANY,
  UPDATE_COMPANY,
  CREATE_COUNTRY,
  GET_PROVINCE_LOOKUP,
  CREATE_PROVINCE,
  CREATE_CITY,
  GET_COUNTRY_BY_ID,
  GET_PROVINCE_BY_ID,
  CREATE_SCHOOL,
  CREATE_UNIV,
  UPDATE_SCHOOL_PROGRAM,
  UPDATE_UNIV,
  DELETE_UNIVERSITY,
  UPDATE_COUNTRY,
  UPDATE_CITY,
  DELETE_CITY,
  UPDATE_PROVINCE,
  CREATE_UNIV_PRODI,
  GET_UNIV_BY_ID,
  UPDATE_UNIV_PRODI,
  DELETE_UNIV_PRODI,
  GET_UNIV_PRODI_BY_ID
};
