import ImgBoy from "../../_assets/media/avatars/008-boy-3.svg";
import ImgGirl from "../../_assets/media/avatars/014-girl-7.svg";
import ImgBlank from "../../_assets/media/avatars/blank.svg";

const AvaBoy = ({...others}) =>{
    return (
        <img src={ImgBoy} alt="SGU-UMS" {...others} />
    );
}
const AvaGirl = ({...others}) =>{
    return (
        <img src={ImgGirl} alt="SGU-UMS" {...others} />
    );
}

const AvaBlank = ({...others}) =>{
    return (
        <img src={ImgBlank} alt="SGU-UMS" {...others} />
    );
}

const AvaFirstName = ({name, ...others}) =>{
    return (
        <div className="symbol symbol-45px me-2" {...others} >
            <span className="symbol-label bg-light-primary text-primary fw-bolder fs-2">
                {name}
            </span>
        </div>
    );
}

export {AvaBoy, AvaGirl, AvaBlank, AvaFirstName};