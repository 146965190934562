import React, { useEffect, useState } from 'react'
import { ImDtCalendarWeeks } from './ImDtCalendarWeeks';
import { SGUCard } from '../../../../../_layouts/components/cards/CardUI';
import { LoadingSkeleton } from '../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertRetry } from '../../../../../_layouts/components/alerts/AlertsUI';
import { ImDtCalendarMonth } from './ImDtCalendarMonth';
import { ImDtCalendarTables } from './ImDtCalendarTables';
import { ButtonIcon } from '../../../../../_layouts/components/buttons/ButtonUI';
import { ScheduleAccessStatus } from './LoadSchdAccess';

export default function CalendarImportanceDate() {

    const [typeCal, setTypeCal] = useState(3);

    const [results, setResults] = useState({
        loading: false,
        data: [],
        message: "",
    });

    const [result_table_view, setTableView] = useState([]);

    const ReloadData = async () => {
        setTableView([]);
        setResults({ loading: true, data: [], message: "" });

        const result_data = await ScheduleAccessStatus();
        if(result_data && (result_data.table_data && Object.values(result_data.table_data).length > 0) && (result_data.month_data && Object.values(result_data.month_data).length > 0)){
            setTableView(result_data.table_data);
            setResults({ loading: false, message: "", data: result_data.month_data });
        }else{
            setTableView([]);
            setResults(result_data);    
        }
    }

    useEffect(() => {
        ReloadData();
    }, [])

    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bolder text-dark">
                    Access Status Dashboard
                </span>
                <span className="text-muted fs-8">by current active calendar</span>
            </SGUCard.Header>
            <SGUCard.Toolbar>
                <div className="d-flex align-items-center">
                    <select name="type_cal" className="form-select form-select-sm w-100px" defaultValue={typeCal} onChange={(e) => setTypeCal(parseInt(e.target.value))}>
                        <option value="1">Month</option>
                        <option value="3">Tables</option>
                    </select>
                    <ButtonIcon type="button" className="btn-sm btn-light-primary ms-1" title="Refresh" onClick={() => ReloadData()}>
                        <i className="bi bi-arrow-clockwise"></i>
                    </ButtonIcon>
                </div>
            </SGUCard.Toolbar>
            <SGUCard.Body className={"px-0 pb-0"}>
                {results.loading ? <LoadingSkeleton /> :
                    results.message ? <AlertRetry messages={results.message} Reload={ReloadData} /> : (
                        typeCal === 1 ? <ImDtCalendarMonth data={results.data} /> : (
                            typeCal === 2 ? <ImDtCalendarWeeks data={results.data} /> : <ImDtCalendarTables data={result_table_view} />
                        )
                    )}
            </SGUCard.Body>
        </SGUCard>
    )
}

