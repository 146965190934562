import { SGUCard } from '../../../../../_layouts/components/cards/CardUI';
import TableData from './TableData';

export function TCRegisProposal() {
  
  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bold text-gray-800">
          Transfer Credit Registration
        </span>
      </SGUCard.Header>
      <SGUCard.Body>
        <TableData />
      </SGUCard.Body>
    </SGUCard>
  )
}
