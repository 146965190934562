import ImgUnderMaintenance from "../../_assets/media/background/undermaintenance-1.gif"


const MaintenanceLight = ({...others}) =>{
    return (
        <img src={ImgUnderMaintenance} alt="SGU-UMS" {...others} />
    );
}
const MaintenanceDark = ({...others}) =>{
    return (
        <img src={ImgUnderMaintenance} alt="SGU-UMS" {...others} />
    );
}

export {MaintenanceLight, MaintenanceDark};