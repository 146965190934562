import React, { useState } from 'react'
import { StudentMultiple } from '../../components/forms/SelectBox';
import { ButtonLong } from '../../../../_layouts/components/buttons/ButtonUI';
import { RequiredMsg } from '../../../../_layouts/_core/ErrorMessage';
import { UPDATE_COUNSELOR } from '../../apis/node-target-data/AcademicApi';
import { openModal } from '../../../../_layouts/components/modals/Modals';
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';

export default function FormAssignStudent({ emp_id }) {
    const paramObj = {
        emp_id: emp_id,
        replace: true
    }
    const [results, setResults] = useState({
        loading: false,
        message: "",
        data: [],
    });

    const [students, setStudents] = useState([]);
    const HandlerSubmit = async (e) => {
        e.preventDefault();
        if (Object.values(students).length > 0) {
            setResults({loading:true, message:"", data:[]})
            const param = paramObj;
            const stdlst = students.map((v) => v.value);
            param.students = stdlst.toString();
            
            const result = await UPDATE_COUNSELOR(param);
            if(result.data && Object.values(result.data).length > 0){
                setResults({loading:false, message:"Successfully Saved", data:result.data});
                setInterval(() => {
                    openModal({open:false})
                }, 1000);
            }else{
                setResults(result);
            }
        } else {
            setResults({loading:false, message:RequiredMsg, data:[]});
        }
    }
    return (
        <div>
            <h1>Student Counselor Registration Form</h1>
            <form method="post" onSubmit={(e) => HandlerSubmit(e)}>
                <StudentMultiple data={students} setData={setStudents} required />

                {results.message && <div className="my-8"><AlertNotif messages={results.message} color={Object.values(results.data).length > 0 && results.message ? "success":"danger"} /></div>}
                <ButtonLong className="btn-primary" type="submit" disabled={results.loading} >
                    Submit
                </ButtonLong>
            </form>
        </div>
    )
}
