import React, { useEffect, useState } from 'react'
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SGUCard } from '../../../../../../_layouts/components/cards/CardUI'
import { CandidateSelect } from '../../components/TCUI'
import { GET_ENROLLMENT_PROCESS } from '../../../../apis/node-target-data/MarketingAPI';
import { LoadingBars } from '../../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../../../_layouts/components/alerts/AlertsUI';
import { AllUnivMajorTransSelect, AllUnivSelect } from '../../../../components/forms/SelectBox';
import { useLocation } from 'react-router-dom';
import { KTIcon } from '../../../../../../_metronic/helpers';

export default function CandidateData({ cadidates, setDcandidate, setCandidates, univ, setUniv, univProdi, setUnivProdi, setTargetDept, doc_date, setDoc_date }) {
    const location = useLocation();
    const unividstorage = location.state ? location.state.data.university_id : 0
    const univprodistorage = location.state ? location.state.data.university_prodi : 0

    const [candidate, setCandidate] = useState({ loading: false, message: "", data: [] });
    const [localUniv, setLocalUniv] = useState(univ);
    const [localUnivProdi, setLocalUnivProdi] = useState(univProdi);

    const GetDetailCandidate = async () => {
        setCandidate({ loading: true, message: "", data: [] });
        const param = { candidate_id: cadidates };
        const result = await GET_ENROLLMENT_PROCESS(param);
        if (result.data && Object.values(result.data).length > 0) {
            setDcandidate(result.data[0]);
            setCandidate({ loading: false, message: "", data: result.data[0] });

            if (result.data[0].target_department) {
                setTargetDept(result.data[0].target_department.name);
            }
            if (result.data[0].regstudent && result.data[0].regstudent.university_id) {
                setLocalUniv(result.data[0].regstudent.university_id);
                setLocalUnivProdi(result.data[0].regstudent.university_prodi_id);
                setUniv(result.data[0].regstudent.university_id)
                setUnivProdi(result.data[0].regstudent.university_prodi_id);
            } else {
                setLocalUniv(unividstorage);
                setUniv(unividstorage);
                setLocalUnivProdi(univprodistorage);
                setUnivProdi(univprodistorage);
            }
        } else {
            setDcandidate("");
            setCandidate(result);
        }
    }

    useEffect(() => {
        if (cadidates) {
            GetDetailCandidate();
        }
    }, [cadidates]);

    useEffect(() => {
        setLocalUniv(univ);
        setLocalUnivProdi(univProdi);
    }, [univ, univProdi]);

    const handleUnivChange = (value) => {
        setLocalUniv(value);
        setUniv(value);
    };

    const handleUnivProdiChange = (value) => {
        setLocalUnivProdi(value);
        setUnivProdi(value);
    };

    return (
        <SGUCard>
            <SGUCard.Body className={"pb-0 pt-8"}>
                <div className="row">
                    <div className="col-lg-4">
                        <CandidateSelect data={cadidates} setData={setCandidates} required />
                    </div>
                    {candidate.loading ? <div className="col-auto"><LoadingBars /></div> :
                        candidate.message ? <div className="col-auto"><AlertNotif messages={candidate.message} /></div> : (
                            <>
                                <div className="col-lg-4">
                                    <AllUnivSelect data={localUniv} setData={handleUnivChange} title={"Previous Institution"} required />
                                </div>
                                {localUniv !== 0 && (
                                    <div className="col-lg-4">
                                        <AllUnivMajorTransSelect univid={localUniv} data={localUnivProdi} setData={handleUnivProdiChange} title={"Major at prev insitution"} required />
                                    </div>
                                )}
                                <div className="col-lg-4">
                                    <div className="form-group">
                                        <label className="fw-bold">Target Department</label>
                                        <input type="text" readOnly className="form-control form-control-sm" value={((candidate.data.target_department && candidate.data.target_department.name)) ? candidate.data.target_department.name : ""} />
                                    </div>
                                </div>
                            </>
                        )}
                    <div className="col-auto">
                        <div className="form-group">
                            <label className="fw-bold required">Date of Matriculation</label>
                            <div className="position-relative">
                                <DatePicker className="form-control form-control-sm" dateFormat={"yyyy-MM-dd"}
                                    minDate={doc_date}
                                    placeholderText='Enter date' selected={doc_date} onChange={(date) => setDoc_date(date)}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }} />
                                <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                                    <KTIcon iconName='calendar' className='fs-1 text-dark' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </SGUCard.Body>
        </SGUCard>
    )
}
