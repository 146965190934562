import React, { useEffect, useState } from 'react'
import { CREATE_ADDRESS, CREATE_FAMILY, GET_FAMILY_BY_REFF, GET_PROFESSION_LOOKUP } from '../../../../apis/node-target-data/IdentityApi';
import { LoadingSkeleton } from '../../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../../../_layouts/components/alerts/AlertsUI';
import { ButtonPrimary, ButtonTersier } from '../../../../../../_layouts/components/buttons/ButtonUI';
import { openModal } from '../../../../../../_layouts/components/modals/Modals';
import { handleKeyDown } from '../../../../components/forms/InputText';
import { RemoveEmptyValue } from '../../../../../../_layouts/_helpers/Global';

export default function FormFamily({ stdBio }) {
    const [parents, setParents] = useState({ loading: false, data: [], message: "" })
    const ReloadData = async () => {
        setParents({ loading: true, data: [], message: "" });
        const param = {
            "reffid": stdBio.reff_family_id ? stdBio.reff_family_id : "-"
        }
        const result = await GET_FAMILY_BY_REFF(param);

        setParents(result);
    };

    useEffect(() => {
        ReloadData();
    }, []);
    return (
        <div>
            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                    <tr className="fw-bolder text-muted">
                        <th>Fullname</th>
                        <th>Profession</th>
                        <th>Contact Person</th>
                    </tr>
                </thead>
                <tbody>
                    {parents.loading ? (
                        <tr>
                            <td colSpan={3}><LoadingSkeleton /></td>
                        </tr>
                    ) : parents.message ? (
                        <tr>
                            <td colSpan={3}><AlertNotif messages={parents.message} /></td>
                        </tr>
                    ) : (
                        Object.values(parents.data).length > 0 ? (
                            parents.data.map((v, index) => (
                                <tr key={index}>
                                    <td>
                                        <span className="d-block fw-bolder">{v.fullname}</span>
                                        <span className="text-muted text-uppercase fw-bolder">{v.type}</span>
                                    </td>
                                    <td><span className="d-block fw-bolder">
                                        {v.profession_other ? v.profession_other : (v.profession ? v.profession.name : "-")}
                                    </span>
                                    </td>
                                    <td>
                                        {Object.values(v.address).length > 0 ? (
                                            <div>
                                                {v.address[0].address ? (
                                                    <span className='d-block'>
                                                        <i className="bi bi-pin-map-fill"></i> {v.address[0].address}
                                                    </span>
                                                ) : "-"}
                                                {v.address[0].cellular1 ? (
                                                    <span className='d-block'><i className="bi bi-phone"></i> {v.address[0].cellular1}</span>
                                                ) : ""}
                                                {v.address[0].email ? (
                                                    <span className='d-block'><i className="bi bi-envelope-at"></i> {v.address[0].email}</span>
                                                ) : ""}
                                            </div>
                                        ) : "-"}
                                    </td>
                                </tr>
                            ))
                        ) : ""
                    )}
                </tbody>
                <tfoot>
                    <tr>
                        <td colSpan={3} className='text-center'>
                            <ButtonPrimary className="btn-sm fw-bold" type='button' disabled={parents.loading} onClick={() => openModal({ header: "Form Add Family", message: <FormParent reff_id={stdBio.reff_family_id} ReloadData={ReloadData} />, size: "xl" })}>
                                Add New Family
                            </ButtonPrimary>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    )
}


const FormParent = ({ reff_id, ReloadData }) => {
    const [postFams, setPostFams] = useState({ loading: false, data: [], message: "" })
    const [profession, setProfession] = useState({ loading: false, data: [], message: "" })
    const ReloadProfesion = async () => {
        setProfession({ loading: true, data: [], message: "" })
        const result = await GET_PROFESSION_LOOKUP();
        setProfession(result);
    }

    const [paramFams, setParamFams] = useState({
        "reff_id": reff_id,
        "firstname": "",
        "middlename": "",
        "lastname": "",
        "gender": "",
        "profession_id": 0,
        "profession_other": "",
        "type": ""
    })
    const [address, setAddress] = useState({
        address: "",
        cellular1: "",
        email: ""
    });

    useEffect(() => {
        ReloadProfesion();
    }, [])

    const HandlerPostFams = async (e) => {
        e.preventDefault();
        setPostFams({ loading: true, message: "Successfu", data: [] })
        const param_fams = RemoveEmptyValue(postFams);
        const result_fams = await CREATE_FAMILY(param_fams);

        if (result_fams.data && Object.values(result_fams.data).length > 0) {
            const reff_address = result_fams.data.reff_address;
            if (reff_address) {
                let cloneAddr = { ...address };
                cloneAddr.reff_id = reff_address;
                let param_addr = RemoveEmptyValue(cloneAddr);
                const result_addr  = await CREATE_ADDRESS(param_addr);
                setPostFams(result_addr);
                ReloadData();
            }
        } else {
            setPostFams(result_fams);
        }

    }

    return (
        <form method='post' id='form-family' autoComplete='off' onSubmit={(e) => HandlerPostFams(e)}>
            <div className="row mb-5">
                <div className="col-4">
                    <div className="mb-5">
                        <label className='required fw-bolder'>Firstname</label>
                        <input type="text" required name="firstname" className="form-control text-capitalize" tabIndex={1} defaultValue={paramFams.firstname} onChange={(e) => setParamFams({ ...paramFams, firstname: e.target.value })} />
                    </div>
                </div>

                <div className="col-4">
                    <div className="mb-5">
                        <label className='fw-bolder'>Middlename</label>
                        <input type="text" name="middlename" className="form-control text-capitalize" tabIndex={2} defaultValue={paramFams.middlename} onChange={(e) => setParamFams({ ...paramFams, middlename: e.target.value })} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="mb-5">
                        <label className='required fw-bolder'>Lastname</label>
                        <input type="text" required name="lastname" className="form-control text-capitalize" tabIndex={3} defaultValue={paramFams.lastname} onChange={(e) => setParamFams({ ...paramFams, lastname: e.target.value })} />
                    </div>
                </div>

                <div className="col-2">
                    <div className="mb-5">
                        <label className='required fw-bolder'>Gender</label>
                        <select name="gender" className='form-select' defaultValue={paramFams.gender} onChange={(e) => setParamFams({ ...paramFams, gender: e.target.value })}>
                            <option value="">Choose One</option>
                            <option value="p">Male</option>
                            <option value="w">Female</option>
                        </select>
                    </div>
                </div>

                <div className="col-2">
                    <div className="mb-5">
                        <label className='required fw-bolder'>Type</label>
                        <select name="type" className='form-select' defaultValue={paramFams.type} onChange={(e) => setParamFams({ ...paramFams, type: e.target.value })}>
                            <option value="">Choose One</option>
                            <option value="FATHER">Father</option>
                            <option value="MOTHER">Mother</option>
                            <option value="SISTER">Sister</option>
                            <option value="BROTHER">Brother</option>
                            <option value="OTHER">Other</option>
                        </select>
                    </div>
                </div>

                <div className="col-4">
                    <div className="mb-5">
                        <label className='fw-bolder required'>Profession</label>
                        <select name="profession" required className='form-select' defaultValue={paramFams.profession_id} onChange={(e) => setParamFams({ ...paramFams, profession_id: parseInt(e.target.value) })}>
                            <option value="0">Choose One</option>
                            {Object.values(profession.data).length > 0 ? (
                                profession.data.map((v, index) => (
                                    <option value={v.id} key={index}>{v.name}</option>
                                ))
                            ) : ""}
                        </select>
                    </div>
                </div>

                <div className="col-4">
                    <div className="mb-5">
                        <label className={'fw-bolder ' + (paramFams.profession_id === 4 ? "required" : "")}>Profession Other</label>
                        <input type="text" name="profession_other" required={paramFams.profession_id === 4 ? true : false} className="form-control text-capitalize" disabled={paramFams.profession_id === 4 ? false : true} defaultValue={paramFams.profession_other} onChange={(e) => setParamFams({ ...paramFams, profession_other: e.target.value })} />
                    </div>
                </div>
            </div>

            <h3>Contact Person</h3>
            <div className="mb-5 row">
                <div className="col-12">
                    <div className="mb-5">
                        <label className='required fw-bolder'>Address</label>
                        <input type="text" required name="address" className="form-control" tabIndex={4} defaultValue={address.address} onChange={(e) => setAddress({ ...address, address: e.target.value })} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="">
                        <label className='required fw-bolder'>Cellular</label>
                        <input type="text" required name="cellular1" className="form-control" tabIndex={5} defaultValue={address.cellular1} onChange={(e) => setAddress({ ...address, cellular1: e.target.value })} maxLength={15} onKeyDown={handleKeyDown} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="">
                        <label className='fw-bolder'>Email</label>
                        <input type="text" name="email" className="form-control" tabIndex={6} defaultValue={address.email} onChange={(e) => setAddress({ ...address, email: e.target.value })} />
                    </div>
                </div>
            </div>

            {postFams.message ? (
                <div className="my-5">
                    <AlertNotif messages={postFams.message} color={Object.values(postFams.data).length > 0 ? "info" : "danger"} />
                </div>
            ) : ""}

            <div className="text-end">
                <div className="btn-group">
                    <ButtonTersier className="btn-sm fw-bolder text-uppercase px-9 py-4" type='reset' onClick={() => openModal({ open: false })}>Cancel</ButtonTersier>
                    <ButtonPrimary className="btn btn-primary fw-bolder text-uppercase px-9 py-4" disabled={postFams.loading} type='submit'>
                        {postFams.loading ? "Processing..." : "Save"}
                    </ButtonPrimary>
                </div>
            </div>

        </form>
    )
}