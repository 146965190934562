import React, { useState } from 'react'
import PackageJSON from "../../../../../package.json";
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';
import { AccessInvalid, RequiredMsg } from '../../../../_layouts/_core/ErrorMessage';
import { useOutletContext } from 'react-router-dom';
import FilterUI from '../../components/filters/FilterUI';
import { MessageError, openModal } from '../../../../_layouts/components/modals/Modals';
import { GET_RPT_TEACHINGLOAD } from '../../apis/node-target-data/AcademicApi';
import { AcadTerm, CourseOwner, Department, EmployeeSelect } from '../../components/forms/SelectBox';
import { CourseLookUp, LectLookUp, SectionID, Semester } from '../../components/forms/InputText';
import { SGUCardFlush } from '../../../../_layouts/components/cards/CardUI';
import { LoadingSkeleton } from '../../../../_layouts/components/loadbars/LoadingBars';
import TableData from './TableData';
import { RemoveEmptyValue } from '../../../../_layouts/_helpers/Global';
import { KTIcon } from '../../../../_metronic/helpers';
import CalendarWeek from './CalendarWeek';
import { ButtonTersier } from '../../../../_layouts/components/buttons/ButtonUI';

export function Timetables() {
  const context = useOutletContext();
  const widget_47 = context.widgets.find(
    (item) => item.widget_id === 47 && item.is_enabled === 1
  );

  const [term_id, setTerm_id] = useState(PackageJSON.system_param.ActiveAcad);
  const [semester, setSemester] = useState("");
  const [clu_id, setCluId] = useState("");
  const [emp_id, setEmp_id] = useState(0);
  const [cost_center, setCost_center] = useState(0);
  const [section_id, setSection_id] = useState("");
  const [course, setCourse] = useState("");
  const [course_id, setCourseID] = useState(0);
  const [offset, setOffset] = useState(1);
  const limit = 10;

  const postParam = {
    cost_center,
    term_id,
    emp_id,
    semester,
    clu_id,
    section_id,
    course_id,
  };

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const ReloadData = async (paramPost) => {
    setResults({ loading: true, data: [], message: "" });

    const param = RemoveEmptyValue(paramPost);
    const result = await GET_RPT_TEACHINGLOAD(param);
    setResults(result);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const SubmitFilter = (e) => {
    e.preventDefault();
    if (postParam.term_id) {
      let param = { ...postParam };
      param.offset = 1;
      param.limit = 10;
      ReloadData(param);
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  };

  const HandlerPage = (type) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let current_offset = offset;
    if (type == 1) {
      current_offset = offset - 1;
    } else {
      current_offset = offset + 1;
    }
    setOffset(current_offset);
    const param = { ...postParam };
    param.offset = current_offset;
    param.limit = 10;
    ReloadData(param);
  };

  const [activeView, setActiveView] = useState("table");

  return widget_47 ? (
    <div className="row">
      <div className="col-lg-3 mb-5">
        <FilterUI title="Timetable" onSubmit={SubmitFilter} isload={results.loading}>
          <AcadTerm data={term_id} setData={setTerm_id} required />
          <Department data={clu_id} setData={setCluId} />
          <Semester data={semester} setData={setSemester} />
          <CourseOwner data={cost_center} setData={setCost_center} />
          <SectionID data={section_id} setData={setSection_id} />
          <CourseLookUp
            course={course}
            setCourse={setCourse}
            setCourseID={setCourseID}
          />
          <EmployeeSelect data={emp_id} setData={setEmp_id} title={"Lecturer"} />
        </FilterUI>
      </div>
      <div className="col-lg-9 mb-5">
        <SGUCardFlush>
          <SGUCardFlush.Header>
            <span className="card-label fw-bolder text-dark">
              Browse Timetable
            </span>
            <span className="text-muted mt-1 fw-bold fs-7">
              List of timetable by filter
            </span>
          </SGUCardFlush.Header>
          <SGUCardFlush.Toolbar>
            <ul className="nav nav-pills nav-line-pills border rounded p-1">
              <li className="nav-item">
                <ButtonTersier
                  className={'btn-sm fw-semibold nav-link btn-color-gray-500 fs-7 ' + (activeView === "table" && "active")}
                  onClick={() => setActiveView("table")}
                >
                  <KTIcon iconName='element-11' className='fs-2' />
                  <span className="nav-text">Table View</span>
                </ButtonTersier>
              </li>
              <li className="nav-item">
                <ButtonTersier
                  className={'btn-sm fw-semibold nav-link btn-color-gray-500 fs-7 ' + (activeView === "calendar" && "active")}
                  onClick={() => setActiveView("calendar")}
                >
                  <KTIcon iconName='calendar' className='fs-2' />
                  <span className="nav-text">Calendar View</span>
                </ButtonTersier>
              </li>
            </ul>
          </SGUCardFlush.Toolbar>
          <SGUCardFlush.Body className={"pt-0 pb-0"}>
            {results.loading ? (
              <LoadingSkeleton />
            ) : results.message ? (
              <AlertNotif messages={results.message} />
            ) : (
              <div className='tab-content'>
                {activeView === "table" && (
                  <div className='pb-8'>
                    <TableData results={results} ReloadData={SubmitFilter} />
                    <div className="w-100">
                      {Object.values(results.data).length > 0 && (
                        <div className="footer">
                          <ul className="pagination">
                            <li
                              className={
                                "page-item " +
                                (offset !== 1 ? "cursor-pointer" : "disabled")
                              }
                            >
                              <span
                                className="page-link"
                                onClick={() => HandlerPage(1)}
                              >
                                <span>‹ Prev</span>
                              </span>
                            </li>
                            <li className="page-item active">
                              <span className="page-link text-white">
                                Page {offset}
                              </span>
                            </li>
                            <li
                              className={
                                "page-item " +
                                (Object.values(results.data).length === limit
                                  ? "cursor-pointer"
                                  : "disabled")
                              }
                            >
                              <span
                                className="page-link"
                                onClick={() => HandlerPage(2)}
                              >
                                <span>Next ›</span>
                              </span>
                            </li>
                          </ul>
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </SGUCardFlush.Body>
        </SGUCardFlush>

        {activeView === "calendar" && (
          <CalendarWeek param={postParam} />
        )}

      </div>
    </div>
  ) : <AlertNotif messages={AccessInvalid} />
}
