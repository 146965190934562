import React, { useEffect, useState } from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { GET_THS } from "../../apis/node-target-data/AcademicApi";
import { AlertNotif, AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import { Error404 } from "../../errors/components/Error404";
import { LoadingSkeleton, NoRecordFound } from "../../../../_layouts/components/loadbars/LoadingBars";
import { HeaderThesis } from "./components/ThesisDefenseUI";

export function DetailThesisDefense() {
  const context = useOutletContext();
  const widget_76 = context.widgets.find(
    (item) => item.widget_id === 76 && item.is_enabled === 1
  );

  let params = useParams();
  const token = params.token;

  try {
    const decode = atob(token);
    const data = JSON.parse(decode);
    return widget_76 ? (
      <DetailPage data={data} />
    ) : (
      <AlertNotif messages={AccessInvalid} />
    );
  } catch (error) {
    return <Error404 />;
  }
}

const DetailPage = ({data}) => {
  const context = useOutletContext();
  const widget_77 = context.widgets.find(
    (item) => item.widget_id === 77 && item.is_enabled === 1
  );
  const widget_78 = context.widgets.find(
    (item) => item.widget_id === 78 && item.is_enabled === 1
  );
  const widget_79 = context.widgets.find(
    (item) => item.widget_id === 79 && item.is_enabled === 1
  );
  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const GetThsDetail = async () => {
    setResults({ loading: true, data: [], message: "" });

    const param = { term_id:data.term_id, ths_id: data.thesis_id };
    const result = await GET_THS(param);
    if (result.data && Object.values(result.data).length > 0) {
      const r_data = result.data[0];
      setResults({ loading: false, data: r_data, message: "" });
    } else {
      setResults(result);
    }
  };

  useEffect(() => {
    GetThsDetail();
  }, []);

  return results.loading ? <LoadingSkeleton /> :
    results.message ? <AlertRetry messages={results.message} Reload={GetThsDetail} /> : 
    Object.values(results.data).length > 0 ? (
      <div className="detail-thesis">
        <HeaderThesis data={results.data} />
        <Outlet context={{ data:results.data, widgets:{ widget_77, widget_78, widget_79 } }} />
      </div>
    ) : <NoRecordFound />;
};
