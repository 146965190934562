import React, { useMemo, useState } from "react";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  PaginationComponent,
  SearchBarTable,
} from "../../../../_layouts/components/datatables/MYDatatables";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import moment from "moment";
import { useOutletContext } from "react-router-dom";
import { UpdateGroup, UpdateStatus } from "./FormUser";

export default function TableData({ results, ReloadData }) {
  const context = useOutletContext();
  const widget_89 = context.widgets.find(
    (item) => item.widget_id === 89 && item.is_enabled === 1
  );

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: "No", field: "id", sortable: false },
    { name: "Email Staff", field: "email", sortable: true },
    { name: "Employee ID", field: "employeeid", sortable: true },
    { name: "Group", field: "group_name", sortable: true },
    { name: "Status", field: "is_active", sortable: true },
    { name: "Last Login", field: "last_login", sortable: true },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if(Object.values(results.data).length > 0){
        computedData = results.data.map(c => {
            let obj = {};
            obj.user_id = c.user_id || "";
            obj.email = c.email || "";
            obj.app_id = c.app_id || "";
            obj.last_login = c.last_login ? moment(c.last_login).format("DD MMM YYYY, hh:mm A") : "";
            obj.last_login_time = c.last_login || "";
            obj.employeeid = c.employeeid || "";
            obj.group_id = c.group_id || "";
            obj.group_name = c.group_name || "";
            obj.status = (c.is_active === 1) ? "Active":"Not Active";
            obj.status_id = c.is_active || "";
            return obj;
        })
        computedData.sort((a, b) => (a.last_login_time < b.last_login_time ? 1 : -1));
    }

    

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key]
            .toString()
            .toLowerCase()
            .includes(search)
        );
      });
    }
    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [results.data, search, sorting, currentPage]);
  return (
    <div>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex">
          <ButtonItemRefresh
            totalItems={totalItems}
            onClick={(e) => ReloadData(e)}
          />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table table-row-dashed align-start fs-6 gy-4 my-0 pb-3 dataTable no-footer align-start">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {results.loading ? (
              <tr>
                <td colSpan={8}>
                  <LoadingSkeleton />
                </td>
              </tr>
            ) : results.message ? (
              <tr>
                <td colSpan={8}>
                  <AlertNotif messages={results.message} />
                </td>
              </tr>
            ) : ResultData.length > 0 ? (
              ResultData.map((v, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{v.email}</td>
                  <td>{v.employeeid}</td>
                  <td><UpdateGroup user_id={v.user_id} group_name={v.group_name} group_id={v.group_id} ReloadData={ReloadData} /></td>
                  <td><UpdateStatus user_id={v.user_id} is_active={v.status_id} ReloadData={ReloadData} /></td>
                  <td>{v.last_login}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={8}>
                  <NoRecordFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {ResultData.length > 0 && (
          <div className="footer">
            <PaginationComponent
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </div>
  );
}