import React, { useState } from 'react'
import FilterSection from '../../FilterSection'
import { RemoveEmptyValue } from '../../../../../../../_layouts/_helpers/Global';
import { MessageError } from '../../../../../../../_layouts/components/modals/Modals';
import { RequiredMsg } from '../../../../../../../_layouts/_core/ErrorMessage';
import { LoadingSkeleton } from '../../../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../../../../_layouts/components/alerts/AlertsUI';
import { GET_SECTION, UPDATE_SECTION_CLASSROOM } from '../../../../../apis/node-target-data/AcademicApi';
import { Link } from 'react-router-dom';

export default function FormJoinClassRoom({ section_id }) {
    const [results, setResults] = useState({
        loading: false,
        message: "",
        data: [],
    });

    const ReloadData = async (param) => {
        setResults({ loading: true, message: "", data: [] });
        const result = await GET_SECTION(param);

        setResults(result);
    };

    const SubmitFilter = (filterparam) => {
        const param = RemoveEmptyValue(filterparam);
        if (param) {
            ReloadData(param);
        } else {
            openModal({
                message: <MessageError message={RequiredMsg} />,
            });
        }
    };

    const handlerJoinClass = async (target_section_id) => {
        var param = {
            section_id: section_id,
            target_section_id: target_section_id
        }
        const result = await UPDATE_SECTION_CLASSROOM(param);

        setResults(result);
        setInterval(() => {
            window.location.reload();
        }, 1000);
    }

    return (
        <div className='section-browse row'>
            <div className="col-xl-4 mb-xl-10">
                <FilterSection SubmitFilter={SubmitFilter} results={results} setResults={setResults} />
            </div>
            <div className="col-xl-8 mb-5 mb-xl-10">
                {results.loading ? <LoadingSkeleton /> : (
                    results.message ? <AlertNotif messages={results.message} /> :
                        Object.values(results.data).length > 0 && (
                            <div className='section-list'>
                                <h3>Section List ({Object.values(results.data).length})</h3>
                                {results.data.map((v, index) => (
                                    <RenderClassRoom data={v} key={index} handlerJoinClass={handlerJoinClass} />
                                ))}
                            </div>
                        )
                )}
            </div>
        </div>
    )
}

const RenderClassRoom = ({ data, handlerJoinClass }) => {
    return (
        <div className="g-classroom">
            <div className="g-header p-5 rounded-top bg-success">
                <div className="d-flex justify-content-between align-items-start flex-wrap">
                    <div className="d-flex flex-column">
                        <div className="d-flex align-items-center mb-2">
                            <p className="mb-0 text-white fs-2 fw-bolder me-1">
                                {data.g_name}
                            </p>
                        </div>
                        <div className="">
                            <p className="text-white mb-0">{data.g_section}</p>
                            <p className="text-white mb-0">Owner {data.g_ownerIdEmail}</p>
                        </div>
                    </div>
                    <div className="d-none d-lg-block">
                        <button className="btn btn-light btn-icon" type='button' onClick={() => handlerJoinClass(data.section_id)}>
                            <i className="bi bi-plus-circle text-success"></i>
                        </button>
                    </div>
                </div>
            </div>
            <div className="g-body border bg-light rounded-bottom p-5">
                <div className="d-flex justify-content-between">
                    {Object.values(data.lecturer).length > 0 ? (
                        <div className="lecturer me-3">
                            <h4>Lecturer</h4>
                            <ol>
                                {data.lecturer.map((l, index) => (
                                    <li key={index} className='text-dark text-capitalize'>
                                        {(l.emp) ? l.emp.fullname : "-"}
                                    </li>
                                ))}
                            </ol>
                        </div>
                    ) : ""}
                    <div className='text-start'>
                        <h4>Section ID</h4>
                        <p className='mb-0'>{data.section_id}</p>
                        <p className='mb-0 d-none'>Credit {(data.course) ? data.course.credit : "0"}</p>
                    </div>
                </div>
                <div className="d-grid gap-2">
                    <Link to={data.g_alternateLink} className="btn btn-lg btn-secondary" target='_blank'>Visit class room</Link>
                </div>
            </div>
        </div>
    )
}