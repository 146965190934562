import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import { AlertNotif } from "../../../_layouts/components/alerts/AlertsUI";
import { AccessInvalid } from "../../../_layouts/_core/ErrorMessage";
import { LoadingSkeleton } from "../../../_layouts/components/loadbars/LoadingBars";
import { Content } from "../../../_metronic/layout/components/content";
import { ExtractWidgetIds } from "../../../_layouts/_helpers/Global";
import { GET_WIDGET_BY_GROUP } from "../apis/module/BackendRequest";
import { AuthDecodeToken } from "../../reducer/AuthRedux";

export class ScoreLayout extends Component {
  constructor(props) {
    super(props);
    this.FetchWidgets = this.FetchWidgets.bind(this);
    var Token = AuthDecodeToken();
    this.state = {
      token: Token,
      widgets: { loading: false, data: [], message: "" },
      menu_id: 12,
      widget_id: 35,
    };
  }

  async FetchWidgets(parameter) {
    this.setState({ widgets: { loading: true, data: [], message: "" } });

    const result = await GET_WIDGET_BY_GROUP(parameter);

    if (result.data && Object.values(result.data).length > 0) {
      const isenabled = result.data.find((item) => item.is_enabled === 1);
      this.setState({
        widgets: { loading: false, message: "", data: isenabled || [] },
      });
    } else {
      this.setState({ widgets: result });
    }
  }

  componentDidMount() {
    const paramwidget = {
      group_id: this.state.token.identity.group_id,
      menu_id: this.state.menu_id,
    };
    this.FetchWidgets(paramwidget);
  }
  render() {
    const extractWidget = ExtractWidgetIds(this.state.widgets.data);
    const myoutlet = { widgets: extractWidget };

    return (
      <>
        <Content>
          {this.state.widgets.loading ? (
            <LoadingSkeleton />
          ) : this.state.widgets.message ? (
            <AlertNotif
              title={"Information"}
              messages={this.state.widgets.message}
              color="danger"
            />
          ) : Object.values(this.state.widgets.data).length > 0 ? (
            <Outlet context={myoutlet} />
          ) : (
            <AlertNotif
              title={"Information"}
              messages={AccessInvalid}
              color="danger"
            />
          )}
        </Content>
      </>
    );
  }
}
