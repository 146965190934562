import moment from "moment";
import { ButtonIcon, ButtonTersier } from "../../../../../../_layouts/components/buttons/ButtonUI"
import { KTIcon } from "../../../../../../_metronic/helpers"
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../../../../../_layouts/components/popovers/PopOvers";
import { openModal } from "../../../../../../_layouts/components/modals/Modals";

const PrevNextWeekButton = ({ postParam, setPostParam }) => {
    const handlerWeek = (type) => {
        let startOfWeeks = moment(postParam.tgl).format("YYYY-MM-DD");
        let endOfWeeks = moment(postParam.tglend).format("YYYY-MM-DD");

        if (type === "prev") {
            startOfWeeks = moment(postParam.tgl)
                .subtract(1, "weeks")
                .startOf("weeks")
                .format("YYYY-MM-DD");
            endOfWeeks = moment(postParam.tgl)
                .subtract(1, "weeks")
                .endOf("weeks")
                .format("YYYY-MM-DD");
        } else if (type === "next") {
            startOfWeeks = moment(postParam.tgl)
                .add(1, "weeks")
                .startOf("weeks")
                .format("YYYY-MM-DD");
            endOfWeeks = moment(postParam.tgl)
                .add(1, "weeks")
                .endOf("weeks")
                .format("YYYY-MM-DD");
        }

        setPostParam((prev) => {
            const data = { ...prev };
            data.tgl = startOfWeeks;
            data.tglend = endOfWeeks;
            return data;
        })
    }
    return (
        <div className='btn-group'>
            <ButtonIcon className="btn-sm btn-light" type="button" title="Previous Week" onClick={() => handlerWeek("prev")}>
                <KTIcon iconName='left' className='fs-1' />
            </ButtonIcon>
            <ButtonIcon className="btn-sm btn-light" type="button" title="Next Week" onClick={() => handlerWeek("next")}>
                <KTIcon iconName='right' className='fs-1' />
            </ButtonIcon>
        </div>
    )
}

const PrevNextMonthButton = ({ calendarRef, postParam, setPostParam }) => {
    const handlerMonth = (type) => {
        let startOfMonth, endOfMonth;

        if (type === "prev") {
            startOfMonth = moment(postParam.tgl)
                .subtract(1, "months")
                .startOf("month")
                .format("YYYY-MM-DD");
            endOfMonth = moment(postParam.tgl)
                .subtract(1, "months")
                .endOf("month")
                .add(1, "day")
                .format("YYYY-MM-DD");
        } else if (type === "next") {
            startOfMonth = moment(postParam.tgl)
                .add(1, "months")
                .startOf("month")
                .format("YYYY-MM-DD");
            endOfMonth = moment(postParam.tgl)
                .add(1, "months")
                .endOf("month")
                .add(1, "day")
                .format("YYYY-MM-DD");
        }
        setPostParam((prev) => {
            return {
                ...prev,
                tgl: startOfMonth,
                tglend: endOfMonth,
            };
        });
    };

    return (
        <div className='btn-group'>
            <ButtonIcon className="btn-sm btn-light" type="button" title="Previous Month" onClick={() => handlerMonth("prev")}>
                <KTIcon iconName='left' className='fs-1' />
            </ButtonIcon>
            <ButtonTersier className="btn-sm fw-bold" type="button" >
                {moment(postParam.tgl).format("MMMM YYYY")}
            </ButtonTersier>
            <ButtonIcon className="btn-sm btn-light" type="button" title="Next Month" onClick={() => handlerMonth("next")}>
                <KTIcon iconName='right' className='fs-1' />
            </ButtonIcon>
        </div>
    )
}

const ConvertSchdItem = (data) => {
    let events = [];
    let blue = "#0359fb82";
    let green = "#03b898ba";
    let red = "#ef05624f";
    if (data) {
        data.map((v) => {
            let colorStatus = "";
            if(v.status_id === 1) {
                colorStatus = red;
            }else if(v.status_id === 2){
                colorStatus = green;
            }else if(v.status_id === 3){
                colorStatus = blue;
            }else{
                colorStatus = "#f9f9f9";
            }
            var objItem = {};
            objItem.title = v.name || "";
            objItem.id = v.id;
            objItem.start = v.start_dt;
            objItem.end = v.end_dt;
            objItem.groupId = JSON.stringify(v);
            objItem.background = colorStatus;
            objItem.border = colorStatus;
            events.push(objItem);
        })
    }

    return events;
}

const SchdBoxCall = (data) => {
    let item = data.event;
    let objItem = JSON.parse(item.groupId);
    return (
        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={renderTooltip({ title: moment(item.start).format("hh:mm a") + "-" + moment(item.end).format("hh:mm a") + ", " + item.title })}>
            <div className="mb-0 text-dark">
                {item.title}
            </div>
        </OverlayTrigger>
    )
}

const EventRender = (data) => {
    let item = data.event;
    const groupData = JSON.parse(item.groupId);
    return (
        <div className={"event-item w-100 cursor-pointer px-1 "} onClick={() => openModal({ message: <DetailSchd data={groupData} /> })}>
            <p className="fs-8 mb-0 text-wrap text-dark">
                <span className="fw-bold">{item.title}</span> 
                <span className="ms-1">{groupData.atp && (groupData.atp.name || "")}</span>
            </p>
        </div>

    );
};

const DetailSchd = ({ data }) => {
    return (
        <div>
            <h1>{data.name}</h1>
            <div className="fs-6 text-muted mb-5">
                <span className="start_dt">{moment(data.start_dt).format("D MMM YYYY hh:mm A")}</span>
                <span className="mx-2">until</span>
                <span className="end_dt">{moment(data.end_dt).format("D MMM YYYY hh:mm A")}</span>
            </div>

            <div className="mb-5">
                <span className="d-block">Academic Term:</span>
                <span className="fw-bold">{data.atp && (data.atp.name || "")}</span>
            </div>

            {data.var1 && (
                <div className="mb-5">
                    <span className="d-block">Section:</span>
                    <span className="fw-bold">{data.var1}</span>
                </div>
            )}

            {data.remark && (
                <div className="mb-5">
                    <span className="d-block">Remark:</span>
                    <span className="fw-bold">{data.remark}</span>
                </div>
            )}

        </div>
    )
}

export { PrevNextWeekButton, ConvertSchdItem, SchdBoxCall, EventRender, PrevNextMonthButton }