import React from 'react'

const SGUCard = ({ children }) => {
    let header, toolbar, body, footer;

    React.Children.forEach(children, child => {
        if (child.type === SGUCard.Header) {
            header = child;
        } else if (child.type === SGUCard.Toolbar) {
            toolbar = child;
        } else if (child.type === SGUCard.Body) {
            body = child;
        } else if (child.type === SGUCard.Footer) {
            footer = child;
        }
    });

    return (
        <div className="card h-100 card-xl-stretch mb-5 mb-xl-8 ">
            {header && (
                <div className="card-header border-0 py-0">
                    {header}
                    {toolbar && (
                        <div className="card-toolbar">
                            {toolbar}
                        </div>
                    )}
                </div>
            )}

            {body}
            {footer && footer}
        </div>
    );
};

SGUCard.Header = ({ children }) => (
    <h3 className="card-title align-items-start flex-column mb-0">
        {children}
    </h3>
);

SGUCard.Toolbar = ({ children }) => (
    <div>
        {children}
    </div>
);

SGUCard.Body = ({ children, className }) => {
    let class_name = `card-body pt-0 ${className}`
    return (
        <div className={class_name}>
            {children}
        </div>
    )

};

SGUCard.Footer = ({ children, className }) => (
    <div className={`card-footer ${className}`}>
        {children}
    </div>
);

const CardFlush = ({ children }) => {
    return (
        <div className="card card-flush flex-row-fluid">
            <div className="card-body pt-0">
                {children}
            </div>
        </div>
    )
}

const SGUCardFlush = ({ children }) => {
    let header, toolbar, body;

    React.Children.forEach(children, child => {
        if (child.type === SGUCardFlush.Header) {
            header = child;
        } else if (child.type === SGUCardFlush.Toolbar) {
            toolbar = child;
        } else if (child.type === SGUCardFlush.Body) {
            body = child;
        }
    });

    return (
        <div className="card card-flush mb-5 mb-xl-8">
            {header && (
                <div className="card-header border-0 py-0">
                    {header}
                    {toolbar && (
                        <div className="card-toolbar">
                            {toolbar}
                        </div>
                    )}
                </div>
            )}
            {body}
        </div>
    );
};

SGUCardFlush.Header = ({ children }) => (
    <h3 className="card-title align-items-start flex-column mb-0">
        {children}
    </h3>
);

SGUCardFlush.Toolbar = ({ children }) => (
    <div>{children}</div>
);

SGUCardFlush.Body = ({ children, className }) => {
    let class_name = `card-body pt-0 ${className}`
    return (
        <div className={class_name}>
            {children}
        </div>
    )
};

export { SGUCard, CardFlush, SGUCardFlush }