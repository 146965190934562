import React, { useMemo } from 'react'
import { SGUCard } from '../../../../../../_layouts/components/cards/CardUI'
import { NoRecordFound } from '../../../../../../_layouts/components/loadbars/LoadingBars';
import { InfoCourse, theadtitle } from '../../components/TCUI';
import { handleKeyDown } from '../../../../components/forms/InputText';
import { ButtonIcon, ButtonTersier } from '../../../../../../_layouts/components/buttons/ButtonUI';
import { KTIcon } from '../../../../../../_metronic/helpers';
import { MessageError, openModal } from '../../../../../../_layouts/components/modals/Modals';
import TableData from '../TableData';
import { DataCourseSampleByStudent } from '../../components/ConstantData';

export default function MatriculationCourse({ mycourses, setMyCourses, RemoveCourse, setCourses }) {
    const handleInputChange = (index, field, value) => {
        const updatedCourses = [...mycourses];
        updatedCourses[index][field] = value;
        setMyCourses(updatedCourses);
    };

    const totalCredit = useMemo(() => {
        return mycourses.reduce((sum, course) => {
            if (course.course_type !== 'asc.course.type.optional') {
                return sum + (Number(course.credit) || 0);
            }
            return sum;
        }, 0);
    }, [mycourses]);

    const totalCreditPrevInstitution = useMemo(() => {
        return mycourses.reduce((sum, course) => {
            return sum + (Number(course.credit_ext) || 0);
        }, 0);
    }, [mycourses]);

    const HandlerAddStudentCopy = (data) => {
        console.log(data);
        const courses = data.transfer_proposal_dt;
        if(Object.values(courses).length > 0){
            const course_selected = courses.map( item => ({...item, id:item.course_id, coursetitle: item.course.coursetitle, code:item.course.code, credit:item.course.credit }) );
            setCourses([...mycourses,...course_selected]);
            openModal({open:false});
        }else{
            openModal({message:<MessageError message={"Course data not found"} />});
        }
    }

    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bold text-gray-800">
                    Matriculation Courses 
                </span>
                <span className="text-muted fs-8">
                    Total {Object.values(mycourses).length} item
                </span>
            </SGUCard.Header>
            <SGUCard.Toolbar>
                <ButtonTersier type="button" className="btn-sm fw-bold me-2" onClick={()=>openModal({size:"lg",message:<TableData is_choosen={true} HandlerAddStudentCopy={HandlerAddStudentCopy} />})}>
                    <KTIcon iconName='file-up' className='fs-2' />
                    Import Proposal
                </ButtonTersier>
            </SGUCard.Toolbar>
            <SGUCard.Body className={"px-0"}>
                <div className="table-resposinve">
                    <table className="table align-middle table-row-dashed fs-8 gy-1 gx-1">
                        <thead>
                            <tr>
                                <th className='fw-bold text-center text-capitalize bg-light-warning' colSpan={3}>SGU Equivalent Course</th>
                                <th className='fw-bold text-center text-capitalize bg-light-info' colSpan={4}>Previous Institution</th>
                            </tr>
                            <tr>
                                {theadtitle.concat(theadtitle).map((v, index) => (
                                    <th key={index} className={'fw-bold text-center text-capitalize w-' + (v.width + "px ") + (index > 2 ? "bg-light-info" : "bg-light-warning")}>{v.name}</th>
                                ))}
                                <th className='fw-bold text-center text-capitalize bg-light-info'></th>
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(mycourses).length > 0 ? (
                                mycourses.map((v, index) => (
                                    <tr key={index}>
                                        <td className='ps-2 bg-light-warning'>
                                            <InfoCourse data={v} />
                                        </td>
                                        <td className='text-center bg-light-warning'>
                                            <span className="text-muted">
                                                {v.credit} SKS
                                            </span>
                                        </td>
                                        <td className='bg-light-warning'>
                                            <input
                                                type="text"
                                                autoComplete='off'
                                                onKeyDown={handleKeyDown}
                                                className="form-control form-control-sm  text-center"
                                                name="score"
                                                tabIndex={index}
                                                value={v.score}
                                                onChange={(e) => handleInputChange(index, 'score', e.target.value)}
                                            />
                                        </td>
                                        <td className='bg-light-info'>
                                            <input
                                                type="text"
                                                autoComplete='off'
                                                className="form-control form-control-sm"
                                                name="course_ori"
                                                tabIndex={index}
                                                value={v.course_ext}
                                                onChange={(e) => handleInputChange(index, 'course_ext', e.target.value)}
                                            />
                                        </td>
                                        <td className='bg-light-info'>
                                            <input
                                                type="text"
                                                autoComplete='off'
                                                onKeyDown={handleKeyDown}
                                                className="form-control form-control-sm text-center"
                                                name="course_ori_credit"
                                                tabIndex={index}
                                                value={v.credit_ext}
                                                onChange={(e) => handleInputChange(index, 'credit_ext', e.target.value)}
                                            />
                                        </td>
                                        <td className='bg-light-info'>
                                            <input
                                                type="text"
                                                autoComplete='off'
                                                className="form-control form-control-sm text-center"
                                                name="course_ori_score"
                                                tabIndex={index}
                                                value={v.score_ext}
                                                onChange={(e) => handleInputChange(index, 'score_ext', e.target.value)}
                                            />
                                        </td>
                                        <td className='bg-light-info'>
                                            <ButtonIcon
                                                type="button"
                                                className="btn-sm"
                                                title="Remove"
                                                onClick={() => RemoveCourse(v.id)}
                                            >
                                                <KTIcon iconName="trash" className="fs-2 text-danger" />
                                            </ButtonIcon>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={7} className='p-2'>
                                        <NoRecordFound />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        {Object.values(mycourses).length > 0 && (
                        <tfoot>
                            <tr>
                                <td colSpan={7}></td>
                            </tr>
                            <tr>
                                <td className='fw-bold bg-light-warning'>Total Credit</td>
                                <td className='text-center fw-bold bg-light-warning'>{totalCredit} SKS</td>
                                <td className='bg-light-warning'></td>
                                <td className='fw-bold bg-light-info'>Total Credit</td>
                                <td className='text-center fw-bold bg-light-info'>{totalCreditPrevInstitution} SKS</td>
                                <td colSpan={2} className='bg-light-info'></td>
                            </tr>
                        </tfoot>
                        )}
                    </table>
                </div>
            </SGUCard.Body>
        </SGUCard>
    )
}
