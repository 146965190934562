import React, { useEffect } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { LayoutSplashScreen } from "../_layouts";
import { useProvider } from "../_layouts/UMSLayoutProvider";
import { MessageError, openModal } from "../_layouts/components/modals/Modals";
import { ButtonRelogin } from "../_layouts/components/buttons/ButtonUI";
import { COMPONENT_MAP } from "./SlugElements";
import Dashboard from "./modules/dashboard";
import MyProfiles from "./modules/profiles";

export default function BaseRoutes() {
  const { env, account, routes } = useProvider();
  const expired = account.expiredSession;
  var currDatetime = new Date();
  currDatetime = currDatetime.getTime();
  const navigate = useNavigate();

  const filterRoutes = (routes) => {
    return (
      routes
        //.filter(route => route.menu_is_active === 1 )
        .map((route) => ({
          ...route,
          children: filterRoutes(route.children),
        }))
    );
  };

  useEffect(() => {
    if (currDatetime >= expired) {
      openModal({
        message: (
          <MessageError
            message={"Your session has expired. Please log in again."}
            button={
              <ButtonRelogin onClick={() => navigate("/logout")}>
                Re-Login
              </ButtonRelogin>
            }
          />
        ),
      });
      setTimeout(() => {
        console.log("heh");
        window.location = env.baseuri + "logout";
      }, 21000);
    }
  }, [currDatetime]);

  return (
    <React.Suspense fallback={<LayoutSplashScreen />}>
      <Routes>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="profile" element={<MyProfiles />} />
        <Route path="login" element={<Navigate to="/dashboard" />} />

        {generateRoutes(routes)}

        <Route path="*" element={<Navigate to="/error" />} />
      </Routes>
    </React.Suspense>
  );
}

const generateRoutes = (routes) => {
  return routes.map((route, index) => generateRoutesForRoute(route, index));
};

const generateRoutesForRoute = (data, index) => {
  const SlugComponent = COMPONENT_MAP[data.slug];

  if (!SlugComponent) {
      console.error(`Component not found for slug: ${data.slug}`);
      //return null;
  }

  if (data.children && data.children.length > 0) {
    return [
      <Route key={index} path={data.slug_uri} element={<SlugComponent />}>
        {data.children.map((child, childIndex) => {
          const ChildComponent = COMPONENT_MAP[child.slug];

          if (child.slug_uri === "index") {
            return (
              <Route index element={<ChildComponent />} key={childIndex} />
            );
          } else {
            return generateRoutesForRoute(child, childIndex);
          }
        })}
      </Route>,
    ];
  } else {
    return [
      <Route key={index} path={data.slug_uri} element={<SlugComponent />} />,
    ];
  }
};