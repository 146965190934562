import React, { useEffect, useRef, useState } from 'react'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import moment from 'moment';
import { EventBoxCall } from './CalendarAtributes';
import './FullCalendar.css';

export function FullCalendarWeek({ data, startDT, endDT, title, createNewClass }) {
    const calendarRef = useRef();
    const defaultMaxTime = "22:00"
    const defaultMinTime = "07:00"
    const [slotMaxTime, setSlotMaxTime] = useState(defaultMaxTime);
    const [slotMinTime, setSlotMinTime] = useState(defaultMinTime);

    useEffect(() => {
        if (data && data.length > 0) {

            const sortedData = [...data].sort((a, b) => {
                const timeA = moment(a.start).format('HH:mm');
                const timeB = moment(b.start).format('HH:mm');
                return timeA.localeCompare(timeB);
            });

            const { earliestStart, latestEnd } = sortedData.reduce((acc, current) => {
                const currentStartTime = moment(current.start);
                const currentEndTime = moment(current.end);

                if (!acc.earliestStart || currentStartTime.format('HH:mm') < acc.earliestStart.format('HH:mm')) {
                    acc.earliestStart = currentStartTime;
                }

                if (!acc.latestEnd || currentEndTime.isAfter(acc.latestEnd)) {
                    acc.latestEnd = currentEndTime;
                }

                return acc;
            }, { earliestStart: null, latestEnd: null });

            
            if (earliestStart) {
                const minTime = earliestStart.clone().subtract(1, 'hour').format('HH:mm');
                setSlotMinTime(minTime);
            } else {
                setSlotMinTime(defaultMinTime);
            }

            if (latestEnd) {
                const maxTime = latestEnd.clone().add(1, 'hour').format('HH:mm');
                setSlotMaxTime(maxTime);
            } else {
                setSlotMaxTime(defaultMaxTime);
            }
        } else {
            setSlotMinTime(defaultMinTime);
            setSlotMaxTime(defaultMaxTime);
        }
    }, [data]);


    return (
        <div id="sgu-calendar-week" className='bg-white mt-1'>
            <div className="px-5 pt-5 d-flex justify-content-between align-items-center">
                <h3 className='mb-0'>
                    <span className="d-block">Swiss German University</span>
                    {title && <span className='fs-6'>{title}</span>}
                </h3>
                <h3>Week {moment(startDT).format("MMM DD")} - {moment(endDT).format("MMM DD")} {moment(endDT).format("YYYY")}</h3>
            </div>
            <FullCalendar
                headerToolbar={{ start: '', center: '', end: '' }}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin,]}
                initialView="timeGridWeek"
                slotMinTime={slotMinTime}
                slotMaxTime={slotMaxTime}
                handleWindowResize={false}
                allDaySlot={false}
                weekends={true}
                weekNumbers={false}
                dayHeaderFormat={{ weekday: 'long', month: '2-digit', day: '2-digit', omitCommas: true }}
                ref={calendarRef}
                initialDate={startDT}
                goToDate={startDT}
                setDates={startDT}
                select={[startDT, endDT]}
                events={data}
                validRange={{ start: startDT, end: moment(endDT).add(1, 'day').startOf('day').format('YYYY-MM-DD') }}
                nowIndicator={false}
                expandRows={true}
                showNonCurrentDates={false}
                eventColor="black"
                eventBackgroundColor="white"
                eventContent={(info) => EventBoxCall(info)}
                editable={false}
                droppable={false}
                dateClick={(info) => createNewClass(info)}
                hiddenDays={[0]}
                className={{ border: '1px solid #dee2e6' }}
            />
        </div>
    )
}
