import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { MailUserMultiple, RoomMultiple } from "../../components/forms/SelectBox";
import { ButtonPrimary, ButtonTersier } from "../../../../_layouts/components/buttons/ButtonUI";
import moment from "moment";
import { RequiredMsg } from "../../../../_layouts/_core/ErrorMessage";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { CREATE_SCHD } from "../../apis/node-target-data/AcademicApi";
import { MessageError, MessageSuccess, openModal } from "../../../../_layouts/components/modals/Modals";

export default function FormCreateEvent({ iscustom=false, date, room_id, ReloadData }) {
  const [eventName, setEventName] = useState("");
  const [start_dt, setStart_dt] = useState(date || "");
  const [end_dt, setEnd_dt] = useState(date || "");
  const [rooms, setRooms] = useState([]);
  const [members, setMembers] = useState([]);

  const [submit, setSubmit] = useState({loading:false, data:[], message:""});
  
  const HandlerEndDate = (date) => {
    if (date < start_dt) {
      alert("End date is bigger than start date.");
      setEnd_dt(start_dt);
    } else {
      setEnd_dt(date);
    }
  }
  
  const HandlerSubmit = async (e) => {
    e.preventDefault();
    setSubmit({loading:true, message:"", data:[]});
    if(Object.values(members).length > 0 && Object.values(rooms).length > 0 && eventName && start_dt && end_dt){
    const member = members.map(v =>{
      return {sgumail:v.value}
    })
    const room = rooms.map(v =>{
      return {room_id:v.value}
    })
    const param = {
      event_type: "asc.event.type.event", 
      label:eventName, 
      member:member, 
      room:room,
      start_dt: moment(start_dt).format("YYYY-MM-DD HH:mm:00"),
      end_dt: moment(end_dt).format("YYYY-MM-DD HH:mm:00"),
    }
    const result  = await CREATE_SCHD(param);
    if(result.data && Object.values(result.data).length > 0){
      openModal({message:<MessageSuccess message={"Successfully saved"}  />});
    }else{
      openModal({message:<MessageError message={result.message} />});
    }
    setSubmit(result);

    }else{
     setSubmit({loading:false, message:RequiredMsg, data:[]});
    }
  };

  return (
    <form autoComplete="off" onSubmit={(e) => HandlerSubmit(e)} method="post">
      <div className="form-group mb-5">
        <label className="required fw-bold">Event Name</label>
        <input
          type="text"
          className="form-control form-control-sm"
          defaultValue={eventName}
          onChange={(e) => setEventName(e.target.value)}
        />
      </div>

      <div className="row">
        <div className="col-lg-6">
          <div className="form-group mb-5">
            <label className="fw-bold mb-2 required d-block">
              Start Date
            </label>
            <DatePicker
              className="form-control form-control-sm pe-10"
              dateFormat={"yyyy-MM-dd hh:mm aa"}
              isClearable
              showTimeSelect
              timeIntervals={10}
              readOnly={iscustom}
              placeholderText="Enter date"
              selected={start_dt}
              onChange={(date) => {setStart_dt(date); setEnd_dt(date);}}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        </div>
        <div className="col-lg-6">
          <div className="form-group mb-5">
            <label className="d-block fw-bold mb-2 required">
              End Date
            </label>
            <DatePicker
              className="form-control form-control-sm pe-10"
              dateFormat={"yyyy-MM-dd hh:mm aa"}
              isClearable
              showTimeSelect
              placeholderText="Enter date"
              selected={end_dt}
              onChange={(date) => HandlerEndDate(date)}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        </div>
      </div>

      <RoomMultiple data={rooms} setData={setRooms} required room_id={room_id} />

      <MailUserMultiple data={members} setData={setMembers} required  />

      {submit.message && (
        <div className="my-5">
          <AlertNotif messages={submit.message} />
        </div>
      )}

      <div className="text-end">
          {!iscustom && (
              <ButtonTersier type="button" className="btn-lg me-3 fw-bold" onClick={()=>{openModal({header:"Confirmation",message:<ConfirmLeave />})}}>
                  Cancel
              </ButtonTersier>
          )}
          <ButtonPrimary type="submit" className="btn-lg fw-bold" disabled={submit.loading}>
              {submit.loading ? "Processing...":"Save Changes"}
          </ButtonPrimary>
      </div>
      
    </form>
  );
}
