import { ButtonIcon } from "../../../../../../_layouts/components/buttons/ButtonUI";

const ButtonAdd2Filter = ({ param, HandlerAddCourse }) => {
    
    return (
        <ButtonIcon className="btn-sm btn-light btn-active-light-primary" type="button" onClick={()=>HandlerAddCourse(param)}>
            <i className="ki-outline ki-plus fs-5 m-0"></i>
        </ButtonIcon>
    );
};

export {ButtonAdd2Filter}