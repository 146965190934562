import moment from "moment";

const AddStatus = (items) => {
  const now = moment();

  return items.map((item) => {
    const startDt = moment(item.start_dt_formatted, "YYYY-MM-DD hh:mm:ss");
    const endDt = moment(item.end_dt_formatted, "YYYY-MM-DD hh:mm:ss");
    let status = "";

    if (now.isBefore(startDt)) {
      status = "Upcoming";
    } else if (now.isBetween(startDt, endDt, null, "[]")) {
      status = "Active";
    } else if (now.isAfter(endDt)) {
      status = "Done";
    }

    return { ...item, status };
  });
};

const ColorStatus = (status) => {
  let labels = { color: "", text: status };
  switch (status) {
    case "Active":
      labels = { color: "success", text: "Open" };
      break;
    case "Done":
      labels = { color: "danger", text: "Closed" };
      break;
    case "Upcoming":
      labels = { color: "primary", text: "Upcoming" };
      break;
    default:
      labels = { color: "light", text: status };
      break;
  }

  return labels;
};

export {AddStatus, ColorStatus}