import React, { useEffect, useMemo, useState } from "react";
import { SGUCard } from "../../../../../../_layouts/components/cards/CardUI";
import { RemoveEmptyValue } from "../../../../../../_layouts/_helpers/Global";
import {
  GET_ATD_STUDENT_BY_ATP,
  GET_SCR_STUDENT_BY_ATPID,
} from "../../../../apis/node-target-data/AcademicApi";
import { LoadingBars } from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../../_layouts/components/alerts/AlertsUI";
import { ButtonTersier } from "../../../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { openModal } from "../../../../../../_layouts/components/modals/Modals";
import DetailAtdList from "./DetailAtdList";
import DetailScoreCom from "./DetailScoreCom";

export default function ClassList({ stdAcad, stdBio }) {
  const studentid = stdAcad.studentid;
  const semester_status = stdAcad.semester_status;
  let cSemester = {};
  if (Object.values(semester_status).length > 0) {
    semester_status.sort((a, b) => (a.id > b.id ? 1 : -1));
    cSemester = semester_status[Object.values(semester_status).length - 1];
  }
  const [currSemes, setCurrSemes] = useState(
    cSemester && cSemester.atp_term && (cSemester.atp_term.id || "")
  );
  const [results, setResults] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const postParam = {
    studentid: studentid,
    atpid: currSemes,
  };

  const ReloadData = async () => {
    setResults({ loading: true, message: "", data: [] });
    const param = RemoveEmptyValue(postParam);
    const result_score = await GET_SCR_STUDENT_BY_ATPID(param);
    const result_atd = await GET_ATD_STUDENT_BY_ATP(param);

    if (
      (result_score.data || result_atd.data) &&
      (Object.values(result_atd.data).length > 0 ||
        Object.values(result_score.data).length > 0)
    ) {
      setResults({
        loading: false,
        message: "",
        data: { score: result_score.data, attd: result_atd.data },
      });
    } else {
      setResults({
        loading: false,
        message: result_score.message || result_atd.message,
        data: [],
      });
    }
  };

  useEffect(() => {
    if (currSemes) {
      ReloadData();
    }
  }, [currSemes]);

  const ResultData = useMemo(() => {
    let computedData = [];
    if (results.data && Object.values(results.data).length > 0) {
      const score_data = results.data.score;
      const atd_data = results.data.attd;

      if (score_data.length > 0) {
        computedData = score_data.map((v) => {
          const findAtd =
            atd_data.find((item) => item?.course?.id === v?.course?.id) || {};
          return {
            ...v,
            t_session: findAtd?.t_session ?? "",
            t_absent: findAtd?.t_absent ?? "",
            atd_id: findAtd?.id ?? "",
            section_lec: findAtd?.section_lec ?? "",
          };
        });
      }
      computedData.sort((a, b) => (a.id < b.id ? 1 : -1));
    }

    return computedData;
  }, [results.data]);

  const DetailOpenAtd = (course) => {
    var param = {
      studentid: postParam.studentid,
      atpid: postParam.atpid,
      courseid: course.id,
    };
    openModal({
      header: `Detail Attendance ${course.coursetitle}`,
      message: (
        <DetailAtdList
          param={param}
          student={{
            studentid: postParam.studentid,
            student_name: stdBio.fullname,
          }}
          course={course}
        />
      ),
      size: "xl",
    });
  };
  const DetailOpenScore = (course, section_com) => {
    const sortId = section_com.sort((a, b) =>
      a.wg_com_id > b.wg_com_id ? 1 : -1
    );
    const ascScrComWeight = sortId.sort((a, b) =>
      a.weight > b.weight ? 1 : -1
    );
    openModal({
      header: `Detail Score ${course.coursetitle}`,
      message: <DetailScoreCom data={ascScrComWeight} />,
      size: "xl",
    });
  };

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bolder fs-3 mb-1">
          {stdBio.firstname}'s Class List
        </span>
        <span className="text-muted mt-1 fw-bold fs-7">
          Academic Performance
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        {Object.values(semester_status).length > 0 && (
          <select
            name="term_id"
            className="form-select form-select-sm"
            defaultValue={postParam.atpid}
            onChange={(e) => setCurrSemes(parseInt(e.target.value))}
          >
            {semester_status.map((v, index) => (
              <option value={v.atp_term.id} key={index}>
                Semester {v.semester}
              </option>
            ))}
          </select>
        )}
      </SGUCard.Toolbar>
      <SGUCard.Body>
        <div className="table-responsive">
          <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
            <thead>
              <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                <th>Subject</th>
                <th>Credit</th>
                <th>Lecturer</th>
                <th>Session</th>
                <th>Absent</th>
                <th>Score</th>
                <th>Grade</th>
                <th>Final Result</th>
              </tr>
            </thead>
            <tbody>
              {results.loading ? (
                <tr>
                  <td colSpan={8}>
                    <LoadingBars />
                  </td>
                </tr>
              ) : results.message ? (
                <tr>
                  <td colSpan={8}>
                    <AlertNotif messages={results.message} />
                  </td>
                </tr>
              ) : ResultData.length > 0 ? (
                ResultData.map((v, index) => (
                  <tr
                    key={index}
                    className={v.grade_fin === "F" && "bg-light-danger"}
                  >
                    <td>
                      <RowCourse data={v} />
                    </td>
                    <td>{v.credit} SKS</td>
                    <td>
                      {Object.values(v.section_lec).length > 0
                        ? v.section_lec.map((e, index) => (
                            <div
                              key={index}
                              className={
                                "my-2 " +
                                (index + 1 < Object.values(v.section_lec).length
                                  ? "border-bottom"
                                  : "")
                              }
                            >
                              <span className="fw-bolder fs-7">
                                {e.emp ? e.emp.fullname : "-"}
                              </span>
                            </div>
                          ))
                        : "-"}
                    </td>
                    <td>{v.t_session}</td>
                    <td>
                      <ButtonTersier
                        type="button"
                        title="Click for detail"
                        className="btn-sm fw-bold"
                        onClick={() => DetailOpenAtd(v.course)}
                      >
                        {(v.atd ? v.atd : 0) + "%"}
                        <KTIcon iconName="information" className="ms-1 fs-6" />
                      </ButtonTersier>
                    </td>
                    <td>
                      {v.course ? (
                        !v.course.is_thesis ? (
                          Object.values(v.section_com).length > 0 ? (
                            v.score && v.course.score_type === 1 ? (
                              <ButtonTersier
                                type="button"
                                title="Click for detail"
                                className="btn-sm fw-bold"
                                onClick={() =>
                                  DetailOpenScore(v.course, v.section_com)
                                }
                              >
                                {v.score}
                                <KTIcon
                                  iconName="information"
                                  className="ms-1 fs-6"
                                />
                              </ButtonTersier>
                            ) : v.score_letter ? (
                              v.score_letter
                            ) : (
                              v.score
                            )
                          ) : (
                            v.score
                          )
                        ) : (
                          v.score
                        )
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>{v.grade_fin}</td>
                    <td>
                      <small>{v.course_status || ""}</small>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={8}>No record found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </SGUCard.Body>
    </SGUCard>
  );
}

const RowCourse = ({ data }) => {
  const course_type = data.course ? data.course.course_type : "";
  let courseTypeArr = course_type.split(".");
  let courseType = courseTypeArr[courseTypeArr.length - 1];
  return (
    <div>
      <span className="d-block fw-bolder">
        {data.course ? data.course.coursetitle : ""}
      </span>
      <span className="text-muted text-capitalize">
        {data.course ? data.course.code : ""} · {courseType} · {data.section_id}
      </span>
    </div>
  );
};
