import React, { useMemo, useState } from "react";
import { HeaderDatatables, PaginationComponent } from "../../../../../_layouts/components/datatables/MYDatatables";
import { ButtonIcon } from "../../../../../_layouts/components/buttons/ButtonUI";
import { RenderItem } from "./RenderItem";

export default function TableData({ data, HandlerAddStudent }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [totalItems, setTotalItems] = useState(0);
  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: "Student", field: "name", sortable: false },
    { name: "Action", field: "status_id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    const computedData = data.map((v) => {
      let obj = {};
      obj.name = v.fullname || "";
      obj.email = v.sgumail || "";
      obj.icon = v.clu && (v.clu.code || "");
      obj.status = v.status && (v.status.name || "");
      obj.status_id = v.status && (v.status.id || "");
      obj.userid = v.studentid || "";
      obj.clu = v.clu && (v.clu.name || "");
      obj.cluspec = v.clu_spec && (v.clu_spec.name || "");
      obj.cluspec_code = v.clu_spec && (v.clu_spec.code || "");
      obj.detail = v;
      return obj;
    });

    computedData.sort((a, b) => (a.status < b.status ? -1 : 1));
    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [data, currentPage, sorting]);

  return (
    <div className="table-responsive">
      <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
        <HeaderDatatables
          headers={headers}
          onSorting={(field, order) => setSorting({ field, order })}
        />
        <tbody>
        {ResultData.length > 0 ? (
            ResultData.map((v,index)=>(
                <tr key={index}>
                    <td>
                        <RenderItem data={v} />
                    </td>
                    <td>
                        <ButtonIcon
                            type="button"
                            title="Add"
                            className="btn-sm btn-light btn-active-light-primary"
                            onClick={()=>HandlerAddStudent(v.userid)}
                        >
                            <i className="ki-outline ki-plus-circle fs-5 m-0"></i>
                        </ButtonIcon>
                    </td>
                </tr>
            ))
            ) : (
                <tr>
                  <td colSpan="5">No record found.</td>
                </tr>
              )}
        </tbody>
      </table>
      {totalItems > 0 && (
        <div className="footer">
          <PaginationComponent
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    </div>
  );
}
