import { OverlayTrigger, Popover } from "react-bootstrap"
import { KTIcon } from "../../../../../../_metronic/helpers"
import { ButtonDanger, ButtonPrimary, ButtonTersier } from "../../../../../../_layouts/components/buttons/ButtonUI"
import { LoadingBars, SpinnerLoad } from "../../../../../../_layouts/components/loadbars/LoadingBars"
import { AlertNotif, AlertRetry } from "../../../../../../_layouts/components/alerts/AlertsUI"
import { useEffect, useRef, useState } from "react"
import { CREATE_INSTITUTIONHIST, DELETE_INSTITUTIONHIST, GET_LOOK_UP_SCHOOL, GET_UNIV_LOOKUP, GET_UNIV_PRODI_LOOKUP } from "../../../../apis/node-target-data/IdentityApi"
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { handleKeyDown, InputAutoFillIn } from "../../../../components/forms/InputText"
import { CapitalizeFirstLetter, RemoveEmptyValue } from "../../../../../../_layouts/_helpers/Global"
import { RequiredMsg } from "../../../../../../_layouts/_core/ErrorMessage"
import { SyncInstituteData, SyncSchoolData } from "../../../../course-managements/transfer-credits/proposal-form/componensts/PropControllers"
import { MessageError, MessageSuccess, openModal } from "../../../../../../_layouts/components/modals/Modals"

const RowUniversity = ({ index, data, Reload }) => {
    return (
        <tr key={index}>
            <td>{index + 1}</td>
            <td>University</td>
            <td>
                <span className="fw-bold d-block">{data?.university?.name || ""}</span>
                {data.university_prodi && (
                    <span className="text-muted fs-7">Major {data.university_prodi?.name}</span>
                )}
                {data.lastsemester && (
                    <span className="text-muted fs-7"> · Semester {data.lastsemester}</span>
                )}
                {data.gpa && (
                    <span className="d-block text-muted fs-7">GPA {data.gpa}</span>
                )}
            </td>
            <td>{data.yeargraduation}</td>
            <td><ButtonRemoveInst reff_ins_id={data.id} Reload={Reload} /></td>
        </tr>
    )
}

const RowSchool = ({ index, data, Reload }) => {
    return (
        <tr key={index}>
            <td>{index + 1}</td>
            <td>School</td>
            <td>
                <span className="fw-bold d-block">{data?.school?.name || ""}</span>
                {data.gpa && (
                    <span className="d-block text-muted fs-7">GPA {data.gpa}</span>
                )}
            </td>
            <td>{data.yeargraduation}</td>
            <td><ButtonRemoveInst reff_ins_id={data.id} Reload={Reload} /></td>
        </tr>
    )
}

const ButtonRemoveInst = ({ reff_ins_id, Reload }) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);

    return (
        <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            rootClose
            placement="top"
            trigger="click"
            show={show}
            onToggle={() => setShow(!show)}
            overlay={
                <Popover className="p-3">
                    <Popover.Body className="p-0">
                        <RemoveItem
                            id={reff_ins_id}
                            closePopover={() => setShow(false)}
                            ReloadData={Reload}
                        />
                    </Popover.Body>
                </Popover>
            }
        >
            <button
                type="button"
                ref={target}
                className="btn btn-icon btn-sm"
                onClick={() => setShow(!show)}
            >
                <KTIcon iconName="trash" className="fs-3 text-danger" />
            </button>
        </OverlayTrigger>
    );
}

const RemoveItem = ({ id, closePopover, ReloadData }) => {
    const [remove, setRemove] = useState({
        loading: false,
        data: [],
        message: "",
    });
    const HandlerRemove = async (id) => {
        setRemove({ loading: true, data: [], message: "" });
        const param = { institutionhist_id: id };
        const result = await DELETE_INSTITUTIONHIST(param);
        if (result.data && Object.values(result.data).length > 0) {
            ReloadData();
        }
        setRemove(result);
    };
    return (
        <div className="text-center p-2">
            <p className="fs-4 mb-3 fw-bold">Are you sure wants to remove?</p>
            <div className="btn-group">
                <ButtonTersier
                    className="btn-sm fw-bold"
                    onClick={closePopover}
                    disabled={remove.loading}
                >
                    No
                </ButtonTersier>
                <ButtonDanger
                    className="btn-sm fw-bold"
                    onClick={() => HandlerRemove(id)}
                    disabled={remove.loading}
                >
                    {remove.loading ? <SpinnerLoad /> : "Yes"}
                </ButtonDanger>
            </div>
            {remove.message && <AlertNotif messages={remove.message} />}
        </div>
    );
};

const FormNewInstitution = ({ ReloadData, reff_id }) => {
    const [institution_type, setInstitute_type] = useState("");
    const [yeargraduation, setYeargraduation] = useState(moment().toDate());
    const [lastsemester, setLastsemester] = useState("");
    const [gpa, setGPA] = useState("");
    const [university_id, setUniversity_id] = useState(0);
    const [university_name, setUniversity_name] = useState("");
    const [university_prodi_id, setUniversity_prodi_id] = useState(0);
    const [university_prodi_name, setUniversity_prodi_name] = useState("");
    const [school_id, setSchool_id] = useState("");
    const [school_name, setSchool_name] = useState("");
    const [showForm, setShowForm] = useState({ univ: false, school: false });

    useEffect(() => {
        if (institution_type === "identity.ins.type.university") {
            setShowForm({ univ: true, school: false });
            setGPA(""); setLastsemester(""); setUniversity_id(0); setUniversity_name(""); setUniversity_prodi_id(0); setUniversity_prodi_name("");
        } else if (institution_type === "identity.ins.type.school") {
            setShowForm({ univ: false, school: true });
            setGPA(""); setSchool_name(""); setSchool_id(0);
        } else {
            setShowForm({ univ: false, school: false });
            setGPA(""); setSchool_name(""); setSchool_id(0);
            setLastsemester(""); setUniversity_id(0); setUniversity_name(""); setUniversity_prodi_id(0); setUniversity_prodi_name("");
        }
    }, [institution_type])

    const HandlerChangeUniv = (data) => {
        setUniversity_name(CapitalizeFirstLetter(data.label))
        setUniversity_id(data.value);
    }

    const HandlerChangeUnivProdi = (data) => {
        setUniversity_prodi_name(CapitalizeFirstLetter(data.label));
        setUniversity_prodi_id(data.value);
    }

    const HandlerChangeSchool = (data) => {
        setSchool_name(CapitalizeFirstLetter(data.label));
        setSchool_id(data.value);
    }

    const [submit, setSubmit] = useState({ loading: false, data: [], message: "" });
    const HandlerSubmit = async (e) => {
        e.preventDefault();
        let valid = false;
        setSubmit({ loading: true, data: [], message: "" });

        if (institution_type === "identity.ins.type.university" && university_id && university_prodi_id && lastsemester && yeargraduation) {
            valid = true;
        } else if (institution_type === "identity.ins.type.school" && school_id && yeargraduation) {
            valid = true;
        } else {
            valid = false;
        }

        if (valid) {
            let syc_ins = {};
            let post_param = {institution_type:institution_type, yeargraduation:yeargraduation, reff_id:reff_id};
            if (institution_type === "identity.ins.type.university") {
                // mapping univ and prodi
                syc_ins = { univid: university_id, univprodiid: university_prodi_id };
                const objIns = {univ_name: university_name, univ_prodi: university_prodi_name};
                if (university_id === 9999 || university_prodi_id === 9999) {
                    syc_ins = await SyncInstituteData(university_id, university_prodi_id, objIns);
                }
                //end mapping univ and prodi

                post_param.lastsemester=lastsemester;
                post_param.university_id=syc_ins.univid;
                post_param.university_prodi_id=syc_ins.univprodiid;
            } else if (institution_type === "identity.ins.type.school") {
                //mapping school
                syc_ins = { school_id: school_id };
                if(school_id === 9999) {
                    syc_ins = await SyncSchoolData(school_id, school_name);
                }
                //end mapping school

                post_param.school_id = syc_ins.school_id;
            }
            if(gpa){
                post_param.gpa=gpa;
            }

            console.log(post_param);

            const param = RemoveEmptyValue(post_param);
            const result = await CREATE_INSTITUTIONHIST(param);
            if(result.message){
                openModal({message:<MessageError message={result.message} />});
            }else{
                openModal({message:<MessageSuccess message={"Successfully Saved"} />});
                ReloadData();
            }
            setSubmit({ loading: false, data: [], message: "" });
        } else {
            setSubmit({ loading: false, data: [], message: RequiredMsg });
        }
    }

    return (
        <form method="post" autoComplete="off" onSubmit={(e) => HandlerSubmit(e)}>
            <h1 className="mb-8">Form History of education</h1>
            <div className="row">
                <div className="col-lg-6">
                    <div className="form-group">
                        <label className="required fw-bold">Institute Type</label>
                        <select className="form-select" required defaultValue={institution_type} onChange={(e) => setInstitute_type(e.target.value)}>
                            <option value="">Choose one</option>
                            <option value="identity.ins.type.university">University</option>
                            <option value="identity.ins.type.school">School</option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                        <label className="required fw-bold">Graduation Year</label>
                        <div>
                            <DatePicker className="form-control" isClearable showYearPicker
                                selected={yeargraduation}
                                onChange={(date) => setYeargraduation(date)}
                                dateFormat="yyyy"
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }} />
                        </div>
                    </div>
                </div>
            </div>
            <div id="form-univ" className={(!showForm.univ && "d-none") + " mt-8"}>
                <h4>Previous Institution:</h4>
                <InputTextUniv selected={university_name} HandlerChangeUniv={HandlerChangeUniv} />
                <InputTextUnivProdi selected={university_prodi_name} HandlerChangeUnivProdi={HandlerChangeUnivProdi} univid={university_id} />
                <div className="row">
                    <div className="col-lg-6">
                        <div className="form-group mb-8">
                            <label className="required fw-bold">Semester Passed</label>
                            <input
                                type="text"
                                onKeyDown={handleKeyDown}
                                className="form-control"
                                name="lastsemester"
                                placeholder="Enter semester"
                                defaultValue={lastsemester}
                                onChange={(e) => setLastsemester(parseInt(e.target.value))}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="form-group mb-8">
                            <label className="fw-bold">GPA</label>
                            <input
                                type="text"
                                className="form-control"
                                name="gpa"
                                placeholder="Enter GPA"
                                value={gpa}
                                onKeyPress={(e) => {
                                    const charCode = e.which ? e.which : e.keyCode;
                                    if (
                                        (charCode < 48 || charCode > 57) && // 0-9
                                        charCode !== 46 // .
                                    ) {
                                        e.preventDefault();
                                    }
                                }}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value === '' || (/^\d*\.?\d*$/).test(value)) {
                                        setGPA(value);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div id="form-school" className={(!showForm.school && "d-none") + " mt-8"}>
                <InputTextSchool selected={school_name} HandlerChangeSchool={HandlerChangeSchool} />
                <div className="form-group mb-8">
                    <label className="fw-bold">Scademic Transcript</label>
                    <input
                        type="text"
                        className="form-control"
                        name="gpa"
                        placeholder="Enter Score"
                        value={gpa}
                        onKeyPress={(e) => {
                            const charCode = e.which ? e.which : e.keyCode;
                            if (
                                (charCode < 48 || charCode > 57) && // 0-9
                                charCode !== 46 // .
                            ) {
                                e.preventDefault();
                            }
                        }}
                        onChange={(e) => {
                            const value = e.target.value;
                            if (value === '' || (/^\d*\.?\d*$/).test(value)) {
                                setGPA(value);
                            }
                        }}
                    />
                </div>
            </div>
            {submit.message && <AlertNotif messages={submit.message} />}
            <div className="text-end mt-8">
                <ButtonPrimary type="submit" className="btn-lg" disabled={submit.loading}>
                    {submit.loading ? "Processing..." : "Save Changes"}
                </ButtonPrimary>
            </div>
        </form>
    )
}

const InputTextUniv = ({ selected, HandlerChangeUniv }) => {
    const [result, setResult] = useState({
        loading: false,
        message: "",
        data: [],
    });
    const ReloadData = async () => {
        setResult({ loading: true, message: "", data: [] });
        const result = await GET_UNIV_LOOKUP({ "": "" });

        if (result.data && Object.values(result.data).length > 0) {
            const remove_other = result.data.filter((v) => v.id !== 9999);

            const sort_data = remove_other.sort((a, b) => a.name.localeCompare(b.name));
            const option_result = sort_data.map((v) => {
                let obj = {};
                obj.label = v.name ? decodeURIComponent(CapitalizeFirstLetter(v.name)) : "";
                obj.value = v.id;
                return obj;
            });
            setResult({ loading: false, message: "", data: option_result });
        } else {
            setResult(result);
        }
    };

    useEffect(() => {
        ReloadData();
    }, []);

    return result.loading ? <LoadingBars /> :
        result.message ? <AlertRetry messages={result.message} Reload={ReloadData} /> :
            <div className="select-box mb-8">
                <InputAutoFillIn title={"Previous Institute"} selected={selected} items={result.data} HandlerChangeData={HandlerChangeUniv} />
            </div>
}

const InputTextUnivProdi = ({ selected, HandlerChangeUnivProdi, univid }) => {

    const [result, setResult] = useState({
        loading: false,
        message: "",
        data: [],
    });

    const ReloadData = async (param) => {
        setResult({ loading: true, message: "", data: [] });
        const result = await GET_UNIV_PRODI_LOOKUP(param);

        if (result.data && Object.values(result.data).length > 0) {
            const remove_other = result.data.filter((v) => v.name.toLowerCase() !== "other");

            const sort_data = remove_other.sort((a, b) => a.name.localeCompare(b.name));
            const option_result = sort_data.map((v) => {
                let obj = {};
                obj.label = v.name ? decodeURIComponent(CapitalizeFirstLetter(v.name)) : "";
                obj.value = v.id;
                return obj;
            });
            setResult({ loading: false, message: "", data: option_result });
        } else {
            setResult(result);
        }
    };

    useEffect(() => {
        ReloadData({ univid: univid });
    }, [univid]);

    return result.loading ? <LoadingBars /> :
        result.message ? <AlertRetry messages={result.message} Reload={ReloadData} /> :
            <div className="select-box mb-8">
                <InputAutoFillIn title={"Major at prev university"} selected={selected} items={result.data} HandlerChangeData={HandlerChangeUnivProdi} />
            </div>
}

const InputTextSchool = ({ selected, HandlerChangeSchool }) => {
    const [result, setResult] = useState({
        loading: false,
        message: "",
        data: [],
    });
    const ReloadData = async () => {
        setResult({ loading: true, message: "", data: [] });
        const result = await GET_LOOK_UP_SCHOOL({ "": "" });

        if (result.data && Object.values(result.data).length > 0) {
            const remove_other = result.data.filter((v) => v.id !== 9999);

            const sort_data = remove_other.sort((a, b) => a.name.localeCompare(b.name));
            const option_result = sort_data.map((v) => {
                let obj = {};
                obj.label = v.name ? decodeURIComponent(v.name) : "";
                obj.value = v.id;
                return obj;
            });
            setResult({ loading: false, message: "", data: option_result });
        } else {
            setResult(result);
        }
    };

    useEffect(() => {
        ReloadData();
    }, []);

    return result.loading ? <LoadingBars /> :
        result.message ? <AlertRetry messages={result.message} Reload={ReloadData} /> :
            <div className="select-box mb-8">
                <InputAutoFillIn title={"School Name"} selected={selected} items={result.data} HandlerChangeData={HandlerChangeSchool} />
            </div>
}

export { RowUniversity, RowSchool, FormNewInstitution };