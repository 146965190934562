import React, { useEffect, useState } from "react";
import { READ_FILE } from "../../../apis/module/JupiterRequest";
import { LoadingBars } from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import moment from "moment";

export default function WindowPicture({ data }) {
  const uniqid = data.public_url;
  const arrUniqID = uniqid.split("s/");
  const fileID = arrUniqID[1];

  const [file, setFile] = useState({ loading: false, data: [], message: "" });
  const LOAD_FILE = async () => {
    setFile({ loading: true, data: [], message: "" });

    const result = await READ_FILE(`${fileID}?x=1920&y=1080`);
    setFile(result);
  };

  useEffect(()=>{
    LOAD_FILE();
  },[])
  return (
    <div>
      <h1 className="text-uppercase">{data.file_type}</h1>
      <div className="row mb-5">
        <div className="col-lg-6">
          <div className="fs-6">
            <span className="fw-bold">File Name:</span>
            <p className="text-capitalize text-wrap mb-0">{data.filename}</p>
          </div>
          <div className="fs-6">
            <span className="fw-bold">File Type</span>
            <span className="text-capitalize">: {data.mimetype}</span>
          </div>
        </div>
        <div className="col-lg-6">
          <div className="fs-6">
            <span className="fw-bold">File Size</span>
            <span className="text-capitalize">: {data.size} KB</span>
          </div>
          <div className="fs-6">
            <span className="fw-bold">File Owner</span>
            <span className="text-lowercase">: {data.owner}</span>
          </div>
          <div className="fs-6">
            <span className="fw-bold">Created At</span>
            <span className="text-lowercase">: {moment(data.created).format("DD MMM YYYY, hh:mm A")}</span>
          </div>
        </div>
      </div>
      {file.loading ? <LoadingBars /> :
      file.message ? <AlertNotif messages={file.message} /> :
      Object.values(file.data).length > 0 && (
        <div className="text-center cursor-pointer" onClick={()=>window.open(file.data,"_blank").focus()}>
            <img src={file.data} alt={data.filename} id="img-load" className="w-100 rounded" />
        </div>
      )}
    </div>
  );
}
