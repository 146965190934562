import React from 'react'
import moment from "moment";
import BG1 from "../../../_layouts/_assets/media/background/14-dark.png"
import { ImageComp } from '../../../_layouts/components/images/Logos'
import { VersionApp } from '../../../_layouts/_core/Core'
import { Outlet } from 'react-router-dom';
import Modals from '../../../_layouts/components/modals/Modals';

export function AuthLayout() {
    const year = moment().format("Y")
    return (
        <div
            className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bg-light bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
            style={{ backgroundImage: `url(${BG1})` }}
        >
            <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
                <div className='mb-5 bg-white rounded px-5 py-3 shadow-sm '>
                    <ImageComp className='h-45px' />
                </div>
                <div className='w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
                    <Outlet />
                </div>
            </div>

            <div className='d-flex flex-column align-items-center justify-content-center'>
                <p className="text-center mb-0 text-muted">Version {VersionApp}</p>
                <div className='d-flex align-items-center fw-semibold'>
                    <a href='https://sgu.ac.id/' target='_blank' className='text-muted text-hover-primary'>
                        SGU &copy; {year} &#183;
                    </a>

                    <a href='https://helpdesk.sgu.ac.id/' target='_blank' className='text-muted text-hover-primary px-2'>
                        Help &#183;
                    </a>

                    <a href='https://helpdesk.sgu.ac.id/' target='_blank' className='text-muted text-hover-primary'>
                        Contact Us
                    </a>
                </div>
            </div>
            <Modals />
        </div>
    )
}
