import React, { useEffect, useState } from "react";
import { RSAencryptData } from "../../../../_layouts/_helpers/RSAEnc";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import {
  AlertNotif,
} from "../../../../_layouts/components/alerts/AlertsUI";
import { GET_REQUEST_DOC } from "../../apis/module/JupiterRequest";
import { useOutletContext } from "react-router-dom";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import FilterUI from "../../components/filters/FilterUI";
import { InputTextName, SelectDocType } from "./components/FilterUI";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";
import TableData from "./components/TableData";
import { ButtonPrimary } from "../../../../_layouts/components/buttons/ButtonUI";
import DetailFile from "./components/DetailFile";
import FormStorageFile from "./components/FormStorageFile";

export function MStorageFiles() {
  const context = useOutletContext();
  const widget_104 = context.widgets.find(
    (item) => item.widget_id === 104 && item.is_enabled === 1
  );

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const [open3rdColl, setOpen3rdColl] = useState({open:false, data:[]});
  const [isOpenForm, setIsOpenForm] = useState({open:false, data:[]});
  const [filename, setFilename] = useState("");
  const [doc_type, setDoc_type] = useState("");
  const [user, setUser] = useState("");
  const [owner, setOwner] = useState("");

  const ReloadDocFile = async () => {
    setOpen3rdColl({open:false, data:[]});
    const post_param = { doc_type, user, owner, filename, path:"SGU" };
    const param = RemoveEmptyValue(post_param);
    const objData = JSON.stringify(param);
    const rsaEnc = RSAencryptData(objData);
    setResults({ loading: true, data: [], message: "" });
    const formData = new FormData();
    formData.append("data", rsaEnc);
    const result = await GET_REQUEST_DOC(formData);
    setResults(result);
  };

  const SubmitFilter = (e) => {
    e.preventDefault();
    ReloadDocFile();
  };

  useEffect(() => {
    if (isOpenForm.open) {
      setOpen3rdColl({open:false, data:[]});
    }
  }, [isOpenForm.open]);

  useEffect(()=>{
    ReloadDocFile()
  },[])

  return widget_104 ? (
    <div>
        {isOpenForm.open && (
            <FormStorageFile ReloadDocFile={ReloadDocFile} setIsOpenForm={setIsOpenForm} data={isOpenForm.data} />
        )}
        <div className="row">
        <div className="col-lg-3 mb-5">
            <FilterUI
            title="Storage file"
            onSubmit={SubmitFilter}
            isload={results.loading}
            >
            <SelectDocType data={doc_type} setData={setDoc_type} />
            <InputTextName data={owner} setData={setOwner} title={"Owner File"} />
            <InputTextName
                data={filename}
                setData={setFilename}
                title={"Filename"}
            />
            <InputTextName data={user} setData={setUser} title={"User File"} />
            </FilterUI>
        </div>
        <div className={"mb-5 col-lg-" + (open3rdColl.open ? 6 : 9)}>
            <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bolder text-dark">Management Files</span>
            </SGUCard.Header>
            <SGUCard.Toolbar>
                {!isOpenForm.open && (
                <ButtonPrimary type="button" className="btn-sm me-2" onClick={()=>setIsOpenForm({open:true, data:[]})}>
                    <i className="ki-outline ki-folder-up fs-2"></i>
                    Upload File
                </ButtonPrimary>
                )}
            </SGUCard.Toolbar>
            <SGUCard.Body className={"px-0"}>
                <TableData results={results} Reload={ReloadDocFile} open3rdColl={open3rdColl} setOpen3rdColl={setOpen3rdColl} setIsOpenForm={setIsOpenForm} />
            </SGUCard.Body>
            </SGUCard>
        </div>
        {open3rdColl.open && <div className="col-lg-3"><DetailFile data={open3rdColl} setData={setOpen3rdColl} /></div>}
        </div>
    </div>
  ) : (
    <AlertNotif messages={AccessInvalid} />
  );
}
