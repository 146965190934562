import ImgErrorLight from "../../_assets/media/errors/404-error.png";
import ImgErrorDark from "../../_assets/media/errors/404-error-dark.png";


const Error404Light = ({...others}) =>{
    return (
        <img src={ImgErrorLight} alt="SGU-UMS" {...others} />
    );
}
const Error404Dark = ({...others}) =>{
    return (
        <img src={ImgErrorDark} alt="SGU-UMS" {...others} />
    );
}

export {Error404Light, Error404Dark};