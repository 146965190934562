import React, { useMemo, useState } from "react";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  PaginationComponent,
  SearchBarTable,
} from "../../../../_layouts/components/datatables/MYDatatables";
import { ButtonCreateSection } from "../teaching-loads/components/ButtonCreate";
import moment from "moment";
import { LinkBtn } from "../../../../_layouts/components/buttons/LinkUI";

export function TableData(props) {
  const { data, is_create, ReloadData, paramTL } = props;

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: "Course", field: "coursetitle", sortable: true },
    { name: "Credit", field: "credit", sortable: false },
    { name: "Academic Term", field: "id", sortable: false },
    { name: "Max Session", field: "course_lesson", sortable: true },
    { name: "Status", field: "id", sortable: false },
    { name: "Action", field: "id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];

    if (Object.values(data).length > 0) {
      computedData = data.map(v => {
        var obj = {};
        obj.course_id = v.id;
        obj.coursetitle = v.coursetitle ? v.coursetitle : "";
        obj.credit = v.credit ? v.credit : "";
        obj.code = v.code ? v.code : "";
        let courseTypeArr = v.course_type.split(".");
        let courseType = courseTypeArr[courseTypeArr.length - 1];
        obj.course_type = courseType ? courseType : "";
        obj.course_lesson = v.course_lesson ? v.course_lesson : "";
        obj.atp_start = v.atp_start ? v.atp_start.name : "";
        obj.atp_end = v.atp_end ? v.atp_end.name : "";
        obj.audit_created = v.audit_created ? v.audit_created : "";
        obj.audit_createdby = v.audit_createdby ? v.audit_createdby : "";
        obj.audit_edited = v.audit_edited ? v.audit_edited : "";
        obj.audit_editedby = v.audit_editedby ? v.audit_editedby : "";
        obj.status = v.status ? v.status : "";
        obj.detail = v;
        return obj;
      })

      computedData.sort((a, b) => (a.status < b.status ? -1 : 1));
    }

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          //(listData[key]) ? listData[key].includes(search) : ''
          listData[key].includes(search)
        );
      });
    }
    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [data, currentPage, search, sorting]);

  return (
    <div className="list-courses">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <ButtonItemRefresh
          totalItems={totalItems}
          onClick={() => ReloadData()}
        />
      </div>
      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {ResultData.length > 0 ? (
              ResultData.map((listData, index) => {
                let courseTypeArr = listData.course_type.split(".");
                let courseType = courseTypeArr[courseTypeArr.length - 1];
                if (paramTL) {
                  paramTL.curr_group_type = paramTL.curr_group;
                  listData.paramTL = paramTL;
                }

                return (
                  <tr key={index} className={index % 2 === 0 ? "odd" : "even"}>
                    <td className="fs-7">
                      <span className="d-block text-dark fw-bolder">
                        {listData.coursetitle}
                      </span>
                      <span className="d-block text-muted">
                        {listData.code}
                      </span>
                    </td>
                    <td className="fs-7">
                      <span className="d-block text-dark fw-bolder">
                        Credit {listData.credit}
                      </span>
                      <span className="d-block text-muted text-capitalize">
                        {courseType}
                      </span>
                    </td>
                    <td className="fs-7" width={"25%"}>
                      <span className="d-block text-dark fw-bolder fs-8">
                        {listData.atp_start}
                      </span>
                      <span className="d-block text-muted text-capitalize me-2 fs-8">until</span>
                      {listData.atp_end ? (
                        <span className="d-block text-dark fw-bolder fs-8">
                          {listData.atp_end}
                        </span>
                      ) : <span className="d-block text-dark fw-bolder fs-8">Now</span>}
                    </td>
                    <td className="fs-7">
                      <span className="d-block text-dark fw-bolder">
                        {listData.course_lesson}
                      </span>
                    </td>
                    <td>
                      <span className={"badge badge-light-" + (listData.status === "ACTIVE" ? "primary" : "danger")}>
                        {listData.status}
                      </span>
                      <div>
                        <small className="text-muted fs-8">
                          {listData.audit_createdby}
                        </small>
                        <small className="d-block text-muted fs-8">
                          {listData.audit_created && moment(listData.audit_created).format("DD MMM YYYY h:mm A")}
                        </small>
                      </div>
                    </td>
                    <td>
                      <div className="btn-group">
                        {listData.status === "ACTIVE" && (
                          is_create && <ButtonCreateSection param={listData} />
                        )}
                        {!is_create && (
                          <LinkBtn to={"detail/"+btoa(JSON.stringify({course:listData, param:paramTL}))} title="Detail" type="button" className="btn btn-icon btn-sm btn-light btn-active-light-primary">
                            <i className="ki-outline ki-arrow-right fs-5 m-0"></i>
                          </LinkBtn>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  <p>No record found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {ResultData.length > 0 && (
          <div className="footer">
            <PaginationComponent
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
