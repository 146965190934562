import React from 'react'
import { Link, useLocation } from 'react-router-dom';

export default function Breadcrumb() {
    const location = useLocation();
    const { pathname } = location;
    let current_pathArr = pathname.split(/\//g);
    current_pathArr = current_pathArr.filter(item => item !== "");
    let current_path = current_pathArr[0] && current_pathArr[0].replace(/[^\w\s]/gi, ' ');
    

    return (
        <div className="page-title d-flex justify-content-center flex-column me-5">
            <h1 className="d-flex flex-column text-gray-900 fw-bolder fs-3 mb-0 text-capitalize">{current_path}</h1>
            <ul className="breadcrumb breadcrumb-separatorless fw-bold fs-7 pt-1">
                <li className="breadcrumb-item text-muted">
                    <Link to={"/dashboard"} className="text-muted text-hover-primary text-capitalize">
                        UMS
                    </Link>
                </li>

                {Object.values(current_pathArr).length > 1 &&
                    current_pathArr.map((v, index) => (
                        ((v && (!isJwt(v)) && index !== 0) && (
                            <React.Fragment key={index}>
                                <li className="breadcrumb-item">
                                    <span className="bullet bg-gray-300 w-5px h-2px"></span>
                                </li>
                                <li className="breadcrumb-item text-muted" key={index}>
                                    <span className="text-muted text-hover-primary text-capitalize">
                                        {v.replace(/[^\w\s]/gi, ' ')}
                                    </span>
                                </li>
                            </React.Fragment>
                        ))
                    ))}
            </ul>
        </div>
    )
}

const isJwt = (token) => {
    try {
        atob(token);
        return true;
    } catch (error) {
        return false;
    }
    
  };