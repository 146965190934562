import React, { useState } from "react";
import { SGUCardFlush } from "../../../../_layouts/components/cards/CardUI";
import {
  ButtonIcon,
  ButtonLong,
  ButtonPrimary
} from "../../../../_layouts/components/buttons/ButtonUI";
import { SpinnerLoad } from "../../../../_layouts/components/loadbars/LoadingBars";
import { KTIcon } from "../../../../_metronic/helpers";

export default function FilterUI(props) {
  const { children, title, onSubmit, isload } = props;
  const [show, setShow] = useState(true);
  const [isFilterVisible, setIsFilterVisible] = useState(true);

  const handleCloseFilter = (e) => {
    e.preventDefault();
    let parent = e.currentTarget;
    while (parent && parent.id !== "kt_app_content_container") {
      parent = parent.parentElement;
    }

    if (parent) {
      const rowElement = parent.querySelector(".row");

      if (rowElement) {
        const colLg3 = rowElement.querySelector(".col-lg-3");
        const colLg9 = rowElement.querySelector(".col-lg-9");

        if (colLg3 && colLg9) {
          colLg3.classList.remove("col-lg-3");
          colLg3.classList.add("col-auto"); 
        
          colLg9.classList.remove("col-lg-9");
          colLg9.classList.add("col-lg-12");
          setIsFilterVisible(false);
          console.log("Filter closed and layout adjusted");
        } else {
          console.log("Could not find col-lg-3 or col-lg-9 elements");
        }
      } else {
        console.log("Could not find row element");
      }
    } else {
      console.log("Parent element not found");
    }
  };

  const handleShowFilter = () => {
    const parent = document.getElementById("kt_app_content_container");
    if (parent) {
      const rowElement = parent.querySelector(".row");
      if (rowElement) {
        const colLg3 = rowElement.querySelector(".col-auto");
        const colLg12 = rowElement.querySelector(".col-lg-12");

        if (colLg3 && colLg12) {
          colLg3.classList.remove("col-auto");
          colLg3.classList.add("col-lg-3");
          
          colLg12.classList.remove("col-lg-12");
          colLg12.classList.add("col-lg-9");
          setIsFilterVisible(true);
          console.log("Filter shown and layout adjusted");
        }
      }
    }
  };

  return (
    <>
      {!isFilterVisible && (
        <ButtonPrimary onClick={handleShowFilter} className="mb-5 fw-bold px-10">
          <KTIcon iconName="filter" className="fs-2 text-white" />
          Show Filter
        </ButtonPrimary>
      )}
      {isFilterVisible && (
        <SGUCardFlush>
          <SGUCardFlush.Body>
            <form
              method="post"
              autoComplete="off"
              className="mt-8"
              onSubmit={(e) => onSubmit(e)}
            >
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <div className="symbol symbol-circle me-5 d-none">
                    <div className="symbol-label bg-transparent text-primary border border-secondary border-dashed">
                      <i className="ki-outline ki-filter fs-2x text-primary"></i>
                    </div>
                  </div>
                  <div className="d-flex flex-column">
                    <h3>Filter {title}</h3>
                    <div className="text-muted fw-semibold">
                      Fill out the form correctly
                    </div>
                  </div>
                </div>

                <div className="toolbar">
                  <ButtonIcon
                    className="btn-active-light-primary d-none"
                    type="button"
                    onClick={() => setShow(!show)}
                  >
                    <i
                      className={
                        "ki-outline ki-" +
                        (show ? "down" : "up") +
                        " fs-2x text-primary"
                      }
                    ></i>
                  </ButtonIcon>
                  <ButtonIcon
                    className="btn-active-light-primary btn-close-filter"
                    type="button"
                    title="Close Filter"
                    onClick={handleCloseFilter}
                  >
                    <i
                      className={"ki-outline ki-cross fs-2x text-primary"}
                    ></i>
                  </ButtonIcon>
                </div>
              </div>
              {show && (
                <div className="form-filter mt-5">
                  {children}

                  <div className="text-end mt-5">
                    <ButtonLong
                      className="btn-sm btn-primary px-6 fw-bold"
                      type="submit"
                      disabled={isload}
                    >
                      {isload ? (
                        <SpinnerLoad />
                      ) : (
                        <>
                          <i className="bi bi-search"></i>
                          Search
                        </>
                      )}
                    </ButtonLong>
                  </div>
                </div>
              )}
            </form>
          </SGUCardFlush.Body>
        </SGUCardFlush>
      )}
    </>
  );
}