import { AxiosLibGlobal } from "../../../_layouts/_helpers/Helpers";
import { AuthDecodeToken } from "../../reducer/AuthRedux";

const current_account = AuthDecodeToken()

const RequestData = async (data) => {
    try {
        const config = {
            method: data.method,
            service: "BACKEND_REQUEST",
            pathuri: data.pathuri,
            headers: data ? data.headers : {},
            payload: data ? data.payload : {}
        }
        const results = await AxiosLibGlobal(config);
        if (Object.values(results.data).length > 0) {
            return results.data;
        } else {
            return results;
        }
    } catch (error) {
        return { loading: false, data: [], message: "" }
    }
}

const ReqAPIEstes = async (payload) => {
    const config = {
        method: "post",
        service: "REQUEST_TARGET_DATA",
        headers: current_account.headers,
        payload: payload
    }

    const result = await AxiosLibGlobal(config);

    if (Object.values(result.data).length > 0) {
        const responce = result.data.response;
        if (responce.data && Object.values(responce.data).length > 0) {
            return { loading: false, message: "", data: responce.data }
        } else {
            return { loading: false, message: "", data: [] }
        }
    } else {
        return result;
    }
}

const RequestExternalPostEstes = async (data) => {
    try {
        const config = {
            method: data.method,
            service: "REQUEST_EXTERNAL_PASS",
            pathuri: data.pathuri,
            headers: data ? data.headers : {},
            payload: data ? data.payload : {}
        }
        const results = await AxiosLibGlobal(config);
        if (Object.values(results.data).length > 0) {
            return results.data;
        } else {
            return results;
        }
    } catch (error) {
        return { loading: false, data: [], message: "" }
    }
}

const RequestExternalAPI = async (data) => {
    try {
        const config = {
            method: data.method,
            service: "EXTERNAL_URI",
            pathuri: data.pathuri,
            headers: data ? data.headers : {},
            payload: data ? data.payload : {}
        }
        const results = await AxiosLibGlobal(config);
        if (Object.values(results.data).length > 0) {
            return results.data;
        } else {
            return results;
        }
    } catch (error) {
        return { loading: false, data: [], message: "" }
    }
}

export { RequestData, ReqAPIEstes, RequestExternalPostEstes, RequestExternalAPI }
