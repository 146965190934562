import { useEffect, useState } from "react";
import { openModal } from "../../../../../_layouts/components/modals/Modals";
import { ButtonTersier } from "../../../../../_layouts/components/buttons/ButtonUI";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import { GET_PROPROF_RESULT } from "../../../apis/module/ProprofRequest";

const ModalEntranceTest = ({ candidate_id }) => {
  const [data, setData] = useState({ loading: true, message: "", data: {} });

  const detailData = async () => {
    console.log("candidate_id", candidate_id);
    setData({ loading: true, message: "", data: {} });

    const response = await fetch(
      `${process.env.REACT_APP_PROPROFS_URL}/api/classroom/v1/reports/users/`,
      {
        method: "POST",
        mode: "cors",
        header: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: process.env.REACT_APP_PROPROFS_TOKEN,
          username: "admission@sgu.ac.id",
          email_or_id: `${candidate_id}@sgu.ac.id`,
        }),
      }
    );

    const x = await response.json();
    // console.log("response", x);

    if (x.status === "SUCCESS") {
      setData({
        loading: false,
        message: "",
        data: x.result.length > 0 ? x.result[0] : {},
      });
    } else {
      setData({ loading: false, message: x.status, data: {} });
    }
  };

  useEffect(() => {
    detailData();
  }, []);

  return (
    <div>
      {data.loading ? (
        <LoadingSkeleton />
      ) : Object.keys(data.data).length > 0 ? (
        <div className="ms-5">
          <h5 className="text-primary">{data.data.Name}</h5>
          <h5 className="text-muted">{data.data.ID}</h5>

          <div className="mt-10">
            <h5>Assignment</h5>
            {data?.data?.assignment.map((q, i) => (
              <div className="ms-5 mb-5" key={i}>
                <tr>
                  <td className="pe-5">Quiz</td>
                  <td className="fw-bold d-flex">: {q.title}</td>
                </tr>
                <tr>
                  <td className="pe-5">Point</td>
                  <td className="fw-bold d-flex">: {q.percentCompleted} %</td>
                </tr>
                <tr>
                  <td className="pe-5">Status</td>
                  <td
                    className={`fw-bold d-flex ${
                      q.percentCompleted >= 70
                        ? "text-success"
                        : q.percentCompleted === 0
                        ? "text-warning"
                        : "text-danger"
                    }`}
                  >
                    :{" "}
                    {q.percentCompleted >= 70
                      ? "Passed"
                      : q.percentCompleted === 0
                      ? "Pending"
                      : "Failed"}
                  </td>
                </tr>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <NoRecordFound />
      )}
      <div class="separator my-10"></div>
      <div className="d-flex justify-content-end">
        <ButtonTersier onClick={() => openModal({ open: false })}>
          Close
        </ButtonTersier>
      </div>
    </div>
  );
};

export { ModalEntranceTest };
