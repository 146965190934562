import { Link } from "react-router-dom"
import { renderTooltip } from "../../../../../../_layouts/components/popovers/PopOvers";
import { OverlayTrigger } from "react-bootstrap";


const StatusPerson = (status) => {
    let color = "";
    switch (status) {
        case "asc.student.status.active":
            color = "success";
            break;
        case "asc.student.status.graduate":
            color = "info";
            break;
        case "asc.student.status.leave":
            color = "warning";
            break;
        default:
            color = "danger";
            break;
    }

    return color;
}

const ItemPerson = ({ data }) => {
    const status_color = StatusPerson(data.status_id);
    return (
        <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement="top"
            overlay={renderTooltip({ title: `${data.clu}` })}
        >
            <div className="item-person m-3">
                <div className="d-flex align-items-center">
                    <div className="symbol  symbol-40px symbol-circle ">
                        <span className={"symbol-label  bg-light-" + status_color + " text-" + status_color + " fs-6 fw-bolder "}>{data.icon}</span>
                        <span className={"symbol-badge bg-" + status_color + " start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2"}></span>
                    </div>
                    <div className="ms-4">
                        <span className="fs-6 fw-bold text-gray-900 mb-2">
                            {data.name}
                        </span>
                        <div className="fw-semibold fs-7 text-muted">
                            {data.email}
                        </div>
                    </div>
                </div>
            </div>
        </OverlayTrigger>
    )
}

const ItemPersonStaff = ({ data }) => {
    const status_color = data.status_id === 1 ? "success":"danger";
    return (
        <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement="top"
            overlay={renderTooltip({ title: `${data.position}` })}
        >
            <div className="item-person m-3">
                <div className="d-flex align-items-center">
                    <div className="symbol  symbol-40px symbol-circle ">
                        <span className={"symbol-label  bg-light-" + status_color + " text-" + status_color + " fs-6 fw-bolder "}>{data.icon}</span>
                        <span className={"symbol-badge bg-" + status_color + " start-100 top-100 border-4 h-15px w-15px ms-n2 mt-n2"}></span>
                    </div>
                    <div className="ms-4">
                        <span className="fs-6 fw-bold text-gray-900 mb-2">
                            {data.name}
                        </span>
                        <div className="fw-semibold fs-7 text-muted">
                            {data.email}
                        </div>
                    </div>
                </div>
            </div>
        </OverlayTrigger>
    )
}

export { ItemPerson, ItemPersonStaff }