import React from "react";

export function TableHeader({ score_component, selectedColumn }) {
  return (
    <thead>
      <tr
        className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bold text-uppercase"
        style={{ fontSize: "11px" }}
      >
        <th className="freeze-column w-250px bg-light align-middle text-center">
          Student
        </th>
        {score_component.map((c, index) => (
          <th
            key={index}
            className={
              "text-center " +
              (selectedColumn === c.wg_com_id
                ? "active text-primary"
                : "cursor-pointer bg-light")
            }
          >
            <div className="min-w-100px">
              <div className="text-wrap">{decodeURIComponent(c.name)}</div>
              <div className="cursor-pointer">
                <span>{(c.weight * 100).toFixed(0)}%</span>
              </div>
            </div>
          </th>
        ))}
        <th className="text-center align-middle  bg-light">Score</th>
        <th className="text-center align-middle  bg-light">Grade</th>
        <th className="text-center align-middle  bg-light">Absent</th>
        <th className="text-center align-middle  bg-light min-w-120px">
          Final Result
        </th>
      </tr>
    </thead>
  );
}
