import { ReqAPIEstes } from "../ApiBackend";

const GET_EMPLOYEE_LOOK_UP = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_EMPLOYEE_LOOK_UP"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const LOOK_UP_COST_CENTER = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "LOOK_UP_COST_CENTER"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_EMP_BIO_BY_IDENID = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_EMP_BIO_BY_IDENID"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_HR_FISCAL_YEAR = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_HR_FISCAL_YEAR"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}
export {GET_EMPLOYEE_LOOK_UP, LOOK_UP_COST_CENTER, GET_EMP_BIO_BY_IDENID, GET_HR_FISCAL_YEAR}