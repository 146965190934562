import React, { useEffect, useState } from 'react'
import { SGUCard } from '../../../../../../_layouts/components/cards/CardUI'
import moment from 'moment'
import { GET_SCHD_CLASS_STD_NEXT } from '../../../../apis/node-target-data/AcademicApi';
import FullCalendarStdSchd from './FullCalendarStdSchd';
import { LoadingSkeleton } from '../../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif, AlertRetry } from '../../../../../../_layouts/components/alerts/AlertsUI';
import { ButtonIcon, ButtonTersier } from '../../../../../../_layouts/components/buttons/ButtonUI';

export default function TimetableStd({ data }) {
    const studentid = data.studentid;
    const [schd, setSchd] = useState({
        loading: false,
        message: "",
        data: [],
    });

    const startOfMonth = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
    const endOfMonth = moment()
        .endOf("month")
        .add(7, "days")
        .format("YYYY-MM-DD");

    const paramPost = {
        studentid: studentid,
        tgl: startOfMonth,
        tglend: endOfMonth,
    };
    const [paramDate, setParamDate] = useState(paramPost);

    const GET_SCHD = async (param) => {
        setSchd({ loading: true, data: [], message: "" });
        const result = await GET_SCHD_CLASS_STD_NEXT(param);

        if (result.data && Object.values(result.data).length > 0) {
            const data_calendar = result.data.map(v => {
                let obj = {};
                obj.title = v.section_id ? (v.course ? v.course.coursetitle : "-") : v.label;
                obj.id = v.section_id ? v.section_id : "";
                obj.start = v.start_dt;
                obj.end = v.end_dt;
                obj.groupId = JSON.stringify(v);
                obj.backgroundColor = '#00ff00';
                obj.borderColor = '#00ff00';
                return obj;
            });
            setSchd({loading:false, message:"", data:data_calendar});
        } else {
            setSchd(result);
        }

    }

    useEffect(() => {
        GET_SCHD(paramDate);
    }, [])

    const handlerPrevNextMonth = (type) => {
        if (type === 1) {
            const startOfMonth = moment(paramDate.tgl)
                .subtract(1, "months")
                .startOf("month")
                .format("YYYY-MM-DD");
            const endOfMonth = moment(paramDate.tgl)
                .subtract(1, "months")
                .endOf("month")
                .format("YYYY-MM-DD");
            setParamDate({ ...paramDate, tgl: startOfMonth, tglend: endOfMonth });
            let paramSchd = {
                studentid: studentid,
                tgl: startOfMonth,
                tglend: endOfMonth,
            };
            GET_SCHD(paramSchd);
        } else if (type === 2) {
            const startOfMonth = moment(paramDate.tgl)
                .add(1, "months")
                .startOf("month")
                .format("YYYY-MM-DD");
            const endOfMonth = moment(paramDate.tgl)
                .add(1, "months")
                .endOf("month")
                .format("YYYY-MM-DD");
            setParamDate({ ...paramDate, tgl: startOfMonth, tglend: endOfMonth });
            let paramSchd = {
                studentid: studentid,
                tgl: startOfMonth,
                tglend: endOfMonth,
            };
            GET_SCHD(paramSchd);
        } else {
            const startOfMonth = moment(new Date())
                .startOf("month")
                .format("YYYY-MM-DD");
            const endOfMonth = moment(new Date())
                .endOf("month")
                .format("YYYY-MM-DD");
            setParamDate({ ...paramDate, tgl: startOfMonth, tglend: endOfMonth });
            let paramSchd = {
                studentid: studentid,
                tgl: startOfMonth,
                tglend: endOfMonth,
            };
            GET_SCHD(paramSchd);
        }
    };
    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bolder fs-3 mb-1">Timetable</span>
                <span className="text-muted mt-1 fw-bold fs-7">Term {data.atp_term && data.atp_term.name}</span>
            </SGUCard.Header>
            <SGUCard.Toolbar>
                <div className="btn-group">
                    <ButtonIcon className="btn-lg btn-light" type="button" disabled={schd.loading} onClick={() => handlerPrevNextMonth(1)}>
                        <i className="ki-outline ki-left fs-5 m-0"></i>
                    </ButtonIcon>
                    <ButtonTersier className="btn-lg" type="button" disabled={schd.loading} onClick={() => handlerPrevNextMonth(3)}>
                        <span className="fw-bolder">
                            {moment(paramDate.tgl).format("MMMM YYYY")}
                        </span>
                    </ButtonTersier>
                    <ButtonIcon className="btn-lg btn-light" type="button" disabled={schd.loading} onClick={() => handlerPrevNextMonth(2)}>
                        <i className="ki-outline ki-right fs-5 m-0"></i>
                    </ButtonIcon>
                </div>
            </SGUCard.Toolbar>
            <SGUCard.Body>
                {schd.loading ? <LoadingSkeleton /> :
                    schd.message ? <AlertRetry messages={schd.message} Reload={GET_SCHD(paramDate)} /> :
                        (
                            <FullCalendarStdSchd
                                data={Object.values(schd.data).length > 0 ? schd.data : []}
                                initialDate={paramDate.tgl}
                                GET_SCHD_CLASS_STD_NEXT={GET_SCHD}
                                paramDate={paramDate}
                                actionDetail={true}
                            />
                        )}
            </SGUCard.Body>
        </SGUCard>
    )
}
