import React, { useCallback, useEffect, useMemo, useState } from "react";
import { debounce } from 'lodash';
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import {
  LoadingSkeleton,
  NoRecordFound,
  SpinnerLoad,
} from "../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif, AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import {
  ButtonItemRefresh,
  HeaderDatatables,
} from "../../../../_layouts/components/datatables/MYDatatables";
import { GET_COMPANY } from "../../apis/node-target-data/IdentityApi";
import moment from "moment";
import { useOutletContext } from "react-router-dom";
import {
  ButtonIcon,
  ButtonPrimary,
  ButtonTersier,
} from "../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../_metronic/helpers";
import { openModal } from "../../../../_layouts/components/modals/Modals";
import FormCompany from "./FormCompany";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import { InputSearch } from "../components/InputComp";

export function CompanyManagement() {
  const context = useOutletContext();
  const widget_96 = context.widgets.find(
    (item) => item.widget_id === 96 && item.is_enabled === 1
  );
  const widget_101 = context.widgets.find(
    (item) => item.widget_id === 101 && item.is_enabled === 1
  );
  const [name, setName] = useState("");
  const [offset, setOffset] = useState(1);
  const limit = 10;
  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const ReloadData = async (param) => {
    setResults(prev => ({ ...prev, loading: true, message: "" }));
    const result = await GET_COMPANY(param);
    if (result.data && Object.values(result.data).length > 0) {
      setResults(prev => ({
        loading: false,
        data: [...prev.data, ...result.data],
        message: "",
      }));
    } else {
      setResults({
        loading: false,
        data: [...results.data],
        message: result.message || "No new data found",
      });
    }
  };

  useEffect(() => {
    const param = { name, offset, limit }
    ReloadData(param);
  }, []);

  const RefreshData = () =>{
    setName("");
    setOffset(1);
    setResults({ loading: true, message: "", data:[] });
    ReloadData({ name:"", offset:1, limit })
  }

  const FindDataDebounced = useCallback(debounce((param) => {
    ReloadData(param);
    setResults({ loading: true, message: "", data:[] });
  }, 500), []);

  const HandlerSearch = (value) =>{
    if(value.length > 3){
      setName(value);
      const param = { name:encodeURIComponent(value), offset:1, limit }
      FindDataDebounced(param)
    }
  }

  const LoadMoreContent = () => {
    const offset_next = offset + 1
    setOffset(offset_next);
    const param = { name, offset: offset_next, limit }
    ReloadData(param);
  }

  const [totalItems, setTotalItems] = useState(0);
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const headers = [
    { name: "No", field: "id", sortable: false },
    { name: "Name", field: "name", sortable: true },
    { name: "PIC", field: "contactperson", sortable: false },
    { name: "Contact", field: "cellular1", sortable: false },
    { name: "Address", field: "address", sortable: true },
    { name: "Created By", field: "audit_createdby", sortable: false },
    { name: "Actions", field: "id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = results.data;

    if (Object.values(results.data).length > 0) {
      computedData.sort((a, b) => (a.id < b.id ? 1 : -1));
    }

    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedData;
  }, [results.data, sorting]);

  return widget_96 ? (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bolder text-dark">
          Master Data Company
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        {widget_101 && (
          <ButtonPrimary
            type="button"
            className="btn-sm fw-bold"
            onClick={() =>
              openModal({ message: <FormCompany ReloadData={ReloadData} /> })
            }
          >
            <KTIcon iconName="plus" />
            Create New
          </ButtonPrimary>
        )}
      </SGUCard.Toolbar>
      <SGUCard.Body>
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <InputSearch defaultValue={name} onChange={(e) => HandlerSearch(e.target.value)} results={results} />

          <div className="d-flex">
            <ButtonItemRefresh
              totalItems={totalItems}
              onClick={() => RefreshData()}
            />
          </div>
        </div>
        {results.loading ? (
          <LoadingSkeleton />
        ) : results.message ? (
          <AlertNotif messages={results.message} />
        ) : (
          <div className="table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
              <HeaderDatatables
                headers={headers}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <tbody>
                {results.loading ? (
                  <tr>
                    <td colSpan={7}><LoadingSkeleton /></td>
                  </tr>
                ) : results.message ? (
                  <tr>
                    <td colSpan={7}><AlertRetry messages={results.message} Reload={ReloadData} /></td>
                  </tr>
                ) : ResultData.length > 0 ? (
                  ResultData.map((v, index) => (
                    <tr key={index}>
                      <td width={"4%"} className="fs-7">
                        {index + 1}
                      </td>
                      <td className="fs-7 text-capitalize fw-bold">{v.name}</td>
                      <td className="fs-7">
                        {v.contactperson && (
                          <div>
                            <span className="fw-bold">{v.contactperson}</span>
                          </div>
                        )}
                        {v.address &&
                          v.address.cellular1 && (
                            <div className="d-flex align-items-center">
                              <span className="bi bi-telephone-fill text-muted fs-1x me-1"></span>
                              {v.address.cellular1}
                            </div>
                          )}
                      </td>
                      <td className="fs-7">
                        <span className="d-block">{v.address && (v.address.email || "")}</span>
                        <span className="d-block">{v.address && (v.address.phone1 || "")}</span>
                        {v.address && (v.address.phone2 && (
                          <span className="">{v.address.phone2}</span>
                        ))}
                      </td>
                      <td className="fs-7 text-capitalize">
                        {v.address && (
                          <>
                            <div>
                              {v.address.address && (
                                <>
                                  <KTIcon iconName="map" className="fs-1x" />
                                  {(decodeURIComponent(v.address.address) || "")}
                                </>
                              )}
                            </div>
                            <div>
                              {v.address.city && (
                                <span className="me-2">
                                  {v.address.city.name || ""},
                                </span>
                              )}
                              <span>
                                {v.address.country && v.address.country.name}
                              </span>
                            </div>
                          </>
                        )}
                      </td>
                      <td className="fs-7">
                        <span className="fw-bold d-block">{v.audit_createdby}</span>
                        <span className="text-muted">{v.audit_edited
                          ? moment(v.audit_edited).format("DD MMM YYYY hh:mm A")
                          : v.audit_created
                            ? moment(v.audit_created).format(
                              "DD MMM YYYY hh:mm A"
                            )
                            : ""}</span>
                      </td>
                      <td>
                        <ButtonIcon
                          className="text-hover-warning"
                          title="Update"
                          onClick={() => openModal({ message: <FormCompany ReloadData={ReloadData} data={v} /> })}
                        >
                          <KTIcon iconName="pencil" />
                        </ButtonIcon>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={9}>
                      <NoRecordFound />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        )}

        <div className="text-center">
          <ButtonTersier type="button" onClick={() => LoadMoreContent()} disabled={results.loading}>
            {results.loading ? <SpinnerLoad /> : "Load More"}
          </ButtonTersier>
        </div>
      </SGUCard.Body>
    </SGUCard>
  ) : <AlertNotif messages={AccessInvalid} />;
}
