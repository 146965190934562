import React, { useMemo, useState } from "react";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  SearchBarTable,
} from "../../../../_layouts/components/datatables/MYDatatables";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import {
  RowCourse,
  RowCumulative,
  RowLecturer,
  RowStudent,
} from "./components/AttdUI";
import { KTIcon } from "../../../../_metronic/helpers";
import { LinkBtn } from "../../../../_layouts/components/buttons/LinkUI";

export default function TableData({ results, ReloadData }) {
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const headers = [
    { name: "Student", field: "student_name", sortable: true },
    { name: "Course", field: "course_name", sortable: true },
    { name: "Lecturer", field: "section_lec", sortable: false },
    { name: "Attendance", field: "t_absent", sortable: true },
    { name: "Detail", field: "attd_id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (results.data) {
      computedData = results.data.map((c) => {
        var obj = {};
        obj.atp_id = c.atp_term ? c.atp_term.id : 0;
        obj.atp_name = c.atp_term ? c.atp_term.name : "-";
        obj.course_name = c.course ? c.course.coursetitle : "-";
        obj.course_code = c.course ? c.course.code : "-";
        obj.course_credit = c.course ? c.course.credit : 0;
        obj.course_id = c.course ? c.course.id : 0;
        obj.course_lesson = c.course_lesson ? c.course_lesson : 0;
        obj.attd_id = c.id;
        obj.section_lec = c.section_lec ? c.section_lec : "-";
        obj.student_id = c.studentid;
        obj.student_name = c.studentdata ? c.studentdata.fullname : "-";
        obj.student_semester = c.semester;
        obj.student_status = c.status;
        obj.t_absent = c.t_absent;
        obj.t_session = c.t_session;
        obj.atd = c.atd ? c.atd + "%" : "";
        return obj;
      });
      computedData.sort((a, b) => (a.course_name > b.course_name ? 1 : -1));
    }

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key]
            .toString()
            .toLowerCase()
            .includes(search)
        );
      });
    }

    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedData;
  }, [results.data, search, sorting]);
  return (
    <div>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex">
          <ButtonItemRefresh
            totalItems={totalItems}
            onClick={(e) => ReloadData(e)}
          />
        </div>
      </div>

      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {results.loading ? (
              <tr>
                <td colSpan={5}>
                  <LoadingSkeleton />
                </td>
              </tr>
            ) : results.message ? (
              <tr>
                <td colSpan={5}>
                  <AlertNotif messages={results.message} />
                </td>
              </tr>
            ) : ResultData.length > 0 ? (
              ResultData.map((v, index) => (
                <tr key={index}>
                  <td>
                    <RowStudent data={v} />
                  </td>
                  <td>
                    <RowCourse data={v} />
                  </td>
                  <td>
                    <RowLecturer data={v.section_lec} />
                  </td>
                  <td width={"10%"}>
                    <RowCumulative data={v} />
                  </td>
                  <td>
                    <LinkBtn
                      to={"detail/" + btoa(JSON.stringify(v))}
                      target="_blank"
                      className="btn btn-icon btn-sm btn-light"
                      title="View Attendance"
                    >
                      <KTIcon iconName="arrow-right" className="fs-5" />
                    </LinkBtn>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>
                  <NoRecordFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
