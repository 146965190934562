import { OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import { renderTooltip } from "../popovers/PopOvers";

const LinkBtn = (props) => {
  const { children, title, to, ...others } = props;
  if (title) {
    return (
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement="top"
        overlay={renderTooltip({ title: title })}
      >
        <Link to={to} {...others}>
          {children}
        </Link>
      </OverlayTrigger>
    );
  } else {
    return (
      <Link to={to} {...others}>
        {children}
      </Link>
    );
  }
};

export { LinkBtn };
