import React, { useEffect, useState } from "react";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import {
  GET_MEMBER_SYS,
  GET_USER_ESTES,
} from "../../apis/module/BackendRequest";
import { KTIcon } from "../../../../_metronic/helpers";
import { LoadingSkeleton } from "../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import moment from "moment";

import { Scatter, Line } from "react-chartjs-2";
import Chart from "chart.js/auto";

export default function WActivityLogin() {
  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [logToday, setLogToday] = useState([]);
  const ReloadData = async () => {
    setLogToday([]);
    setResults({ loading: true, data: [], message: "" });

    const param = { env: "sgu-ums" };
    const result_user = await GET_USER_ESTES(param);
    if (result_user.data && Object.values(result_user.data).length > 0) {
      const result = await GET_MEMBER_SYS();
      if (result.data && Object.values(result.data).length > 0) {
        const user_list = result.data.map((v) => {
          const find_user = result_user.data.find(
            (item) => item.user_id === v.email
          );
          v.last_login = find_user && (find_user.last_login || "");
          v.app_id = find_user && (find_user.app_id || "");
          return v;
        });
        setResults({ loading: false, message: "", data: user_list });

        const todayLog = user_list.filter(
          (item) =>
            item.last_login &&
            moment(item.last_login).format("YYYY-MM-DD") ===
              moment().format("YYYY-MM-DD")
        ).sort((a, b) => new Date(a.last_login) - new Date(b.last_login));
        setLogToday(todayLog);
      } else {
        setResults(result);
      }
    } else {
      setResults({ loading: false, data: [], message: "Failed retrive data" });
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bolder text-dark">Grafict Chart Line </span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        <div className="d-flex align-items-center">
            <KTIcon iconName="calendar" className="fs-1" />
            <span className="fs-4">{moment().format("DD MMM YYYY")}</span>
        </div>
      </SGUCard.Toolbar>
      <SGUCard.Body>
        {results.loading ? (
          <LoadingSkeleton />
        ) : results.message ? (
          <AlertRetry messages={results.message} Reload={ReloadData} />
        ) : (
          Object.values(logToday).length > 0 && <LineChart data={logToday} />
        )}
      </SGUCard.Body>
    </SGUCard>
  );
}

const ScaterrPlot = ({ data }) => {
  const uniqueEmails = [...new Set(data.map((item) => item.email))];

  const processData = (data) => {
    const scatterData = data.map((item) => ({
      x: new Date(item.last_login).getTime(),
      y: uniqueEmails.indexOf(item.email),
    }));

    return {
      datasets: [
        {
          label: "Login Times",
          data: scatterData,
          backgroundColor: "rgba(75, 192, 192, 1)",
        },
      ],
    };
  };

  const timeTooltipFormat = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleString(); // Formatkan sesuai keperluan
  };

  const chartData = processData(data);

  return (
    <Scatter
      data={chartData}
      options={{
        scales: {
          x: {
            type: "linear",
            position: "bottom",
            title: {
              display: true,
              text: "Login Time",
            },
            ticks: {
              callback: (value) => timeTooltipFormat(value),
            },
          },
          y: {
            title: {
              display: true,
              text: "Email (Index)",
            },
            ticks: {
              callback: (value) => uniqueEmails[value],
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const email = uniqueEmails[tooltipItem.raw.y];
                const loginTime = timeTooltipFormat(tooltipItem.raw.x);
                return `${email}: ${loginTime}`;
              },
            },
          },
        },
      }}
    />
  );
};

const LineChart = ({ data }) => {
  const uniqueEmails = [...new Set(data.map((item) => item.employeeid))];
  const processData = (data) => {
    const lineData = data.map((item) => ({
      x: new Date(item.last_login).getTime(),
      y: uniqueEmails.indexOf(item.employeeid),
      email: item.email,
      employeeid: item.employeeid 
    }));

    return {
      datasets: [
        {
          label: "User Login Times",
          data: lineData,
          fill: false, 
          borderColor: "rgba(75, 192, 192, 1)", 
          backgroundColor: "rgba(75, 192, 192, 0.2)", 
          tension: 0.4, 
          pointStyle: "rectRounded", 
          pointRadius: 6, 
          pointHoverRadius: 8, 
          pointBackgroundColor: "rgba(255, 99, 132, 0.8)",
          pointBorderColor: "rgba(54, 162, 235, 1)", 
          pointBorderWidth: 2, 
        },
      ],
    };
  };

  const timeTooltipFormat = (timestamp) => {
    const date = new Date(timestamp);
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return date.toLocaleString('en-US', options);
  };

  const chartData = processData(data);

  return (
    <Line
      data={chartData}
      options={{
        
        scales: {
          x: {
            type: "linear", 
            position: "bottom",
            title: {
              display: true,
              text: "Login Time",
            },
            ticks: {
              callback: (value) => timeTooltipFormat(value),
            },
          },
          y: {
            title: {
              display: true,
              text: "NIP",
            },
            ticks: {
              callback: (value) => uniqueEmails[value],
            },
          },
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                const email = tooltipItem.raw.email;
                const loginTime = timeTooltipFormat(tooltipItem.raw.x);
                return `${email}: ${loginTime}`;
              },
            },
          },
        },
      }}
    />
  );
};
