const RenderStudentRep = ({ studentRep }) => {
  return (
    <div className="table-responsive" style={{ maxHeight: "350px" }}>
      <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
        <tbody>
          {studentRep.map((v) => (
            <>
              <tr className="fs-7 fw-bold text-gray-500 border-bottom-0 bg-light-primary text-center">
                <th colSpan={2} className="fw-bold">
                  Semester {v.semes}
                </th>
              </tr>
              <tr className="fs-7 fw-bold text-gray-500 border-bottom-0">
                <th>Department</th>
                <th>Studentid</th>
              </tr>
              {v.dept.map((d) => (
                <tr>
                  <td>{d.code}</td>
                  <td>{d.stdid}</td>
                </tr>
              ))}
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};


export {RenderStudentRep}