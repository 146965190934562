import { SGUCard } from '../../../../../_layouts/components/cards/CardUI'
import { KTIcon } from '../../../../../_metronic/helpers'
import { useOutletContext } from 'react-router-dom'
import { LinkBtn } from '../../../../../_layouts/components/buttons/LinkUI'
import TableData from './TableData'

export function TransferCreditProposal() {
  const context = useOutletContext();
  const widget_108 = context.widgets.find(
    (item) => item.widget_id === 108 && item.is_enabled === 1
  );

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bold text-gray-800">
          Transfer Credit Proposal
        </span>
      </SGUCard.Header>
      {widget_108 && (
        <SGUCard.Toolbar>
          <LinkBtn to={"form"} className="btn btn-primary btn-sm fw-bold">
            <KTIcon iconName='plus' className='fs-2' />
            Add New
          </LinkBtn>
        </SGUCard.Toolbar>
      )}
      <SGUCard.Body>
        <TableData />
      </SGUCard.Body>
    </SGUCard>
  )
}