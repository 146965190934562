import React from "react";
import { Line } from "./charts/Line";
import { Donat } from "./charts/Donat";
import { FeeData } from "./charts/FeeData";
import { MajorChart } from "./charts/MajorChart";
import { Bar } from "./charts/Bar";

export function ADMDashboard() {
  
  return (
    <div>
      <div className="row g-5 g-xl-8">
        <div className="col-lg-6">
          <Line className="card-xl-stretch mb-xl-8" />
        </div>
        <div className="col-lg-3">
          <Donat className="card-xl-stretch mb-xl-8" />
        </div>
        <div className="col-lg-3">
          <div className="row g-0">
            <FeeData
              className="card-xl-stretch mb-8"
              chartColor="primary"
              chartHeight="150px"
              title="Registration Fee"
            />
            <FeeData
              className="card-xl-stretch mb-8"
              chartColor="primary"
              chartHeight="150px"
              title="Tuition Fee"
            />
          </div>
        </div>
      </div>

      <div className="row g-5 g-xl-8">
        <div className="col-lg-4">
          <MajorChart className="card-xl-stretch mb-xl-8" />
        </div>
        <div className="col-lg-8">
          <Bar className="card-xl-stretch mb-xl-8" />
        </div>
      </div>

      <div className="row g-5 g-xl-8">
        <div className="col-12"></div>
      </div>
    </div>
  );
}
