import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { SGUCardFlush } from "../../../../../_layouts/components/cards/CardUI";
import { YearIntakeEnroll } from "../../../components/forms/SelectBox";
import { GET_ENROLLMENT_PROCESS, GET_YEARINTAKE } from "../../../apis/node-target-data/MarketingAPI";
import { LoadingBars, LoadingSkeleton, NoRecordFound } from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../../_layouts/components/alerts/AlertsUI";
import { GroupByKey } from "../../../../../_layouts/_helpers/Global";

export default function IntakeProspectiveStd() {
  const [yearintakes, setYearIntakes] = useState({ loading: false, message: "", data: [] });
  const [yearintake, setYearintake] = useState(0);

  const ReloadActiveYear = async () =>{
    setYearIntakes({loading:true, message:"", data:[]});
    const result = await GET_YEARINTAKE();
    if(result.data && Object.values(result.data).length > 0){
      const findActive = result.data.find(item => item.isactive === 1);
      setYearintake(findActive.id);
    }
    setYearIntakes(result);
  }

  useEffect(()=>{
    ReloadActiveYear();
  },[]);

  const [prospectiveStd, setProspectiveStd] = useState({ loading: false, message: "", data: [] });
  const RELOAD_GET_ENROLLMENT_PROCESS = async ()=>{
    const param ={yearintake, status:encodeURIComponent("ON PROCESS"), offset:1, limit:1000};
    const result = await GET_ENROLLMENT_PROCESS(param);
    if(result.data && Object.values(result.data).length > 0){
      setProspectiveStd({ loading: false, message: "", data: result.data });
    }else{
      setProspectiveStd(result);
    }
  }

  useEffect(()=>{
    if(yearintake !== 0){
      RELOAD_GET_ENROLLMENT_PROCESS();
    }
  },[yearintake]);

  const useDataDept = (data = {}) => {
      let cloneData = [];
      if (Object.values(prospectiveStd.data).length > 0) {
        prospectiveStd.data.sort((a, b) => (a.target_department.name > b.target_department.name ? 1 : -1));
          cloneData = prospectiveStd.data.map((v) => {
              var obj = {};
              obj.target_department_id = v.target_department ? v.target_department.id : 0;
              obj.target_department_name = v.target_department ? v.target_department.name : "";
              obj.status = v.status;
              obj.candidate_id = v.candidate_id;
              obj.program_id = v.program_id;
              obj.yearintake = v.yearintake;
              obj.prospect_name = v.regstudent ? v.regstudent.fullname : "";
              obj.prospect_email = v.regstudent ? v.regstudent.email : "";
              return obj;
          });
      }

      const arrDept = [], labelLine = [], colorChart = [];
      if (Object.values(cloneData).length > 0) {
          const arrGroupCLU = GroupByKey(cloneData, "target_department_name");
          Object.keys(arrGroupCLU).map((v) => {
              labelLine.push(v);
              colorChart.push(getRandomColor());
              arrDept.push(Object.values(arrGroupCLU[v]).length);
          });
          data = {
              labels: labelLine,
              datasets: [
                  {
                      label: "Student by Program Study",
                      data: arrDept,
                      backgroundColor: colorChart,
                  },
              ],
          };
      }

      return [data];
  };

  const getRandomColor = () => {
      var letters = '0123456789ABCDEF';
      var color = '#';
      for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
  }

  const [dataDeptChart, setDataDeptChart] = useDataDept();


  return (
    <SGUCardFlush>
      <SGUCardFlush.Header>
        <span className="card-label fw-bold text-gray-800">Prospective students</span>
        <span className="text-gray-500 mt-1 fw-semibold fs-6">
          Percentage of prospective students with <span className="text-primary text-capitalize">completed</span> status
        </span>
      </SGUCardFlush.Header>
      <SGUCardFlush.Body>
        {yearintakes.loading ? <LoadingSkeleton /> :
        yearintakes.message? <AlertRetry Reload={ReloadActiveYear} messages={yearintakes.message}  /> :
        Object.values(yearintakes.data).length > 0 && (
          <>
            <YearIntakeEnroll data={yearintake} setData={setYearintake} required />
            {prospectiveStd.loading ? <LoadingBars /> :
            prospectiveStd.message? <AlertRetry Reload={RELOAD_GET_ENROLLMENT_PROCESS} messages={prospectiveStd.message}  /> :
            Object.values(prospectiveStd.data).length > 0 ? (
              <div className="text-center">
                <Pie data={dataDeptChart ? dataDeptChart : null} />
                <p className="mt-5 fw-bolder">Total of Complete {Object.values(prospectiveStd.data).length} Prospective Student</p>
              </div>
            ): <NoRecordFound />}
          </>
        )}
        
      </SGUCardFlush.Body>
    </SGUCardFlush>
  );
}
