import { useMemo, useState } from "react";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  SearchBarTable,
} from "../../../../../_layouts/components/datatables/MYDatatables";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import {
  AlertFailed,
  AlertNotif,
} from "../../../../../_layouts/components/alerts/AlertsUI";
import { KTIcon } from "../../../../../_metronic/helpers";
import { ProgramDegree, YearIntake } from "../../Helper";
import { OverlayTrigger, Popover } from "react-bootstrap";
import {
  CREATE_MKT_FIN_TRANS,
  FLAG_MKT_FIN_TRANS,
  SUBMIT_MKT_FIN_TRANS_OFFLINE,
} from "../../../apis/node-target-data/MarketingAPI";
import { RegisterStatus } from "../../components/Helper";
import moment from "moment";
import { BadgeItem, ButtonAccept } from "../../components/EnrollmentComp";
import { AcadList } from "../../components/Dropdown";
import { useNavigate } from "react-router-dom";

export default function TableData({ results, ReloadData, widget }) {
  const navigation = useNavigate();
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [choosenAcad, setChoosenAcad] = useState({});
  const [isPopupActive, setIsPopupActive] = useState(false);
  const [accept, setAccept] = useState([
    {
      status: true,
    },
    {
      status: false,
    },
  ]);

  const [controlStateLoading, setControlStateLoading] = useState([]);

  const [loadingUpdateRegfee, setLoadingUpdateRegfee] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const [loadingUpdateTuitionfee, setLoadingUpdateTuitionfee] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const headers = [
    { name: "Name", field: "name", sortable: false },
    { name: "Reg Id", field: "reg_id", sortable: false },
    { name: "Department", field: "department", sortable: false },
    { name: "Enroll Type", field: "enrollment_type", sortable: false },
    { name: "Intake", field: "intake", sortable: false },
    { name: "Reg Status", field: "reg_status", sortable: false },
    { name: "Academic Calendar", field: "acad_cal", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (results.data) {
      computedData = results.data.map((c) => {
        var obj = {};
        obj.name = c.regstudent.fullname;
        obj.reg_id = c.candidate_id;
        obj.registrationdate = c.registrationdate;
        obj.reg_date = c.registrationdate
          ? moment(c.registrationdate).format("DD MMM YYYY hh:mm A")
          : "";
        obj.enrollment_type = c.enrollment_type?.name ?? "-";
        obj.major = c.target_department.name;
        obj.yearintake = c.yearintake;
        obj.degree = c.target_department.program_id;
        obj.reg_fee_status =
          c.fin_tr.length > 0
            ? c.fin_tr.find(
                (x) => x.trans_type.includes("regfee") && x.is_active === 1
              )?.status ?? "EMPTY"
            : "EMPTY";

        obj.trans_id_reg_fee =
          c.fin_tr.length > 0 &&
          c.fin_tr.find(
            (x) => x.trans_type.includes("regfee") && x.is_active === 1
          )?.trans_id;

        obj.tuition_status =
          c.fin_tr.length > 0
            ? c.fin_tr.find(
                (x) => x.trans_type.includes("tuitionfee") && x.is_active === 1
              )?.status ?? "EMPTY"
            : "EMPTY";

        obj.trans_id_tuition_fee =
          c.fin_tr.length > 0 &&
          c.fin_tr.find(
            (x) => x.trans_type.includes("tuitionfee") && x.is_active === 1
          )?.trans_id;

        obj.reg_status = c.status;
        obj.fin_tr = c.fin_tr;
        obj.complete = c;
        obj.enroll_id = c.id;
        return obj;
      });

      computedData.sort((a, b) =>
        b.registrationdate > a.registrationdate ? 1 : -1
      );
    }

    // computedData = computedData.filter((prev) => {
    //     if (prev.section_id) {
    //         return prev;
    //     }
    // });

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) => {
          const value = listData[key];
          return (
            value &&
            value
              .toString()
              .toLowerCase()
              .includes(search)
          );
        });
      });
    }

    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    // set loading state
    let stateArray = computedData.map((c, i) => {
      var obj = {};
      obj.id = i;
      obj.isLoadingFee = false;
      obj.isLoadingTuition = false;
      return obj;
    });

    setControlStateLoading(stateArray);
    console.log("computedData ", computedData);
    console.log("real data ", results.data);
    return computedData;
  }, [results.data, search, sorting]);

  const PopUp = (reg_id) => {
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        onToggle={(e) => setIsPopupActive(e)}
        placement="left"
        overlay={
          <Popover className="p-3">
            <Popover.Body className="p-0">
              <>
                <div
                  disabled
                  className="cursor-pointer bg-hover-light-primary text-hover-primary ps-3 pe-5 py-3 rounded d-flex align-items-center"
                  // onClick={() => update(key, trans_id, status, index)}
                >
                  <KTIcon iconName="send" className="fs-3 me-2" />
                  <span>Send Acceptance</span>
                </div>
                {/* <div
                  className="cursor-pointer bg-hover-light-primary text-hover-primary ps-3 pe-5 py-3 rounded d-flex align-items-center"
                  onClick={() => navigation("/admissions/enrollment/detail/" + btoa(JSON.stringify(reg_id)))}
                >
                  <KTIcon iconName="cheque" className="fs-3 me-2" />
                  <span>Detail</span>
                </div> */}
                <div class="separator my-5"></div>
                <div
                  className="cursor-pointer bg-hover-light-danger text-hover-danger ps-3 pe-5 py-3 rounded d-flex align-items-center"
                  // onClick={() => SendInvoice("regfee", index, enroll_id)}
                >
                  <KTIcon iconName="cross-circle" className="fs-3 me-2" />
                  <span>Cancel</span>
                </div>
              </>
            </Popover.Body>
          </Popover>
        }
      >
        <div className="cursor-pointer" style={{ display: "inline-flex" }}>
          <KTIcon iconName="burger-menu-5" className="fs-3 me-2" />
        </div>
      </OverlayTrigger>
    );
  };

  return (
    <div>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex">
          <ButtonItemRefresh
            totalItems={totalItems}
            onClick={(e) => ReloadData(e)}
          />
        </div>
      </div>
      {(loadingUpdateRegfee.message !== "" ||
        loadingUpdateTuitionfee.message !== "") && (
        <AlertFailed
          title="Something goes wrong!"
          Reload={() => ReloadData()}
          messages={
            loadingUpdateRegfee.message !== ""
              ? loadingUpdateRegfee.message
              : loadingUpdateTuitionfee.message
          }
        />
      )}
      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {results.loading ? (
              <tr>
                <td colSpan={9}>
                  <LoadingSkeleton />
                </td>
              </tr>
            ) : results.message ? (
              <tr>
                <td colSpan={9}>
                  <AlertNotif messages={results.message} color="danger" />
                </td>
              </tr>
            ) : ResultData.length > 0 ? (
              ResultData.map((v, index) => (
                <tr key={index}>
                  <td className="fs-7 fw-bold">{v.name}</td>
                  <td className="fs-7">
                    <span className="d-block fw-bold">{v.reg_id}</span>
                    <BadgeItem
                      icon={"calendar"}
                      name={v.reg_date}
                      title={"Reg Date"}
                    />
                  </td>
                  <td className="fs-7">
                    <span className="d-block fw-bold">{v.major}</span>
                    <BadgeItem
                      icon={"teacher"}
                      name={ProgramDegree(v.degree)}
                      title={"Program Degree"}
                    />
                  </td>
                  <td className="fs-7 fw-bold">{v.enrollment_type}</td>
                  <td className="fs-7 fw-bold">{YearIntake(v.yearintake)}</td>
                  <td>
                    <RegisterStatus data={v.reg_status} />
                  </td>
                  <td className="w-25">
                    {accept[index].status ? (
                      <div className="d-flex">
                        <AcadList data={choosenAcad} setData={setChoosenAcad} />
                        <div>
                          <ButtonAccept
                            type="button"
                            disabled={isPopupActive}
                            className="btn-sm ms-3"
                          >
                            Accept
                          </ButtonAccept>
                        </div>
                      </div>
                    ) : (
                      <span>2024-2025 Odd Term Bachelor</span>
                    )}
                  </td>
                  <td className="text-center">
                    {PopUp(v.reg_id)}
                    {/* <div className="btn-group">
                      <LinkBtn
                        to={"detail/" + btoa(JSON.stringify(v.reg_id))}
                        target="_self"
                        className="btn btn-icon btn-sm btn-light"
                        title="Detail Candidate"
                      >
                        <KTIcon iconName="arrow-right" className="fs-5" />
                      </LinkBtn>
                    </div> */}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={11}>
                  <NoRecordFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
