const template1 = () => {
  return `<p style="text-align: center; line-height: 1;">&nbsp;ACCEPTANCE LETTER</p>
    <p style="text-align: center; line-height: 1;">Year Intake {{yearintake_name}}</p>
    <p style="text-align: center;">&nbsp;</p>
    <p>Tangerang, {{date_now}}<br>Ref: 0251/ADM/Nov/14/SYP</p>
    <p><strong>Dear, {{name}}</strong></p>
    <p><strong>Congratulations!</strong></p>
    <p>After carefully reviewing your applications and result of the Entrance Test, your thoughtful application and<br>remarkable accomplishment convinced us that you have the intellectual energy, imagination, and talent to<br>flourish at SGU.</p>
    <p>Therefore, I am pleased to inform you that you have been accepted as a bachelor&rsquo;s degree student in<br>Mechatronics Engineering Academic Year 2025/2026 with the following result of Test:</p>
    <p style="text-align: center;"><strong>Points: {{points}}</strong></p>
    <p>This offer will be confirmed once the tuition fee is paid according to the designated time and all the required<br>documents have been submitted. Please note that all documents should be submitted by 30 June 2025 at the<br>latest. I have enclosed the Study fee for your information.<br>Once again, I extend my congratulations on your admission to Swiss German University and welcome you to<br>the SGU Family!</p>`;
};

export { template1 };
