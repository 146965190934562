import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { getCSSVariableValue } from "../../../../../_metronic/assets/ts/_utils";

const DumbbellChart = ({ className }) => {
  const chartRef = useRef(null);

  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(chartRef.current, getChartOptions());
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshMode();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef]);

  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Coupon</span>
          <span className="text-muted fw-semibold fs-7">
            More than 1000 new records
          </span>
        </h3>
      </div>
      <div className="card-body">
        <div
          ref={chartRef}
          id="kt_charts_widget_3_chart"
          style={{ height: "350px" }}
        ></div>
      </div>
    </div>
  );
};

export { DumbbellChart };

function getChartOptions() {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");

  return {
    series: [
      {
        data: [
          {
            x: "2019",
            y: [28, 45],
          },
          {
            x: "2020",
            y: [32, 41],
          },
          {
            x: "2021",
            y: [29, 78],
          },
          {
            x: "2022",
            y: [30, 46],
          },
          {
            x: "2023",
            y: [35, 41],
          },
          {
            x: "2024",
            y: [45, 65],
          },
          {
            x: "2025",
            y: [41, 56],
          },
        ],
      },
    ],
    chart: {
      height: 350,
      type: "rangeBar",
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,  // Disable the toolbar, including the download menu
      },
    },
    plotOptions: {
      bar: {
        isDumbbell: true,
        columnWidth: 3,
        dumbbellColors: [["#008FFB", "#00E396"]],
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: "top",
      horizontalAlign: "left",
      customLegendItems: ["Coupon Created", "Coupon Used"],
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        gradientToColors: ["#00E396"],
        inverseColors: true,
        stops: [0, 100],
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: false,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    xaxis: {
      tickPlacement: "on",
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
  };
}
