import React from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import TableAttd from "./components/TableAttd";
import { CourseInfo } from "../../course-managements/master-courses/components/CourseUI";
import StdInfo from "./components/StdInfo";

export function DetailAttdStd() {
  const context = useOutletContext();
  const widget_32 = context.widgets.find(
    (item) => item.widget_id === 32 && item.is_enabled === 1
  );
  const params = useParams();
  const token = params.token;
  try {
    const decode = atob(token);
    return widget_32 ? (
      <DetailPage decode={decode} />
    ) : (
      <AlertNotif messages={AccessInvalid} />
    );
  } catch (error) {
    return <Error404 />;
  }
}

const DetailPage = ({ decode }) => {
  const data = JSON.parse(decode);
  const student_id = data.student_id;
  const course_id = data.course_id;
  const atp_id = data.atp_id;

  return (
    <div id="detail-atd" className="row">
      <div className="col-lg-3">
        <div className="mb-5">
          <StdInfo data={data} />
        </div>
        <div className="mb-5">
          <CourseInfo course_id={course_id} />
        </div>
      </div>
      <div className="col-lg-9">
        <TableAttd atp_id={atp_id} student_id={student_id} course_id={course_id} />
      </div>
    </div>
  );
};
