import React, { useEffect, useState } from 'react'
import PackageJSON from "../../../../../package.json";
import { useOutletContext } from 'react-router-dom';
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';
import { AccessInvalid, RequiredMsg } from '../../../../_layouts/_core/ErrorMessage';
import { MessageError, openModal } from '../../../../_layouts/components/modals/Modals';
import FilterUI from '../../components/filters/FilterUI';
import { AcadTerm, Department, EmployeeSelect } from '../../components/forms/SelectBox';
import { LoadingSkeleton } from '../../../../_layouts/components/loadbars/LoadingBars';
import { GET_ACAD_TERM, GET_THS } from '../../apis/node-target-data/AcademicApi';
import { StudentID, StudentName } from '../../components/forms/InputText';
import { SGUCard } from '../../../../_layouts/components/cards/CardUI';
import TableData from './TableData';
import { RemoveEmptyValue } from '../../../../_layouts/_helpers/Global';
import { ButtonDownload } from '../../../../_layouts/components/buttons/ButtonUI';
import { DownloadThesisSchedule } from './DownloadThesisSchedule';

export function ThesisDefenseSchedule() {
  const context = useOutletContext();
  const widget_75 = context.widgets.find(
    (item) => item.widget_id === 75 && item.is_enabled === 1
  );

  const title = "Thesis Defense";

  const [term_id, setTerm_id] = useState(PackageJSON.system_param.ActiveAcad);
  const [clu_id, setClu_id] = useState(0);
  const [studentid, setStudentId] = useState("");
  const [studentname, setStudentname] = useState("");
  const [advisor, setAdvisor] = useState(0);
  const [coadvisor, setCoadvisor] = useState(0);

  const postParam = {
    term_id, clu_id, studentid, studentname, advisor
  }

  const [termSelected, setTermSelected] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const ReloadTerm = async () => {
    const param = { atpid: postParam.term_id };
    const result = await GET_ACAD_TERM(param);
    setTermSelected(result);
  };

  useEffect(()=>{
    ReloadTerm();
  },[term_id])

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadData = async (postParam) => {
    setResults({ loading: true, data: [], message: "" });

    const param = RemoveEmptyValue(postParam);
    const result = await GET_THS(param);
    setResults(result);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const param = RemoveEmptyValue(postParam);
    ReloadData(param);
  }, [])

  const SubmitFilter = (e) => {
    e.preventDefault();
    if (postParam.term_id) {
      const param = RemoveEmptyValue(postParam);
      ReloadData(param);
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  };

  const [isDownload, setDownload] = useState(false);
  const HandlerDownload = () =>{
    const param = RemoveEmptyValue(postParam);
    const termname = termSelected.data.name && (termSelected.data.name || "-");
    DownloadThesisSchedule(param, termname, setDownload);
  }

  return widget_75 ? (
    <div className="row">
      <div className="col-lg-3 mb-5">
        <FilterUI title="Timetable" onSubmit={SubmitFilter} isload={results.loading}>
          <AcadTerm data={term_id} setData={setTerm_id} required />
          <Department data={clu_id} setData={setClu_id} />
          <StudentID data={studentid} setData={setStudentId} />
          <StudentName data={studentname} setData={setStudentname} />
          <EmployeeSelect data={advisor} setData={setAdvisor} title={"Advisor"} />
          <EmployeeSelect data={coadvisor} setData={setCoadvisor} title={"Co-advisor"} />
        </FilterUI>
      </div>

      <div className="col-lg-9 mb-5">
        <SGUCard>
          <SGUCard.Header>
            <span className="card-label fw-bolder text-dark">
              Browse {title}
            </span>
            <span className="text-muted mt-1 fw-bold fs-7">
              List of thesis defense schedule for students
            </span>
          </SGUCard.Header>
          <SGUCard.Toolbar>
            {Object.values(results.data).length > 0 && (
              <ButtonDownload type="button" className="btn-sm fw-bold" onClick={()=>HandlerDownload()} disabled={isDownload}>
                {isDownload ? "Processing...":"Export to excel"}
              </ButtonDownload>
            )}
          </SGUCard.Toolbar>
          <SGUCard.Body>
            {results.loading ? (
              <LoadingSkeleton />
            ) : results.message ? (
              <AlertNotif messages={results.message} />
            ) : (
              <TableData results={results} />
            )}
          </SGUCard.Body>
        </SGUCard>
      </div>
    </div>
  ) : <AlertNotif messages={AccessInvalid} />
}
