import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { NoRecordFound } from '../../../../_layouts/components/loadbars/LoadingBars'
import { SearchBarTable } from '../../../../_layouts/components/datatables/MYDatatables';

export default function TableData({ results }) {
    const [search, setSearch] = useState("");

    const ResultData = useMemo(() => {
        let computedData = [];
        if (Object.values(results.data).length > 0) {
            computedData = results.data;
        }
        computedData.sort((a, b) => (a.section_id > b.section_id ? 1 : -1));

        if (search) {
            computedData = computedData.filter((listData) => {
                return Object.keys(listData).some(
                    (key) =>
                        listData[key]
                            .toString()
                            .toLowerCase()
                            .includes(search)
                    //console.log(key,listData[key])
                );
            });
        }

        return computedData
    }, [results.data, search]);

    return (
        <div>
            <div className="w-50 px-8 mb-5">
                <SearchBarTable
                    size="w-250px"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <div className="list-room">
                {ResultData.length > 0 ? (
                    <div className="table-responsive">
                        <table className="table table-row-dashed table-striped align-middle gs-0 gy-3 my-0 mb-8" id="table-to-xls">
                            <thead>
                                <tr className="fw-bolder text-muted text-uppercase">
                                    <th className="text-center">No</th>
                                    <th>Subject 1</th>
                                    <th></th>
                                    <th>Subject 2</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ResultData.map((v, index) => (

                                    <tr>
                                        <td className="text-center">{index + 1}</td>
                                        <td> <FetchRoomItem data={v} /> </td>
                                        <td></td>
                                        <td> <FetchRoomItem2 data={v} /></td>
                                    </tr>

                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : <NoRecordFound />}
            </div>
        </div>
    )
}


const FetchRoomItem = ({ data }) => {
    return (
        <div className="room-item">
            <div className="fs-5 fw-bolder text-danger">
                {moment(data.leftstart).format("MMM DD, YYYY")} {moment(data.leftstart).format("HH:mm")}-{moment(data.leftend).format("HH:mm")}
            </div>
            <div className="fs-5 fw-bolder text-danger">
                Room {data.leftroom}
            </div>
            <div className="fs-5 fw-bolder">
                {data.leftcourse}
            </div>
            <div className="fs-5">
                Credit {data.leftcredit}
            </div>
            <div className="fs-5">
                Section {data.leftsection}
            </div>
        </div>
    )
}

const FetchRoomItem2 = ({ data }) => {
    return (
        <div className="room-item">
            <div className="fs-5 fw-bolder text-danger">
                {moment(data.rightstart).format("MMM DD, YYYY")} {moment(data.rightstart).format("HH:mm")}-{moment(data.rightend).format("HH:mm")}
            </div>
            <div className="fs-5 fw-bolder text-danger">
                Room {data.rightroom}
            </div>
            <div className="fs-5 fw-bolder">
                {data.rightcourse}
            </div>
            <div className="fs-5">
                Credit {data.rightcredit}
            </div>
            <div className="fs-5">
                Section {data.rightsection}
            </div>
        </div>
    )
}