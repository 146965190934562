import React, { useState } from "react";
import GroupList from "./components/groups/GroupList";
import UserList from "./components/users/UserList";
import { ListWidget } from "../menu-widgets/components/ListWidget";

export function GroupUser() {
  const [activeGroup, setActiveGroup] = useState();

  return (
    <div className="row">
      <div className="col-auto col-xl-5">
        <GroupList activeGroup={activeGroup} setActiveGroup={setActiveGroup} />
      </div>
      {activeGroup && (
        <div className="col-auto col-xl-7">
          <div className="px-3">
            <ul className="nav nav-pills nav-pills-custom row position-relative mx-0 mb-0">
              <li className="nav-item col-4 mx-0 px-0">
                <a
                  className="nav-link text-gray-500 d-flex justify-content-center w-100 border-0 h-100 active"
                  data-bs-toggle="tab"
                  href="#card-widget"
                >
                  <span className="nav-text fw-bold fs-4 mb-3">
                    Access Widget
                  </span>
                  <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded"></span>
                </a>
              </li>
              <li className="nav-item col-4 mx-0 p-0">
                <a
                  className="nav-link text-gray-500 d-flex justify-content-center w-100 border-0 h-100"
                  data-bs-toggle="tab"
                  href="#card-users"
                >
                  <span className="nav-text fw-bold fs-4 mb-3">Membership</span>
                  <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded"></span>
                </a>
              </li>
              <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded"></span>
            </ul>
          </div>
          <div className="tab-content">
            <div className="tab-pane blockui fade show active" id="card-widget">
              <ListWidget activeGroup={activeGroup} />
            </div>
            <div className="tab-pane blockui fade" id="card-users">
              <UserList activeGroup={activeGroup} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
