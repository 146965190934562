import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { useOutletContext } from 'react-router-dom';
import { AccessInvalid, RequiredMsg } from '../../../../_layouts/_core/ErrorMessage';
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';
import { SGUCard } from '../../../../_layouts/components/cards/CardUI';
import { ButtonDownload } from '../../../../_layouts/components/buttons/ButtonUI';
import { CalendarNotes, CalendarPrevNext, ConsentEvent, htmlToImageConvert } from '../components/CalendarAtributes';
import Filter from './Filter';
import { GET_SCHD } from '../../apis/node-target-data/AcademicApi';
import { LoadingSkeleton } from '../../../../_layouts/components/loadbars/LoadingBars';
import { FullCalendarWeek } from '../components';
import { ConvertEventItem } from '../components/HelperCalendar';
import { MessageError, openModal } from '../../../../_layouts/components/modals/Modals';

export function RoomTimetable() {
  const context = useOutletContext();
  const widget_51 = context.widgets.find(
    (item) => item.widget_id === 51 && item.is_enabled === 1
  );
  const elementRef = useRef(null);
  const title = "Room Timetable";

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const monday = moment().startOf("weeks").format("YYYY-MM-DD");
  const sunday = moment().endOf("weeks").format("YYYY-MM-DD");

  const [postParam, setPostParam] = useState({ grouped: "True", start_dt: monday, end_dt: sunday, room_id:0 });

  useEffect(() => {
    if (postParam.room_id) {
      GetScheduleTimetable();
    }
  }, [postParam])

  const GetScheduleTimetable = async () => {
    setResults({ loading: true, message: "", data: [] });

    const result = await GET_SCHD(postParam);
    if (result.data && Object.values(result.data).length > 0) {
      const data_result = ConvertEventItem(result.data);
      setResults({ loading: false, message: "", data: data_result });
    } else {
      setResults({ loading: false, message: result.message, data: [] });
    }
  };

  const [downloadPic, setDownloadPic] = useState(false);

  const createNewClass = (data) => {
    if(postParam.room_id){
      const selectedDate = moment(data.dateStr).toDate();
      openModal({ message: <ConsentEvent selectedDate={selectedDate} ReloadData={GetScheduleTimetable} iscustom={true} roomSelected={{ value:postParam.room_id, label:"" }} room_id={postParam.room_id} /> })
    }else{
      openModal({message:<MessageError message={RequiredMsg} />})
    }
  }


  return widget_51 ? (
    <>
      <SGUCard>
        <SGUCard.Body className={"pt-5 pb-5"}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h3 className="mb-2">
                {title}
              </h3>
              <Filter onSubmit={GetScheduleTimetable} isload={results.loading} setPostParam={setPostParam} />
            </div>

            <ButtonDownload className="btn-sm" type="button" disabled={downloadPic} onClick={() => htmlToImageConvert(elementRef, title, setDownloadPic)}>
              {downloadPic ? "Processing..." : "Download Timetable"}
            </ButtonDownload>

          </div>
        </SGUCard.Body>
      </SGUCard>

      {results.loading ? <LoadingSkeleton /> :
        results.message ? <AlertNotif messages={results.message} /> : (
          <>
            <div className="d-flex justify-content-between align-items-center mb-5">
              <CalendarNotes />
              <CalendarPrevNext start_dt={postParam.start_dt} setPostParam={setPostParam} schd={true} />
            </div>
            <SGUCard>
              <SGUCard.Body className={"p-0"}>
                <div className="calendar-week" ref={elementRef}>
                  <FullCalendarWeek data={results.data} startDT={postParam.start_dt} endDT={postParam.end_dt} title={title} createNewClass={createNewClass} />
                </div>
              </SGUCard.Body>
            </SGUCard>
          </>
        )}
    </>
  ) : <AlertNotif messages={AccessInvalid} />;
}
