import { Link } from "react-router-dom"
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI"
import { OverlayTrigger } from "react-bootstrap"
import { renderTooltip } from "../../../../../_layouts/components/popovers/PopOvers"

const RenderSectionList = ({ data }) => {
    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bold text-gray-800">
                    Section <span className="text-primary">{data.section_id}</span>
                </span>
            </SGUCard.Header>
            <SGUCard.Toolbar>
                <Link className="btn btn-sm btn-primary fw-bold" to={data.section_id + "/detail"}>
                    View Detail <i className="bi bi-arrow-right-circle ms-5"></i>
                </Link>
            </SGUCard.Toolbar>
            <SGUCard.Body>
                <div className="d-flex align-items-center justify-content-between flex-wrap d-grid gap-2 mb-5">
                    <CardItem icon={"bookmark-2"} color="success" name={"Course Title"} value={data.course && (data.course.coursetitle || "-")} />
                    <CardItem icon={"bookmark-2"} color="success" name={"Course Code"} value={data.course && (data.course.code || "-")} />
                    <CardItem icon={"bookmark-2"} color="success" name={"Credit"} value={data.course && ((data.course.credit + " SKS") || "-")} />
                </div>
                <div className="d-flex align-items-center flex-wrap d-grid gap-2">
                    <CardItem icon={"calendar"} name={"Academic Calendar"} value={data.atp_term && (data.atp_term.name || "-")} />
                    <CardItem icon={"status"} name={"Max Session"} value={data.section_session || "-"} />
                    <CardItem icon={"parcel"} name={"Max Seat"} value={data.max_seat || "-"} />
                    <CardItem icon={"security-user"} name={"Course Owner"} value={data.cost_center && (data.cost_center.name || "-")} />
                </div>
                <div className="d-flex align-items-center justify-content-between flex-wrap d-grid gap-2">
                    <CardItemLect data={data.lecturer} />
                    <CardItemStudyProgram data={data.prereq} />
                </div>
            </SGUCard.Body>
        </SGUCard>
    )
}

const CardItem = ({ icon, name, value, color = "primary" }) => {
    return (
        <>
            <div className="d-flex align-items-center me-5 me-xl-13 mb-3">
                <div className="symbol symbol-30px symbol-circle me-3">
                    <span className={"symbol-label bg-light-" + color}>
                        <i className={"ki-outline ki-" + icon + " fs-3 m-0 text-" + color}></i>
                    </span>
                </div>
                <div className="m-0">
                    <span className="fw-semibold text-gray-500 d-block fs-8">{name}</span>
                    <span className="fw-bold text-gray-800 fs-7">{value}</span>
                </div>
            </div>
        </>
    )
}

const CardItemLect = ({ data }) => {
    const total = Object.values(data).length;
    const totalmax = 1;
    return (
        <div className="d-flex flex-column justify-content-end pe-0">
            <span className="fs-8 fw-bolder text-gray-800 d-block mb-2">Lecturer</span>
            <span className="symbol-group symbol-hover flex-nowrap">
                {total > 0 ? (
                    total > totalmax ? (
                        data.map((v, index) => (
                            <OverlayTrigger
                                delay={{ show: 250, hide: 400 }}
                                placement="top"
                                overlay={renderTooltip({ title: (v.emp && (v.emp.fullname || "-")) })}
                            >
                                <span className="symbol symbol-30px symbol-circle  border border-primary" key={index}>
                                    <span className="symbol-label bg-light text-primary fw-bold text-capitalize">
                                        {v.emp && (v.emp.email[0] || "-")}
                                    </span>
                                </span>
                            </OverlayTrigger>
                        ))
                    ) : (
                        data.map((v, index) => (
                            <div key={index}>
                                <span className="symbol symbol-30px symbol-circle  border border-primary" key={index}>
                                    <span className="symbol-label bg-light text-primary fw-bold text-capitalize">
                                        {v.emp && (v.emp.email[0] || "-")}
                                    </span>
                                </span>
                                <span className="ms-3 fw-bold fs-6">{(v.emp && (v.emp.fullname || "-"))}</span>
                            </div>
                        ))
                    )
                ) : "-"}
            </span>
        </div>
    )
}

const CardItemStudyProgram = ({ data }) => {
    const total = Object.values(data).length;
    const totalmax = 1;
    return (
        <div className="d-flex flex-column justify-content-end pe-0">
            <span className="fs-8 fw-bolder text-gray-800 d-block mb-2">Study Program</span>
            <span className="symbol-group symbol-hover flex-nowrap">
                {total > 0 ? (
                    total > totalmax ? (
                        data.map((v, index) => (
                            <OverlayTrigger
                                delay={{ show: 250, hide: 400 }}
                                placement="top"
                                overlay={renderTooltip({ title: (v.clu && ((v.clu.name || "") + ("-Semester " + (v.semester || "")))) })}
                            >
                                <span className="symbol symbol-30px symbol-circle border border-warning" key={index}>
                                    <span className="symbol-label bg-light text-warning fw-bold text-capitalize">
                                        {v.clu && (v.clu.code || "")}
                                    </span>
                                </span>
                            </OverlayTrigger>
                        ))
                    ) : (
                        data.map((v, index) => (
                            <div className="d-flex align-items-center me-5 me-xl-13 mb-3" key={index}>
                                <div className="symbol symbol-30px symbol-circle border border-warning me-2">
                                    <span className="symbol-label bg-light text-warning fw-bold text-capitalize">
                                        {v.clu && (v.clu.code || "")}
                                    </span>
                                </div>
                                <div className="m-0">
                                    <span className="fw-semibold text-gray-800 d-block fs-7">{v.clu && (v.clu.name || "")}</span>
                                    <span className="fw-bold text-gray-500 fs-8">Semester {v.semester || ""}</span>
                                </div>
                            </div>
                        ))
                    )
                ) : "-"}
            </span>
        </div>
    )
}

const HeaderSection = ({ data }) => {
    return (
        <div className="info-section d-flex justify-content-between align-items-center mb-8">
            <div className="section-name">
                <div className="d-flex flex-column">
                    <span className="text-dark fw-bold fs-3 mb-1">{data.section_id}</span>
                    <span className="text-muted fw-bold">Section ID</span>
                </div>
            </div>

            <div className="term-name">
                <div className="d-flex flex-column">
                    <span className="text-dark fw-bold fs-3 mb-1">{(data.atp_term) ? data.atp_term.name : "-"}</span>
                    <span className="text-muted fw-bold">Academic Calendar</span>
                </div>
            </div>

            <div className="session-name">
                <div className="d-flex flex-column">
                    <span className="text-dark fw-bold fs-3 mb-1">{(data.course) ? data.course.coursetitle : "-"}</span>
                    <span className="text-muted fw-bold">
                        {(data.course) ? data.course.code : "-"}
                        <span className="ms-1">({(data.course) ? data.course.credit : "-"} SKS)</span>
                    </span> 
                </div>
            </div>
        </div>
    )
}
const SectionItemLabel = ({ name, value }) => {
    return (
        <div className="mb-5">
            <p className="mb-0 text-muted">{name}</p>
            <p className='fw-bolder'>{value}</p>
        </div>
    )
}

const LectProgress = ({ data }) => {
    const percentageApproved = (data.emp_approved / data.emp_session) * 100;
    const percentageSubmitted = (data.emp_submitted / data.emp_session) * 100;
    const remainingQuota = data.emp_session - Math.max(data.emp_approved, data.emp_submitted);
    const percentageRemaining = (remainingQuota / data.emp_session) * 100;
    const percentageDone = 100 - percentageRemaining;
    return (
        <div className="border border-light bg-white rounded p-3 me-3">
            <div className="lect-name me-5">
                <p className="mb-0">
                    <Link to={{ pathname: "/employee/" + data.emp_id+"/detail" }} className="text-dark fw-bolder" target="_blank">
                        {(data.emp) ? data.emp.fullname : "-"}
                    </Link>
                </p>
                <p className="mb-0 text-muted fs-8">
                    Total Assigned: {data.emp_submitted}/{data.emp_session}
                </p>
            </div>
            <div className="lect-progress w-200px">
                <div className="d-flex me-2 w-100">
                    {percentageDone < 100 ? (
                        <div className="progress progress-xs mt-2 mb-2 w-100 h-20px">
                            {percentageApproved > 0 ? (
                                <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={renderTooltip({ title: (data.emp_approved) + " session approved" })}>
                                    <div className="progress-bar rounded-right-0 bg-primary fw-bolder" title='Approved' role="progressbar" style={{ width: (percentageApproved.toFixed(0)) + "%", height: "20px" }} >{data.emp_approved}</div>
                                </OverlayTrigger>
                            ) : ""}
                            {percentageSubmitted > 0 && (data.emp_approved !== data.emp_submitted) ? (
                                <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={renderTooltip({ title: (data.emp_submitted) + " session submited" })}>
                                    <div className="progress-bar rounded-left-0 bg-light-primary text-primary fw-bolder" title='Submited' role="progressbar" style={{ width: (percentageSubmitted.toFixed(0)) + "%", height: "20px" }}>{data.emp_submitted}</div>
                                </OverlayTrigger>
                            ) : ""}
                            <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={renderTooltip({ title: (remainingQuota) + " session remaining" })}>
                                <div className="progress-bar rounded-left-0 bg-light text-muted fw-bolder" title='Remaining' role="progressbar" style={{ width: (percentageRemaining.toFixed(0)) + "%", height: "20px" }} >{remainingQuota.toFixed(0)}</div>
                            </OverlayTrigger>
                        </div>
                    ) : (
                        <div className="progress progress-xs mt-2 mb-2 w-100 h-20px">
                            <div className="progress-bar rounded-left-0 bg-primary" title='Finish' role="progressbar" style={{ width: "100%", height: "20px" }} ></div>
                        </div>
                    )}

                    <div className="d-flex flex-stack ms-3">
                        <span className="text-muted me-2 fs-7 fw-bold">{(percentageDone) ? percentageDone.toFixed(0) : 0}%</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export { RenderSectionList, HeaderSection, SectionItemLabel, LectProgress }