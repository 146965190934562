import React, { useEffect, useState } from "react";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import { ButtonPrimary } from "../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../_metronic/helpers";
import {
  GET_MEMBER_SYS,
  GET_USER_ESTES,
} from "../../apis/module/BackendRequest";
import TableData from "./TableData";
import { useOutletContext } from "react-router-dom";
import { openModal } from "../../../../_layouts/components/modals/Modals";
import FormUser from "./FormUser";
import WActivityLogin from "./WActivityLogin";

export function UserList() {
  const context = useOutletContext();
  const widget_89 = context.widgets.find(
    (item) => item.widget_id === 89 && item.is_enabled === 1
  );

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const ReloadData = async () => {
    setResults({ loading: true, data: [], message: "" });

    const param = { env: "sgu-ums" };
    const result_user = await GET_USER_ESTES(param);
    if (result_user.data && Object.values(result_user.data).length > 0) {
      const result = await GET_MEMBER_SYS();
      if (result.data && Object.values(result.data).length > 0) {
        const user_list = result.data.map((v) => {
          const find_user = result_user.data.find(
            (item) => item.user_id === v.email
          );
          v.last_login = find_user && (find_user.last_login || "");
          v.app_id = find_user && (find_user.app_id || "");
          return v;
        });
        setResults({ loading: false, message: "", data: user_list });
      } else {
        setResults(result);
      }
    } else {
      setResults({ loading: false, data: [], message: "Failed retrive data" });
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bolder text-dark">Browse User List</span>
        <span className="text-muted mt-1 fw-bold fs-7">
          List of user in this system
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        {widget_89 && (
          <ButtonPrimary
            type="button"
            className="btn-sm fw-bold"
            onClick={() =>
              openModal({
                message: (
                  <FormUser ReloadData={ReloadData} users={results.data} />
                ),
              })
            }
          >
            <KTIcon iconName="plus" />
            Add New
          </ButtonPrimary>
        )}
      </SGUCard.Toolbar>
      <SGUCard.Body>
        <TableData results={results} ReloadData={ReloadData} />
      </SGUCard.Body>
    </SGUCard>
  );
}
