import React, { useEffect, useState } from "react";
import { Outlet, useOutletContext, useParams } from "react-router-dom";
import { ASC_IS_LAISTHESISREQUIRED, GET_ACTIVITY, GET_THS } from "../../apis/node-target-data/AcademicApi";
import { AlertNotif, AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import { Error404 } from "../../errors/components/Error404";
import { LoadingSkeleton, NoRecordFound } from "../../../../_layouts/components/loadbars/LoadingBars";
import { HeaderThesis } from "./components/ThesisDefenseUI";
import { useProvider } from "../../../../_layouts/UMSLayoutProvider";

export function DetailThesisDefense() {
  const context = useOutletContext();
  const widget_76 = context.widgets.find(
    (item) => item.widget_id === 76 && item.is_enabled === 1
  );

  let params = useParams();
  const token = params.token;

  try {
    const decode = atob(token);
    const data = JSON.parse(decode);
    return widget_76 ? (
      <DetailPage data={data} />
    ) : (
      <AlertNotif messages={AccessInvalid} />
    );
  } catch (error) {
    return <Error404 />;
  }
}

const DetailPage = ({ data }) => {
  const { account } = useProvider();

  const context = useOutletContext();
  const widget_77 = context.widgets.find(
    (item) => item.widget_id === 77 && item.is_enabled === 1
  );
  const widget_78 = context.widgets.find(
    (item) => item.widget_id === 78 && item.is_enabled === 1
  );
  const widget_79 = context.widgets.find(
    (item) => item.widget_id === 79 && item.is_enabled === 1
  );
  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const [survey, setSurvey] = useState({
    loading: false,
    surv_advisor: 0,
    surv_coadvisor: 0,
    message: "",
  });
  const [thsActivity, setThsActivity] = useState({
    loading: false,
    act_advisor: 0,
    act_coadvisor: 0,
    message: "",
  });
  const [isComplete, setIsComplete] = useState({
    lais: 1,
    ths_activity: 1,
  });


  const GetThsDetail = async () => {
    setResults({ loading: true, data: [], message: "" });

    const param = { term_id: data.term_id, ths_id: data.thesis_id };
    const result = await GET_THS(param);
    if (result.data && Object.values(result.data).length > 0) {
      const r_data = result.data[0];
      setResults({ loading: false, data: r_data, message: "" });
    } else {
      setResults(result);
    }
  };

  const checkSurveyCompletion = (surv_advisor, surv_coadvisor) => {
    if (data.advisor_id && data.coadvisor_id) {
      if (surv_advisor === 0 && surv_coadvisor === 0) {
        return 0;
      }
    } else if (data.advisor_id && !data.coadvisor_id) {
      if (surv_advisor === 0) {
        return 0;
      }
    }
    return 1;
  };


  const GetThsSurvey = async () => {
    let surv_advisor = 1;
    let surv_coadvisor = 1;
    setSurvey({ loading: true, data: [], message: "" });
    if (data.advisor_id) {
      const param1 = { studentid: data.studentid, emp_id: parseInt(data.advisor_id) };
      const result1 = await ASC_IS_LAISTHESISREQUIRED(param1);
      if (result1.data !== null && result1.data !== undefined) {
        surv_advisor = result1.data;
      }
    }
    if (data.coadvisor_id) {
      const param2 = { studentid: data.studentid, emp_id: parseInt(data.coadvisor_id) };
      const result2 = await ASC_IS_LAISTHESISREQUIRED(param2);
      if (result2.data !== null && result2.data !== undefined) {
        surv_coadvisor = result2.data;
      }
    }
    const surveyComplete = checkSurveyCompletion(surv_advisor, surv_coadvisor);
    setSurvey({
      loading: false,
      surv_advisor: surv_advisor,
      surv_coadvisor: surv_coadvisor,
      message: ""
    });

    setIsComplete(prevState => ({
      ...prevState,
      lais: surveyComplete
    }));

  };


  const checkActivityCompletion = (act_advisor, act_coadvisor) => {
    if (data.advisor_id && data.coadvisor_id) {
      if (act_advisor >= 5 && act_coadvisor >= 3) {
        return 0;
      }
    } else if (data.advisor_id && !data.coadvisor_id) {
      if (act_advisor >= 8) {
        return 0;
      }
    }
    return 1;
  };

  const GetThsActivity = async () => {
    setThsActivity({ loading: true, message: "" });
    let act_advisor = 0;
    let act_coadvisor = 0;

    if (data.advisor_id) {
      const param1 = { type: "act.type.thesis.activity", user: data.student_mail, target_user: data.advisor_mail };
      const result1 = await GET_ACTIVITY(param1);
      if (result1.data && Object.values(result1.data).length > 0) {
        const r_data1 = result1.data;
        const approvedItems = r_data1.filter(item => item.status === "APPROVED");
        act_advisor = approvedItems.length;

      }
    }

    if (data.coadvisor_id) {
      const param2 = { type: "act.type.thesis.activity", user: data.student_mail, target_user: data.coadvisor_mail };
      const result2 = await GET_ACTIVITY(param2);
      if (result2.data && Object.values(result2.data).length > 0) {
        const r_data2 = result2.data;
        const approvedItems = r_data2.filter(item => item.status === "APPROVED");
        act_coadvisor = approvedItems.length;
      }
    }

    const activityComplete = checkActivityCompletion(act_advisor, act_coadvisor);
    setThsActivity({
      loading: false,
      act_advisor: act_advisor,
      act_coadvisor: act_coadvisor,
      message: ""
    });

    setIsComplete(prevState => ({
      ...prevState,
      ths_activity: activityComplete
    }));
  };
  const typeck = account.typeCK;

  useEffect(() => {
    GetThsDetail();

    if (typeck === "development") {
      GetThsSurvey();
      GetThsActivity();
    }
  }, []);

  return results.loading ? <LoadingSkeleton /> :
    results.message ? <AlertRetry messages={results.message} Reload={GetThsDetail} /> :
      Object.values(results.data).length > 0 ? (
        <div className="detail-thesis">
          <HeaderThesis data={results.data} />

          {typeck === "development" ? (
            <Outlet context={{
              data: results.data, widgets: {
                widget_77: isComplete.lais === 0 && isComplete.ths_activity === 0 ? widget_77 : null,
                widget_78,
                widget_79
              }, isComplete: isComplete
            }} />
          ) : (
            <Outlet context={{ data: results.data, widgets: { widget_77, widget_78, widget_79 } }} />
          )}
          
        </div>
      ) : <NoRecordFound />;
};
