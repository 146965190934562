import { ReqAPIEstes } from "../ApiBackend";

const GET_PROFILE = async () => {
    const payload = {
        "target_data": "GET_PROFILE"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_ACTIVE_ACAD = async () => {
    const payload = {
        "target_data": "GET_ACTIVE_ACAD"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}


const GET_ACAD_LOOKUP = async (param) => {
    const payload = {
        "parameters": param ? param : { "type": "asc.atp.type.term" },
        "target_data": "GET_ACAD_LOOKUP"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}


const GET_STUDENT = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_STUDENT"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}


const GET_ROOM_CONFLICT = async () => {
    const payload = {
        "target_data": "GET_ROOM_CONFLICT"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_ROOM = async () => {
    const payload = {
        "target_data": "GET_ROOM"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}


const GET_ACAD_MSTONE_BY_ACAD = async (param) => {
    let payload = {
        "target_data": "GET_ACAD_MSTONE_BY_ACAD"
    }
    if(param){
        payload.parameters = param;
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const CLU_LOOK_UP = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "CLU_LOOK_UP"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const TYPE_LOOK_UP = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "TYPE_LOOK_UP"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_MSTONE_LOOKUP = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_MSTONE_LOOKUP"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_CORPORATE = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_CORPORATE"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_TEACHINGLOAD = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_TEACHINGLOAD"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_COURSE = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_COURSE"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const CREATE_SECTION = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "CREATE_SECTION"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_SECTION = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_SECTION"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const UPDATE_SECTION = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "UPDATE_SECTION"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const DELETE_SECTION = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "DELETE_SECTION"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const APPEND_SECTION_PREREQ = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "APPEND_SECTION_PREREQ"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const APPEND_SECTION_EMP = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "APPEND_SECTION_EMP"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_SECTION_STUDENT = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_SECTION_STUDENT"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const UPDATE_SECTION_STUDENT = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "UPDATE_SECTION_STUDENT"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const ADD_TEACHER_CLASSROOM_BATCH = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "ADD_TEACHER_CLASSROOM_BATCH"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const ADD_STUDENT_CLASSROOM_BATCH = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "ADD_STUDENT_CLASSROOM_BATCH"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const DELETE_CLASSROOM = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "DELETE_CLASSROOM"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const CREATE_CLASSROOM = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "CREATE_CLASSROOM"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const UPDATE_SECTION_CLASSROOM = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "UPDATE_SECTION_CLASSROOM"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_STUDENT_ACTIVITY = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_STUDENT_ACTIVITY"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_STUDENT_DATA_M = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_STUDENT_DATA_M"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_STUDENT_STATUS = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_STUDENT_STATUS"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_SCHD_CLASS_STD_NEXT = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_SCHD_CLASS_STD_NEXT"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_SCR_STUDENT_BY_ATPID = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_SCR_STUDENT_BY_ATPID"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_ATD_STUDENT_BY_ATP = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_ATD_STUDENT_BY_ATP"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_ATD_STUDENT_BY_COURSE = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_ATD_STUDENT_BY_COURSE"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_COUNSELOR = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_COUNSELOR"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const UPDATE_COUNSELOR = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "UPDATE_COUNSELOR"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_STUDENT_OUTSTANDING = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_STUDENT_OUTSTANDING"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const SUBMIT_LEAVE_STUDENT_ACTIVECALENDAR = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "SUBMIT_LEAVE_STUDENT_ACTIVECALENDAR"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const SUBMIT_RESIGN_STUDENT = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "SUBMIT_RESIGN_STUDENT"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const UPDATE_STUDENT_DATA = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "UPDATE_STUDENT_DATA"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_STUDENT_ACTIVITY_DETAIL = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_STUDENT_ACTIVITY_DETAIL"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_STD_PCF_HIST = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_STD_PCF_HIST"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_SCHD_CLASS_LEC_NEXT = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_SCHD_CLASS_LEC_NEXT"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_RPT_TEACHINGLOAD = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_RPT_TEACHINGLOAD"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_ATD_LECTURER_BY_SECTION_WITH_STUDENT = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_ATD_LECTURER_BY_SECTION_WITH_STUDENT"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_COUNSELING_BY_LECTURER = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_COUNSELING_BY_LECTURER"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_ATD_LECTURER_BY_SECTION = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_ATD_LECTURER_BY_SECTION"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const APPROVAL_ATD = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "APPROVAL_ATD"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_ATD_LECTURER_BY_ATDID = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_ATD_LECTURER_BY_ATDID"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const UPDATE_STD_ATD_BY_ATD_LEC = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "UPDATE_STD_ATD_BY_ATD_LEC"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const SUBMIT_ATD_LECTURER_NA = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "SUBMIT_ATD_LECTURER_NA"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const UPDATE_STD_ATD = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "UPDATE_STD_ATD"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_SCR_LECTURER_SECTION = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_SCR_LECTURER_SECTION"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const SUBMIT_SCR_STD_BY_LEC_SCR_COM = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "SUBMIT_SCR_STD_BY_LEC_SCR_COM"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const SCR_SET_INCOMPLETE_BY_LEC = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "SCR_SET_INCOMPLETE_BY_LEC"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_SCR_STUDENT = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_SCR_STUDENT"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const UPDATE_SCR_STUDENT_COM = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "UPDATE_SCR_STUDENT_COM"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_THS = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_THS"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_ACAD_TERM = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_ACAD_TERM"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_SCHD = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_SCHD"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const CREATE_SCHD_CLASS = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "CREATE_SCHD_CLASS"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const CREATE_SCHD = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "CREATE_SCHD"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const DELETE_SCHD = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "DELETE_SCHD"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const CREATE_MILESTONE = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "CREATE_MILESTONE"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const DELETE_ACAD_MILESTONE = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "DELETE_ACAD_MILESTONE"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const RDB_GROUP_GOOGLE = async () => {
    const payload = {
        "target_data": "RDB_GROUP_GOOGLE"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const RPT_STUDENT_SUMMARY = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "RPT_STUDENT_SUMMARY"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const UPDATE_THS = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "UPDATE_THS"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_THS_EXAMINER = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_THS_EXAMINER"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const APPEND_EXAMINER = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "APPEND_EXAMINER"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const DELETE_THS = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "DELETE_THS"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const GET_CLASSROOM = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "GET_CLASSROOM"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const CREATE_ACAD = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "CREATE_ACAD"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

const SUBMIT_PCF_STUDENT = async (param) => {
    const payload = {
        "parameters": param,
        "target_data": "SUBMIT_PCF_STUDENT"
    }
    const result = await ReqAPIEstes(payload)

    return result;
}

export {
    GET_PROFILE,
    GET_ACTIVE_ACAD,
    GET_ACAD_LOOKUP,
    GET_STUDENT,
    GET_ROOM_CONFLICT,
    GET_ROOM,
    GET_ACAD_MSTONE_BY_ACAD,
    CLU_LOOK_UP,
    TYPE_LOOK_UP,
    GET_CORPORATE,
    GET_TEACHINGLOAD,
    GET_COURSE,
    CREATE_SECTION,
    GET_SECTION,
    UPDATE_SECTION,
    DELETE_SECTION,
    APPEND_SECTION_PREREQ,
    APPEND_SECTION_EMP,
    GET_SECTION_STUDENT,
    UPDATE_SECTION_STUDENT,
    ADD_TEACHER_CLASSROOM_BATCH,
    ADD_STUDENT_CLASSROOM_BATCH,
    DELETE_CLASSROOM,
    CREATE_CLASSROOM,
    UPDATE_SECTION_CLASSROOM,
    GET_STUDENT_ACTIVITY,
    GET_STUDENT_DATA_M,
    GET_STUDENT_STATUS,
    GET_SCHD_CLASS_STD_NEXT,
    GET_SCR_STUDENT_BY_ATPID,
    GET_ATD_STUDENT_BY_ATP,
    GET_ATD_STUDENT_BY_COURSE,
    GET_COUNSELOR,
    UPDATE_COUNSELOR,
    GET_STUDENT_OUTSTANDING,
    SUBMIT_LEAVE_STUDENT_ACTIVECALENDAR,
    SUBMIT_RESIGN_STUDENT,
    UPDATE_STUDENT_DATA,
    GET_STUDENT_ACTIVITY_DETAIL,
    GET_STD_PCF_HIST,
    GET_SCHD_CLASS_LEC_NEXT,
    GET_RPT_TEACHINGLOAD,
    GET_ATD_LECTURER_BY_SECTION_WITH_STUDENT,
    GET_COUNSELING_BY_LECTURER,
    GET_ATD_LECTURER_BY_SECTION,
    APPROVAL_ATD,
    GET_ATD_LECTURER_BY_ATDID,
    UPDATE_STD_ATD_BY_ATD_LEC,
    SUBMIT_ATD_LECTURER_NA,
    UPDATE_STD_ATD,
    GET_SCR_LECTURER_SECTION,
    SUBMIT_SCR_STD_BY_LEC_SCR_COM,
    SCR_SET_INCOMPLETE_BY_LEC,
    GET_SCR_STUDENT,
    UPDATE_SCR_STUDENT_COM,
    GET_THS,
    GET_ACAD_TERM,
    GET_SCHD,
    CREATE_SCHD_CLASS,
    CREATE_SCHD,
    DELETE_SCHD,
    CREATE_MILESTONE,
    DELETE_ACAD_MILESTONE,
    GET_MSTONE_LOOKUP,
    RDB_GROUP_GOOGLE,
    RPT_STUDENT_SUMMARY,
    UPDATE_THS,
    GET_THS_EXAMINER,
    APPEND_EXAMINER,
    DELETE_THS,
    GET_CLASSROOM,
    CREATE_ACAD,
    SUBMIT_PCF_STUDENT
}