import React, { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import { getCSSVariableValue } from "../../../../../_metronic/assets/ts/_utils";

const CircularBar = ({ className, chartColor, chartHeight }) => {
  const chartRef = useRef(null);

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(
      chartRef.current,
      chartOptions(chartColor, chartHeight)
    );
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef]);

  return (
    <div className={`card ${className}`}>
      {/* begin::Beader */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Billing</span>
          <span className="text-muted fw-semibold fs-7">
            Total: 200
          </span>
        </h3>

        {/* <div className="card-toolbar">Ulala</div> */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body d-flex flex-column">
        <div className="flex-grow-1">
          <div ref={chartRef} className="mixed-widget-4-chart"></div>
        </div>

        <div className="pt-5">
          <p className="text-center fs-6 pb-5 ">
            <span className="badge badge-light-danger fs-8">Notes:</span>&nbsp;
            To see the detail data per student and amount of the bills, click see details
          </p>

          <a href="#" className={`btn btn-${chartColor} w-100 py-3`}>
            Details
          </a>
        </div>
      </div>
      {/* end::Body */}
    </div>
  );
};

const chartOptions = (chartColor, chartHeight) => {
  const baseColor = getCSSVariableValue("--bs-" + chartColor);
  const lightColor = getCSSVariableValue("--bs-" + chartColor + "-light");
  const labelColor = getCSSVariableValue("--bs-gray-700");

  return {
    series: [73],
    chart: {
      fontFamily: "inherit",
      height: chartHeight,
      type: "radialBar",
    },
    plotOptions: {
      radialBar: {
        // startAngle: -90, // Start the radial bar at the top (half-circle start)
        // endAngle: 90, // End the radial bar at the bottom (half-circle end)
        hollow: {
          margin: 0,
          size: "65%",
        },
        dataLabels: {
          name: {
            show: false,
            fontWeight: "700",
          },
          value: {
            color: labelColor,
            fontSize: "30px",
            fontWeight: "700",
            offsetY: 12,
            show: true,
            formatter: function(val) {
              return val + "%";
            },
          },
        },
        track: {
          background: lightColor,
          strokeWidth: "100%",
        },
      },
    },
    colors: [baseColor],
    stroke: {
      lineCap: "round", // Rounded stroke edges
    },
    tooltip: {
      enabled: true, // Enable tooltips
    //   formatter: function(val) {
    //     return `Paid: ${val}%`; // Customize the tooltip content
    //   },
    //   style: {
    //     fontSize: "12px", // Font size for tooltip text
    //     colors: ["#FFFFFF"], // Color of the tooltip text
    //   },
    },
    labels: ["Paid"],
  };
};

export { CircularBar };
