import React, { useState } from 'react'
import { SGUCard } from '../../../../../../../_layouts/components/cards/CardUI'
import { UpdateSection } from './UpdateSection';
import { ButtonTersier } from '../../../../../../../_layouts/components/buttons/ButtonUI';
import { useOutletContext } from 'react-router-dom';
import { SectionItemLabel } from '../../SectionUI';

export function CardSectionInfo() {
    const context = useOutletContext();
    const data = context.data;
    const widgets = context.widget;
    const widget_9 = widgets.find(
        (item) => item.widget_id === 9 && item.is_enabled === 1
    );
    const [isOpen, setIsOpen] = useState(false);
    return (
        <SGUCard>
            <SGUCard.Body>
                <div className="mt-8">
                    <SectionItemLabel name={"Section ID"} value={data.section_id} />
                    <SectionItemLabel name={"Academic Calendar"} value={data.atp_term && (data.atp_term.name || "")} />
                    <SectionItemLabel name={"Curriculum"} value={data.curr_group_type && (data.curr_group_type.name || "")} />
                    <SectionItemLabel name={"Max Session"} value={data.section_session || "-"} />

                    {!isOpen ? (
                        <>
                            <SectionItemLabel name={"Cost Center"} value={data.cost_center && (data.cost_center.name || "")} />
                            <SectionItemLabel name={"Max Number of Student"} value={data.max_seat || "-"} />
                            {data.corporate && <SectionItemLabel name={"Corporate"} value={data.corporate.name || "-"} />}
                            {data.parent_section && <SectionItemLabel name={"Parent Section"} value={data.parent_section} />}
                        </>
                    ) : (
                        <div className="update">
                            <UpdateSection data={data} setIsOpen={setIsOpen} />
                        </div>
                    )}

                    {widget_9 && (
                        !isOpen && (
                            <div className="d-grid gap-2 mt-5">
                                <ButtonTersier className="btn-sm fw-bold" type="button" onClick={() => setIsOpen(!isOpen)}>
                                    <i className="bi bi-pencil-square"></i> Update Section
                                </ButtonTersier>
                            </div>
                        )
                    )}
                </div>
            </SGUCard.Body>
        </SGUCard>
    )
}

