import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../../../../_layouts/components/popovers/PopOvers";

const RowLecturer = ({ obj }) => {
    return (
        <div className="d-flex justify-content-start flex-column">
            <span className="text-dark fw-bolder fs-6">{obj.lecturername}</span>
            <div className="d-flex">
                <span className="text-dark-50 fw-bold text-muted fs-7">
                    Total Assigned: {obj.t_assigned}/{obj.emp_session}
                </span>
                <span className="mx-2">·</span>
                <span className="text-dark-50 fw-bold text-muted fs-7">
                    Total Section: {obj.session_plotting ? obj.session_plotting : 0}
                </span>
            </div>
        </div>
    );
};

const RowCourse = ({ obj }) => {
    return (
        <div className="cours-item">
            {obj.course ? (
                <div className="position-relative py-2">
                    <span className="mb-1 text-dark fw-bolder">
                        {obj.course}
                    </span>
                    <div>
                        <span className="fs-7 text-muted fw-bolder">{obj.course_code}</span>
                        <span className="mx-2">·</span>
                        <span className="fs-7 text-muted fw-bolder">{obj.section_id}</span>
                    </div>
                    <span className="fs-7 text-muted fw-bolder">
                        Credit
                        <span className="text-info ms-2">{obj.course_credit} SKS</span>
                    </span>
                </div>
            ) : (
                ""
            )}
        </div>
    );
};

const LectProgressRPT = ({ data }) => {
    var percentageDone = 0, pAppv = 0, pSubmit = 0, sisa = 0;
    if (data.t_assigned) {
        //percentageDone = (data.t_assigned / data.session_plotting )* 100;
        pAppv = (data.t_approved / data.emp_session) * 100;
        pSubmit = (data.t_submitted / data.emp_session) * 100;
        percentageDone = (data.t_assigned / data.emp_session) * 100;
        sisa = 100 - percentageDone;
    }
    return (
        <div className="d-flex align-items-center">
            {percentageDone < 100 ? (
                <div className="progress progress-xs mt-2 mb-2 w-100 h-20px">
                    {pAppv > 0 ? (
                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={renderTooltip({ title: "Approved: " + data.t_approved + " session" })}>
                            <div className="progress-bar rounded-right-0 bg-primary fw-bolder" title='Approved' role="progressbar" style={{ width: (pAppv.toFixed(0)) + "%", height: "20px" }} >{data.t_approved}</div>
                        </OverlayTrigger>
                    ) : ""}
                    {pSubmit > 0 ? (
                        <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={renderTooltip({ title: "Submited: " + data.t_submitted + " session" })}>
                            <div className="progress-bar rounded-left-0 bg-light-primary text-primary fw-bolder" title='Submited' role="progressbar" style={{ width: (pSubmit.toFixed(0)) + "%", height: "20px" }}>{data.t_submitted}</div>
                        </OverlayTrigger>
                    ) : ""}
                    <OverlayTrigger delay={{ show: 250, hide: 400 }} placement="top" overlay={renderTooltip({ title: (data.emp_session - data.t_assigned) + " session left" })}>
                        <div className="progress-bar rounded-left-0 bg-light text-muted fw-bolder" title='Left' role="progressbar" style={{ width: (sisa.toFixed(0)) + "%", height: "20px" }} >{(data.emp_session - data.t_assigned)}</div>
                    </OverlayTrigger>
                </div>
            ) : (
                <div className="progress progress-xs mt-2 mb-2 w-100 h-20px">
                    <div className="progress-bar rounded-left-0 bg-primary" title='Finish' role="progressbar" style={{ width: "100%", height: "20px" }} >{data.t_assigned}</div>
                </div>
            )}
            <span className="ms-2 text-gray-500 fw-semibold">{percentageDone.toFixed(0)}%</span>
        </div>
    )
}

export { LectProgressRPT, RowCourse, RowLecturer }