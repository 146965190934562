import React, { useEffect, useState } from 'react'
import Moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { CREATE_INSTITUTIONHIST, DELETE_INSTITUTIONHIST, GET_INSTITUTIONHIST_BY_REFF } from '../../../../apis/node-target-data/IdentityApi'
import { AlertNotif } from '../../../../../../_layouts/components/alerts/AlertsUI'
import { LoadingSkeleton } from '../../../../../../_layouts/components/loadbars/LoadingBars'
import { ButtonPrimary } from '../../../../../../_layouts/components/buttons/ButtonUI'
import { MessageError, MessageSuccess, openModal } from '../../../../../../_layouts/components/modals/Modals'
import { SchoolLookUp, UnivLookUp, UnivProdyLookUp } from '../../../../components/forms/SelectBox';
import { RemoveEmptyValue } from '../../../../../../_layouts/_helpers/Global';

export default function FormInstitution({ stdBio }) {
    const [institute, setInstitute] = useState({ loading: false, data: [], message: "" })
    const ReloadData = async () => {
        setInstitute({loading:true, message:"", data:[]})
        const param = { reffid: stdBio.reff_ins_id }
        const result = await GET_INSTITUTIONHIST_BY_REFF(param);
        setInstitute(result);
    }

    useEffect(() => {
        ReloadData();
    }, [])

    const OpenFormNew = () => {
        openModal({ header: "Form Institutions", message: <FormAdd reff_id={stdBio.reff_ins_id} ReloadData={ReloadData} />, size: "sm" });
    }

    const HandlerRemove = async (institutionhist_id) => {
        if (confirm("Are you sure want to remove?") === true) {
            const param = { institutionhist_id: institutionhist_id }
            const result = await DELETE_INSTITUTIONHIST(param);

            if (result) {
                openModal({ message: <MessageSuccess message={"Successfully removed"} /> });
                ReloadData();
            }
        }
    }

    return (
        institute.loading ? <LoadingSkeleton /> : institute.message ? <AlertNotif messages={institute.message} /> : (
            Object.values(institute.data).length > 0 ? (
                <div className="table-responsive">
                    <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                            <tr className='fw-bolder text-muted'>
                                <th>No</th>
                                <th>Type</th>
                                <th>Institution</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {institute.data.map((v, index) => (
                                <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{v.university ? "University" : "School"}</td>
                                    <td>{v.university ? (v.university.name ? v.university.name : "") : (v.school ? (v.school.name ? v.school.name : "") : "-")}</td>
                                    <td>
                                        <div className="btn-group">
                                            <button className="btn btn-icon btn-sm btn-light-danger" type='button' onClick={() => HandlerRemove(v.id)}>
                                                <i className="bi bi-trash"></i>
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>

                    <div className="text-center">
                        <ButtonPrimary className="btn-sm fw-bold" type='button' onClick={() => OpenFormNew()}>Add New Institution</ButtonPrimary>
                    </div>
                </div>
            ) : (
                <div className="border bg-light rounded p-5">
                    <div className="text-center">
                        <i className="bi bi-building-slash fw-bolder fs-1"></i>
                        <p className="text-dark mb-0 mt-2">Instittution is empty

                            <span className="d-block">
                                <a type='button' className='text-primary' onClick={() => OpenFormNew()}>Click here</a> to create a new one.
                            </span>
                        </p>
                    </div>
                </div>
            )
        )
    )
}


const FormAdd = ({ reff_id, ReloadData }) => {
    const [schoolname, setSchoolName] = useState(0);
    const [schoolprogname, setSchoolProgName] = useState(0);
    const [univ, setUniv] = useState(0);
    const [univPrody, setUnivPrody] = useState(0);
    const paramObj = {
        "institution_type": "",
        "reff_id": reff_id,
        "school_id": schoolname,
        "schoolprogram_id": schoolprogname,
        "university_id": univ,
        "university_prodi_id": univPrody,
        "yeargraduation": Moment().toDate()
    }
    const [paramIns, setParamInst] = useState(paramObj);
    const [submitInst, setSubmitInst] = useState({ loading: false, data: [], message: "" })
    const HandlerPostInst = async (e) => {
        e.preventDefault();
        setSubmitInst({ loading: true, data: [], message: "" })
        let postParam = { ...paramIns };
        postParam.school_id = schoolname;
        postParam.schoolprogram_id = schoolprogname;
        postParam.university_id = univ;
        postParam.university_prodi_id = univPrody;
        postParam.yeargraduation = Moment(postParam.yeargraduation).format("YYYY");

        const param = RemoveEmptyValue(postParam);
        const result = await CREATE_INSTITUTIONHIST(param);

        setSubmitInst(result);
        ReloadData();
        setInterval(() => {
            openModal({ open: false });
        }, 1000);

    }

    const HandlerInstType = (e) => {
        var value = e.target.value;
        if (value === 'identity.ins.type.school') {
            setParamInst({ ...paramIns, institution_type: value, university_id: 0, university_prodi_id: 0 })
        } else if (value === 'identity.ins.type.university') {
            setParamInst({ ...paramIns, institution_type: value, school_id: 0, schoolprogram_id: 0 })
        }
    }

    return (
        <form method='post' autoComplete='off' onSubmit={(e) => HandlerPostInst(e)}>
            <div className="mb-5">
                <label className='fw-bolder required'>Type Institution</label>
                <select name="institution_type" required className="form-select" defaultValue={paramIns.institution_type} onChange={(e) => HandlerInstType(e)}>
                    <option value="">Choose One</option>
                    <option value="identity.ins.type.school">School</option>
                    <option value="identity.ins.type.university">University</option>
                </select>
            </div>

            {paramIns.institution_type === "identity.ins.type.school" ? (
                <div className="flt-school">
                    <SchoolLookUp data={schoolname} setData={setSchoolName} required />
                    <SchoolLookUp data={schoolprogname} setData={setSchoolProgName} />
                    {/* <Select2SchoolProg paramIns={paramIns} setParamInst={setParamInst} /> */}
                </div>
            ) : ""}

            {paramIns.institution_type === "identity.ins.type.university" ? (
                <div className="flt-school">
                    <UnivLookUp data={univ} setData={setUniv} />
                    {univ ? (
                        <UnivProdyLookUp data={univPrody} setData={setUnivPrody} univid={univ} />
                    ) : ""}
                </div>
            ) : ""}

            <div className="mb-5">
                <label className='fw-bolder'>Year Graduate</label>
                <DatePicker className="form-control" isClearable showYearPicker
                    selected={paramIns.yeargraduation} onChange={(date) => setParamInst({ ...paramIns, yeargraduation: date })}
                    dateFormat="yyyy"
                    onKeyDown={(e) => {
                        e.preventDefault();
                    }} />
            </div>

            {submitInst.message ? <AlertNotif color={Object.values(submitInst.data).length > 0 ? "info" : "danger"} messages={submitInst.message} /> : ""}

            <div className="text-center mt-10">
                <div className="btn-group">
                    <button className="btn btn-light fw-bolder text-uppercase" type='button' onClick={() => openModal({ open: false })}>Cancel</button>
                    <button className="btn btn-primary fw-bolder text-uppercase" disabled={submitInst.loading} type='submit'>
                        {submitInst.loading ? "Processing..." : "Save"}
                    </button>
                </div>
            </div>

        </form>
    )
}