import React from "react";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import { NoRecordFound } from "../../../../../_layouts/components/loadbars/LoadingBars";

export default function CourseActivityType({ data }) {
  const course_activity = data.course_act;

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bold text-gray-800">
          Course Activity Type
        </span>
      </SGUCard.Header>
      <SGUCard.Body className={"px-0"}>
        <div className="table-responsive">
          <table className="table table-row-bordered align-middle gy-6">
            <thead className="border-bottom border-gray-200 fs-6 fw-bold bg-light">
              <tr>
                <th width="8%" className="ps-3">No</th>
                <th>Type</th>
                <th>Session Duration</th>
                <th>Weight</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(course_activity).length > 0 ? (
                course_activity.map((v, index) => (
                  <tr key={index}>
                    <td className="ps-3">{index+1}</td>
                    <td>{v.activity_type && (v.activity_type.name || "")}</td>
                    <td>{v.duration_schd}</td>
                    <td>{v.weight}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4}>
                    <NoRecordFound />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </SGUCard.Body>
    </SGUCard>
  );
}
