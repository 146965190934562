import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { GET_EMP_BIO_BY_IDENID } from '../apis/node-target-data/HRAPI';
import { LoadingSkeleton, NoRecordFound } from '../../../_layouts/components/loadbars/LoadingBars';
import { AlertRetry } from '../../../_layouts/components/alerts/AlertsUI';
import CardProfile from './CardProfile';
import EmpTimetable from './timetables/EmpTimetable';
import ClassList from './class-list/ClassList';
import AcadCounselor from './counselor/AcadCounselor';
import { GET_USER_GROUP } from '../apis/module/BackendRequest';
import { Error404 } from '../errors/components/Error404';

export function Employee() {
    let params = useParams();
    const token = params.empid;

    try {
        const decode = atob(token);
        return <DetailPage empid={decode} />
    } catch (error) {
        return <Error404 />
    }
}

const DetailPage = ({empid}) => {
    const [results, setResults] = useState({
        loading: false,
        data: [],
        message: "",
    });

    const ReloadData = async () => {
        setResults({ loading: true, data: [], message: "" });
        const param = { employeeid: empid };
        const result = await GET_EMP_BIO_BY_IDENID(param);

        if (result.data && Object.values(result.data).length > 0) {
            const param = { employeeid: result.data.employee_id, email: result.data.sgumail }
            const result_emp = await GET_USER_GROUP(param)

            if (result_emp.data && Object.values(result_emp.data).length > 0) {
                let final_result = { ...result.data };
                final_result.group_name = result_emp.data.group_name;
                setResults({ loading: false, data: final_result, message: "" });
            } else {
                setResults(result);
            }
        } else {
            setResults(result);
        }
    }

    useEffect(() => {
        ReloadData();
    }, []);

    return (
        results.loading ? <LoadingSkeleton /> :
            results.message ? <AlertRetry messages={results.message} Reload={ReloadData} /> :
                Object.values(results.data).length > 0 ? (
                    <div className="row">
                        <div className="col-6 col-lg-3">
                            <CardProfile profile={results.data} />
                        </div>
                        <div className="col-6 col-lg-9">
                            <EmpTimetable employee_id={empid} />
                        </div>
                        <div className="col-12 mb-8">
                            <ClassList profile={results.data} />
                        </div>
                        <div className="col-12">
                            <AcadCounselor profile={results.data} />
                        </div>
                    </div>
                ) : <NoRecordFound />
    )
}
