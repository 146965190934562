import React, { useEffect, useState } from "react";
import CourseOffering from "./CourseOffering";
import MYPCF from "./MYPCF";
import { SGUCard } from "../../../../../../_layouts/components/cards/CardUI";
import { ButtonPrimary, ButtonTersier } from "../../../../../../_layouts/components/buttons/ButtonUI";
import { MessageError, openModal } from "../../../../../../_layouts/components/modals/Modals";
import { AcadTerm } from "../../../../components/forms/SelectBox";
import { RequiredMsg } from "../../../../../../_layouts/_core/ErrorMessage";
import { WindowSuccessPCF, WindowSuccessPCFOri } from "./MatriculationUI";
import { SUBMIT_PCF_STUDENT } from "../../../../apis/node-target-data/AcademicApi";
import { useOutletContext } from "react-router-dom";

export default function MatriculationPCF({ stdStatus, is_transfer, Back2Page }) {
  const program_id = stdStatus.program ? stdStatus.program.id : 0;
  const term_intake = stdStatus.term_intake ? stdStatus.term_intake.id : 0;
  const term_intake_name = stdStatus.term_intake ? stdStatus.term_intake.name : "";
  const context = useOutletContext();
  const widget_109 = context.widgets.find(
    (item) => item.widget_id === 109 && item.is_enabled === 1
  );
  const sess_course = sessionStorage.getItem("courseData");
  const [courseData, setCourseData] = useState(sess_course ? JSON.parse(sess_course) : []);
  const [mycourses, setMyCourses] = useState([]);
  const [term_id, setTerm_id] = useState(0);

  useEffect(() => {
    const datapcf = courseData.map(v => ({
      ...v,
      action_type: "add",
      is_transfer: is_transfer,
      score: "",
      section_id: "",
      course_ext:"",
      credit_ext :""
    }));
    setMyCourses(datapcf);
  }, [courseData, is_transfer]);

  let parampost = {
    studentid: stdStatus.studentid,
    term_id: term_intake,
    courses: []
  }

  const RemoveCourse = (id) => {
    //setMyCourses(prevCourses => prevCourses.filter(course => course.id !== id));
    setCourseData(prevData => prevData.filter(course => course.id !== id));
  };

  const [submit, setSubmit] = useState({ loading: false, message: "", data: [] });

  const SubmitHandler = async (e) => {
    e.preventDefault();
    if (Object.values(mycourses).length > 0 && parampost.term_id) {
      const invalidScores = mycourses.reduce((acc, course) => {
        if (!course.score || course.score === "" || course.score === null || course.score === undefined) {
          acc.push({ id: course.coursetitle, message: "Empty score" });
        } else {
          const score = parseInt(course.score);
          if (isNaN(score) || score < 0) {
            acc.push({ id: course.coursetitle, message: "Invalid score" });
          } else if (score > 100) {
            acc.push({ id: course.coursetitle, message: "Score too high" });
          }
        }
        return acc;
      }, []);

      if (invalidScores.length > 0) {
        console.log(invalidScores);
        const errorMessages = invalidScores.map(error => 
          `Course ${error.id} has ${error.message};`
        ).join('\n');
        openModal({ message: <MessageError message={errorMessages} /> });
        return;
      }

      setSubmit({ loading: true, message: "", data: [] })
      const courses = mycourses.map(course => {
        let obj = {};
        obj.action_type = course.action_type;
        obj.course_id = parseInt(course.id);
        obj.score = parseInt(course.score);
        obj.section_id = course.section_id;
        obj.is_transfer = parseInt(course.is_transfer);
        obj.course_ext = course.course_ext || "";
        obj.credit_ext = (course.credit_ext && course.credit_ext !== null) ? parseInt(course.credit_ext) : 0;
        return obj;
      });

      parampost.courses = courses;

      const result = await SUBMIT_PCF_STUDENT(parampost);
      if (result.data && Object.values(result.data).length > 0) {
        //save course into session storage
        sessionStorage.setItem('courseData', JSON.stringify(courseData));
        //end save 
        if(widget_109){
          openModal({ message: <WindowSuccessPCF totalCourse={Object.values(mycourses).length} setCourseData={setCourseData} Back2Page={Back2Page} /> })
        }else{
          openModal({ message: <WindowSuccessPCFOri setCourseData={setCourseData} Back2Page={Back2Page} /> })
        }
        
      } else {
        openModal({ message: <MessageError message={result.message} /> })

      }
      setSubmit(result);
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> })
    }
  }

  return (
    <div className="row">
      <div className="col-lg-4">
        <CourseOffering courseData={courseData} setCourses={setCourseData} />
      </div>
      <div className="col-lg-8">
        <form autoComplete="off" method="post" onSubmit={(e) => SubmitHandler(e)}>
          <SGUCard>
            <SGUCard.Header>
              <span className="card-label fw-bold text-gray-800">
              Transfer Credits Proposal Form for Academic Term <span className="text-primary">{term_intake_name}</span>
              </span>
            </SGUCard.Header>
            <SGUCard.Body>

              <MYPCF mycourses={mycourses} setMyCourses={setMyCourses} courseData={courseData} setCourseData={setCourseData} RemoveCourse={RemoveCourse} />

              <div className="text-end mt-8">
                <div className="btn-group">
                  <ButtonTersier className="btn-lg fw-bold" type="button" disabled={submit.loading} onClick={()=>Back2Page()}>
                    Cancel
                  </ButtonTersier>
                  <ButtonPrimary className="btn-lg fw-bold" type="submit" disabled={submit.loading}>
                    {submit.loading ? "Processing..." : "Submit"}
                  </ButtonPrimary>
                </div>
              </div>

            </SGUCard.Body>
          </SGUCard>
        </form>
      </div>
    </div>
  );
}
