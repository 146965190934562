import React, { useEffect, useState } from "react";
import { RemoveEmptyValue } from "../../../../../_layouts/_helpers/Global";
import FilterUI from "../../../components/filters/FilterUI";
import { GET_COURSE } from "../../../apis/node-target-data/AcademicApi";
import { Coursetype } from "../../../components/forms/SelectBox";
import {
  ButtonPrimary,
  ButtonTersier,
} from "../../../../../_layouts/components/buttons/ButtonUI";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import { TableData } from "..";
import { useNavigate } from "react-router-dom";
import { ButtonBack } from "../../teaching-loads/components/ButtonCreate";
import { CourseCode, CourseTitle } from "../../../components/forms/InputText";

export function LookUpCourseCatalog(props) {
  const { param, is_create, setSubLayout } = props;

  const [coursecode, setCoursecode] = useState("");
  const [coursetitle, setCourseTitle] = useState("");
  const [coursetype, setCourseType] = useState("");
  const postParam = {
    coursecode,
    coursetitle,
    coursetype,
    courseowner: param.org_id,
  };

  const [results, setResults] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const ReloadData = async () => {
    setResults({ loading: true, message: "", data: [] });
    const param = RemoveEmptyValue(postParam);
    const result = await GET_COURSE(param);
    setResults(result);
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const SubmitFilter = async (e) => {
    e.preventDefault();
    ReloadData();
  };

  const Back2List = () => {
    setSubLayout(param);
  };

  const history = useNavigate();

  useEffect(() => {
    const handleBackButton = (event) => {
        event.preventDefault();
        Back2List();
    }

    window.history.pushState(null, null, window.location.href);

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [history]);

  return (
    <div className="row">
      <div className="col-lg-3">
        <ButtonBack Back2List={Back2List} />
        <FilterUI title="Course Catalog" onSubmit={SubmitFilter}>
          <CourseCode data={coursecode} setData={setCoursecode} />
          <CourseTitle data={coursetitle} setData={setCourseTitle} />
          <Coursetype setData={setCourseType} />
          <div className="text-end">
            <ButtonTersier
              className="btn-sm me-2 px-6 fw-bold"
              type="reset"
              onClick={() => Back2List()}
            >
              Cancel
            </ButtonTersier>
            <ButtonPrimary
              className="btn-sm px-6 fw-bold"
              type="submit"
              disabled={results.loading}
            >
              {results.loading ? (
                <>
                  <span
                    className="spinner-grow spinner-grow-sm"
                    aria-hidden="true"
                  ></span>
                  <span className="visually-hidden" role="status">
                    Loading...
                  </span>
                </>
              ) : (
                <>
                  <i className="bi bi-search"></i>
                  Search
                </>
              )}
            </ButtonPrimary>
          </div>
        </FilterUI>
      </div>
      <div className="col-lg-9">
        <SGUCard>
          <SGUCard.Header>
            <span className="card-label fw-bold text-gray-800">
              Course Catalog
            </span>
            <span className="text-muted mt-1 fw-semibold fs-7">
              Browse the course catalog
            </span>
          </SGUCard.Header>
          <SGUCard.Body>
            {results.loading ? (
              <LoadingSkeleton />
            ) : results.message ? (
              <AlertNotif messages={results.message} color="danger" />
            ) : Object.values(results.data).length > 0 ? (
              <TableData data={results.data} is_create={is_create} ReloadData={ReloadData} paramTL={param} />
            ) : (
              <NoRecordFound />
            )}
          </SGUCard.Body>
        </SGUCard>
      </div>
    </div>
  );
}
