import { useOutletContext } from 'react-router-dom';
import StudentScore from './scores/StudentScore';

export function ScoreInfo() {
    const context = useOutletContext();
    const data = context.data;
    return (
        <div>
            <StudentScore section={data} />
        </div>
    )
}
