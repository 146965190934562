import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { SGUCard, SGUCardFlush } from '../../../../_layouts/components/cards/CardUI';
import { LoadingSkeleton, NoRecordFound } from '../../../../_layouts/components/loadbars/LoadingBars';
import { AlertRetry } from '../../../../_layouts/components/alerts/AlertsUI';
import { GET_STUDENT_ACTIVITY, GET_STUDENT_ACTIVITY_DETAIL, GET_STUDENT_DATA_M, GET_STUDENT_STATUS } from '../../apis/node-target-data/AcademicApi';
import { ButtonPrimary } from '../../../../_layouts/components/buttons/ButtonUI';
import { KTIcon } from '../../../../_metronic/helpers';
import { StudentIDFormat } from '../../../../_layouts/_helpers/Global';
import { CardActivityHist, CardActivityLeave, TablePCFActivity } from './ActivityUI';

export function DetailActivity() {
  let params = useParams();
  const token = params.activity_id;
  const decodeToken = atob(token);
  const objToken = JSON.parse(decodeToken);
  const activity_id = objToken.eventid;
  const studentid = objToken.studentid;

  const [activity, setActivity] = useState({ loading: false, data: [], message: "" });
  const ReloadData = async () => {
    setActivity({ loading: true, data: [], message: "" });
    const param_my_act = { studentid: studentid }
    const result_act = await GET_STUDENT_ACTIVITY(param_my_act);

    let selected_act = null;
    if (result_act.data && Object.values(result_act.data).length > 0) {
      selected_act = result_act.data.find(item => item.id === activity_id);
    }
    console.log("selected_act", selected_act);

    const param = { eventid: activity_id }
    const result = await GET_STUDENT_ACTIVITY_DETAIL(param);

    const param_std = { studentid: studentid };
    const std_bio = await GET_STUDENT_DATA_M(param_std);
    const std_acad = await GET_STUDENT_STATUS(param_std);

    if (result.data && std_bio.data && std_acad.data) {
      setActivity({ loading: false, data: { activity: selected_act, activity_detail: result.data, std_acad: std_acad.data, std_bio: std_bio.data }, message: "" });
    } else {
      setActivity(result);
    }
  }

  useEffect(() => {
    ReloadData();
  }, []);

  let totalSKS = 0;

  return (
    <div className='row gx-5 gx-xl-10'>
      <div className="col-xl-8 mb-10">
        <SGUCard>
          <SGUCard.Header>
            {Object.values(activity.data).length > 0 && (
              <>
                <span className="card-label fw-bolder fs-3 mb-1">
                  <span>
                    {activity.data.activity && (activity.data.activity.student_activity && (activity.data.activity.student_activity.name || ""))}
                  </span>
                  <span className="mx-2">
                    Semester
                  </span>
                  <span className='text-primary'>
                    {activity.data.activity && (activity.data.activity.semester || "0")}
                  </span>
                </span>
                <span className="text-muted mt-1 fw-bold fs-7">
                  Details of the Submitted {activity.data.activity && (activity.data.activity.student_activity && (activity.data.activity.student_activity.name || ""))}
                </span>
              </>
            )}
          </SGUCard.Header>
          <SGUCard.Toolbar>
            {Object.values(activity.data).length > 0 && (
              activity.data.activity && (
                <ButtonPrimary className="btn-sm" disabled={true}>
                  {activity.data.activity.status_activity || ""}
                </ButtonPrimary>
              )
            )}
          </SGUCard.Toolbar>
          <SGUCard.Body>
            {activity.loading ? <LoadingSkeleton /> :
              activity.message ? <AlertRetry messages={activity.message} Reload={ReloadData} /> :
                Object.values(activity.data).length > 0 ? (
                  (activity.data.activity.student_activity && activity.data.activity.student_activity.id.includes("pcf")) ?
                  <TablePCFActivity activity={activity} /> :
                  (activity.data.activity.student_activity && activity.data.activity.student_activity.id.includes("leave")) ?
                  <CardActivityLeave activity={activity.data.activity} /> : <NoRecordFound />
                ) : <NoRecordFound />}
          </SGUCard.Body>
        </SGUCard>
      </div>
      <div className="col-xl-4 mb-10">
        <CardActivityHist activity={activity} ReloadData={ReloadData} />
      </div>
    </div>

  )
}
