import React, { useMemo, useState } from "react";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  SearchBarTable,
} from "../../../../_layouts/components/datatables/MYDatatables";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import {
  LectProgressRPT,
  RowCourse,
  RowLecturer,
} from "../../course-managements/teaching-loads/components/TeachingLoadUI";
import { KTIcon } from "../../../../_metronic/helpers";
import { LinkBtn } from "../../../../_layouts/components/buttons/LinkUI";

export default function TableData({ results, ReloadData }) {
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const headers = [
    { name: "Course", field: "course", sortable: true },
    { name: "Lecturer", field: "lecturername", sortable: true },
    { name: "Section ID", field: "section_id", sortable: true },
    { name: "Action", field: "section_id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (results.data) {
      computedData = results.data.map((c) => {
        var obj = {};
        obj.corporate = c.corporate ? c.corporate : "";
        obj.cost_center = c.cost_center ? c.cost_center : "";
        obj.cost_center_name = c.cost_center ? c.cost_center.name : "";
        obj.cost_center_code = c.cost_center
          ? c.cost_center.code
            ? c.cost_center.code
            : ""
          : "-";
        obj.emp_session = c.emp_session ? c.emp_session : 0;
        obj.section_id = c.section_id;
        obj.section_session = c.section_session;
        obj.max_seat = c.max_seat;
        obj.lecturername = c.emp ? (c.emp.fullname ? c.emp.fullname : "") : "";
        obj.lecturerid = c.emp ? (c.emp.id ? c.emp.id : "") : "";
        obj.course_id = c.course ? c.course.id : 0;
        obj.course = c.course ? c.course.coursetitle : "";
        obj.course_code = c.course ? c.course.code : "";
        obj.course_credit = c.course ? c.course.credit : "";
        obj.t_approved = c.t_approved;
        obj.t_assigned = c.t_assigned;
        obj.t_submitted = c.t_submitted;
        obj.session_plotting = c.session_plotting;
        return obj;
      });
      computedData.sort((a, b) => (a.section_id > b.section_id ? 1 : -1));
    }

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key]
            .toString()
            .toLowerCase()
            .includes(search)
        );
      });
    }

    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedData;
  }, [results.data, search, sorting]);

  return (
    <div>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex">
          <ButtonItemRefresh
            totalItems={totalItems}
            onClick={(e) => ReloadData(e)}
          />
        </div>
      </div>

      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {results.loading ? (
              <tr>
                <td colSpan={5}>
                  <LoadingSkeleton />
                </td>
              </tr>
            ) : results.message ? (
              <tr>
                <td colSpan={5}>
                  <AlertNotif messages={results.message} />
                </td>
              </tr>
            ) : ResultData.length > 0 ? (
              ResultData.map((v, index) => (
                <tr key={index}>
                  <td width={"35%"}>
                    <RowCourse obj={v} />
                  </td>
                  <td>
                    <RowLecturer obj={v} />
                    <LectProgressRPT data={v} />
                  </td>
                  <td>
                    <LinkBtn to={"/course-managements/section/"+v.section_id+"/detail"} className="fw-bold text-dark text-hover-primary" title="View Section">{v.section_id}</LinkBtn>
                  </td>
                  <td>
                    <LinkBtn
                      to={"detail/" + btoa(v.section_id)}
                      target="_blank"
                      className="btn btn-icon btn-sm btn-light"
                      title="View Classroom"
                    >
                      <KTIcon iconName="arrow-right" className="fs-5" />
                    </LinkBtn>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>
                  <NoRecordFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
