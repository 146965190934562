import moment from 'moment';
import React, { useMemo, useState } from 'react'
import { ButtonItemRefresh, HeaderDatatables, SearchBarTable } from '../../../../_layouts/components/datatables/MYDatatables';
import { NoRecordFound, SpinnerLoad } from '../../../../_layouts/components/loadbars/LoadingBars';
import { RowStudent, RowThesisInfo } from '../../scores/thesis/ThesisUI';
import { OverlayTrigger } from 'react-bootstrap';
import { renderTooltip } from '../../../../_layouts/components/popovers/PopOvers';
import { openModal } from '../../../../_layouts/components/modals/Modals';
import { ButtonIcon } from '../../../../_layouts/components/buttons/ButtonUI';
import { LinkBtn } from '../../../../_layouts/components/buttons/LinkUI';
import { KTIcon } from '../../../../_metronic/helpers';
import { DownloadAttdSchd } from './DownloadAttdSchd';
import { StudentIDFormat } from '../../../../_layouts/_helpers/Global';

export default function TableData({ results, ReloadData }) {
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const headers = [
        { name: "No", field: "id", sortable: false },
        { name: "Student", field: "student_name", sortable: true },
        { name: "Title", field: "final_title", sortable: true },
        { name: "Defense Schedule", field: "defensedate", sortable: true },
        { name: "Examiners", field: "ths_main_ex", sortable: false },
        { name: "Actions", field: "id", sortable: false },
    ];

    const ResultData = useMemo(() => {
        let computedData = [];
        if (results.data) {
            computedData = results.data.map((c) => {
                var obj = {};
                obj.id = c.id || "";
                obj.section_id = c.section_id || "";
                obj.studentid = c.studentid || "";
                obj.student_name = c.studentdata ? (c.studentdata.fullname || "") : "";
                obj.student_mail= c.studentdata ? (c.studentdata.sgumail || "") : "";
                obj.clu_name = c.studentdata ? ((c.studentdata.clu && (c.studentdata.clu.name || "")) || "") : "";
                obj.clu_code = c.studentdata ? ((c.studentdata.clu && (c.studentdata.clu.code || "")) || "") : "";
                obj.clu_spec_name = c.studentdata ? ((c.studentdata.clu_spec && (c.studentdata.clu_spec.name || "")) || "") : "";
                obj.clu_spec_code = c.studentdata ? ((c.studentdata.clu_spec && (c.studentdata.clu_spec.code || "")) || "") : "";
                obj.submit_title = c.submit_title || "";
                obj.final_title = c.final_title || "";
                obj.defensedate = c.defensedate || "";
                obj.defensetime = c.defensetime || "";
                obj.atp_id_term = c.atp_id_term || "";
                obj.duedate = c.duedate || "";
                obj.defense_schd = c.defensedate && (moment(c.defensedate + " " + c.defensetime).format("DD MMM YYYY hh:mm A") || "");
                obj.is_active = c.is_active || "";
                obj.advisor_id = c.advisor_id || "";
                obj.advisor = c.advisor && (c.advisor.fullname || "");
                obj.advisor_mail = c.advisor && (c.advisor.email || "");
                obj.coadvisor_id = c.coadvisor_id || "";
                obj.coadvisor = c.coadvisor && (c.coadvisor.fullname || "");
                obj.coadvisor_mail = c.coadvisor && (c.coadvisor.email || "");
                obj.note = c.note || "";
                obj.revision = c.revision || "";
                obj.status = c.status || "";
                obj.ths_main_ex = c.ths_main_ex || "";
                obj.room_id = c.room_id || "";
                obj.room_name = c.room && (c.room.name || "");
                obj.student_course = c.student_course || "";
                obj.student_course_com = c.student_course_com || "";
                obj.section_com = c.section_com || "";
                return obj;
            });

            computedData.sort((a, b) => (a.student_name > b.student_name ? 1 : -1));
        }

        computedData = computedData.filter((prev) => {
            if (prev.section_id) {
                return prev;
            }
        });

        if (search) {
            computedData = computedData.filter((listData) => {
                return Object.keys(listData).some(
                    (key) =>
                        listData[key]
                            .toString()
                            .toLowerCase()
                            .includes(search)
                    //console.log(key,listData[key])
                );
            });
        }

        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        return computedData;
    }, [results.data, search, sorting]);

    return (
        <div>
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <SearchBarTable
                    size="w-250px"
                    onChange={(e) => setSearch(e.target.value)}
                />
                <div className="d-flex">
                    <ButtonItemRefresh
                        totalItems={totalItems}
                        onClick={(e) => ReloadData(e)}
                    />
                </div>
            </div>
            <div className="table-responsives">
                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
                    <HeaderDatatables
                        headers={headers}
                        onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                        {results.loading ? (
                            <tr>
                                <td colSpan={4}>
                                    <LoadingSkeleton />
                                </td>
                            </tr>
                        ) : results.message ? (
                            <tr>
                                <td colSpan={4}>
                                    <AlertNotif messages={results.message} color="danger" />
                                </td>
                            </tr>
                        ) : ResultData.length > 0 ? (
                            ResultData.map((v, index) => (
                                <tr key={index}>
                                    <td className='fs-8'>{index + 1}</td>
                                    <td><RowStudent data={v} /></td>
                                    <td>{v.final_title && <RowThesisInfo data={v} />}</td>
                                    <td>
                                        {v.defense_schd && (
                                            <>
                                                <span className="fs-8 d-block fw-bold">{v.defense_schd}</span>
                                                <span className="badge badge-light fs-8">Room {v.room_name}</span>
                                            </>
                                        )}
                                    </td>
                                    <td>{Object.values(v.ths_main_ex).length > 0 && <RowExaminers data={v.ths_main_ex} student={v.student_name} />}</td>
                                    <td><RowAction data={v} /></td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={4}>
                                    <NoRecordFound />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const RowAction = ({ data }) => {
    const [isdownload, setDownload] = useState(false);
    const DownloadPDF = (id) =>{
        const param = {ths_id:id, term_id:data.atp_id_term};
        DownloadAttdSchd(param, `Thesis Attendance for ${StudentIDFormat(data.studentid)}-${data.student_name}` ,setDownload);
    }
    return (
        <div className='btn-group'>
            <ButtonIcon className="btn-sm btn-light" type="button" title="Download Attendance" disabled={isdownload} onClick={()=>DownloadPDF(data.id) }>
                {isdownload ? <SpinnerLoad /> : <i className="bi bi-filetype-pdf text-danger fs-2"></i> }                
            </ButtonIcon>
            <LinkBtn className="btn btn-icon btn-sm btn-light" title="View Detail" target="_blank" to={`${btoa(JSON.stringify({thesis_id:data.id, term_id:data.atp_id_term, studentid:data.studentid, student_mail:data.student_mail, advisor_id:data.advisor_id, advisor_mail:data.advisor_mail, coadvisor_id:data.coadvisor_id, coadvisor_mail:data.coadvisor_mail}))}/schedules`}>
                <KTIcon iconName="arrow-right" className="fs-5" />
            </LinkBtn>
        </div>
    )
}

const RowExaminers = ({ data, student }) => {
    const totalshow = 5;
    const dataToShow = data.slice(0, totalshow);

    const Examiners = ({ data }) => {
        return (
            <div className="mailing">
                <ol>
                    {data.map((v, index) => (
                        <li key={index}>{v.emp && (v.emp.fullname || "")}</li>
                    ))}
                </ol>
            </div>
        );
    };

    return (
        <div className="d-flexs justify-content-start align-items-center">
            <div className="symbol-group symbol-hover flex-nowrap flex-grow-1 w-20px pe-2 mb-2">
                {Object.values(dataToShow).length > 0
                    ? dataToShow.map((v, index) => (
                        <OverlayTrigger
                            delay={{ show: 250, hide: 400 }}
                            placement="top"
                            overlay={renderTooltip({ title: v.emp && (v.emp.fullname || "") })}
                            key={index}
                        >
                            <div className="symbol symbol-circle symbol-25px">
                                <div className="symbol-label fs-8 fw-semibold bg-light text-primary border border-primary text-uppercase">
                                    {v.emp && (v.emp.email.charAt(0) || "")}
                                </div>
                            </div>
                        </OverlayTrigger>
                    ))
                    : ""}
            </div>
            <div>
                <span className="badge badge-light text-muted cursor-pointer" onClick={() => openModal({ header: `Examiner for ${student}`, message: <Examiners data={data} /> })} >
                    {Object.values(data).length <= totalshow ? (
                        <span>
                            view <i className="bi bi-chevron-double-right fs-9"></i>
                        </span>
                    ) : (
                        <span>
                            {Object.values(data).length - totalshow} more
                            <i className="bi bi-chevron-double-right fs-9"></i>
                        </span>
                    )}
                </span>
            </div>
        </div>
    );
};