import React, { useEffect, useState } from "react";
import { useOutletContext, useParams } from "react-router-dom";
import { Error404 } from "../../errors/components/Error404";
import { AlertNotif, AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import { GET_THS } from "../../apis/node-target-data/AcademicApi";
import { LoadingSkeleton, NoRecordFound } from "../../../../_layouts/components/loadbars/LoadingBars";
import { CardTitle } from "./components/CardTitle";
import { CardScore } from "./components/CardScore";
import { CardRevisionList } from "./components/CardRevision";
import { CardStudentInfo } from "./components/CardStudentInfo";

export function DetailThesisScore() {
  const context = useOutletContext();
  const widget_43 = context.widgets.find(
    (item) => item.widget_id === 43 && item.is_enabled === 1
  );

  const params = useParams();
  const token = params.token;
  try {
    const decode = atob(token);
    const data = JSON.parse(decode);
    return widget_43 ? (
      <DetailPage data={data} />
    ) : (
      <AlertNotif messages={AccessInvalid} />
    );
  } catch (error) {
    return <Error404 />;
  }
}

const DetailPage = ({data}) =>{
  
  const [result, setResult] = useState({loading:false, data:[], message:""});
  const ReloadData = async () => {
    setResult({loading:true, message:"", data:[]});
    const param = {term_id:data.term_id , ths_id: data.ths_main_id};
    const result = await GET_THS(param);

    if(result.data && Object.values(result.data).length > 0){
      setResult({loading:false, message:"", data:result.data[0]});
    }else{
      setResult(result);
    }
  }

  useEffect(()=>{
    ReloadData();
  },[]);

  return result.loading ? <LoadingSkeleton /> :
  result.message ? <AlertRetry messages={result.message} Reload={ReloadData} /> :
  Object.values(result.data).length > 0 ? (
    <div className='row'>
        <div className="col-lg-4">
            <div className="mb-5">
                <CardStudentInfo data={result.data} />
            </div>
        </div>
        <div className="col-lg-8">
          <CardTitle data={result.data} />
          <div className="mt-5">
            <CardScore data={result.data} />
          </div>
        </div>
        <div className="col-12">
        <CardRevisionList data={result.data} />
        </div>
    </div>
  ) :  <NoRecordFound />
}