import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { KTIcon } from "../../../../../_metronic/helpers";
import {
  ButtonPrimary,
  ButtonTersier,
} from "../../../../../_layouts/components/buttons/ButtonUI";
import {
  DELETE_REQUEST_DOC,
  DOWNLOAD_REQUEST_DOC,
  GET_REQUEST_DOC,
  POST_REQUEST_DOC,
  POST_REQUEST_DOC_MULTIPLE,
} from "../../../apis/module/JupiterRequest";
import { Encryptor } from "../../components/Helper";
import Skeleton from "react-loading-skeleton";
import {
  LoadingSkeleton,
  NoRecordFound,
  SpinnerLoad,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import { useProvider } from "../../../../../_layouts/UMSLayoutProvider";
import {
  CompleteDoc,
  DoctypeIdentifier,
  ScanningExistingDoc,
  VerifiedMandatoryDocs,
  VerifyStatus,
} from "./Helper";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { renderTooltip } from "../../../../../_layouts/components/popovers/PopOvers";
import {
  DELETE_MKT_DOC,
  GET_ENROLLMENT_PROCESS,
  UPDATE_ENROLLPROC_DOC_STATUS,
  UPLOAD_MKT_DOC,
  VERIFY_MKT_DOC,
} from "../../../apis/node-target-data/MarketingAPI";
import { ButtonMini } from "../../components/EnrollmentComp";

const ModalUploadDoc = ({
  registered_id,
  triggerNotification,
  candidate_id,
  ReloadData,
}) => {
  const { account } = useProvider();
  const [doc_status, setDocStatus] = useState(0);
  const [previews, setPreviews] = useState({}); // preview file sblm upload
  const [zoom, setZoom] = useState({ state: false, seeker: "up" });
  const [docType, setDocType] = useState("");
  const [progressUploading, setProgressUploading] = useState(0);
  const [isSubmit, setIsSubmit] = useState(false);
  const [indexing, setIndexing] = useState({});
  const [acceptedFilesCopy, setAcceptedFilesCopy] = useState([]);
  const [previewFiles, setPreviewFiles] = useState(null); // preview file online
  const [isDocTypeAlreadyExistState, setIsDocTypeAlreadyExistState] = useState(
    false
  );
  const [completeDocLoading, setCompleteDocLoading] = useState({
    loading: false,
    id: 0,
  });
  const [isDocComplete, setIsDocComplete] = useState(false);

  const [verifiedDoc, setVerifiedDoc] = useState({
    pasfoto: false,
    ktp: false,
    passport: false,
    reportcards: false,
    ijazah: false,
    birthcertificate: false,
    familycertificate: false,
    certificate: false,
    recommendationletter: false,
  });

  const ujiCoba = [
    {
      name: "KTP",
      key: "ktp",
    },
    {
      name: "PASSPORT",
      key: "passport",
    },
    {
      name: "RAPOR",
      key: "reportcards",
    },
    {
      name: "IJAZAH",
      key: "ijazah",
    },
    {
      name: "PAS FOTO",
      key: "pasfoto",
    },
    {
      name: "BIRTH CERT",
      key: "birthcertificate",
    },
    {
      name: "FAMILY CERT",
      key: "familycertificate",
    },
    {
      name: "CERTIFICATE",
      key: "certificate",
    },
    {
      name: "RCMD LETTER",
      key: "recommendationletter",
    },
  ];

  const [isFileTooLarge, setIsFileTooLarge] = useState({
    state: false,
    message: "",
  });
  const [fileList, setFileList] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const onDrop = useCallback((acceptedFiles) => {
    const validFiles = acceptedFiles.filter((file) => {
      const isValidType = [
        "image/jpeg",
        "image/png",
        "image/jpg",
        "application/pdf",
      ].includes(file.type);

      const isValidSize = file.size <= 2 * 1024 * 1024; // 2MB

      if (!isValidType) {
        setIsFileTooLarge({
          state: true,
          message: `File type is not valid. Only jpg, png, jpeg, and pdf.`,
        });
        setTimeout(() => {
          setIsFileTooLarge({ state: false, message: "" });
        }, 5000);

        return false;
      } else if (!isValidSize) {
        setIsFileTooLarge({
          state: true,
          message: `File size is too large. Maximum file size is 2MB.`,
        });
        setTimeout(() => {
          setIsFileTooLarge({ state: false, message: "" });
        }, 5000);
        return false;
      }

      return true;
    });

    console.log("acceptedFilesCopy ", validFiles);

    setAcceptedFilesCopy(validFiles);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles,
  } = useDropzone({
    onDrop,
    // accept: {
    //   "application/pdf": [".pdf"],
    //   "image/jpeg": [".jpeg", ".jpg"],
    //   "image/png": [".png"],
    // },
  });

  useEffect(() => {
    if (acceptedFilesCopy) {
      acceptedFilesCopy.forEach((file) => {
        // Generate preview untuk setiap file
        generatePreview(file);
      });
    }
  }, [acceptedFilesCopy]);

  const generatePreview = (file) => {
    // Jika file adalah gambar
    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviews({
          [file.name]: {
            type: "image",
            url: reader.result,
          },
        });
      };
      reader.readAsDataURL(file);
    }
    // Untuk file PDF
    else if (file.type === "application/pdf") {
      const url = URL.createObjectURL(file);
      setPreviews({
        [file.name]: {
          type: "pdf",
          url: url,
        },
      });
    }
  };

  const PreviewComponent = ({
    file = { name: "img" },
    preview,
    zoom = false,
  }) => {
    switch (preview.type) {
      case "image":
        return (
          <img
            src={preview.url}
            alt={file.name}
            className="w-100 rounded"
            style={{ objectFit: "cover", height: zoom ? "auto" : 250 }}
          />
        );
      case "pdf":
        return (
          <iframe
            src={preview.url}
            className="w-100 rounded-lg"
            style={{ objectFit: "cover", height: zoom ? "100%" : 250 }}
            title={file.name}
          />
        );
      default:
        return null;
    }
  };

  const GetFileList = async (candidate_id) => {
    setFileList({ loading: true, message: "", data: [] });

    const lst = await GET_ENROLLMENT_PROCESS({
      yearintake: JSON.parse(sessionStorage.getItem("previousFilter"))
        .yearintake,
      candidate_id: candidate_id,
    });

    console.log("lst", lst);

    if (lst.data.length > 0) {
      setCompleteDocLoading({ loading: false, id: lst.data[0].id });
      setDocStatus(lst.data[0].doc_status);
      setFileList({
        loading: lst.loading,
        message: lst.message,
        data: lst.data[0].regstudent.doc_list,
      });
      //
      setVerifiedDoc({
        pasfoto:
          lst.data[0].regstudent.doc_list.find(
            (dt) =>
              dt.doc_type.split(".").at(-1) === "pasfoto" && dt.verify === 1
          ) ?? false,
        ktp:
          lst.data[0].regstudent.doc_list.find(
            (dt) => dt.doc_type.split(".").at(-1) === "ktp" && dt.verify === 1
          ) ?? false,
        passport:
          lst.data[0].regstudent.doc_list.find(
            (dt) =>
              dt.doc_type.split(".").at(-1) === "passport" && dt.verify === 1
          ) ?? false,
        reportcards:
          lst.data[0].regstudent.doc_list.find(
            (dt) =>
              dt.doc_type.split(".").at(-1) === "reportcards" && dt.verify === 1
          ) ?? false,
        ijazah:
          lst.data[0].regstudent.doc_list.find(
            (dt) =>
              dt.doc_type.split(".").at(-1) === "ijazah" && dt.verify === 1
          ) ?? false,
        birthcertificate:
          lst.data[0].regstudent.doc_list.find(
            (dt) =>
              dt.doc_type.split(".").at(-1) === "birthcertificate" &&
              dt.verify === 1
          ) ?? false,
        familycertificate:
          lst.data[0].regstudent.doc_list.find(
            (dt) =>
              dt.doc_type.split(".").at(-1) === "familycertificate" &&
              dt.verify === 1
          ) ?? false,
        certificate:
          lst.data[0].regstudent.doc_list.find(
            (dt) =>
              dt.doc_type.split(".").at(-1) === "certificate" && dt.verify === 1
          ) ?? false,
        recommendationletter:
          lst.data[0].regstudent.doc_list.find(
            (dt) =>
              dt.doc_type.split(".").at(-1) === "recommendationletter:" &&
              dt.verify === 1
          ) ?? false,
      });
    } else {
      setFileList(lst);
    }
    // console.log("list -> ", lst);
  };

  const UploadFile = async () => {
    const param = {
      doc_type: `strg.doc.type.${docType}`,
      user: account.identity.email,
      owner: registered_id,
    };

    if (isDocTypeAlreadyExistState)
      param["file_id"] = isDocTypeAlreadyExistState.file_id;

    const encrypted = Encryptor(param);

    const formData = new FormData();
    formData.append("data", encrypted);
    formData.append("file", acceptedFilesCopy[0]);

    const up = await POST_REQUEST_DOC_MULTIPLE(formData, (progress) => {
      if (progress < 95) {
        setProgressUploading(progress);
      }
    });

    console.log("param -> ", param);
    console.log("up -> ", up);

    if (up.data?.result === "success") {
      //upload file ref to enrollment_doc

      const update_doc = await UPLOAD_MKT_DOC({
        file_id: up.data.data.file_id,
        reff_id: registered_id,
        doc_type: up.data.data.doc_type,
        mimetype: up.data.data.mimetype.includes("image") ? "image" : "pdf",
      });

      triggerNotification({
        title: "Success",
        type: "success",
        message: isDocTypeAlreadyExistState
          ? "Success update file"
          : "Success upload file",
        duration: 3000,
      });
      setProgressUploading(100);
      setAcceptedFilesCopy([]);
      setPreviews({});
      setIsDocTypeAlreadyExistState(false);
      setIsSubmit(false);
      setDocType("");
      GetFileList(candidate_id);
      ReloadData();
    } else {
      triggerNotification({
        title: "Failed!",
        type: "danger",
        message: up?.error?.msg,
        duration: 3000,
      });
      setProgressUploading(0);
      setIsSubmit(false);
    }
  };

  const VerifyFile = async (index, file_id) => {
    setIndexing({ index: index, verify: true });

    const verify = await VERIFY_MKT_DOC({
      file_id: file_id,
    });
    console.log("verify -> ", verify);
    setIndexing({ index: index, verify: false });

    triggerNotification({
      title: verify.message !== "" ? "Filed!" : "Success",
      type: verify.message !== "" ? "danger" : "success",
      message:
        verify.message !== "" ? verify.message : "Success verifying file",
      duration: 3000,
    });

    if (verify.message === "") {
      GetFileList(candidate_id);
      ReloadData();
    }
  };

  const DownloadFile = async (index, file_name, file_id) => {
    setIndexing({ index: index, download: true });

    const encrypted = Encryptor({
      file_id: file_id,
      user: account.identity.email,
    });

    const formData = new FormData();
    formData.append("data", encrypted);
    formData.append("file", acceptedFilesCopy[0]);

    const down = await DOWNLOAD_REQUEST_DOC(formData);
    console.log("down -> ", down);
    setIndexing({ index: index, download: false });

    if (down.status === 200) {
      const url = URL.createObjectURL(down.data);
      const a = document.createElement("a"); // Membuat elemen <a> untuk download
      a.href = url;
      a.download = `${file_name}.jpg`; // Nama file saat diunduh
      document.body.appendChild(a); // Menambahkan elemen ke DOM
      a.click(); // Memicu download
      document.body.removeChild(a); // Menghapus elemen setelah download
    } else {
      triggerNotification({
        title: "Failed!",
        type: "danger",
        message: down?.message,
        duration: 3000,
      });
    }
  };

  const PreviewFile = async (index, file_id) => {
    setIndexing({ index: index, preview: true });

    const encrypted = Encryptor({
      file_id: file_id,
      user: account.identity.email,
    });

    const formData = new FormData();
    formData.append("data", encrypted);
    formData.append("file", acceptedFilesCopy[0]);

    const prev = await DOWNLOAD_REQUEST_DOC(formData);
    console.log("prev -> ", prev);
    setIndexing({ index: index, preview: false });

    if (prev.status === 200) {
      const url = URL.createObjectURL(prev.data); // Membuat URL untuk blob
      console.log(url);
      if (prev.data.type.startsWith("image/")) {
        setPreviewFiles({
          type: "image",
          url: url,
        });
      } else {
        setPreviewFiles({
          type: "pdf",
          url: url,
        });
      }
      setZoom({ state: true, seeker: "prev" });
    } else {
      triggerNotification({
        title: "Failed!",
        type: "danger",
        message: prev?.message,
        duration: 3000,
      });
    }
  };

  const DeleteFile = async (data) => {
    console.log("object deleted ", data);
    setIndexing({ index: data.index, delete: true });
    const encrypted = Encryptor({
      file_id: data.file_id,
      user: account.identity.email,
    });

    const formData = new FormData();
    formData.append("data", encrypted);

    const del = await DELETE_REQUEST_DOC(formData);
    console.log("del -> ", del);

    setIndexing({ index: data.index, delete: false });
    if (del.result === "success") {
      const delete_doc = await DELETE_MKT_DOC({
        file_id: data.file_id,
      });
      console.log("delete_doc -> ", delete_doc);
      ReloadData();
      triggerNotification({
        title: "Success",
        type: "success",
        message: "Success delete file",
        duration: 3000,
      });
      GetFileList(candidate_id);
    } else {
      triggerNotification({
        title: "Failed!",
        type: "danger",
        message: del?.error?.msg,
        duration: 3000,
      });
    }
  };

  const CompleteDoc = async () => {
    if (completeDocLoading.id !== 0) {
      setCompleteDocLoading({ ...completeDocLoading, loading: true });
      const complete = await UPDATE_ENROLLPROC_DOC_STATUS({
        id: completeDocLoading.id,
        doc_status: 1,
      });
      console.log("complete -> ", complete);
      if (complete.message === "") {
        setCompleteDocLoading({ loading: false, id: 0 });
        ReloadData();
        triggerNotification({
          title: "Success",
          type: "success",
          message: "Success complete documents",
          duration: 3000,
        });
        GetFileList(candidate_id);
      } else {
        setCompleteDocLoading({ ...completeDocLoading, loading: false });
        triggerNotification({
          title: "Failed!",
          type: "danger",
          message: complete?.message,
          duration: 3000,
        });
      }
    }
  };

  const handleSubmit = () => {
    setIsSubmit(true);
    UploadFile();
  };

  useEffect(() => {
    GetFileList(candidate_id);
  }, []);

  // cek apakah file yg akan di uplaod sudah ada di list yang sama atau belum
  useEffect(() => {
    if (docType) {
      const isDocTypeAlreadyExist = fileList.data.find((dt) =>
        dt.doc_type.includes(docType)
      );

      // console.log("isDocTypeAlreadyExist", isDocTypeAlreadyExist);
      setIsDocTypeAlreadyExistState(isDocTypeAlreadyExist ?? false);
    }
  }, [docType]);

  const DeleteConfirmation = ({ iconName, data }) => {
    return (
      <OverlayTrigger
        trigger="click"
        placement="right"
        rootClose
        overlay={
          <Popover className="p-3">
            <Popover.Body className="p-0">
              <p className="fw-bold">
                <span className="text-danger">*</span>Deleted file can not be
                restore
              </p>
              <div
                className="cursor-pointer bg-hover-light-danger text-hover-danger ps-3 pe-5 py-3 rounded d-flex align-items-center"
                onClick={() => DeleteFile(data)}
              >
                <KTIcon iconName="information-5" className="fs-3 me-2" />
                <span>Confirm Delete</span>
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <div>
          <KTIcon
            iconName={iconName}
            className={`fs-1 cursor-pointer text-hover-danger me-3`}
          />
        </div>
      </OverlayTrigger>
    );
  };

  const MenuDocList = ({ iconName, title, data }) => {
    return (
      <OverlayTrigger
        trigger="hover"
        placement="top"
        overlay={renderTooltip({ title })}
      >
        <div>
          {iconName === "trash" ? (
            <DeleteConfirmation iconName={iconName} data={data} />
          ) : (
            <KTIcon
              iconName={iconName}
              className={`fs-1 cursor-pointer text-hover-${
                iconName === "trash" ? "danger" : "primary"
              } me-3`}
            />
          )}
        </div>
      </OverlayTrigger>
    );
  };

  const MenuDocListForbidden = ({ iconName }) => {
    return (
      <div>
        {iconName === "trash" ? (
          <KTIcon iconName={iconName} className={`fs-1 opacity-50 me-3`} />
        ) : (
          <KTIcon iconName={iconName} className={`fs-1 opacity-50 me-3`} />
        )}
      </div>
    );
  };

  return (
    <div>
      <div
        className="accordion accordion-icon-collapse"
        id="kt_accordion_list_doc"
      >
        <div className="mb-5">
          <div className="d-flex justify-content-between">
            <div
              className="accordion-header py-3 d-flex collapsed"
              data-bs-toggle="collapse"
              data-bs-target="#kt_accordion_3_item_1"
            >
              <span className="accordion-icon">
                <i className="ki-duotone ki-plus-square fs-3 accordion-icon-off">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                </i>
                <i className="ki-duotone ki-minus-square fs-3 accordion-icon-on">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </span>
              <h3 className="fs-4 fw-semibold mb-0 ms-4">Document List</h3>
            </div>
            {verifiedDoc.ktp || verifiedDoc.passport ? (
              <ButtonMini
                className="btn-sm"
                iconName={doc_status === 1 ? "folder-added" : ""}
                onClick={() => CompleteDoc()}
                disabled={completeDocLoading.loading || doc_status === 1}
              >
                {doc_status === 1
                  ? "Completed"
                  : completeDocLoading.loading
                  ? "Processing..."
                  : "Complete Doc"}
              </ButtonMini>
            ) : null}
          </div>

          <div
            id="kt_accordion_3_item_1"
            className="fs-6 collapse ps-10 pe-5"
            data-bs-parent="#kt_accordion_list_doc"
          >
            <div className="row overflow-auto" style={{ maxHeight: 250 }}>
              {fileList.loading ? (
                <LoadingSkeleton />
              ) : fileList.message ? (
                <AlertNotif messages={fileList.message} color="danger" />
              ) : fileList.data?.length > 0 ? (
                <div className="col-12 mb-5">
                  <div className="mt-5">
                    <div className="space-y-1">
                      {fileList.data.map((file, index) => (
                        <div
                          key={index}
                          className="d-flex align-items-center justify-content-between bg-gray-50 rounded-lg mb-3 border-bottom"
                        >
                          {/* icon */}
                          <div className="d-flex">
                            <div className="mt-2 me-2 fs-3 text-muted">
                              {index + 1}
                            </div>
                            <div
                              className="position-relative me-3"
                              style={{ width: 40, height: 40 }}
                            >
                              <KTIcon
                                iconName="folder"
                                className={`fs-3x ${
                                  file?.mimetype &&
                                  file?.mimetype
                                    .split(".")
                                    .at(-1)
                                    .toLowerCase() === "pdf"
                                    ? "text-info"
                                    : "text-primary"
                                }`}
                              />
                              <p
                                className="text-white"
                                style={{
                                  position: "absolute",
                                  top: "50%",
                                  left: "50%",
                                  transform: "translate(-50%, -50%)",
                                }}
                              >
                                {file.mimetype === "image" ? "img" : "pdf"}
                              </p>
                            </div>
                            {/* file type & verify */}
                            <div className="d-flex flex-column">
                              <span className="fs-5 fw-bold">
                                {DoctypeIdentifier(file.doc_type)}
                              </span>
                              <span>{VerifyStatus(file.verify)}</span>
                            </div>
                          </div>
                          {/* icons menu */}
                          <div className="d-flex align-items-center">
                            <div
                              onClick={() => file.verify === 0 && VerifyFile(index, file.file_id)
                              }
                            >
                              {indexing.index === index && indexing.verify ? (
                                <div className="me-5">
                                  <SpinnerLoad />
                                </div>
                              ) : file.verify === 1 ? (
                                <MenuDocListForbidden
                                  iconName="shield-tick"
                                  title="Already Verified"
                                />
                              ) : (
                                <MenuDocList
                                  iconName="shield-tick"
                                  title="Verify"
                                />
                              )}
                            </div>

                            <div
                              onClick={() =>
                                DownloadFile(index, file.mimetype, file.file_id)
                              }
                            >
                              {indexing.index === index && indexing.download ? (
                                <div className="me-5">
                                  <SpinnerLoad />
                                </div>
                              ) : (
                                <MenuDocList
                                  iconName="file-down"
                                  title="Download"
                                />
                              )}
                            </div>
                            <div
                              onClick={() => PreviewFile(index, file.file_id)}
                              className="me-3"
                            >
                              {indexing.index === index && indexing.preview ? (
                                <div className="me-5 ms-1">
                                  <SpinnerLoad />
                                </div>
                              ) : (
                                <MenuDocList iconName="size" title="Preview" />
                              )}
                            </div>
                            <div>
                              {indexing.index === index && indexing.delete ? (
                                <div className="me-5 ms-1">
                                  <SpinnerLoad />
                                </div>
                              ) : VerifiedMandatoryDocs(
                                  file.doc_type,
                                  verifiedDoc,
                                  doc_status
                                ) ? (
                                <MenuDocListForbidden
                                  iconName="trash"
                                  title="Forbidden"
                                />
                              ) : (
                                <MenuDocList
                                  iconName="trash"
                                  title="Delete"
                                  data={{
                                    index: index,
                                    file_id: file.file_id,
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <NoRecordFound />
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="separator border-primary mb-5"></div>

      {/* load file and preview */}
      {Object.keys(previews).length > 0 ? (
        <div className="">
          {Object.entries(previews).map(([fileName, preview]) => {
            const file = { name: fileName };
            return (
              <div
                key={fileName}
                className="bg-gray-50 rounded-lg position-relative"
              >
                <div
                  className="cursor-pointer position-absolute d-flex align-items-center justify-content-center"
                  style={{
                    right: 10,
                    top: 10,
                    borderRadius: 49,
                    height: 35,
                    width: 35,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
                  title="close"
                  onClick={() => {
                    setPreviews({});
                    setAcceptedFilesCopy([]);
                    setDocType("");
                  }}
                >
                  <i class="ki-duotone ki-cross-circle fs-2x">
                    <span class="path1 text-white"></span>
                    <span class="path2 text-white"></span>
                  </i>
                </div>
                <div
                  className="cursor-pointer position-absolute"
                  style={{
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)",
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
                  title="Enlarge"
                  onClick={() => {
                    setZoom({ state: true, seeker: "up" });
                  }}
                >
                  <KTIcon
                    iconName="arrow-two-diagonals"
                    className="fs-5x text-white"
                  />
                </div>
                <div className="w-100 h-100">
                  <PreviewComponent file={file} preview={preview} />
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <div
          {...getRootProps()}
          className={`border-2 border-dashed rounded p-8 text-center cursor-pointer
          ${
            isDragActive
              ? "border-blue-500 bg-blue-50"
              : "border-gray-300 hover:border-gray-400"
          }`}
        >
          <input {...getInputProps()} />

          <i
            class={`ki-duotone ki-folder-up ${
              isDragActive ? "text-primary" : "text-gray-500"
            }`}
            style={{ fontSize: 120 }}
          >
            <span class="path1"></span>
            <span class="path2"></span>
          </i>

          <div className="text-lg font-medium text-muted mt-10">
            {isDragActive ? (
              <h5>Drop it!</h5>
            ) : isFileTooLarge.state ? (
              <AlertNotif messages={isFileTooLarge.message} color="danger" />
            ) : (
              <>
                <h5>Drag & drop or click to browse your file</h5>
                <span className="fs-6 fw-light">
                  Supports: JPG, JPEG, PNG & PDF. Max size 2MB
                </span>
              </>
            )}
          </div>
        </div>
      )}

      {/* selected file */}
      {acceptedFilesCopy.length > 0 && (
        <div className="mt-5">
          <div className="space-y-1">
            {acceptedFilesCopy.map((file, index) => (
              <div
                key={index}
                className="d-flex items-center justify-between bg-gray-50 rounded-lg"
              >
                <div
                  className="position-relative me-3"
                  style={{ width: 40, height: 40 }}
                >
                  <KTIcon
                    iconName="folder"
                    className={`fs-3x ${
                      file.name
                        .split(".")
                        .at(-1)
                        .toLowerCase() === "pdf"
                        ? "text-info"
                        : "text-primary"
                    }`}
                  />
                  <p
                    className="text-white"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    {file.name.split(".").at(-1)}
                  </p>
                </div>
                <div className="d-flex flex-column">
                  <span className="fs-5 fw-bold">
                    {file.name.slice(0, 10)}
                    {file.name.length > 10 ? "..." : ""}
                  </span>
                  <span className="text-gray-500">
                    {(file.size / (1024 * 1024)).toFixed(2)} MB
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* type */}
      <div className="mt-5">
        <p className="fw-bold m-0">Doc type:</p>
        <div className="d-flex flex-wrap mt-2">
          {/* <div className="form-check form-check-sm form-check-custom form-check-solid me-5">
            <input
              className={`form-check-input cursor-pointer ${verifiedDoc.ktp &&
                "opacity-50"} ${verifiedDoc.ktp &&
                docType === "ktp" &&
                "opacity-100"}`}
              type="checkbox"
              checked={verifiedDoc.ktp || docType === "ktp"}
              readOnly={verifiedDoc.ktp}
              id="ktp"
              onClick={() => setDocType(docType === "ktp" ? "" : "ktp")}
            />
            <label className="form-check-label" for="ktp">
              KTP
            </label>
          </div>
          <div className="form-check form-check-sm form-check-custom form-check-solid me-5">
            <input
              className={`form-check-input cursor-pointer ${verifiedDoc.passport &&
                "opacity-50"} ${verifiedDoc.passport &&
                docType === "passport" &&
                "opacity-100"}`}
              type="checkbox"
              checked={verifiedDoc.passport || docType === "passport"}
              readOnly={verifiedDoc.passport}
              id="passport"
              onClick={() =>
                setDocType(docType === "passport" ? "" : "passport")
              }
            />
            <label className="form-check-label" for="passport">
              PASSPORT
            </label>
          </div>
          <div className="form-check form-check-sm form-check-custom form-check-solid me-5">
            <input
              className={`form-check-input cursor-pointer ${verifiedDoc.reportcards &&
                "opacity-50"} ${verifiedDoc.reportcards &&
                docType === "reportcards" &&
                "opacity-100"}`}
              type="checkbox"
              checked={verifiedDoc.reportcards || docType === "reportcards"}
              readOnly={verifiedDoc.reportcards}
              id="rapor"
              onClick={() =>
                setDocType(docType === "reportcards" ? "" : "reportcards")
              }
            />
            <label className="form-check-label" for="rapor">
              RAPOR
            </label>
          </div>
          <div className="form-check form-check-sm form-check-custom form-check-solid me-5">
            <input
              className={`form-check-input cursor-pointer ${verifiedDoc.pasfoto &&
                "opacity-50"} ${verifiedDoc.pasfoto &&
                docType === "pasfoto" &&
                "opacity-100"}`}
              type="checkbox"
              checked={verifiedDoc.pasfoto || docType === "pasfoto"}
              readOnly={verifiedDoc.pasfoto}
              id="pasfoto"
              onClick={() => setDocType(docType === "pasfoto" ? "" : "pasfoto")}
            />
            <label className="form-check-label" for="pasfoto">
              PASFOTO
            </label>
          </div>
          <div className="form-check form-check-sm form-check-custom form-check-solid me-5">
            <input
              className={`form-check-input cursor-pointer ${verifiedDoc.ijazah &&
                "opacity-50"} ${verifiedDoc.ijazah &&
                docType === "ijazah" &&
                "opacity-100"}`}
              type="checkbox"
              checked={verifiedDoc.ijazah || docType === "ijazah"}
              readOnly={verifiedDoc.ijazah}
              id="ijazah"
              onClick={() => setDocType(docType === "ijazah" ? "" : "ijazah")}
            />
            <label className="form-check-label" for="ijazah">
              IJAZAH
            </label>
          </div> */}

          {ujiCoba.map((x, index) => (
            <div
              key={index}
              className="form-check form-check-sm form-check-custom form-check-solid me-5 mb-3"
            >
              <input
                className={`form-check-input cursor-pointer ${verifiedDoc[
                  x.key
                ] && "opacity-50"} ${verifiedDoc[x.key] &&
                  docType === x.key &&
                  "opacity-100"}`}
                type="checkbox"
                checked={verifiedDoc[x.key] || docType === x.key}
                readOnly={verifiedDoc[x.key]}
                id={x.key}
                onClick={() => setDocType(docType === x.key ? "" : x.key)}
              />
              <label className="form-check-label cursor-pointer" for={x.key}>
                {x.name}
              </label>
            </div>
          ))}

          {/* <div className="form-check form-check-sm form-check-custom form-check-solid me-5">
            <input
              className={`form-check-input cursor-pointer`}
              type="checkbox"
              checked={docType === "other"}
              id="other"
              onClick={() => setDocType("other")}
            />
            <label className="form-check-label" for="other">
              <input
                type="text"
                placeholder="Other type"
                onFocus={() => setDocType("other")}
                className="w-100 form-control form-control-sm"
              />
            </label>
          </div> */}
        </div>
      </div>

      {/* button submit and loading */}
      <div
        className={`card-body d-flex align-items-end pt-0 mt-5 ${
          docType === "" || acceptedFilesCopy.length === 0
            ? "opacity-50"
            : !isSubmit
            ? "opacity-100 cursor-pointer"
            : "opacity-100 cursor-not-allowed"
        }`}
        onClick={() =>
          !isSubmit && docType !== "" && acceptedFilesCopy.length > 0
            ? handleSubmit()
            : null
        }
      >
        <div className="d-flex align-items-center flex-column mt-3 w-100 position-relative">
          <div
            className={`d-flex justify-content-between align-items-center mt-auto mb-2 fs-4 position-absolute ${
              progressUploading > 45 ? "text-white" : "text-white"
            } ${
              isSubmit
                ? progressUploading < 50
                  ? "text-gray-700"
                  : "text-white"
                : "text-white"
            }`}
            style={{
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            {isSubmit ? (
              <span>{progressUploading}%</span>
            ) : (
              <span>
                {isDocTypeAlreadyExistState === false ? "Submit" : "Update"}
              </span>
            )}
          </div>

          <div
            className="mx-3 w-100 bg-primary bg-opacity-25 rounded"
            style={{ height: 45 }}
          >
            <div
              className="bg-primary rounded"
              role="progressbar"
              style={{
                width: `${isSubmit ? progressUploading : 100}%`,
                height: 45,
              }}
              aria-valuenow={50}
              aria-valuemin={0}
              aria-valuemax={100}
            ></div>
          </div>
        </div>
      </div>

      {/* large preview */}
      {zoom.state && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)",
            // display: previewModal? "block" : "none",
            zIndex: 100,
          }}
        >
          <div
            style={{
              width: "90%",
              height: "90%",
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "10px",
            }}
          >
            {previewFiles || zoom.seeker === "up" ? (
              <div className="bg-gray-50 rounded-lg position-relative w-100 h-100">
                <div
                  className="cursor-pointer position-absolute d-flex align-items-center justify-content-center"
                  style={{
                    right: 10,
                    top: 10,
                    borderRadius: 49,
                    height: 40,
                    width: 40,
                    backgroundColor: "rgba(0, 0, 0, 0.5)",
                  }}
                  title="close"
                  onClick={() => {
                    setZoom({ state: false, seker: "up" });
                  }}
                >
                  <i class="ki-duotone ki-cross-circle fs-2x">
                    <span class="path1 text-white"></span>
                    <span class="path2 text-white"></span>
                  </i>
                </div>
                <div
                  className="w-100 h-100"
                  style={{ overflowY: "auto", scrollbarWidth: "none" }}
                >
                  {zoom.seeker === "up" ? (
                    Object.entries(previews).map(([fileName, preview]) => {
                      const file = { name: fileName };
                      return (
                        <PreviewComponent
                          file={file}
                          preview={preview}
                          zoom={zoom.state}
                        />
                      );
                    })
                  ) : (
                    <PreviewComponent
                      preview={previewFiles}
                      zoom={zoom.state}
                    />
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ModalUploadDoc;
