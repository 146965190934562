import {useEffect} from 'react'
import {ILayout, useLayout} from '../../core'
import PackageJSON from "../../../../../package.json"

const Footer = () => {
  const {config} = useLayout()
  useEffect(() => {
    updateDOM(config)
  }, [config])
  return (
    <>
      <div className='text-gray-900 order-2 order-md-1'>
        <span className='text-muted fw-semibold me-1'>
          {new Date().getFullYear().toString()}&copy;
        </span>
        <a
          href='https://sgu.ac.id/'
          target='_blank'
          className='text-gray-800 text-hover-primary'
        >
          Swiss German University
        </a>
        <span className="text-muted fw-semibold ms-1">
         · v{PackageJSON.version}
        </span>
      </div>

      <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
        <li className='menu-item'>
          <a href='http://helpdesk.sgu.ac.id/' target='_blank' className='menu-link px-2'>
            Help
          </a>
        </li>

        <li className='menu-item'>
          <a href='https://wa.me/message/NNSYT7PGEZM5E1' target='_blank' className='menu-link px-2'>
            Contact Us
          </a>
        </li>
      </ul>
    </>
  )
}

const updateDOM = (config: ILayout) => {
  if (config.app?.footer?.fixed?.desktop) {
    document.body.classList.add('data-kt-app-footer-fixed', 'true')
  }

  if (config.app?.footer?.fixed?.mobile) {
    document.body.classList.add('data-kt-app-footer-fixed-mobile', 'true')
  }
}

export {Footer}
