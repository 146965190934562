import { KTIcon } from "../../../../_metronic/helpers";

const icons = {
  success: "check-circle",
  danger: "cross-circle",
};

const Toaster = ({ type = "success", message, title }) => {
  return (
    <div
      className={`p-5 m-5 rounded text-white d-flex align-items-center alert alert-${type} position-fixed end-0`}
      style={{ top: 5, zIndex: 999, boxShadow: "0 3px 8px rgba(0, 0, 0, 0.3)" }}
    >
      <KTIcon iconName={icons[type]} className={`text-${type} fs-2x me-3`} />
      <div className="d-flex flex-column">
        {/* <h5 className="mb-1">{title}</h5> */}
        <span className={`text-${type} fs-5`}>{message}</span>
      </div>
    </div>
  );
};

export default Toaster;
