import React, { useEffect, useState } from "react";
import {
  ButtonIcon,
  ButtonPrimary,
} from "../../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../../_metronic/helpers";
import { InputTextName, SelectDocType } from "./FilterUI";
import { InputFileUpload, RenderFileUpload } from "./StorageUI";
import {
  MessageError,
  openModal,
} from "../../../../../_layouts/components/modals/Modals";
import { RequiredMsg } from "../../../../../_layouts/_core/ErrorMessage";
import { useProvider } from "../../../../../_layouts/UMSLayoutProvider";
import { RSAencryptData } from "../../../../../_layouts/_helpers/RSAEnc";
import { POST_REQUEST_DOC_MULTIPLE, READ_FILE } from "../../../apis/module/JupiterRequest";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import { LoadingSkeleton } from "../../../../../_layouts/components/loadbars/LoadingBars";
import { DocumentType } from "../../../components/forms/SelectBox";


export default function FormStorageFile({
  data,
  ReloadDocFile,
  setIsOpenForm,
}) {
  const is_existing =Object.values(data).length;
  const { account } = useProvider();
  const [showFileUpload, setShowFileUpload] = useState(!data);
  const [doc_type, setDoc_type] = useState(data ? data.doc_type : "");
  const [owner, setOwner] = useState(data ? data.owner : "");
  const [files, setFiles] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [images, setImages] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });

  useEffect(() => {
    if (data) {
      if (files.length === 0) setShowFileUpload(true);
      else setShowFileUpload(false);

      if(data.public_url){
        LOAD_FILE_IMG(data.public_url);
      }
    } else {
      setShowFileUpload(true);
    }
  }, [data, files]);

  useEffect(() => {
    console.log("Progress updated:", uploadProgress);
  }, [uploadProgress]);

  const LOAD_FILE_IMG = async (uniqid) => {
    const arrUniqID = uniqid.split("s/");
    const fileID = arrUniqID[1];
    setImages({ loading: true, data: [], message: "" });

    const result = await READ_FILE(`${fileID}?x=1920&y=1080`);
    if (result.data && Object.values(result.data).length > 0) {
      setImages(result);
    } else {
      setImages({
        loading: false,
        data: [],
        message: "Failed to read the file",
      });
    }
  };

  const SubmitUpload = async () => {
    if (doc_type && owner && files.length > 0) {
      let param = {
        user: account.identity.email,
        doc_type: doc_type.value,
        owner,
      };
      if(data){
        param.file_id = data.file_id
      }
      console.log("Submitting data:", param);
      
      const objData = JSON.stringify(param);
      const rsaEnc = RSAencryptData(objData);
      console.log(rsaEnc);
      setSubmit({ loading: true, data: [], message: "" });

      try {
        const uploadPromises = files.map((file) => {
          const formData = new FormData();
          formData.append("data", rsaEnc);
          formData.append("file", file);
          return POST_REQUEST_DOC_MULTIPLE(formData, (progress) => {
            console.log(`Progress for ${file.name}:`, progress);
            setUploadProgress((prev) => {
              const newProgress = { ...prev, [file.name]: progress };
              console.log("New progress state:", newProgress);
              return newProgress;
            });
          });
        });

        const results = await Promise.all(uploadPromises);

        const successfulUploads = results.filter(
          (result) => result.result === "success"
        );
        const failedUploads = results.filter(
          (result) => result.result !== "success"
        );

        if (successfulUploads.length > 0) {
          setSubmit({
            loading: false,
            data: successfulUploads,
            message: `Successfully uploaded ${successfulUploads.length} out of ${files.length} files.`,
          });
          ReloadDocFile();

          setTimeout(() => {
            setIsOpenForm({ open: false, data: [] });
          }, 2000);
        }

        if (failedUploads.length > 0) {
          setSubmit({
            loading: false,
            data: [],
            message: `Failed to upload ${failedUploads.length} files. Please try again.`,
          });
        }
      } catch (error) {
        console.error("Upload error:", error);
        setSubmit({
          loading: false,
          data: [],
          message:
            "An error occurred during the upload process. Please try again.",
        });
      }
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        right: "2%",
        width: "500px",
        zIndex: 1001,
      }}
    >
      <div className="bg-white rounded shadow">
        <div className="d-flex justify-content-between align-items-center bg-light p-5">
          <h3>New Upload File</h3>
          <ButtonIcon
            type="button"
            className="btn-sm"
            onClick={() => setIsOpenForm({ open: false, data: [] })}
            title="Close"
          >
            <KTIcon iconName="cross" className="fs-1 fw-bold text-dark" />
          </ButtonIcon>
        </div>

        <div
          style={{ minHeight: "500px" }}
          className="p-5 d-flex flex-column justify-content-between align-items-center"
        >
          <div
            className="w-100"
            style={{
              maxHeight: "450px",
              overflowX: "hidden",
              overflowY: "auto",
            }}
          >
            <DocumentType data={doc_type} setData={setDoc_type} required={true} disabled={is_existing > 0 ? true : false} />
            
            <InputTextName
              data={owner}
              setData={setOwner}
              title={"Owner File"}
              required={true}
              readonly={is_existing > 0 ? true : false}
            />

            {data && (
              (images.loading ? (
                <LoadingSkeleton />
              ) : (
                Object.values(images.data).length > 0 && (
                  <div className="border p-2 rounded bg-light mb-5">
                    <img
                      src={images.data}
                      alt={data.filename}
                      id="img-load"
                      className="w-100 rounded"
                    />
                  </div>
                )
              ))
            )}

            {showFileUpload && <InputFileUpload setData={setFiles} />}

            {files.length > 0 && (
              <RenderFileUpload
                uploadProgress={uploadProgress}
                files={files}
                setFiles={setFiles}
              />
            )}
          </div>
          <div className="w-100 pt-5">
            <div className="w-100">
              {submit.message && (
                <AlertNotif
                  messages={submit.message}
                  color={
                    Object.values(submit.data).length > 0 && submit.message
                      ? "primary"
                      : "danger"
                  }
                />
              )}
            </div>
            <div className="text-end">
              <ButtonPrimary
                type="button"
                className="btn-sm fw-bold"
                disabled={submit.loading}
                onClick={() => SubmitUpload()}
              >
                <KTIcon iconName="folder-up" className="fs-2 text-white" />
                Upload File
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
