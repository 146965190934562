import React, { useEffect, useState } from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import Chart from "chart.js/auto";
import { GET_STUDENT } from '../../../apis/node-target-data/AcademicApi';
import PackageJSON from '../../../../../../package.json'
import { SGUCard } from '../../../../../_layouts/components/cards/CardUI';
import { LoadingSkeleton } from '../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../../_layouts/components/alerts/AlertsUI';
import { GroupByKey } from '../../../../../_layouts/_helpers/Global';
import { AcadTerm } from '../../../components/forms/SelectBox';

export function IntakeStudent() {
    const actTermID = PackageJSON.system_param.ActiveAcad;
    const [term_intake_id, setTerm_intake_id] = useState(actTermID);

    const [std, setStd] = useState({ loading: false, message: "", data: [] });
    const GET_STUDENT_LOAD = async (term_id) => {
        setStd({ loading: true, message: "", data: [] })

        const param = { term_intake_id: term_id, status: "asc.student.status.active" }
        const results = await GET_STUDENT(param)

        if (results.data && Object.values(results.data).length > 0) {
            setStd({ loading: false, message: "", data: results.data });
        } else {
            setStd(results)
        }
    }

    const Reload_data = () => {
        GET_STUDENT_LOAD(term_intake_id);
    }
    useEffect(() => {
        Reload_data()
    }, [term_intake_id])

    const useDataDept = (data = {}) => {
        let cloneData = [];
        if (Object.values(std.data).length > 0) {
            //cloneData = std.data;
            std.data.sort((a, b) => (a.clu.name > b.clu.name ? 1 : -1));
            cloneData = std.data.map((v) => {
                var obj = {};
                obj.clu_id = v.clu_id;
                obj.clu_name = v.clu ? v.clu.code : v.clu_id;
                obj.clu_spec_name = v.clu_spec ? v.clu_spec.name : "";
                obj.status = v.status
                    ? v.status.name
                        ? v.status.name
                        : v.status.id
                    : "";
                obj.studentid = v.studentid;
                obj.semester = v.semester;
                obj.student_email = v.sgumail ? v.sgumail : "";
                obj.studentname = v.fullname ? v.fullname : "";
                obj.student_intake = v.term_intake ? v.term_intake.name : "";
                return obj;
            });
        }

        const arrDept = [], labelLine = [], colorChart = [];
        if (Object.values(cloneData).length > 0) {
            const arrGroupCLU = GroupByKey(cloneData, "clu_name");
            Object.keys(arrGroupCLU).map((v) => {
                labelLine.push(v);
                colorChart.push(getRandomColor());
                arrDept.push(Object.values(arrGroupCLU[v]).length);
            });
            data = {
                labels: labelLine,
                datasets: [
                    {
                        label: "Student by Program Study",
                        data: arrDept,
                        backgroundColor: colorChart,
                    },
                ],
            };
        }

        return [data];
    };

    const getRandomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const [dataDeptChart, setDataDeptChart] = useDataDept();

    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bold text-gray-800">Active Student</span>
                <span className="text-gray-500 mt-1 fw-semibold fs-6">
                    Choose the intake by <span className="text-primary">Program Degree</span>
                </span>
            </SGUCard.Header>
            <SGUCard.Body className={"pb-0"}>
                <AcadTerm data={term_intake_id} setData={setTerm_intake_id} required />
                {std.loading ? <LoadingSkeleton /> :
                    std.message ? <AlertNotif messages={std.message} color='danger' /> :
                        (Object.values(std.data).length > 0) && (
                            <div className="text-center">
                                <Pie data={dataDeptChart ? dataDeptChart : null} />
                                <p className="mt-5 fw-bolder">Total {Object.values(std.data).length} Student Active</p>
                            </div>
                        )}
            </SGUCard.Body>
        </SGUCard>
    )
}
