import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  GET_STUDENT_DATA_M,
  GET_STUDENT_STATUS,
} from "../../apis/node-target-data/AcademicApi";
import { AcademicInfo, ActivityHistory, Biodata, CardHeaderStd } from "./components";
import { SGUCardFlush } from "../../../../_layouts/components/cards/CardUI";
import { LoadingSkeleton } from "../../../../_layouts/components/loadbars/LoadingBars";
import {
  AlertRetry,
} from "../../../../_layouts/components/alerts/AlertsUI";
import { Error404 } from "../../errors/components/Error404";

export function DetailStudent() {
  let params = useParams();
  const student_id = params.student_id;

  const [stdBio, setStdBio] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [stdAcad, setStdAcad] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const navTabs = [
    {
      id: 1,
      target: "card-acad",
      name: "Academic Information",
      component: <AcademicInfo stdAcad={stdAcad.data} stdBio={stdBio.data} />,
    },
    { id: 2, target: "card-bio", name: "Biodata", component: <Biodata stdAcad={stdAcad.data} stdBio={stdBio.data} /> },
    { id: 3, target: "card-hist", name: "Activity History", component: <ActivityHistory stdAcad={stdAcad.data} stdBio={stdBio.data} /> },
  ];

  const [activeTab, setActTab] = useState(0);
  const [tabTarget, setTabTarget] = useState();


  const ReloadData = async () => {
    setStdAcad({ loading: true, data: [], message: "" });
    setStdBio({ loading: true, data: [], message: "" });

    const param = { studentid: student_id };
    const std_bio = await GET_STUDENT_DATA_M(param);
    const std_acad = await GET_STUDENT_STATUS(param);

    if (std_acad.data && std_bio.data) {
      setActTab(navTabs[0].id)
      setTabTarget(navTabs.find(tab => tab.id === activeTab));
    }
    setStdAcad(std_acad);
    setStdBio(std_bio);

  };
  useEffect(() => {
    ReloadData();
  }, [student_id]);

  useEffect(() => {
    setTabTarget(navTabs.find(tab => tab.id === activeTab));
  }, [activeTab])

  return (
    <div id="student-detail">
      {stdAcad.loading || stdBio.loading ? (
        <SGUCardFlush>
          <SGUCardFlush.Body>
            <LoadingSkeleton />
          </SGUCardFlush.Body>
        </SGUCardFlush>
      ) : stdAcad.message || stdBio.message ? (
        <div className="w-400px m-auto">
          <AlertRetry
            messages={stdAcad.message || stdBio.message}
            Reload={ReloadData}
          />
        </div>
      ) : Object.values(stdBio.data).length > 0 &&
        Object.values(stdAcad.data).length > 0 ? (
        <div>
          <SGUCardFlush>
            <SGUCardFlush.Body className={"px-0 pb-0"}>
              <CardHeaderStd bio={stdBio.data} acad={stdAcad.data} activeTab={activeTab} setActTab={setActTab} />
              {tabTarget && (
                <>
                  <div className="separator"></div>
                  <div className="px-8">
                    <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                      {navTabs.map((v, index) => (
                        <li className="nav-item" key={index}>
                          <a
                            target={`#${v.target}`}
                            data-bs-toggle="tab"
                            className={
                              "cursor-pointer nav-link text-active-primary py-5 me-6 " +
                              (activeTab === v.id && "active")
                            }
                            onClick={() => setActTab(v.id)}
                          >
                            {v.name}
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </>
              )}
            </SGUCardFlush.Body>
          </SGUCardFlush>

          {tabTarget && (
            <div className="">
              <div className="tab-content">
                <div
                  className="tab-pane blockui fade show active"
                  id={"card-" + tabTarget.target}
                >
                  {tabTarget.component}
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <Error404 />
      )}
    </div>
  );
}
