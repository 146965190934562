import { doc_type } from "../../../../../_layouts/_core/ConstantData";

const SelectDocType = ({ data, setData, required, readonly }) => {
  return (
    <div className="form-group mb-5">
      <label className={"form-label fw-bold "+(required && "required")}>Document Type</label>
      <select
        name="doc_type"
        disabled={readonly}
        defaultValue={data}
        className="form-select form-select-sm"
        onChange={(e) => setData(e.target.value)}
      >
        <option value="">Choose One</option>
        {doc_type.map((v, index) => (
          <option key={index} value={v.id}>
            {v.name}
          </option>
        ))}
      </select>
    </div>
  );
};

const InputTextName = ({ data, setData, title, required, readonly }) => {
  return (
    <div className="form-group mb-5">
      <label className={"form-label fw-bold "+(required && "required")}>{title}</label>
      <input
        type="text"
        className="form-control form-control-sm"
        name="name"
        readOnly={readonly}
        autoComplete="off"
        defaultValue={data}
        onChange={(e)=>setData(e.target.value)}
      />
    </div>
  );
};

export { SelectDocType, InputTextName };
