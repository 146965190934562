const ColorStatusIcon = (data) => {
  let color = "";
  let icon = "";
  if (data.status_file === "uploaded" || data.status_file === "updated") {
    color = "primary";
  } else {
    color = "danger";
  }
  if (data.mimetype === "application/pdf" && (data.status_file === "uploaded" || data.status_file === "updated")) {
    icon = "document";
  } else if ( data.mimetype.includes("image") && (data.status_file === "uploaded" || data.status_file === "updated")) {
    icon = "picture";
  } else if (data.status_file === "deleted") {
    icon = "file-deleted";
  } else {
    icon = "file";
  }

  return {color, icon}
};


export {ColorStatusIcon}