import React, { useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FilterCalendar } from '../components'
import moment from 'moment';
import { MessageError, openModal } from '../../../../_layouts/components/modals/Modals';

export default function Filter({ isload, setPostParam }) {
  const currentDate = moment().toDate();
  const [start_dt, setStart_dt] = useState(currentDate);
  const [end_dt, setEnd_dt] = useState(currentDate);

  const HandlerFilter = (e) => {
    e.preventDefault();
    if (start_dt && end_dt) {
      setPostParam((prev)=>{
        const data = {...prev};
        data.start_dt = moment(start_dt).format("YYYY-MM-DD HH:mm");
        data.end_dt = moment(end_dt).format("YYYY-MM-DD HH:mm");
        return data;
      })
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  }

  const HandlerEndDate = (date) => {
    if (date < start_dt) {
      openModal({
        message: <MessageError message={"End date is bigger than start date."} />,
      });
      setEnd_dt(start_dt);
    } else {
      setEnd_dt(date);
    }
  }
  return (
    <FilterCalendar onSubmit={HandlerFilter} isload={isload}>
      <div className="mb-8 me-3">
        <label className="fw-bold mb-2 required d-block">
          Start Date
        </label>
        <DatePicker
          className="form-control form-control-sm pe-10"
          dateFormat={"yyyy-MM-dd hh:mm aa"}
          isClearable
          showTimeSelect
          timeIntervals={10}
          placeholderText="Enter date"
          selected={start_dt}
          onChange={(date) => {setStart_dt(date); setEnd_dt(date);}}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
      </div>
      <div className="mb-8 me-3">
        <label className="d-block fw-bold mb-2 required">
          End Date
        </label>
        <DatePicker
          className="form-control form-control-sm pe-10"
          dateFormat={"yyyy-MM-dd hh:mm aa"}
          isClearable
          showTimeSelect
          placeholderText="Enter date"
          selected={end_dt}
          onChange={(date) => HandlerEndDate(date)}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
        />
      </div>
    </FilterCalendar>
  )
}
