import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  PaginationComponent,
  SearchBarTable,
} from "../../../../_layouts/components/datatables/MYDatatables";
import { ButtonIcon, ButtonPrimary, ButtonTersier } from "../../../../_layouts/components/buttons/ButtonUI";
import { EnrollmentStdType, StudentIDFormat } from "../../../../_layouts/_helpers/Global";
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function TableData({ data, ReloadData, paramST }) {
  const navigate = useNavigate();
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: "No", field: "studentid", sortable: false },
    { name: "Student Name", field: "student_name", sortable: true },
    { name: "Department", field: "clu_name", sortable: true },
    { name: "Semester", field: "student_semester", sortable: true },
    { name: "Intake/Enrollment", field: "intake", sortable: true },
    { name: "Action", field: "student_id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (Object.values(data).length > 0) {
      computedData = data.map((c) => {
        var enrollType = "";
        if(c.enrollment_type_id){
          enrollType = EnrollmentStdType(c.enrollment_type_id);
        }
        var obj = {};
        obj.clu_name = c.clu ? c.clu.name || "" : "";
        obj.clu_spec_name = c.clu_spec ? c.clu_spec.name || "" : "";
        obj.student_id = c.studentid ? c.studentid : "";
        obj.student_name = c.fullname ? c.fullname : "-";
        obj.student_semester = c.semester ? c.semester.toString() : "";
        obj.sgumail = c.sgumail ? c.sgumail : "";
        obj.is_active = c.is_active ? c.is_active : "";
        obj.curriculum = c.curr ? c.curr.name || "" : "";
        obj.status = c.status
          ? c.status.name
            ? c.status.name
            : c.status.id
          : "";
        obj.intake = c.term_intake ? c.term_intake.name || "" : "";
        obj.atp_term_name = c.atp_term ? c.atp_term.name || "" : "";
        obj.enrollmentType = enrollType;
        obj.corporate = c.corporate ? c.corporate.name || "" : "";
        obj.cgpa = c.cgpa ? c.cgpa.toString() : "0";
        obj.ccredit_gpa = c.ccredit_gpa ? c.ccredit_gpa.toString() : "";
        return obj;
      });

      computedData.sort((a, b) => (a.student_name > b.student_name ? 1 : -1));
    }

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key]
            .toString()
            .toLowerCase()
            .includes(search)
        );
      });
    }
    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [data, search, sorting, currentPage]);

  const BtnAction = ({data}) =>{
    if((data.enrollmentType !== "Transfer") && (data.enrollmentType !== "cooperation")){
      return (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          rootClose
          placement="top"
          trigger="click"
          overlay={
            <Popover className="p-3">
              <Popover.Body className="p-0">
                <TooltipChoosen data={data} />
              </Popover.Body>
            </Popover>
          }
        >
          <button
            type="button"
            className="btn btn-icon btn-sm"
          >
            <i className="ki-outline ki-arrow-right fs-5 m-0"></i>
          </button>
        </OverlayTrigger>
      );
    }else{
      const postData = {student_id:data.student_id, is_transfer:1};
      return (
        <ButtonIcon type="button" className="btn-sm" onClick={()=>{navigate(`${btoa(JSON.stringify(postData))}`, {state: {param: paramST}})}} title="View">
            <i className="ki-outline ki-arrow-right fs-5 m-0"></i>
        </ButtonIcon>
      )
    }
  }

  const TooltipChoosen = ({data}) =>{
    const HandlerAction = (value) =>{
      let param = {student_id:data.student_id, is_transfer:value};  
      navigate(`${btoa(JSON.stringify(param))}`, {state: {param: paramST}})
    }
    return (
      <div className="p-2 text-center">
        <p className="text-dark fw-bold fs-5">Is student transfer ?</p>
        <div className="btn-group">
          <ButtonTersier className="btn-sm fw-bold" type="button" onClick={()=>HandlerAction(0)}>
            No
          </ButtonTersier>
          <ButtonPrimary type="button" className="btn-sm fw-bold" onClick={()=>HandlerAction(1)}>
            Yes
          </ButtonPrimary>
        </div>
      </div>
    )
  }

  return (
    <div id="table-data">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex">
          <div className="mx-2">
            <ButtonItemRefresh
              totalItems={totalItems}
              onClick={() => ReloadData()}
            />
          </div>
        </div>
      </div>

      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {ResultData.length > 0 ? (
              ResultData.map((v, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <span className="fw-bold d-block">{v.student_name}</span>
                    <span className="text-primary">{StudentIDFormat(v.student_id)}</span>
                  </td>
                  <td>
                    {v.corporate !== "SGU" && (
                      <span className="badge badge-light">{v.corporate}</span>
                    )}
                    <span className="fw-bold d-block">{v.clu_name}</span>
                    <span className="text-muted fs-8">{v.clu_spec_name}</span>
                  </td>
                  <td>
                    <span className="fw-bold d-block">
                      Semester {v.student_semester}
                    </span>
                    <span className="badge badge-light">CGPA {v.cgpa}</span>
                  </td>
                  <td>
                    <span className="fw-bold fs-7 d-block">{v.intake}</span>
                    <span className="badge badge-light-primary text-capitalize">{v.enrollmentType}</span>
                  </td>
                  <td>
                  <BtnAction data={v} />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No record found</td>
              </tr>
            )}
          </tbody>
        </table>

        {totalItems > 0 && (
          <div className="footer">
            <PaginationComponent
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
