import React, { useMemo, useState } from "react";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import { SectionLabel } from "../../components/labeling/SectionUI";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  SearchBarTable,
} from "../../../../_layouts/components/datatables/MYDatatables";
import { useOutletContext } from "react-router-dom";
import { LoadingSkeleton } from "../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import {
  ButtonPrimary,
  ButtonTersier,
} from "../../../../_layouts/components/buttons/ButtonUI";
import { openModal } from "../../../../_layouts/components/modals/Modals";
import { ButtonCreateSection } from "./components/ButtonCreate";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";

export default function TableData({
  results,
  paramTL,
  ReloadData,
  ChangeLayout,
  MsStoneIsOpen
}) {
  const context = useOutletContext();
  const widget_8 = context.widgets.find(
    (item) => item.widget_id === 8 && item.is_enabled === 1
  );

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [totalItems, setTotalItems] = useState(0);

  const headers = [
    { name: "Course", field: "coursetitle", sortable: true },
    { name: "Credit & Type", field: "course_credit", sortable: true },
    { name: "Sections", field: "section", sortable: false },
    { name: "Actions", field: "section", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];

    if (results.data) {
      computedData = results.data.map((c) => {
        var obj = {};
        obj.course_id = c.course ? c.course.id : "-";
        obj.coursetitle = c.course
          ? c.course.coursetitle
            ? c.course.coursetitle
            : "-"
          : "-";
        obj.course_code = c.course ? c.course.code : "-";
        obj.course_lesson = c.course ? c.course.course_lesson : "-";
        obj.course_credit = c.course
          ? c.course.credit
            ? c.course.credit.toString()
            : "-"
          : "-";
        obj.course_type = c.course ? c.course.course_type : "-";
        obj.offering_type = c.offering_type ? c.offering_type : "";
        obj.section = c.section ? c.section : "";
        return obj;
      });
    }

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key]
            .toString()
            .toLowerCase()
            .includes(search)
        );
      });
    }
    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedData;
  }, [results.data, search, sorting]);

  const rowCourses = (obj) => {
    paramTL.curr_group_type = paramTL.curr_group;
    obj.paramTL = paramTL;
    return (
      <div className="position-relative py-2">
        <span className="mb-1 text-dark fw-bolder">{obj.coursetitle}</span>
        <div className="fs-7 text-muted fw-bolder">{obj.course_code}</div>
      </div>
    );
  };

  const rowOffer = (obj) => {
    let courseTypeArr = obj.course_type.split(".");
    let courseType = courseTypeArr[courseTypeArr.length - 1];
    return (
      <div className="offer">
        <div className="text-dark fw-bolder d-block fs-6 text-capitalize">
          <span className="text-muted fs-7">{courseType}</span>
        </div>
      </div>
    );
  };

  const rowSection = (obj) => {
    const sections = obj.section;
    const total = Object.values(sections).length;

    const totalshow = 3;
    const dataToShow = sections.slice(0, totalshow);

    return (
      <div>
        {total > 1 && (
          <p className="mb-0 fs-7 fw-bold">
            Have <span className="text-danger"> {total} </span> section:
          </p>
        )}
        {total > 0 && (
          <div className="row mb-1">
            {dataToShow.map((v, index) => (
              <div className="col-auto my-1" key={index}>
                <SectionLabel item={v} />
              </div>
            ))}
          </div>
        )}
        {total > totalshow && (
          <ButtonTersier
            className="btn-sm btn-active-light-primary"
            type="button"
            onClick={() =>
              openModal({
                header: <>{obj.coursetitle + "/" + obj.course_code}:</>,
                message: <DetailSection data={sections} />,
              })
            }
          >
            <i className="ki-outline ki-eye fs-5 m-0"></i>
            View more
          </ButtonTersier>
        )}
      </div>
    );
  };

  const DetailSection = ({ data }) => {
    return (
      <ol>
        {data.map((v, index) => (
          <li key={index}>
            <span className="fw-bold fs-5">
              {v.section_id} ·{" "}
              <span className="text-muted">
                Max Seat: {v.max_seat} · Max Session: {v.section_session}
              </span>
            </span>
          </li>
        ))}
      </ol>
    );
  };

  const ButtonOpenCatalog = () => {
    return (
      <ButtonPrimary
        className="btn-sm ms-1 fw-bold btn-info"
        type="button"
        onClick={() => ChangeLayout("LookUpCourseCatalog")}
      >
        <i className="ki-outline ki-book fs-5 m-0"></i>
        Open Course Catalog
      </ButtonPrimary>
    );
  };

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bold text-gray-800">
          Course Section & Teaching Assignments
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        {(MsStoneIsOpen && ResultData.length > 0) && <ButtonOpenCatalog />}
      </SGUCard.Toolbar>
      <SGUCard.Body>
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <SearchBarTable
            size="w-250px"
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="d-flex">
            <ButtonItemRefresh
              totalItems={totalItems}
              onClick={() => ReloadData(RemoveEmptyValue(paramTL))}
            />
          </div>
        </div>

        <div className="table-responsive">
          <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
            <HeaderDatatables
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {results.loading ? (
                <tr>
                  <td colSpan={4}>
                    <LoadingSkeleton />
                  </td>
                </tr>
              ) : results.message ? (
                <tr>
                  <td colSpan={4}>
                    <AlertNotif messages={results.message} color="danger" />
                  </td>
                </tr>
              ) : ResultData.length > 0 ? (
                ResultData.map((listData, index) => (
                  <tr key={index} className={index % 2 === 0 ? "odd" : "even"}>
                    <td className="w-300px">{rowCourses(listData)}</td>
                    <td>
                      <div className="fw-bolder">
                        {listData.course_credit} SKS
                      </div>
                      {rowOffer(listData)}
                    </td>
                    <td>{rowSection(listData)}</td>
                    <td width={"20%"}>
                      {(widget_8 && MsStoneIsOpen) && <ButtonCreateSection param={listData} />}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    <p>No record found</p>
                    <ButtonOpenCatalog />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </SGUCard.Body>
    </SGUCard>
  );
}
