import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { IconSearch } from '../../../../../_layouts/components/images/Others';
import { LoadingBars } from '../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../../_layouts/components/alerts/AlertsUI';
import { GET_ROOM_CONFLICT } from '../../../apis/node-target-data/AcademicApi';

export function WRoomConflict() {
    const [roomConflict, setRoom] = useState({ loading: false, message: "", data: [] });
    const ReloadData = async () => {
        setRoom({ loading: true, message: "", data: [] })
        const result = await GET_ROOM_CONFLICT()
        setRoom(result)
    }
    useEffect(() => {
        ReloadData()
    }, [])
    return (
        <div>
            {roomConflict.loading ? (
                <LoadingBars />
            ) : roomConflict.message ? (
                <AlertNotif messages={roomConflict.message} color={"danger"} />
            ) : Object.values(roomConflict.data).length > 0 && (
                <Link to={{ pathname: "/reporting/room-conflict" }} >
                    <div className="card cursor-pointer mb-5" style={{ backgroundColor: "#f1416c" }} >
                        <div className="card-body d-flex ps-xl-15">
                            <div className="info">
                                <div className="position-relative fs-2x z-index-2 fw-bolder text-white">
                                    <span className="me-2">
                                        There are <span className="position-relative d-inline-block text-primary me-2">{Object.values(roomConflict.data).length} classes</span>
                                        with <span className="text-primary">room conflict</span>.
                                    </span>
                                    <span className="d-block">Click here to view.</span>
                                </div>
                            </div>
                            <IconSearch className="position-absolute me-3 bottom-0 end-0 h-200px" />
                        </div>
                    </div>
                </Link>
            )}
        </div>
    )
}
