import { useState } from "react";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import { ButtonPrimary, ButtonTersier } from "../../../../../_layouts/components/buttons/ButtonUI";
import { MessageError, MessageSuccess, openModal } from "../../../../../_layouts/components/modals/Modals";
import { SCR_SET_INCOMPLETE_BY_LEC } from "../../../apis/node-target-data/AcademicApi";

const ConfirmLeavePage = ({ SUBMIT_SCORE, param, wg_com_id, section_id }) => {
  const HadnlerSaveChanges = () => {
    var objScr = [];
    param.map((v) => {
      if (v.atd < 25) {
        if (Object.values(v.student_course_com).length > 0) {
          const getScore = v.student_course_com.find(
            (prev) => prev.wg_com.id === wg_com_id
          );
          objScr.push({
            studentid: v.studentid.toString(),
            score: typeof 0 === getScore.score ? 0 : getScore.score,
          });
        }
      }
    });
    let objParam = {
      section_id: section_id,
      wg_com_id: wg_com_id,
      student: objScr,
    };
    const isScoreNull = objParam.student.find((item) => item.score === "");
    if (isScoreNull) {
      openModal({
        header: "Error",
        message: (
          <MessageAlertScore
            message={
              "Score cannot be empty. All fields must be filled before saving."
            }
          />
        ),
      });
    } else {
      openModal({ open: false });
      SUBMIT_SCORE(objParam);
    }
  }
  return (
    <div className="text-center">
      <h4>
        All unsaved information will be lost. Do you want to save your changes?
      </h4>
      <div className="btn-group mt-5">
        <ButtonTersier
          className="btn-lg"
          type="button"
          onClick={() => openModal({ open: false })}
        >
          No
        </ButtonTersier>
        <ButtonPrimary
          onClick={() => HadnlerSaveChanges()}
          className="btn-lg"
        >
          Yes
        </ButtonPrimary>
      </div>
    </div>
  );
};

const ConfirmStatusScore = ({ student, id }) => {
  const [results, setResult] = useState({loading:false, message:"", data:[]});

  const HandlerIncomplete = async (id) => {
    setResult({loading:true, message:"", data:[]})
    const param = { scr_id: parseInt(id) };
    const result = await SCR_SET_INCOMPLETE_BY_LEC(param);
    if(result.data && Object.values(result.data).length > 0){
      openModal({message:<MessageSuccess message={"Successfully updated"} />});
      setResult({loading:false, message:"", data:result.data});
    }else{
      openModal({message:<MessageError message={result.message} />});
      setResult(result);    
    }
  }

  return (
    <div className="text-center">
      <AlertNotif color="info" messages={"This action will exclude the course from the student's CGPA, GPA, and cumulative earned credits calculation."} />
      <h3 className="my-10">
        <span>
          Are you sure you want to change the Final Result status for <span className="text-info">{student.fullname}</span> to <span className="text-danger">Incomplete</span> ?
        </span>
      </h3>

      <div className="btn-group">
        <ButtonTersier className="btn-lg" onClick={() => openModal({ open: false })} >
          Cancel
        </ButtonTersier>
        <ButtonPrimary className="btn-lg btn-danger" disabled={results.loading} onClick={() => HandlerIncomplete(id)} >
          {results.loading ? "Processing...":"Set to Incomplete"}
        </ButtonPrimary>
      </div>
    </div >
  )
}
export { ConfirmLeavePage, ConfirmStatusScore };
