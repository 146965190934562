import React, { useRef, useState } from 'react'
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import moment from 'moment';
import { EventRender, PrevNextMonthButton } from './components/CalendarCompImDt';

export function ImDtCalendarMonth({ data }) {
    const calendarRef = useRef();
    const startOfMonth = moment()
        .startOf("month")
        .format("YYYY-MM-DD");
    const endOfMonth = moment()
        .endOf("month")
        .add(1, "days")
        .format("YYYY-MM-DD");

    const [postParam, setPostParam] = useState({ tgl: startOfMonth, tglend: endOfMonth });
    return (
        <div>
            <div className="d-flex align-items-center justify-content-between px-8">
                <div className="notes d-flex">
                    <div className="d-flex">
                        <div className="w-20px bg-light-danger rounded"></div>
                        <div className='text-danger ms-1'>Done</div>
                    </div>
                    <div className="d-flex mx-2">
                        <div className="w-20px bg-light-success rounded"></div>
                        <div className='text-success ms-1'>Scheduled</div>
                    </div>
                </div>
                <div>
                    <PrevNextMonthButton calendarRef={calendarRef} postParam={postParam} setPostParam={setPostParam} />
                </div>
            </div>
            <FullCalendar
                ref={calendarRef}
                defaultView="dayGridMonth"
                headerToolbar={{ start: '', center: '', end: '' }}
                initialDate={postParam.tglend}
                validRange={{ start: postParam.tgl, end: postParam.tglend }}
                plugins={[dayGridPlugin]}
                events={data}
                eventContent={(info) => EventRender(info)}
                eventColor='#3788d8'
                eventTextColor='black'
                showNonCurrentDates={true}
                eventDidMount={(info) => {
                    if (info.event.extendedProps.background) {
                        info.el.style.background = info.event.extendedProps.background;
                    }
                    if (info.event.extendedProps.border) {
                        info.el.style.borderColor = info.event.extendedProps.border;
                    }
                }}
            />
        </div>
    )
}
