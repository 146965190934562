import ExcelJs from "exceljs";

const SheetStudentBody = (wb, param) => {
    const tableHeader = [
    { key: "A", title: "No", width: 5 },
    { key: "B", title: "Student ID", width: 20 },
    { key: "C", title: "Student Name", width: 30 },
    { key: "D", title: "Email", width: 25 },
    { key: "E", title: "Department", width: 40 },
    { key: "F", title: "Specialization", width: 40 },
    { key: "G", title: "Intake", width: 40 },
    { key: "H", title: "Enrollment Type", width: 40 },
    { key: "I", title: "Status", width: 15 }];

    const headers = tableHeader;
    const ws = wb.addWorksheet("Student Information");
    const cloneHeader = [...headers];
    const newHeader = cloneHeader.map((obj) => {
        //delete obj.title;
        var x = {};
        x.key = obj.key;
        x.width = obj.width;
        return x;
    });
    ws.columns = newHeader;

    ws.getRow(1).getCell("A").value = "Student Information - Total "+(Object.values(param).length);
    ws.getRow(1).getCell("A").font = {
        bold: true,
        size: 16,
    };

    let rowNumber = 3;
    const rowHeader = ws.getRow(rowNumber);
    tableHeader.map((v, index) => {
        var i = index + 1;
        rowHeader.getCell(i).value = v.title;
        rowHeader.getCell(i).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
        }
        rowHeader.getCell(i).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "191970" },
        };
        rowHeader.getCell(i).alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        rowHeader.getCell(i).font = {
            bold: true,
            size: 11,
            color: { argb: "FFFFFF" },
        };
    })

    //BODY - PARAMETER
    rowNumber = rowNumber + 1;
    var num = 1;
    param.map((v) => {
        if (!v.section_id) {
            const row = ws.getRow(rowNumber);
            var enrollType = "";
            if(v.enrollment_type_id){
                var enrollment = v.enrollment_type_id;
                var enrollmentArr = enrollment.split(".");
                enrollType = enrollmentArr[Object.values(enrollmentArr).length - 1]
            }
            row.getCell(1).value = (num++);
            row.getCell(2).value = (v.studentid) ? v.studentid : '';
            row.getCell(3).value = (v.fullname) ? v.fullname : "";
            row.getCell(4).value = (v.sgumail) ? v.sgumail : "";
            row.getCell(5).value = (v.clu) ? (v.clu.name ? v.clu.name : "") : "";
            row.getCell(6).value = (v.clu_spec) ? (v.clu_spec.name ? v.clu_spec.name : "") : "";
            row.getCell(7).value = (v.term_intake) ? (v.term_intake.name ? v.term_intake.name : "") : "";
            row.getCell(8).value = enrollType;
            row.getCell(9).value = (v.status) ? (v.status.name ? v.status.name : v.status.id) : "";
            rowNumber++;
        }
    });
    //END BODY - PARAMETER
}

const DownloadStd = (obj, myfilename) => {
    const wb = new ExcelJs.Workbook();
    wb.creator = "UMS-SGU";
    wb.lastModifiedBy = "UMS-SGU-System";
    wb.created = new Date();
    wb.modified = new Date();
    wb.lastPrinted = new Date();

    SheetStudentBody(wb, obj, myfilename);

    const writeFile = (fileName, content) => {
        const link = document.createElement("a");
        const blob = new Blob([content], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        link.download = fileName;
        link.href = URL.createObjectURL(blob);
        link.click();
    };

    wb.xlsx.writeBuffer().then((buffer) => {
        writeFile(myfilename, buffer);
    });
}

export default DownloadStd;