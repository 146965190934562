import { useNavigate, useOutletContext } from "react-router-dom";
import Select from "react-select";
import { debounce } from "lodash";
import {
  ButtonLong,
  ButtonPrimary,
  ButtonTersier,
} from "../../../../../_layouts/components/buttons/ButtonUI";
import { openModal } from "../../../../../_layouts/components/modals/Modals";
import { useCallback, useEffect, useRef, useState } from "react";
import { StudentMultiple } from "../../../components/forms/SelectBox";
import { KTIcon } from "../../../../../_metronic/helpers";
import { GET_ENROLLMENT_PROCESS } from "../../../apis/node-target-data/MarketingAPI";
import { LoadingBars, SpinnerLoad } from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif, AlertRetry } from "../../../../../_layouts/components/alerts/AlertsUI";
import { CapitalizeFirstLetter, GroupByKey, StudentIDFormat } from "../../../../../_layouts/_helpers/Global";
import moment from "moment";
import { GET_STUDENT } from "../../../apis/node-target-data/AcademicApi";
import { GET_UNIV_LOOKUP, GET_UNIV_PRODI_LOOKUP } from "../../../apis/node-target-data/IdentityApi";
import { InputAutoFillIn } from "../../../components/forms/InputText";

const InfoCourse = ({ data }) => {
  // let course_type = "";
  // if(data.course_type){
  //   const course_type_arr = data.course_type.split(".");
  //   course_type = course_type_arr[course_type_arr.length - 1];
  // }

  return (
    <div>
      <span className="fw-bold mb-1 fs-8 d-block">{data.coursetitle}</span>
      <div className="fs-8">
        <span className="text-muted">{data.code}</span>
        {/* <span className="mx-1">·</span>
        <span className="text-muted text-capitalize">{course_type}</span> */}
      </div>
    </div>
  );
};

const SearchBarTable = (props) => {
  const { size, ...others } = props;
  return (
    <div
      className={
        "d-flex align-items-center mb-3 mb-lg-0 bg-white border rounded " +
        (size ? size : "w-50")
      }
    >
      <input
        type="text"
        className="form-control form-control-sm form-control-flush"
        placeholder="Type here Course Code or Course title"
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
          }
        }}
        {...others}
      />
      <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect
            opacity="0.5"
            x="17.0365"
            y="15.1223"
            width="8.15546"
            height="2"
            rx="1"
            transform="rotate(45 17.0365 15.1223)"
            fill="currentColor"
          ></rect>
          <path
            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
            fill="currentColor"
          ></path>
        </svg>
      </span>
    </div>
  );
};

const WindowSuccessPCF = ({ totalCourse, setCourseData, Back2Page }) => {
  //const navigate = useNavigate();
  const HandlerBack = (type) => {
    if (type === 1) {
      //navigate("/students/matriculation-data-submission");
      Back2Page();
    } else {
      setCourseData([]);
    }
    sessionStorage.removeItem("courseData");
    openModal({ open: false });
  };

  const [formstudent, setFormStudent] = useState(false);

  return !formstudent ? (
    <div className="text-center py-8">
      <div className="icon mb-5">
        <i
          className="bi bi-check-circle text-success"
          style={{ fontSize: "6em" }}
        ></i>
      </div>
      <h1 className="text-success mb-5">Successfully saved</h1>
      <p className="mb-10 fs-4">
        Would you like to <b>copy</b>{" "}
        <span className="text-primary">{totalCourse} subject</span> for{" "}
        <b>other students</b>?
      </p>
      <div className="text-center">
        <ButtonLong
          type="button"
          className="btn btn-lg btn-light fw-bold"
          onClick={() => HandlerBack(2)}
        >
          No, I would like to continue filling it in
        </ButtonLong>
        <ButtonLong
          type="button"
          className="btn btn-primary btn-lg fw-bold my-5"
          onClick={() => setFormStudent(true)}
        >
          Yes, i would like to copy for other students
        </ButtonLong>

        <ButtonTersier
          type="button"
          className="btn-lg btn-light-success fw-bold"
          onClick={() => HandlerBack(1)}
        >
          <div className="d-flex align-items-center">
            <KTIcon iconName="verify" className="fs-1" />
            <span className="ms-2">Finish</span>
          </div>
        </ButtonTersier>
      </div>
    </div>
  ) : (
    <FormStudent />
  );
};

const WindowSuccessPCFOri = ({ setCourseData, Back2Page }) => {
  const HandlerBack = (type) => {
    if (type === 1) {
      Back2Page();
    } else {
      setCourseData([]);
    }
    sessionStorage.removeItem("courseData");
    openModal({ open: false });
  };

  return (
    <div className="text-center py-8">
      <div className="icon mb-5">
        <i
          className="bi bi-check-circle text-success"
          style={{ fontSize: "6em" }}
        ></i>
      </div>
      <h1 className="text-success mb-5">Successfully saved</h1>
      <div className="text-center">
        <ButtonLong
          type="button"
          className="btn btn-lg btn-light fw-bold mb-5"
          onClick={() => HandlerBack(2)}
        >
          No, I would like to continue filling it in
        </ButtonLong>

        <ButtonTersier
          type="button"
          className="btn-lg btn-light-primary fw-bold"
          onClick={() => HandlerBack(1)}
        >
          <div className="d-flex align-items-center">
            <KTIcon iconName="verify" className="fs-1" />
            <span className="ms-2">Finish</span>
          </div>
        </ButtonTersier>
      </div>
    </div>
  );
};

const FormStudent = () => {
  const navigate = useNavigate();
  const [students, setStudents] = useState([]);
  const [is_transfer, setIsTransfer] = useState(0);
  const HandlerGotoStudent = () => {
    if (Object.values(students).length === 1) {
      const student_id = students[0].value;
      const postData = {
        student_id: student_id,
        is_transfer: parseInt(is_transfer),
      };
      navigate(
        "/students/matriculation-data-submission/" +
        btoa(JSON.stringify(postData))
      );
      openModal({ open: false });
    } else {
      alert("Only for one student?");
    }
  };

  return (
    <div className="find-student">
      <h3>Choose student for copy the subject</h3>
      <div>
        <StudentMultiple data={students} setData={setStudents} required />
        <div className="form-group">
          <label className="fw-bold required">Is Transfer</label>
          <select
            name="is_transfer"
            className="form-select form-select-sm"
            defaultValue={is_transfer}
            onChange={(e) => setIsTransfer(e.target.value)}
          >
            <option value="0">NO</option>
            <option value="1">YES</option>
          </select>
        </div>

        <div className="text-center mt-8">
          <ButtonTersier
            type="button"
            className="btn-lg fw-bold me-3"
            onClick={() => openModal({ open: false })}
          >
            Cancel
          </ButtonTersier>
          <ButtonPrimary
            type="button"
            className="btn-lg fw-bold"
            onClick={() => HandlerGotoStudent()}
          >
            Submit
          </ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

const CandidateSelect = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
  showtitle = true,
}) => {
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState(null);

  const context = useOutletContext();
  const widget_111 = context.widgets.find(
    (item) => item.widget_id === 111 && item.is_enabled === 1
  );

  const FindDataDebounced = useCallback(
    debounce(async (param, paramstd) => {
      try {
        await ReloadData(param);
        if (widget_111 && paramstd)
          await ReloadDataStudent(paramstd);
      } catch (error) {
        console.error("Error in FindDataDebounced:", error);
      }
    }, 500),
    []
  );

  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({ ...provided, zIndex: 9999 }),
  };

  const ReloadData = async (param) => {
    setResult({ loading: true, message: "", data: [] });
    setOptions([]);
    const result = await GET_ENROLLMENT_PROCESS(param);

    if (result.data && Object.values(result.data).length > 0) {
      const only_transfer = result.data.filter(
        (v) =>
          v.enrollment_type_id === "mkt.enrollment.type.cooperation" ||
          v.enrollment_type_id === "mkt.enrollment.type.transfer.in.external" ||
          v.enrollment_type_id === "mkt.enrollment.type.learingprogram"
      );

      const grouped_transfer = only_transfer.reduce((acc, current) => {
        if (!acc[current.candidate_id] || current.id > acc[current.candidate_id].id) {
          acc[current.candidate_id] = current;
        }
        return acc;
      }, {});
      const grouped_transfer_array = Object.values(grouped_transfer);

      const option_result = grouped_transfer_array.map((v) => ({
        value: v.candidate_id,
        label:
          StudentIDFormat(v.candidate_id) +
          " · " +
          (v.regstudent && (v.regstudent.fullname || "")),
      }));
      setOptions(option_result);
      if (data) {
        const findSelected = option_result.find((item) => item.value === data);
        setSelectedValue(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
      setOptions([]);
    }
  };

  const ReloadDataStudent = async (param) => {
    setResult((prevResult) => ({ ...prevResult, loading: true, message: "" }));
    const result = await GET_STUDENT(param);

    if (result.data && Object.values(result.data).length > 0) {
      const camel_cases = result.data.map((student) => {
        student.fullname = student.fullname
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
        return student;
      })
      const option_result = camel_cases.map((v) => ({
        value: v.studentid,
        label:
          StudentIDFormat(v.studentid) +
          " · " + v.fullname,
      }));

      setResult((prevResult) => ({
        ...prevResult,
        loading: false,
        data: [...prevResult.data, ...result.data],
      }));

      setOptions((prevOptions) => {
        const newOptions = [...prevOptions, ...option_result];
        // Remove duplicates based on value
        return newOptions.filter((option, index, self) =>
          index === self.findIndex((t) => t.value === option.value)
        );
      });

      if (data) {
        const findSelected = option_result.find((item) => item.value === data);
        setSelectedValue(findSelected);
      }
    }else{
      setResult((prevResult) => ({
       ...prevResult,
        loading: false,
        message: "",
      }));
    }
  };

  const FindData = (inputValue) => {
    const keyword = inputValue.replace(/-/g, '').trim();
    setInputValue(keyword);
    if (keyword.length >= 3) {
      let param, paramstd;
      if (!isNaN(keyword) && keyword.trim() !== "") {
        param = { candidate_id: keyword };
        paramstd = { studentid: keyword }
      } else {
        param = { candidatename: keyword };
        paramstd = { studentname: keyword, status: "asc.student.status.active" };
      }
      FindDataDebounced(param, paramstd);
    } else {
      setOptions([]);
    }
  };

  const findOptionByValue = useCallback(
    (value) => {
      return options.find((option) => option.value === value);
    },
    [options]
  );

  const handleSelected = (selected) => {
    setData(selected ? selected.value : null);
    setSelectedValue(selected);
    setInputValue("");
  };

  useEffect(() => {
    if (data) {
      //FindData(data);
      FindDataDebounced({ candidate_id: data }, {studentid: data});
    }
  }, [data]);

  useEffect(() => {
    if (data && options.length > 0) {
      const option = findOptionByValue(data);
      if (option) {
        setSelectedValue(option);
      }
    }
  }, [data, options, findOptionByValue]);

  useEffect(() => {
    return () => {
      FindDataDebounced.cancel();
    };
  }, [FindDataDebounced]);

  return (
    <>
      <div className="mb-5">
        {showtitle && (
          <label className={"fw-bold " + (required && "required")}>
            {title || "Candidate Student"}
            {result.loading && <SpinnerLoad />}
          </label>
        )}
        <Select
          placeholder="type name min 3 character"
          options={options}
          onInputChange={FindData}
          inputValue={inputValue}
          onChange={handleSelected}
          value={selectedValue}
          isDisabled={disabled}
          styles={customStyles}
          menuPortalTarget={document.body}
          menuPosition="fixed"
        />
      </div>
      {result.message && <AlertNotif messages={result.message} />}
    </>
  );
};

const theadtitle = [
  { name: "course", width: 0 },
  { name: "credit", width: 80 },
  { name: "score", width: 80 },
];

const CandidatePreview = ({ candidate, univ, univprodi, target_dept, doc_date }) => {
  return (
    <table style={{ fontSize: "0.75rem", width: "100%", borderCollapse: "unset", marginBottom: "2px" }}>
      <tbody>
        <tr>
          <th style={styles.title}>
            Candidate Name
          </th>
          <td width={"2%"}>:</td>
          <td style={styles.c_name} width={"60%"}>{candidate.data?.name}</td>
        </tr>
        <tr>
          <th style={styles.title}>
            Candidate ID
          </th>
          <td width={"2%"}>:</td>
          <td style={styles.c_name}>
            {candidate.data?.candidate_id}
          </td>
        </tr>
        <tr>
          <th style={styles.title}>
            Previous Institution
          </th>
          <td width={"2%"}>:</td>
          <td style={styles.c_name}>{univ && (univ.data?.name ?? "")}</td>
        </tr>
        <tr>
          <th style={styles.title}>
            Major at previous institution
          </th>
          <td width={"2%"}>:</td>
          <td style={styles.c_name}>{univprodi && (univprodi.data?.name ?? "")}</td>
        </tr>
        <tr>
          <th style={styles.title}>
            Transferring to SGU Department
          </th>
          <td width={"2%"}>:</td>
          <td style={styles.c_name}>{target_dept && (target_dept || "")}</td>
        </tr>
        <tr>
          <th style={styles.title}>
            Date of Matriculation
          </th>
          <td width={"2%"}>:</td>
          <td style={styles.c_name}>{moment(doc_date).format('MMMM D, YYYY')}</td>
        </tr>
      </tbody>
    </table>
  );
};

const styles = {
  container: { width: "595px" },
  title: { fontWeight: "bold", verticalAlign: "middle", fontSize: "0.75rem" },
  c_name: { verticalAlign: "middle", fontSize: "0.75rem" },
};

const InputTextUniv = ({ selected, HandlerChangeUniv }) => {
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({ loading: true, message: "", data: [] });
    const result = await GET_UNIV_LOOKUP({ "": "" });

    if (result.data && Object.values(result.data).length > 0) {
      const remove_other = result.data.filter((v) => v.id !== 9999);

      const sort_data = remove_other.sort((a, b) => a.name.localeCompare(b.name));
      const option_result = sort_data.map((v) => {
        let obj = {};
        obj.label = v.name ? decodeURIComponent(CapitalizeFirstLetter(v.name)) : "";
        obj.value = v.id;
        return obj;
      });
      setResult({ loading: false, message: "", data: option_result });
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  return result.loading ? <LoadingBars /> :
    result.message ? <AlertRetry messages={result.message} Reload={ReloadData} /> :
      <div className="select-box">
        <InputAutoFillIn title={"Previous Institute"} selected={selected} items={result.data} HandlerChangeData={HandlerChangeUniv} />
      </div>
}

const InputTextUnivProdi = ({ selected, HandlerChangeUnivProdi }) => {
  
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const ReloadData = async () => {
    setResult({ loading: true, message: "", data: [] });
    const result = await GET_UNIV_PRODI_LOOKUP({"":""});

    if (result.data && Object.values(result.data).length > 0) {
      const remove_other = result.data.filter((v) => v.name.toLowerCase() !== "other");
      const remove_duplicate = remove_other.reduce((acc, current) => {
        const x = acc.find(item => item.name === current.name);
        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      }, []);

      const sort_data = remove_duplicate.sort((a, b) => a.name.localeCompare(b.name));
      const option_result = sort_data.map((v) => {
        let obj = {};
        obj.label = v.name ? decodeURIComponent(CapitalizeFirstLetter(v.name)) : "";
        obj.value = v.id;
        return obj;
      });
      setResult({ loading: false, message: "", data: option_result });
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  return result.loading ? <LoadingBars /> :
    result.message ? <AlertRetry messages={result.message} Reload={ReloadData} /> :
      <div className="select-box">
        <InputAutoFillIn title={"Major at prev university"} selected={selected} items={result.data} HandlerChangeData={HandlerChangeUnivProdi} />
      </div>
}

export {
  InfoCourse,
  SearchBarTable,
  WindowSuccessPCF,
  WindowSuccessPCFOri,
  CandidateSelect,
  theadtitle,
  CandidatePreview,
  InputTextUniv,
  InputTextUnivProdi
};
