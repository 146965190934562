import React, { useEffect, useState } from "react";
import {
  DOWNLOAD_REQUEST_DOC,
  GET_STRG_FILE_INFO,
  READ_FILE,
} from "../../../apis/module/JupiterRequest";
import {
  LoadingSkeleton,
  SpinnerLoad,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import moment from "moment";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import {
  ButtonIcon,
  ButtonPrimary,
} from "../../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../../_metronic/helpers";
import { ColorStatusIcon } from "./FileHelpers";
import { ConvertBytes } from "../../../../../_layouts/_helpers/Global";
import { RSAencryptData } from "../../../../../_layouts/_helpers/RSAEnc";
import { useProvider } from "../../../../../_layouts/UMSLayoutProvider";
import { openModal } from "../../../../../_layouts/components/modals/Modals";
import { MessageError } from "../../../admissions/components/Modals";

export default function DetailFile({ data, setData }) {
  const { account } = useProvider();
  const file = data.data;
  let color = ColorStatusIcon(file).color;
  let icon = ColorStatusIcon(file).icon;

  const [images, setImages] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const LOAD_FILE_IMG = async (uniqid) => {
    const arrUniqID = uniqid.split("s/");
    const fileID = arrUniqID[1];
    setImages({ loading: true, data: [], message: "" });

    const result = await READ_FILE(`${fileID}?x=1920&y=1080`);
    if (result.data && Object.values(result.data).length > 0) {
      setImages(result);
    } else {
      setImages({
        loading: false,
        data: [],
        message: "Failed to read the file",
      });
    }
  };
  const [detail, setDetail] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const DETAIL_FILE = async (param) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setDetail({ loading: true, data: [], message: "" });
    const objData = JSON.stringify(param);
    const parameters = RSAencryptData(objData);
    const formData = new FormData();
    formData.append("data", parameters);
    const result = await GET_STRG_FILE_INFO(formData);
    if (result.data && Object.values(result.data).length > 0) {
      if (result.data.public_url) {
        LOAD_FILE_IMG(result.data.public_url);
      }else{
        setImages({
          loading: false,
          data: [],
          message: "",
        })
      }
    }
    setDetail(result);
  };

  useEffect(() => {
    DETAIL_FILE({ file_id: data.data.file_id });
  }, [data.data]);

  const HandlerClose = () =>{
    setData({ open: false, data: [] }); 
    setImages({
      loading: false,
      data: [],
      message: "",
    });
  }

  return (
    <SGUCard>
      <SGUCard.Header>
        <div className="d-flex align-items-center">
          <i className={"ki-outline fs-1 ki-" + icon + " text-" + color}></i>
          <span className="card-label fw-bolder text-dark text-uppercase ms-2">
            {file.file_type}
          </span>
        </div>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        <ButtonIcon
          type="button"
          className="btn-sm"
          onClick={() => HandlerClose()}
          title="Hide Details"
        >
          <KTIcon iconName="cross" className="fs-1 fw-bold text-dark" />
        </ButtonIcon>
      </SGUCard.Toolbar>
      <SGUCard.Body>
        {file.status_file === "deleted" && (
          <div className="bg-light border border-danger rounded p-3 text-uppercase mb-5 d-flex align-items-center justify-content-center">
            <KTIcon iconName="information" className="fs-1 text-danger" />
            <span className="text-danger fw-bold ms-2">
              File has been deleted
            </span>
          </div>
        )}

        {file.mimetype.includes("image") &&
          (images.loading ? (
            <LoadingSkeleton />
          ) : (
            Object.values(images.data).length > 0 && (
              <div className="border p-2 rounded bg-light mb-5">
                <img
                  src={images.data}
                  alt={file.filename}
                  id="img-load"
                  className="w-100 rounded"
                />
              </div>
            )
          ))}

        <div id="tab-header" className="mb-5">
          <ul className="nav nav-pills nav-pills-custom row position-relative mx-0 mb-0">
            <li className="nav-item col-4 mx-0 px-0">
              <a
                className="nav-link text-gray-500 d-flex justify-content-center w-100 border-0 h-100 active"
                data-bs-toggle="tab"
                href="#details"
              >
                <span className="nav-text fw-bold fs-4 mb-3">Details</span>
                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded"></span>
              </a>
            </li>
            <li className="nav-item col-4 mx-0 p-0">
              <a
                className="nav-link text-gray-500 d-flex justify-content-center w-100 border-0 h-100"
                data-bs-toggle="tab"
                href="#activity"
              >
                <span className="nav-text fw-bold fs-4 mb-3">Activity</span>
                <span className="bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px bg-primary rounded"></span>
              </a>
            </li>
            <span className="position-absolute z-index-1 bottom-0 w-100 h-4px bg-light rounded"></span>
          </ul>
        </div>

        {detail.loading ? (
          <LoadingSkeleton />
        ) : detail.message ? (
          <AlertNotif messages={detail.message} />
        ) : (
          Object.values(detail.data).length > 0 && (
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="details"
                role="tabpanel"
              >
                <DataFile data={detail.data} account={account.identity} />
              </div>

              <div className="tab-pane fade show" id="activity" role="tabpanel">
                <ActFile data={detail.data.file_act} />
              </div>
            </div>
          )
        )}
      </SGUCard.Body>
    </SGUCard>
  );
}

const DataFile = ({ data, account }) => {
  const [isdownload, setIsdownload] = useState(false);
  return (
    <>
      <div className="mb-5">
        <span className="d-block fs-8 fw-bold">Type</span>
        <span className="">{data.mimetype}</span>
      </div>

      <div className="mb-5">
        <span className="d-block fs-8 fw-bold">Size</span>
        <span className="">{ConvertBytes(data.size)}</span>
      </div>

      <div className="mb-5">
        <span className="fs-8 fw-bold">Location</span>
        <div className="d-flex align-items-center border border-dark rounded p-2">
          <KTIcon iconName="folder" className="fs-1" />
          <span className="ms-2">{data.path}</span>
        </div>
      </div>

      <div className="mb-5">
        <span className="d-block fs-8 fw-bold">Owner</span>
        <span className="">{data.owner}</span>
      </div>

      <div className="mb-5">
        <span className="d-block fs-8 fw-bold">Created</span>
        <span className="">
          {moment(data.created).format("DD MMM YYYY hh:mm A")}
        </span>
      </div>

      {data.edited && (
        <div className="mb-5">
          <span className="d-block fs-8 fw-bold">Last Modify</span>
          <span className="">
            {moment(data.edited).format("DD MMM YYYY hh:mm A")}
          </span>
        </div>
      )}

      <div className="mb-5">
        <span className="d-block fs-8 fw-bold">File ID</span>
        <span className="">{data.file_id}</span>
      </div>

      <div className="mb-5">
        <span className="d-block fs-8 fw-bold">File Name</span>
        <span className="">{data.filename}</span>
      </div>

      {data.status.includes("uploaded") && (
        <div className="d-grid gap-2">
          <ButtonPrimary
            type="button"
            disabled={isdownload}
            className="btn-lg fw-bold text-uppercase"
            onClick={() => HandlerDownload(data, account, setIsdownload)}
          >
            {isdownload ? (
              <>
                <SpinnerLoad className="me-2" />
                Downloading..
              </>
            ) : (
              <>
                <KTIcon iconName="cloud-download" className="fs-2" />
                Download File
              </>
            )}
          </ButtonPrimary>
        </div>
      )}
    </>
  );
};

const HandlerDownload = async (data, account, setIsdownload) => {
  setIsdownload(true);
  const param = { file_id: data.file_id, user: account.email };
  const objData = JSON.stringify(param);
  const rsaEnc = RSAencryptData(objData);
  const formData = new FormData();
  formData.append("data", rsaEnc);

  try {
    const response = await DOWNLOAD_REQUEST_DOC(formData);

    if (response.status === 200 && response.data) {
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });

      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", data.filename);

      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

      window.URL.revokeObjectURL(url);
      setIsdownload(false);
    } else {
      setIsdownload(false);
      throw new Error("Download failed");
    }
  } catch (error) {
    setIsdownload(false);
    console.error("Download error:", error);
    openModal({
      message: <MessageError message={error.message || "Download failed"} />,
    });
  }
};

const ActFile = ({ data }) => {
  const data_sort = data.sort((a, b) => (a.id < b.id ? 1 : -1));
  const total_data = Object.values(data_sort).length;

  const StatusData = (text) => {
    const labelArr = text.split(".");
    const label = labelArr[Object.values(labelArr).length - 1];
    let icon = "";
    let color = "";
    if (label.includes("delete")) {
      icon = "trash";
      color = "danger";
    } else {
      icon = "folder-up";
      color = "primary";
    }
    return { label, icon, color };
  };

  return (
    <div className="timeline">
      {data_sort.map(
        (v, index) =>
          v.file_act && (
            <div className="timeline-item align-items-center mb-7" key={index}>
              {index !== total_data - 1 && (
                <div className="timeline-line mt-1 mb-n6 mb-sm-n7"></div>
              )}
              <div className="timeline-icon">
                <i
                  className={`ki-outline ki-${
                    StatusData(v.file_act).icon
                  } fs-2 text-${StatusData(v.file_act).color}`}
                ></i>
              </div>
              <div className="timeline-content m-0">
                <span className="fs-6 text-gray-500 fw-semibold d-block">
                  <span className="text-capitalize">
                    {StatusData(v.file_act).label}
                  </span>
                  <span className="me-1 text-lowercase">by {v.editedby}</span>
                </span>
                {v.edited && (
                  <span className="fs-6 fw-bold text-gray-800">
                    {moment(v.edited).format("DD MMM YYYY hh:mm A")}
                  </span>
                )}
              </div>
            </div>
          )
      )}
    </div>
  );
};
