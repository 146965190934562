import React, { useEffect, useState } from 'react'
import { SGUCard } from '../../../../../../_layouts/components/cards/CardUI'
import { ButtonIcon, ButtonPrimary, ButtonTersier } from '../../../../../../_layouts/components/buttons/ButtonUI'
import { KTIcon } from '../../../../../../_metronic/helpers'
import { StudentIDFormat } from '../../../../../../_layouts/_helpers/Global';
import { PMatriculations } from '../../proposal-form/componensts/details';
import { ConfirmRemove, MessageError, MessageSuccess, openModal } from '../../../../../../_layouts/components/modals/Modals';
import { BtnPrevFile } from '../../../../master-data-managements/storage-files/components/StorageUI';
import { LinkBtn } from '../../../../../../_layouts/components/buttons/LinkUI';
import { RequiredMsg } from '../../../../../../_layouts/_core/ErrorMessage';
import { handleKeyDown } from '../../../../components/forms/InputText';
import { UPDATE_CANDIDATE } from '../../../../apis/node-target-data/MarketingAPI';
import { useProvider } from '../../../../../../_layouts/UMSLayoutProvider';
import { RSAencryptData } from '../../../../../../_layouts/_helpers/RSAEnc';
import { DELETE_REQUEST_DOC, POST_REQUEST_DOC_MULTIPLE } from '../../../../apis/module/JupiterRequest';
import { AlertNotif } from '../../../../../../_layouts/components/alerts/AlertsUI';
import { ProgressBar } from 'react-bootstrap';

export default function Forms({ candidate, proposal }) {
    const [candidateData, setCandidateData] = useState(candidate);
    const [last_semester, setLastSemester] = useState(candidateData.regstudent && (candidateData.regstudent.university_last_semester || ""));
    const [courses, setCourses] = useState([]);
    const [total_credit, setTotalCredit] = useState(0);
    const [total_credit_ext, setTotalCreditExt] = useState(0);
    const transid = proposal.transid || "";
    const [regstudentID, setRegstudentID] = useState("");
    const [showTrans, setShowTrans] = useState(false);

    useEffect(() => {
        setRegstudentID(candidate.regstudent?.registered_id || "");
        setCandidateData(candidate);
        const course_dt = proposal.transfer_proposal_dt.map(item => ({ ...item, id: item.course_id, coursetitle: item.course.coursetitle, code: item.course.code, credit: item.course.credit }))
        setCourses(course_dt)
        const total_credit = proposal.transfer_proposal_dt ?
            proposal.transfer_proposal_dt.reduce((sum, item) => sum + (parseFloat(item.course.credit) || 0), 0)
            : 0;
        setTotalCredit(total_credit);
        const total_credit_ext = proposal.transfer_proposal_dt ?
            proposal.transfer_proposal_dt.reduce((sum, item) => sum + (parseFloat(item.credit_ext) || 0), 0)
            : 0;
        setTotalCreditExt(total_credit_ext);
    }, [candidate, proposal])


    const [file, setFile] = useState(null);
    const [isExtension, setIsExtension] = useState(false);
    const [isSize, setIsSize] = useState(false);

    const { account } = useProvider();
    const [uploadFile, setUploadFile] = useState({ loading: false, message: "", data: [] });
    const [uploadProgress, setUploadProgress] = useState({});

    const validateFile = (file) => {
        const validExtensions = ['pdf'];
        const maxSize = 2 * 1024 * 1024; // 2MB in bytes

        const fileExtension = file.name.split('.').pop().toLowerCase();
        const fileSize = file.size;

        setIsExtension(validExtensions.includes(fileExtension));
        setIsSize(fileSize <= maxSize);

        return validExtensions.includes(fileExtension) && fileSize <= maxSize;
    }

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            if (validateFile(selectedFile)) {
                setFile(selectedFile);
                setUploadProgress(10);
                setTimeout(() => {
                    HandlerUploadFile(selectedFile);
                }, 1000);

            } else {
                setFile(null);
                const messageError = 'Invalid file type or size. Please choose a file with a valid extension PDF and size less than 2MB.';
                openModal({ message: <MessageError message={messageError} /> })
            }
        }
    }

    const isImageFile = (file) => {
        return file.type.startsWith("image/");
    };

    const HandlerUploadFile = async (selectedFile) => {
        if (!selectedFile) {
            openModal({ message: <MessageError message="Please select a file to upload." /> })
            return;
        }
        setUploadFile({ loading: true, data: [], message: "" });

        //const doc_type = "strg.doc.type.decreetransfercredit";
        const doc_type = "strg.doc.type.pasfoto";
        const param = {
            "user": account.identity.email,
            "doc_type": doc_type,
            "owner": regstudentID,
            "reff_id": transid
        }
        
        console.log(param);
        const objData = JSON.stringify(param);
        const rsaEnc = RSAencryptData(objData);
        
        setUploadProgress(50);
        
        try {
            setUploadProgress(70);
            const formData = new FormData();
            formData.append("data", rsaEnc);
            formData.append("file", selectedFile);

            const result = await POST_REQUEST_DOC_MULTIPLE(formData, (progress) => {
                console.log(`Progress for ${selectedFile.name}:`, progress);
                setUploadProgress((prev) => {
                    const newProgress = { ...prev, [selectedFile.name]: progress };
                    console.log("New progress state:", newProgress);
                    return newProgress;
                });
            });

            if (result.result === "success") {
                setUploadFile({
                    loading: false,
                    data: [result.data?.data],
                    message: "",
                });
                setUploadProgress(0);
            } else {
                openModal({ message: <MessageError message={"Failed to upload file. Please try again."} /> })
                setUploadProgress(0);
            }
        } catch (error) {
            console.log(error);
            setUploadFile({
                loading: false,
                data: [],
                message: "An error occurred during the upload process. " + error.message,
            });
            setUploadProgress(0);
        }
    }

    const [submit, setSubmit] = useState({ loading: false, message: "", data: [] });
    const SubmitProposal = async (e) => {
        e.preventDefault();
        if (last_semester && file && transid && candidateData && Object.values(courses).length > 0) {
            const current_last_semester = candidateData.regstudent && (candidateData.regstudent.university_last_semester || 0)
            if (parseInt(current_last_semester) !== last_semester) {
                const param_candidate = { id: candidateData.regstudent.id, university_last_semester: last_semester };
                const result_update = await UPDATE_CANDIDATE(param_candidate);
            }



        } else {
            openModal({ message: <MessageError message={RequiredMsg} /> })
        }
    }

    const HandlerDeleteFile = async (data) => {
        const param = {
            file_id: data.file_id,
            user: account.identity.email,
        };
        const objData = JSON.stringify(param);
        const rsaEnc = RSAencryptData(objData);

        const formData = new FormData();
        formData.append("data", rsaEnc);
        const result = await DELETE_REQUEST_DOC(formData);
        if (result.data && Object.values(result.data).length > 0) {
            openModal({
                message: <MessageSuccess message={"Successfully removed"} />,
            });
            setUploadFile({ loading: false, data: [], message: "" });
            setFile(null);
            setUploadProgress(0);
        } else {
            openModal({ message: <MessageError message={result.message} /> });
        }
    };

    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bold text-gray-800">Student's Proposal Form</span>
            </SGUCard.Header>
            <SGUCard.Body>
                <form autoComplete='off' method='post' onSubmit={(e) => SubmitProposal(e)}>
                    <div className="row mb-5 align-items-center">
                        <label className="col-lg-3 fw-semibold text-muted required">Student:</label>
                        <div className="col-lg-9">
                            <span className="text-dark fw-bold">{StudentIDFormat(candidateData.candidate_id) + " · " + (candidateData.regstudent && (candidateData.regstudent.fullname || ""))}</span>
                        </div>
                    </div>
                    <div className="row mb-5 align-items-center">
                        <label className="col-lg-3 fw-semibold text-muted required">Target Department:</label>
                        <div className="col-lg-9">
                            <span className="text-dark fw-bold">{candidateData.target_department && (candidateData.target_department.name || "")}</span>
                        </div>
                    </div>

                    <div className="row mb-5 align-items-center">
                        <label className="col-lg-3 fw-semibold text-muted required">Previous University:</label>
                        <div className="col-lg-9">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-floating">
                                        <input type="text" className="form-control form-control-sm fw-bold" readOnly value={proposal.university_name || "-"} />
                                        <label className='fw-semibold text-muted'>Institution Name</label>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-floating">
                                        <input type="text" className="form-control form-control-sm fw-bold" readOnly value={proposal.university_prodi_name || "-"} />
                                        <label className='fw-semibold text-muted'>Major at prev insitution</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mb-5 align-items-center">
                        <label className="col-lg-3 fw-semibold text-muted required">Accepted at semester:</label>
                        <div className="col-lg-9">
                            <input type="text" className="form-control form-control-sm w-100px text-center" value={last_semester} onChange={(e) => setLastSemester(e.target.value ? parseInt(e.target.value) : 0)} onKeyDown={handleKeyDown} />
                        </div>
                    </div>

                    <div className="row mb-5 align-items-start">
                        <label className="col-lg-3 fw-semibold text-muted required">Upload Decree:</label>
                        <div className="col-lg-9">
                            {Object.values(uploadFile.data).length > 0 ? (
                                uploadFile.data.map((v, index) => (
                                    <div key={index} className='d-flex justify-content-between align-items-center'>
                                        <div className='d-flex align-items-center'>
                                            <i className="bi bi-filetype-pdf fs-2x text-danger"></i>
                                            <span className="mx-2">{file.name || v.filename}</span>
                                        </div>

                                        <div className='d-flex align-items-center'>
                                            <KTIcon iconName='check-circle' className='fs-2 text-success' />
                                            <ButtonIcon type="button" className="btn-sm" title="Remove File" onClick={() =>
                                                openModal({
                                                    message: (
                                                        <ConfirmRemove
                                                            handler={() => HandlerDeleteFile(v)}
                                                            message={"Are you sure want to remove?"}
                                                        />
                                                    ),
                                                })
                                            } >
                                                <KTIcon iconName='cross-circle' className='fs-2 text-danger' />
                                            </ButtonIcon>
                                        </div>

                                    </div>
                                ))
                            ) : (
                                <>
                                    <div className="input-group">
                                        <input type="file" className="form-control" onChange={handleFileChange} accept=".pdf" />
                                        {file && (
                                            <div className="input-group-text p-0">
                                                {isImageFile(file) ? <BtnPrevFile file={file} /> : (
                                                    <button
                                                        type="button"
                                                        className="btn btn-icon btn-sm"
                                                        onClick={() =>
                                                            window.open(URL.createObjectURL(file), "_blank")
                                                        }
                                                    >
                                                        <KTIcon iconName="document" className="fs-1" />
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    </div>

                                    <div className="notes fs-8 my-2 d-flex align-items-center justify-content-between">
                                        <ul className='nav'>
                                            <li><span className="fw-bold">Notes:</span></li>
                                            <li className='mx-2 d-flex align-items-center'>
                                                <KTIcon iconName={(isExtension ? 'check' : 'cross') + '-circle'} className={'fs-1x me-1 text-' + (isExtension ? 'success' : 'danger')} />
                                                Only <span className='fw-bold mx-1'>PDF</span> files are allowed.
                                            </li>
                                            <li className='d-flex align-items-center'>
                                                <KTIcon iconName={(isSize ? 'check' : 'cross') + '-circle'} className={'fs-1x me-1 text-' + (isSize ? 'success' : 'danger')} />
                                                Maximum file size is <span className='fw-bold ms-1'>2 MB</span>.
                                            </li>
                                        </ul>
                                        {transid && (
                                            <div className="text-end">
                                                <span className="badge badge-light-info cursor-pointer" title="Trans ID" onClick={() => setShowTrans(!showTrans)}>
                                                    <KTIcon iconName='code' className='fs-1x text-info' />
                                                    {showTrans && <span className="ms-1">{transid}</span>}
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </>
                            )}

                            {uploadFile.message && (
                                <AlertNotif title={"Failed Upload"} messages={uploadFile.message} color={uploadFile.data.length > 0 ? 'success' : 'danger'} />
                            )}

                            {((uploadProgress > 0 && uploadProgress < 100) && uploadFile.loading) && (
                                <div className='d-flex align-items-center '>
                                    <div className="w-100">
                                        <ProgressBar
                                            striped
                                            animated
                                            variant="success"
                                            now={uploadProgress}
                                            max="100"
                                        />
                                    </div>
                                    <span className='text-success'>({uploadProgress}%)</span>
                                </div>
                            )}
                        </div>
                    </div>

                    <p className="fw-semibold text-muted required">Transferable Credits:</p>
                    {(Object.values(courses).length > 0) && <PMatriculations data={courses} t_credit={total_credit} t_credit_ext={total_credit_ext} />}

                    <div className="text-end mt-8">
                        <LinkBtn to={"/course-managements/transfer-credit-registration"} className="btn btn-sm btn-light me-2">
                            Cancel
                        </LinkBtn>
                        <ButtonPrimary type="submit" className="btn-sm px-8" disabled={submit.loading}>
                            {submit.loading ? "Processing..." : "Approve Proposal"}
                        </ButtonPrimary>
                    </div>
                </form>
            </SGUCard.Body>
        </SGUCard>
    )
}
