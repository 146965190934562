import React, { useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import {
  APPROVAL_ATD,
  GET_ATD_LECTURER_BY_SECTION,
} from "../../../apis/node-target-data/AcademicApi";
import { LoadingSkeleton } from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../../_layouts/components/alerts/AlertsUI";
import moment from "moment";
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../../../../_layouts/components/popovers/PopOvers";
import { ButtonItemRefresh } from "../../../../../_layouts/components/datatables/MYDatatables";
import { DownloadAtdSummary } from "../../DownloadAtdSum";
import {
  ButtonDownload,
  ButtonIcon,
  ButtonTersier,
} from "../../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../../_metronic/helpers";
import {
  MessageError,
  openModal,
} from "../../../../../_layouts/components/modals/Modals";
import StudentList from "./StudentList";
import FormTeachingTopic from "./FormTeachingTopic";

export default function TableAttd({ data, section_id, widget_33 }) {
  const [totalItems, setTotalItems] = useState(0);
  const [status, setStatus] = useState(5);
  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const [statusDownload, setStatusDownload] = useState(false);
  const DownloadAttd = () => {
    DownloadAtdSummary(section_id, setStatusDownload);
  };

  const ReloadData = async () => {
    setResults({ loading: true, data: [], message: "" });
    const param = {
      section_id: section_id,
      status: status,
      view_other: "FALSE",
    };
    const result = await GET_ATD_LECTURER_BY_SECTION(param);
    if (result.data && Object.values(result.data).length > 0) {
      result.data.sort((a, b) => (a.start_dt < b.start_dt ? 1 : -1));
      setTotalItems(Object.values(result.data).length);
      setResults({ loading: false, data: result.data, message: "" });
    } else {
      setResults(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, [status]);

  const [detailPage, setDetailPage] = useState();
  if (detailPage) {
    return <StudentList data={detailPage} setData={setDetailPage} widget_33={widget_33} />;
  } else {
    return (
      <SGUCard>
        <SGUCard.Body className={"px-0"}>
          <div className="d-flex align-items-center justify-content-between p-8">
            <div className="">
              <select
                name="status"
                className="form-select form-select-sm"
                defaultValue={status}
                onChange={(e) => setStatus(parseInt(e.target.value))}
              >
                <option value="1">Status: ALL</option>
                <option value="2">Status: DRAFT</option>
                <option value="3">Status: SUBMITTED</option>
                <option value="4">Status: APPROVED</option>
                <option value="5">Status: SUBMITTED & APPROVED</option>
              </select>
            </div>
            <div className="actions">
              <ButtonItemRefresh
                totalItems={totalItems}
                onClick={() => ReloadData()}
              />
              <ButtonDownload
                type="button"
                className="btn-sm ms-3"
                title="Download"
                disabled={statusDownload}
                onClick={() => DownloadAttd()}
              >
                {statusDownload ? "Downloding..." : "Export to excel"}
              </ButtonDownload>
            </div>
          </div>

          <div className="table-list-atd table-responsive">
            <table className="table table-row-bordered align-middle gs-9">
              <thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bolder bg-light bg-opacity-75">
                <tr className="fw-bolder">
                  <th>No</th>
                  <th>Class Info</th>
                  <th width="10%">Start Date</th>
                  <th>Teaching Topic</th>
                  <th>Status Date</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {results.loading ? (
                  <tr>
                    <td colSpan={10}>
                      <LoadingSkeleton />
                    </td>
                  </tr>
                ) : results.message ? (
                  <tr>
                    <td colSpan={10}>
                      <AlertRetry
                        messages={results.message}
                        Reload={ReloadData}
                      />
                    </td>
                  </tr>
                ) : Object.values(results.data).length > 0 ? (
                  results.data.map((v, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>
                        {" "}
                        <ClassInfo data={v} />{" "}
                      </td>
                      <td>
                        {" "}
                        <SchdInfo data={v} />
                      </td>
                      <td>
                        {" "}
                        <TeachingInfo data={v} />
                      </td>
                      <td>
                        {" "}
                        <StatusInfo data={v} />
                      </td>
                      <td>
                        {" "}
                        <BadgeInfo data={v} />
                      </td>
                      <td>
                        {" "}
                        <ActionAppv
                          data={v}
                          ReloadData={ReloadData}
                          setDetailPage={setDetailPage}
                          lecturers={data.lecturer}
                          widget_33={widget_33}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={10}> No record found </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </SGUCard.Body>
      </SGUCard>
    );
  }
}

const ClassInfo = ({ data }) => {
  let actTypeArr = data.activity_type.split(".");
  let actType = actTypeArr[actTypeArr.length - 1];
  return (
    <div>
      <span className="fw-bolder">
        {data.emp ? data.emp.fullname || "" : ""}
      </span>
      <div className="fs-7">
        <span className="text-muted">
          <span className="me-1">Type:</span>
          <span className="text-capitalize">
            {actType}
          </span>
        </span>
        <span className="mx-2">·</span>
        <span className="text-muted">
          <span className="me-1">Session: </span>
          <span>{data.weight}</span>
        </span>
      </div>
    </div>
  );
};

const SchdInfo = ({ data }) => {
  return (
    <>
      <span className="fw-bolder">
        {moment(data.start_dt).format("MMM Do YYYY")}
      </span>
      <div>
        <span className="text-dark-50 fs-8">
          {moment(data.start_dt).format("hh:mm A")} -{" "}
          {moment(data.end_dt).format("hh:mm A")}{" "}
        </span>
      </div>
    </>
  );
};

const TeachingInfo = ({ data }) => {
  return (
    <>
      {data.teaching_topic &&
        (data.teaching_topic.length > 40 ? (
          <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement="top"
            overlay={renderTooltip({
              title: data.teaching_topic,
            })}
          >
            <p className="mb-0">
              {data.teaching_topic.substring(0, 40) + "..."}
            </p>
          </OverlayTrigger>
        ) : (
          <p className="mb-0">{data.teaching_topic}</p>
        ))}
      {data.remark && (
        <div>
          <span className="fw-bold text-muted d-block">Remark:</span>
          <span className="text-dark-50">{data.remark}</span>
        </div>
      )}
    </>
  );
};

const StatusInfo = ({ data }) => {
  return (
    <>
      {data.submit_formatted && (
        <div>
          <span className="fw-bolder d-block fs-8">Submit Date:</span>
          <span className="text-dark-50 fs-8">{data.submit_formatted}</span>
        </div>
      )}
      {data.approved_formatted && (
        <div>
          <span className="fw-bolder d-block fs-8">Approved Date:</span>
          <span className="text-dark-50 fs-8">{data.approved_formatted}</span>
        </div>
      )}
    </>
  );
};

const BadgeInfo = ({ data }) => {
  switch (data.status) {
    case "SUBMITTED":
      return (
        <div className="d-flex align-items-center flex-column">
          <KTIcon iconName="time" className="fs-2x text-info me-1" />
          <span className="text-info fw-bold">{data.status}</span>
        </div>
      );
      break;
    case "APPROVED":
      return (
        <div className="d-flex align-items-center flex-column">
          <KTIcon iconName="verify" className="fs-2x me-1 text-success" />
          <span className="text-success fw-bold">{data.status}</span>
        </div>
      );
      break;
    case "CANCELED":
      return (
        <div className="d-flex align-items-center flex-column">
          <KTIcon iconName="cross-circle" className="fs-2x me-1 text-danger" />
          <span className="text-danger fw-bold">{data.status}</span>
        </div>
      );
      break;

    default:
      return "";
      break;
  }
};

const ActionAppv = ({ data, ReloadData, setDetailPage, lecturers, widget_33 }) => {
  const [isload, setisload] = useState(false);
  const HandlerAppv = async (status) => {
    setisload(true);
    const param = { atdid: data.id, status: status };
    const result = await APPROVAL_ATD(param);
    if (result.data && Object.values(result.data).length > 0) {
      ReloadData();
    } else {
      openModal({ message: <MessageError message={result.message} /> });
    }
    setisload(false);
  };
  return (
    <div className="d-flex flex-column">
        {widget_33 && (
            (data.status === "SUBMITTED" || data.status === "CANCELED") && (
                <div className="btn-group mb-3">
                <ButtonTersier
                    className="btn-sm fw-bold btn-light-primary"
                    type="button"
                    disabled={isload}
                    onClick={() => HandlerAppv(1)}
                >
                    {isload ? (
                    <>
                        <span
                        className="spinner-grow spinner-grow-sm"
                        aria-hidden="true"
                        ></span>
                        <span className="visually-hidden" role="status">
                        Loading...
                        </span>
                        Approve
                    </>
                    ) : (
                    "Approve"
                    )}
                </ButtonTersier>
                {data.status !== "CANCELED" && (
                    <ButtonTersier
                    className="btn-sm fw-bold btn-light-danger"
                    type="button"
                    disabled={isload}
                    onClick={() => HandlerAppv(2)}
                    >
                    {isload ? (
                        <>
                        <span
                            className="spinner-grow spinner-grow-sm"
                            aria-hidden="true"
                        ></span>
                        <span className="visually-hidden" role="status">
                            Loading...
                        </span>
                        Cancel
                        </>
                    ) : (
                        "Cancel"
                    )}
                    </ButtonTersier>
                )}
                </div>
            )
        )}
      {data.status ? (
        <div className="btn-group">
          {data.qr && (
            <ButtonIcon
              className="btn-sm btn-light"
              type="button"
              title="Open QR Code"
              onClick={() => OpenQR(data)}
            >
              <i className="bi bi-qr-code"></i>
            </ButtonIcon>
          )}
          <ButtonTersier
            className="btn-sm text-primary"
            type="button"
            title="View Student"
            onClick={() => setDetailPage(data)}
          >
            <div className="d-flex align-items-center">
              <KTIcon
                iconName="user-tick"
                className="me-2 fs-1x text-primary"
              />
              <span>Students</span>
            </div>
          </ButtonTersier>
        </div>
      ) : (
        widget_33 && (
            <ButtonTersier
            className="btn-sm btn-light"
            type="button"
            onClick={() =>
                openModal({
                header: `Attendance ${data.start_dt_formatted}`,
                message: (
                    <FormTeachingTopic
                    tt_schd_id={data.tt_schd_id}
                    lecturers={lecturers}
                    ReloadData={ReloadData}
                    />
                ),
                })
            }
            >
              Add Teaching Topic
            </ButtonTersier>
        )
      )}
    </div>
  );
};

const OpenQR = (data) => {
  const paramqr = data.qr;
  paramqr.section_id = data.section_id;
  paramqr.start_dt = data.start_dt;
  paramqr.end_dt = data.end_dt;
  if (paramqr) {
    var trimmed_payload = JSON.stringify(paramqr).replace(/\s+/g, "");
    var base64 = CryptoJS.enc.Base64.stringify(
      CryptoJS.enc.Utf8.parse(trimmed_payload)
    );
    var linkQR = "https://atdqr.sgu.ac.id/showqr/" + base64;
    //window.location.replace(linkQR);

    var strWindowFeatures =
      "location=yes,height=700,width=550,scrollbars=yes,status=yes";
    window.open(linkQR, "_blank", strWindowFeatures);
  }
};

export { BadgeInfo };
