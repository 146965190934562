import React from 'react'
import { SGUCard } from '../../../../../_layouts/components/cards/CardUI'
import { KTIcon } from '../../../../../_metronic/helpers'
import { LectProgressRPT, RowLecturer } from '../../../course-managements/teaching-loads/components/TeachingLoadUI'
import { ButtonTersier } from '../../../../../_layouts/components/buttons/ButtonUI'

export default function HeaderAttd({ data }) {
    return (
        <SGUCard>
            <SGUCard.Body className={"pt-8"}>
                <div className="flex-grow-1">
                    <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                        <div className="d-flex flex-column">
                            <div className="d-flex align-items-center mb-2">
                                <span className="text-gray-900 text-hover-primary fs-2 fw-bold me-1">{data.course}</span>
                                <KTIcon iconName='verify' className='fs-1 text-primary' />
                            </div>
                            <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
                                <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                                    <KTIcon iconName='abstract-36' className='fs-4 me-1' />
                                    {data.course_code}
                                </span>
                                <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
                                    <KTIcon iconName='abstract-24' className='fs-4 me-1' />
                                    Credit {data.course_credit} SKS
                                </span>
                            </div>
                        </div>
                        <div className="d-flex">
                            <ButtonTersier type="button" className="btn-sm fw-bold text-muted text-hover-danger" onClick={() => window.close()}>
                                <i className="bi bi-x-circle text-muted"></i>
                                Exit Page
                            </ButtonTersier>
                        </div>
                    </div>
                    <div className="d-flex flex-wrap flex-stack align-items-start">
                        <div className="d-flex flex-column flex-grow-1 pe-8">
                            <div className="d-flex flex-wrap">
                                <CardBorder name={"Section ID"} value={data.section_id} />
                                <CardBorder name={"Course Owner"} value={data.cost_center_code} />
                                <CardBorder name={"Total Session"} value={data.section_session} />
                                <CardBorder name={"Max Seat"} value={data.max_seat} />
                            </div>
                        </div>

                        <div className="d-flex align-items-center flex-column">
                            <div className="w-100">
                                <RowLecturer obj={data} />
                                <LectProgressRPT data={data} />
                            </div>
                        </div>
                    </div>
                </div>
            </SGUCard.Body>
        </SGUCard>
    )
}

const CardBorder = ({ name, value }) => {
    return (
        <div className="border border-gray-300 border-dashed rounded min-w-125px py-3 px-4 me-6 mb-3">
            <div className="fs-2 fw-bold counted">{value}</div>
            <div className="fw-semibold fs-6 text-gray-500">{name}</div>
        </div>
    )
}
