import React, { useEffect, useState } from "react";
import { LoadingSkeleton } from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../../../_layouts/components/alerts/AlertsUI";
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../../../../../_layouts/components/popovers/PopOvers";
import { GET_ATD_STUDENT_BY_COURSE } from "../../../../apis/node-target-data/AcademicApi";
import moment from "moment";
import { ButtonItemRefresh } from "../../../../../../_layouts/components/datatables/MYDatatables";
import { ButtonDownload } from "../../../../../../_layouts/components/buttons/ButtonUI";
import DownloadAtd from "./DownloadAtd";

export default function DetailAtdList({ param, student, course }) {
  const [results, setResults] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const ReloadData = async () => {
    setResults({ loading: true, message: "", data: [] });
    const result = await GET_ATD_STUDENT_BY_COURSE(param);

    setResults(result);
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const LocationCoordinate = ({ values }) => {
    let coordinate = values.coordinate;
    let coordinateType = "";
    if (coordinate) {
      let coordinateArr = coordinate.split(",");
      coordinateType = coordinateArr[0] + "," + coordinateArr[1];
    }

    return (
      <div>
        {coordinateType !== "0,0" ? (
          <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement="top"
            overlay={renderTooltip({ title: "Click here to open map" })}
          >
            <a
              href={
                "https://www.google.com/maps/search/?api=1&query=" +
                coordinateType
              }
              target="_blank"
            >
              View location
            </a>
          </OverlayTrigger>
        ) : (
          <div>
            <span className="fw-bolder me-1">Location:</span>
            <span className="text-danger">{coordinateType}</span>
          </div>
        )}
      </div>
    );
  };

  return results.loading ? (
    <LoadingSkeleton />
  ) : results.message ? (
    <AlertRetry messages={results.message} Reload={ReloadData} />
  ) : (
    <div className="table-responsive" style={{ maxHeight: "400px" }}>
      <div className="d-flex justify-content-between mb-5">
        <div className="mx-2">
          <ButtonItemRefresh
            totalItems={Object.values(results.data).length}
            onClick={() => ReloadData()}
          />
        </div>
        {Object.values(results.data).length > 0 && (
          <ButtonDownload
            className="btn-sm"
            type="button"
            onClick={()=>DownloadAtd(results.data, {student,course })}
          >
            Export to excel
          </ButtonDownload>
        )}
      </div>
      <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
        <thead>
          <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
            <th>No</th>
            <th width={150}>Schedule Date</th>
            <th>Teaching Topic</th>
            <th>Status</th>
            <th width={150}>Tap Time</th>
            <th>Remark</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(results.data).length > 0 ? (
            results.data.map((v, index) => (
              <tr
                key={index}
                className={
                  v.flag_sts === 3 || v.flag_sts === 4 || v.flag_sts === 5
                    ? "bg-light-danger"
                    : ""
                }
              >
                <td>{index + 1}</td>
                <td>
                  <span className="d-block fw-bolder">
                    {v.atd_lecturer
                      ? v.atd_lecturer.start_dt
                        ? moment(v.atd_lecturer.start_dt).format("D MMM YYYY")
                        : ""
                      : ""}
                  </span>
                  <span className="text-dark fs-7">
                    {v.atd_lecturer
                      ? v.atd_lecturer.start_dt
                        ? moment(v.atd_lecturer.start_dt).format("hh:mm A")
                        : ""
                      : ""}{" "}
                    -
                    {v.atd_lecturer
                      ? v.atd_lecturer.end_dt
                        ? moment(v.atd_lecturer.end_dt).format("hh:mm A")
                        : ""
                      : ""}
                  </span>
                </td>
                <td>
                  <span className="d-block fw-bolder">
                    {v.atd_lecturer ? v.atd_lecturer.emp.fullname : ""}
                  </span>
                  <span className="fs-7">
                    {v.atd_lecturer ? v.atd_lecturer.teaching_topic : ""}
                  </span>
                </td>
                <td className="text-center">
                  <span className="fw-bolder">{v.status}</span>
                </td>
                <td>
                  <span className="">
                    <span className="d-block fw-bolder">
                      {v.tap_time
                        ? moment(v.tap_time).format("DD MMM YYYY")
                        : ""}
                    </span>
                    <span className="fs-7">
                      {v.tap_time ? moment(v.tap_time).format("hh:mm A") : ""}
                    </span>
                  </span>
                </td>
                <td>
                  <span className="fw-semibold">{v.remark ? v.remark : ""}</span>
                  {v.tap_time ? <LocationCoordinate values={v} /> : ""}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6}>No record found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
