import React, { useState } from 'react'
import { ButtonDanger, ButtonIcon, ButtonTersier } from '../../../../../../_layouts/components/buttons/ButtonUI';
import { KTIcon } from '../../../../../../_metronic/helpers';
import { MessageError, openModal } from '../../../../../../_layouts/components/modals/Modals';
import { EmployeeSelect } from '../../../../components/forms/SelectBox';
import { SectionCompSelect } from '../ThesisDefenseUI';
import { useOutletContext } from 'react-router-dom';
import { RequiredMsg } from '../../../../../../_layouts/_core/ErrorMessage';
import { SpinnerLoad } from '../../../../../../_layouts/components/loadbars/LoadingBars';
import { APPEND_EXAMINER } from '../../../../apis/node-target-data/AcademicApi';

export function FormExaminers({ components, dataCallback, setCallback, ReloadData }) {
  const context_data = useOutletContext();
  const context = context_data.data;
  let dataemp = null; let datacomp = [];
  if (dataCallback.data) {
    const exam_comp = dataCallback.data.permission;
    dataemp = { label: dataCallback.data.emp_name, value: dataCallback.data.emp_id };
    datacomp = exam_comp.filter(prev => prev.has_access === 1).map(v => {
      return { value: v.wg_com.id, label: `${v.name} ${v.weight * 100}%` }        
    })
  }
  const [emp_id, setEmp_id] = useState(dataemp ? (dataemp || 0) : 0);
  const [component, setComponent] = useState(datacomp);


  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const HandlerSubmit = async () => {
    if (emp_id && Object.values(component).length > 0) {
      setResults({ loading: true, message: "", data: [] });
      let permission = component.map(v => {
        return { has_access: 1, wg_com_id: v.value }
      })
      let param = { examiner: [] };
      if (dataemp) {
        //update
        datacomp.map(item =>{
          const exist = permission.some(prev => prev.wg_com_id === item.value);
          if(!exist){
            permission.push({
              has_access: 0, wg_com_id: item.value
            })
          }
        })
        param.examiner = [
          {
            emp_id: dataemp.value,
            permission: permission,
            ths_id: context && (context.id || 0)
          }
        ];
      } else {
        //insert
        param.examiner = [
          {
            emp_id: emp_id,
            permission: permission,
            ths_id: context && (context.id || 0)
          }
        ];
      }

      const result = await APPEND_EXAMINER(param);
      if (result.data && Object.values(result.data).length > 0) {
        ReloadData();
      } else if (result.message) {
        openModal({ message: <MessageError message={result.message} /> });
        setResults(result);
      } else {
        setResults(result);
      }
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> })
    }
  }

  return (
    <tr>
      <td></td>
      <td width={"40%"}><EmployeeSelect data={emp_id} setData={setEmp_id} showtitle={false} disabled={dataemp ? true : false} /></td>
      <td width={"45%"}><SectionCompSelect data={component} setData={setComponent} components={components} /></td>
      <td width={"15%"}>
        <div className="btn-group mb-5">
          <ButtonIcon type="button" className="btn-sm fw-bold" title="Cancel" disabled={results.loading} onClick={() => openModal({ message: <ConfirmRemove setCallback={setCallback} /> })}>
            <KTIcon iconName="cross-circle" className="fs-2 text-danger" />
          </ButtonIcon>
          <ButtonIcon type="button" className="btn-sm btn-primary fw-bold" title="Submit" onClick={() => HandlerSubmit()} disabled={results.loading}>
            {results.loading ? <SpinnerLoad /> : <KTIcon iconName="check" className="fs-2 text-white" />}
          </ButtonIcon>
        </div>
      </td>
    </tr>
  )
}


const ConfirmRemove = ({ setCallback }) => {
  return (
    <div className="text-center">
      <h3 className='mb-5'>Are you sure you want to cancel this process?</h3>
      <ButtonTersier className="btn-lg fw-bold" type="button" onClick={() => openModal({ open: false })}>
        No
      </ButtonTersier>
      <ButtonDanger className="btn-lg fw-bold ms-5" type="button" onClick={() => { setCallback({ open: false, data: "" }); openModal({ open: false }) }}>
        Yes
      </ButtonDanger>
    </div>
  )
}