import React, { useMemo, useState } from "react";
import { Link } from "react-router-dom";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  PaginationComponent,
  SearchBarTable,
} from "../../../../_layouts/components/datatables/MYDatatables";
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../../../_layouts/components/popovers/PopOvers";
import { ButtonDownload } from "../../../../_layouts/components/buttons/ButtonUI";
import DownloadStd from "./DownloadStd";
import { EnrollmentStdType, StudentIDFormat } from "../../../../_layouts/_helpers/Global";

export default function TableData({ data, ReloadData }) {
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: "No", field: "studentid", sortable: false },
    { name: "Student Name", field: "student_name", sortable: true },
    { name: "Department", field: "clu_name", sortable: true },
    { name: "Semester", field: "student_semester", sortable: true },
    { name: "Intake", field: "intake", sortable: true },
    { name: "Enrollment", field: "enrollmentType", sortable: true },
    { name: "Action", field: "student_id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (Object.values(data).length > 0) {
      computedData = data.map((c) => {
        let enrollType = "";
        if(c.enrollment_type_id){
          enrollType = EnrollmentStdType(c.enrollment_type_id);
        }
        var obj = {};
        obj.clu_name = c.clu ? c.clu.name || "" : "";
        obj.clu_spec_name = c.clu_spec ? c.clu_spec.name || "" : "";
        obj.student_id = c.studentid ? c.studentid : "";
        obj.student_name = c.fullname ? c.fullname : "-";
        obj.student_semester = c.semester ? c.semester.toString() : "";
        obj.sgumail = c.sgumail ? c.sgumail : "";
        obj.is_active = c.is_active ? c.is_active : "";
        obj.curriculum = c.curr ? c.curr.name || "" : "";
        obj.status = c.status
          ? c.status.name
            ? c.status.name
            : c.status.id
          : "";
        obj.intake = c.term_intake ? c.term_intake.name || "" : "";
        obj.atp_term_name = c.atp_term ? c.atp_term.name || "" : "";
        obj.enrollmentType = enrollType;
        obj.corporate = c.corporate ? c.corporate.name || "" : "";
        obj.cgpa = c.cgpa ? c.cgpa.toString() : "0";
        obj.ccredit_gpa = c.ccredit_gpa ? c.ccredit_gpa.toString() : "";
        return obj;
      });

      computedData.sort((a, b) => (a.student_name > b.student_name ? 1 : -1));
    }

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key]
            .toString()
            .toLowerCase()
            .includes(search)
        );
      });
    }
    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [data, search, sorting, currentPage]);
  return (
    <div id="table-data">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex">
          <div className="mx-2">
            <ButtonItemRefresh
              totalItems={totalItems}
              onClick={() => ReloadData()}
            />
          </div>
          {totalItems > 0 && (
            <ButtonDownload
              className="btn-sm"
              type="button"
              onClick={() => DownloadStd(data, "Student-Information")}
            >
              Export to excel
            </ButtonDownload>
          )}
        </div>
      </div>

      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {ResultData.length > 0 ? (
              ResultData.map((v, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>
                    <span className="fw-bold d-block">{v.student_name}</span>
                    <span className="text-primary">{StudentIDFormat(v.student_id)}</span>
                  </td>
                  <td>
                    <span className="fw-bold d-block">{v.clu_name}</span>
                    <span className="text-muted">{v.clu_spec_name}</span>
                  </td>
                  <td>
                    <span className="fw-bold d-block">
                      Semester {v.student_semester}
                    </span>

                    <span className="badge badge-light">CGPA {v.cgpa}</span>
                  </td>
                  <td>
                    <span className="fw-bold fs-8 d-block">{v.intake}</span>
                    <span
                      className={
                        "badge badge-" +
                        (v.status === "Active" ? "primary" : "danger")
                      }
                    >
                      {v.status}
                    </span>
                  </td>
                  <td>
                    <span className="fw-bold text-capitalize d-block">
                      {v.enrollmentType}
                    </span>
                    {v.corporate !== "SGU" && (
                      <span className="badge badge-light">{v.corporate}</span>
                    )}
                  </td>
                  <td>
                    <OverlayTrigger
                      delay={{ show: 250, hide: 400 }}
                      placement="top"
                      overlay={renderTooltip({ title: "Detail" })}
                    >
                      <Link
                        className="btn btn-icon btn-sm btn-light-primary"
                        to={v.student_id}
                      >
                        <i className="ki-outline ki-arrow-right fs-5 m-0"></i>
                      </Link>
                    </OverlayTrigger>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5}>No record found</td>
              </tr>
            )}
          </tbody>
        </table>

        {totalItems > 0 && (
          <div className="footer">
            <PaginationComponent
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
