import axios from "axios";
import { RequestExternalAPI } from "../ApiBackend";

const ConsumerKey =
  "Basic Y2xpZW50OmtqN0tmclVtOXNKOFdxQ0hnZE5aUWFuU0JNdkUzdWJw";

const POST_REQUEST_DOC = async (data) => {
  const param = {
    method: "post",
    payload: data,
    pathuri: `${process.env.REACT_APP_JUPITER_URL}${process.env.REACT_APP_JUPITER_PATH}/file/upload`,
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: ConsumerKey,
    },
  };
  const result = await RequestExternalAPI(param);
  return result;
};

const POST_REQUEST_DOC_MULTIPLE = (formData, onProgressCallback) => {
  return new Promise((resolve, reject) => {
    axios.post(`${process.env.REACT_APP_JUPITER_URL}${process.env.REACT_APP_JUPITER_PATH}/file/upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: ConsumerKey,
      },
      onUploadProgress: (progressEvent) => {
        if (onProgressCallback) {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          onProgressCallback(percentCompleted);
        }
      }
    })
    .then(response => {
      resolve({
        result: "success",
        data: response.data
      });
    })
    .catch(error => {
      console.error("Upload error:", error);
      resolve({
        result: "error",
        error: error.response ? error.response.data : 'Network error'
      });
    });
  });
};

const GET_REQUEST_DOC = async (data) => {
  const param = {
    method: "post",
    payload: data,
    pathuri: `${process.env.REACT_APP_JUPITER_URL}${process.env.REACT_APP_JUPITER_PATH}/file`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: ConsumerKey,
    },
  };
  const result = await RequestExternalAPI(param);
  return result;
};

const GET_STRG_FILE_INFO = async (data) => {
  const param = {
    method: "post",
    payload: data,
    pathuri: `${process.env.REACT_APP_JUPITER_URL}${process.env.REACT_APP_JUPITER_PATH}/file/info`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: ConsumerKey,
    },
  };
  const result = await RequestExternalAPI(param);
  return result;
};

const DELETE_REQUEST_DOC = async (data) => {
  const param = {
    method: "post",
    payload: data,
    pathuri: `${process.env.REACT_APP_JUPITER_URL}${process.env.REACT_APP_JUPITER_PATH}/file/delete`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: ConsumerKey,
    },
  };
  const result = await RequestExternalAPI(param);
  return result;
};

const READ_FILE = async (file_id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_JUPITER_NODE_URL}/apps/files_sharing/publicpreview/${file_id}`,
      {
        responseType: "blob",
      }
    );
    const fileUrl = URL.createObjectURL(response.data);
    return { loading: false, data: fileUrl, message: "" };
  } catch (error) {
    console.error("Error loading file:", error);
    return { loading: false, data: null, message: error.message };
  }
};

const DOWNLOAD_REQUEST_DOC = async (formData) => {
  try {
    const config = {
      method: 'post',
      url: `${process.env.REACT_APP_JUPITER_URL}${process.env.REACT_APP_JUPITER_PATH}/file/download`,
      data: formData,
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: ConsumerKey,
      },
    };

    const response = await axios(config);

    if (response.status === 200) {
      return {
        status: response.status,
        data: response.data,
        headers: response.headers,
      };
    } else {
      throw new Error('Download failed');
    }
  } catch (error) {
    console.error('Download error:', error);
    return {
      status: error.response?.status || 500,
      message: error.message || 'An error occurred during download',
    };
  }
};


export { POST_REQUEST_DOC, POST_REQUEST_DOC_MULTIPLE, GET_REQUEST_DOC, DELETE_REQUEST_DOC, READ_FILE, GET_STRG_FILE_INFO, DOWNLOAD_REQUEST_DOC };
