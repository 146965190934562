import React, { useEffect, useState } from "react";
import { GET_WIDGET_ALL_BY_GROUP } from "../../apis/module/BackendRequest";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import { LoadingSkeleton } from "../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import { TreeLineItem } from "./ListWidget";
import { ButtonItemRefresh } from "../../../../_layouts/components/datatables/MYDatatables";

export default function WTreeList() {
  const [menus, setMenus] = useState({ loading: false, data: [], message: "" });
  const [widgets, setWidgets] = useState([]);
  const ReloadData = async () => {
    setMenus({ loading: true, data: [], message: "" });

    const result = await GET_WIDGET_ALL_BY_GROUP();
    if (result.data && Object.values(result.data).length > 0) {
      const list_widget = result.data.flatMap((v) => v.widgets);
      setWidgets(list_widget);
      setMenus({ loading: false, data: result.data, message: "" });
    } else {
      setMenus(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);
  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bold fs-3 mb-1">Widget Tree List</span>
        <span className="text-muted mt-1 fw-semibold fs-7">
          Total {widgets.length} widget
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        <ButtonItemRefresh
          totalItems={widgets.length}
          onClick={() => ReloadData()}
        />
      </SGUCard.Toolbar>
      <SGUCard.Body>
        {menus.loading ? (
          <LoadingSkeleton />
        ) : menus.message ? (
          <AlertRetry messages={menus.message} Reload={ReloadData} />
        ) : (
          Object.values(menus.data).length > 0 && (
            <div>
                <div className="tree tree-line">
                {widgets.map((v, index) => (
                    <TreeLineItem key={index} data={v} />
                ))}
                </div>
            </div>
          )
        )}
      </SGUCard.Body>
    </SGUCard>
  );
}
