import React, { useState } from 'react'
import { ButtonLong } from '../../../../../../../_layouts/components/buttons/ButtonUI'
import { useNavigate, useOutletContext } from 'react-router-dom';
import { DELETE_SECTION } from '../../../../../apis/node-target-data/AcademicApi';
import { MessageError, MessageSuccess, openModal } from '../../../../../../../_layouts/components/modals/Modals';
import { AlertNotif } from '../../../../../../../_layouts/components/alerts/AlertsUI';

export default function CardDeleteSection() {
    const navigate = useNavigate();
    const context = useOutletContext();
    const data = context.data;
    const section_id = data.section_id;
    const widgets = context.widget;
    const widget_17 = widgets.find(
        (item) => item.widget_id === 17 && item.is_enabled === 1
    );

    const [results, setResults] = useState({ loading: false, message: "", data: [] });

    const DeleteSection = async () => {
        openModal({open:false})
        setResults({ loading: true, message: "", data: [] });
        const param = {
            deletetype: "section",
            section_id: section_id
        }
        const result = await DELETE_SECTION(param);

        if (result.message && result.data.length === 0) {
            openModal({open:true, message: <MessageError message={result.message} />})
            setResults(result);
        }else{
            setResults({ loading: false, message: "", data: []  });
            openModal({open:true, message: <MessageSuccess message={"Successfully removed"} />})
            setInterval(() => {
                navigate('/course-managements/section');
            }, 1000);
        }
        
    }

    const ConfirmDelSection = () => {
        console.log(results);
        const [issame, setIsSame] = useState(false);
        return (
            <div className="remove-section">
                <AlertNotif messages={"Unexpected bad things will happen if you don’t read this!"} color="primary" />
                <div className="info">
                    <p className="text-dark">
                        This action cannot be undone.
                        This will permanently delete the <span className="user-select-none fw-bold">{section_id}</span> section, Lecturer Assignment, Study Program Qualification, Student List,  and remove all owner section.
                    </p>
                    <div className="fv-row fv-plugins-icon-container mb-8">
                        <label className="required fs-6 mb-2">
                            Please type <span className="fw-bolder user-select-none">{section_id}</span> to confirm.
                        </label>
                        <input type="text" name="section_id" className='form-control form-control-lg' autoComplete='off' onChange={(e) => setIsSame((e.target.value === section_id) ? true : false)} />
                    </div>
                    <div className="text-center">
                        <button className="btn btn-danger" type='button' disabled={issame ? (results.loading ? true : false) : true} onClick={() => DeleteSection()}>
                            {results.loading ? "Processing..." : "I understand the consequences, delete this section"}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    return (
        widget_17 && (
            <div className="card bg-danger">
                <div className="card-body">
                    <h3 className='text-white'>Delete this section</h3>
                    <p className="text-white">Once you delete a section, there is no going back. Please be certain.</p>
                    <ButtonLong className="btn btn-lg btn-light" disabled={results.loading} type="button" onClick={() => openModal({ message: <ConfirmDelSection /> })}>
                        {results.loading ? (
                            <div className="spinner-border spinner-border-sm" role="status"></div>
                        ) : (
                            <span className="text-uppercase">Remove {section_id}</span>
                        )}
                    </ButtonLong>
                </div>
            </div>
        )
    )
}
