import React, { useState } from "react";
import { FilterCalendar } from "../components";
import { MessageError, openModal } from "../../../../_layouts/components/modals/Modals";
import { RequiredMsg } from "../../../../_layouts/_core/ErrorMessage";
import moment from "moment";
import { RoomSingle } from "../../components/forms/SelectBox";

export default function Filter({ isload, setPostParam, setRoomName }) {
  const [room_id, setRoom_id] = useState(0);

  const HandlerFilter = (e) => {
    e.preventDefault();
    if (room_id) {
      const monday = moment().startOf("weeks").format("YYYY-MM-DD");
      const sunday = moment().endOf("weeks").format("YYYY-MM-DD");
      setPostParam(prev => {
        const data = {...prev};
        data.room_id = room_id;
        data.start_dt = monday;
        data.end_dt = sunday;
        return data;
      })
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  }

  return (
    <FilterCalendar onSubmit={HandlerFilter} isload={isload}>
      <div className="w-300px me-3">
        <RoomSingle data={room_id} setData={setRoom_id} setRoomName={setRoomName} showtitle={false} required placeholder="Find Room" />
      </div>
    </FilterCalendar>
  );
}
