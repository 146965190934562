import {
  SGUCard,
} from "../../../../../_layouts/components/cards/CardUI";
import { KTIcon } from "../../../../../_metronic/helpers";

const CardRevisionList = ({ data }) => {
  const thesis_revision_data = data.ths_main_ex;
  const totalRevision = Object.values(thesis_revision_data).length;
  return (
    totalRevision > 0 && (
      <div className="revision-notes">
        <h1>Thesis Revision Notes</h1>
        <div className="row mt-8">
          {thesis_revision_data.map((v, index) => (
            (v.revision && (
            <div className="col-lg-4 mb-5" key={index}>
              <SGUCard>
                <SGUCard.Body className={"pt-8"}>
                  <div className="d-flex align-items-sm-center mb-5">
                    <div className="symbol symbol-45px me-4 symbol-circle">
                      <div className="symbol-label bg-light-primary">
                        <KTIcon
                          iconName="notepad-bookmark"
                          className="fs-1 text-primary"
                        />
                      </div>
                    </div>
                    <div className="flex-grow-1 me-2">
                      <span className="text-gray-500 fs-4 fw-semibold">
                        {v.emp && (v.emp.fullname || "")}
                      </span>
                      <span className="text-gray-800 fw-bold d-block fs-7">
                        {v.emp && (v.emp.email || "")}
                      </span>
                    </div>
                  </div>
                  <div className="thesis-note">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: decodeHtml(v.revision),
                      }}
                    />
                  </div>
                </SGUCard.Body>
              </SGUCard>
            </div>
            ))
          ))}
        </div>
      </div>
    )
  );
};

const decodeHtml = (html) => {
  const txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
};

export { CardRevisionList };
