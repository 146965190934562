import React, { useEffect, useState } from "react";
import { ProgressBar } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SpinnerLoad = () => {
  return (
    <>
      <span className="spinner-grow spinner-grow-sm" aria-hidden="true"></span>
      <span className="visually-hidden" role="status">
        Loading...
      </span>
    </>
  );
};

const LoadingBars = () => {
  return (
    <div className="text-center m-3">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

const LoadingSkeleton = ({ count = 3 }) => {
  return (
    <div className="my-skeleton">
      <Skeleton height={40} style={{ marginBottom: 10 }} />
      <Skeleton count={count} />
    </div>
  );
};

const NoRecordFound = ({ message = "No record found" }) => {
  return (
    <div className="border rounded p-5 text-center bg-light">
      <span className="svg-icon svg-icon-primary svg-icon-2x">
        <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
          <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <rect x="0" y="0" width="24" height="24" />
            <path
              d="M20.4061385,6.73606154 C20.7672665,6.89656288 21,7.25468437 21,7.64987309 L21,16.4115967 C21,16.7747638 20.8031081,17.1093844 20.4856429,17.2857539 L12.4856429,21.7301984 C12.1836204,21.8979887 11.8163796,21.8979887 11.5143571,21.7301984 L3.51435707,17.2857539 C3.19689188,17.1093844 3,16.7747638 3,16.4115967 L3,7.64987309 C3,7.25468437 3.23273352,6.89656288 3.59386153,6.73606154 L11.5938615,3.18050598 C11.8524269,3.06558805 12.1475731,3.06558805 12.4061385,3.18050598 L20.4061385,6.73606154 Z"
              fill="#000000"
              opacity="0.3"
            />
            <polygon
              fill="#000000"
              points="14.9671522 4.22441676 7.5999999 8.31727912 7.5999999 12.9056825 9.5999999 13.9056825 9.5999999 9.49408582 17.25507 5.24126912"
            />
          </g>
        </svg>
      </span>
      <p className="mb-0">{message}</p>
    </div>
  );
};

const LoadingProgressBar = ({ start, setProgressBar }) => {
  const [progress, setProgress] = useState(0);

  const simulateProgress = () => {
    const increaseAmount = 100 / 50;
    const interval = setInterval(() => {
      if (progress < 100) {
        setProgress((prevProgress) => {
          const newProgress = prevProgress + increaseAmount;
          return newProgress >= 100 ? 100 : newProgress;
        });
      } else {
        // window.scrollTo({
        //     top: 0,
        //     behavior: "smooth",
        // });
        clearInterval(interval);
        setProgressBar(false);
      }
    }, 1000);
  };

  if (start) {
    simulateProgress();
  }

  return <ProgressBar now={progress} label={`${progress}%`} />;
};

const LoadingProgressBarComplete = ({ start, setProgressBar, onComplete }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    let interval;
    if (start) {
      interval = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress === 100) {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
            clearInterval(interval);
            setProgressBar(false);
            if (onComplete) {
              onComplete();
            }
            return 100;
          }
          const newProgress = oldProgress + 10;
          return Math.min(newProgress, 100);
        });
      }, 200);
    }

    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [start, setProgressBar, onComplete]);

  return start ? <ProgressBar now={progress} label={`${progress}%`} /> : null;
};

export {
  SpinnerLoad,
  LoadingBars,
  LoadingSkeleton,
  NoRecordFound,
  LoadingProgressBar,
  LoadingProgressBarComplete,
};
