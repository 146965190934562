import React, { useEffect, useMemo, useState } from 'react'
import { GET_SCHD } from '../../apis/node-target-data/AcademicApi';
import { LoadingSkeleton, NoRecordFound } from '../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif, AlertRetry } from '../../../../_layouts/components/alerts/AlertsUI';
import { ButtonItemRefresh, HeaderDatatables, SearchBarTable } from '../../../../_layouts/components/datatables/MYDatatables';
import moment from 'moment';
import { ButtonDownload, ButtonIcon } from '../../../../_layouts/components/buttons/ButtonUI';
import { KTIcon } from '../../../../_metronic/helpers';
import { RowCalendar, RowLecturer, RowRoom, RowSchd, RowStatus } from './components/TimetableUI';
import { DownloadTimetable } from './DownloadTimetable';
import { openModal } from '../../../../_layouts/components/modals/Modals';
import { ConfirmRemoveSchd } from './components/TimetableComp';

export default function DetailTimetables({ section_id, lecturers, widget_50, widget_49 }) {
    const [results, setResults] = useState({
        loading: false,
        data: [],
        message: "",
    });

    const GetScheduleTimetable = async () => {
        setResults({ loading: true, message: "", data: [] });

        const param = { section_id: section_id };
        const result = await GET_SCHD(param);
        if (result.data && Object.values(result.data).length > 0) {
            setResults({ loading: false, message: "", data: result.data });
        } else {
            setResults({ loading: false, message: result.message, data: [] });
        }
    };

    useEffect(() => {
        GetScheduleTimetable();
    }, [])

    const currentDate = moment().format("YYYY-MM-D");
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const [activeTab, setActiveTab] = useState("all");

    const headers = [
        { name: "No", field: "id", sortable: false },
        { name: "Schedule Date", field: "start_dt", sortable: true },
        { name: "Room Class", field: "event_room", sortable: false },
        { name: "Lecturer", field: "atd_lect_name", sortable: true },
        { name: "Status", field: "atd_status", sortable: true },
        { name: "Session", field: "weight", sortable: false },
        { name: "Actions", field: "id", sortable: false },
        { name: "Google Calendar", field: "event_calendar", sortable: false },
    ];

    const ResultData = useMemo(() => {
        let computedData = [];

        if (Object.values(results.data).length > 0) {
            computedData = results.data.map((c) => {
                var obj = {};
                obj.start_dt = c.start_dt ? c.start_dt : "";
                obj.start_dt_formatted = c.start_dt_formatted ? c.start_dt_formatted : "";
                obj.end_dt_formatted = c.end_dt_formatted ? c.end_dt_formatted : "";
                obj.event_room = (c.event_room) ? c.event_room : "";
                obj.is_makeup = c.is_makeup ? c.is_makeup : "";
                obj.activity_type_id = c.activity_type ? c.activity_type.id : "";
                obj.activity_type = c.activity_type ? c.activity_type.name : "";
                obj.atd_lecturer = c.atd_lecturer ? c.atd_lecturer : "";
                obj.atd_lect_name = c.atd_lecturer ? ((c.atd_lecturer.emp) ? c.atd_lecturer.emp.fullname : "") : "";
                obj.atd_lect_id = c.atd_lecturer ? ((c.atd_lecturer.emp) ? c.atd_lecturer.emp.id : "") : "";
                obj.atd_teaching_topic = c.atd_lecturer ? c.atd_lecturer.teaching_topic : "";
                obj.atd_status = c.atd_lecturer ? c.atd_lecturer.status : "";
                obj.atd_lec_time = c.atd_lecturer ? c.atd_lecturer.lec_tap_time : "";
                obj.weight = c.weight ? c.weight : "";
                obj.id = c.id ? c.id : "";
                obj.event_calendar = c.event_calendar ? c.event_calendar : "";
                obj.section = (c.section) ? c.section : "";
                obj.courses = (c.course) ? c.course : "";
                obj.label = c.label? c.label : "";
                return obj;
            });

            computedData.sort((a, b) => (a.start_dt < b.start_dt ? 1 : -1));
        }

        if (activeTab !== "all") {
            computedData = computedData.filter(item => item.atd_lect_id === activeTab)
        }

        if (search) {
            computedData = computedData.filter((listData) => {
                return Object.keys(listData).some(
                    (key) =>
                        listData[key]
                            .toString()
                            .toLowerCase()
                            .includes(search)
                );
            });
        }

        setTotalItems(computedData.length);

        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        return computedData;
    }, [results.data, search, sorting, activeTab]);

    const [statusDownload, setStatusDownload] = useState(false);
    const HandlerDownload = () => {
        DownloadTimetable(section_id, setStatusDownload)
    }

    return results.loading ? <LoadingSkeleton /> :
        results.message ? <AlertRetry messages={results.message} Reload={GetScheduleTimetable} /> : (
            <>
                {Object.values(lecturers).length > 1 && (
                    <div className="d-flex justify-content-between align-items-center mb-5 ">
                        <ul className="nav nav-pills nav-pills-custom row position-relative">
                            <li className="nav-item col-auto mx-0 p-0">
                                <span className="nav-link active d-flex justify-content-center border-0 h-100 cursor-pointer" onClick={() => setActiveTab("all")}>
                                    <span className="nav-text text-gray-800 fw-bold fs-4 mb-3">
                                        All ({Object.values(results.data).length})
                                    </span>
                                    <span className={"bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px rounded " + (activeTab === "all" ? "bg-primary" : "bg-light")}></span>
                                </span>
                            </li>
                            {lecturers.map((v, index) => (
                                <li className="nav-item col-auto mx-0 p-0" key={index}>
                                    <span className="nav-link active d-flex justify-content-center border-0 h-100 cursor-pointer" onClick={() => setActiveTab(v.emp_id)}>
                                        <span className="nav-text text-gray-800 fw-bold fs-4 mb-3">
                                            {v.emp && (v.emp.fullname || "")} ({v.emp_submitted}/{v.emp_session})
                                        </span>
                                        <span className={"bullet-custom position-absolute z-index-2 bottom-0 w-100 h-4px rounded " + (activeTab === v.emp_id ? "bg-primary" : "bg-light")}></span>
                                    </span>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
                <div>
                    <div className="mb-3 d-flex justify-content-between align-items-center">
                        <SearchBarTable
                            size="w-250px"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <div className="d-flex">
                            <ButtonItemRefresh
                                totalItems={totalItems}
                                onClick={() => GetScheduleTimetable()}
                            />
                            <ButtonDownload disabled={statusDownload} type="button" className="btn-sm fw-bold ms-3" onClick={() => HandlerDownload()}>
                                {statusDownload ? "Processing..." : "Export to excel"}
                            </ButtonDownload>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
                            <HeaderDatatables
                                headers={headers}
                                onSorting={(field, order) => setSorting({ field, order })}
                            />
                            <tbody>
                                {results.loading ? (
                                    <tr>
                                        <td colSpan={8}>
                                            <LoadingSkeleton />
                                        </td>
                                    </tr>
                                ) : results.message ? (
                                    <tr>
                                        <td colSpan={8}>
                                            <AlertNotif messages={results.message} />
                                        </td>
                                    </tr>
                                ) : ResultData.length > 0 ? (
                                    ResultData.map((v, index) => (
                                        <tr key={index} className={(currentDate === moment(v.start_dt_formatted).format("YYYY-MM-D")) ? "bg-light-success" : ""}>
                                            <td className='text-center'>{index + 1}</td>
                                            <td>
                                                <RowSchd v={v} />
                                            </td>
                                            <td>
                                                <RowRoom v={v} />
                                            </td>
                                            <td width={"30%"}>
                                                <RowLecturer v={v} ReloadData={GetScheduleTimetable} lecturers={lecturers} widget_49={widget_49} />
                                                {v.label && (
                                                <div className="fs-8 text-dark-50 d-flex align-items-center badge badge-light">
                                                    <KTIcon iconName='notepad' className="fs-7 me-1" />
                                                    {v.label}
                                                </div>
                                                )}
                                            </td>
                                            <td>
                                                <RowStatus v={v} />
                                            </td>
                                            <td>{v.weight || ""}</td>
                                            <td>
                                                {widget_50 && (
                                                    <ButtonIcon type="button" className="btn-sm" onClick={() => openModal({ header: "Confirmation", message: <ConfirmRemoveSchd id={v.id} Reload={GetScheduleTimetable} /> })}>
                                                        <KTIcon iconName='trash' className='fs-1 text-hover-danger' />
                                                    </ButtonIcon>
                                                )}
                                            </td>
                                            <td>
                                                <RowCalendar v={v} />
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={8}>
                                            <NoRecordFound />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        )
}

