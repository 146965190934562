import React, { useEffect, useRef, useState } from "react";
import ApexCharts from "apexcharts";
import { getCSSVariableValue } from "../../../../../_metronic/assets/ts/_utils";

const MajorChart = ({ className }) => {
  const chartRef = useRef(null);
  const [degree, setDegree] = useState(1);

  const refreshMode = () => {
    if (!chartRef.current) {
      return;
    }

    const chart = new ApexCharts(chartRef.current, getChartOptions(degree));
    if (chart) {
      chart.render();
    }

    return chart;
  };

  useEffect(() => {
    const chart = refreshMode();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, degree]);

  return (
    <div className={`card ${className}`}>
      <div className="card-header border-0 pt-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            Major Most Choosen
          </span>
          <span className="text-muted fw-semibold fs-7">
            More than 1000 new records
          </span>
        </h3>
        <div className="card-toolbar">
          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary  px-4 me-1 ${degree === 1 && "active"}`}
            onClick={() => setDegree(1)}
          >
            Bachelor
          </a>

          <a
            className={`btn btn-sm btn-color-muted btn-active btn-active-primary px-4 me-1 ${degree === 2 && "active"}`}
            onClick={() => setDegree(2)}
          >
            Mater
          </a>
        </div>
      </div>
      <div className="card-body">
        <div
          ref={chartRef}
          id="kt_charts_widget_3_chart"
          style={{ height: "350px" }}
        ></div>
      </div>
    </div>
  );
};

export { MajorChart };

function getChartOptions(degree) {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");
  const baseColor = getCSSVariableValue("--bs-primary");

  const master = [
    "Data Science Cyber Security",
    "Data Science Business Informatics",
    "Digital Innovation",
    "Engineering Management",
    "Mechatronics",
    "Master of Business Administration",
  ];

  const bachelor = [
    "Mechatronics Engineering",
    "IT Technopreneurs",
    "AI and Data Science",
    "IE: Smart Industry",
    "IE: Eco-Industry",
    "IT Cyber Security",
    "Hybrid and Electric Vehicles",
    "Hotel and Tourism Management",
    "Business and Management",
    "Business Accounting",
    "Global Strategic Communications",
    "International Culinary Business",
    "Pharmaceutical Chemical Engineering",
    "Food Technology",
    "Biomedical Engineering",
    "Sustainable Energy & Environment",
  ];

  const dataBachelor = [
    400,
    430,
    448,
    470,
    540,
    580,
    690,
    1100,
    1200,
    1380,
    500,
    700,
    233,
    455,
    300,
    200,
  ];
  const dataMaster = [540, 580, 690, 1100, 1200, 1380];

  return {
    series: [
      {
        data: degree === 1 ? dataBachelor : dataMaster,
      },
    ],
    chart: {
      type: "bar",
      height: 350,
      toolbar: {
        show: false, // Disable the toolbar, including the download menu
      },
    },
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: "end",
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: degree === 1 ? bachelor : master,
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    grid: {
      borderColor: borderColor,
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function(val) {
          return val;
        },
      },
    },
    colors: [baseColor],
  };
}
