import React, { useEffect, useState } from 'react'
import { SGUCard } from '../../../../_layouts/components/cards/CardUI'
import { AcadTerm } from '../../components/forms/SelectBox';
import PackageJSON from "../../../../../package.json"
import { GET_RPT_TEACHINGLOAD } from '../../apis/node-target-data/AcademicApi';
import { LoadingBars, LoadingSkeleton } from '../../../../_layouts/components/loadbars/LoadingBars';
import { AlertRetry } from '../../../../_layouts/components/alerts/AlertsUI';
import { OverlayTrigger } from 'react-bootstrap';
import { renderTooltip } from '../../../../_layouts/components/popovers/PopOvers';
import { KTIcon } from '../../../../_metronic/helpers';
import { ButtonIcon } from '../../../../_layouts/components/buttons/ButtonUI';
import { DownloadAtdSummary } from '../../attendances/DownloadAtdSum';

export default function ClassList({ profile }) {
    const email = profile.sgumail;
    const mailarr = email.split(".");
    const firstname = mailarr[0];

    const empid = profile.id;
    const [term_id, setTerm_id] = useState(PackageJSON.system_param.ActiveAcad);

    const [results, setResults] = useState({
        message: "",
        data: [],
        loading: false,
    });

    const ReloadData = async () => {
        setResults({ message: "", data: [], loading: true });
        const param = { emp_id: empid, term_id: term_id };
        const result = await GET_RPT_TEACHINGLOAD(param);

        setResults(result);
    }

    useEffect(() => {
        ReloadData();
    }, [term_id]);

    const [statusDownload, setStatusDownload] = useState(false);
    const DownloadAttd = (data) => {
        const dataLecturer = [{ emp: data.emp }];
        const currentData = {
            section_id: data.section_id,
            lecturers: dataLecturer,
        };
        DownloadAtdSummary(setStatusDownload, currentData);
    };

    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bolder fs-3 mb-1">
                    <span className="text-capitalize me-1">{firstname}’s</span>
                    Course Assignments
                </span>
                <span className="text-muted mt-1 fw-bold fs-7">Courses Assigned by Term</span>
            </SGUCard.Header>
            <SGUCard.Toolbar>
                <div className="w-300px pt-5">
                    <AcadTerm data={term_id} setData={setTerm_id} showtitle={false} />
                </div>
            </SGUCard.Toolbar>
            <SGUCard.Body>
                <div className="table-responsive">
                    <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
                        <thead>
                            <tr className="text-uppercase text-muted fw-bolder">
                                <th>No</th>
                                <th>Section</th>
                                <th width="30%">Course</th>
                                <th>Department</th>
                                <th>Attendance</th>
                                <th>Score</th>
                            </tr>
                        </thead>
                        <tbody>
                            {results.loading ? <tr><td colSpan={6}><LoadingSkeleton /></td></tr> :
                                results.message ? <tr><td colSpan={6}><AlertRetry messages={results.message} Reload={ReloadData} /></td></tr> :
                                    Object.values(results.data).length > 0 ? (
                                        results.data.map((v, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>
                                                    <span className="d-block fw-bolder">{v.section_id}</span>
                                                    <span className="d-block fs-7">
                                                        Total Session: {v.section_session}
                                                    </span>
                                                    <span className="d-block fs-7">
                                                        Max Seat: {v.max_seat}
                                                    </span>
                                                </td>
                                                <td>
                                                    {v.course && (
                                                        <>
                                                            <span className="d-block fw-bolder">
                                                                {v.course.coursetitle}
                                                            </span>
                                                            <span className="d-block fs-7">{v.course.code}</span>
                                                            <span className="d-block fs-7">
                                                                Credit: {v.course.credit}
                                                            </span>
                                                        </>
                                                    )}
                                                </td>
                                                <td>
                                                    <div className="d-flex flex-wrap">
                                                        {v.section_pre
                                                            && v.section_pre.map((s, index) => (
                                                                <div key={index}>
                                                                    <OverlayTrigger
                                                                        key={index}
                                                                        delay={{ show: 250, hide: 400 }}
                                                                        placement="top"
                                                                        overlay={renderTooltip({
                                                                            title: s.clu
                                                                                ? s.clu.name + " Semester " + s.semester
                                                                                : "-",
                                                                        })}
                                                                    >
                                                                        <div className="symbol symbol-40px m-1">
                                                                            <div className="symbol-label fs-5 fw-semibold bg-primary text-inverse-primary">
                                                                                {s.clu_spec ? (
                                                                                    (s.clu_spec.code || "-")
                                                                                ) : (
                                                                                    s.clu && (s.clu.code || "-")
                                                                                )}
                                                                                -{s.semester}
                                                                            </div>
                                                                        </div>
                                                                    </OverlayTrigger>
                                                                </div>
                                                            ))
                                                        }
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="btn-group">
                                                        {statusDownload ? <LoadingBars /> : (
                                                            <ButtonIcon type="button" className="btn-sm btn-light" title="Download" onClick={() => DownloadAttd(v)}>
                                                                <KTIcon iconName='cloud-download' className='text-primary fs-1' />
                                                            </ButtonIcon>
                                                        )}
                                                        <ButtonIcon type="button" className="btn-sm btn-light" title="View Attendance">
                                                            <KTIcon iconName='arrow-right' className='fs-1' />
                                                        </ButtonIcon>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ButtonIcon type="button" className="btn-sm btn-light" title="View Score">
                                                        <KTIcon iconName='arrow-right' className='fs-1' />
                                                    </ButtonIcon>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={6}>No record found</td>
                                        </tr>
                                    )}
                        </tbody>
                    </table>
                </div>


            </SGUCard.Body>
        </SGUCard>
    )
}
