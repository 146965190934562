import React from "react";
import { useOutletContext } from "react-router-dom";
import { ModalCharts } from "./charts/ModalCharts";
import { Line } from "./charts/Line";
import { Donat } from "./charts/Donat";
import { FeeData } from "./charts/FeeData";
import { MajorChart } from "./charts/MajorChart";
import { Bar } from "./charts/Bar";

export function ADMDashboard() {
  const context = useOutletContext();
  const widget_106 = context.widgets.find(
    (item) => item.widget_id === 106 && item.is_enabled === 1
  );
  return (
    <div>
      <div className="row g-5 g-xl-8">
        <div className="col-xl-6">
          <Line className="card-xl-stretch mb-xl-8" />
        </div>
        <div className="col-xl-3">
          <Donat className="card-xl-stretch mb-xl-8" />
        </div>
        <div className="col-xl-3">
          <div className="row g-0">
            <FeeData
              className="card-xl-stretch mb-8"
              chartColor="primary"
              chartHeight="150px"
              title="Registration Fee"
            />
            <FeeData
              className="card-xl-stretch mb-8"
              chartColor="primary"
              chartHeight="150px"
              title="Tuition Fee"
            />
          </div>
        </div>
      </div>

      <div className="row g-5 g-xl-8">
        <div className="col-xl-4">
          <MajorChart className="card-xl-stretch mb-xl-8" />
        </div>
        <div className="col-xl-8">
          <Bar className="card-xl-stretch mb-xl-8" />
        </div>
      </div>

      <div className="row g-5 g-xl-8">
        <div className="col-12"></div>
      </div>
    </div>
  );
}
