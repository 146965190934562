import { OverlayTrigger } from "react-bootstrap";
import { Link } from "react-router-dom";
import { renderPopOver } from "../../../../_layouts/components/popovers/PopOvers";

const bodyOverlaySection = (obj) => {
  return (
    <div className="section-item">
      <p className="text-dark">
        Section id: <br />
        <span className="text-primary">{obj.section_id}</span>
      </p>
      <p className="text-dark">
        Department: <br />
        <span className="text-primary">
          {obj.cost_center ? obj.cost_center.name : ""}
        </span>
      </p>
      {obj.corporate ? (
        <p className="text-dark">
          Corporate: <br />
          <span className="text-primary">{obj.corporate.name}</span>
        </p>
      ) : (
        ""
      )}
      <p className="text-dark">
        Max Session: <br />
        <span className="text-primary">{obj.section_session}</span>
      </p>
      <p className="text-dark">
        Max Seat: <br />
        <span className="text-primary">{obj.max_seat}</span>
      </p>
    </div>
  );
};

const SectionLabel = ({ item, index }) => {
  return (
    <OverlayTrigger
      key={index}
      delay={{ show: 250, hide: 400 }}
      placement="top"
      overlay={renderPopOver({
        header: "Detail Section",
        body: bodyOverlaySection(item),
      })}
    >
      <Link
        target="_blank"
        className="text-gray-800 text-hover-primary fs-6 fw-bold"
        to={`/course-managements/section/${item.section_id}/detail`}
        target="_blank"
      >
        <div className="me-5 w-150px">
          <div className="d-flex">
            <div className="symbol symbol-40px me-4">
              <div className="symbol-label fs-5 fw-semibold bg-danger text-inverse-danger">
                {item.cost_center
                  ? item.cost_center.code
                    ? item.cost_center.code
                    : "-"
                  : "-"}
              </div>
            </div>
            <div className="section-info">
              <span className="d-block">{item.section_id}</span>
              <span className="text-muted fw-semibold d-block fs-7">
                Max Session {item.section_session}
              </span>
            </div>
          </div>
        </div>
      </Link>
    </OverlayTrigger>
  );
};

export { SectionLabel };
