import React from 'react'
import Accordion from 'react-bootstrap/Accordion';
import FormOverview from './FormOverview';
import FormBiodata from './FormBiodata';
import FormFamily from './FormFamily';
import FormInstitution from './FormInstitution';

export function Biodata({ stdAcad, stdBio }) {
    return (
        (Object.values(stdAcad).length > 0 && Object.values(stdBio).length > 0) ? (
            <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <div className="flex-column">
                            <h2 className="mb-1">Student Status</h2>
                            <span className="fs-6 fw-bold text-muted">Academic Overview</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <FormOverview stdAcad={stdAcad} stdBio={stdBio} />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                    <Accordion.Header>
                        <div className="flex-column">
                            <h2 className="mb-1">Student Data</h2>
                            <span className="fs-6 fw-bold text-muted">General Information</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <FormBiodata stdBio={stdBio} />
                    </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                    <Accordion.Header>
                        <div className="flex-column">
                            <h2 className="mb-1">Student Family</h2>
                            <span className="fs-6 fw-bold text-muted">Family General Information</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <FormFamily stdBio={stdBio} />
                    </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                    <Accordion.Header>
                        <div className="flex-column">
                            <h2 className="mb-1">Student Institutions</h2>
                            <span className="fs-6 fw-bold text-muted">History of education</span>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <FormInstitution stdBio={stdBio} />
                    </Accordion.Body>
                </Accordion.Item>

            </Accordion>
        ) : (
            <NoRecordFound />
        )
    )
}
