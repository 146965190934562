const LecturerTypeName = (value) =>{
    var label = "";
    if (value === "fulltime") {
        label = "Full Time";
    }else if (value === "semifulltime") {
        label = "Semi Full Time";
    }else if (value === "parttime") {
        label  = "Part Time";
    }else{
        label = value;
    }
    return label;
}

export {LecturerTypeName}