import React, { useEffect, useState } from "react";
import FilterUI from "../../../components/filters/FilterUI";
import {
  CandidateName,
  DateRangeFilter,
  Degree,
  EnrollmentType,
  LimitFetch,
  RegistrationFeeStatus,
  RegistrationStatus,
  TuitionFeeStatus,
} from "../../components/EnrollmentComp";
import {
  DepartmentEnrollment,
  MonthIntakeListFilter,
  YearIntakeList,
  YearIntakeListFilter,
} from "../../components/Dropdown";
import { useProvider } from "../../../../../_layouts/UMSLayoutProvider";

const Filter = ({ SubmitFilter, results, setResults }) => {
  const {account} = useProvider()
  const previousFilter = sessionStorage.getItem("lastVisit")
    ? sessionStorage.getItem("previousFilter")
    : null;

  const [dateRange, setDateRange] = useState(
    previousFilter ? JSON.parse(previousFilter).dateRange ?? "" : ""
  );

  const [candidatename, setCandidateName] = useState(
    previousFilter ? JSON.parse(previousFilter).candidatename ?? "" : ""
  );

  const [limit, setLimit] = useState(
    previousFilter ? JSON.parse(previousFilter).limit ?? 10 : 10
  );
  const [yearintake, setYearintake] = useState(
    previousFilter ? JSON.parse(previousFilter).yearintake ?? 27 : 27
  );
  const [month_intake, setMonth_intake] = useState(
    previousFilter ? JSON.parse(previousFilter).month_intake ?? "" : ""
  );
  const [target_department, setTargetDepartment] = useState(
    previousFilter ? JSON.parse(previousFilter).target_department ?? "" : ""
  );
  const [status, setStatus] = useState(
    previousFilter ? JSON.parse(previousFilter).status ?? "" : ""
  );
  const [program_id, setProgram_id] = useState(
    previousFilter ? JSON.parse(previousFilter).program_id ?? "" : ""
  );
  const [regfee_status, set_regfee_status] = useState(
    previousFilter ? JSON.parse(previousFilter).regfee_status ?? "" : ""
  );
  const [tuition_status, set_tuition_status] = useState(
    previousFilter ? JSON.parse(previousFilter).tuition_status ?? "" : ""
  );
  const [enrollment_type, set_enrollment_type] = useState(
    previousFilter ? JSON.parse(previousFilter).enrollment_type ?? "" : ""
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    const paramFilter = {
      yearintake,
      month_intake,
      target_department,
      status,
      limit,
      [candidatename[0] == 1 || candidatename[0] == 2
        ? "candidate_id"
        : "candidatename"]: candidatename,
      program_id,
      ...dateRange,
      regfee_status,
      tuition_status,
      enrollment_type
    };
    SubmitFilter(paramFilter);
  };

  return (
    <FilterUI
      title="Registration"
      onSubmit={handleSubmit}
      isload={results.loading}
    >
      <YearIntakeListFilter data={yearintake} setData={setYearintake} />
      <MonthIntakeListFilter data={month_intake} setData={setMonth_intake} />
      <DepartmentEnrollment
        data={target_department}
        setData={setTargetDepartment}
        title="Department"
      />
      <EnrollmentType data={enrollment_type} setData={set_enrollment_type} />
      <Degree data={program_id} setData={setProgram_id} />
      {account.typeCK === "development" && (
        <>
          <RegistrationFeeStatus data={regfee_status} setData={set_regfee_status} />
          <TuitionFeeStatus data={tuition_status} setData={set_tuition_status} />
        </>
      )}
      <RegistrationStatus data={status} setData={setStatus} />
      <CandidateName
        data={candidatename}
        setData={setCandidateName}
        placeholder="Type Name or Candidate ID"
      />
      <DateRangeFilter data={dateRange} setData={setDateRange} />
      <LimitFetch data={limit} setData={setLimit} />
    </FilterUI>
  );
};

export default Filter;
