import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ButtonIcon, ButtonPrimary } from "../../../../_layouts/components/buttons/ButtonUI";
import { AlertNotif, AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import { EmployeeSelect } from "../../components/forms/SelectBox";
import { GET_EMP_BIO_BY_IDENID } from "../../apis/node-target-data/HRAPI";
import { LoadingBars, LoadingSkeleton, SpinnerLoad } from "../../../../_layouts/components/loadbars/LoadingBars";
import { GET_GROUPS, GET_USER_BY_EMPID, POST_ADD_USER, UPDATE_MEMBER_GROUP, UPDATE_STATUS_USER } from "../../apis/module/BackendRequest";
import { RequiredMsg } from "../../../../_layouts/_core/ErrorMessage";
import { MessageError, MessageSuccess, openModal } from "../../../../_layouts/components/modals/Modals";
import { KTIcon } from "../../../../_metronic/helpers";

export default function FormUser({ ReloadData }) {
  const [emp_id, setEmp_id] = useState(0);
  const [role_id, setRole_id] = useState(0);
  const [postParam, setPostParam] = useState({
    email: "",
    employeeid: "",
    is_active: 1,
    role_id: role_id
  });

  const [employee, setEmployee] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const GET_EMP_DATA = async () => {
    setEmployee({ loading: true, data: [], message: "" });
    const param = { empid: emp_id };
    const result = await GET_EMP_BIO_BY_IDENID(param);

    if (result.data && Object.values(result.data).length > 0) {
      setPostParam({ ...postParam, email: result.data.sgumail, employeeid: result.data.employee_id });
    }

    setEmployee(result);
  }

  useEffect(() => {
    if (emp_id !== 0) {
      GET_EMP_DATA();
    }
  }, [emp_id])

  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const HandlerSubmit = async (e) => {
    e.preventDefault();
    setSubmit({ loading: true, message: "", data: [] });
    if (postParam.email && postParam.employeeid && role_id) {

      //check is exist user
      const param_exist_user = { email: postParam.email, employeeid: postParam.employeeid }
      const result_exist = await GET_USER_BY_EMPID(param_exist_user);

      if (result_exist.data && Object.values(result_exist.data).length > 0) {
        setSubmit({ loading: false, message: "This user is already registered in the system", data: [] });
      } else {
        const param = {
          email: postParam.email,
          is_active: postParam.is_active,
          role_id: role_id,
          employeeid: postParam.employeeid
        }
        const result = await POST_ADD_USER(param);
        if (result.data && Object.values(result.data).length > 0) {
          ReloadData();
          openModal({ message: <MessageSuccess message={"Successfully saved"} /> })
          setSubmit({ loading: false, message: "", data: [] });
        } else {
          setSubmit({ loading: false, message: "Failed save data", data: [] });
        }
      }

    } else {
      setSubmit({ loading: false, message: RequiredMsg, data: [] });
    }
  };
  return (
    <form method="post" autoComplete="off" onSubmit={(e) => HandlerSubmit(e)}>
      <h3 className="mb-5">Form user</h3>
      <EmployeeSelect data={emp_id} setData={setEmp_id} />
      {employee.loading ? <LoadingSkeleton /> :
        employee.message ? <AlertRetry messages={employee.message} Reload={GET_EMP_DATA} /> :
          Object.values(employee.data).length > 0 && (
            <>
              <div className="form-group mb-5">
                <label className="required fw-bold">Email</label>
                <input type="text" className="form-control" readOnly defaultValue={postParam.email} />
              </div>

              <SelectGroup data={postParam.role_id} setData={setRole_id} required />

              <div className="form-group mb-5">
                <label className="required fw-bold">Status</label>
                <select name="status" className="form-select" defaultValue={postParam.is_active} onChange={(e) => setPostParam({ ...postParam, is_active: parseInt(e.target.value) })}>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
              </div>

              {submit.message && (
                <div className="my-5">
                  <AlertNotif messages={submit.message} color="danger" />
                </div>
              )}

              <div className="text-end">
                <ButtonPrimary type="submit" className="btn-lg" disabled={submit.loading}>
                  {submit.loading ? "Processing..." : "Save Changes"}
                </ButtonPrimary>
              </div>
            </>
          )}
    </form>
  );
}

const SelectGroup = ({ data, setData, required = false, disabled = false, showtitle = true, title }) => {
  const [selected, setSelected] = useState(data || "");
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    setResult({
      loading: true,
      message: "",
      data: [],
    });

    const result = await GET_GROUPS();

    if (result.data && Object.values(result.data).length > 0) {
      const option_result = result.data.map((v) => ({
        value: v.group_id,
        label: v.name,
      }));
      setOptions(option_result);
      if (selected) {
        const findSelected = option_result.find(
          (item) => item.value === selected
        );
        setSelectedOption(findSelected);
      }
      setResult(result);
    } else {
      setResult(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandleChange = (option) => {
    setSelectedOption(option);
    setSelected(option.value);
    setData(option.value);
  };

  return (
    <div className={"form-group mb-5"}>
      {showtitle && (
        <label className={"fw-bold " + (required && "required")}>
          {title || "Group Type"}
        </label>
      )}
      {result.loading ? (
        <LoadingBars />
      ) : result.message ? (
        <AlertRetry messages={result.message} Reload={() => ReloadData()} />
      ) : (
        Object.values(result.data).length > 0 && (
          <Select
            name="group_type"
            classNamePrefix="react-select"
            value={selectedOption}
            onChange={HandleChange}
            isDisabled={disabled}
            options={options}
            placeholder={"Choose One"}
          />
        )
      )}
    </div>
  );
};


export const UpdateGroup = ({ user_id, group_id, group_name, ReloadData }) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [role_id, setRole_id] = useState(group_id);
  const UpdateForm = async () => {
    if (group_id === role_id) {
      openModal({ message: <MessageError message={"The group type you selected is still the same as before. Please review your input."} /> });
    } else {
      const param = { group_id: role_id, user_id };
      console.log(param);
      const result = await UPDATE_MEMBER_GROUP(param);
      if (result.data && Object.values(result.data).length > 0) {
        openModal({ message: <MessageSuccess message={"Successfully updated"} /> });
        ReloadData();
      } else {
        openModal({ message: <MessageError message={"Failed save the data"} /> });
      }
    }

  }
  return isUpdate ? (
    <div className="d-flex align-items-center">
      <div className="w-50">
        <SelectGroup data={role_id} setData={setRole_id} showtitle={false} />
      </div>
      <div className="mb-5 ms-3 btn-group">
        <ButtonIcon type="button" className="btn-sm btn-primary" title="Submit" onClick={() => UpdateForm()}>
          <KTIcon iconName="check" className="fs-1" />
        </ButtonIcon>
        <ButtonIcon type="button" className="btn-sm btn-light" title="Cancel" onClick={() => setIsUpdate(false)}>
          <KTIcon iconName="cross" className="fs-1" />
        </ButtonIcon>
      </div>
    </div>
  ) : (
    <div className="d-flex align-items-center">
      {group_name}
      <ButtonIcon type="button" className="btn-sm" title="Update" onClick={() => setIsUpdate(true)}>
        <KTIcon iconName="message-edit" className="fs-2" />
      </ButtonIcon>
    </div>
  )
}

export const UpdateStatus = ({ user_id, is_active, ReloadData }) => {
  const [isenabled, setIsenabled] = useState(
    is_active === 1 ? true : false
  );

  const [result, setResult] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const handlerTick = async (e) => {
    const is_checked = e.target.checked;
    setResult({ loading: true, message: "", data: [] })
    let param = { user_id, is_active: is_active }
    if (is_checked) {
      param = { ...param, is_active: 1 }
    } else {
      param = { ...param, is_active: 0 }
    }
    console.log(param);
    const result = await UPDATE_STATUS_USER(param);
    if (result.data && Object.values(result.data).length > 0) {
      ReloadData();
    }else{
      openModal({message:<MessageError message={"Failed update status"} />})
      setResult(result);
    }
    
  }
  return result.loading ? <SpinnerLoad /> : (
    <div className="form-check form-switch">
      <input
        type="checkbox"
        name="is_group"
        className="form-check-input"
        defaultChecked={isenabled}
        defaultValue={user_id}
        onChange={(e) => handlerTick(e)}
        title={isenabled ? "Active" : "Inactive"}
      />
    </div>
  )
}

