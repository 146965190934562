import React from "react";
import { NoRecordFound } from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { CurrentGradeCumulative } from "./CurrentGradeCumulative";
import TimetableStd from "./TimetableStd";
import ClassList from "./ClassList";
import CardCounselor from "./CardCounselor";
import { KTIcon } from "../../../../../../_metronic/helpers";
import GraphicsGPA from "./GraphicsGPA";
import { CardLeaveResign, PCFAmmount } from "./CardActivity";

export function AcademicInfo({ stdAcad, stdBio }) {

  return Object.values(stdAcad).length > 0 || Object.values(stdBio).length > 0 ? (
    <>
      <div className="row gx-5 gx-xl-10">
        <div className="col-xl-4 mb-10">
          <div className="page-title d-flex flex-column justify-content-center flex-wrap me-3 mb-8">
            <div className="d-flex align-items-center flex-grow-1 me-2 me-sm-5">
              <KTIcon iconName="calendar" className="text-primary fs-3x" />
              <div className="ms-3">
                <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
                  {stdAcad.atp_term && (stdAcad.atp_term.name || "")}
                </h1>
                <span className="text-muted">
                  Active Calendar Academic
                </span>
              </div>
            </div>
          </div>
          <div className="mb-8">
            <CurrentGradeCumulative data={stdAcad} />
          </div>
          <GraphicsGPA data={stdAcad} />
        </div>
        <div className="col-xl-8 mb-10">
          <TimetableStd data={stdAcad} />
        </div>
      </div>
      <div className="row gx-5 gx-xl-10">
        <div className="col-xl-12 mb-10">
          <ClassList stdAcad={stdAcad} stdBio={stdBio} />
        </div>
      </div>
      {stdAcad.status.name !== "Resign" && (
        <div className="row gx-5 gx-xl-10">
          <div className="col-xl-4 mb-10">
            <CardCounselor studentid={stdAcad.studentid} />
          </div>
          <div className="col-xl-4 mb-10">
            <CardLeaveResign stdAcad={stdAcad} stdBio={stdBio} />
          </div>
          <div className="col-xl-4 mb-10">
            <PCFAmmount studentid={stdAcad.studentid} />
          </div>
        </div>
      )}
    </>
  ) : (
    <NoRecordFound />
  );
}
