import { useEffect, useState } from "react"
import { LoadingBars } from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../../../_layouts/components/alerts/AlertsUI";
import { GET_STUDENT_OUTSTANDING } from "../../../../apis/node-target-data/AcademicApi";
import { MessageError, openModal } from "../../../../../../_layouts/components/modals/Modals";
import { ButtonIcon } from "../../../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../../../_metronic/helpers";
import LeaveStd from "./Leave";
import ResignStd from "./Resign";
import { useOutletContext } from "react-router-dom";

const PCFAmmount = ({ studentid }) => {
    const [results, setResults] = useState({ loading: false, data: [], message: "" });

    const ReloadData = async () => {
        setResults({ loading: true, data: [], message: "" });
        const param = { studentid: studentid }
        const result = await GET_STUDENT_OUTSTANDING(param);
        setResults(result);
    }

    useEffect(() => {
        ReloadData();
    }, []);

    return (
        results.loading ? <LoadingBars /> :
            results.message ? <AlertRetry messages={results.message} Reload={ReloadData} /> :
                Object.values(results.data).length > 0 && (
                    <div className="card h-100 mb-8" style={{ backgroundColor: "#CBD4F4" }}>
                        <div className="card-body d-flex flex-column flex-center">
                            <h1 className="fs-2x">{results.data.formatted}</h1>
                            <p className="fw-bolder text-white text-capitalize text-center">
                                <span className="d-block">Outstanding Amount</span>
                                <span className="badge badge-primary">Current Invoice</span>
                            </p>
                        </div>
                    </div>
                )
    )
}

const CardLeaveResign = ({ stdAcad, stdBio }) => {
    const context = useOutletContext();
    const widget_72 = context.widgets.find(
        (item) => item.widget_id === 72 && item.is_enabled === 1
    );
    const widget_73 = context.widgets.find(
        (item) => item.widget_id === 73 && item.is_enabled === 1
    );
    const HandlerOpenForm = () =>{
        const status = stdAcad.status.name;
        if(status === "Active"){
            openModal({ header: "Resignation Form", message: <ResignStd stdAcad={stdAcad} />, size: "lg" })
        }else{
            openModal({message:<MessageError message={"Unable to submit the resignation form because "+stdBio.fullname+" are not an active student. Status student is "+status} />});
        }
    }
    return (
        <div className="h-100">
            {widget_72 && (
            <div className="card mb-5">
                <div className="card-body p-5">
                    <div className="d-flex justify-content-between align-items-center">
                        <h3 className="text-info fs-2x mb-0">Leave Semester</h3>
                        <ButtonIcon
                            className="btn-sm btn-clear"
                            type="button"
                            title="Submit Leave"
                            onClick={() => openModal({ header: "", message: <LeaveStd stdBio={stdBio} stdAcad={stdAcad} />, size: "lg" })}
                        >
                            <KTIcon iconName="notepad-edit" className="text-warning fs-1" />
                        </ButtonIcon>
                    </div>
                </div>
            </div>
            )}
            {widget_73 && (
            <div className="card bg-danger">
                <div className="card-body p-5">
                    <div className="d-flex justify-content-between align-items-center">
                        <h3 className="text-white fs-2x mb-0">Resignation Form</h3>
                        <ButtonIcon
                            className="btn-sm btn-clear"
                            type="button"
                            title="Submit Resign"
                            onClick={() => HandlerOpenForm()}
                        >
                            <KTIcon iconName="notepad-edit" className="text-white fs-1" />
                        </ButtonIcon>
                    </div>
                </div>
            </div>
            )} 
        </div>
    );
};

export { PCFAmmount, CardLeaveResign }