import React, { useEffect, useState } from "react";
import { useLocation, useOutletContext } from "react-router-dom";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import {
  AccessInvalid,
  RequiredMsg,
} from "../../../../_layouts/_core/ErrorMessage";
import { openModal } from "../../../../_layouts/components/modals/Modals";
import { MessageError } from "../../admissions/components/Modals";
import { GET_STUDENT } from "../../apis/node-target-data/AcademicApi";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";
import {
  StudentID,
  StudentName,
  YearGraduate,
} from "../../components/forms/InputText";
import {
  AcadProgram,
  AcadTerm,
  Corporate,
  Department,
} from "../../components/forms/SelectBox";
import FilterUI from "../../components/filters/FilterUI";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import { LoadingSkeleton, NoRecordFound } from "../../../../_layouts/components/loadbars/LoadingBars";
import TableData from "./TableData";

export function Matriculation() {
  const context = useOutletContext();
  const widget_107 = context.widgets.find(
    (item) => item.widget_id === 107 && item.is_enabled === 1
  );

  const location = useLocation();

  const status = "asc.student.status.active";
  const [expand, setExpand] = useState(false);
  const [studentname, setStudentname] = useState(location.state ? (location.state.param && (location.state.param.studentname) ||  "") :  "");
  const [studentid, setStudentid] = useState(location.state ? (location.state.param && (location.state.param.studentid) ||  "") :  "");
  const [clu_id, setClu_id] = useState(location.state ? (location.state.param && (location.state.param.clu_id) ||  0) :  0);
  const [corporate_id, setCorporate_id] = useState(location.state ? (location.state.param && (location.state.param.corporate_id) ||  0) :  0);
  const [term_intake_id, setTerm_intake_id] = useState(location.state ? (location.state.param && (location.state.param.term_intake_id) ||  0) :  0);
  const [term_id, setTerm_id] = useState(location.state ? (location.state.param && (location.state.param.term_id) ||  0) :  0);
  const [yudisium_year, setYudisium_year] = useState(location.state ? (location.state.param && (location.state.param.yudisium_year) ||  "") :  "");
  const [program_id, setProgram_id] = useState(location.state ? (location.state.param && (location.state.param.program_id) ||  0) :  0);

  const postParam = {
    studentname,
    studentid,
    clu_id,
    corporate_id,
    term_id,
    term_intake_id,
    yudisium_year,
    program_id,
  };

  useEffect(()=>{
    sessionStorage.removeItem("courseData");
    if(location.state && location.state.param){
      ReloadData();
    }
  },[location.state])

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadData = async () => {
    setResults({ loading: true, message: "", data: [] });
    let param = RemoveEmptyValue(postParam);
    param.status = status;
    const result = await GET_STUDENT(param);
    if(result.data && Object.values(result.data).length > 0){
      const only_transfer_std = result.data.filter(item => (item.enrollment_type_id.includes("transfer") || item.enrollment_type_id.includes("cooperation")));
      setResults({ loading: false, message: "", data: only_transfer_std });
    }else{
      setResults(result);
    }
    
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const SubmitFilter = (e) => {
    e.preventDefault();
    const param = RemoveEmptyValue(postParam);
    if (Object.keys(param).length > 0) {
      ReloadData();
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  };

  return widget_107 ? (
    <div className="row">
      <div className="col-lg-3">
        <FilterUI
          title="Student Active"
          onSubmit={SubmitFilter}
          isload={results.loading}
        >
          <StudentName data={studentname} setData={setStudentname} />
          <StudentID data={studentid} setData={setStudentid} />
          <Department data={clu_id} setData={setClu_id} />
          <AcadTerm data={term_id} setData={setTerm_id} />
          <AcadTerm
            data={term_intake_id}
            setData={setTerm_intake_id}
            title="Term Intake"
          />
          <p
            className="text-end mb-0 cursor-pointer mb-5"
            onClick={() => setExpand(!expand)}
          >
            {!expand ? (
              <>
                <span className="text-primary me-2">Show more</span>
                <i className="bi bi-chevron-down text-primary"></i>
              </>
            ) : (
              <>
                <span className="text-primary me-2">Show less</span>
                <i className="bi bi-chevron-up text-primary"></i>
              </>
            )}
          </p>
          <div className={"expand-filter " + (!expand ? "d-none" : "")}>
            <AcadProgram data={program_id} setData={setProgram_id} />
            <Corporate data={corporate_id} setData={setCorporate_id} />
            <YearGraduate data={yudisium_year} setData={setYudisium_year} />
          </div>
        </FilterUI>
      </div>
      <div className="col-lg-9">
        <SGUCard>
          <SGUCard.Header>
            <span className="card-label fw-bold text-gray-800">
              Browse Student Active
            </span>
          </SGUCard.Header>
          <SGUCard.Body>
            {results.loading ? (
              <LoadingSkeleton />
            ) : results.message ? (
              <AlertNotif messages={results.message} />
            ) : Object.values(results.data).length > 0 ? (
              <TableData data={results.data} ReloadData={ReloadData} paramST={RemoveEmptyValue(postParam)} />
            ) : (
              <NoRecordFound />
            )}
          </SGUCard.Body>
        </SGUCard>
      </div>
    </div>
  ) : (
    <AlertNotif messages={AccessInvalid} />
  );
}
