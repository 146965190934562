import React, { useState } from 'react'
import { SGUCard } from '../../../../../../../_layouts/components/cards/CardUI'
import { Link, useOutletContext } from 'react-router-dom';
import { ButtonIcon, ButtonLong, ButtonPrimary, ButtonTersier } from '../../../../../../../_layouts/components/buttons/ButtonUI';
import { ADD_STUDENT_CLASSROOM_BATCH, ADD_TEACHER_CLASSROOM_BATCH, CREATE_CLASSROOM, DELETE_CLASSROOM } from '../../../../../apis/node-target-data/AcademicApi';
import { MessageSuccess, openModal } from '../../../../../../../_layouts/components/modals/Modals';
import { LoadingProgressBar } from '../../../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../../../../_layouts/components/alerts/AlertsUI';
import FormJoinClassRoom from './FormJoinClassRoom';

export function CardGoogleClass() {
    const context = useOutletContext();
    const data = context.data;
    const widgets = context.widget;
    const widget_16 = widgets.find(
        (item) => item.widget_id === 16 && item.is_enabled === 1
    );
    const widget_17 = widgets.find(
        (item) => item.widget_id === 17 && item.is_enabled === 1
    );

    const [submit, setSubmit] = useState({
        loading: false,
        message: "",
        data: []
    });
    const [is_progres, setProgressBar] = useState(false);

    const handlerOpenClassRoom = async (type) => {
        if (type === "create") {
            setProgressBar(true); setSubmit({
                loading: true,
                message: "",
                data: []
            });

            CreateClassRoom();
        } else if (type === "join") {
            openModal({ header: "Join existing google class room", message: <FormJoinClassRoom section_id={data.section_id} />, size: "xl" })
        } else {
            alert("Unknown type");
        }
    }

    const CreateClassRoom = async () => {
        const param = { section_id: data.section_id }
        const result = await CREATE_CLASSROOM(param);

        if (result.data && Object.values(result.data).length > 0) {
            setProgressBar(false); setSubmit(result);
            setInterval(() => {
                window.location.reload();
            }, 1000);
        } else {
            const msg = result.message.includes("00000");
            if (msg) {
                setSubmit({ loading: false, message: "An error occurred while creating the classroom. Please try again in a moment", data: [] });
            } else {
                setSubmit(result);
            }
            setProgressBar(false);
        }
    }

    const handlerSync = async () => {
        setSubmit({
            loading: true,
            message: "",
            data: []
        })
        const param = { section_id: data.section_id };
        const result_teacher = await ADD_TEACHER_CLASSROOM_BATCH(param);
        const result_std = await ADD_STUDENT_CLASSROOM_BATCH(param);

        if (result_teacher.data && result_std.data) {
            openModal({ message: <MessageSuccess message={"Synchronization was successful."} /> })
            setSubmit({
                loading: false,
                message: "",
                data: []
            })
        } else {
            setSubmit({
                loading: false,
                message: result_teacher.message + " " + result_std.message,
                data: []
            })
        }


    }

    const handlerRemoveClassRoom = async () => {
        if(confirm("Are you sure want to remove?") === true) {
            setSubmit({
                loading: true,
                message: "",
                data: []
            })
            const param = { section_id: data.section_id }
            const result = await DELETE_CLASSROOM(param);

            setSubmit(result);
            setInterval(() => {
                window.location.reload();
            }, 1000);
        }
    }

    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bold text-gray-800">Google Class Room</span>
                <span className="text-muted mt-1 fw-bold fs-7">Integrated cross system</span>
            </SGUCard.Header>
            <SGUCard.Body >
                {submit.message && (
                    <div className="my-8">
                        <AlertNotif messages={submit.message} />
                    </div>
                )}
                <div className="d-flex flex-column h-300px justify-content-between align-items-center">
                    <div className="w-100 h-100">
                        {data.g_ownerId ? (
                            <div className="my-5">
                                <div className="mb-3">
                                    <p className="mb-0 text-muted">Calendar ID</p>
                                    <p className="mb-0 fw-bolder">{data.g_calendarId}</p>
                                </div>
                                <div className="mb-3">
                                    <p className="mb-0 text-muted">Account</p>
                                    <p className="mb-0 fw-bolder">{data.g_ownerIdEmail}</p>
                                </div>
                                <div className="mb-3">
                                    <p className="mb-0 text-muted">Name</p>
                                    <p className="mb-0 fw-bolder" title='Click to open class room'>
                                        <Link to={data.g_alternateLink} target="_blank" className="text-dark text-hover-primary">
                                            {data.g_name} <i className="bi bi-link-45deg"></i>
                                        </Link>
                                    </p>
                                </div>
                                <div className="mb-3">
                                    <p className="mb-0 text-muted">Section</p>
                                    <p className="mb-0 fw-bolder">{data.g_section}</p>
                                </div>

                                {widget_17 && (
                                    <div className="d-grid">
                                        <ButtonLong
                                            className="btn-sm btn-light btn-active-light-danger"
                                            onClick={() => handlerRemoveClassRoom()}
                                        >
                                            <i className="ki-outline ki-trash fs-5 m-0"></i> Remove classroom
                                        </ButtonLong>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <div className="d-flex align-items-center justify-content-center h-100">
                                <div className='text-center'>

                                    {is_progres || submit.loading ? (
                                        <div className="w-100">
                                            <p className="text-muted">Please wait a moment, generating the classroom.</p>
                                            <LoadingProgressBar start={true} setProgressBar={setProgressBar} />
                                        </div>
                                    ) : (
                                        <>
                                            <p className='text-muted'>This section does not yet have a  Google Classroom</p>
                                            {widget_16 && (
                                                <ButtonPrimary className="btn-sm fw-bold" type="button" onClick={() => handlerOpenClassRoom('create')}>
                                                    <i className="bi bi-plus-circle"></i> Create new class room
                                                </ButtonPrimary>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    {widget_16 && (
                        <div className="footer w-100 text-end">
                            {!is_progres && (
                                <div className="btn-group">
                                    <ButtonTersier className="btn-sm btn-light-info" type="button" onClick={() => handlerOpenClassRoom('join')}>
                                        <i className="bi bi-arrow-left-right"></i>
                                        <span>Join existing class room</span>
                                    </ButtonTersier>
                                    <ButtonPrimary className="btn-sm" type="button" onClick={() => handlerSync()} disabled={submit.loading}>
                                        {submit.loading ? (
                                            <span>Processing...</span>
                                        ) : (
                                            <span><i className="bi bi-arrow-clockwise"></i> Sync</span>
                                        )}
                                    </ButtonPrimary>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </SGUCard.Body>
        </SGUCard>
    )
}
