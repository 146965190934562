import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../../../../_layouts/components/popovers/PopOvers";

const StatusPerson = (status) => {
    let color = "";
    switch (status) {
        case "asc.student.status.active":
            color = "success";
            break;
        case "asc.student.status.graduate":
            color = "info";
            break;
        case "asc.student.status.leave":
            color = "warning";
            break;
        default:
            color = "danger";
            break;
    }

    return color;
}

const RenderItem = ({ data }) => {
    const status_color = StatusPerson(data.status_id);
    return (
        <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            placement="top"
            overlay={renderTooltip({ title: data.clu })}
        >
            <div className="item-person">
                <div className="d-flex align-items-center">
                    <div className="symbol  symbol-50px">
                        <span className={"symbol-label  bg-light-" + status_color + " text-" + status_color + " fs-6 fw-bolder "}>{data.icon}</span>
                    </div>
                    <div className="ms-4">
                        <div>
                            <span className="fs-6 fw-bold text-gray-900 mb-2">
                                {data.name}
                            </span>
                            <div className="fw-semibold fs-7 text-muted">
                                {data.userid}
                            </div>
                        </div>
                        <div>
                            <span className="badge badge-light">
                                {data.status}
                            </span>
                            {data.detail.corporate && (
                                <span className="badge badge-light-info ms-2">
                                    {data.detail.corporate.name || "-"}
                                </span>
                            )}
                            {data.status === "Active" && (
                                <span className="badge badge-light-primary ms-2">
                                    Semester {data.detail.semester || "-"}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </OverlayTrigger>
    )
}
export { RenderItem }