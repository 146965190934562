import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider } from '../_metronic/layout/core'
import { ThemeModeProvider } from '../_metronic/partials'
import Routed from "./Routed";
import { LayoutSplashScreen } from "../_layouts";
import { MasterInit } from "../_metronic/layout/MasterInit";

export default function App({ store, basename }) {
  const baseUri = window.location.href;
  if(!baseUri.includes("localhost")){
    console.log = console.warn = console.error = () => { };
    console.warn = () => { };
  }

  return (
    <Provider store={store}>
      <React.Suspense fallback={<LayoutSplashScreen />}>
        <BrowserRouter basename={basename}>
          <I18nProvider>
            <LayoutProvider>
              <ThemeModeProvider>
                <Routed />
                <MasterInit />
              </ThemeModeProvider>
            </LayoutProvider>
          </I18nProvider>
        </BrowserRouter>
      </React.Suspense>
    </Provider>
  );
}
