import { RequestData, RequestExternalPostEstes } from "../ApiBackend";

const GET_MENU_BY_GROUP = async (payload) => {
    const param = {
        method: "post",
        pathuri: "api/menu/by-group",
        payload: payload
    };
    const result = await RequestData(param);

    if (result.data && Object.values(result.data).length > 0) {
        return { loading: false, message: "", data: result.data };
    } else {
        return result;
    }
}

const GET_ROUTES = async () => {
    const param = {
        method: "get",
        pathuri: "api/menu",
    };
    const result = await RequestData(param);

    if (result.data && Object.values(result.data).length > 0) {
        const res_routes = GroupChildRoute(result.data);
        return { loading: false, message: "", data: res_routes };
    } else {
        return result;
    }
}

const GET_USER_SYS = async (payload) => {
    const param = {
        method: "post",
        pathuri: "api/group/member",
        payload: (payload && (payload || null))
    }
    const result = await RequestData(param);
    if (result.data && Object.values(result.data).length > 0) {
        const res_routes = GroupChildRoute(result.data);
        return { loading: false, message: "", data: res_routes };
    } else {
        return result;
    }
}

const GET_MEMBER_SYS = async () => {
    const param = {
        method: "get",
        pathuri: "api/user"
    }
    const result = await RequestData(param);
    if (result.data && Object.values(result.data).length > 0) {
        const res_routes = GroupChildRoute(result.data);
        return { loading: false, message: "", data: res_routes };
    } else {
        return result;
    }
}

const GroupChildRoute = (menu) => {
    return menu
        .map((item) => {
            if (item.children) {
                item.children = GroupChildRoute(item.children);
            }
            return item;
        });
};

const GET_WIDGET_ALL_BY_GROUP = async (data) => {
    const param = {
        method: "post",
        pathuri: "api/menu/widget",
        payload: data
    };
    const result = await RequestData(param);

    if (result.data && Object.values(result.data).length > 0) {
        return { loading: false, message: "", data: result.data };
    } else {
        return result;
    }
}

const GET_GROUPS = async () => {
    const param = {
        method: "get",
        pathuri: "api/group"
    }
    const result = await RequestData(param);
    if (result.data && Object.values(result.data).length > 0) {
        return { loading: false, message: "", data: result.data };
    } else {
        return result;
    }
}

const GET_WIDGET_BY_GROUP = async (data) => {
    const param = {
        method: "post",
        pathuri: "api/menu/widget",
        payload: data
    };
    const result = await RequestData(param);

    if (result.data && Object.values(result.data).length > 0) {
        const lastItem = result.data[0]
        return { loading: false, message: "", data: lastItem.widgets };
    } else {
        return result;
    }
}

const GET_USER_GROUP = async (data) => {
    const param = {
        method: "post",
        pathuri: "api/user/check-exist",
        payload: data
    }
    const result = await RequestData(param);

    if (result.data && Object.values(result.data).length > 0) {
        return { loading: false, message: "", data: result.data };
    } else {
        return result;
    }
}

const GET_USER_BY_EMPID = async (data) => {
    const param = {
        method: "post",
        pathuri: "api/user/by-empid",
        payload: data
    }
    const result = await RequestData(param);

    if (result.data && Object.values(result.data).length > 0) {
        return { loading: false, message: "", data: result.data };
    } else {
        return result;
    }
}

const GET_SYS_PARAM = async (data) => {
    const param = {
        method: "post",
        pathuri: "mc/api/sys-param",
        payload: data
    }
    const result = await RequestExternalPostEstes(param);

    if (result.data && Object.values(result.data).length > 0) {
        return { loading: false, message: "", data: result.data };
    } else {
        return result;
    }
}

const POST_SYS_PARAM = async (data) => {
    const param = {
        method: "post",
        pathuri: "mc/api/post-sys-param",
        payload: data
    }
    const result = await RequestExternalPostEstes(param);

    if (result.data && Object.values(result.data).length > 0) {
        return { loading: false, message: "", data: result.data };
    } else {
        return result;
    }
}

const GET_USER_ESTES = async (data) => {
    const param = {
        method: "post",
        pathuri: "mc/api/user-env",
        payload: data
    }
    const result = await RequestExternalPostEstes(param);

    if (result.data && Object.values(result.data).length > 0) {
        return { loading: false, message: "", data: result.data };
    } else {
        return result;
    }
}

const POST_ADD_GROUP_BY_USER = async (param) => {
    const config = {
        method: "post",
        pathuri: "api/group/member/add",
        payload: param
    }
    const result = await RequestData(config);

    if (result.data && Object.values(result.data).length > 0) {
        return { loading: false, message: "", data: result.data };
    } else {
        return result;
    }
}

const POST_ADD_USER = async (param) => {
    const config = {
        method: "post",
        pathuri: "api/user/create",
        payload: param
    }
    const result = await RequestData(config);

    if (result.data && Object.values(result.data).length > 0) {
        return { loading: false, message: "", data: result.data };
    } else {
        return result;
    }
}

const UPDATE_MEMBER_GROUP = async (param) => {
    const config = {
        method: "post",
        pathuri: "api/group/member/update",
        payload: param
    }
    const result = await RequestData(config);

    if (result.data && Object.values(result.data).length > 0) {
        return { loading: false, message: "", data: result.data };
    } else {
        return result;
    }
}

const UPDATE_STATUS_USER = async (param) => {
    const config = {
        method: "post",
        pathuri: "api/user/update-status",
        payload: param
    }
    const result = await RequestData(config);

    if (result.data && Object.values(result.data).length > 0) {
        return { loading: false, message: "", data: result.data };
    } else {
        return result;
    }
}

export { GET_ROUTES, GET_USER_SYS, GET_WIDGET_ALL_BY_GROUP, GET_WIDGET_BY_GROUP, GET_USER_GROUP, GET_SYS_PARAM, GET_MEMBER_SYS, GET_MENU_BY_GROUP, GET_GROUPS, GET_USER_ESTES, POST_ADD_GROUP_BY_USER, POST_ADD_USER, GET_USER_BY_EMPID, UPDATE_MEMBER_GROUP, UPDATE_STATUS_USER, POST_SYS_PARAM }