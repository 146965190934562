import React, { useEffect, useMemo, useState } from "react";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import {
  RDB_GROUP_GOOGLE,
} from "../../../apis/node-target-data/AcademicApi";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../../_layouts/components/alerts/AlertsUI";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  PaginationComponent,
  SearchBarTable,
} from "../../../../../_layouts/components/datatables/MYDatatables";
import { openModal } from "../../../../../_layouts/components/modals/Modals";

export default function GMailGroups() {
  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const ReloadData = async () => {
    setResults({ loading: true, data: [], message: "" });
    const param = { status: "ACTIVE" };
    const result = await RDB_GROUP_GOOGLE(param);
    setResults(result);
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: "No", field: "id", sortable: false },
    { name: "Name", field: "name", sortable: true },
    { name: "Description", field: "description", sortable: false },
    { name: "Members", field: "member", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = results.data;

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some(
          (key) =>
            listData[key]
              .toString()
              .toLowerCase()
              .includes(search)
          //console.log(key,listData[key])
        );
      });
    }

    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [results.data, search, sorting, currentPage]);

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bolder text-dark">Google Classroom</span>
        <span className="text-muted mt-1 fw-bold fs-7">
          list of active google class room
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar></SGUCard.Toolbar>
      <SGUCard.Body>
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <SearchBarTable
            size="w-250px"
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="d-flex">
            <ButtonItemRefresh
              totalItems={totalItems}
              onClick={() => ReloadData()}
            />
          </div>
        </div>
        {results.loading ? (
          <LoadingSkeleton />
        ) : results.message ? (
          <AlertRetry messages={results.message} Reload={ReloadData} />
        ) : (
          <div className="table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
              <HeaderDatatables
                headers={headers}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <tbody>
                {ResultData.length > 0 ? (
                  ResultData.map((v, index) => (
                    <tr key={index}>
                      <td width={"8%"} className="fs-7">
                        {index + 1}
                      </td>
                      <td className="fs-7">
                        <span className="d-block fw-bold">{v.name}</span>
                        <span className="text-muted">{v.email}</span>
                      </td>
                      <td className="fs-7">{v.description}</td>
                      <td className="fs-7">
                        <div className="cursor-pointer text-center" onClick={() => openModal({ header:`Mail Group ${v.email}`,message: <RenderMember member={v.members} /> }) } >
                          <span className="d-block fw-bold">
                            {v.directMembersCount} user
                          </span>
                          <span className="text-primary">View</span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <NoRecordFound />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {ResultData.length > 0 && (
              <PaginationComponent
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
          </div>
        )}
      </SGUCard.Body>
    </SGUCard>
  );
}

const RenderMember = ({ member }) => {
  return (
    <ol>
      {member.map((v, index) => (
        <li className="item" key={index}>
          {v.email}
        </li>
      ))}
    </ol>
  );
};
