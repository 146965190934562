import React from 'react'

export default function DetailScoreCom({data}) {
  return (
    <div className="table-responsive">
      <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
        <thead>
          <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
            <th width="8%">No</th>
            <th>Grade Component</th>
            <th>Weight</th>
            <th>Score</th>
          </tr>
        </thead>
        <tbody>
          {Object.values(data).length > 0 ? (
            data.map((v, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>{v.name ? decodeURIComponent(v.name) || "" : ""}</td>
                <td>{v.weight * 100}%</td>
                <td>{v.score}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>No record found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}
