import CardOutlet from '../_layouts/components/cards/CardOutlet';
import { AcademicAdministrations, ADMDashboard, AdmissionLayout, Coupons, DetailEnrollments, Documents, Enrollments, FromEnrollments } from './modules/admissions';
import { AttendanceLayout, AttendanceLect, AttendanceStd, DetailAttdLect, DetailAttdStd } from './modules/attendances';
import { AcademicAccessWindow, CalendarLayout, DetailThesisDefense, EventManagement, Examiners, FormCreateTimetable, FormExaminers, FormSchedule, FormStdRepresentative, LecturerTimetable, RoomOccupancy, RoomTimetable, StudyProgramTimetable, ThesisDefenseSchedule, Timetables } from './modules/calendar';
import { AttendanceInfo, BrowseSection, CourseManagementLayout, DetailCourse, DetailSection, FormCreateSection, FormProposalCredit, FormProposalRegis, GeneralInfo, MCourse, PreviewProposalCredit, ScoreInfo, TCDetail, TCRegisProposal, TeachingLoads, TimetableInfo, TransferCreditProposal } from './modules/course-managements';
import { DevelopmentMode, DevelopmentModeLayout } from './modules/development-mode';
import { Employee, EmployeeLayout } from './modules/employees';
import { Error404 } from './modules/errors/components/Error404';
import { GroupUser, GroupUserLayout } from './modules/group-users'
import { MUserLayout, UserList } from './modules/management-users';
import { CompanyManagement, GoogleManagement, MAcademicCalendar, MasterDataLayout, MStorageFiles, RegionalManagement, SchoolManagement } from './modules/master-data-managements';
import { AdmissionRpt, ReportingLayout, RoomConflicts, StudentPerformance, TeachingLoadNoRate, TeachingLoadRate } from './modules/reportings';
import { ClassroomScore, DetailClassroomScore, DetailStudentScore, DetailThesisScore, ScoreLayout, StudentScore, ThesisScore } from './modules/scores';
import { AcadActivity, DetailActivity, DetailStudent, StudentLayout, Students } from './modules/students';

const COMPONENT_MAP = {
    Error404:Error404,
    CardOutlet:CardOutlet,
    
    GroupUserLayout:GroupUserLayout,
    GroupUser:GroupUser,

    CourseManagementLayout:CourseManagementLayout,
    TeachingLoads:TeachingLoads,
    FormCreateSection:FormCreateSection,

    TransferCreditProposal:TransferCreditProposal,
    FormProposalCredit:FormProposalCredit,
    PreviewProposalCredit:PreviewProposalCredit,
    TCRegisProposal:TCRegisProposal,
    FormProposalRegis:FormProposalRegis,

    BrowseSection:BrowseSection,   
    DetailSection:DetailSection, 
    GeneralInfo:GeneralInfo,
    AttendanceInfo:AttendanceInfo,
    ScoreInfo:ScoreInfo,
    TimetableInfo:TimetableInfo,
    MCourse:MCourse,
    DetailCourse:DetailCourse,

    StudentLayout:StudentLayout,
    Students:Students,
    DetailStudent:DetailStudent,
    AcadActivity:AcadActivity,
    DetailActivity:DetailActivity,
    // Matriculation:Matriculation,
    // MTXDetailStudent:MTXDetailStudent,

    EmployeeLayout:EmployeeLayout,
    Employee:Employee,

    AttendanceLayout:AttendanceLayout,
    AttendanceLect:AttendanceLect,
    DetailAttdLect:DetailAttdLect,
    AttendanceStd:AttendanceStd,
    DetailAttdStd:DetailAttdStd,

    ScoreLayout:ScoreLayout,
    ClassroomScore:ClassroomScore,
    DetailClassroomScore:DetailClassroomScore,
    StudentScore:StudentScore,
    DetailStudentScore:DetailStudentScore,
    ThesisScore:ThesisScore,
    DetailThesisScore:DetailThesisScore,

    CalendarLayout:CalendarLayout,
    StudyProgramTimetable:StudyProgramTimetable,
    FormStdRepresentative:FormStdRepresentative,
    Timetables:Timetables,
    FormCreateTimetable:FormCreateTimetable,
    RoomTimetable:RoomTimetable,
    LecturerTimetable:LecturerTimetable,
    RoomOccupancy:RoomOccupancy,
    AcademicAccessWindow:AcademicAccessWindow,
    ThesisDefenseSchedule:ThesisDefenseSchedule,
    DetailThesisDefense:DetailThesisDefense,
    Examiners:Examiners,
    FormExaminers:FormExaminers,
    FormSchedule:FormSchedule,
    EventManagement:EventManagement,

    AdmissionLayout:AdmissionLayout,
    ADMDashboard:ADMDashboard,
    Enrollments:Enrollments,
    FromEnrollments:FromEnrollments,
    DetailEnrollments:DetailEnrollments,
    Documents:Documents,
    Coupons: Coupons,
    AcademicAdministrations:AcademicAdministrations,

    ReportingLayout:ReportingLayout,
    TeachingLoadRate:TeachingLoadRate,
    TeachingLoadNoRate:TeachingLoadNoRate,
    StudentPerformance:StudentPerformance,
    RoomConflicts:RoomConflicts,
    AdmissionRpt:AdmissionRpt,

    MUserLayout:MUserLayout,
    UserList:UserList,

    DevelopmentModeLayout:DevelopmentModeLayout,
    DevelopmentMode:DevelopmentMode,

    MasterDataLayout:MasterDataLayout,
    MAcademicCalendar:MAcademicCalendar,
    GoogleManagement:GoogleManagement,
    RegionalManagement:RegionalManagement,
    CompanyManagement:CompanyManagement,
    SchoolManagement:SchoolManagement,
    MStorageFiles:MStorageFiles
    
};

export {COMPONENT_MAP}