import React, { useEffect, useRef, useState } from "react";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import Filter from "./Filter";
import moment from "moment";
import { GET_SCHD_CLASS_STD_NEXT } from "../../apis/node-target-data/AcademicApi";
import { ConvertEventItem } from "../components/HelperCalendar";
import { FullCalendarWeek } from "../components";
import { CalendarNotes, CalendarPrevNext, ConsentEvent, htmlToImageConvert } from "../components/CalendarAtributes";
import { ButtonDownload, ButtonIcon } from "../../../../_layouts/components/buttons/ButtonUI";
import { MessageError, openModal } from "../../../../_layouts/components/modals/Modals";
import { LoadingSkeleton } from "../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { KTIcon } from "../../../../_metronic/helpers";
import { useOutletContext } from "react-router-dom";
import { AccessInvalid, RequiredMsg } from "../../../../_layouts/_core/ErrorMessage";
import { RenderStudentRep } from "./StudentRepresentative";
import { LinkBtn } from "../../../../_layouts/components/buttons/LinkUI";

export function StudyProgramTimetable() {
  const context = useOutletContext();
  const widget_46 = context.widgets.find(
    (item) => item.widget_id === 46 && item.is_enabled === 1
  );
  const widget_93 = context.widgets.find(
    (item) => item.widget_id === 93 && item.is_enabled === 1
  );
  const elementRef = useRef(null);
  const title = "Study Program Timetable";

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const monday = moment().startOf("weeks").format("YYYY-MM-DD");
  const sunday = moment().endOf("weeks").format("YYYY-MM-DD");

  const [postParam, setPostParam] = useState({ studentid: "", tgl: monday, tglend: sunday });

  useEffect(() => {
    if (postParam.studentid) {
      GetScheduleTimetable();
    }
  }, [postParam])

  const GetScheduleTimetable = async () => {
    setResults({ loading: true, message: "", data: [] });

    const result = await GET_SCHD_CLASS_STD_NEXT(postParam);
    if (result.data && Object.values(result.data).length > 0) {
      const data_result = ConvertEventItem(result.data);
      setResults({ loading: false, message: "", data: data_result });
    } else {
      setResults({ loading: false, message: result.message, data: [] });
    }
  };

  const [downloadPic, setDownloadPic] = useState(false);

  const createNewClass = (data) => {
    if(postParam.studentid){
      const selectedDate = moment(data.dateStr).toDate();
      openModal({ message: <ConsentEvent selectedDate={selectedDate} ReloadData={GetScheduleTimetable} iscustom={true} /> })
    }else{
      openModal({message:<MessageError message={RequiredMsg} />})
    }
  }

  const [studentRep, setStudentRep] = useState([]);

  return widget_46 ? (
    <>
      <SGUCard>
        <SGUCard.Body className={"pt-5 pb-5"}>
          <div className="d-flex justify-content-between align-items-center">
            <div>
              <h3 className="mb-2">
                {title}
                <span className="cursor-pointer ms-2" title="View Student Representative" onClick={() => openModal({ header: "Student Representative", message: <RenderStudentRep studentRep={studentRep} /> })}>
                  <KTIcon iconName="information-4" />
                </span>
              </h3>
              <div className="d-flex align-items-center">
                <Filter onSubmit={GetScheduleTimetable} isload={results.loading} setPostParam={setPostParam} setStudentRep={setStudentRep} />
                {widget_93 && (
                  <LinkBtn to={"student-representative"} className="btn btn-icon btn-sm btn-light ms-2" title="Update Student Representative">
                    <KTIcon iconName="setting-2" className="fs-1" />
                  </LinkBtn>
                )}
              </div>
            </div>

            <ButtonDownload className="btn-sm" type="button" disabled={downloadPic} onClick={() => htmlToImageConvert(elementRef, title, setDownloadPic)}>
              {downloadPic ? "Processing..." : "Download Timetable"}
            </ButtonDownload>

          </div>
        </SGUCard.Body>
      </SGUCard>

      {results.loading ? <LoadingSkeleton /> :
        results.message ? <AlertNotif messages={results.message} /> : (
          <>
            <div className="d-flex justify-content-between align-items-center mb-5">
              <CalendarNotes />
              <CalendarPrevNext start_dt={postParam.tgl} setPostParam={setPostParam} />
            </div>
            <SGUCard>
              <SGUCard.Body className={"p-0"}>
                <div className="calendar-week" ref={elementRef}>
                  <FullCalendarWeek data={results.data} startDT={postParam.tgl} endDT={postParam.tglend} title={title} createNewClass={createNewClass} />
                </div>
              </SGUCard.Body>
            </SGUCard>
          </>
        )}
    </>
  ) : <AlertNotif messages={AccessInvalid} />;
}

