import React, { useEffect, useState } from 'react'
import { SGUCard } from '../../../../_layouts/components/cards/CardUI'
import { GET_COUNSELING_BY_LECTURER } from '../../apis/node-target-data/AcademicApi';
import { LoadingSkeleton, NoRecordFound } from '../../../../_layouts/components/loadbars/LoadingBars';
import { AlertRetry } from '../../../../_layouts/components/alerts/AlertsUI';
import { ButtonPrimary } from '../../../../_layouts/components/buttons/ButtonUI';
import { GroupByKey } from '../../../../_layouts/_helpers/Global';
import TableStudent from './TableStudent';
import { openModal } from '../../../../_layouts/components/modals/Modals';
import FormAssignStudent from './FormAssignStudent';

export default function AcadCounselor({ profile }) {
    const email = profile.sgumail;
    const mailarr = email.split(".");
    const firstname = mailarr[0];

    const empid = profile.id;

    const [results, setResults] = useState({
        loading: false,
        message: "",
        data: [],
    });

    const ReloadData = async () => {
        setResults({loading: true,message: "",data: []})
        const param = { empid: empid };
        const result = await GET_COUNSELING_BY_LECTURER(param);

        if (result.data && Object.values(result.data).length > 0) {
            let objIsactive = [1];
            var all = result.data
                .filter((o) =>
                    objIsactive.find((x) =>
                        o.studentstatusdata !== null
                            ? x === o.studentstatusdata.is_active
                            : o
                    )
                )
                .map((o) => o);
            const stdGrouped = GroupByKey(all, "studentid");
            const stdData = FetchStdData(stdGrouped);
            const stdFilterd = stdData.filter(item => item.studentid !== "");
            const stdSorted = stdFilterd.sort((a, b) => {
                const nameA = a.student_name.toUpperCase();
                const nameB = b.student_name.toUpperCase();
              
                if (nameA < nameB) {
                  return -1;
                }
                if (nameA > nameB) {
                  return 1;
                }
                return 0;
              });


            setResults({ loading: false, message: "", data: stdSorted });
        } else {
            setResults(result);
        }
    }

    const FetchStdData = (data) => {
        var results = [];
        Object.keys(data).map((k) => {
            if (Object.values(data[k]).length > 0) {
                var obj = {};
                obj.studentid = data[k][0].studentid;
                obj.student_name = data[k][0].studentstatusdata
                    ? data[k][0].studentstatusdata.fullname
                    : "";
                obj.student_status = data[k][0].studentstatusdata
                    ? (data[k][0].studentstatusdata.status ? data[k][0].studentstatusdata.status.id : "")
                    : "";
                var ispending = data[k].find(item => item.event_status === "SUBMITTED");
                obj.student_status_activity = ispending ? ispending.event_status : "";
                obj.student_activity = data[k];
                results.push(obj);
            }
        });
        return results;
    };

    useEffect(() => {
        ReloadData();
    }, []);

    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bolder fs-3 mb-1">
                    <span className="text-capitalize me-1">{firstname}’s</span>
                    Student Counselor
                </span>
                <span className="text-muted mt-1 fw-bold fs-7">List of student</span>
            </SGUCard.Header>
            <SGUCard.Toolbar>
                <ButtonPrimary className="btn-sm fw-bold" type="button" onClick={()=>openModal({message:<FormAssignStudent emp_id={empid} />})}>
                    Add new student
                </ButtonPrimary>
            </SGUCard.Toolbar>
            <SGUCard.Body>
                {results.loading ? <LoadingSkeleton /> :
                    results.message ? <AlertRetry messages={results.message} Reload={ReloadData} /> :
                        Object.values(results.data).length > 0 ? (
                            <TableStudent data={results.data} ReloadData={ReloadData} />
                        ) : <NoRecordFound />}
            </SGUCard.Body>
        </SGUCard>
    )
}
