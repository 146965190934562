import React, { useEffect, useMemo, useState } from "react";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import {
  ButtonIcon,
} from "../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../_metronic/helpers";
import {
  ButtonItemRefresh,
  SearchBarTable,
} from "../../../../_layouts/components/datatables/MYDatatables";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import {
  LoadingBars,
  NoRecordFound,
} from "../../../../_layouts/components/loadbars/LoadingBars";
import { openModal } from "../../../../_layouts/components/modals/Modals";
import FormUpdateMenu from "./FormUpdateMenu";
import { GET_ROUTES } from "../../apis/module/BackendRequest";

export function ListMenu() {
  const [menus, setMenus] = useState({ loading: false, data: [], message: "" });

  const ReloadData = async () =>{
    setMenus({ loading: false, data: [], message: "" });
    const result = await GET_ROUTES();
    setMenus(result);
  }

  useEffect(() => {
    ReloadData();    
  }, []);

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");

  const filterMenu = (menu) => {
    return menu
      .filter((item) => item.slug_uri !== "index")
      .map((item) => {
        if (item.children) {
          item.children = filterMenu(item.children);
        }
        return item;
      });
  };

  const ResultData = useMemo(() => {
    let computedData = filterMenu(menus.data);

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key]
            .toString()
            .toLowerCase()
            .includes(search)
        );
      });
    }
    setTotalItems(computedData.length);
    return computedData;
  }, [menus.data, search]);

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bold fs-3 mb-1">
          Master Menu and Routes
        </span>
        <span className="text-muted mt-1 fw-semibold fs-7">
          Over {totalItems} menu
        </span>
      </SGUCard.Header>
      <SGUCard.Body>
        <div className="list-data">
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <SearchBarTable
              size="w-250px"
              onChange={(e) => setSearch(e.target.value)}
            />
            <ButtonItemRefresh
              totalItems={totalItems}
              onClick={() => ReloadData()}
            />
          </div>

          {menus.loading ? (
            <LoadingBars />
          ) : menus.message ? (
            <AlertNotif messages={menus.message} color="danger" />
          ) : ResultData.length > 0 ? (
            <div className="hover-scroll-y scroll-ps m-2">
              <div className="p-3 p-lg-4 tree tree-line">
                {ResultData.map((v, index) => (
                  <TreeLineItem data={v} key={index} ReloadData={ReloadData} />
                ))}
              </div>
            </div>
          ) : (
            <NoRecordFound />
          )}
        </div>
      </SGUCard.Body>
    </SGUCard>
  );
}

const TreeLineItem = ({ data, ReloadData }) => {
  if (Object.values(data.children).length > 0) {
    return <TreeChildren data={data} ReloadData={ReloadData} />;
  } else {
    return <TreeSingle data={data} ReloadData={ReloadData} />;
  }
};

const TreeChildren = ({ data, ReloadData }) => {
  if (data.menu_name) {
    return (
      <div className="tree-node">
        <div className="tree-content">
          <a
            className="tree-toggle collapsed"
            data-bs-toggle="collapse"
            href={`#kt_node_${data.route_id}`}
            aria-expanded="false"
          >
            <KTIcon
              iconName={
                data.menu_is_active === 1 ? "add-folder" : "delete-folder"
              }
              className="outline tree-icon-default"
            />
          </a>
          <div className="tree-section">
            <span className="fs-5 fw-semibold text-gray-900">
              {data.menu_name}
            </span>
            <pre className="prism-code bg-light rounded p-0 fs-7 mb-0 ms-2">
              <span className="token-line px-2">
                <span className="token plain">/{data.slug_uri}</span>
              </span>
            </pre>

            {data.menu_type === 2 && (
                <span className="badge badge-light-info fs-8 ms-1">
                    <KTIcon iconName="check-circle" className="text-info" />
                    Staging
                </span>
            )}

            <ButtonUpdate data={data} ReloadData={ReloadData} />
          </div>
        </div>

        {data.children && data.children.length > 0 && (
          <div className="tab-content">
            <div className="tree-sub collapse" id={`kt_node_${data.route_id}`}>
              {data.children.map((child, index) => (
                <TreeLineItem key={index} data={child} />
              ))}
            </div>
          </div>
        )}
      </div>
    );
  }
};

const TreeSingle = ({ data, ReloadData }) => {
  return (
    <div className="tree-node">
      <div className="tree-content">
        <span className="tree-toggle">
          <KTIcon
            iconName={data.menu_is_active === 1 ? "folder" : "delete-folder"}
            className={
              "tree-icon-default " +
              (data.menu_is_active !== 1 && "text-danger")
            }
          />
        </span>
        <div className="tree-section">
          <span
            className={
              "fs-5 fw-semibold " +
              (data.menu_is_active === 1 ? "text-gray-900" : "text-danger")
            }
          >
            {data.menu_name ? data.menu_name : data.slug}
          </span>
          <pre className="prism-code bg-light rounded py-1 fs-7 mb-0 ms-2">
            <span className="token-line px-2">
              <span className="token plain">/{data.slug_uri}</span>
            </span>
          </pre>
          {data.menu_type === 2 && (
            <span className="badge badge-light-info fs-8 ms-1">
              <KTIcon iconName="check-circle" className="text-info" />
              Staging
            </span>
          )}
          <ButtonUpdate data={data} ReloadData={ReloadData} />
        </div>
      </div>
    </div>
  );
};

const ButtonUpdate = ({ data, ReloadData }) => {
  return (
    <ButtonIcon className="btn-sm" type="button" title="Update" onClick={()=>openModal({message:<FormUpdateMenu data={data} ReloadData={ReloadData} />})}>
      <KTIcon iconName="pencil" className="text-gray" />
    </ButtonIcon>
  );
};
