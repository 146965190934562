import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import { Bar } from "./Bar";
import { Donat } from "./Donat";
import { CircularBar } from "./CircularBar";
import { Line } from "./Line";
import { FeeData } from "./FeeData";
import { DumbbellChart } from "./DumbblleChart";
import { MajorChart } from "./MajorChart";
import { renderTooltip } from "../../../../../_layouts/components/popovers/PopOvers";
import { ButtonCoupon } from "../../components/EnrollmentComp";
import { KTIcon, KTSVG } from "../../../../../_metronic/helpers";

const ModalCharts = () => {
  return (
    <div>
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement="top"
        overlay={renderTooltip({ title: "Open Chart" })}
      >
        <div>
          <ButtonCoupon
            type="button"
            className="btn-sm ms-3"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_chart"
            //   onClick={() => SeeCoupon()}
          >
            <KTIcon iconName="graph-4" />
          </ButtonCoupon>
        </div>
      </OverlayTrigger>
      <div className="modal bg-white fade" tabIndex={-1} id="kt_modal_chart">
        <div className="modal-dialog modal-fullscreen">
          <div className="modal-content shadow-none">
            <div className="modal-header">
              <h5 className="modal-title">Dashboard</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <KTSVG
                  path="media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>
            <div className="modal-body bg-gray-300">
              <div className="row g-5 g-xl-8">
                <div className="col-xl-6">
                  <Line className="card-xl-stretch mb-xl-8" />
                </div>
                <div className="col-xl-3">
                  <Donat className="card-xl-stretch mb-xl-8" />
                </div>
                <div className="col-xl-3">
                  <div className="row g-0">
                    <FeeData
                      className="card-xl-stretch mb-8"
                      chartColor="primary"
                      chartHeight="150px"
                      title="Registration Fee"
                    />
                    <FeeData
                      className="card-xl-stretch mb-8"
                      chartColor="primary"
                      chartHeight="150px"
                      title="Tuition Fee"
                    />
                  </div>
                </div>
              </div>

              <div className="row g-5 g-xl-8">
                <div className="col-xl-4">
                  {/* <DumbbellChart className="card-xl-stretch mb-xl-8" /> */}
                  <MajorChart className="card-xl-stretch mb-xl-8" />
                </div>
                <div className="col-xl-8">
                  <Bar className="card-xl-stretch mb-xl-8" />
                </div>
              </div>

              <div className="row g-5 g-xl-8">
                <div className="col-12">
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ModalCharts };
