import React, { useEffect, useState } from "react";
import {
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom";
import { AlertNotif, AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import { Error404 } from "../../errors/components/Error404";
import {
  GET_SCR_STUDENT,
  GET_SECTION,
} from "../../apis/node-target-data/AcademicApi";
import { LoadingSkeleton } from "../../../../_layouts/components/loadbars/LoadingBars";
import FormScore from "./components/FormScore";
import { StudentInfo } from "./components/ScoreUI";
import { CourseInfo } from "../../course-managements/master-courses/components/CourseUI";

export function DetailStudentScore() {
  const context = useOutletContext();
  const widget_40 = context.widgets.find(
    (item) => item.widget_id === 40 && item.is_enabled === 1
  );

  const params = useParams();
  const token = params.token;
  try {
    const decode = atob(token);
    const data = JSON.parse(decode);
    return widget_40 ? (
      <DetailPage data={data} />
    ) : (
      <AlertNotif messages={AccessInvalid} />
    );
  } catch (error) {
    return <Error404 />;
  }
}

const DetailPage = ({ data }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const state_data = location.state;
  
  const studentid = data.studentid;
  const course_id = data.course_id;
  const atpid = data.atpid;

  const [studentScore, setStudentScore] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const [section, setSection] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const ReloadData = async () => {
    const loaddata = { loading: true, message: "", data: [] };
    setSection(loaddata);
    setStudentScore(loaddata);

    const param = { course_id: course_id, studentid: studentid, atpid: atpid };
    const result = await GET_SCR_STUDENT(param);
    if (result.data && Object.values(result.data).length > 0) {
      const data_course_active = result.data.find(item => item.status === "ACTIVE");
      setStudentScore({ loading: false, message: "", data: data_course_active });
    } else {
      setStudentScore(result);
    }

    const result_section = await GET_SECTION(param);
    if (result_section.data && Object.values(result_section.data).length > 0) {
      setSection({ loading: false, message: "", data: result_section.data[0] });
    } else {
      setSection(result_section);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const Back2Page = () => {
    navigate("/score/student", {
      state: {param: state_data.param},
    });
  };
  useEffect(() => {
    //BACK HANDLER
    const handleBackButton = (event) => {
      event.preventDefault();
      Back2Page();
    };

    window.history.pushState(null, null, window.location.href);

    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
    //END BACK
  }, [navigate]);

  return (
    <div className="row">
      <div className="col-lg-3">
        {studentScore.loading ? <LoadingSkeleton /> :
        studentScore.message ? <AlertNotif messages={studentScore.message} /> : (
          <div className="mb-5">
            <StudentInfo data={studentScore.data} />
          </div>
        )}
        <div className="">
          <CourseInfo course_id={course_id} />
        </div>
      </div>
      <div className="col-lg-9">
        {studentScore.loading ? (
          <LoadingSkeleton />
        ) : studentScore.message ? (
          <AlertRetry Reload={ReloadData} messages={studentScore.message} />
        ) : (
          <FormScore
            data={studentScore.data}
            section={section.data}
            ReloadData={ReloadData}
          />
        )}
      </div>
    </div>
  );
};
