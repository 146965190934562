import React, { useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { NoRecordFound } from "../../../../_layouts/components/loadbars/LoadingBars";
import { ButtonIcon } from "../../../../_layouts/components/buttons/ButtonUI";
import { openModal } from "../../../../_layouts/components/modals/Modals";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  PaginationComponent,
  SearchBarTable,
} from "../../../../_layouts/components/datatables/MYDatatables";
import { KTIcon } from "../../../../_metronic/helpers";
import moment from "moment";
import { addStatus, ColorStatus, ConfirmRemoveItem } from "./helpers";
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../../../_layouts/components/popovers/PopOvers";

export default function TableData({ data, ReloadData }) {
  const context = useOutletContext();
  const widget_56 = context.widgets.find(
    (item) => item.widget_id === 56 && item.is_enabled === 1
  );
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const headers = [
    { name: "Academic Term", field: "name", sortable: true },
    { name: "Academic Access", field: "name", sortable: true },
    { name: "Open Date & Time", field: "startdt", sortable: true },
    { name: "Close Date & Time", field: "enddt", sortable: true },
    { name: "Affected Section", field: "audit_created", sortable: false },
    { name: "Status", field: "status", sortable: true },
    { name: "Remarks", field: "id", sortable: false },
    { name: "Created", field: "id", sortable: false },
    { name: "", field: "id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];

    if (data) {
      computedData = data.map((c) => {
        var obj = {};
        obj.id = c.id;
        obj.atp_name = c.atp && (c.atp.name || "");
        obj.name = c.name || "";
        obj.var1 = c.var1 || "";
        obj.remark = c.remark || "";
        obj.end_dt = c.end_dt_formatted || "";
        obj.startdt = c.start_dt || "";
        obj.enddt = c.end_dt || "";
        obj.start_dt = c.start_dt_formatted || "";
        obj.is_public = c.is_public;
        obj.audit_createdby = c.audit_createdby || "";
        obj.audit_created =
          c.audit_created &&
          (moment(c.audit_created).format("DD MMM YYYY hh:mm A") || "");
        return obj;
      });

      computedData = addStatus(computedData);
      // computedData.sort(
      //   (a, b) =>
      //     moment(a.start_dt, "DD MMM YYYY, hh:mm A") -
      //     moment(b.start_dt, "DD MMM YYYY, hh:mm A")
      // );
      computedData.sort((a, b) => (a.startdt > b.startdt ? -1 : 1));
    }

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key]
            .toString()
            .toLowerCase()
            .includes(search)
        );
      });
    }
    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [data, search, sorting, currentPage]);

  return (
    <div className="table-responsive">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex">
          <ButtonItemRefresh
            totalItems={totalItems}
            onClick={() => ReloadData()}
          />
        </div>
      </div>
      <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
        <HeaderDatatables
          headers={headers}
          onSorting={(field, order) => setSorting({ field, order })}
        />
        <tbody>
          {ResultData.length > 0 ? (
            ResultData.map((v, index) => (
              <tr key={index}>
                <td>
                  <span className="fw-bold fs-7">{v.atp_name}</span>
                </td>
                <td>
                  <div className="d-flex align-items-center">
                    <BadgePublic status={v.is_public} />
                    <span className="fw-bold fs-7 ms-1">{v.name || "-"}</span>
                  </div>
                </td>
                <td className="fs-7">{v.start_dt}</td>
                <td className="fs-7">{v.end_dt}</td>
                <td className="fs-7">{v.var1 || "All Section"}</td>
                <td className="fs-7">
                  <BadgeStatus status={v.status} />
                </td>
                <td className="fs-7">{v.remark}</td>
                <td className="fs-7">
                  <span className="fw-bold d-block">{v.audit_createdby}</span>
                  <span className="text-muted">{v.audit_created}</span>
                </td>
                <td>
                  {widget_56 && (
                    <ButtonIcon
                      className="btn-sm"
                      type="button"
                      title="Remove item"
                      onClick={() =>
                        openModal({
                          header: `Remove ${v.name}`,
                          message: (
                            <ConfirmRemoveItem
                              id={v.id}
                              ReloadData={ReloadData}
                            />
                          ),
                        })
                      }
                    >
                      <KTIcon iconName="trash" className="fs-1 text-danger" />
                    </ButtonIcon>
                  )}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={9}>
                <NoRecordFound />
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {ResultData.length > 0 && (
        <div className="footer">
          <PaginationComponent
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    </div>
  );
}

const BadgeStatus = ({ status }) => {
  const status_label = ColorStatus(status);
  return (
    <span className={`fs-7 badge badge-light-${status_label.color}`}>
      {status}
    </span>
  );
};

const BadgePublic = ({ status }) => {
  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement="top"
      overlay={renderTooltip({ title: status === 1 ? "Publicly" : "Private" })}
    >
      <span>
        <KTIcon
          iconName={status === 1 ? "shield" : "shield-slash"}
          className="fs-3"
        />
      </span>
    </OverlayTrigger>
  );
};

export { BadgeStatus, BadgePublic };
