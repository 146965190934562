import React, { useEffect, useState } from 'react'
import { SGUCard } from '../../../../../../_layouts/components/cards/CardUI'
import { useOutletContext } from 'react-router-dom';
import { ButtonPrimary } from '../../../../../../_layouts/components/buttons/ButtonUI';
import { LectProgress } from '../SectionUI';
import DetailTimetables from '../../../../calendar/timetables/DetailTimetables';
import { GET_WIDGET_BY_GROUP } from '../../../../apis/module/BackendRequest';
import { AuthDecodeToken } from '../../../../../reducer/AuthRedux';
import { ExtractWidgetIds } from '../../../../../../_layouts/_helpers/Global';
import { LoadingSkeleton } from '../../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../../../_layouts/components/alerts/AlertsUI';
import { AccessInvalid } from '../../../../../../_layouts/_core/ErrorMessage';
import { LinkBtn } from '../../../../../../_layouts/components/buttons/LinkUI';

export function TimetableInfo() {
    var Token = AuthDecodeToken();
    const context = useOutletContext();
    const data = context.data;
    const lecturers = data.lecturer;

    const [widgets, setWidgets] = useState({ loading: false, data: [], message: "" });

    const FetchWidgets = async () => {
        setWidgets({ loading: true, data: [], message: "" });
        const param = {
            group_id: Token.identity.group_id,
            menu_id: 16,
        }
        const result = await GET_WIDGET_BY_GROUP(param);

        if (result.data && Object.values(result.data).length > 0) {
            const isenabled = result.data.find((item) => item.is_enabled === 1);
            const data = ExtractWidgetIds(isenabled);
            setWidgets({ loading: false, message: "", data: data });
        } else {
            setWidgets(result);
        }
    }

    useEffect(() => {
        FetchWidgets();
    }, [])

    if (widgets.loading) {
        return <LoadingSkeleton />;
    } else if (widgets.message) {
        return <AlertNotif messages={widgets.message} />
    } else if (Object.values(widgets.data).length > 0) {
        const widget_48 = widgets.data.find(item => item.widget_id === 48 && item.is_enabled === 1);
        const widget_49 = widgets.data.find(item => item.widget_id === 49 && item.is_enabled === 1);
        const widget_50 = widgets.data.find(item => item.widget_id === 50 && item.is_enabled === 1);

        return widget_48 ? (
            <div>
                <SGUCard>
                    <SGUCard.Header>
                        <span className="card-label fw-bold text-gray-800">Timetables</span>
                        <span className="text-muted mt-1 fw-bold fs-7">Instructor Schedule for Courses</span>
                    </SGUCard.Header>
                    {widget_49 ? (
                        <SGUCard.Toolbar>
                            <LinkBtn to={"/calendar/timetable/create/" + btoa(data.section_id)} target='_blank' className="btn btn-sm btn-primary fw-bold">
                                <i className={"ki-outline ki-plus-circle fs-3"}></i>
                                Create New Schedule
                            </LinkBtn>
                        </SGUCard.Toolbar>
                    ):""}
                    <SGUCard.Body>
                        <div className="list-data">
                            <DetailTimetables section_id={data.section_id} lecturers={lecturers} widget_50={widget_50} />
                        </div>
                    </SGUCard.Body>
                </SGUCard>
            </div>
        ) : <AlertNotif messages={AccessInvalid} />
    } else {
        return "";
    }
}
