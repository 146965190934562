import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { CourseInfo } from "../../master-courses/components/CourseUI";
import { FormLecturer } from "./FormLecturer";
import { FormClass } from "./FormClass";
import { FormSection } from "./FormSection";
import { Error404 } from "../../../errors/components/Error404";
import { PreviewSection } from "./PreviewSection";

export function FormCreateSection() {
  const navigate = useNavigate();
  const location = useLocation();
  if (location.state) {
    const course_id = location.state.course_id;
    const paramTL = location.state.param;
    const course_lesson = location.state.course_lesson;

    const Back2Page = () => {
      navigate("/course-managements/teaching-load", {
        state: { param: paramTL },
      });
    };

    let objSection = {
      cost_center: paramTL ? paramTL.org_id : 0,
      curr_group_type: paramTL ? paramTL.curr_group_type : "",
      course_id: course_id ? course_id : 0,
      emp: [],
      max_seat: 32,
      prereq: [],
      section_session: course_lesson,
      term_id: paramTL ? paramTL.term_id : 0,
      corporate_id: paramTL ? paramTL.corporate_id : 10
    };
    const [paramSection, setParamSection] = useState(objSection);
    const [wizardActv, setWizardActv] = useState(0);
    const [is_pref, setIsPrev] = useState(false);

    const wizardStep = [
      {
        id: 0,
        name: "Lecturer Assignment",
        component: (
          <FormLecturer
            wizardActv={wizardActv}
            setWizardActv={setWizardActv}
            paramSection={paramSection}
            setParamSection={setParamSection}
          />
        ),
      },
      {
        id: 1,
        name: "Study Program Qualification (Optional)",
        component: (
          <FormClass
            wizardActv={wizardActv}
            setWizardActv={setWizardActv}
            paramSection={paramSection}
            setParamSection={setParamSection}
          />
        ),
      },
      {
        id: 2,
        name: "Section Attribute",
        component: (
          <FormSection
            wizardActv={wizardActv}
            setWizardActv={setWizardActv}
            paramSection={paramSection}
            setParamSection={setParamSection}
            setIsPrev={setIsPrev}
          />
        ),
      },
    ];



    useEffect(() => {
      //BACK HANDLER
      const handleBackButton = (event) => {
        event.preventDefault();
        Back2Page();
      };

      window.history.pushState(null, null, window.location.href);

      window.addEventListener("popstate", handleBackButton);

      return () => {
        window.removeEventListener("popstate", handleBackButton);
      };
      //END BACK      
    }, [navigate]);

    useEffect(() => {
      //REFRESH HANDLER
      const handleBeforeUnload = (event) => {
        event.preventDefault();
        //event.returnValue = '';
        alert('Hi');
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
      //END REFRESH
    }, [])



    return (
      <div className="row">
        <div className="col-lg-3">
          <div className="tombol-back mb-5">
            <div
              className="cursor-pointer d-flex align-items-center"
              onClick={() => Back2Page()}
            >
              <i className="ki-outline ki-arrow-left fs-3x"></i>
              <span className="fs-1 text-gray-700">Back</span>
            </div>
          </div>
          <CourseInfo course_id={course_id} />
        </div>
        <div className="col-lg-9">
          {is_pref && <PreviewSection data={paramSection} setIsPrev={setIsPrev} Back2Page={Back2Page} />}
          <div className={is_pref ? "d-none" : ""}>
            <h1 className="my-4">Form Create Section</h1>
            <div className="form-create-section">
              <div className="wizard-steps border rounded">
                {wizardStep.map((v, index) => (
                  <div className="wizard-step-item" key={index}>
                    <div
                      className={
                        "wizars-header bg-ligh p-5 border-bottom " +
                        (index !== wizardActv ? "border-top" : "")
                      }
                    >
                      <div className="wizard-title">
                        <h3 className=" text-primary">
                          <span className="num me-2">{index + 1}.</span>
                          <span className="title">{v.name}</span>
                        </h3>
                      </div>
                    </div>
                    <div
                      className={
                        "wizard-body bg-white p-5 " +
                        (index === wizardActv ? "" : "d-none")
                      }
                    >
                      {v.component}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return <Error404 />;
  }
}
