import ExcelJs from "exceljs";
import { MessageError, openModal } from '../../../../../_layouts/components/modals/Modals';
import { BlobImg } from '../../../../../_layouts/components/images/Logos';
import { GET_THS } from "../../../apis/node-target-data/AcademicApi";
import { fixedTwoDecimalsWithoutRounding, MergingScore } from "./CardScore";
import moment from "moment";
import { StudentIDFormat } from "../../../../../_layouts/_helpers/Global";

const DownloadScoreThsStd = async (param, studentid, setStatus) => {
    try {
        setStatus(true);
        const result = await GET_THS(param);

        if(result.data && Object.values(result.data).length > 0){
            ScoreStudent(result.data[0], studentid)
        }else{
            openModal({ message: <MessageError message={result.message} /> })
        }
        setStatus(false);
    } catch (error) {
        console.log(error);
        openModal({message:<MessageError message={"Failed to download. Please try again later."} />})
        setStatus(false);
    }
}

const ScoreStudent = (data, studentid) =>{
    const wb = new ExcelJs.Workbook();
    wb.creator = "UMS-SGU";
    wb.lastModifiedBy = "UMS-SGU-System";
    wb.created = new Date();
    wb.modified = new Date();
    wb.lastPrinted = new Date();

    SheetScore(wb, data,studentid);

    const writeFile = (fileName, content) => {
        const link = document.createElement("a");
        const blob = new Blob([content], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        });
        link.download = fileName;
        link.href = URL.createObjectURL(blob);
        link.click();
    };

    wb.xlsx.writeBuffer().then((buffer) => {
        writeFile(
            "Report Thesis Score Student "+StudentIDFormat(studentid),
            buffer
        );
    });
}

const SheetScore = (wb, data, studentid) =>{
    const ws = wb.addWorksheet("Score Thesis "+StudentIDFormat(studentid));
    const HeaderXLS = BlobImg();
    const imageId1 = wb.addImage({
        fileName: "logo-1",
        extension: "png",
        base64: HeaderXLS.imglogo,
    });
    const imageId2 = wb.addImage({
        fileName: "logo-2",
        extension: "png",
        base64: HeaderXLS.imglogoName,
    });

    ws.addImage(imageId1, "A1:B2");
    ws.addImage(imageId2, "E2:F2");
    let rowNumber = 4;

    ws.getRow(rowNumber).getCell("A").value = "Thesis Assessment Components";
    ws.getRow(rowNumber).getCell("A").font = {
        bold: true,
        size: 16,
    };
    ws.getRow(rowNumber).getCell("A").border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
    };
    ws.getRow(rowNumber).getCell("A").alignment = { horizontal: "center" };
    ws.mergeCells("A"+rowNumber+":F"+rowNumber);
    rowNumber = rowNumber + 2;

    ws.getRow(rowNumber).getCell("A").value = "Student ID: " + (data.studentid && (StudentIDFormat(data.studentid) || "")) ;
    ws.mergeCells("A"+rowNumber+":C"+rowNumber);
    rowNumber = rowNumber + 1;  

    ws.getRow(rowNumber).getCell("A").value = "Student Name: " + (data.studentdata && (data.studentdata.fullname || "")) ;
    ws.mergeCells("A"+rowNumber+":C"+rowNumber);
    rowNumber = rowNumber + 1;  
    ws.getRow(rowNumber).getCell("A").value = "Department: " + ((data.studentdata.clu && data.studentdata.clu.name) || "") ;
    ws.mergeCells("A"+rowNumber+":C"+rowNumber);
    rowNumber = rowNumber + 1;   
    if(data.studentdata.clu_spec){
        ws.getRow(rowNumber).getCell("A").value = "Specialization: " + ((data.studentdata.clu_spec && data.studentdata.clu_spec.name) || "");
        ws.mergeCells("A"+rowNumber+":C"+rowNumber);
        rowNumber = rowNumber + 1;   
    } 

    ws.getRow(rowNumber).getCell("A").value = "Title: " + (data.final_title || "") ;
    ws.mergeCells("A"+rowNumber+":I"+rowNumber);
    rowNumber = rowNumber + 1;  

    ws.getRow(rowNumber).getCell("A").value = "Advisor: " + (data.advisor && (data.advisor.fullname || ""));
    ws.mergeCells("A"+rowNumber+":C"+rowNumber);
    rowNumber = rowNumber + 1;  

    if(data.coadvisor){
        ws.getRow(rowNumber).getCell("A").value = "Co-Advisor: " + (data.coadvisor.fullname || "");
        ws.mergeCells("A"+rowNumber+":C"+rowNumber);
        rowNumber = rowNumber + 1;  
    }

    if(data.defensedate){
        ws.getRow(rowNumber).getCell("A").value = "Defense Date: " + (moment(data.defensedate + " " + data.defensetime).format("DD MMM YYYY, hh:mm A")) ;
        ws.mergeCells("A"+rowNumber+":C"+rowNumber);
        rowNumber = rowNumber + 1; 
    }

    if(data.duedate){
        ws.getRow(rowNumber).getCell("A").value = "Due Date: " + (moment(data.duedate).format("DD MMM YYYY")) ;
        ws.mergeCells("A"+rowNumber+":C"+rowNumber);
        rowNumber = rowNumber + 1; 
    }

    const rowHeader = ws.getRow(rowNumber);
    RowHeader(rowHeader, 1, "No");
    RowHeader(rowHeader, 2, "Component");
    let columnHeader = 3;
    
    if(Object.values(data.ths_main_ex).length > 0) {
        data.ths_main_ex.map(v =>{
            RowHeader(rowHeader, columnHeader, v.emp && (v.emp.fullname || ""));
            columnHeader = columnHeader + 1;
        })
    }

    let cHeader = columnHeader;
    RowHeader(rowHeader, cHeader, "Average Score");
    cHeader = cHeader + 1;
    RowHeader(rowHeader, cHeader, "Weight");
    cHeader = cHeader + 1;
    RowHeader(rowHeader, cHeader, "Weight Score");
    cHeader = cHeader + 1;

    rowNumber = rowNumber + 1;
    if(Object.values(data.student_course_com).length > 0){
        const scoreData = MergingScore(data.section_com, data.student_course_com, data.ths_main_ex)
        if(scoreData){
            let num = 1;
            scoreData.map(v =>{
                const row = ws.getRow(rowNumber);
                row.getCell(1).value = num++;
                row.getCell(2).value = v.name;
                let r = 3;
                data.ths_main_ex.map((user) => {
                    const findScore = v.scores.find(
                      (item) => item.submittedby_id === user.emp_id
                    );
                    row.getCell(r).value = findScore ? (findScore.score || "-" ): "-";
                    r++;
                })
                row.getCell(r++).value = v.average_score && fixedTwoDecimalsWithoutRounding(v.average_score);
                row.getCell(r++).value = v.weight + "%";
                row.getCell(r++).value = v.weighted_score && fixedTwoDecimalsWithoutRounding(v.weighted_score);

                row.eachCell({ includeEmpty: true }, (cell) => {
                    cell.border = {
                      top: { style: "thin" },
                      left: { style: "thin" },
                      bottom: { style: "thin" },
                      right: { style: "thin" },
                    };
                });
                rowNumber++;
            })

            rowNumber = rowNumber + 1; 
            const rowFinal = ws.getRow(rowNumber);
            rowFinal.getCell(2).value = "Final Score: " + (data.student_course && (data.student_course.score || "-"));
            rowFinal.getCell(4).value = "Final Result: " + (data.student_course && (data.student_course.course_status || "-"));
            rowFinal.getCell(6).value = "Grade: " + (data.student_course && (data.student_course.grade_fin || "-"));
            rowFinal.eachCell({ includeEmpty: true }, (cell) => {
                cell.font = {
                    bold: true,
                    size: 16,
                };
            });
            rowNumber = rowNumber + 1; 
        }
    }

    ws.columns.forEach((column, index) => {
        if (index === 0) {
            column.width = 5;
        }else if(index === 1){
            column.width = 15;
        } else {
            column.width = 20;
        }
    });

}

const RowHeader = (rowHeader, i, value) => {
    rowHeader.getCell(i).value = value;
    rowHeader.getCell(i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
    };
    rowHeader.getCell(i).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "191970" },
    };
    rowHeader.getCell(i).alignment = {
        vertical: "middle",
        horizontal: "center",
    };
    rowHeader.getCell(i).font = {
        bold: true,
        size: 11,
        color: { argb: "FFFFFF" },
    };
    rowHeader.getCell(i).alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
    };
}

export {DownloadScoreThsStd}