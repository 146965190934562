import axios from "axios";
import sign from 'jwt-encode';
import CryptoJS from "crypto-js";
import { GET_ENVIRONMENT } from "../_core/Core";
import { AuthDecodeToken } from "../../app/reducer/AuthRedux";

const env = GET_ENVIRONMENT();

const AxiosLibGlobal = async (config) => {
    const param = FetchHeader(config);

    try {
        let response = await axios(param)
            .then(function (response) {
                let result = response.data;
                
                if (response.status === 200 || response.status === 201) {
                    if (result.result === "success") {
                        return { loading: false, message: "", data: result }                        
                    } else if (result.result === "error") {
                        let msg = "";
                        if(result.response){
                            msg = result.response.message.user +". Code ("+result.response.message.code+")";
                        }else{
                            msg = result.message.user +". Code ("+result.message.code+")";
                        }
                        
                        return { loading: false, message: msg, data: [] }
                    } else if ("message" in result) {
                        return { loading: false, message: `${result.message.user} (${result.message.code})`, data: [] }
                    }else{
                        return { loading: false, message: result.message ? result.message : "" , data: result ? result : [] }
                    }
                } else {
                    return { loading: false, message: response.error ? response.error : "No record found", data: [] }
                }
            })
            .catch(function (error) {
                var message = error.message + ": Failed to connect to the server, please try again later.";
                return { loading: false, message: message, data: [] }
            })
        return response
    } catch (error) {
        return { loading: true, message: "", data: [] }
    }
}

const FetchHeader = (data) => {
    switch (data.service) {
        case "LOGIN":
            data.url = env.estesuri + env.estesauth
            break;
        case "LOGIN_USERSYS":
            data.url = env.backenduri + "api/user/check-exist"
            break;
        case "REQUEST_TARGET_DATA":{
            data.url = env.estesuri + env.estesreq;
            let headerReq = FetchHeaderReqDataEstes(data.headers, data.payload);
            data.headers = headerReq
            break;
        }
        case "REQUEST_EXTERNAL_PASS":{
            data.url = env.estesuri + data.pathuri;
            break;
        }
        case "BACKEND_REQUEST":
            data.url = env.backenduri + data.pathuri
            break;
        case "EXTERNAL_URI":
            data.url = data.pathuri
            data.maxBodyLength = Infinity
            break;
        default:
            data.url = env.backenduri + data.pathuri
            break;
    }
    const result = {
        data: data.payload,
        method: data.method,
        headers: data.headers,
        url: data.url
    }
    return result;
}

const FetchHeaderReqDataEstes = (headers, body) =>{
    const current_account = AuthDecodeToken()
    const Secretkey = current_account.secretkey;
    var d = new Date();
    var tstamp = d.getTime() / 1000;
    
    var payloadTmp = body;
    //var trimmed_payload = payloadTmp.toString().replace(/\s+/g, '');
    var trimmed_payload = JSON.stringify(payloadTmp).replace(/\s+/g, '');
    var base64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(trimmed_payload));
    //console.log(base64);
    var signature_raw = tstamp + '|<->|' + base64;
    //console.log(signature_raw);
    var signature_bytes = CryptoJS.HmacSHA256(signature_raw,Secretkey);
    //console.log(signature_bytes);
    var signature = CryptoJS.enc.Base64.stringify(signature_bytes);
    headers["App-Signature"] = signature;
    headers["App-Timestamp"] = tstamp;
    return headers;
}

async function AxiosLibEstes(value,pathURI) {
    let qs = await import('qs');
    let token = sign(value, "SGUAP*)_");
    
    var data_ = qs.stringify({
            'token' : token
    });
    var config = {
        method: 'post',
        url: env.estesuri+pathURI,
        headers: { 
            'Content-Type': 'application/x-www-form-urlencoded'            
        },
        data : data_
    };
    let response = await axios(config)
        .then(function (response) {
            var result = response.data;
            return result;
        })
        .catch(function (e) {
            console.log(e);
            var message = "Error connection data to middleware.";
            var result = {"error":message,"redirect":false}
            return result;
        })
    return response
}

export { AxiosLibGlobal, AxiosLibEstes }
