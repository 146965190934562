import sign from "jwt-encode";
import jwt_decode from "jwt-decode";
import { combineReducers } from "redux";
import CryptoJSAesJson from "../../_layouts/_helpers/CryptoJSAesJson";
import { GET_ENVIRONMENT, Private_key } from "../../_layouts/_core/Core";

const authReducer = (state, action) => {
  switch (action.type) {
    case "SIGN_IN":
      return action.payload;

    case "SIGN_OUT":
      localStorage.removeItem("token");
      localStorage.clear();
      window.location = "/login";
      return 0;
    default:
      return 0;
  }
};

const authStoreData = (value) => {
  const PRIVATE_KEY = Private_key;

  if (value) {
    var encrypted_password = JSON.parse(
      CryptoJSAesJson.encrypt(value, PRIVATE_KEY)
    );

    const token = sign(encrypted_password, PRIVATE_KEY);
    localStorage.setItem("token", token);
  }
};

const AuthReducers = combineReducers({
  AuthToken: authReducer,
});

const GetToken = localStorage.getItem("token");

const AuthDecodeToken = () => {
  const current_env = GET_ENVIRONMENT()
  var results;
  var mytoken = GetToken
  if (mytoken) {
    var token = mytoken ? jwt_decode(mytoken) : [];
    try {
      var decode_token = CryptoJSAesJson.decrypt(
        JSON.stringify(token),
        Private_key
      );
      results = decode_token;
  
      return results;  
    } catch (error) {
      window.location = current_env.baseuri + "/logout"
      return null;
    }
    
  }
};



export { AuthReducers, authReducer, authStoreData, AuthDecodeToken, GetToken };
