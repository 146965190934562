import { useState } from "react"
import { WAHotline } from "../../../../_layouts/_core/Core"

const ConsumerKey = ({ postData, setPostData }) => {
    const type = [{ id: 1, name: "production" }, { id: 2, name: "development" }]
    return (
        <div className="fv-row mb-8">
            <label className="form-label fw-bolder text-dark fs-6 mb-0">
                Customer Key
            </label>
            <select name="typeCK" id="typeCK" className="form-select form-select-lg form-select-solid text-capitalize" value={postData.typeCK} onChange={(e) => setPostData({ ...postData, typeCK: e.target.value })}>
                <option value="">Choose consumer key</option>
                {type.map((v, index) => (
                    <option value={v.name} className="text-capitalize" key={index}>{v.name}</option>
                ))}
            </select>
        </div>
    )
}
const InputUsername = ({ postData, setPostData }) => {
    const [username, setUsername] = useState(postData.username);
    const [isinvalid, setIsinvalid] = useState(false);
    const handlerText = (value) => {
        if (value) {
            setUsername(value);
            setPostData({ ...postData, username: value })
            setIsinvalid(false);
        } else {
            setIsinvalid(true);
        }
    }
    return (
        <div className="fv-row mb-8">
            <label className="form-label fs-6 fw-bolder text-dark">SGU Email</label>
            <div className="input-group">
                <input
                    type="text"
                    tabIndex={1}
                    autoFocus={true}
                    name="username"
                    maxLength={20}
                    className={"form-control form-control-lg form-control-solid mb-3 mb-lg-0 " + (isinvalid && "is-invalid")}
                    placeholder="firstname.lastname"
                    defaultValue={username}
                    onChange={(e) => handlerText(e.target.value)}
                />
                <span className="input-group-text border-0">{postData.email}</span>
            </div>
        </div>
    )
}

const InputPassword = ({ postData, setPostData, showTitle = false }) => {
    const [openpass, setOpenPass] = useState(false);
    const [password, setPassword] = useState(postData.password);
    const handlerText = (value) => {
        if (value) {
            setPassword(value);
            setPostData({ ...postData, password: value })
        }
    }
    return (
        <div className="fv-row mb-8">
            {!showTitle && (
                <div className="d-flex justify-content-between">
                    <label className="form-label fw-bolder text-dark fs-6 mb-0">
                        Password
                    </label>

                    <a href={WAHotline} target="_blank" className="link-primary fs-6 fw-bolder">
                        Forgot Password ?
                    </a>
                </div>
            )}
            <div className="position-relative mb-3">
                <input
                    className="form-control form-control-lg form-control-solid"
                    type={openpass ? "text" : "password"}
                    placeholder="Password"
                    tabIndex={2}
                    name="password"
                    defaultValue={password}
                    onChange={(e) => handlerText(e.target.value)}
                />
                <span
                    className="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2 text-hover-primary"
                    data-kt-password-meter-control="visibility"
                    onClick={() => openpass ? setOpenPass(false) : setOpenPass(true)} >
                    <i className={"bi bi-" + (openpass ? "eye-slash" : "eye") + " fs-2"}></i>
                </span>
            </div>
        </div>
    )
}

export { InputUsername, ConsumerKey, InputPassword }