import React, { useEffect, useMemo, useState } from "react";
import { useOutletContext } from "react-router-dom";
import {
  AlertNotif,
  AlertRetry,
} from "../../../../_layouts/components/alerts/AlertsUI";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import { GET_ACAD_LOOKUP } from "../../apis/node-target-data/AcademicApi";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  PaginationComponent,
  SearchBarTable,
} from "../../../../_layouts/components/datatables/MYDatatables";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../_layouts/components/loadbars/LoadingBars";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import moment from "moment";
import { AddStatus, ColorStatus } from "../helpers";
import { ButtonPrimary } from "../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../_metronic/helpers";
import { openModal } from "../../../../_layouts/components/modals/Modals";
import FormAcad from "./FormAcad";

export function MAcademicCalendar() {
  const context = useOutletContext();
  const widget_99 = context.widgets.find(
    (item) => item.widget_id === 99 && item.is_enabled === 1
  );
  const widget_100 = context.widgets.find(
    (item) => item.widget_id === 100 && item.is_enabled === 1
  );

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const ReloadData = async () => {
    setResults({ loading: true, data: [], message: "" });
    const result = await GET_ACAD_LOOKUP({ "type": "asc.atp.type" });
    if (result.data && Object.values(result.data).length > 0) {
      const r_acad = AddStatus(result.data);
      setResults({ loading: false, data: r_acad, message: "" });
    } else {
      setResults(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 15;

  const headers = [
    { name: "No", field: "id", sortable: false },
    { name: "Academic Term", field: "name", sortable: true },
    { name: "Program Degree", field: "program_id", sortable: true },
    { name: "Active Periode", field: "start_dt_formatted", sortable: true },
    { name: "Type", field: "atp_group_type", sortable: false },
    { name: "Status", field: "status", sortable: true },
  ];

  const ResultData = useMemo(() => {
    let computedData = results.data;

    if (Object.values(results.data).length > 0) {
      computedData.sort((a, b) => (a.id < b.id ? 1 : -1));
    }

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some(
          (key) =>
            listData[key]
              .toString()
              .toLowerCase()
              .includes(search)
          //console.log(key,listData[key])
        );
      });
    }

    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [results.data, search, sorting, currentPage]);

  return widget_99 ? (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bolder text-dark">
          Academic Calendar
        </span>
        <span className="text-muted mt-1 fw-bold fs-7">
          list of academic calendar periode
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        {widget_100 && (
        <ButtonPrimary type="button" className="btn-sm fw-bold" onClick={()=>openModal({message:<FormAcad />})}>
            <KTIcon iconName="plus" />
            Create New 
        </ButtonPrimary>
        )}
      </SGUCard.Toolbar>
      <SGUCard.Body>
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <SearchBarTable
            size="w-250px"
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="d-flex">
            <ButtonItemRefresh
              totalItems={totalItems}
              onClick={() => ReloadData()}
            />
          </div>
        </div>
        {results.loading ? (
          <LoadingSkeleton />
        ) : results.message ? (
          <AlertRetry messages={results.message} Reload={ReloadData} />
        ) : (
          <div className="table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
              <HeaderDatatables
                headers={headers}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <tbody>
                {ResultData.length > 0 ? (
                  ResultData.map((v, index) => (
                    <tr key={index}>
                      <td width={"3%"} className="fs-7">
                        {index + 1}
                      </td>
                      <td className="fs-7">{v.name}</td>
                      <td className="fs-7">
                        {(v.program && v.program.name) || ""}
                      </td>
                      <td className="fs-7">
                        {v.start_dt_formatted &&
                          (moment(v.start_dt_formatted).format(
                            "DD MMM YYYY hh:mm A"
                          ) ||
                            "")}
                        <span className="mx-3 text-muted">until</span>
                        {moment(v.end_dt_formatted).format(
                          "DD MMM YYYY hh:mm A"
                        )}
                      </td>
                      <td className="fs-7">{v.atp_group_type}</td>
                      <td><BadgeStatus status={v.status} /></td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <NoRecordFound />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {ResultData.length > 0 && (
              <PaginationComponent
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
          </div>
        )}
      </SGUCard.Body>
    </SGUCard>
  ) : (
    <AlertNotif messages={AccessInvalid} />
  );
}

const BadgeStatus = ({ status }) => {
  const status_label = ColorStatus(status);
  return (
    <span className={`fs-7 badge badge-light-${status_label.color}`}>
      {status}
    </span>
  );
};
