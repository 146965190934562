import React from "react";
import {
  SGUCard,
  SGUCardFlush,
} from "../../../../../_layouts/components/cards/CardUI";
import FindStudent from "./students/FindStudent";
import FindStaff from "./staff/FindStaff";
import { LinkBtn } from "../../../../../_layouts/components/buttons/LinkUI";

export function FindPeople() {
  return (
    <>
      <div className="mb-5">
        <div className="d-flex align-items-center">
          <div className="symbol symbol-circle me-5">
            <div className="symbol-label bg-white text-primary border border-secondary border-dashed">
              <i className="ki-outline ki-user fs-2x text-primary"></i>
            </div>
          </div>
          <div className="d-flex flex-column w-100">
            <h2>Search People</h2>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-6 mb-5 mb-xl-10">
          <SGUCard>
            <SGUCard.Header>
              <span className="card-label fw-bold text-gray-800">
                Find Student
              </span>
            </SGUCard.Header>
            <SGUCard.Toolbar>
                <LinkBtn className="btn btn-sm btn-light text-primary" to='/students/search'>
                    <i className="ki-outline ki-setting-4 fs-2 text-primary"></i>
                    <span className="ms-2">Advance Filter</span>
                </LinkBtn>
            </SGUCard.Toolbar>
            <SGUCard.Body>
              <FindStudent />
            </SGUCard.Body>
          </SGUCard>
        </div>
        <div className="col-lg-6 mb-5 mb-xl-10">
          <SGUCard>
            <SGUCard.Header>
              <span className="card-label fw-bold text-gray-800">
                Find Employee / Lecturer
              </span>
            </SGUCard.Header>
            <SGUCard.Body>
              <FindStaff />
            </SGUCard.Body>
          </SGUCard>
        </div>
      </div>
    </>
  );
}
