export {CourseManagementLayout} from "./CourseManagementLayout"
export {TeachingLoads} from "./teaching-loads"
export {FormCreateSection} from "./teaching-loads/form-create/FormCreateSection"

export {BrowseSection} from "./sections"
export {LookUpCourseCatalog} from "./master-courses/components/LookUpCourseCatalog"
export {DetailSection} from "./sections/DetailSection"
export {GeneralInfo} from "./sections/components/detail-sections/GeneralInfo"
export {AttendanceInfo} from "./sections/components/detail-sections/AttendanceInfo"
export {ScoreInfo} from "./sections/components/detail-sections/ScoreInfo"
export {TimetableInfo} from "./sections/components/detail-sections/TimetableInfo"

export {MCourse} from "./master-courses/MCourse"
export {DetailCourse} from "./master-courses/DetailCourse"

export {TransferCreditProposal} from "./transfer-credits/proposal-form"
export {FormProposalCredit} from "./transfer-credits/proposal-form/FormProposalCredit"
export {PreviewProposalCredit} from "./transfer-credits/proposal-form/PreviewProposalCredit"
export {TCDetail} from "./transfer-credits/proposal-form/componensts/TCDetail"

export {TCRegisProposal} from "./transfer-credits/registration-form"
export {FormProposalRegis} from "./transfer-credits/registration-form/FormProposalRegis"