import React, { useEffect, useState } from "react";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import { GET_ATD_STUDENT_BY_COURSE, UPDATE_STD_ATD } from "../../../apis/node-target-data/AcademicApi";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../../_layouts/components/alerts/AlertsUI";
import moment from "moment";
import { KTIcon } from "../../../../../_metronic/helpers";
import { ButtonItemRefresh } from "../../../../../_layouts/components/datatables/MYDatatables";
import { ButtonPrimary, ButtonTersier } from "../../../../../_layouts/components/buttons/ButtonUI";
import { MessageError, MessageSuccess, openModal } from "../../../../../_layouts/components/modals/Modals";
import { LocationCoordinate } from "../../lecturers/components/StudentList";

export default function TableAttd({ atp_id, student_id, course_id }) {
  const [totalItems, setTotalItems] = useState(0);
  const [results, setResults] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const [submit, setSubmit] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const [postData, setPostData] = useState([]);
  const ReloadData = async () => {
    setResults({ loading: true, message: "", data: [] });
    const param = { atpid: atp_id, studentid: student_id, courseid: course_id };

    const result = await GET_ATD_STUDENT_BY_COURSE(param);
    if (result.data && Object.values(result.data).length > 0) {
      setTotalItems(Object.values(result.data).length);
      setResults({ loading: false, message: "", data: result.data });
      setPostData(result.data);
    } else {
      setResults(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandlerSubmit = async (e) =>{
    e.preventDefault();
    setSubmit({loading: true, message: "", data: []});
    const param = postData.map(v => {
      var obj = {};
        obj.atd_std_id = v.id;
        obj.remark = v.remark ? v.remark : "";
        obj.status = v.status;
      return obj;
    })
    const result = await UPDATE_STD_ATD({student:param});
    if(result.data && Object.values(result.data).length > 0){
      openModal({message:<MessageSuccess message={"Successfully saved"} />});
      setSubmit({loading: false, message: "Success", data: result.data});
      ReloadData();
    }else{
      openModal({message:<MessageError message={result.message} />});
      setSubmit(result);
    }
  }
  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bolder text-dark">
          Attendance Detail
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        <ButtonItemRefresh
          totalItems={totalItems}
          onClick={(e) => ReloadData(e)}
        />
      </SGUCard.Toolbar>
      <SGUCard.Body className={"px-0"}>
        {results.loading ? (
          <LoadingSkeleton />
        ) : results.message ? (
          <AlertRetry messages={results.message} Reload={ReloadData} />
        ) : (
          <div className="table-responsive">
            <form method="post" autoComplete="off" onSubmit={(e)=>HandlerSubmit(e)}>
              <table className="table table-row-bordered align-middle gs-9">
                <thead className="border-bottom border-gray-200 fs-6 text-gray-600 fw-bolder bg-light bg-opacity-75">
                  <tr className="fw-bolder">
                    <th width="5%">No</th>
                    <th>Class Info</th>
                    <th>Schedule</th>
                    <th>Teaching Topic</th>
                    <th width="10%">Tap Time</th>
                    <th width="20%">Attendance</th>
                    <th width="15%">Remark</th>
                  </tr>
                </thead>
                <tbody>
                  {totalItems > 0 ? (
                    postData.map((v, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>
                          <ClassInfo data={v.atd_lecturer} />
                        </td>
                        <td>
                          <Schd data={v.atd_lecturer} />
                        </td>
                        <td>
                          <div className="flex-wrap fs-8">
                            {v.atd_lecturer.teaching_topic}
                          </div>
                        </td>
                        <td width={"8%"}>
                          <span className="fw-bold fs-8">{v.tap_time}</span>
                        </td>
                        <td>
                          <AtdStd data={v} setPostData={setPostData} />
                        </td>
                        <td>
                          {v.flag_sts === 3 ? (
                            <div className="fs-7 text-danger">
                              <KTIcon
                                iconName="notepad-bookmark"
                                className="text-danger"
                              />
                              {v.remark}
                            </div>
                          ) : (
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              defaultValue={v.remark}
                              onChange={(e)=>{
                                setPostData((prev) => {
                                  const newData = [...prev];
                                  const index = newData.findIndex((item) => item.id === v.id);
                                  newData[index].remark = e.target.value;
                                  return newData;
                                });
                              }}
                            />
                          )}
                          {v.coordinate && <div className="mt-2"><LocationCoordinate values={v} /></div> }
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colspan="7">
                        <NoRecordFound />
                      </td>
                    </tr>
                  )}
                </tbody>
                {totalItems > 0 && (
                  <tfoot>
                    <tr>
                      <td colSpan={7} className="text-end"> 
                        <div className="btn-group">
                          <ButtonTersier
                            className="btn-lg fw-bold text-uppercase"
                            type="button"
                            onClick={() => window.close()}
                          >
                            Cancel
                          </ButtonTersier>
                          <ButtonPrimary
                            className="btn-lg fw-bold text-uppercase"
                            type="submit"
                            disable={submit.loading}
                          >
                            {submit.loading ? "Processing..." : "Save Changes"}
                          </ButtonPrimary>
                        </div>
                      </td>
                    </tr>
                  </tfoot>
                )}
              </table>
            </form>
          </div>
        )}
      </SGUCard.Body>
    </SGUCard>
  );
}

const ClassInfo = ({ data }) => {
  let activity_type = "";
  if (data.activity_type) {
    const activity_arr = data.activity_type.split(".");
    activity_type = activity_arr[Object.values(activity_arr).length - 1];
  }
  return (
    <div className="fs-7">
      <span className="fw-bold d-block">
        {(data.emp && data.emp.fullname) || "-"}
      </span>
      <span className="text-muted text-capitalize fs-8">Type: {activity_type}</span>
      {data.session && (
        <>
          <span className="mx-2">·</span>
          <span className="text-muted text-capitalize fs-8">
            Session: {data.session}
          </span>
        </>
      )}
    </div>
  );
};

const Schd = ({ data }) => {
  return (
    <div className="fs-7">
      <span className="d-block fw-bold">
        {moment(data.start_dt).format("MMMM Do YYYY")}
      </span>
      <div className="text-muted fs-8">
        <span>{moment(data.start_dt).format("hh:mm A")}</span>
        {data.end_dt && (
          <>
            <span className="mx-2">-</span>
            <span>{moment(data.end_dt).format("h:mm A")}</span>
          </>
        )}
      </div>
    </div>
  );
};

const AtdStd = ({ data, setPostData }) => {
  const handlerTick = (value, id) => {
    setPostData((prev) => {
      const newData = [...prev];
      const index = newData.findIndex((item) => item.id === id);
      newData[index].status = value;
      return newData;
    });
  };
  return (
    <div>
      <label className="me-2">
        <input
          type="radio"
          name={data.id + "-atd-id"}
          value={"P"}
          disabled={data.flag_sts === 3 ? true : false}
          defaultChecked={data.status === "P" ? true : false}
          onChange={(e) => handlerTick(e.target.value, data.id)}
        />{" "}
        Present
      </label>
      <label>
        <input
          type="radio"
          name={data.id + "-atd-id"}
          value={"A"}
          disabled={data.flag_sts === 3 ? true : false}
          defaultChecked={data.status === "A" ? true : false}
          onChange={(e) => handlerTick(e.target.value, data.id)}
        />{" "}
        Absent
      </label>
    </div>
  );
};
