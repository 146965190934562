import React from 'react'
import { CardCourseOwner, CardGoogleClass, CardLecturerAssign, CardSectionInfo, CardStudentList, CardStudyProgram } from './general-info';
import CardDeleteSection from './general-info/CardDeleteSection';

export function GeneralInfo() {
    return (
        <div>
            <div className="general-info">
                <h3 className='mb-5'>General Information</h3>
                <div className="row">
                    <div className="col-12 col-lg-8 mb-8">
                        <div className="row">
                            <div className="col-12 col-lg-6 mb-8">
                                <CardSectionInfo />
                            </div>
                            <div className="col-12 col-lg-6 mb-8">
                                <CardCourseOwner />
                            </div>
                            <div className="col-12 mb-8">
                                <CardStudentList />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4 mb-8">
                        <CardStudyProgram />
                        <div className="my-5">
                            <CardLecturerAssign />
                        </div>
                        <div className="my-5">
                            <CardGoogleClass />
                        </div>
                        <div className="my-5">
                            <CardDeleteSection />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

