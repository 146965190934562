import { useNavigate } from "react-router-dom";
import { ButtonIcon, ButtonLong, ButtonTersier } from "../../../../../_layouts/components/buttons/ButtonUI";

const ButtonCreateSection = ({ param, ReloadData }) => {
  const navigate = useNavigate();
  const course_id = param.course_id || param.id || 0;
  const course_lesson = param.course_lesson || 0;
  
  return (
    <ButtonTersier className="btn-sm btn-light btn-active-light fw-bold" onClick={()=>navigate('create-section',{state:{course_id:course_id, param:param.paramTL, course_lesson:course_lesson}})}>
      <i className="ki-outline ki-plus-circle fs-5 m-0"></i>
      <span className="fs-7">Add Section</span>
    </ButtonTersier>
  );
};

const ButtonBack = ({Back2List}) =>{
    return (
        <ButtonLong className={"btn-lg bg-white border btn-active-light-primary mb-5"} onClick={()=>Back2List()}>
          <div className="d-flex align-items-center">
            <div className="symbol symbol-circle me-5">
              <div className="symbol-label bg-light text-primary border border-secondary border-dashed">
                <i className="ki-outline ki-arrow-left fs-2x text-primary"></i>
              </div>
            </div>
            <div className="text-start">
                <h3>Back </h3>
                <div className="text-muted fw-semibold">To Teaching Load</div>
            </div>
          </div>
        </ButtonLong>
    )
}

export { ButtonCreateSection, ButtonBack };
