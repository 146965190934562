import React, { useState } from "react";
import { SGUCardFlush } from "../../../_layouts/components/cards/CardUI";
import { ButtonLong } from "../../../_layouts/components/buttons/ButtonUI";
import {
  MessageSuccess,
  openModal,
} from "../../../_layouts/components/modals/Modals";
import { RequiredMsg } from "../../../_layouts/_core/ErrorMessage";
import CryptoJSAesJson from "../../../_layouts/_helpers/CryptoJSAesJson";

export default function DecodePassword() {
  const [passJSON, setPassJSON] = useState("");
  const publicKey = "S!ZcdNb4XXcw$jXbHQqiFsHp6";

  const generatePassword = () => {
    if (passJSON) {
      console.log(passJSON);

      try {
        let parsedJSON;
        try {
          parsedJSON = JSON.parse(passJSON);
        } catch (parseError) {
          const unescapedJSON = passJSON
            .replace(/\\"/g, '"')
            .replace(/\\\\/g, "\\");
          parsedJSON = JSON.parse(unescapedJSON);
        }

        if (!parsedJSON || typeof parsedJSON !== "object") {
          throw new Error("Invalid JSON structure");
        }
        const decodePass = CryptoJSAesJson.decrypt(
          JSON.stringify(parsedJSON),
          publicKey
        );
        console.log(decodePass);
        if (!decodePass) {
          throw new Error("Decryption failed");
        }
        openModal({
          message: (
            <MessageSuccess
              message={`Password: ${decodePass}`}
              title={"Decode Password Success"}
            />
          ),
        });
      } catch (error) {
        console.error("Error:", error.message);
        openModal({
          message: `Error: ${error.message}. Please check your input.`,
        });
      }
    } else {
      openModal({ message: RequiredMsg });
    }
  };

  return (
    <SGUCardFlush>
      <SGUCardFlush.Body className={"pt-5"}>
        <div className="form-group mb-5">
          <label className="required fw-bold">Enter Pass Encode</label>
          <textarea
            className="form-control"
            rows="2"
            value={passJSON}
            onChange={(e) => setPassJSON(e.target.value)}
          />
        </div>
        <ButtonLong
          className="btn btn-lg btn-primary"
          type="button"
          onClick={() => generatePassword()}
        >
          Decode Password
        </ButtonLong>
      </SGUCardFlush.Body>
    </SGUCardFlush>
  );
}
