import React, { useState } from 'react'
import FilterUI from '../../filters/FilterUI'
import { CourseCode, CourseTitle } from '../InputText'
import { Coursetype } from '../SelectBox'
import { ButtonPrimary, ButtonTersier } from '../../../../../_layouts/components/buttons/ButtonUI'
import { openModal } from '../../../../../_layouts/components/modals/Modals'
import { SGUCard } from '../../../../../_layouts/components/cards/CardUI'
import { LoadingSkeleton, NoRecordFound } from '../../../../../_layouts/components/loadbars/LoadingBars'
import { AlertNotif } from '../../../../../_layouts/components/alerts/AlertsUI'
import { TableData } from './TableData'
import { RemoveEmptyValue } from '../../../../../_layouts/_helpers/Global'
import { GET_COURSE } from '../../../apis/node-target-data/AcademicApi'

export default function InputLookUp({ chooseSubject }) {
    const [coursecode, setCoursecode] = useState("");
    const [coursetitle, setCourseTitle] = useState("");
    const [coursetype, setCourseType] = useState("");
    const postParam = {
        coursecode,
        coursetitle,
        coursetype,
    };

    const HandlerAddCourse = (value) =>{
        console.log(value);
        chooseSubject(value);
    }

    const [results, setResults] = useState({
        loading: false,
        message: "",
        data: [],
    });

    const ReloadData = async () => {
        setResults({ loading: true, message: "", data: [] });
        const param = RemoveEmptyValue(postParam);
        const result = await GET_COURSE(param);
        setResults(result);
    };

    const SubmitFilter = async (e) => {
        e.preventDefault();
        ReloadData();
    };

    return (
        <div className='row'>
            <div className="col-lg-3">
                <FilterUI onSubmit={SubmitFilter}>
                    <CourseCode data={coursecode} setData={setCoursecode} />
                    <CourseTitle data={coursetitle} setData={setCourseTitle} />
                    <Coursetype setData={setCourseType} />
                </FilterUI>
            </div>
            <div className="col-lg-9">
                <SGUCard>
                    <SGUCard.Header>
                        <span className="card-label fw-bold text-gray-800">
                            Course Catalog
                        </span>
                        <span className="text-muted mt-1 fw-semibold fs-7">
                            Browse the course catalog
                        </span>
                    </SGUCard.Header>
                    <SGUCard.Body>
                        {results.loading ? (
                            <LoadingSkeleton />
                        ) : results.message ? (
                            <AlertNotif messages={results.message} color="danger" />
                        ) : Object.values(results.data).length > 0 ? (
                            <TableData data={results.data} ReloadData={ReloadData} HandlerAddCourse={HandlerAddCourse} />
                        ) : (
                            <NoRecordFound />
                        )}
                    </SGUCard.Body>
                </SGUCard>
            </div>
        </div>
    )
}
