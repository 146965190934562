import React from 'react'

export default function FormAcad() {
  return (
    <div>
        <h3>Form create Academic Calendar</h3>
        <form method="post" autoComplete='off'>
            <div className="form-group mb-5">
                sds
            </div>
        </form>
    </div>
  )
}
