const ColorRoomInfo = () => {
    return (
        <div>
            <p className="mb-0 fs-8 fw-bold">Color info:</p>
            <div className="d-flex align-items-center">
                <div className='me-3'>
                    <div className="p-1 rounded bg-white border"></div>
                    <span className='fs-8'>Room Disabled</span>
                </div>
                <div className='me-3'>
                    <div className="p-1 rounded bg-success"></div>
                    <span className='fs-8'>Room Available</span>
                </div>
                <div className='me-3'>
                    <div className="p-1 rounded bg-danger"></div>
                    <span className='fs-8'>Room Occupied</span>
                </div>
            </div>
        </div>
    )
}

export {ColorRoomInfo}