import React, { useEffect, useRef, useState } from 'react'
import { NoRecordFound } from '../../../../_layouts/components/loadbars/LoadingBars';
import "./TableStdPerformance.css";

export default function TableData({ results }) {
    const scrollbarHolderRef = useRef(null);
    const tableWrapperRef = useRef(null);
    const tableRef = useRef(null);

    useEffect(() => {
        const scrollbarHolder = scrollbarHolderRef.current;
        const tableWrapper = tableWrapperRef.current;
        const table = tableRef.current;

        if (table) {
            const handleScroll = () => {
                if (tableWrapper) {
                    tableWrapper.scrollLeft = scrollbarHolder.scrollLeft;
                }
            };

            const updateDummyWidth = () => {
                if (scrollbarHolder.firstChild) {
                    scrollbarHolder.firstChild.style.width = `${table.offsetWidth}px`;
                }
            };

            if (!scrollbarHolder.firstChild) {
                const dummyDiv = document.createElement("div");
                scrollbarHolder.appendChild(dummyDiv);
            }

            updateDummyWidth();

            scrollbarHolder.addEventListener("scroll", handleScroll);
            window.addEventListener("resize", updateDummyWidth);

            return () => {
                scrollbarHolder.removeEventListener("scroll", handleScroll);
                window.removeEventListener("resize", updateDummyWidth);
            };
        }
    }, []);

    const data = results.data;
    return Object.values(data).length > 0 ? (
        ((Object.values(data.column.semester_status).length > 0) && (Object.values(data.column.courses).length > 0)) ? (
            <div className="table-responsive" style={{ width: "100%", overflow: "hidden" }}>
                <div
                    className="scrollbar-holder"
                    ref={scrollbarHolderRef}
                    style={{ overflowX: "auto", overflowY: "hidden", height: "20px" }}
                ></div>
                <div
                    className="table-wrapper"
                    ref={tableWrapperRef}
                    style={{ overflowX: "auto", overflowY: "hidden" }}
                >
                    <table className="table table-flush align-middle table-row-bordered table-row-solid" id="table-to-xls" style={{ minWidth: "600px", tableLayout: "auto" }} ref={tableRef}>
                        <thead className="border-gray-200 fs-5 fw-bold bg-secondary">
                            <tr>
                                <th className="border border-dark text-center" style={{ minWidth: "120px", verticalAlign: "middle" }} rowSpan={3}>Student ID</th>
                                <th className="border border-dark text-center" style={{ minWidth: "250px", verticalAlign: "middle" }} rowSpan={3}>Student Fullname</th>
                                <th className="border border-dark text-center" style={{ minWidth: "150px", verticalAlign: "middle" }} rowSpan={3}>Enrollment Type</th>
                                <th className="border border-dark text-center" style={{ verticalAlign: "middle" }} colSpan={6}>Cummulative</th>
                                <th className="border border-dark text-center" colSpan={(Object.values(data.column.semester_status).length * 5)}>Semester Status</th>
                                <th className="border border-dark text-center" colSpan={Object.values(data.column.courses).length * 5} >Course Status</th>
                            </tr>
                            <tr>
                                <th style={{ minWidth: "100px", verticalAlign: "middle" }} rowSpan={2} className="border border-dark text-center">Predicate</th>
                                <th style={{ minWidth: "100px", verticalAlign: "middle" }} rowSpan={2} className="border border-dark text-center">Remarks</th>
                                <th style={{ minWidth: "80px", verticalAlign: "middle" }} rowSpan={2} className="border border-dark text-center">GPA</th>
                                <th style={{ minWidth: "80px", verticalAlign: "middle" }} rowSpan={2} className="border border-dark text-center">Credit</th>
                                <th style={{ minWidth: "80px", verticalAlign: "middle" }} rowSpan={2} className="border border-dark text-center">Avg Score</th>
                                <th style={{ minWidth: "90px", verticalAlign: "middle" }} rowSpan={2} className="border border-dark text-center">Avg Grade</th>
                                {data.column.semester_status.map((c, index) => (
                                    <th colSpan={5} style={{ minWidth: "150px" }} className="border border-dark text-center" key={index}>{c.name}</th>
                                ))}
                                {data.column.courses.map((c, index) => (
                                    <th style={{ minWidth: "200px", verticalAlign: "middle" }} colSpan={5} className="border border-dark text-center" key={index}>{c.name} (Credit {c.credit})</th>
                                ))}
                            </tr>
                            <tr>
                                {data.column.semester_status.map(() => (
                                    <>
                                        <th className="border border-dark text-center" style={{ minWidth: "90px", verticalAlign: "middle" }} >
                                            Semester
                                        </th>
                                        <th className="border border-dark text-center" style={{ minWidth: "80px", verticalAlign: "middle" }} >
                                            GPA
                                        </th>
                                        <th className="border border-dark text-center" style={{ minWidth: "80px", verticalAlign: "middle" }} >
                                            Credit
                                        </th>
                                        <th className="border border-dark text-center" style={{ minWidth: "80px", verticalAlign: "middle" }} >
                                            Avg Score
                                        </th>
                                        <th className="border border-dark text-center" style={{ minWidth: "90px", verticalAlign: "middle" }} >
                                            Avg Grade
                                        </th>
                                    </>
                                ))}
                                {data.column.courses.map(() => (
                                    <>
                                        <th className="border border-dark text-center" style={{ minWidth: "90px", verticalAlign: "middle" }}>Section ID</th>
                                        <th className="border border-dark text-center" style={{ minWidth: "150px", verticalAlign: "middle" }}>Lecturer</th>
                                        <th className="border border-dark text-center" style={{ minWidth: "80px", verticalAlign: "middle" }}>Points</th>
                                        <th className="border border-dark text-center" style={{ minWidth: "80px", verticalAlign: "middle" }}>Grade</th>
                                        <th className="border border-dark text-center" style={{ minWidth: "80px", verticalAlign: "middle" }}>Result</th>
                                    </>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.values(data.row).length > 0 ? (
                                data.row.map((v, index) => {
                                    let enrollTypeArr = v.enrollment_type.split('.')
                                    let enrollType = enrollTypeArr[Object.values(enrollTypeArr).length - 1]
                                    return (
                                        <tr key={index}>
                                            <td className="border border-dark ps-2">{v.studentid}</td>
                                            <td className="border border-dark ps-2">{v.fullname}</td>
                                            <td className="border border-dark ps-2 text-capitalize">{enrollType}</td>
                                            <td className="border border-dark ps-2 text-capitalize">{v.predicate}</td>
                                            <td className="border border-dark ps-2 text-capitalize">{v.graduate_fail_descr}</td>
                                            <td className="border border-dark text-center">{v.gpa}</td>
                                            <td className="border border-dark text-center">{v.credit}</td>
                                            <td className="border border-dark text-center">{v.avg_point}</td>
                                            <td className="border border-dark text-center">{v.avg_grade}</td>
                                            {data.column.semester_status.map((s) => {
                                                let results = v.semester_status.find(item => item.atp_id_term === s.id);
                                                return (
                                                    <>
                                                        <td className="border border-dark text-center">
                                                            {(results) ? results.semester : ""}
                                                        </td>
                                                        <td className="border border-dark text-center">
                                                            {(results) ? results.gpa : ""}
                                                        </td>
                                                        <td className="border border-dark text-center">
                                                            {(results) ? results.credit_all : ""}
                                                        </td>
                                                        <td className="border border-dark text-center">
                                                            {(results) ? results.avg_point : ""}
                                                        </td>
                                                        <td className="border border-dark text-center">
                                                            {(results) ? results.avg_grade : ""}
                                                        </td>
                                                    </>
                                                )
                                            })}

                                            {data.column.courses.map((c) => {
                                                let results = v.courses.find(item => item.course_id === c.id);
                                                return (
                                                    <>
                                                        <td className="border border-dark text-center">
                                                            {(results) ? (
                                                                Object.values(results.section).length > 0 ? (
                                                                    results.section.map((x, index) => (
                                                                        <span className="d-block" key={index}>{x.section_id}</span>
                                                                    ))
                                                                ) : ""
                                                            ) : ""}
                                                        </td>
                                                        <td className="border border-dark ps-2">
                                                            {(results) ? (
                                                                Object.values(results.section).length > 0 ? (
                                                                    results.section.map((x) => (
                                                                        (Object.values(x.emp).length > 0) ? (
                                                                            x.emp.map((e, index) => (
                                                                                <span className="d-block fs-sm" key={index}>{e.name}</span>
                                                                            ))
                                                                        ) : ""
                                                                    ))
                                                                ) : ""
                                                            ) : ""}
                                                        </td>
                                                        <td className="border border-dark text-center">
                                                            {(results) ? results.score : ""}
                                                        </td>
                                                        <td className="border border-dark text-center">
                                                            {(results) ? results.grade_fin : ""}
                                                        </td>
                                                        <td className="border border-dark text-center">
                                                            {(results) ? results.course_status : ""}
                                                        </td>
                                                    </>
                                                )
                                            })}
                                        </tr>
                                    )
                                }
                                )
                            ) : ""}
                        </tbody>
                    </table>
                </div>
            </div>
        ) : <div className="px-8"><NoRecordFound /></div>
    ) : <div className="px-8"><NoRecordFound /></div>
}
