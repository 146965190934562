import React, { useState } from 'react'
import FilterUI from '../../filters/FilterUI'
import { StudentID, StudentName } from '../InputText';
import { GET_STUDENT } from '../../../apis/node-target-data/AcademicApi';
import { LoadingBars } from '../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../../_layouts/components/alerts/AlertsUI';
import TableData from './TableData';
import { RequiredMsg } from '../../../../../_layouts/_core/ErrorMessage';
import { ButtonLong } from '../../../../../_layouts/components/buttons/ButtonUI';
import { RemoveEmptyValue } from '../../../../../_layouts/_helpers/Global';

export default function InputLookUpStd({ HandlerAddStudent }) {
    const [studentid, setStudentId] = useState("");
    const [studentname, setStudentName] = useState("");

    const [students, setStudents] = useState({
        message: "",
        data: [],
        loading: false,
    });

    const paramObj = { studentid, studentname };
    const SubmitFilter = async (e) => {
        e.preventDefault();
        if (paramObj.studentid !== "" || paramObj.studentname !== "") {
            setStudents({
                message: "",
                data: [],
                loading: true,
            })
            const param = RemoveEmptyValue(paramObj);
            const result = await GET_STUDENT(param);

            setStudents(result);
            setIsOpen(true);
        } else {
            setStudents({ loading: false, data: [], message: RequiredMsg })
        }
    }
    const [isopen, setIsOpen] = useState(false);
    return (
        <div>
            {!isopen && (
                <FilterUI onSubmit={SubmitFilter} isload={students.loading}>
                    <div className="row">
                        <div className="col-12 col-lg-6">
                            <StudentName data={studentname} setData={setStudentName} />
                        </div>
                        <div className="col-12 col-lg-6">
                            <StudentID data={studentid} setData={setStudentId} />
                        </div>
                    </div>
                </FilterUI>
            )}

            {isopen && (
                <>
                    <ButtonLong type="button" className="btn-light-info mb-5" onClick={() => setIsOpen(!isopen)}>
                        <i className="ki-outline ki-filter fs-5 m-0"></i>
                        Show Filter
                    </ButtonLong>
                    <div className="table-responsive">
                        {students.loading ? <LoadingBars /> :
                            students.message ? <AlertNotif messages={students.message} /> :
                                (Object.values(students.data).length > 0 && (
                                    <TableData data={students.data} HandlerAddStudent={HandlerAddStudent} />
                                ))}
                    </div>
                </>
            )}
        </div>
    )
}
