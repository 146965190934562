import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import { GET_THS_EXAMINER } from "../../../../apis/node-target-data/AcademicApi";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../../../_layouts/components/alerts/AlertsUI";
import { SGUCard } from "../../../../../../_layouts/components/cards/CardUI";
import { ButtonPrimary } from "../../../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../../../_metronic/helpers";
import TableData from "./TableData";

export function Examiners() {
  const context_data = useOutletContext();
  const widgets = context_data.widgets;
  const context = context_data.data;
  const [addNew, setAddNew] = useState({open:false, data:""});
  
  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadData = async () => {
    setAddNew({open:false, data:""});
    setResults({ loading: true, data: [], message: "" });
    const param = {
      section_id: context && (context.section_id || ""),
      studentid: context && (context.studentid || ""),
    };
    const result = await GET_THS_EXAMINER(param);
    setResults(result);
  };

  useEffect(() => {
    ReloadData();
  }, []);

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bolder text-dark">
          Evaluation Committee
        </span>
        <span className="text-muted mt-1 fw-bold fs-7">List of Examiners</span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        {(!addNew.open && widgets.widget_77) && (
          <ButtonPrimary type="button" className="btn-sm fw-bold" onClick={()=>setAddNew({open:true, data:""})}>
            <KTIcon iconName="plus" className="text-white" />
            Add New
          </ButtonPrimary>
        )}
      </SGUCard.Toolbar>
      <SGUCard.Body>
        {results.loading ? (
          <LoadingSkeleton />
        ) : results.message ? (
          <AlertRetry messages={results.message} Reload={ReloadData} />
        ) : Object.values(results.data).length > 0 ? (
          <TableData results={results} ReloadData={ReloadData} addNew={addNew} setAddNew={setAddNew} />
        ) : (
          <NoRecordFound />
        )}
      </SGUCard.Body>
    </SGUCard>
  );
}
