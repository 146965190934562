import React from 'react'
import { KTIcon } from '../../../_metronic/helpers'
import { useProvider } from '../../UMSLayoutProvider';

export default function Profile() {
    const { account } = useProvider();
    return (
        <div className='w-profile'>
            <div className='fw-bolder d-flex align-items-center fs-5'>
                <div className='me-2'>
                    <span className="d-block">Hi, {account.identity.given_name}</span>
                    <span className="fw-bold text-muted text-hover-info fs-8">{account.identity.empid}</span>
                </div>
                <div className="badge badge-light-info">
                    <KTIcon iconName='down' className='fs-3' />
                </div>
            </div>

        </div>
    )
}
