import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { AccessInvalid, RequiredMsg } from "../../../../_layouts/_core/ErrorMessage";
import FilterUI from "../../components/filters/FilterUI";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";
import { GET_RPT_TEACHINGLOAD } from "../../apis/node-target-data/AcademicApi";
import { AcadTerm, CourseOwner, Department, DepartmentSpec, EmployeeSelect, FiscalYear } from "../../components/forms/SelectBox";
import { CourseLookUp, SectionID, Semester } from "../../components/forms/InputText";
import { MessageError, openModal } from "../../../../_layouts/components/modals/Modals";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import { LoadingSkeleton } from "../../../../_layouts/components/loadbars/LoadingBars";
import TLTableData from "./TLTableData";

export function TeachingLoadNoRate() {
  const context = useOutletContext();
  const widget_67 = context.widgets.find(
    (item) => item.widget_id === 67 && item.is_enabled === 1
  );
  const title = "Teaching Load";

  const [cost_center, setCost_center] = useState(0);
  const [term_id, setTerm_id] = useState(0);
  const [emp_id, setEmp_id] = useState(0);
  const [course, setCourse] = useState("");
  const [course_id, setCourseID] = useState(0);
  const [section_id, setSection_id] = useState("");
  const [clu_id, setClu_id] = useState("");
  const [clu_spec_id, setClu_spec_id] = useState("");
  const [semester, setSemester] = useState("");

  const paramPost = {
    cost_center, term_id, emp_id, course_id, section_id, clu_id, clu_spec_id, semester, offset: 1, limit: 1000
  }

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadData = async (param) => {
    setResults({ loading: true, message: "", data: [] });
    const result = await GET_RPT_TEACHINGLOAD(param);
    setResults(result);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const SubmitFilter = (e) => {
    e.preventDefault();
    const param = RemoveEmptyValue(paramPost);
    if (param) {
      ReloadData(param);
    }
  }

  return widget_67 ? (
    <div className="row">
      <div className="col-lg-3 mb-5">
        <FilterUI title={title} onSubmit={SubmitFilter} isload={results.loading}>
          <CourseOwner data={cost_center} setData={setCost_center} title={"Cost Center"} />
          <AcadTerm data={term_id} setData={setTerm_id} />
          <Department data={clu_id} setData={setClu_id} />
          <DepartmentSpec data={clu_spec_id} setData={setClu_spec_id} />
          <EmployeeSelect data={emp_id} setData={setEmp_id} title={"Lecturer"} />
          <CourseLookUp course={course} setCourse={setCourse} setCourseID={setCourseID} />
          <SectionID data={section_id} setData={setSection_id} />
          <Semester data={semester} setData={setSemester} />
        </FilterUI>
      </div>
      <div className="col-lg-9 mb-5">
        <SGUCard>
          <SGUCard.Header>
            <span className="card-label fw-bolder text-dark">
              Browse {title}
            </span>
            <span className="text-muted mt-1 fw-bold fs-7">
              List of teaching load by filter
            </span>
          </SGUCard.Header>
          <SGUCard.Body>
            {results.loading ? (
              <LoadingSkeleton />
            ) : results.message ? (
              <AlertNotif messages={results.message} />
            ) : (
              <TLTableData results={results} ReloadData={SubmitFilter} />
            )}
          </SGUCard.Body>
        </SGUCard>
      </div>
    </div>
  ) : (
    <AlertNotif messages={AccessInvalid} />
  );
}
