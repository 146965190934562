import { StudentIDFormat } from "../../../../_layouts/_helpers/Global";
import { AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import { SGUCardFlush } from "../../../../_layouts/components/cards/CardUI";
import { LoadingSkeleton, NoRecordFound } from "../../../../_layouts/components/loadbars/LoadingBars";
import { KTIcon } from "../../../../_metronic/helpers";

const TablePCFActivity = ({ activity, totalSKS }) => {
    return (
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
            <thead>
                <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'>
                    <th width="8%">No</th>
                    <th>Code</th>
                    <th>Course Title</th>
                    <th>Credit & Type</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                {activity.data.activity_detail && Object.values(activity.data.activity_detail).length > 0 ? (
                    activity.data.activity_detail.map((v, index) => {
                        totalSKS = totalSKS + ((v.action_type === "asc.course.action.add" && v.course.course_type !== "asc.course.type.optional") ? (v.course) ? v.course.credit : 0 : 0);
                        let courseTypeArr = v.course.course_type.split(".");
                        let courseType = courseTypeArr[courseTypeArr.length - 1];
                        return (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    <span className="fw-bold d-block">{v.course && (v.course.code || "-")}</span>
                                    <span className="text-muted fs-7">{v.section_id || ""}</span>
                                </td>
                                <td>{v.course && (v.course.coursetitle || "-")}</td>
                                <td>
                                    <span className="fw-bold d-block">{v.course && (v.course.credit || "-")} SKS</span>
                                    <span className="text-capitalize text-muted fs-7">{courseType}</span>
                                </td>
                                <td>
                                    {(v.action_type === "asc.course.action.add") ? <span className="badge badge-light-info">{v.action.name}</span> : (
                                        (v.action_type === "asc.course.action.remove") ? <span className="badge badge-light-danger">{v.action.name}</span> : ""
                                    )}
                                </td>
                            </tr>
                        )
                    })
                ) : (
                    <tr>
                        <td colSpan={6}>No record found</td>
                    </tr>
                )}
            </tbody>
            {totalSKS > 0 && (
                <tfoot>
                    <tr>
                        <td colSpan={3} className='fw-bold text-end'>Cumulative Credit</td>
                        <td colSpan={2} className='fw-bold text-start'>{totalSKS} SKS</td>
                    </tr>
                </tfoot>
            )}
        </table>
    )
}

const CardActivityHist = ({ activity, ReloadData }) => {
    return (
        <SGUCardFlush>
            <SGUCardFlush.Body>
                {activity.loading ? <LoadingSkeleton /> :
                    activity.message ? <AlertRetry messages={activity.message} Reload={ReloadData} /> :
                        Object.values(activity.data).length > 0 ? (
                            <div className="detail mt-8">
                                {(activity.data.std_bio && activity.data.std_acad) && (
                                    <div className='mb-8'>
                                        <h1>{activity.data.std_bio.fullname || "-"}</h1>
                                        <span className="text-gray-500 pt-1 fw-semibold fs-6">
                                            {StudentIDFormat(activity.data.std_acad.studentid)}
                                        </span>
                                    </div>
                                )}
                                {activity.data.activity && (
                                    <div>
                                        <div className="mb-5">
                                            <label className="text-muted">Type</label>
                                            <p className="mb-0 fw-bold">
                                                <span>{activity.data.activity.student_activity.name || "-"} Semester {activity.data.activity.semester || "-"}</span>
                                                {activity.data.activity.submit_date_formatted && (
                                                    <div className="d-flex align-items-center">
                                                        <KTIcon iconName='calendar' className='fs-4 me-1' />
                                                        <span>{activity.data.activity.submit_date_formatted}</span>
                                                    </div>
                                                )}
                                            </p>
                                        </div>

                                        <div className="mb-5">
                                            <label className="text-muted">Term</label>
                                            <p className="mb-0 fw-bold">
                                                {activity.data.activity.atp_term.name || "-"}
                                            </p>
                                        </div>

                                        <div className="mb-5">
                                            <label className="text-muted">Counselor</label>
                                            {Object.values(activity.data.activity.counselor).length > 0 ? (
                                                activity.data.activity.counselor.map((v, index) => (
                                                    <p className="mb-0 fw-bold" key={index}>
                                                        {v.emp && (v.emp.fullname || "-")}
                                                    </p>
                                                ))
                                            ) : "-"}
                                        </div>

                                        <div className="mb-5">
                                            <label className="text-muted">Status</label>
                                            <p className="mb-0 fw-bold">
                                                <span>{activity.data.activity.status_activity || "-"}</span>
                                                {activity.data.activity.approve_date_formatted && (
                                                    <div className="d-flex align-items-center">
                                                        <KTIcon iconName='calendar' className='fs-4 me-1' />
                                                        <span>{activity.data.activity.approve_date_formatted || ""}</span>
                                                    </div>
                                                )}

                                            </p>
                                        </div>

                                    </div>
                                )}

                            </div>
                        ) : <NoRecordFound />}
            </SGUCardFlush.Body>
        </SGUCardFlush>
    )
}

const CardActivityLeave = ({activity}) =>{
    return (
        <div className="mt-5">
            <p className="mb-0">Reason:</p>
            <h3>{activity?.note || "-"}</h3>
        </div>
    )
}
export { TablePCFActivity, CardActivityHist, CardActivityLeave }