import React, { useState } from "react";
import { SGUCardFlush } from "../../../../_layouts/components/cards/CardUI";
import { ButtonIcon, ButtonLong, ButtonPrimary, ButtonTersier } from "../../../../_layouts/components/buttons/ButtonUI";
import { SpinnerLoad } from "../../../../_layouts/components/loadbars/LoadingBars";

export default function FilterUI(props) {
  const { children, title, onSubmit, isload } = props;
  const [show, setShow] = useState(true);

  return (
    <SGUCardFlush>
      <SGUCardFlush.Body>
        <form method="post" autoComplete="off" className="mt-8" onSubmit={(e) => onSubmit(e)}>
          <div className="d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <div className="symbol symbol-circle me-5 d-none">
                <div className="symbol-label bg-transparent text-primary border border-secondary border-dashed">
                  <i className="ki-outline ki-filter fs-2x text-primary"></i>
                </div>
              </div>
              <div className="d-flex flex-column">
                <h3>Filter {title}</h3>
                <div className="text-muted fw-semibold">
                  Fill out the form correctly
                </div>
              </div>
            </div>

            <div className="toolbar">
              <ButtonIcon className="btn-active-light-primary" type="button" onClick={() => setShow(!show)}>
                <i className={"ki-outline ki-" + (show ? "down" : "up") + " fs-2x text-primary"}></i>
              </ButtonIcon>
            </div>
          </div>
          {show && (
            <div className="form-filter mt-5">
              {children}

              <div className="text-end mt-5">
                <ButtonLong
                  className="btn-sm btn-primary px-6 fw-bold"
                  type="submit"
                  disabled={isload}
                >
                  {isload ? <SpinnerLoad /> : (
                    <>
                      <i className="bi bi-search"></i>
                      Search
                    </>
                  )}
                </ButtonLong>
              </div>
            </div>
          )}
        </form>
      </SGUCardFlush.Body>
    </SGUCardFlush>
  );
}
