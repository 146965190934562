import React, { useMemo, useState } from "react";
import { HeaderDatatables, PaginationComponent } from "../../../../../../_layouts/components/datatables/MYDatatables";
import { ItemPersonStaff } from "../components/ItemPerson";
import { LinkBtn } from "../../../../../../_layouts/components/buttons/LinkUI";
import { NoRecordFound } from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { Link } from "react-router-dom";

export default function TableDataEmp({ data }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [totalItems, setTotalItems] = useState(0);
  const ITEMS_PER_PAGE = 5;

  const headers = [
    { name: "No", field: "userid", sortable: false },
    { name: "Name", field: "name", sortable: false },
    { name: "Emp ID", field: "userid", sortable: false },
  ];

  const ResultData = useMemo(() => {
    const computedData = data.map((v) => {
      let obj = {};
      obj.name = v.name_related || "";
      obj.email = v.sgumail || "";
      obj.position = v.sgumail && (v.sgumail.includes("lecturer") ? "Lecturer" : "Staff");
      obj.icon = obj.position && (obj.position[0] || "");
      obj.userid = v.id || "";
      obj.status_id = 1;
      obj.detail = v;
      return obj;
    });

    computedData.sort((a, b) => (a.name < b.name ? -1 : 1));

    setTotalItems(computedData.length);

    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [data, currentPage, sorting]);

  return (
    <div className="table-responsive">
      <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
        <HeaderDatatables
          headers={headers}
          onSorting={(field, order) => setSorting({ field, order })}
        />
        <tbody>
          {ResultData.length > 0 ? (
            ResultData.map((v, index) => (
              <tr key={index}>
                <td>{index + 1}</td>
                <td>
                  <Link to={"/employee/" + btoa(v.userid)}>
                    <ItemPersonStaff data={v} />
                  </Link>
                </td>
                <td className="fs-7">
                  <span className="d-block fw-bolder">{v.userid}</span>
                  <span className="text-muted">{v.status_id === 1 ? "Active" : "Not Active"}</span>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5"><NoRecordFound /></td>
            </tr>
          )}
        </tbody>
      </table>

      {ResultData.length > 0 && (
        <div className="footer">
          <span className="fw-bold">Total {totalItems}</span>
          <PaginationComponent
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      )}
    </div>
  );
}
