import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { CREATE_ADDRESS, DELETE_ADDRESS, GET_ADDRESS_BY_REFF, UPDATE_ADDRESS } from '../../../../apis/node-target-data/IdentityApi';
import { LoadingBars } from '../../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../../../_layouts/components/alerts/AlertsUI';
import { ButtonDanger, ButtonIcon, ButtonPrimary } from '../../../../../../_layouts/components/buttons/ButtonUI';
import { KTIcon } from '../../../../../../_metronic/helpers';
import { FormBirthDate, FormFullname, FormGender } from './BioComponent';
import { openModal } from '../../../../../../_layouts/components/modals/Modals';
import { CityLookUp, CountryLookUp } from '../../../../components/forms/SelectBox';
import { RequiredMsg } from '../../../../../../_layouts/_core/ErrorMessage';
import { RemoveEmptyValue } from '../../../../../../_layouts/_helpers/Global';
import { handleKeyDown } from '../../../../components/forms/InputText';
import { useOutletContext } from 'react-router-dom';

export default function FormBiodata({ stdBio }) {
    const context = useOutletContext();
    const widget_26 = context.widgets.find(
        (item) => item.widget_id === 26 && item.is_enabled === 1
    );
    const [myAddress, setMyAddres] = useState({ loading: false, data: [], message: "" });
    const GET_ADDRESS_BY_REFF_LOAD = async () => {
        setMyAddres({ loading: true, data: [], message: "" });
        const param = { reffid: stdBio.reff_address_id };
        const result = await GET_ADDRESS_BY_REFF(param);

        setMyAddres(result);
    };

    useEffect(() => {
        GET_ADDRESS_BY_REFF_LOAD();
    }, [])

    const LabelItem = ({ name, value, FormUpdate }) => {
        const COMPONENT_MAP = {
            FormFullname: FormFullname,
            FormBirthDate: FormBirthDate,
            FormGender: FormGender,
        }
        const SlugComp = COMPONENT_MAP[FormUpdate];

        return (
            <div className="row mb-6">
                <label className='col-lg-4 fw-semibold fs-6 text-muted'>{name}</label>
                <div className="col-lg-8">
                    <span className="fw-bolder fs-6 text-gray-800 fs-6">: {value}</span>
                    {widget_26 && FormUpdate && (
                        <ButtonIcon type='button' className="btn-sm btn-clear ms-2" title={'Change ' + name} onClick={() => openModal({ header: "Form update " + name, message: <SlugComp stdBio={stdBio} />, size: "xl" })} >
                            <KTIcon iconName='notepad-edit' />
                        </ButtonIcon>
                    )}
                </div>
            </div>
        )
    }


    return (
        <div className='biodata'>
            <div className="row">
                <div className="col-12 col-lg-5">
                    <h4 className="fw-bold mb-5">Personal Biodata</h4>
                    <div className="personal-bio">
                        <LabelItem name={"Fullname"} value={stdBio.fullname || "-"} FormUpdate={"FormFullname"} />
                        <LabelItem name={"Birthdate"} value={(stdBio.birthplace && stdBio.birthdate) && (stdBio.birthplace + ", " + moment(stdBio.birthdate).format("D MMM YYYY") || "-")} FormUpdate={"FormBirthDate"} />
                        <LabelItem name={"Gender"} value={stdBio.gender && ((stdBio.gender === 'p') ? "Male" : "Female")} FormUpdate={"FormGender"} />
                        <LabelItem name={"Religion"} value={stdBio.religion && (stdBio.religion.name || "-")} />
                        <LabelItem name={"Cellular"} value={stdBio.cellular || "-"} />
                        <LabelItem name={"Citizenship"} value={stdBio.citizenship && (stdBio.citizenship.citizenship || "-")} />
                    </div>
                </div>
                <div className="col-12 col-lg-7">
                    <div className="d-flex align-items-center">
                        <h4 className="fw-bold mb-0">Contact Person</h4>
                        {widget_26 && (
                            <ButtonIcon type="button" className="btn-sm btn-clear" title="Add New Contact" onClick={() => openModal({ message: <FormAddress data={{ reff_id: stdBio.reff_address_id }} /> })} >
                                <KTIcon iconName='plus-square' className='fs-2 text-primary' />
                            </ButtonIcon>
                        )}
                    </div>
                    <div className="list-contact d-flex align-items-center">
                        {myAddress.loading ? <LoadingBars /> :
                            myAddress.message ? <AlertNotif color={"danger"} messages={myAddress.message} /> : (
                                Object.values(myAddress.data).length > 0 && (
                                    myAddress.data.map((v, index) => (
                                        <div className="contact-item border rounded p-3 me-3 w-200px" key={index}>
                                            <div className="bg-light text-center rounded mb-2">
                                                <span className="fw-bold text-dark fs-6">
                                                    {v.is_emergency === 1 ? "Emergency" : "Main"} Contact
                                                </span>
                                            </div>
                                            <RowContact name={"Cellular"} value={v.cellular1} />
                                            {v.cellular2 && <RowContact name={"Cellular 2"} value={v.cellular2} />}
                                            <RowContact name={"Email"} value={v.email} />
                                            <RowContact name={"Address"} value={v.address || "-"} />
                                            {v.postalcode && <RowContact name={"Postalcode"} value={v.postalcode} />}
                                            {v.city && <RowContact name={"City"} value={v.city.name || ""} />}
                                            {v.country && <RowContact name={"Country"} value={v.country.name || ""} />}
                                            {widget_26 && (
                                                <div className="text-end">
                                                    <div className="btn-group">
                                                        <ButtonIcon className="btn-sm btn-clear" title="Update" onClick={() => openModal({ message: <FormAddress data={v} /> })}>
                                                            <KTIcon iconName='notepad-edit' className='fs-2x' />
                                                        </ButtonIcon>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    ))
                                ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

const RowContact = ({ name, value }) => {
    return (
        <div className="form-group mb-5">
            <label className="fw-bold">{name}</label>
            <p className="mb-0">{value}</p>
        </div>
    )
}

const FormAddress = ({ data }) => {
    const [city, setCity] = useState(data.city_id || 0)
    const [country, setCountry] = useState(data.country_id || 0)
    const paramObj = {
        reff_id: data.reff_id,
        cellular1: data.cellular1 || "",
        cellular2: data.cellular2 || "",
        email: data.email || "",
        address: data.address || "",
        postalcode: data.postalcode || ""
    }
    const [postContact, setPostContact] = useState(paramObj);
    const [results, setResults] = useState({ loading: false, message: "", data: [] });
    const SubmitData = async (e) => {
        e.preventDefault();
        if (postContact.cellular1 && postContact.email && postContact.address && postContact.postalcode && city && country) {
            setResults({ loading: true, message: "", data: [] });

            const param = RemoveEmptyValue(postContact);
            param.city_id = city;
            param.country_id = country;

            let result;
            if (data.id) {
                param.id = data.id;
                result = await UPDATE_ADDRESS(param);
            } else {
                result = await CREATE_ADDRESS(param);
            }

            if (result.data && Object.values(result.data).length > 0) {
                setResults({ loading: false, message: "Successfully save. Please reload the page.", data: result.data });
            } else {
                const msg = results.message.includes("00000");
                if (msg) {
                    setResults({ loading: false, message: "Failed to save, try again later", data: [] });
                } else {
                    setResults(result);
                }
            }

        } else {
            setResults({ loading: false, message: RequiredMsg, data: [] });
        }
    }

    const DeleteAddress = async (address_id) => {
        if (confirm("Are you sure want to remove?") === true) {
            setResults({ loading: true, message: "", data: [] });
            const param = { address_id: address_id };
            const result = await DELETE_ADDRESS(param);
            if (result.data && Object.values(result.data).length > 0) {
                setResults({ loading: false, message: "Successfully removed. Please reload the page.", data: result.data });
            } else {
                const msg = results.message.includes("00000");
                if (msg) {
                    setResults({ loading: false, message: "Failed to remove, try again later", data: [] });
                } else {
                    setResults({ loading: false, message: "Successfully removed. Please reload the page.", data: result.data });
                }
            }

        }
    }

    return (
        <form method='post' autoComplete='off' onSubmit={(e) => SubmitData(e)}>
            <div className="row">
                <div className="col-12 col-lg-6">
                    <div className="form-group mb-8">
                        <label className="fw-bold required">Cellular 1</label>
                        <input type="text" name="cellular1" maxLength={15} required className="form-control form-control-sm" defaultValue={postContact.cellular1} onChange={(e) => setPostContact({ ...postContact, cellular1: e.target.value })} onKeyDown={handleKeyDown} />
                    </div>
                </div>
                <div className="col-12 col-lg-6">
                    <div className="form-group mb-8">
                        <label className="fw-bold">Cellular 2</label>
                        <input type="text" name="cellular2" maxLength={15} className="form-control form-control-sm" defaultValue={postContact.cellular2} onChange={(e) => setPostContact({ ...postContact, cellular2: e.target.value })} onKeyDown={handleKeyDown} />
                    </div>
                </div>
            </div>

            <div className="form-group mb-8">
                <label className="fw-bold required">Email</label>
                <input type="text" name="email" required className="form-control form-control-sm" defaultValue={postContact.email} onChange={(e) => setPostContact({ ...postContact, email: e.target.value })} />
            </div>

            <div className="form-group mb-8">
                <label className="fw-bold required">Address</label>
                <input type="text" name="address" required className="form-control form-control-sm" defaultValue={postContact.address} onChange={(e) => setPostContact({ ...postContact, address: e.target.value })} placeholder="Street name, City" />
                <small className="text-muted">Please use latin alphabet</small>
            </div>

            <div className="row">
                <div className="col-12 col-lg-4">
                    <div className="form-group mb-8">
                        <label className="fw-bold required">Postal Code</label>
                        <input type="text" name="postal_code" maxLength={5} required className="form-control form-control-sm" defaultValue={postContact.postalcode} onChange={(e) => setPostContact({ ...postContact, postalcode: e.target.value })} onKeyDown={handleKeyDown} />
                    </div>
                </div>

                <div className="col-12 col-lg-4">
                    <CityLookUp data={data.city && (data.city.name || postContact.city_id)} setData={setCity} required />
                </div>

                <div className="col-12 col-lg-4">
                    <CountryLookUp data={data.country && (data.country.name || "")} setData={setCountry} required />
                </div>
                <div className="col-12">
                    <div className="form-check form-switch mb-5">
                        <input className="form-check-input" disabled={true} type="checkbox" role="switch" id="flexCustome" defaultValue={postContact.is_emergency} defaultChecked={postContact.is_emergency ? true : false} onChange={(e) => setPostContact({ ...postContact, is_emergency: e.target.value })} />
                        <label className="form-check-label fs-6" for="flexCustome"><span className="fw-bolder">This is emergency contact</span></label>
                    </div>
                </div>
            </div>

            <div className="my-5 d-flex justify-content-between align-items-center">
                <ButtonDanger type="button" className="btn-sm fw-bold btn-light-danger" disabled={results.loading} onClick={() => DeleteAddress(data.id)} >
                    <KTIcon iconName='trash' className='fs-2 me-1 text-hover-white' />
                    {results.loading ? "Processing..." : "Remove"}
                </ButtonDanger>
                <ButtonPrimary type="submit" className="btn-sm fw-bold" disabled={results.loading} >
                    {results.loading ? "Processing..." : "Save Changes"}
                </ButtonPrimary>

            </div>

            {results.message && <AlertNotif messages={results.message} color={(Object.values(results.data).length > 0 && results.message) ? "primary" : "danger"} />}
        </form>
    )
}