import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { UPDATE_STUDENT_DATA } from "../../../../apis/node-target-data/AcademicApi";
import { ButtonPrimary, ButtonTersier } from "../../../../../../_layouts/components/buttons/ButtonUI";
import { AlertNotif } from "../../../../../../_layouts/components/alerts/AlertsUI";
import moment from "moment";

const LabelItem = ({ name, value }) => {
    return (
        <div className="row mb-6">
            <label className='col-lg-5 fw-semibold fs-6 text-muted'>{name}</label>
            <div className="col-lg-7">
                <span className="fw-bolder fs-6 text-gray-800 fs-6">: {value}</span>
            </div>
        </div>
    )
}

const FormFullname = ({ stdBio }) => {
    const paramFullname = { studentdata_id: stdBio.id, firstname: stdBio.firstname, middlename: (stdBio.middlename ? stdBio.middlename : ""), lastname: (stdBio.lastname ? stdBio.lastname : "") };
    const [fullname, setFullname] = useState(paramFullname);

    const [studentUpdate, setStudentUpdate] = useState({
        loading: false,
        message: "",
        data: [],
    });
    const SubmitData = async (e) => {
        e.preventDefault();
        if (fullname.firstname && fullname.lastname) {
            setStudentUpdate({ loading: true, data: [], message: "" });
            const result = await UPDATE_STUDENT_DATA(fullname);

            if (result.data && Object.values(result.data).length > 0) {
                setStudentUpdate({ loading: false, data: result.data, message: "Data updated successfully. Please reload this page." });
            } else {
                setStudentUpdate(result);
            }
        } else {
            setStudentUpdate({ loading: false, data: [], message: "Please fill up the form completely" });
        }
    };

    return (
        <form method="post" id="frm-fullname" autoComplete="off" onSubmit={(e) => SubmitData(e)}>
            <div className="row mb-3">
                <div className="col-4">
                    <div className="mb-3">
                        <label className="required fw-bold">Firstname</label>
                        <input type="text" className="form-control" name="firstname" defaultValue={fullname.firstname} onChange={(e) => setFullname({ ...fullname, firstname: e.target.value })} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="mb-3">
                        <label className="fw-bold">Middlename</label>
                        <input type="text" className="form-control" name="middlename" defaultValue={fullname.middlename} onChange={(e) => setFullname({ ...fullname, middlename: e.target.value })} />
                    </div>
                </div>
                <div className="col-4">
                    <div className="mb-3">
                        <label className="required fw-bold">Lastname</label>
                        <input type="text" className="form-control" name="lastname" defaultValue={fullname.lastname} onChange={(e) => setFullname({ ...fullname, lastname: e.target.value })} />
                    </div>
                </div>
            </div>

            {studentUpdate.message ? <AlertNotif messages={studentUpdate.message} color={(Object.keys(studentUpdate.data).length > 0) ? "info" : "danger"} /> : ""}

            <div className="text-end mt-8">
                <div className="btn-group">
                    <ButtonTersier className="btn-sm fw-bold text-uppercase px-9 py-4" type="reset" onClick={() => openModal({ open: false })}>
                        Close
                    </ButtonTersier>
                    <ButtonPrimary disabled={studentUpdate.loading} className="btn-sm fw-bold text-uppercase px-9 py-4" type="submit" >
                        {studentUpdate.loading ? "Processing..." : "Save"}
                    </ButtonPrimary>
                </div>
            </div>
        </form>
    )
}

const FormBirthDate = ({ stdBio }) => {
    const paramBirthdate = {
        studentdata_id: stdBio.id,
        birthplace: stdBio.birthplace ? stdBio.birthplace : "",
        birthdate: stdBio.birthdate ? moment(stdBio.birthdate).toDate() : moment().toDate()
    };
    const [birthdate, setBirthDate] = useState(paramBirthdate);
    const [studentUpdate, setStudentUpdate] = useState({ loading: false, data: [], message: "" });

    const SubmitData = async (e) => {
        e.preventDefault();
        if (birthdate.birthdate && birthdate.birthplace) {
            setStudentUpdate({ loading: true, data: [], message: "" });
            const result = await UPDATE_STUDENT_DATA(birthdate);

            if (result.data && Object.values(result.data).length > 0) {
                setStudentUpdate({ loading: false, data: result.data, message: "Data updated successfully. Please reload this page." });
            } else {
                const msg = result.message.includes("00000");
                if (msg) {
                    setStudentUpdate({ loading: false, data: [], message: "Submission failed due to unauthorized data ownership. Please try again later." });
                } else {
                    setStudentUpdate(result);
                }
            }
        } else {
            setStudentUpdate({ loading: false, data: [], message: "Please fill up the form completely" });
        }
    };

    return (
        <form method="post" autoComplete="off" id="form-brithdate" onSubmit={(e) => SubmitData(e)}>
            <div className="row mb-3">
                <div className="col-auto">
                    <div className="mb-3">
                        <label className="fw-bold required">City</label>
                        <input type="text" name="city" className="form-control required text-capitalize" defaultValue={paramBirthdate.birthplace} onChange={(e) => setBirthDate({ ...birthdate, birthplace: e.target.value })} />
                    </div>
                </div>
                <div className="col-auto">
                    <div className="mb-3">
                        <label className="fw-bold required">Birth date</label>
                        <div>
                            <DatePicker className="form-control" dateFormat={"yyyy-MM-dd"} isClearable
                                placeholderText='Enter date' selected={birthdate.birthdate} onChange={(date) => setBirthDate({ ...birthdate, birthdate: date })}
                                onKeyDown={(e) => {
                                    e.preventDefault();
                                }} />
                        </div>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="pt-5">
                        <button disabled={studentUpdate.loading} className="btn btn-primary fw-bolder text-uppercase px-9 py-4" type="submit" >
                            {studentUpdate.loading ? "Processing..." : "Save"}
                        </button>
                    </div>
                </div>
            </div>

            {studentUpdate.message ? <AlertNotif messages={studentUpdate.message} color={(Object.keys(studentUpdate.data).length > 0) ? "info" : "danger"} /> : ""}

        </form>
    )
}

const FormGender = ({ stdBio }) => {
    const [gender, setGender] = useState({ studentdata_id: stdBio.id, gender: stdBio.gender });
    const [studentUpdate, setStudentUpdate] = useState({ loading: false, data: [], message: "" });

    const SubmitData = async (e) => {
        e.preventDefault();
        if (gender.gender) {
            setStudentUpdate({ loading: true, data: [], message: "" });
            const result = await UPDATE_STUDENT_DATA(gender);

            if (result.data && Object.values(result.data).length > 0) {
                setStudentUpdate({ loading: false, data: result.data, message: "Data updated successfully. Please reload this page." });
            } else {
                const msg = result.message.includes("00000");
                if (msg) {
                    setStudentUpdate({ loading: false, data: [], message: "Submission failed due to unauthorized data ownership. Please try again later." });
                } else {
                    setStudentUpdate(result);
                }
            }
        } else {
            setStudentUpdate({ loading: false, data: [], message: "Please fill up the form completely" });
        }
    };

    return (
        <form method="post" autoComplete="off" id="form-brithdate" onSubmit={(e) => SubmitData(e)}>
            <div className="w-50 m-auto">
                <div className="d-flex flex-column align-items-center">
                    <div className="mb-3 me-5">
                        <label className="fw-bold required">Gender</label>
                        <div className="mt-5">
                            <label>
                                <input type="radio" name="gender" value={"w"} checked={gender.gender === "w"} defa onChange={(e) => setGender({ ...gender, gender: e.target.value })} /> Female
                            </label>
                            <label className="ms-8">
                                <input type="radio" name="gender" value={"p"} checked={gender.gender === "p"} onChange={(e) => setGender({ ...gender, gender: e.target.value })} /> Male
                            </label>
                        </div>
                    </div>

                    <div className="pt-5">
                        <div className="text-center">
                            <ButtonPrimary disabled={studentUpdate.loading} className="btn-sm fw-bold text-uppercase px-9 py-4" type="submit" >
                                {studentUpdate.loading ? "Processing..." : "Save"}
                            </ButtonPrimary>
                        </div>
                    </div>
                </div>

                {studentUpdate.message ? <AlertNotif messages={studentUpdate.message} color={(Object.keys(studentUpdate.data).length > 0) ? "info" : "danger"} /> : ""}
            </div>
        </form>
    )
}



export { LabelItem, FormFullname, FormBirthDate, FormGender }