import React from 'react'
import { SGUCard } from '../../../../../_layouts/components/cards/CardUI'

export default function CourseVersion({data}) {
  const course_version = data.ver_hist;

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bold text-gray-800">
          Course Version
        </span>
      </SGUCard.Header>
      <SGUCard.Body className={"px-0"}>
        <div className="table-responsive">
          <table className="table table-row-bordered align-middle gy-6">
            <thead className="border-bottom border-gray-200 fs-6 fw-bold bg-light">
              <tr>
                <th width="8%" className="ps-3">No</th>
                <th>Code</th>
                <th>Title</th>
                <th>Credit</th>
                <th>ECTS</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {Object.values(course_version).length > 0 ? (
                course_version.map((v, index) => (
                  <tr key={index}>
                    <td className="ps-3">{index+1}</td>
                    <td>{v.code || ""}</td>
                    <td>{v.coursetitle}</td>
                    <td>{v.credit}</td>
                    <td>{v.ects}</td>
                    <td>{v.status}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6}>
                    <NoRecordFound />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </SGUCard.Body>
    </SGUCard>
  );
}
