import React, { useState } from 'react'
import { ButtonPrimary } from '../../../../_layouts/components/buttons/ButtonUI'

export default function FormUpdateMenu({data, ReloadData}) {
    const objparam = {
        route_id:data.route_id,
        menu_id:data.menu_id,
        menu_is_active:data.menu_is_active || 0,
        menu_name:data.menu_name || "",
        menu_position:data.menu_position || 0,
        menu_type:data.menu_type || "",
        menu_uri:data.menu_uri || "",
        slug:data.slug || "",
        slug_uri:data.slug_uri || ""
    }
    const [post, setPost] = useState(objparam)
    
  return (
    <form method='post' autoComplete='off'>
        <p className="fw-bolder fs-4 mb-1">Menu Setup</p>
        <div className="row mb-8">
            <div className="col-6">
                <div className="form-group mb-5">
                    <label className="required fw-bolder">Menu Name:</label>
                    <input type="text" className="form-control form-control-sm" defaultValue={post.menu_name} onChange={(e)=>setPost({...post, menu_name:e.target.value})} />
                </div>
            </div>
            <div className="col-6">
                <div className="form-group">
                    <label className="required fw-bolder">URI:</label>
                    <input type="text" className="form-control form-control-sm" defaultValue={post.menu_uri} onChange={(e)=>setPost({...post, menu_uri:e.target.value})} />
                </div>
            </div>
            <div className="col-3">
                <div className="form-group">
                    <label className="required fw-bolder">Position:</label>
                    <input type="number" className="form-control form-control-sm" defaultValue={post.menu_position} onChange={(e)=>setPost({...post, menu_position:parseInt(e.target.value)})} />
                </div>
            </div>
            <div className="col-3"></div>
            <div className="col-6">
                <div className="form-group">
                    <label className="required fw-bolder">Status</label>
                    <select name="is_active" className="form-select form-select-sm" defaultValue={post.menu_is_active} onChange={(e)=>setPost({...post, menu_is_active:parseInt(e.target.value)})}>
                        <option value="0">Not Active</option>
                        <option value="1">Active</option>
                    </select>
                </div>
            </div>
        </div>

        <p className="fw-bolder fs-4 mb-1">Slug Setup</p>
        <div className="row mb-8">
            <div className="col-6">
                <div className="form-group">
                    <label className="required fw-bolder">Element:</label>
                    <input type="text" className="form-control form-control-sm" defaultValue={post.slug} onChange={(e)=>setPost({...post, slug:e.target.value})} />
                </div>
            </div>
            <div className="col-6">
                <div className="form-group">
                    <label className="required fw-bolder">Path:</label>
                    <input type="text" className="form-control form-control-sm" defaultValue={post.slug_uri} onChange={(e)=>setPost({...post, slug_uri:e.target.value})} />
                </div>
            </div>
        </div>
        
        <div className="form-group mb-8">
            <label className="required fw-bolder">Environment</label>
            <select name="is_active" className="form-select form-select-sm" defaultValue={post.menu_type} onChange={(e)=>setPost({...post, menu_type:parseInt(e.target.value)})}>
                <option value="0">Choose One</option>
                <option value="1">Private</option>
                <option value="2">Staging</option>
                <option value="3">Production</option>
            </select>
        </div>

        <div className="text-end">
            <ButtonPrimary type="submit" disabled={true}>
                Save Changes
            </ButtonPrimary>
        </div>
    </form>
  )
}
