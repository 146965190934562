import React, { useEffect, useMemo, useState } from "react";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import { ButtonIcon, ButtonPrimary } from "../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../_metronic/helpers";
import { LoadingSkeleton, NoRecordFound } from "../../../../_layouts/components/loadbars/LoadingBars";
import TFormUploadFile from "./TFormUploadFile";
import { DELETE_REQUEST_DOC, GET_REQUEST_DOC } from "../../apis/module/JupiterRequest";
import { RSAencryptData } from "../../../../_layouts/_helpers/RSAEnc";
import { useProvider } from "../../../../_layouts/UMSLayoutProvider";
import { AlertNotif, AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import moment from "moment";
import { ConfirmRemove, MessageError, MessageSuccess, openModal } from "../../../../_layouts/components/modals/Modals";
import TDetailFile from "./TDetailFile";

export default function TDocumentFileUpload() {
  const { account } = useProvider();
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [results, setResults] = useState({loading:false, data:[], message:""});

  const mydocumentdata = {
    owner: account.identity.email
  };
  const objData = JSON.stringify(mydocumentdata);
  const rsaEnc = RSAencryptData(objData);

  const ReloadDocFile = async () =>{
    setResults({loading:true, data:[], message:""});
    const formData = new FormData();
    formData.append('data', rsaEnc);
    const result = await GET_REQUEST_DOC(formData);
    if(result.data && Object.values(result.data).length > 0){
      const my_files = result.data.filter(item => item.status === "strg.file.status.uploaded");
      setResults({loading:false, data:my_files, message:""});
    }else{
      setResults(result);
    }
  }

  useEffect(()=>{
    ReloadDocFile();
  },[])

  const HandlerDeleteFile = async (data) =>{
    const param = {
      file_id:data.file_id,
      user:account.identity.email
    }
    const objData = JSON.stringify(param);
    const rsaEnc = RSAencryptData(objData);
  
    const formData = new FormData();
    formData.append('data', rsaEnc);
    const result = await DELETE_REQUEST_DOC(formData);
    if(result.data && Object.values(result.data).length > 0){
      ReloadDocFile();
      openModal({message:<MessageSuccess message={"Successfully removed"} />});
    }else{
      openModal({message:<MessageError message={result.message} />});
    }
  }

  const [totalItems, setTotalItems] = useState(0);
  const ResultData = useMemo(()=>{
    let computedData = [];
    if(Object.values(results.data).length > 0){
      computedData = results.data.map(v => {
        let obj = v;
        const filetypeArr = v.doc_type.split(".");
        const filetype = filetypeArr[Object.values(filetypeArr).length - 1];
        obj.file_type = filetype;
        return obj;
      })
    }
    computedData.sort((a, b) => (a.id < b.id ? 1 : -1));

    setTotalItems(computedData.length);


    return computedData;
  },[results.data])

  return showUploadForm ? <TFormUploadFile setShowUploadForm={setShowUploadForm} /> : (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bolder text-dark">Document File Test</span>
        <span className="text-muted mt-1 fw-bold fs-7">
          total {totalItems} file
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        <ButtonPrimary type="button" className="btn-sm" onClick={()=>setShowUploadForm(!showUploadForm)}>
            <i className="ki-outline ki-folder-up fs-2"></i>
            Upload File
        </ButtonPrimary>
        <ButtonIcon type="button" className="btn-sm btn-light ms-2" onClick={()=>ReloadDocFile()} title="Refresh">
          <i className="bi bi-arrow-clockwise"></i>
        </ButtonIcon>
      </SGUCard.Toolbar>
      <SGUCard.Body>
        
        <div className="table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
                <thead>
                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
                        <th>Doc File</th>
                        <th>Last Modify</th>
                        <th width="15%">Action</th>
                    </tr>
                </thead>
                <tbody>
                  {results.loading ? (
                    <tr>
                      <td colSpan={6}><LoadingSkeleton /></td>
                    </tr>
                  ): results.message ? (
                    <tr>
                      <td colSpan={6}><AlertRetry Reload={ReloadDocFile} messages={results.message} /></td>
                    </tr>
                  ): ResultData.length > 0 ? (
                    ResultData.map((v,index)=>(
                      <tr key={index}>
                        <td className="fs-7">
                          <span className="d-block fw-bold text-capitalize">{v.file_type}</span>
                          <span className="text-muted">{v.size} KB</span>
                        </td>
                        <td className="fs-7">
                          <span className="d-block fw-bold">{v.createdby}</span>
                          <span className="text-muted">{moment(v.created).format("DD MMM YYYY, hh:mm A")}</span>
                        </td>
                        <td>
                          <div className="btn-group">
                            {v.public_url && (
                            <ButtonIcon type="button" className="btn-sm" title="View" onClick={()=>openModal({message:<TDetailFile data={v} />, size:"lg"})}>
                              <KTIcon iconName="picture" className="fs-2 text-hover-primary" />
                            </ButtonIcon>
                            )}
                            <ButtonIcon type="button" className="btn-sm" title="Remove" onClick={()=>openModal({message:<ConfirmRemove handler={()=>HandlerDeleteFile(v)} message={"Are you sure want to remove?"} />}) }>
                              <KTIcon iconName="trash" className="fs-2 text-hover-danger" />
                            </ButtonIcon>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={6}>
                          <NoRecordFound />
                      </td>
                  </tr>
                  )}
                    
                </tbody>
            </table>
        </div>
      </SGUCard.Body>
    </SGUCard>
  );
}
