import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { debounce } from 'lodash';
import { SGUCardFlush } from '../../../../../_layouts/components/cards/CardUI'
import { ButtonIcon, ButtonPrimary } from '../../../../../_layouts/components/buttons/ButtonUI'
import { KTIcon } from '../../../../../_metronic/helpers'
import { AlertNotif, AlertRetry } from '../../../../../_layouts/components/alerts/AlertsUI';
import { AccessInvalid } from '../../../../../_layouts/_core/ErrorMessage';
import { useOutletContext } from 'react-router-dom';
import { InputSearch } from '../../components/InputComp';
import { GET_LOOK_UP_COUNTRY } from '../../../apis/node-target-data/IdentityApi';
import { LoadingSkeleton, NoRecordFound } from '../../../../../_layouts/components/loadbars/LoadingBars';
import { PaginationComponent } from '../../../../../_layouts/components/datatables/MYDatatables';
import { openModal } from '../../../../../_layouts/components/modals/Modals';
import Form from './Form';

export default function MCountry({ country_id, setCountry_id, setProvince_id, setCity_id }) {
    const context = useOutletContext();
    const widget_97 = context.widgets.find(
        (item) => item.widget_id === 97 && item.is_enabled === 1
    );
    const widget_102 = context.widgets.find(
        (item) => item.widget_id === 102 && item.is_enabled === 1
    );

    const [search, setSearch] = useState("");

    const [results, setResults] = useState({
        loading: false,
        data: [],
        message: "",
    });
    const ReloadData = async (param) => {
        setResults({ loading: true, message: "", data: [] });
        const result = await GET_LOOK_UP_COUNTRY(param);
        setResults(result);
        setProvince_id(0);
        setCity_id(0);
        setCountry_id(0);
    };

    const FindDataDebounced = useCallback(debounce((param) => {
        ReloadData(param);
    }, 500), []);

    const HandlerSearch = (value) => {
        if (value.length >= 3) {
            setSearch(value);
            const param = { search: encodeURIComponent(value) }
            FindDataDebounced(param)
        } else if (value.length === 0) {
            const param = { "": "" }
            setCountry_id(0);
            FindDataDebounced(param)
        }
    }

    useEffect(() => {
        ReloadData({ "": "" });
    }, []);

    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 10;

    const ResultData = useMemo(() => {
        let computedData = results.data;

        if (Object.values(results.data).length > 0) {
            computedData.sort((a, b) => (a.name > b.name ? 1 : -1));
        }

        setTotalItems(computedData.length);

        if (computedData.length > 0) {
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        } else {
            return [];
        }
    }, [results.data, currentPage]);

    return widget_97 ? (
        <SGUCardFlush>
            <SGUCardFlush.Header>
                <span className="card-label fw-bolder text-dark">
                    Master Country
                </span>
                <span className="text-muted mt-1 fw-bold fs-7">
                    total: {totalItems} data
                </span>
            </SGUCardFlush.Header>
            <SGUCardFlush.Toolbar>
                {widget_102 && (
                    <ButtonPrimary className="btn-sm fw-bold" onClick={() => openModal({ message: <Form ReloadData={ReloadData} /> })}>
                        <KTIcon iconName='plus' />
                        Add New
                    </ButtonPrimary>
                )}
            </SGUCardFlush.Toolbar>
            <SGUCardFlush.Body>
                <div className="d-flex align-items-center">
                    <div className="w-100">
                        <InputSearch defaultValue={search} onChange={(e) => HandlerSearch(e.target.value)} results={results} />
                    </div>
                    <ButtonIcon type="button" className="btn-sm btn-light ms-3" title="Refresh" onClick={()=>{ReloadData({ "": "" })}}>
                        <i className="bi bi-arrow-repeat fs-2 text-primary"></i>
                    </ButtonIcon>
                </div>

                <div className="table-responsive">
                    <table className="table table-row-dashed align-start fs-6 gy-4 my-0 pb-3 dataTable no-footer align-start">
                        <tbody>
                            {results.loading ? (
                                <tr>
                                    <td><LoadingSkeleton /></td>
                                </tr>
                            ) :
                                results.message ? (
                                    <tr>
                                        <td><AlertRetry messages={results.message} Reload={ReloadData} /></td>
                                    </tr>
                                ) : (
                                    ResultData.length > 0 ? (
                                        ResultData.map((v, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <div className={"d-flex flex-stack " + ((country_id === v.id) ? "bg-light-info py-3 pe-3 rounded":"")}>
                                                        <div className="symbol symbol-40px me-4">
                                                            <div className="symbol-label fs-2 fw-semibold bg-danger text-inverse-danger">{v.code}</div>
                                                        </div>
                                                        <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                            <div className="flex-grow-1 me-2">
                                                                <span className="text-gray-800 text-hover-primary fs-6 fw-bold">{v.name}</span>
                                                                {v.citizenship && (
                                                                    <span className="text-muted fw-semibold d-block fs-7">
                                                                        Citizenship: <span className="fw-bold ms-1">{v.citizenship}</span>
                                                                    </span>
                                                                )}
                                                            </div>
                                                            {widget_102 && (
                                                            <ButtonIcon className="btn-sm text-hover-primary w-30px h-30px ms-1" type="button" title="Update" onClick={()=>openModal({message:<Form ReloadData={ReloadData} data={v} />})}>
                                                                <KTIcon iconName='pencil' className='fs-2' />
                                                            </ButtonIcon>
                                                            )}
                                                            <ButtonIcon className="btn-sm btn-bg-light btn-active-color-primary w-30px h-30px ms-1" type="button" title="View" onClick={()=>setCountry_id(v.id)}>
                                                                <KTIcon iconName='arrow-right' className='fs-2' />
                                                            </ButtonIcon>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td><NoRecordFound /></td>
                                        </tr>
                                    )
                                )}
                        </tbody>
                    </table>
                </div>

                {!results.loading && (
                    <PaginationComponent
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                )}
            </SGUCardFlush.Body>
        </SGUCardFlush>
    ) : <AlertNotif messages={AccessInvalid} />
}
