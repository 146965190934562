import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ButtonPrimary, ButtonTersier } from "../../../../_layouts/components/buttons/ButtonUI";

const states = {
  setState: null,
  changeState(data) {
    if (!this.setState) return;
    this.setState((prevData) => {
      return {
        ...prevData,
        ...data,
      };
    });
  },
};

const handleClose = () => {
  states.changeState({
    open: false,
  });
};

const Modals = () => {
  const [data, setData] = useState({
    open: false,
    header: "ini header default",
    message: "ini message default",
    size: "md",
    footer: "",
    onClose: handleClose,
  });

  states.setState = setData;

  return (
    <Modal show={data.open} onHide={data.onClose} size={data.size}>
      {data.header ? (
        <Modal.Header closeButton>
          <h3 className="modal-title">{data.header}</h3>
        </Modal.Header>
      ) : (
        ""
      )}
      <Modal.Body>
        {!data.header ? (
          <div className="">
            <button onClick={data.onClose} className="btn btn-sm btn-light btn-icon rounded-circle position-absolute end-0 top-0 fw-bolder m-2 text-hover-danger">
              <i className="bi bi-x-lg"></i>
            </button>
            {data.message}
          </div>
        ) : (data.message)}
      </Modal.Body>
      {data.footer ? (
        <Modal.Footer>
          <button onClick={data.onClose} className="btn btn-secondary px-10">
            No
          </button>
          {data.footer}
        </Modal.Footer>
      ) : (
        ""
      )}
    </Modal>
  );
};

const openModals = ({
  open = true,
  message,
  header,
  size,
  footer,
  onClose = () => {},
}) => {
  states.changeState({
    message,
    header,
    size,
    open,
    footer,
    onClose: () => {
      onClose();
      handleClose();
    },
  });
};

const MessageError = ({ title, message, button }) => {
  return (
    <div className="text-center py-8">
      <div className="icon mb-5">
        <i
          className="bi bi-x-circle text-danger"
          style={{ fontSize: "6em" }}
        ></i>
      </div>
      {title && <h1 className="text-danger">{title}</h1>}
      <p className="mb-10">{message || "There is some error on server"}</p>
      {button ? button : 
            <ButtonPrimary type="button" className="btn-lg fw-bolder" onClick={() => openModals({ open: false })}>Ok, got it!</ButtonPrimary>
      }
    </div>
  );
};

const MessageSuccess = ({ title, message }) => {
  return (
    <div className="text-center py-8">
      <div className="icon mb-5">
        <i
          className="bi bi-check-circle text-success"
          style={{ fontSize: "6em" }}
        ></i>
      </div>
      {title && <h1 className="text-dark">{title}</h1>}
      <p className="mb-10">{message}</p>
      <ButtonPrimary type="button" className="btn-lg fw-bolder" onClick={() => openModals({ open: false })}>Ok, got it!</ButtonPrimary>
    </div>
  );
};

const MessageSuccessConfirmLeave = ({ title, message }) => {
  return (
    <div className="text-center py-8">
      <div className="icon mb-5">
        <i
          className="bi bi-check-circle text-success"
          style={{ fontSize: "6em" }}
        ></i>
      </div>
      {title && <h1 className="text-dark">{title}</h1>}
      <p className="mb-10">{message}</p>
      <div className="text-center">
          <ButtonTersier className="btn-lg fw-bolder" type="button" onClick={()=>openModals({open:false})}>
            No
          </ButtonTersier>
          <ButtonPrimary type="button" className="btn-lg fw-bolder" onClick={() => window.close()}>
            Yes Please
          </ButtonPrimary>
      </div>
      
    </div>
  );
};

const MessageErrorConnection = ({ title, message }) => {
  const navigate = useNavigate()
  return (
    <div className="text-center py-8">
      <div className="icon mb-5">
        <i
          className="bi bi-exclamation-triangle text-danger"
          style={{ fontSize: "6em" }}
        ></i>
      </div>
      {title && <h1 className="text-danger">{title}</h1>}
      <p className="mb-10">{message}</p>
      <ButtonPrimary type="button" className="btn-lg fw-bolder" onClick={() => navigate("logout")}>Re-Login again</ButtonPrimary>
    </div>
  );
};


const ConfirmLeave = ({ message, url }) => {
  const navigate = useNavigate();
  const HandlerLeave = () =>{
    openModals({ open: false });
    navigate(url);
  }
  return (
    <div className="text-center py-8">
      <p className="mb-10 fs-3">{message}</p>
      <div className="">
        <ButtonTersier type="button" className="me-5" onClick={() => openModals({ open: false })} >Cancel</ButtonTersier>
        <ButtonPrimary type="button" onClick={HandlerLeave}>Leave Page</ButtonPrimary>
      </div>
    </div>
  );
};

const ConfirmRemove = ({ message, handler }) => {
  
  return (
    <div className="text-center">
      <p className="mb-10 fs-3">{message}</p>
      <div className="">
        <ButtonTersier type="button" className="me-5" onClick={() => openModals({ open: false })} >Cancel</ButtonTersier>
        <ButtonPrimary type="button" onClick={handler}>Confirm</ButtonPrimary>
      </div>
    </div>
  );
};

export default Modals;
export { openModals, MessageError, MessageSuccess, MessageErrorConnection, ConfirmLeave, ConfirmRemove, MessageSuccessConfirmLeave };
