import { useState } from "react";
import FilterUI from "../../../components/filters/FilterUI";
import { DepartmentEnrollment, YearIntakeListFilter } from "../../components/Dropdown";
import { CandidateName, Degree, LimitFetch } from "../../components/EnrollmentComp";

const Filter = ({ SubmitFilter, results, title }) => {
  const [candidatename, setCandidateName] = useState("");
  const [limit, setLimit] = useState(10);
  const [program_id, setProgram_id] = useState("");
  const [yearintake, setYearIntake] = useState(27);
  const [target_department, setTargetDepartment] = useState("");


  const handleSubmit = (e) => {
    e.preventDefault();
    const paramFilter = {
      [candidatename[0] == 1 || candidatename[0] == 2
        ? "candidate_id"
        : "candidatename"]: candidatename,
      yearintake,
      target_department,
      limit,
      program_id,
    };
    SubmitFilter(paramFilter);
  };

  return (
    <FilterUI title={title} onSubmit={handleSubmit} isload={results.loading}>
      <YearIntakeListFilter data={yearintake} setData={setYearIntake} />
      <DepartmentEnrollment
        data={target_department}
        setData={setTargetDepartment}
        title="Department"
      />
      <Degree data={program_id} setData={setProgram_id} />
      <CandidateName data={candidatename} setData={setCandidateName}  placeholder="Type Name or Candidate ID" />
      <LimitFetch data={limit} setData={setLimit} />
    </FilterUI>
  );
};

export default Filter;
