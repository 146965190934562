import React, { useEffect, useState } from "react";
import { Corporate } from "../../../components/forms/SelectBox";


export function FormSection({wizardActv, setWizardActv, paramSection, setParamSection, setIsPrev }) {
    const [corporate_id, setCorporate_id] = useState(paramSection.corporate_id);

    const openPrev = () =>{
        //openModal({ header:"Preview Section", message:<FormPrev course={course} paramSection={paramSection} />, open:true, size:"lg" });
        alert("prev")
        console.log(paramSection);
    }
    return (
        <div className='field-section'>
            
            <div className="row mb-6">
                <label className="col-lg-4 col-form-label fw-bold fs-6">
                    Corporate
                </label>
                <div className="col-lg-2 col-xl-3 fv-row fv-plugins-icon-container">
                    <Corporate data={corporate_id} setData={setCorporate_id} disabled={corporate_id ? true : false} />
                </div>
            </div>

            <div className="row mb-6">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    Max Number of Student
                </label>
                <div className="col-lg-2 col-xl-3 fv-row fv-plugins-icon-container">
                    <input type="number" name="max_seat" className="form-control form-control-sm mb-3 mb-lg-0" min={1} max={paramSection.max_seat} value={paramSection.max_seat} onChange={(e) => setParamSection({ ...paramSection, max_seat: parseInt(e.target.value) })} />
                    <div className="fv-plugins-message-container invalid-feedback"></div>
                </div>
            </div>

            <div className="row mb-6 d-none">
                <label className="col-lg-4 col-form-label required fw-bold fs-6">
                    Maximum Session
                </label>
                <div className="col-lg-2 fv-row fv-plugins-icon-container">
                    <input type="number" readOnly name="max_seat" className="form-control form-control-lg form-control-solid mb-3 mb-lg-0" min={1} value={paramSection.section_session} onChange={(e) => setParamSection({ ...paramSection, section_session: parseInt(e.target.value) })} />
                    <div className="fv-plugins-message-container invalid-feedback"></div>
                </div>
            </div>

            <div className="text-end">
                <div className="btn-group">
                    <button className="btn btn-lg btn-secondary" type="button" onClick={() => setWizardActv(wizardActv - 1)}>Back</button>
                    <button className="btn btn-lg btn-primary" type="button" onClick={()=>setIsPrev(true)}>
                        <span className="me-2">Preview </span>
                        <i className="bi bi-arrow-right"></i>
                    </button>
                </div>
            </div>
        </div>
    )
}
