import { Dropdown, OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../popovers/PopOvers";
import { forwardRef } from "react";
import { KTIcon } from "../../../_metronic/helpers";
import "./ButtonStyle.css";

const ButtonSelf = forwardRef(({ children, className, ...props }, ref) => {
  return (
    <button ref={ref} className={"btn fw-semibold " + className} {...props}>
      {children}
    </button>
  );
});

const ButtonLong = (props) => {
  const { children, className, title, ...others } = props;
  return (
    <div className="d-grid gap-2">
      {title ? (
        <OverlayTrigger
          delay={{ show: 250, hide: 400 }}
          placement="top"
          overlay={renderTooltip({ title: title })}
        >
          <ButtonSelf className={className} {...others}>
            {children}
          </ButtonSelf>
        </OverlayTrigger>
      ) : (
        <ButtonSelf className={className} {...others}>
          {children}
        </ButtonSelf>
      )}
    </div>
  );
};

const ButtonPrimary = (props) => {
  const { children, className, title, ...others } = props;
  const class_name = `btn btn-primary ${className}`;
  return title ? (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement="top"
      overlay={renderTooltip({ title: title })}
    >
      <ButtonSelf className={class_name} {...others}>
        {children}
      </ButtonSelf>
    </OverlayTrigger>
  ) : (
    <ButtonSelf className={class_name} {...others}>
      {children}
    </ButtonSelf>
  );
};

const ButtonSecondary = (props) => {
  const { children, className, title, ...others } = props;
  const class_name = `btn btn-info ${className}`;
  return title ? (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement="top"
      overlay={renderTooltip({ title: title })}
    >
      <ButtonSelf className={class_name} {...others}>
        {children}
      </ButtonSelf>
    </OverlayTrigger>
  ) : (
    <ButtonSelf className={class_name} {...others}>
      {children}
    </ButtonSelf>
  );
};

const ButtonTersier = (props) => {
  const { children, className, title, ...others } = props;
  const class_name = `btn btn-light ${className}`;
  return title ? (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement="top"
      overlay={renderTooltip({ title: title })}
    >
      <ButtonSelf className={class_name} {...others}>
        {children}
      </ButtonSelf>
    </OverlayTrigger>
  ) : (
    <ButtonSelf className={class_name} {...others}>
      {children}
    </ButtonSelf>
  );
};

const ButtonDanger = (props) => {
  const { children, className, title, ...others } = props;
  const class_name = `btn btn-danger ${className}`;
  return title ? (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement="top"
      overlay={renderTooltip({ title: title })}
    >
      <ButtonSelf className={class_name} {...others}>
        {children}
      </ButtonSelf>
    </OverlayTrigger>
  ) : (
    <ButtonSelf className={class_name} {...others}>
      {children}
    </ButtonSelf>
  );
};

const ButtonIcon = (props) => {
  const { children, className, title, ...others } = props;
  const class_name = `btn btn-icon ${className}`;
  return title ? (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      placement="top"
      overlay={renderTooltip({ title: title })}
    >
      <ButtonSelf className={class_name} {...others}>
        {children}
      </ButtonSelf>
    </OverlayTrigger>
  ) : (
    <ButtonSelf className={class_name} {...others}>
      {children}
    </ButtonSelf>
  );
};

const ButtonRefresh = (props) => {
  const { totalItem, ActionClick } = props;
  return (
    <div className="input-group">
      <span className={"input-group-text bg-light fs-7 py-2"}>
        Total {totalItem ? totalItem : 0}
      </span>
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement={"top"}
        overlay={renderTooltip({ title: "Refersh" })}
      >
        <ButtonSelf
          className="btn btn-sm btn-icon btn-info"
          onClick={ActionClick}
        >
          <i className="bi bi-arrow-repeat"></i>
        </ButtonSelf>
      </OverlayTrigger>
    </div>
  );
};

const ButtonDownload = (props) => {
  const { children, className, ...others } = props;
  const class_name = `btn btn-info ${className}`;
  return (
    <ButtonSelf className={class_name} {...others}>
      <i className="bi bi-upload"></i>
      {children}
    </ButtonSelf>
  );
};

const ButtonRelogin = (props) => {
  const { children, className, ...others } = props;
  const class_name = `btn btn-primary ${className}`;
  return (
    <ButtonSelf className={class_name} {...others}>
      {children}
    </ButtonSelf>
  );
};

const ButtonDropdown = ({ icon, children, title, ...others }) => {
  const ItemDropdown = ({ icon, children, ...others }) => {
    return (
      <Dropdown>
        <Dropdown.Toggle
          variant="light"
          id="dropdown-basic"
          {...others}
        >
          <KTIcon iconName={icon} className="fs-2" />
        </Dropdown.Toggle>

        <Dropdown.Menu>{children}</Dropdown.Menu>
      </Dropdown>
    )
  }
  if (title) {
    return (
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement={"top"}
        overlay={renderTooltip({ title: title })}
      >
        <ItemDropdown icon={icon} children={children} {...others} />
      </OverlayTrigger>
    );
  } else {
    return <ItemDropdown icon={icon} children={children} {...others} />
  }
};

export {
  ButtonLong,
  ButtonPrimary,
  ButtonSecondary,
  ButtonTersier,
  ButtonDanger,
  ButtonIcon,
  ButtonRefresh,
  ButtonDownload,
  ButtonRelogin,
  ButtonDropdown,
};
