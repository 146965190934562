import ExcelJs from "exceljs";
import { BlobImg } from "../../../../../_layouts/components/images/Logos";
import { LecturerTypeName } from "./Helper";

const DownloadTL = (data) => {
    const wb = new ExcelJs.Workbook();
    wb.creator = "UMS-SGU";
    wb.lastModifiedBy = "UMS-SGU-System";
    wb.created = new Date();
    wb.modified = new Date();
    wb.lastPrinted = new Date();

    //body here
    SheetReport(wb, data);
    //end body here

    const writeFile = (fileName, content) => {
        const link = document.createElement("a");
        const blob = new Blob([content], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        });
        link.download = fileName;
        link.href = URL.createObjectURL(blob);
        link.click();
    };

    wb.xlsx.writeBuffer().then((buffer) => {
        writeFile(
            "Report Teaching Load ",
            buffer
        );
    });
};

const RowHeader = (rowHeader, i, value) => {
    rowHeader.getCell(i).value = value;
    rowHeader.getCell(i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
    };
    rowHeader.getCell(i).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "191970" },
    };
    rowHeader.getCell(i).alignment = {
        vertical: "middle",
        horizontal: "center",
    };
    rowHeader.getCell(i).font = {
        bold: true,
        size: 11,
        color: { argb: "FFFFFF" },
    };
}

const SheetReport = (wb, data) => {
    console.log(data);
    const ws = wb.addWorksheet("Teaching Load");

    const HeaderXLS = BlobImg();
    const imageId1 = wb.addImage({
        fileName: "logo-1",
        extension: "png",
        base64: HeaderXLS.imglogo,
    });
    const imageId2 = wb.addImage({
        fileName: "logo-2",
        extension: "png",
        base64: HeaderXLS.imglogoName,
    });

    ws.addImage(imageId1, "B1:B2");
    ws.addImage(imageId2, "E2:F2");

    let rowNumber = 4;

    ws.getRow(rowNumber).getCell("A").value = "Teaching Load Report";
    ws.getRow(rowNumber).getCell("A").font = {
        bold: true,
        size: 16,
    };
    ws.getRow(rowNumber).getCell("A").border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
    };
    ws.getRow(rowNumber).getCell("A").alignment = { horizontal: "center" };
    ws.mergeCells("A" + rowNumber + ":F" + rowNumber);
    rowNumber = rowNumber + 1;

    const tableHeader = [
        { key: "A", title: "No", width: 10 },
        { key: "B", title: "Section ID", width: 20 },
        { key: "C", title: "Course Code", width: 20 },
        { key: "D", title: "Course Name", width: 35 },
        { key: "E", title: "Course Credit", width: 15 },
        { key: "F", title: "Academic Term", width: 35 },
        { key: "G", title: "Lecturer", width: 30 },
        { key: "H", title: "Lecturer's Session", width: 30 },
        { key: "I", title: "Course's Session", width: 30 },
        { key: "J", title: "Lecturer's Status", width: 20 },
        { key: "K", title: "Lecturer's Credit", width: 20 },
        { key: "L", title: "Cost Center", width: 40 },
        { key: "M", title: "Student Department", width: 40 }
    ];
    const rowHeader = ws.getRow(rowNumber);
    tableHeader.map((v, index) => {
        RowHeader(rowHeader, (index + 1), v.title)
    })
    rowNumber = rowNumber + 1;

    let num = 1; let totalBudget = 0;
    data.map((v) => {
        if (v.section_id) {
            let lectType = "-";
            if (v.emp) {
                if (v.emp.emp_type_id) {
                    let lectTypeArr = v.emp.emp_type_id.split(".");
                    lectType = lectTypeArr[lectTypeArr.length - 1];
                }
            }
            var budget = (lectType === "fulltime") ? 0 : ((v.emp) ? v.emp.budget_lecture : 0);
            totalBudget = totalBudget + budget;
            const row = ws.getRow(rowNumber);
            var lec_session = v.emp_session / 14;
            row.getCell(1).value = (num++);
            row.getCell(2).value = v.section_id;
            row.getCell(3).value = (v.course) ? v.course.code : '';
            row.getCell(4).value = (v.course) ? v.course.coursetitle : '';
            row.getCell(5).value = (v.course) ? v.course.credit : '';
            row.getCell(6).value = (v.atp_term) ? v.atp_term.name : '';
            row.getCell(7).value = (v.emp) ? v.emp.fullname : '-';
            row.getCell(8).value = v.emp_session;
            row.getCell(9).value = v.section_session;
            row.getCell(10).value = LecturerTypeName(lectType);
            row.getCell(11).value = (lec_session % 1 != 0) ? Number(lec_session.toFixed(2)) : Number(lec_session);
            row.getCell(12).value = (v.cost_center) ? v.cost_center.name : "";
            let stdDepartment = "";
            if (Object.values(v.section_pre).length > 0) {
                v.section_pre.map((c) => {
                    stdDepartment += ((c.clu) ? c.clu.name : "") + " (Sem " + c.semester + "/" + c.std_class + "), ";
                })
            }
            row.getCell(13).value = stdDepartment;
            rowNumber++;
        }
    });

    ws.columns.forEach((column, index) => {
        if (index === 0) {
            column.width = 5;
        } else {
            let maxColumnLength = 0;
            column.eachCell({ includeEmpty: true }, cell => {
                let cellLength = cell.value ? cell.value.toString().length : 0;
                if (cellLength > maxColumnLength) {
                    maxColumnLength = cellLength;
                }
            });
            column.width = maxColumnLength < 10 ? 10 : maxColumnLength;
        }
    });

};

export { DownloadTL }