import React, { useState } from 'react'
import PackageJSON from "../../../../../package.json";
import ReactHTMLTableToExcel from 'react-html-table-to-excel-3';
import { useOutletContext } from 'react-router-dom';
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';
import { AccessInvalid, RequiredMsg } from '../../../../_layouts/_core/ErrorMessage';
import { MessageError, openModal } from '../../../../_layouts/components/modals/Modals';
import { RemoveEmptyValue } from '../../../../_layouts/_helpers/Global';
import FilterUI from '../../components/filters/FilterUI';
import { AcadTerm, Department, DepartmentSpec } from '../../components/forms/SelectBox';
import { SGUCard } from '../../../../_layouts/components/cards/CardUI';
import { LoadingSkeleton } from '../../../../_layouts/components/loadbars/LoadingBars';
import TableData from './TableData';
import { RPT_STUDENT_SUMMARY } from '../../apis/node-target-data/AcademicApi';
import { ButtonItemRefresh } from '../../../../_layouts/components/datatables/MYDatatables';

export function StudentPerformance() {
  const context = useOutletContext();
  const widget_69 = context.widgets.find(
    (item) => item.widget_id === 69 && item.is_enabled === 1
  );
  const title = "Student Performance";

  const [term_intake_id, setTerm_intake_id] = useState(0);
  const [term_id, setTerm_id] = useState(PackageJSON.system_param.ActiveAcad);
  const [clu_id, setClu_id] = useState(0);
  const [clu_spec_id, setClu_spec_id] = useState(0);

  const paramPost = {
    term_intake_id, term_id, clu_id, clu_spec_id
  }

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadData = async (param) => {
    setResults({ loading: true, message: "", data: [] });
    const result = await RPT_STUDENT_SUMMARY(param);
    setResults(result);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  const SubmitFilter = (e) => {
    e.preventDefault();
    if (paramPost.term_intake_id && paramPost.term_id && paramPost.clu_id) {
      console.log(paramPost)
      const param = RemoveEmptyValue(paramPost);
      ReloadData(param);
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> })
    }
  }

  return widget_69 ? (
    <div className="row">
      <div className="col-lg-3 mb-5">
        <FilterUI title={title} onSubmit={SubmitFilter} isload={results.loading}>
          <AcadTerm data={term_id} setData={setTerm_id} required title={"Academic Term"} />
          <AcadTerm data={term_intake_id} setData={setTerm_intake_id} required title={"Intake Term"} />
          <Department data={clu_id} setData={setClu_id} required />
          <DepartmentSpec data={clu_spec_id} setData={setClu_spec_id} />
        </FilterUI>
      </div>
      <div className="col-lg-9 mb-5">
        <SGUCard>
          <SGUCard.Header>
            <span className="card-label fw-bolder text-dark">
              Browse {title}
            </span>
            <span className="text-muted mt-1 fw-bold fs-7">
              List of student body by filter
            </span>
          </SGUCard.Header>
          <SGUCard.Toolbar>
            {Object.values(results.data).length > 0 && (
              <>
                <ButtonItemRefresh
                  totalItems={results.data.row ? Object.values(results.data.row).length : 0}
                  onClick={(e) => SubmitFilter(e)}
                />
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button btn btn-sm btn-info ms-3"
                  table="table-to-xls"
                  filetype="xls"
                  filename="Report Student Summary"
                  sheet="Student Summary"
                  buttonText="Export to excel" />
              </>
            )}
          </SGUCard.Toolbar>
          <SGUCard.Body className={"px-0"}>
            {results.loading ? (
              <div className="px-8"><LoadingSkeleton /></div>
            ) : results.message ? (
              <div className="px-8"><AlertNotif messages={results.message} /></div>
            ) : (
              <TableData results={results} />
            )}
          </SGUCard.Body>
        </SGUCard>
      </div>
    </div>
  ) : <AlertNotif messages={AccessInvalid} />
}
