import { useState } from "react";
import { ButtonDownload } from "../../../../../_layouts/components/buttons/ButtonUI";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import { DownloadScoreThsStd } from "./DownloadScoreThsStd";

const CardScore = ({ data }) => {
  const totalExaminer = data.ths_main_ex
    ? Object.values(data.ths_main_ex).length
    : 0;
  const totalScore = Object.values(data.student_course_com).length;
  const [isDownload, setDownload] = useState(false);
  const HandlerDownload = () => {
    const param = { term_id: data.atp_id_term, ths_id: data.id };
    DownloadScoreThsStd(param, data.studentid, setDownload);
  };
  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bolder text-dark">
          Thesis Assessment Components
        </span>
      </SGUCard.Header>

      <SGUCard.Toolbar>
        {totalExaminer > 0 && (
          <ButtonDownload
            type="button"
            className="btn-sm"
            disabled={isDownload}
            onClick={() => HandlerDownload()}
          >
            {isDownload ? "Processing..." : "Export to excel"}
          </ButtonDownload>
        )}
      </SGUCard.Toolbar>

      <SGUCard.Body className={"px-0"}>
        {totalExaminer > 0 && totalScore > 0 ? (
          <div className="table-responsive">
            <table className="table table-row-dashed align-start fs-6 gy-4 my-0 pb-3 dataTable no-footer align-middle">
              <thead>
                <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0 bg-light align-middle text-center">
                  <th width="5%">No</th>
                  <th>Component</th>
                  {data.ths_main_ex.map((v, index) => (
                    <th key={index} width="15%">
                      {v.emp && (v.emp.fullname || "")}
                    </th>
                  ))}
                  <th>Average Score</th>
                  <th>Weight</th>
                  <th>Weighted Score</th>
                </tr>
              </thead>
              <tbody>
                <RowScore
                  section_com={data.section_com}
                  score={data.student_course_com}
                  examiner={data.ths_main_ex}
                />
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={totalExaminer + 4} className="text-end">
                    <span className="fw-bold">Final Result</span>
                  </td>
                  <td className="text-center">
                    <span className="fw-bold">
                      {data.student_course && (data.student_course.score || "")}
                    </span>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        ) : (
          <CardComponentScore data={data.section_com} />
        )}
      </SGUCard.Body>
    </SGUCard>
  );
};

const CardComponentScore = ({ data }) => {
  return (
    <div className="table-responsive">
      <table className="table table-row-dashed align-start fs-6 gy-4 my-0 pb-3 dataTable no-footer align-middle">
        <thead>
          <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0 bg-light align-middle text-center">
            <th width="5%">No</th>
            <th>Component</th>
            <th>Average Score</th>
            <th>Weight</th>
            <th>Weighted Score</th>
          </tr>
        </thead>
        <tbody>
          {data.map((v, index) => (
            <tr>
              <td className="text-center">{index + 1}</td>
              <td>{v.name}</td>
              <td></td>
              <td className="text-center">{(v.weight * 100).toFixed(0)}%</td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const fixedTwoDecimalsWithoutRounding = (number) => {
  let [integerPart, decimalPart = ""] = number.toString().split(".");
  decimalPart = decimalPart.padEnd(2, "0").slice(0, 2);
  return `${integerPart}.${decimalPart}`;
};

const RowScore = ({ section_com, score, examiner }) => {
  const transformData = MergingScore(section_com, score, examiner);
  return transformData.map((v, index) => (
    <tr key={index}>
      <td className="text-center">{index + 1}</td>
      <td>{v.name}</td>
      {examiner.map((user, index) => {
        const findScore = v.scores.find(
          (item) => item.submittedby_id === user.emp_id
        );
        return (
          <td className="text-center" key={index}>
            {findScore ? findScore.score || "-" : "-"}
          </td>
        );
      })}
      <td className="text-center">
        {fixedTwoDecimalsWithoutRounding(v.average_score)}
      </td>
      <td className="text-center">{v.weight}%</td>
      <td className="text-center">
        {fixedTwoDecimalsWithoutRounding(v.weighted_score)}
      </td>
    </tr>
  ));
};

const MergingScore = (section_com, score, examiner) => {
  const results = section_com.map((item) => {
    let obj = {};
    obj.id = item.id;
    obj.name = decodeURIComponent(item.name);
    obj.weight = (item.weight * 100).toFixed(0);
    obj.wg_com_id = item.wg_com_id;
    const score_student = examiner
      .map((exa) => {
        const findScoreCom = score.find(
          (com) =>
            com.wg_com.id === item.wg_com_id &&
            com.submittedby_id === exa.emp_id
        );
        if (findScoreCom) {
          return {
            score: findScoreCom.score,
            submitted_formatted: findScoreCom.submitted_formatted,
            submittedby_id: findScoreCom.submittedby_id,
            submitted_by:
              findScoreCom.submittedby &&
              (findScoreCom.submittedby.fullname || ""),
          };
        }
        return undefined;
      })
      .filter((score) => score !== undefined);

    obj.scores = score_student;

    if (score_student.length > 0) {
      const totalScore = score_student.reduce(
        (total, current) => total + current.score,
        0
      );
      obj.average_score = (totalScore / score_student.length).toFixed(2);

      const totalWeightedScore = obj.average_score * (obj.weight / 100);
      obj.weighted_score = totalWeightedScore;
    } else {
      obj.average_score = null;
      obj.weighted_score = null;
    }

    return obj;
  });

  return results;
};

export { CardScore, MergingScore, fixedTwoDecimalsWithoutRounding };
