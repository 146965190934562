import React, { useEffect, useMemo, useState } from "react";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import { ButtonIcon, ButtonPrimary } from "../../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../../_metronic/helpers";
import { useOutletContext } from "react-router-dom";
import { DELETE_UNIVERSITY, GET_UNIV_LOOKUP } from "../../../apis/node-target-data/IdentityApi";
import { PaginationComponent, SearchBarTable } from "../../../../../_layouts/components/datatables/MYDatatables";
import { LoadingSkeleton, NoRecordFound } from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../../_layouts/components/alerts/AlertsUI";
import { ConfirmRemove, MessageError, MessageSuccess, openModal } from "../../../../../_layouts/components/modals/Modals";
import Form from "./Form";
import ProdyUniv from "./ProdyUniv";

export default function MUniversity() {
  const context = useOutletContext();
  const widget_103 = context.widgets.find(
    (item) => item.widget_id === 103 && item.is_enabled === 1
  );

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadData = async () => {
    setResults({ loading: true, message: "", data: [] });
    const result = await GET_UNIV_LOOKUP({ "":"" });
    setResults(result);
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const [search, setSearch] = useState("");
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const ResultData = useMemo(() => {
    let computedData = [];

    if (Object.values(results.data).length > 0) {
      computedData = results.data.map((v) => {
        let obj = {};
        obj.name = v.name ? decodeURIComponent(v.name) : "";
        obj.id = v.id;
        return obj;
      });
      computedData.sort((a, b) => (a.id < b.id ? 1 : -1));
    }

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          listData[key]
            .toString()
            .toLowerCase()
            .includes(search)
        );
      });
    }

    setTotalItems(computedData.length);

    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [results.data, search, currentPage]);

  
  const HandlerDelete = async (id) =>{
    const param = {id:id};
    const result = await DELETE_UNIVERSITY(param);
    if(result.data && Object.values(result.data).length > 0){
      openModal({message:<MessageSuccess message={"Successfully remove"} />})
      ReloadData();
    }else{
      openModal({message:<MessageError message={"Failed remove"} />})
    }
  }

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bolder text-dark">
          Master University
        </span>
        <span className="text-muted mt-1 fw-bold fs-7">
          total: {totalItems} data
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        {widget_103 && (
          <ButtonPrimary
            className="btn-sm fw-bold"
            onClick={() => openModal({ message: <Form ReloadData={ReloadData} /> })}
          >
            <KTIcon iconName="plus" />
            Add New
          </ButtonPrimary>
        )}
      </SGUCard.Toolbar>
      <SGUCard.Body>
        <div className="d-flex align-items-center">
          <div className="w-100">
            <SearchBarTable size="w-100" onChange={(e) => setSearch(e.target.value)} />
          </div>
          <ButtonIcon type="button" className="btn-sm btn-light ms-3" title="Refresh" onClick={()=>{ReloadData({ "": "" })}}>
              <i className="bi bi-arrow-repeat fs-2 text-primary"></i>
          </ButtonIcon>
        </div>
      
        <div className="table-responsive">
            <table className="table table-row-dashed align-start fs-6 gy-4 my-0 pb-3 dataTable no-footer align-start">
            <tbody>
              {results.loading ? (
                <tr>
                  <td>
                    <LoadingSkeleton />
                  </td>
                </tr>
              ) : results.message ? (
                <tr>
                  <td>
                    <AlertRetry
                      messages={results.message}
                      Reload={ReloadData}
                    />
                  </td>
                </tr>
              ) : ResultData.length > 0 ? (
                ResultData.map((v, index) => (
                  <tr key={index}>
                    <td>
                      <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                        <div className="flex-grow-1 me-2">
                          <span className="text-gray-800 text-hover-primary fs-6 fw-bold">
                            {v.name}
                          </span>
                        </div>

                        {widget_103 && (
                          <div className="text-center">
                            {v.id !== 9999 && (
                            <ButtonIcon type="button" className="btn-sm" title="Study Program" onClick={() => openModal({header:`Study Program in ${v.name}`,message:<ProdyUniv univid={v.id} />})} >
                              <KTIcon iconName="teacher" className="fs-3 text-primary" />
                            </ButtonIcon>
                            )}
                            <ButtonIcon type="button" className="btn-sm text-hover-primary" title="Update" onClick={() => openModal({ message: <Form ReloadData={ReloadData} data={v} /> })} >
                              <KTIcon iconName="pencil" className="fs-3" />
                            </ButtonIcon>
                            {v.id !== 9999 && (
                            <ButtonIcon type="button" className="btn-sm" title="Remove" onClick={() => openModal({header:"Confirmation",message:<ConfirmRemove message={"Are you sure want to remove"} handler={()=>HandlerDelete(v.id)} />})} >
                              <KTIcon iconName="trash" className="fs-3 text-danger" />
                            </ButtonIcon>
                            )}
                          </div>
                        )}
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td>
                    <NoRecordFound />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {!results.loading && (
          <PaginationComponent
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page) => setCurrentPage(page)}
          />
        )}
      </SGUCard.Body>
    </SGUCard>
  );
}
