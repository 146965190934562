import { useState } from "react"
import { ButtonDanger, ButtonPrimary, ButtonTersier } from "../../../../../_layouts/components/buttons/ButtonUI"
import { MessageError, MessageSuccess, openModal } from "../../../../../_layouts/components/modals/Modals"
import { KTIcon } from "../../../../../_metronic/helpers"
import { DELETE_SCHD } from "../../../apis/node-target-data/AcademicApi"
import moment from "moment"

const ExistSchedule = ({schd, setExistSchd, CREATE_SCEDULE}) =>{
    return (
        <div className="alert alert-primary d-flex align-items-start p-5">
            <KTIcon iconName="information-3" className="text-primary fs-3x" />
            <div className="d-flex flex-column w-100 ms-3">
                <h3 className="mb-1">Information of room occupied</h3>
                <p className="text-dark mb-3">The schedule you selected has been assigned to:</p>
                <ol className="ps-5">
                    {schd.data.map((v, index) => (
                    <li key={index} className="text-start text-dark">
                        <span className="ms-1 fw-bolder ">{(v.label ? v.label : (v.course && (v.course.coursetitle || "")))}</span> in 
                        {(v.event_room && (
                        v.event_room.map((r, index) => (
                            <span key={index} className="fw-bolder mx-1">
                            Room {r.room ? r.room.g_resourceName : ""}
                            {(index < (Object.values(v.event_room).length - 1)) ? ", " : ""}
                            </span>
                        ))
                        ))}
                        <span className="mx-1">at</span>
                        <span className="text-danger fw-bold">{moment(v.start_dt).format("DD MMM YYYY hh:mm A")} - {moment(v.end_dt).format("hh:mm A")}</span>
                    </li>
                    ))}
                </ol>

                <p className="mb-3 text-dark">Are you sure wants to create this session ?</p>

                <div className="text-end">
                    <ButtonTersier type="button" className="btn-lg fw-bold me-2" onClick={()=>setExistSchd([])}>
                        No
                    </ButtonTersier>
                    <ButtonPrimary type="button" className="btn-lg fw-bold" onClick={()=>CREATE_SCEDULE(schd.param)}>
                        Yes
                    </ButtonPrimary>
                </div>
            </div>
        </div>
    )
}

const MessageSuccessSubmit = ({ResetForm}) =>{
    return (
        <div className="text-center py-8">
            <div className="icon mb-5">
                <i className="bi bi-check-circle text-success" style={{ fontSize: "6em" }} ></i>
            </div>
            <h1 className="text-dark">Successfully saved</h1>
            <p className="mb-10">The sessions have been successfully created. Would you like to create another schedule?</p>
            <ButtonTersier type="button" className="btn-lg fw-bolder me-5" onClick={()=>window.close()}>
                No
            </ButtonTersier>
            <ButtonPrimary type="button" className="btn-lg fw-bolder" onClick={() => {ResetForm(); openModal({open:false})}}>
                Yes
            </ButtonPrimary>
        </div>
    )
}

const ConfirmRemoveSchd = ({id, Reload}) =>{
    const [results, setResults] = useState({loading: false, message: '', data: []});
    const HandlerRemove = async () =>{
        setResults({loading: true, message: '', data: []})
        const param = {schd_id : id}
        const result = await DELETE_SCHD(param);
        if(!result.loading){
            openModal({open:false})
        }
        Reload();
    }
    return (
        <div className="text-center">
            <h3 className="mb-10">Are you sure want to remove ?</h3>

            <ButtonTersier type="button" className="btn-lg fw-bold me-5" onClick={()=>openModal({open:false})}>
                No
            </ButtonTersier>
            <ButtonDanger type="button" disabled={results.loading} className="btn-lg fw-bold" onClick={()=>HandlerRemove()}>
                {results.loading ? "Processing..":"Remove schedule"}
            </ButtonDanger>
        </div>
    )       
}

export {ExistSchedule, MessageSuccessSubmit, ConfirmRemoveSchd}