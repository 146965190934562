import React, { useState } from "react";
import { ButtonLong } from "../../../../../_layouts/components/buttons/ButtonUI";
import { MessageSuccess, openModal } from "../../../../../_layouts/components/modals/Modals";
import { RequiredMsg } from "../../../../../_layouts/_core/ErrorMessage";
import { SUBMIT_ATD_LECTURER_NA } from "../../../apis/node-target-data/AcademicApi";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";

export default function FormTeachingTopic({ tt_schd_id, lecturers, ReloadData }) {
  //console.log(data);
  //console.log(lecturers);
  // {
  //     "budget_lecture": 8680000,
  //     "email": "aditya.pratama@sgu.ac.id",
  //     "emp_type_id": "hr.emp.type.fulltime",
  //     "fullname": "Dr.Eng. Aditya Tirta Pratama, S.Si., M.T.",
  //     "id": 47,
  //     "rate": "S3",
  //     "rate_lecture_amount": 310000
  // }

  const [postParam, setPostParam] = useState({
    consecutive: true,
    emp_id: 0,
    remark: "",
    teaching_topic: "",
    tt_schd_id: tt_schd_id,
  });

  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const HandlerSubmit = async (e) => {
    e.preventDefault();
    if(postParam.emp_id && postParam.teaching_topic){
        setSubmit({loading:true, message:"", data:[]});
        const result = await SUBMIT_ATD_LECTURER_NA(postParam);
        if(result.data && Object.values(result.data).length > 0){
            openModal({message:<MessageSuccess message={"Successfully saved"} />});
            ReloadData();
            setInterval(() => {
                openModal({open:false});
            }, 1000);
        }else{
            setSubmit(result);
        }
        
        
    }else{
        setSubmit({loading:false, message:RequiredMsg, data:[]});
    }
  };
  return (
    <form method="post" autoComplete="off" onSubmit={(e) => HandlerSubmit(e)}>
      <div className="form-group mb-5">
        <label className="required fw-bold">Lecturer</label>
        <select
          name="lecturer_id"
          className="form-select"
          defaultValue={postParam.emp_id}
          onChange={(e) =>
            setPostParam({ ...postParam, emp_id: parseInt(e.target.value) })
          }
        >
          <option value="0">Choose One</option>
          {lecturers.map((v, index) => (
            <option value={v.emp.id} key={index}>
              {v.emp.fullname}
            </option>
          ))}
        </select>
      </div>
      <div className="form-group mb-5">
        <label className="required fw-bold">Teaching Topic</label>
        <input
          type="text"
          name="teaching_topic"
          className="form-control"
          defaultValue={decodeURIComponent(postParam.teaching_topic)}
          onChange={(e) =>
            setPostParam({
              ...postParam,
              teaching_topic: encodeURIComponent(e.target.value),
            })
          }
        />
      </div>

      {submit.message && (
        <div className="my-8">
            <AlertNotif messages={submit.message} />
        </div>
      )}

      <div className="text-center">
        <ButtonLong
          type="submit"
          className="btn-lg btn-primary fw-bold text-uppercase"
          disabled={submit.loading}
        >
          {submit.loading ? "Processing..." : "Submit"}
        </ButtonLong>
      </div>
    </form>
  );
}
