import ExcelJs from "exceljs";
import { GET_ENROLLMENT_PROCESS } from "../../../apis/node-target-data/MarketingAPI";
import { YearIntake } from "../../Helper";
import { BlobImg } from "../../../../../_layouts/components/images/Logos";
import {
  MessageError,
  openModal,
} from "../../../../../_layouts/components/modals/Modals";
import moment from "moment";

const DownloadEnrollment = async (param, setStatus) => {
  let paramExcel = { ...param };
  delete paramExcel.limit;
  delete paramExcel.offset;
  try {
    setStatus(true);
    const result = await GET_ENROLLMENT_PROCESS(param);

    if (result.data && Object.values(result.data).length > 0) {
      const sort_desc = result.data.sort((a, b) =>
        moment(a.registrationdate).toDate() <
        moment(b.registrationdate).toDate()
          ? 1
          : -1
      );

      EnrollmentExcel(
        sort_desc,
        `Intake ${YearIntake(param.yearintake)}`,
        paramExcel
      );
    } else {
      openModal({ message: <MessageError message={result.message} /> });
    }

    setStatus(false);
  } catch (error) {
    console.log(error);
    openModal({
      message: (
        <MessageError message={"Failed to download. Please try again later."} />
      ),
    });
    setStatus(false);
  }
};

const EnrollmentExcel = (data, intake_label, paramExcel) => {
  console.log(intake_label, data);
  const wb = new ExcelJs.Workbook();
  wb.creator = "UMS-SGU";
  wb.lastModifiedBy = "UMS-SGU-System";
  wb.created = new Date();
  wb.modified = new Date();
  wb.lastPrinted = new Date();

  //body here
  SheetEnrollment(wb, data, intake_label, paramExcel);
  //end body here

  const writeFile = (fileName, content) => {
    const link = document.createElement("a");
    const blob = new Blob([content], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    link.click();
  };

  wb.xlsx.writeBuffer().then((buffer) => {
    writeFile("Report Admission " + intake_label, buffer);
  });
};

const SheetEnrollment = (wb, data, intake_label, paramExcel) => {
  const ws = wb.addWorksheet(intake_label);
  const HeaderXLS = BlobImg();
  const imageId1 = wb.addImage({
    fileName: "logo-1",
    extension: "png",
    base64: HeaderXLS.imglogo,
  });
  const imageId2 = wb.addImage({
    fileName: "logo-2",
    extension: "png",
    base64: HeaderXLS.imglogoName,
  });

  ws.addImage(imageId1, "A1:B2");
  ws.addImage(imageId2, "H2:J2");

  let rowNumber = 4;

  ws.getRow(rowNumber).getCell("A").value = "Admission " + intake_label;
  ws.getRow(rowNumber).getCell("A").font = {
    bold: true,
    size: 16,
  };
  ws.getRow(rowNumber).getCell("A").border = {
    top: { style: "thin" },
    bottom: { style: "thin" },
  };
  ws.getRow(rowNumber).getCell("A").alignment = { horizontal: "center" };
  ws.mergeCells("A" + rowNumber + ":J" + rowNumber);

  // table filter

  rowNumber = rowNumber + 2;

  const headersFilter = [
    "",
    "Year Intake",
    "Department",
    "Fee Status",
    "Tuition Status",
    "Registration Status",
    "Date",
  ];
  const rowHeaderFilter = ws.getRow(rowNumber);
  headersFilter.forEach((element, index) => {
    RowHeader(rowHeaderFilter, index + 1, element, "filter");
  });

  rowNumber = rowNumber + 1;
  const row = ws.getRow(rowNumber);
  row.getCell(1).value = "Filter";
  row.getCell(2).value =
    paramExcel.yearintake && (YearIntake(paramExcel.yearintake) || "");
  row.getCell(3).value =
    paramExcel.target_department && (data[0].target_department.name || "");
  row.getCell(4).value = paramExcel.regfee_status && (paramExcel.regfee_status || "");
  row.getCell(5).value = paramExcel.tution_status && (paramExcel.tution_status || "");
  row.getCell(6).value =
    paramExcel.status && (decodeURIComponent(paramExcel.status) || "");
  row.getCell(7).value =
    paramExcel.start_dt &&
    (`${moment(paramExcel.start_dt).format("DD MMM YYYY")}-${moment(
      paramExcel.end_dt
    ).format("DD MMM YYYY")}` ||
      "");

  rowNumber = rowNumber + 3;

  const headers = [
    "No",
    "Candidate Name",
    "Registration ID",
    "Registration Date",
    "Applied Department",
    "Enrollment Type",
    "Intake",
    "Mobile Phone",
    "Birthdate",
    "Email",
    "Status Reg Fee",
    "Status Tuition",
    "Registration Status",
  ];
  const rowHeader = ws.getRow(rowNumber);
  headers.forEach((element, index) => {
    RowHeader(rowHeader, index + 1, element);
  });

  rowNumber = rowNumber + 1;
  var num = 1;
  data.map((v) => {
    const fin_trans_reg_fee = v.fin_tr.find(
      (item) => item.trans_type === "mkt.fin.trans.type.regfee"
    );
    const fin_trans_reg_tuition = v.fin_tr.find(
      (item) => item.trans_type === "mkt.fin.trans.type.tuitionfee"
    );
    const row = ws.getRow(rowNumber);
    row.getCell(1).value = num++;
    row.getCell(2).value = v.regstudent && (v.regstudent.fullname || "");
    row.getCell(3).value = v.candidate_id || "";
    row.getCell(4).value =
      v.registrationdate &&
      (moment(v.registrationdate).format("DD MMM YYYY hh:mm A") || "");
    row.getCell(5).value =
      v.target_department && (v.target_department.name || "");
    row.getCell(6).value = v.enrollment_type && (v.enrollment_type.name || "");
    row.getCell(7).value = v.yearintake && (YearIntake(v.yearintake) || "");
    row.getCell(8).value = v.regstudent && (v.regstudent.mobile || "");
    row.getCell(9).value = v.regstudent && (v.regstudent.birthdate || "");
    row.getCell(10).value = v.regstudent && (v.regstudent.email || "");
    row.getCell(11).value =
      fin_trans_reg_fee && (fin_trans_reg_fee.status || "");
    row.getCell(12).value =
      fin_trans_reg_tuition && (fin_trans_reg_tuition.status || "");
    row.getCell(13).value = v.status || "";
    rowNumber++;
  });

  ws.columns.forEach((column, index) => {
    if (index === 0) {
      column.width = 5;
    } else if (index === 1) {
      column.width = 15;
    } else {
      let maxColumnLength = 0;
      column.eachCell({ includeEmpty: true }, (cell) => {
        let cellLength = cell.value ? cell.value.toString().length : 0;
        if (cellLength > maxColumnLength) {
          maxColumnLength = cellLength;
        }
      });
      column.width = maxColumnLength < 10 ? 10 : maxColumnLength;
    }
  });
};

const RowHeader = (rowHeader, i, value, type = "data") => {
  rowHeader.getCell(i).value = value;
  rowHeader.getCell(i).border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
  rowHeader.getCell(i).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: type === "data" ? "191970" : "008AFF" },
  };
  rowHeader.getCell(i).alignment = {
    vertical: "middle",
    horizontal: "center",
  };
  rowHeader.getCell(i).font = {
    bold: true,
    size: 11,
    color: { argb: "FFFFFF" },
  };
};

export { DownloadEnrollment };
