import { AxiosLibGlobal } from "../../../_layouts/_helpers/Helpers";

const LoginEstes = async (data) => {

    const config = {
        method: "post",
        service: "LOGIN",
        headers: data.headers,
        payload: data.payload
    }
    const results = await AxiosLibGlobal(config);
    if (Object.values(results.data).length > 0) {
        const myaccount = results.data;
        const empid = myaccount.identity.empid
        if (empid) {
            const exist_user = await CheckUserSys(results.data);
            if(exist_user){
                if (exist_user.data && Object.values(exist_user.data).length > 0) {
                    myaccount.identity.group_id = exist_user.data.group_id;
                    myaccount.identity.group_name = exist_user.data.group_name;
                    myaccount.identity.account_id = exist_user.data.user_id;

                    return { loading: false, data: myaccount, message: results.data.message.user }
                } else {
                    return { loading: false, data: "", message: exist_user.message }
                }
            }else{
                return { loading: false, data: [], message: "You do not have authority in this system. No group ID detected. Please contact the IST department. Thank you." }    
            }
        } else {
            return { loading: false, data: [], message: "You do not have authority in this system. No employee ID found. Please contact the IST department. Thank you." }
        }
    } else {
        return results;
    }
}

const CheckUserSys = async (data) => {
    const payload = {
        email: data.identity.email,
        employeeid: data.identity.empid
    }

    const config = {
        method: "post",
        service: "LOGIN_USERSYS",
        headers: {},
        payload: payload
    }

    const results = await AxiosLibGlobal(config);
    if (Object.values(results.data).length > 0) {
        return results.data;
    }
    return null;
}

export { LoginEstes }