import { useState } from "react";
import FilterUI from "../../../components/filters/FilterUI";
import { CouponStatus } from "../../components/EnrollmentComp";

const FilterCoupon = ({ SubmitFilter, results, title }) => {
  const [status, setStatus] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const paramFilter = {
      status,
    };
    SubmitFilter(paramFilter);
  };

  return (
    <FilterUI title={title} onSubmit={handleSubmit} isload={results.loading}>
      <CouponStatus data={status} setData={setStatus} />
    </FilterUI>
  );
};

export default FilterCoupon;
