import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  AcadTerm,
  MilestoneInsLookUp,
  SectionSelectMulti,
} from "../../components/forms/SelectBox";
import moment from "moment";
import {
  ButtonPrimary,
  ButtonTersier,
} from "../../../../_layouts/components/buttons/ButtonUI";
import {
  MessageSuccess,
  openModal,
} from "../../../../_layouts/components/modals/Modals";
import { RequiredMsg } from "../../../../_layouts/_core/ErrorMessage";
import { CREATE_MILESTONE } from "../../apis/node-target-data/AcademicApi";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";

export default function FormSchedule({ atpid, ReloadData }) {
  const currentDate = moment().toDate();
  const [atp_id, setAtp_id] = useState(atpid);
  const [mstone_type, setMstone_type] = useState("");
  const [mstone_id, setMstone_id] = useState("");
  const [grouptype, setGrouptype] = useState("");
  const [start_dt, setStart_dt] = useState(currentDate);
  const [end_dt, setEnd_dt] = useState(currentDate);
  const [sections, setSections] = useState([]);
  const [remark, setRemarks] = useState("");
  const [is_public, setIs_public] = useState(0);

  const group_type_information = "asc.atp.milestone.group.information";

  useEffect(()=>{
    if (mstone_id) {
      const [mstoneTypeValue, grouptypeValue] = mstone_id.split("#");
      console.log(grouptypeValue);
      setMstone_type(mstoneTypeValue);
      setGrouptype(grouptypeValue);
    }
  },[mstone_id, grouptype])

  const HandlerEndDate = (date) => {
    if (date < start_dt) {
      alert("End date is bigger than start date.");
      setEnd_dt(start_dt);
    } else {
      setEnd_dt(date);
    }
  };

  const handlerIsPublic = (e) =>{
    const is_checked = e.target.checked;
    if(is_checked){
      setIs_public(1)  
    }else{
      setIs_public(0)  
    }
  } 

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const HandlerSubmit = async (e) => {
    e.preventDefault();
    setResults({ loading: true, message: "", data: [] });
    const totalSection = Object.values(sections).length;
    if (atp_id && mstone_type && start_dt && end_dt && remark && grouptype) {
      let param = {
        atp_id,
        mstone_type,
        start_dt: moment(start_dt).format("YYYY-MM-DD HH:mm:00"),
        end_dt: moment(end_dt).format("YYYY-MM-DD HH:mm:00"),
        remark,
        is_public,
        grouptype
      };
      if (totalSection > 0) {
        let section_name = "";
        sections.map((v, index) => {
          section_name += v.value + (index < totalSection - 1 ? ", " : "");
        });
        param.var1 = section_name;
      }

      const parameters = RemoveEmptyValue(param);
      const result = await CREATE_MILESTONE(parameters);
      if (result.data && Object.values(result.data).length > 0) {
        openModal({
          message: <MessageSuccess message={"Successfully saved."} />,
        });
        ReloadData();
      } else {
        setResults(result);
      }
    } else {
      setResults({ loading: false, message: RequiredMsg, data: [] });
    }
  };

  return (
    <div>
      <h3 className="mb-8">Form create schedule access</h3>
      <div style={{ maxHeight: "400px", overflowX: "hidden", overflowY: "auto" }}>
        <form method="post" autoComplete="off" onSubmit={(e) => HandlerSubmit(e)}>
          <AcadTerm data={atp_id} setData={setAtp_id} required />
          <MilestoneInsLookUp
            data={mstone_id}
            setData={setMstone_id}
            required
          />

          <div className="row">
            <div className="col-lg-6">
              <div className="mb-8">
                <label className="fw-bold mb-2 required d-block">Start Date</label>
                <DatePicker
                  className="form-control form-control-sm pe-10"
                  dateFormat={"yyyy-MM-dd hh:mm aa"}
                  minDate={currentDate}
                  isClearable
                  showTimeSelect
                  timeIntervals={10}
                  placeholderText="Enter date"
                  selected={start_dt}
                  onChange={(date) => {
                    setStart_dt(date);
                    setEnd_dt(date);
                  }}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="mb-8">
                <label className="d-block fw-bold mb-2 required">End Date</label>
                <DatePicker
                  className="form-control form-control-sm pe-10"
                  dateFormat={"yyyy-MM-dd hh:mm aa"}
                  isClearable
                  showTimeSelect
                  placeholderText="Enter date"
                  selected={end_dt}
                  onChange={(date) => HandlerEndDate(date)}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
          </div>

          <SectionSelectMulti data={sections} setData={setSections} />

          <div className="form-group mb-8">
            <label className="required fw-bold">Remark</label>
            <textarea
              name="remarks"
              className="form-control"
              defaultValue={remark}
              onChange={(e) => setRemarks(e.target.value)}
            ></textarea>
          </div>

          <div className="mb-8">
            <div className="form-group">
              <label className="required fw-bold">Do you want to make it public?</label>
              <div className="d-flex align-items-center bg-light p-3 rounded">
                <div className="form-check form-switch mt-2">
                  <input
                    type="checkbox"
                    name="is_public"
                    className="form-check-input"
                    defaultChecked={(is_public === 1) ? true : false}
                    defaultValue={is_public}
                    onChange={(e) => handlerIsPublic(e)}
                    title={(is_public === 1) ? "Tick for NO" : "Tick for YES"}
                  />
                </div>
                {is_public === 1 ? (
                  <span className="text-dark fs-7">Will display on SGU member calendar (students, lecturers, and staff)</span>
                ):(
                  <span className="text-dark fs-7">It will only appear on the internal SGU members' calendar (lecturers and staff)</span>
                )}
              </div>
            </div>
          </div>

          {results.message && (
            <div className="my-5">
              <AlertNotif messages={results.message} color="danger" />
            </div>
          )}

          <div className="text-end">
            <ButtonTersier
              type="button"
              className="btn-lg fw-bold"
              onClick={() => openModal({ open: false })}
            >
              Cancel
            </ButtonTersier>
            <ButtonPrimary
              type="submit"
              className="btn-lg fw-bold ms-2"
              disabled={results.loading}
            >
              {results.loading ? "Processing..." : "Submit"}
            </ButtonPrimary>
          </div>
        </form>
      </div>
    </div>
  );
}
