import { SGUCard } from '../../../../../_layouts/components/cards/CardUI'
import { FormScoreCompNumeric } from './FormScoreCompNumeric'
import { FormScoreCompLetter } from './FormScoreCompLetter'
import { FormScoreOnly } from './FormScoreOnly'
import { LoadingBars } from '../../../../../_layouts/components/loadbars/LoadingBars'
import { KTIcon } from '../../../../../_metronic/helpers'

export default function FormScore({ data, section, ReloadData }) {
    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bolder text-dark">
                    Submit Score Student
                </span>
            </SGUCard.Header>
            <SGUCard.Toolbar>
            <span className="fs-5 badge badge-light">
                <KTIcon iconName='pin' className='fs-2 me-2' />
                Academic Calendar <span className='ms-1'>{data.atp_term && (data.atp_term.name || "")}</span>
            </span>
            </SGUCard.Toolbar>
            <SGUCard.Body>
                <RenderScore data={data} section={section} ReloadData={ReloadData} />
            </SGUCard.Body>
        </SGUCard>
    )
}

const RenderScore = ({ data, section, ReloadData }) => {
    if (data.section_com && Object.values(data.section_com).length > 0) {
        if (data.course.score_type === 2) {
            return <FormScoreCompLetter data={data} ReloadData={ReloadData} />
        }else{
            return <FormScoreCompNumeric data={data} ReloadData={ReloadData} />
        }
    } else if ((data.section_com && Object.values(data.section_com).length === 0) && Object.values(section).length === 0) {
        return <FormScoreOnly data={data} ReloadData={ReloadData} />
    } else if (data.section_com && Object.values(data.section_com).length === 0) {
        return <FormScoreOnly data={data} ReloadData={ReloadData} />
    } else if (Object.values(section).length === 0) {
        return <FormScoreOnly data={data} ReloadData={ReloadData} />
    }
}