import { useEffect, useMemo, useState } from "react";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  SearchBarTable,
} from "../../../../../_layouts/components/datatables/MYDatatables";
import {
  LoadingSkeleton,
  NoRecordFound,
  SpinnerLoad,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import { MontIntake, ProgramDegree, YearIntake } from "../../Helper";
import {
  CREATE_STUDENT_FROM_ENROLLPROC,
  UPDATE_ENROLLMENT,
} from "../../../apis/node-target-data/MarketingAPI";
import { RegisterStatus } from "../../components/Helper";
import moment from "moment";
import {
  BadgeItem,
  ButtonAccept,
  ButtonSetEdit,
} from "../../components/EnrollmentComp";
import {
  AcadList,
  AcadListEdit,
  Corporate,
  CorporateEdit,
} from "../../components/Dropdown";
import RangeDataCounter from "../../components/RangeDataCounter";
import { OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { KTIcon } from "../../../../../_metronic/helpers";

export default function TableData({
  results,
  ReloadData,
  widget,
  triggerNotification,
  acadCal,
}) {
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [editAcadCorIndex, setEditAcadCorIndex] = useState(-1);
  const [loadingAcceptCreateStudent, setLoadingAcceptCreateStudent] = useState(
    -1
  );
  const [copyAcadCor, setCopyAcadCor] = useState([]);
  const [choosenAcad, setChoosenAcad] = useState({
    id: "",
    index: -1,
  });
  const [choosenCorporate, setChoosenCorporate] = useState({
    id: "",
    index: -1,
  });

  const [loadingSetUpdateCorAcad, setLoadingSetUpdateCorAcad] = useState({
    loading: -1,
    message: "",
    data: [],
  });

  const headers = [
    { name: "Name", field: "name", sortable: false },
    { name: "Reg Id", field: "reg_id", sortable: false },
    { name: "Department", field: "department", sortable: false },
    { name: "Enroll Type", field: "enrollment_type", sortable: false },
    // { name: "Intake", field: "intake", sortable: false },
    { name: "Reg Status", field: "reg_status", sortable: false },
    { name: "Corporate", field: "corporate", sortable: false },
    { name: "Academic Calendar", field: "acad_cal", sortable: false },
    { name: "Action", field: "action", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (results.data) {
      computedData = results.data.map((c) => {
        var obj = {};
        obj.name = c.regstudent.fullname;
        obj.reg_id = c.candidate_id;
        obj.corporate_id = c.corporate_id ?? "";
        obj.corporate_name = c.corporate?.name ?? "-";
        obj.term_id = c.term_id ?? "";
        obj.term_name = c.term?.name ?? "-";
        obj.registrationdate = c.registrationdate;
        obj.reg_date = c.registrationdate
          ? moment(c.registrationdate).format("DD MMM YYYY hh:mm A")
          : "";
        obj.enrollment_type = c.enrollment_type?.name ?? "-";
        obj.major = c.target_department.name;
        obj.yearintake = c.yearintake;
        obj.month_intake = c.month_intake;
        obj.degree = c.target_department.program_id;
        obj.reg_fee_status =
          c.fin_tr.length > 0
            ? c.fin_tr.find(
                (x) => x.trans_type.includes("regfee") && x.is_active === 1
              )?.status ?? "EMPTY"
            : "EMPTY";

        obj.trans_id_reg_fee =
          c.fin_tr.length > 0 &&
          c.fin_tr.find(
            (x) => x.trans_type.includes("regfee") && x.is_active === 1
          )?.trans_id;

        obj.tuition_status =
          c.fin_tr.length > 0
            ? c.fin_tr.find(
                (x) => x.trans_type.includes("tuitionfee") && x.is_active === 1
              )?.status ?? "EMPTY"
            : "EMPTY";

        obj.trans_id_tuition_fee =
          c.fin_tr.length > 0 &&
          c.fin_tr.find(
            (x) => x.trans_type.includes("tuitionfee") && x.is_active === 1
          )?.trans_id;

        obj.reg_status = c.status;
        obj.fin_tr = c.fin_tr;
        obj.complete = c;
        obj.enroll_id = c.id;
        return obj;
      });

      computedData.sort((a, b) =>
        b.registrationdate > a.registrationdate ? 1 : -1
      );
    }

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) => {
          const value = listData[key];
          return (
            value &&
            value
              .toString()
              .toLowerCase()
              .includes(search)
          );
        });
      });
    }

    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    // console.log("computedData ", computedData);
    // console.log("real data ", results.data);

    const copy = [];
    computedData.forEach((item) => {
      copy.push({
        id: item.enroll_id,
        corporate_id: item.corporate_id,
        term_id: item.term_id,
      });
    });

    setCopyAcadCor(copy);

    console.log("copy ", copy);

    return computedData;
  }, [results.data, search, sorting]);

  const cekActiveButton = (index) => {
    const isIndexMatching = choosenCorporate.index === choosenAcad.index;
    const isCurrentIndex = choosenAcad.index === index;
    const shouldDisable = isIndexMatching && isCurrentIndex && widget;

    return !shouldDisable;
  };

  const HandleSetUpdateCandidate = async (
    enroll_id,
    corporate,
    term_id,
    index,
    type
  ) => {
    setLoadingSetUpdateCorAcad({ loading: index, message: "", data: [] });

    const send = await UPDATE_ENROLLMENT({
      id: enroll_id,
      corporate_id: corporate,
      term_id: term_id,
    });
    console.log(send);
    send.loading = -1;
    setLoadingSetUpdateCorAcad(send);
    if (send.message === "") {
      triggerNotification({
        title: "Success",
        type: "success",
        message: `Success ${type} data`,
        duration: 3000,
      });
      ReloadData();
    } else {
      triggerNotification({
        title: "Failed!",
        type: "danger",
        message: send.message,
        duration: 3000,
      });
    }
  };

  const HandleAcceptCandidate = async (index, enroll_id) => {
    setLoadingAcceptCreateStudent(index);
    try {
      const param = {
        id: enroll_id,
      };

      const acc = await CREATE_STUDENT_FROM_ENROLLPROC(param);

      console.log(acc);
      if (acc.message === "") {
        triggerNotification({
          title: "Success",
          type: "success",
          message: `Success create student`,
          duration: 3000,
        });
        ReloadData();
      } else {
        triggerNotification({
          title: "Failed!",
          type: "danger",
          message: acc.message,
          duration: 3000,
        });
      }
    } catch (error) {
      console.error(error);
      triggerNotification({
        title: "Failed!",
        type: "danger",
        message: error.message,
        duration: 3000,
      });
    } finally {
      setLoadingAcceptCreateStudent(-1);
    }
  };

  return (
    <div>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex">
          <RangeDataCounter
            totalItems={totalItems}
            onClick={(e) => ReloadData(e)}
          />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {results.loading ? (
              <tr>
                <td colSpan={9}>
                  <LoadingSkeleton />
                </td>
              </tr>
            ) : results.message ? (
              <tr>
                <td colSpan={9}>
                  <AlertNotif messages={results.message} color="danger" />
                </td>
              </tr>
            ) : ResultData.length > 0 ? (
              ResultData.map((v, index) => (
                <tr key={index}>
                  <td className="fs-7 fw-bold">{v.name}</td>
                  <td className="fs-7">
                    <span className="d-block fw-bold">{v.reg_id}</span>
                    <BadgeItem
                      icon={"calendar"}
                      name={v.reg_date}
                      title={"Reg Date"}
                    />
                  </td>
                  <td className="fs-7">
                    <span className="d-block fw-bold">{v.major}</span>
                    <BadgeItem
                      icon="time"
                      name={`${MontIntake(v.month_intake)} ${YearIntake(
                        v.yearintake
                      )}`}
                      title="Year Intake"
                      className="me-3"
                    />
                    <BadgeItem
                      icon={"teacher"}
                      name={ProgramDegree(v.degree)}
                      title={"Program Degree"}
                    />
                  </td>
                  <td className="fs-7 fw-bold">{v.enrollment_type}</td>
                  <td>
                    <RegisterStatus data={v.reg_status} />
                  </td>
                  <td style={{ width: v.term_id ? 180 : 180 }}>
                    {v.term_id ? (
                      index === editAcadCorIndex ? (
                        <CorporateEdit
                          index={index}
                          data={{
                            id: copyAcadCor[index]?.corporate_id,
                            index: index,
                          }}
                          setData={setCopyAcadCor}
                        />
                      ) : (
                        <span>{v.corporate_name}</span>
                      )
                    ) : (
                      <Corporate
                        index={index}
                        data={choosenCorporate}
                        setData={setChoosenCorporate}
                      />
                    )}
                  </td>
                  <td style={{ width: v.term_id ? "auto" : 250 }}>
                    {v.term_id ? (
                      index === editAcadCorIndex ? (
                        <div className="d-flex align-items-center">
                          <AcadListEdit
                            index={index}
                            data={{
                              id: copyAcadCor[index]?.term_id,
                              index: index,
                            }}
                            setData={setCopyAcadCor}
                            acadCal={acadCal}
                          />
                        </div>
                      ) : (
                        <span>{v.term_name}</span>
                      )
                    ) : (
                      <div className="d-flex align-items-center">
                        <AcadList
                          index={index}
                          data={choosenAcad}
                          setData={setChoosenAcad}
                          acadCal={acadCal}
                        />
                      </div>
                    )}
                  </td>
                  <td style={{ width: 170 }}>
                    {v.create_student ? (
                      <span className="text-success fw-bold">Accepted</span>
                    ) : (
                      <div className="d-flex">
                        {v.term_id ? (
                          loadingSetUpdateCorAcad.loading === index ? (
                            <div className="ms-5 me-10 d-flex align-items-center">
                              <SpinnerLoad />
                            </div>
                          ) : editAcadCorIndex === index ? (
                            <ButtonSetEdit
                              type="button"
                              title="Update Data"
                              className="btn-sm me-3"
                              onClick={() => {
                                setEditAcadCorIndex(-1);
                                HandleSetUpdateCandidate(
                                  v.enroll_id,
                                  copyAcadCor[index].corporate_id,
                                  copyAcadCor[index].term_id,
                                  index,
                                  "Update"
                                );
                              }}
                            >
                              Update
                            </ButtonSetEdit>
                          ) : (
                            <ButtonSetEdit
                              type="button"
                              title="Edit Corporate & Acad Cal"
                              className="btn-sm me-3"
                              onClick={() => setEditAcadCorIndex(index)}
                            >
                              Edit
                            </ButtonSetEdit>
                          )
                        ) : loadingSetUpdateCorAcad.loading === index ? (
                          <div className="ms-5 me-10 d-flex align-items-center">
                            <SpinnerLoad />
                          </div>
                        ) : (
                          <ButtonSetEdit
                            type="button"
                            title="Set Corporate & Acad Cal"
                            disabled={cekActiveButton(index)}
                            className="btn-sm me-3"
                            onClick={() =>
                              HandleSetUpdateCandidate(
                                v.enroll_id,
                                choosenCorporate.id,
                                choosenAcad.id,
                                index,
                                "Set"
                              )
                            }
                          >
                            Set
                          </ButtonSetEdit>
                        )}
                        {loadingAcceptCreateStudent === index ? (
                          <div className="d-flex align-items-center ps-7">
                            <SpinnerLoad />
                          </div>
                        ) : (
                          <ButtonAccept
                            type="button"
                            title="Accept as Student"
                            disabled={
                              !v.term_id ||
                              loadingSetUpdateCorAcad.loading === index ||
                              editAcadCorIndex === index
                            }
                            className="btn-sm"
                            onClick={() =>
                              HandleAcceptCandidate(index, v.enroll_id)
                            }
                          >
                            Accept
                          </ButtonAccept>
                        )}
                      </div>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={11}>
                  <NoRecordFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
