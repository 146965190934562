import { useCallback, useEffect, useState } from "react";
import Toaster from "./Toaster";

const useToaster = () => {
  const [toaster, setToaster] = useState(null);

  let timer;

  const triggerNotification = useCallback((notificationProps) => {
    clearTimeout(timer);
    setToaster(notificationProps);
    timer = setTimeout(() => {
      setToaster(null);
      if (notificationProps.onFinished) {
        notificationProps.onFinished();
      }
    }, notificationProps.duration);
  }, []);

  const ToasterComponent = toaster ? (
    <div className={`position-fixed end-0`} style={{ top: 180, zIndex: 9999 }}>
      <Toaster {...toaster} />
    </div>
  ) : null;

  return { ToasterComponent, triggerNotification };
};

export default useToaster;
