import React, { useState, useMemo } from 'react'
import { HeaderDatatables, PaginationComponent } from '../../../../../_layouts/components/datatables/MYDatatables';
import { LoadingBars } from '../../../../../_layouts/components/loadbars/LoadingBars';

export default function TableData(props) {
    const {lecturerData, chooseLec} = props;
    const lecturer = lecturerData;
    const listData = lecturerData.data;
    
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const ITEMS_PER_PAGE = 5;

    const headers = [
        { name: "Lecturer", field: "name_related", sortable: true },
        { name: "Add", field: "id", sortable: false },
    ];

    const ResultData = useMemo(() => {
        let computedData = listData;

        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) =>
                    reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        //Current Page slice
        if (computedData.length > 0) {
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        } else {
            return [];
        }
    }, [listData, currentPage, sorting]);

    return (
        <div className='table-responsive'>
            <table className="table table-row-dashed align-middle fs-6 gy-4 my-0 pb-3 dataTable no-footer">
                <HeaderDatatables
                    headers={headers}
                    onSorting={(field, order) =>
                        setSorting({ field, order })
                    }
                />
                <tbody>
                    {(lecturer.loading) ? (
                        <tr>
                            <td colSpan={4}>
                                <LoadingBars />
                            </td>
                        </tr>
                    ) : (
                        (lecturer.message) ? (
                            <tr>
                                <td colSpan={4}>
                                    <span className="text-danger">{lecturer.message}</span>
                                </td>
                            </tr>
                        ) : (
                            (ResultData.length > 0) ? (
                                ResultData.map((listData, index) => {
                                    return (
                                        <tr key={index}>
                                            <td>
                                                <span className="d-block text-dark fw-bolder">{listData.name_related}</span>
                                                <span className="d-block text-muted">{listData.sgumail}</span>
                                            </td>
                                            <td>
                                                <button className="btn btn-icon btn-light-primary btn-sm" type='button' title='add this subject' onClick={(e) => chooseLec(e, listData)}>
                                                    <i className="bi bi-plus-circle"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr><td colSpan="4">No record found</td></tr>
                            )
                        )
                    )}
                </tbody>
                <tfoot>
                    {(totalItems > 0) ? (
                        <tr>
                            <td colSpan={4}>
                                <div className="footer">
                                    <span className='d-block mb-5'><i className="fas fa-poll"></i> Total item {(totalItems > 0) ? totalItems : 0}</span>
                                    <PaginationComponent
                                        total={totalItems}
                                        itemsPerPage={ITEMS_PER_PAGE}
                                        currentPage={currentPage}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </div>
                            </td>
                        </tr>
                    ) : ''}
                </tfoot>
            </table>
        </div>
    )
}
