export const GET_PROPROF_RESULT = async (data) => {
  try {
    const param = {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };
    const url = `${process.env.REACT_APP_PROPROFS_URL}/api/classroom/v1/reports/users/`;

    const result = await fetch(url, param);

    // Periksa apakah respons berhasil
    if (!result.ok) {
      throw new Error(`HTTP error! status: ${result.status}`);
    }

    const responseData = await result.json();
    return responseData;
  } catch (error) {
    console.error("Fetch error: ", error);
    throw error; // Lempar ulang error agar bisa ditangani di tempat lain
  }
};
