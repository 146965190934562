import React, { useEffect, useState } from "react";
import { READ_FILE } from "../../apis/module/JupiterRequest";
import { LoadingBars } from "../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";

export default function TDetailFile({ data }) {
  const uniqid = data.public_url;
  const arrUniqID = uniqid.split("s/");
  const fileID = arrUniqID[1];

  const [file, setFile] = useState({ loading: false, data: [], message: "" });
  const LOAD_FILE = async () => {
    setFile({ loading: true, data: [], message: "" });

    const result = await READ_FILE(`${fileID}?x=1920&y=1080`);
    setFile(result);
  };

  useEffect(()=>{
    LOAD_FILE();
  },[])
  return (
    <div>
      <h1 className="text-capitalize">{data.file_type}</h1>
      <p className="mb-0">File Name: {data.filename}</p>
      <p>File Size: {data.size} KB</p>
      {file.loading ? <LoadingBars /> :
      file.message ? <AlertNotif messages={file.message} /> :
      Object.values(file.data).length > 0 && (
        <div className="text-center cursor-pointer" onClick={()=>window.open(file.data,"_blank").focus()}>
            <img src={file.data} alt={data.filename} id="img-load" className="w-100 rounded" />
        </div>
      )}
    </div>
  );
}
