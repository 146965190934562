import React from "react";
import MSchool from "./schools";
import MUniversity from "./university";
import { useOutletContext } from "react-router-dom";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";

export function SchoolManagement() {
  const context = useOutletContext();
  const widget_98 = context.widgets.find(
    (item) => item.widget_id === 98 && item.is_enabled === 1
  );
  return widget_98 ? (
    <div className="row">
      <div className="col-lg-6">
        <MSchool />
      </div>
      <div className="col-lg-6">
        <MUniversity />
      </div>
    </div>
  ) : (
    <AlertNotif messages={AccessInvalid} />
  );
}
