import { useDropzone } from "react-dropzone";
import { KTIcon } from "../../../../../_metronic/helpers";
import { useCallback, useState } from "react";
import { ButtonIcon } from "../../../../../_layouts/components/buttons/ButtonUI";
import { OverlayTrigger, Popover, ProgressBar } from "react-bootstrap";
import { ConvertBytes } from "../../../../../_layouts/_helpers/Global";

const InputFileUpload = ({ setData }) => {
  const [error, setError] = useState(null);

  const onDrop = useCallback(
    (acceptedFiles) => {
      const validFiles = acceptedFiles.filter((file) => {
        const isValidType = [
          "image/jpeg",
          "image/png",
          "image/jpg",
          "application/pdf",
        ].includes(file.type);

        const isValidSize = file.size <= 2 * 1024 * 1024; // 2MB

        if (!isValidType) {
          setError(
            `${file.name} is not a valid file type. Only jpg, png, jpeg, and pdf are allowed.`
          );
          return false;
        } else if (!isValidSize) {
          setError(`${file.name} is too large. Maximum file size is 2MB.`);
          return false;
        }

        return true;
      });

      if (validFiles.length > 0) {
        setData((prevFiles) => [...prevFiles, ...validFiles]);
        setError(null);
      }
    },
    [setData]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [".jpeg", ".jpg"],
      "image/png": [".png"],
      "application/pdf": [".pdf"],
    },
    maxSize: 2 * 1024 * 1024, // 2MB
  });

  return (
    <div id="form-upload">
      <div className="form-group mb-5">
        <label className="fw-bold required">Choose File:</label>
        <div {...getRootProps()} className="dropzone mt-2">
          <input {...getInputProps()} />
          <div className="d-flex align-items-center justify-content-center py-8">
            <KTIcon iconName="folder-up" className="fs-4x" />
            <div className="text-dark text-start ms-2">
              {isDragActive ? (
                <span className="d-block fw-bold fs-4">
                  Drop the files here...
                </span>
              ) : (
                <>
                  <span className="d-block fw-bold fs-4">
                    Drop anywhere to upload
                  </span>
                  <span className="text-muted fs-7">
                    or select <span className="text-primary">files</span>
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      {error && <div className="text-danger">{error}</div>}

      <div className="notes fs-8">
        <span className="d-block fw-bold">Notes:</span>
        <ul>
          <li>Only jpg, png, jpeg and pdf files are allowed.</li>
          <li>Maximum file size is 2MB.</li>
        </ul>
      </div>
    </div>
  );
};

const RenderFileUpload = ({ files, setFiles, uploadProgress }) => {
  const handleDeleteFile = (fileToDelete) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file !== fileToDelete));
  };
  const isImageFile = (file) => {
    return file.type.startsWith("image/");
  };

  return (
    files.length > 0 && (
      <div className="form-group mb-5">
        <label className="fw-bold">Attachments:</label>
        <div className="dropzone dropzone-queue">
          {files.map((file, index) => (
            <div className="dropzone-items wm-200px" key={index}>
              <div className="dropzone-item dz-image-preview">
                <div className="dropzone-file">
                  <div className="dropzone-filename text-gray-900">
                    <span className="fw-bold me-2">{file.name}</span>
                    <span className="fw-bold">({ConvertBytes(file.size)})</span>
                  </div>
                </div>

                <div className="dropzone-progress">
                  <ProgressBar
                    striped
                    animated
                    variant="success"
                    now={uploadProgress[file.name] || 0}
                    max="100"
                  />
                </div>

                {!uploadProgress[file.name] ? (
                <div className="dropzone-toolbar">
                  {isImageFile(file) ? (
                    <BtnPrevFile file={file} />
                  ) : (
                    <button
                      type="button"
                      className="btn btn-icon btn-sm"
                      onClick={() =>
                        window.open(URL.createObjectURL(file), "_blank")
                      }
                    >
                      <KTIcon iconName="document" className="fs-1" />
                    </button>
                  )}
                  <ButtonIcon
                    type="button"
                    className="btn-sm"
                    onClick={() => handleDeleteFile(file)}
                  >
                    <KTIcon
                      iconName="cross-circle"
                      className="fs-1 text-danger"
                    />
                  </ButtonIcon>
                </div>
                ) : (
                  <div className="dropzone-toolbar">
                    <span className="fw-bold text-success fs-7">{uploadProgress[file.name] || 0}%</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    )
  );
};

const BtnPrevFile = ({ file }) => {
  const [previewImageUrl, setPreviewImageUrl] = useState(null);

  const handlePreviewClick = useCallback(() => {
    setPreviewImageUrl(URL.createObjectURL(file));
  }, [file]);

  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      rootClose
      placement="left"
      trigger="click"
      overlay={
        <Popover className="p-3">
          <Popover.Body className="p-0">
            <PrevFile image={previewImageUrl} />
          </Popover.Body>
        </Popover>
      }
      onToggle={(nextShow) => {
        if (nextShow) {
          handlePreviewClick();
        }
      }}
    >
      <button
        type="button"
        className="btn btn-icon btn-sm"
        onClick={(e) => handlePreviewClick(e, file)}
      >
        <KTIcon iconName="picture" className="fs-1" />
      </button>
    </OverlayTrigger>
  );
};

const PrevFile = ({ image }) => {
  return (
    <div className="bg-white rounded">
      <img
        src={image}
        alt="prev-file"
        style={{ maxWidth: "200px", maxHeight: "200px" }}
      />
    </div>
  );
};

export { InputFileUpload, RenderFileUpload, BtnPrevFile };
