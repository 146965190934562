import { OverlayTrigger } from "react-bootstrap"
import { renderPopOver } from "../../../../../_layouts/components/popovers/PopOvers"
import moment from "moment"
import { ConsentEvent } from "../../components/CalendarAtributes"
import { MessageError, openModal } from "../../../../../_layouts/components/modals/Modals"
import { RequiredMsg } from "../../../../../_layouts/_core/ErrorMessage"

const RoomOccupanceUI = ({ data, postParam, GetScheduleTimetable }) => {
    return (
        <div className="row">
            {data.map((v, index) => (
                <div key={index} className="col-6 col-lg-3 col-xxl-3 my-3">
                    {(v.occupancy) ? (
                        (Object.values(v.occupancy).length > 0) ?
                            <RoomOccupied v={v} /> : <RoomAvailable v={v} postParam={postParam} GetScheduleTimetable={GetScheduleTimetable} />
                    ) : <RoomDisabled v={v} />}
                </div>
            ))}
        </div>
    )
}

const RoomDisabled = ({ v }) => {
    return (
        <div className="border p-5 h-100 rounded bg-white border-secondary" >
            <p className="mb-0">
                <span className="d-block fw-bolder fs-4">
                    Room {v.g_resourceName}
                </span>
                <span className="d-block text-muted fs-5">
                    <span className="">
                        Capacity: {v.g_capacity ? v.g_capacity : 0}
                    </span>
                </span>
            </p>
        </div>
    )
}

const RoomAvailable = ({ v, postParam, GetScheduleTimetable }) => {
    const createNewClass = () => {
        if (v.id && postParam.start_dt) {
            const selectedDate = moment(postParam.start_dt).toDate();
            openModal({ message: <ConsentEvent selectedDate={selectedDate} ReloadData={GetScheduleTimetable} iscustom={true} roomSelected={{ value: v.id, label: "" }} room_id={v.id} /> })
        } else {
            openModal({ message: <MessageError message={RequiredMsg} /> })
        }
    }

    return (
        <div className="border p-5 h-100 rounded bg-success bg-gradient cursor-pointer" onClick={()=>createNewClass()} >
            <p className="mb-0">
                <span className="d-block fw-bolder fs-4 text-white">
                    Room {v.g_resourceName}
                </span>
                <span className="d-block  text-white fs-5">
                    <span className="">
                        Capacity: {v.g_capacity ? v.g_capacity : 0}
                    </span>
                </span>
            </p>
        </div>
    )
}

const RoomOccupied = ({ v }) => {
    return (
        <OverlayTrigger
            delay={{ show: 250, hide: 400 }}
            overlay={renderPopOver({
                header: (
                    <h4 className="text-danger mb-0">Classes in Room {v.g_resourceName}</h4>
                ),
                body: <ListRoomOccupied values={v.occupancy} />,
            })}
        >
            <div className="border p-5 h-100 rounded bg-danger bg-gradient cursor-pointer" >
                <p className="mb-0">
                    <span className="d-block fw-bolder fs-4 text-white">
                        Room {v.g_resourceName}
                    </span>
                    <span className="d-block text-white fs-5">
                        <span className="">
                            Capacity: {v.g_capacity ? v.g_capacity : 0}
                        </span>
                    </span>

                    <span className="text-white fw-bolder">
                        {Object.values(v.occupancy).length}{" "}
                        {Object.values(v.occupancy).length > 1
                            ? "classes are"
                            : "class is"}{" "}
                        assigned
                    </span>
                </p>
            </div>
        </OverlayTrigger>
    )
}

const ListRoomOccupied = ({ values }) => {
    values.sort((a, b) => (a.start_dt > b.start_dt ? 1 : -1));
    return (
        <div className="list-class">
            <ul>
                {values.map((v, index) => (
                    <li key={index}>
                        {v.section_id ? (
                            <>
                                <span className="d-block fw-bolder">
                                    {v.coursetitle + " (" + v.course_credit + "sks)"}
                                </span>
                                <span className="d-block">Section ID {v.section_id}</span>
                            </>
                        ) : (
                            <span className="d-block fw-bolder">
                                {v.coursetitle}
                            </span>
                        )}

                        <span className="d-block">
                            {moment(v.start_dt).format("DD MMM YYYY")}
                        </span>
                        <span className="">
                            {moment(v.start_dt).format("hh:mm A")}-
                            {moment(v.end_dt).format("hh:mm A")}
                        </span>
                    </li>
                ))}
            </ul>
        </div>
    );
}


export { RoomOccupanceUI }