import React, { useEffect, useState } from 'react'
import { GET_ACAD_MSTONE_BY_ACAD } from '../../../apis/node-target-data/AcademicApi';
import { ImDtCalendarWeeks } from './ImDtCalendarWeeks';
import { SGUCard } from '../../../../../_layouts/components/cards/CardUI';
import { ConvertSchdItem } from './components/CalendarCompImDt';
import { LoadingSkeleton } from '../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertRetry } from '../../../../../_layouts/components/alerts/AlertsUI';
import { ImDtCalendarMonth } from './ImDtCalendarMonth';
import { addStatusCallMonth } from '../../academic-milestone/helpers';
import { ImDtCalendarTables } from './ImDtCalendarTables';
import { ButtonIcon } from '../../../../../_layouts/components/buttons/ButtonUI';

export default function CalendarImportanceDate() {

    const [typeCal, setTypeCal] = useState(3);

    const [results, setResults] = useState({
        loading: false,
        data: [],
        message: "",
    });

    const [result_table_view, setTableView] = useState([]);

    const ReloadData = async () => {
        setTableView([]);
        setResults({ loading: true, data: [], message: "" });
        const param = { view_all: 1, is_active:1 };
        const result = await GET_ACAD_MSTONE_BY_ACAD(param);

        if (result.data && Object.values(result.data).length > 0) {
            const w_status = addStatusCallMonth(result.data);
            const processedData = filterAndDeduplicateID(w_status);
            const schd_items = ConvertSchdItem(processedData);
            setTableView(processedData);
            setResults({ loading: false, message: "", data: schd_items });
        } else {
            setTableView([]);
            setResults(result);
        }
    }

    useEffect(() => {
        ReloadData();
    }, [])

    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bolder text-dark">
                    Importance Date Schedule
                </span>
                <span className="text-muted fs-8">by current active calendar</span>
            </SGUCard.Header>
            <SGUCard.Toolbar>
                <div className="d-flex align-items-center">
                    <select name="type_cal" className="form-select form-select-sm w-100px" defaultValue={typeCal} onChange={(e) => setTypeCal(parseInt(e.target.value))}>
                        <option value="1">Month</option>
                        <option value="3">Tables</option>
                    </select>
                    <ButtonIcon type="button" className="btn-sm btn-light-primary ms-1" title="Refresh" onClick={() => ReloadData()}>
                        <i className="bi bi-arrow-clockwise"></i>
                    </ButtonIcon>
                </div>
            </SGUCard.Toolbar>
            <SGUCard.Body className={"px-0 pb-0"}>
                {results.loading ? <LoadingSkeleton /> :
                    results.message ? <AlertRetry messages={results.message} Reload={ReloadData} /> : (
                        typeCal === 1 ? <ImDtCalendarMonth data={results.data} /> : (
                            typeCal === 2 ? <ImDtCalendarWeeks data={results.data} /> : <ImDtCalendarTables data={result_table_view} />
                        )
                    )}
            </SGUCard.Body>
        </SGUCard>
    )
}


const filterAndDeduplicate = (data) => {
    // Filter hanya status_id 1 dan 2
    const filteredData = data.filter(item => item.status_id === 2);

    // Menghilangkan duplikat berdasarkan mstone_type_id dan atp_id
    const uniqueData = filteredData.reduce((acc, current) => {
        const key = `${current.mstone_type_id}-${current.atp_id}`;
        const existingItem = acc[key];

        if (!existingItem || new Date(current.end_dt) > new Date(existingItem.end_dt)) {
            acc[key] = current;
        }

        return acc;
    }, {});

    // Mengembalikan array dari objek yang telah dideduplikasi
    return Object.values(uniqueData);
};

const filterAndDeduplicateID = (data) => {
    // Filter hanya status_id 1 dan 2
    const filteredData = data.filter(item => item.status_id !== 1);

    // Menghilangkan duplikat berdasarkan mstone_type_id dan atp_id
    const uniqueData = filteredData.reduce((acc, current) => {
        const key = `${current.mstone_type_id}-${current.atp_id}`;
        const existingItem = acc[key];

        if (!existingItem || current.id > existingItem.id) {
            acc[key] = current;
        }

        return acc;
    }, {});

    // Mengembalikan array dari objek yang telah dideduplikasi
    return Object.values(uniqueData);
};