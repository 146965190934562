import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SGUCard } from '../../../../../../_layouts/components/cards/CardUI'
import { CandidateSelect, InputTextUniv, InputTextUnivProdi } from '../../components/TCUI'
import { GET_ENROLLMENT_PROCESS } from '../../../../apis/node-target-data/MarketingAPI';
import { LoadingBars } from '../../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../../../_layouts/components/alerts/AlertsUI';
import { useLocation, useOutletContext } from 'react-router-dom';
import { KTIcon } from '../../../../../../_metronic/helpers';
import { GET_STUDENT, GET_STUDENT_DATA_M, GET_TRANSFER_PROPOSAL } from '../../../../apis/node-target-data/AcademicApi';
import { ButtonIcon } from '../../../../../../_layouts/components/buttons/ButtonUI';
import { handleKeyDown } from '../../../../components/forms/InputText';
import { CapitalizeFirstLetter } from '../../../../../../_layouts/_helpers/Global';

export default function CandidateData({ isUpdate, cadidates, setDcandidate, setCandidates, univ, setUniv, setUnivProdi, target_dept, setTargetDept, doc_date, setDoc_date, univLastSemes, setUnivLastSemes, setIsExistStd, objIns, setObjIns }) {
    const context = useOutletContext();
    const widget_111 = context.widgets.find(
        (item) => item.widget_id === 111 && item.is_enabled === 1
    );

    const [candidate, setCandidate] = useState({ loading: false, message: "", data: [] });

    const IntoEditable = "The previous institution can be updated by admissions based on the master institution data";

    const GetDetailStudent = async (param) => {
        const result_student = await GET_STUDENT(param);
        const result_student_bio = await GET_STUDENT_DATA_M(param);
        if ((result_student_bio.data && Object.values(result_student_bio.data).length > 0) && (result_student.data && result_student.data.length > 0)) {
            const student_data = result_student.data[0];
            const student_bio_data = result_student_bio.data;
            const combined_student_data = {
                ...student_bio_data,
                ...student_data
            };
            const reff_ins = student_bio_data.reff_ins ? student_bio_data.reff_ins : [];
            if (Object.values(reff_ins).length > 0) {
                const current_ins = reff_ins[0];
                let univ_name_label = current_ins?.university?.name || "", univ_prodi_label = current_ins?.university_prodi?.name || "", univ_last_semes_label = current_ins?.lastsemester || "";
                const inst_selected = {
                    univ_name: univ_name_label,
                    univ_prodi: univ_prodi_label,
                    univ_last_semes: univ_last_semes_label
                };
                setObjIns(inst_selected);
                setUniv(current_ins?.university_id || 0);
                setUnivProdi(current_ins?.university_prodi_id || 0);
                setUnivLastSemes(univ_last_semes_label);
            } else {
                setObjIns({
                    univ_name: "",
                    univ_prodi: "",
                    univ_last_semes: ""
                });
                setUniv(0);
                setUnivProdi(0);
                setUnivLastSemes("");
            }

            setDcandidate(combined_student_data);
            setCandidate({ loading: false, message: "", data: student_data });
            setTargetDept(student_data?.clu?.name);
            setIsExistStd(true);
        } else {
            setDcandidate("");
            setCandidate("");
            setIsExistStd(false);
            setTargetDept(target_dept);
            setUniv(0);
            setUnivProdi(0);
            setUnivLastSemes("");
        }
    }

    const GetDetailCandidate = async () => {
        setCandidate({ loading: true, message: "", data: [] }); setTargetDept("");
        const param = { candidate_id: cadidates };
        const result = await GET_ENROLLMENT_PROCESS(param);
        if (result.data && Object.values(result.data).length > 0) {
            const only_transfer = result.data.filter(
                (v) =>
                    v.enrollment_type_id === "mkt.enrollment.type.cooperation" ||
                    v.enrollment_type_id === "mkt.enrollment.type.transfer.in.external" ||
                    v.enrollment_type_id === "mkt.enrollment.type.learingprogram"
            );

            const get_data = only_transfer.reduce((acc, current) => {
                if (!acc[current.candidate_id] || current.id > acc[current.candidate_id].id) {
                    acc[current.candidate_id] = current;
                }
                return acc;
            }, {});
            //const get_cnv_data_array = Object.values(get_data);
            const get_last_data = get_data[cadidates];
            setDcandidate(get_last_data);
            setCandidate({ loading: false, message: "", data: get_last_data });

            if (get_last_data.target_department) {
                setTargetDept(get_last_data.target_department?.name || "");
            }
            let univ_name_label = "", univ_prodi_label = "", univ_last_semes_label = get_last_data?.regstudent?.university_last_semester || "";
            if (get_last_data.regstudent && get_last_data.regstudent.university_id) {
                if (get_last_data.regstudent.university) {
                    univ_name_label = get_last_data.regstudent.university;
                } else {
                    univ_name_label = get_last_data.regstudent?.university_obj?.name || "";
                }

                if (get_last_data.regstudent.university_prodi) {
                    univ_prodi_label = get_last_data.regstudent.university_prodi;
                } else {
                    univ_prodi_label = get_last_data.regstudent?.university_prodi_obj?.name || "";
                }
            }

            const inst_selected = {
                univ_name: univ_name_label,
                univ_prodi: univ_prodi_label,
                univ_last_semes: univ_last_semes_label
            };
            setObjIns(inst_selected);
            setUnivLastSemes(univ_last_semes_label);
            setIsExistStd(false);
            setUniv(get_last_data.regstudent.university_id)
            setUnivProdi(get_last_data.regstudent.university_prodi_id);
        } else {
            if (widget_111 && cadidates) {
                const param_student = { studentid: cadidates };
                GetDetailStudent(param_student);
            } else {
                setDcandidate("");
            }
            setCandidate(result);
        }
    }

    useEffect(() => {
        if (cadidates) {
            GetDetailCandidate();
        }
    }, [cadidates]);

    return (
        <SGUCard>
            <SGUCard.Body className={"pb-0 pt-8"}>
                <div className="row">
                    <div className="col-lg-4">
                        <CandidateSelect disabled={isUpdate} data={cadidates} setData={setCandidates} required />
                    </div>
                    {target_dept && (
                        <div className="col-lg-4">
                            <div className="form-group">
                                <label className="fw-bold">Target Department</label>
                                <div className="form-control border-0 ps-0">{target_dept}</div>
                            </div>
                        </div>
                    )}
                    <div className="col-auto">
                        <div className="form-group">
                            <label className="fw-bold required">Date of Matriculation</label>
                            <div className="position-relative">
                                <DatePicker className="form-control form-control-sm" dateFormat={"yyyy-MM-dd"}
                                    minDate={doc_date}
                                    placeholderText='Enter date' selected={doc_date} onChange={(date) => setDoc_date(date)}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }} />
                                <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                                    <KTIcon iconName='calendar' className='fs-1 text-dark' />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {(cadidates && cadidates.length > 0) && (
                    <div className="row">
                        <div className="col-12">
                            <h4 className='mb-0 d-flex align-items-center'>
                                <span>Previous Institutions</span>
                                <ButtonIcon className={"btn-sm p-0"} type="button" title={IntoEditable}>
                                    <KTIcon iconName='information-3' className='fs-1x text-info' />
                                </ButtonIcon>
                            </h4>
                        </div>
                        {candidate.loading ? <div className="col-auto"><LoadingBars /></div> :
                            candidate.message ? <div className="col-auto"><AlertNotif messages={candidate.message} /></div> : (
                                <FormInst objIns={objIns} setObjIns={setObjIns} univ={univ} setUniv={setUniv} setUnivProdi={setUnivProdi} univLastSemes={univLastSemes} setUnivLastSemes={setUnivLastSemes} />
                            )}
                    </div>
                )}
            </SGUCard.Body>
        </SGUCard>
    )
}

const FormInst = (props) => {
    const { objIns, setObjIns, univ, setUniv, setUnivProdi, univLastSemes, setUnivLastSemes } = props;
    const HandlerChangeUniv = (data) => {
        setObjIns(prev => ({ ...prev, univ_name: CapitalizeFirstLetter(data.label) }));
        setUniv(data.value);
    }

    const HandlerChangeUnivProdi = (data) => {
        setObjIns(prev => ({ ...prev, univ_prodi: CapitalizeFirstLetter(data.label) }));
        setUnivProdi(data.value);
    }

    const HandlerChangeUnivSemes = (value) => {
        setObjIns(prev => ({ ...prev, univ_last_semes: value }));
        setUnivLastSemes(value)
    }

    return (
        <div className='row'>
            <div className="col-lg-4">
                <InputTextUniv selected={objIns.univ_name} HandlerChangeUniv={HandlerChangeUniv} />
            </div>
            <div className="col-lg-4">
                <InputTextUnivProdi selected={objIns.univ_prodi} HandlerChangeUnivProdi={HandlerChangeUnivProdi} />
            </div>
            <div className="col-lg-auto">
                <div className="form-group">
                    <label className="required fw-bold">Semester Passed</label>
                    <input type="text" className="form-control" defaultValue={univLastSemes} onKeyDown={handleKeyDown} onChange={(e) => HandlerChangeUnivSemes(parseInt(e.target.value))} />
                </div>
            </div>
        </div>
    )
}