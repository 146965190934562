import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react'
import { GET_SCHD } from '../../apis/node-target-data/AcademicApi';
import { ConvertEventItem } from '../components/HelperCalendar';
import { CalendarNotes, CalendarPrevNext, ConsentEvent } from '../components/CalendarAtributes';
import { SGUCard } from '../../../../_layouts/components/cards/CardUI';
import { FullCalendarWeek } from '../components';
import { openModal } from '../../../../_layouts/components/modals/Modals';
import { RemoveEmptyValue } from '../../../../_layouts/_helpers/Global';
import { LoadingSkeleton } from '../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';

export default function CalendarWeek({ param }) {
    const elementRef = useRef(null);
    const [results, setResults] = useState({
        loading: false,
        data: [],
        message: "",
    });
    const monday = moment().startOf("weeks").format("YYYY-MM-DD");
    const sunday = moment().endOf("weeks").format("YYYY-MM-DD");

    const [postParam, setPostParam] = useState({ grouped: "True", start_dt: monday, end_dt: sunday });

    useEffect(() => {
        GetScheduleTimetable();
    }, [param, postParam])

    const GetScheduleTimetable = async () => {
        setResults({ loading: true, message: "", data: [] });

        const param_data = RemoveEmptyValue(param);
        let param_clone = { ...postParam };
        Object.keys(param_data).map(v => {
            param_clone[v] = param_data[v];
        })
        const result = await GET_SCHD(param_clone);
        if (result.data && Object.values(result.data).length > 0) {
            const data_result = ConvertEventItem(result.data);
            setResults({ loading: false, message: "", data: data_result });
        } else {
            setResults({ loading: false, message: result.message, data: [] });
        }
    };

    const createNewClass = (data) => {
        const selectedDate = moment(data.dateStr).toDate();
        openModal({ message: <ConsentEvent selectedDate={selectedDate} ReloadData={GetScheduleTimetable} iscustom={true} /> })
    }

    return (
        <div>
            {results.loading ? <LoadingSkeleton /> :
                results.message ? <AlertNotif messages={results.message} /> : (
                    <>
                        <div className="d-flex justify-content-between align-items-center mb-5">
                            <CalendarNotes />
                            <CalendarPrevNext start_dt={postParam.start_dt} setPostParam={setPostParam} schd={true} />
                        </div>
                        <SGUCard>
                            <SGUCard.Body className={"p-0"}>
                                <div className="calendar-week" ref={elementRef}>
                                    <FullCalendarWeek data={results.data} startDT={postParam.start_dt} endDT={postParam.end_dt} createNewClass={createNewClass} />
                                </div>
                            </SGUCard.Body>
                        </SGUCard>
                    </>
                )}
        </div>
    )
}
