import React, { useEffect, useState } from "react";
import { FilterCalendar } from "../components";
import { GET_ACTIVE_ACAD } from "../../apis/node-target-data/AcademicApi";
import { GET_SYS_PARAM } from "../../apis/module/BackendRequest";
import { CleansingJSON } from "../../../../_layouts/_helpers/Global";
import { AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import { LoadingBars } from "../../../../_layouts/components/loadbars/LoadingBars";
import { MessageError, openModal } from "../../../../_layouts/components/modals/Modals";
import { RequiredMsg } from "../../../../_layouts/_core/ErrorMessage";
import moment from "moment";

export default function Filter({ isload, setPostParam, setStudentRep }) {
  const [stdRepresentative, setStdRepresentative] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const ReloadData = async () => {
    setStdRepresentative({ loading: true, data: [], message: "" });
    setStudentRep([]);
    const result_acad = await GET_ACTIVE_ACAD();
    if (result_acad.data && Object.values(result_acad.data).length > 0) {
      const selectedTerm = result_acad.data[0].atp_term_next
        ? result_acad.data[0].atp_term_next.atp_type
        : "";
      if (selectedTerm) {
        const termArr = selectedTerm.split(".");
        const typeName = termArr[
          Object.values(termArr).length - 1
        ].toLowerCase();

        const param = { tipe: "std-representative", name: typeName };
        const result = await GET_SYS_PARAM(param);

        if (result.data && Object.values(result.data).length > 0) {
          const data_std = CleansingJSON(result.data);
          setStdRepresentative({ loading: false, data: data_std, message: "" });
          setStudentRep(data_std)
        } else {
          setStdRepresentative({
            loading: false,
            data: [],
            message: "Failed to retrieve the student representative.",
          });
          setStudentRep([]);
        }
      } else {
        setStdRepresentative({
          loading: false,
          data: [],
          message: "Failed to retrieve the current active calendar.",
        });
        setStudentRep([]);
      }
    } else {
      setStdRepresentative(result_acad);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const [paramPost, setParamPost] = useState({ semester: 0, std: "" });
  const [department, setDepartment] = useState([]);

  const HandlerUpdate = (value) => {
    setDepartment([]);
    if (value) {
      const get_department = stdRepresentative.data.find(item => item.semes === value);
      if (Object.values(get_department.dept).length > 0) {
        setDepartment(get_department.dept);
        setParamPost({semester:value, std:""})
      }
    } else {
      setDepartment([]);
      setParamPost({ semester: 0, std: "" });
    }
  }

  const HandlerFilter = (e) => {
    e.preventDefault();
    if (paramPost.semester && paramPost.std) {
      const monday = moment().startOf("weeks").format("YYYY-MM-DD");
      const sunday = moment().endOf("weeks").format("YYYY-MM-DD");
      setPostParam(prev => {
        const data = {...prev};
        data.studentid = paramPost.std;
        data.tgl = monday;
        data.tglend = sunday;
        return data;
      })
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  }

  return (
    <FilterCalendar onSubmit={HandlerFilter} isload={isload}>
      {stdRepresentative.loading ? (
        <LoadingBars />
      ) : stdRepresentative.message ? (
        <AlertRetry messages={stdRepresentative.message} Reload={ReloadData} />
      ) : (
        Object.values(stdRepresentative.data).length && (
          <div className="d-flex justify-content-start align-items-center">
            <div className="filter-item">
              <select name="semester" required className="form-select form-select-sm" defaultValue={paramPost.semester} onChange={(e) => HandlerUpdate(parseInt(e.target.value))}>
                <option value="0">Choose Semester</option>
                {stdRepresentative.data.map((s, index) => (
                  <option key={index} value={s.semes}>
                    Semester {s.semes}
                  </option>
                ))}
              </select>
            </div>
            <div className="filter-item mx-2">
              <select name="studentid" required className="form-select form-select-sm" value={paramPost.std} onChange={(e) => setParamPost({ ...paramPost, std: e.target.value })}>
                <option value="">Choose Department</option>
                {department.map((v, index) => (
                  <option value={v.stdid} key={index}>{v.code}</option>
                ))}
              </select>
            </div>
          </div>
        )
      )}
    </FilterCalendar>
  );
}
