import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import PackageJSON from "../../../../../package.json";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import FilterUI from "../../components/filters/FilterUI";
import { GET_ATD_STUDENT_BY_ATP } from "../../apis/node-target-data/AcademicApi";
import {
  CourseLookUp,
  handleKeyDown,
  SectionID,
  StudentID,
  StudentName,
} from "../../components/forms/InputText";
import { AcadTerm, Department } from "../../components/forms/SelectBox";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import { LoadingSkeleton } from "../../../../_layouts/components/loadbars/LoadingBars";
import TableData from "./TableData";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";

export function AttendanceStd() {
  const context = useOutletContext();
  const widget_30 = context.widgets.find(
    (item) => item.widget_id === 30 && item.is_enabled === 1
  );

  const [atpid, setAtpid] = useState(PackageJSON.system_param.ActiveAcad);
  const [studentdept, setStudentdept] = useState("");
  const [atd, setAtd] = useState("");
  const [section_id, setSection_id] = useState("");
  const [course, setCourse] = useState("");
  const [course_id, setCourseID] = useState(0);
  const [studentid, setStudentId] = useState("");
  const [studentname, setStudentname] = useState("");
  const [offset, setOffset] = useState(1);
  const limit = 10;

  const postParam = {
    atpid,
    studentdept,
    atd,
    course_id,
    studentid,
    section_id,
    studentname,
  };
  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const ReloadData = async (postParam) => {
    setResults({ loading: true, data: [], message: "" });

    const param = RemoveEmptyValue(postParam);
    const result = await GET_ATD_STUDENT_BY_ATP(param);
    setResults(result);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const SubmitFilter = (e) => {
    e.preventDefault();
    if (atpid) {
      setOffset(1);
      let param = { ...postParam };
      param.offset = 1;
      param.limit = 10;
      ReloadData(param);
    } else {
      openModal({
        message: <MessageError message={RequiredMsg} />,
      });
    }
  };

  const HandlerPage = (type) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let current_offset = offset;
    if (type == 1) {
      current_offset = offset - 1;
    } else {
      current_offset = offset + 1;
    }
    setOffset(current_offset);
    const param = { ...postParam };
    param.offset = current_offset;
    param.limit = 10;
    ReloadData(param);
  };

  return widget_30 ? (
    <div id="approve-lecturer">
      <div className="row">
        <div className="col-lg-3 mb-5">
          <FilterUI title="Attendance" onSubmit={SubmitFilter} isload={results.loading}>
            <AcadTerm data={atpid} setData={setAtpid} />
            <StudentName data={studentname} setData={setStudentname} />
            <StudentID data={studentid} setData={setStudentId} />
            <Department data={studentdept} setData={setStudentdept} />
            <SectionID data={section_id} setData={setSection_id} />
            <CourseLookUp
              course={course}
              setCourse={setCourse}
              setCourseID={setCourseID}
            />

            <div className="form-group mb-5 w-150px">
              <label className="fw-bold">Attendance</label>
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  onKeyDown={handleKeyDown}
                  defaultValue={atd}
                  onChange={(e) => setAtd(e.target.value ? parseFloat(e.target.value) : 0)}
                />
                <span className="input-group-text">%</span>
              </div>
            </div>
          </FilterUI>
        </div>
        <div className="col-lg-9 mb-5">
          <SGUCard>
            <SGUCard.Header>
              <span className="card-label fw-bolder text-dark">
                Browse Attendance Student
              </span>
              <span className="text-muted mt-1 fw-bold fs-7">
                List of attendance by filter
              </span>
            </SGUCard.Header>
            <SGUCard.Body>
              {results.loading ? (
                <LoadingSkeleton />
              ) : results.message ? (
                <AlertNotif messages={results.message} />
              ) : (
                <div className="d-flex flex-column justify-content-between h-100">
                  <div className="w-100">
                    <TableData results={results} ReloadData={SubmitFilter} offset={offset} />
                  </div>
                  <div className="w-100">
                    {Object.values(results.data).length > 0 ? (
                      <div className="footer">
                        <ul className="pagination">
                          <li
                            className={
                              "page-item " +
                              (offset !== 1 ? "cursor-pointer" : "disabled")
                            }
                          >
                            <span
                              className="page-link"
                              onClick={() => HandlerPage(1)}
                            >
                              <span>‹ Prev</span>
                            </span>
                          </li>
                          <li className="page-item active">
                            <span className="page-link text-white">
                              Page {offset}
                            </span>
                          </li>
                          <li
                            className={
                              "page-item " +
                              (Object.values(results.data).length === limit
                                ? "cursor-pointer"
                                : "disabled")
                            }
                          >
                            <span
                              className="page-link"
                              onClick={() => HandlerPage(2)}
                            >
                              <span>Next ›</span>
                            </span>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              )}
            </SGUCard.Body>
          </SGUCard>
        </div>
      </div>
    </div>
  ) : (
    <AlertNotif messages={AccessInvalid} />
  );
}
