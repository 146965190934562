import React, { useEffect, useRef, useState } from 'react'
import { useOutletContext } from 'react-router-dom';
import { AccessInvalid } from '../../../../_layouts/_core/ErrorMessage';
import { AlertNotif, AlertRetry } from '../../../../_layouts/components/alerts/AlertsUI';
import { SGUCard } from '../../../../_layouts/components/cards/CardUI';
import Filter from './Filter';
import { GET_ROOM, GET_SCHD } from '../../apis/node-target-data/AcademicApi';
import moment from 'moment';
import { ButtonTersier } from '../../../../_layouts/components/buttons/ButtonUI';
import { ColorRoomInfo } from './components/ColorRoomInfo';
import { LoadingSkeleton, NoRecordFound } from '../../../../_layouts/components/loadbars/LoadingBars';
import { RoomOccupanceUI } from './components/RoomOccupance';
import { useProvider } from '../../../../_layouts/UMSLayoutProvider';

export function RoomOccupancy() {
  const {account} = useProvider();
  const context = useOutletContext();
  const widget_53 = context.widgets.find(
    (item) => item.widget_id === 53 && item.is_enabled === 1
  );
  const title = "Room Occupancy";

  const [postParam, setPostParam] = useState({ start_dt: "", end_dt: "" });
  const [room, setRoom] = useState(0);
  const [results, setResults] = useState({ loading: false, data: [], message: "" });

  const GetRooms = async () => {
    setResults({ loading: true, message: "", data: [] });
    const result = await GET_ROOM();

    if (result.data && Object.values(result.data).length > 0) {
      const r_room = result.data.sort((a, b) =>
        a.g_buildingId < b.g_buildingId ? -1 : 1
      );
      const room_result = GroupRoomByBuilding(r_room);
      setRoom(r_room);
      setResults({ loading: false, message: "", data: room_result });
    } else {
      setResults(result);
    }
  }

  useEffect(() => {
    GetRooms();
  }, [])

  const GetScheduleTimetable = async () => {
    setResults({ loading: true, message: "", data: [] });

    const param = {
      start_dt: moment(postParam.start_dt).format("YYYY-MM-DD"),
      end_dt: moment(postParam.end_dt).format("YYYY-MM-DD"),
      grouped: "True",
    }
    
    const result = await GET_SCHD(param);
    if (result.data && Object.values(result.data).length > 0) {
      const room_occupied = FindOccupied(room, result.data, postParam.start_dt, postParam.end_dt);
      const sort_room = room_occupied.sort((a, b) =>
        a.g_buildingId < b.g_buildingId ? -1 : 1
      );
      const room_result = GroupRoomByBuilding(sort_room);
      setResults({ loading: false, message: "", data: room_result });
    } else {
      setResults({ loading: false, message: result.message, data: [] });
    }
  };

  useEffect(() => {
    if (postParam.start_dt && postParam.end_dt) {
      GetScheduleTimetable();
    }
  }, [postParam])

  const filterRef = useRef(null);
  useEffect(() => {
    const handleResize = () => {
      if (filterRef.current) {
        filterRef.current.style.width = `${(filterRef.current.parentElement.offsetWidth - 60)}px`;
      }
    };

    handleResize(); 
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return widget_53 ? (
    <>
      <div ref={filterRef} style={{ position: 'fixed', top:100 }}>
        <SGUCard >
          <SGUCard.Body className={"pt-5 pb-0"}>
            <div className="d-flex justify-content-between align-items-center">
              <div>
                <h3 className="mb-2">
                  {title}
                </h3>
                <Filter onSubmit={GetScheduleTimetable} isload={results.loading} setPostParam={setPostParam} />
              </div>
              <div className='d-flex align-items-center'>
                <ColorRoomInfo />
                <ButtonTersier type="button" className="btn-sm fw-bold" disabled={true}>
                  Total Room {Object.values(room).length}
                </ButtonTersier>
              </div>
            </div>
          </SGUCard.Body>
        </SGUCard>
      </div>
      <div style={{ marginTop:"150px" }}>
        {results.loading ? <LoadingSkeleton /> :
          results.message ? <AlertRetry messages={results.message} /> :
            Object.values(results.data).length > 0 ? (
              Object.keys(results.data).map((v, index) => (
                <div key={index} className='mb-8'>
                  <h2 className='d-flex align-items-center'>
                    <i className="bi bi-door-open fs-1 text-primary"></i>
                    <span className="ms-2">{v}</span>
                  </h2>
                  <RoomOccupanceUI data={results.data[v]} postParam={postParam} GetScheduleTimetable={GetScheduleTimetable} />
                </div>
              ))
            ) : <NoRecordFound />}
      </div>
    </>
  ) : <AlertNotif messages={AccessInvalid} />;
}

const FindOccupied = (rooms, schedules, desiredStart, desiredEnd) => {
  console.log(desiredStart, desiredEnd)
  var cloneSchd = [...schedules];
  var computedData = [];
  cloneSchd.map((v) => {
    if (Object.values(v.event_room).length > 0) {
      v.event_room.map((r) => {
        var rm = {};
        rm.room_id = r.room_id;
        rm.room_name = r.room ? r.room.g_resourceName : "";
        rm.coursetitle = v.section_id ? (v.course ? v.course.coursetitle : "-") : v.label;
        rm.course_credit = v.section_id ? (v.course ? v.course.credit : "-") : "";
        rm.section_id = v.section_id ? v.section_id : "";
        rm.start_dt = v.start_dt ? v.start_dt : "";
        rm.end_dt = v.end_dt ? v.end_dt : "";
        rm.event_room = v.event_room ? v.event_room : "";
        computedData.push(rm);
      });
    }
  });

  console.log(computedData);

  var results = rooms.map((v) => {
    var obj = v;
    obj.occupancy = computedData.filter(
      (item) =>
        item.room_id === v.id &&
        moment(item.start_dt, "YYYY-MM-DD hh:mm A").isBefore(desiredEnd) &&
        moment(item.end_dt, "YYYY-MM-DD hh:mm A").isAfter(desiredStart)
    );
    return obj;
  });
  return results;
};

const GroupRoomByBuilding = (rooms) => {
  let groupedRooms = {};
  rooms.filter(room => room.g_buildingId !== null)
    .sort((a, b) => b.g_buildingId.localeCompare(a.g_buildingId) || a.g_floorName - b.g_floorName || a.g_resourceName.localeCompare(b.g_resourceName))
    .forEach((room) => {
      if (room.g_buildingId) {
        const key = `${room.g_buildingId} Floor ${room.g_floorName}`;
        if (!groupedRooms[key]) {
          groupedRooms[key] = [];
        }
        groupedRooms[key].push(room);
      }
    });
  return groupedRooms;
}

