import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import { useOutletContext, useParams } from "react-router-dom";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import CreateTimetable from "./components/CreateTimetable";

export function FormCreateTimetable() {
  let params = useParams();
  const section_id = atob(params.section_id);
  const context = useOutletContext();
  const widget_49 = context.widgets.find(
    (item) => item.widget_id === 49 && item.is_enabled === 1
  );

  return widget_49 ? (
    <div className="w-50 m-auto">
      <SGUCard>
        <SGUCard.Header>
          <span className="card-label fw-bolder text-dark">
            Create Timetable
          </span>
          <span className="text-muted mt-1 fw-bold fs-7">
            Timetable for section {section_id}
          </span>
        </SGUCard.Header>
        <SGUCard.Body>
          <CreateTimetable widget_49={widget_49} section_id={section_id} />
        </SGUCard.Body>
      </SGUCard>
    </div>
  ) : (
    <AlertNotif messages={AccessInvalid} />
  );
}
