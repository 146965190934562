import { CapitalizeFirstLetter } from "../../../../../../_layouts/_helpers/Global";
import { CREATE_SCHOOL, CREATE_UNIV, CREATE_UNIV_PRODI } from "../../../../apis/node-target-data/IdentityApi";

const SyncInstituteData = async (univ, univProdi, data) => {
    let current_univ_id = univ;
    let results = { univid: current_univ_id, univprodiid: univProdi }

    //update university data
    if (univ === 9999) {
        const param = { name: encodeURIComponent(CapitalizeFirstLetter(data.univ_name)) };
        const result_univ = await CREATE_UNIV(param);
        if (result_univ.data && Object.values(result_univ.data).length > 0) {
            current_univ_id = result_univ.data.id;
        }
    }

    //update univ prodi data
    if (univProdi === 9999) {
        const param = { university_id: current_univ_id, name: encodeURIComponent(CapitalizeFirstLetter(data.univ_prodi)) };
        const result_univ_prodi = await CREATE_UNIV_PRODI(param);
        if (result_univ_prodi.data && Object.values(result_univ_prodi.data).length > 0) {
            results.univid = current_univ_id;
            results.univprodiid = result_univ_prodi.data.id;
        }
    }

    return results;
}

const SyncSchoolData = async (school_id, school_name) => {
    let current_school_id = school_id;
    const param = { name: encodeURIComponent(school_name) };
    const result_school = await CREATE_SCHOOL(param);
    if (result_school.data && Object.values(result_school.data).length > 0) {
        current_school_id = result_school.data.id;
    }

    return {school_id:current_school_id};

}

export { SyncInstituteData, SyncSchoolData }