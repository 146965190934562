import React from "react";
import { Content } from "../../../_metronic/layout/components/content";
import {
  FindPeople,
  IntakeStudent,
  WAcadActive,
  WProfile,
  WRoomConflict,
} from "./components";
import CalendarImportanceDate from "../calendar/academic-access-time-window/importance-date/CalendarImportanceDate";
import { useProvider } from "../../../_layouts/UMSLayoutProvider";
import IntakeProspectiveStd from "./components/intake-prospective";

export default function Dashboard() {
  const { account } = useProvider();
  if (account.typeCK === "development") {
    return <DDevelopment />
  } else {
    return <DProduction />;
  }
}

const DProduction = () => {
  return (
    <Content>
      <div className="row g-5 g-xl-10">
        <div className="col-xl-8 mb-5 mb-xl-10">
          <div className="row">
            <div className="col-xl-6 mb-5 mb-xl-10">
              <WAcadActive type={"bachelor"} />
            </div>
            <div className="col-xl-6 mb-5 mb-xl-10">
              <WAcadActive type={"master"} />
            </div>
          </div>
          <div className="col-12">
            <WRoomConflict />
          </div>
          <div className="col-12">
            <FindPeople />
          </div>
        </div>
        <div className="col-xl-4 mb-5 mb-xl-10">
          <div className="mb-5">
            <WProfile />
          </div>
          <div className="mb-3">
            <IntakeStudent />
          </div>
          <IntakeProspectiveStd />
        </div>
      </div>
    </Content>
  );
}

const DDevelopment = () => {
  return (
    <Content>
      <div className="row g-5 g-xl-10">
        <div className="col-12 col-lg-4 col-xl-4 mb-5 mb-xl-10">
          <WAcadActive type={"bachelor"} />
        </div>
        <div className="col-12 col-lg-4 col-xl-4 mb-5 mb-xl-10">
          <WAcadActive type={"master"} />
        </div>
        <div className="col-12 col-lg-4 col-xl-4 mb-5 mb-xl-10">
          <WProfile />
        </div>
      </div>
      <WRoomConflict />
      <div className="row g-5 g-xl-10">
        <div className="col-12 col-lg-8 col-xl-8 mb-5 mb-xl-10">
          <div className="row">
            <div className="col-12 mb-5 mb-xl-10">
              <CalendarImportanceDate />
            </div>
            <div className="col-12">
              <FindPeople />
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-4 col-xl-4 mb-5 mb-xl-10">
          <div className="row">
            <div className="col-12 col-lg-12 col-xl-12 mb-5 mb-xl-10">
              <IntakeStudent />
            </div>
            <div className="col-12 col-lg-12 col-xl-12">
              <IntakeProspectiveStd />
            </div>
          </div>
        </div>
      </div>

    </Content>
  );
}
