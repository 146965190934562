import { useState } from "react";
import {
  ButtonPrimary,
  ButtonTersier,
} from "../../../../../_layouts/components/buttons/ButtonUI";
import { UPDATE_SCR_STUDENT_COM } from "../../../apis/node-target-data/AcademicApi";
import {
  MessageError,
  MessageSuccess,
  openModal,
} from "../../../../../_layouts/components/modals/Modals";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import { handleKeyDown } from "../../../components/forms/InputText";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

const FormScoreOnly = ({ data, ReloadData }) => {
  const context = useOutletContext();
  const widget_41 = context.widgets.find(
    (item) => item.widget_id === 41 && item.is_enabled === 1
  );
  const [score, setScore] = useState(data.score);
  const navigate = useNavigate();
  const location = useLocation();
  const state_data = location.state;
  
  const HandleCancel = () => {
    if (confirm("Are you sure want to leave this page?") === true) {
      if(state_data){
        navigate("/score/student", {
          state: {param:state_data.param},
        });
      }else{
        navigate("/score/student");
      }
    }
  };

  const [submit, setSubmit] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const HandlerSubmit = async (e) => {
    e.preventDefault();

    if (score) {
      setSubmit({ loading: true, message: "", data: [] });
      const param = {
        student: [
          {
            score_type: data.course.score_type,
            scr_std_id: data.id,
            studentid: data.studentid,
            score: score,
          },
        ],
      };

      const result = await UPDATE_SCR_STUDENT_COM(param);

      if (result.data && Object.values(result.data).length > 0) {
        openModal({
          message: (
            <MessageSuccess
              message={
                "Successfully updated."
              }
            />
          ),
        });
        ReloadData();
        setSubmit({ loading: false, message: "", data: result.data });
        //setInterval(() => {
          if(state_data){
            navigate("/score/student", {
              state: {param:state_data.param},
            });
          }else{
            navigate("/score/student");
          }
        //}, 1000);
      } else {
        openModal({ message: <MessageError message={result.message} /> });
        setSubmit(result);
      }
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  };

  return (
    <form method="post" autoComplete="off" onSubmit={(e) => HandlerSubmit(e)}>
      <div className="">
        <table className="table fs-5 align-middle">
          <tbody>
            <tr>
              <td width={"30%"}>Absent</td>
              <td className="fw-bold"> {data.atd} %</td>
            </tr>
            <tr>
              <td>Final Result</td>
              <td className="fw-bold"> {data.course_status || "-"}</td>
            </tr>
            <tr>
              <td>Grade</td>
              <td className="fw-bold"> {data.grade || "-"}</td>
            </tr>
            <tr>
              <td>
                <label className="required">Score Point</label>
              </td>
              <td>
                {widget_41 ? (
                  <input
                    type="text"
                    maxLength={3}
                    max={100}
                    required
                    className="form-control form-control-sm w-100px"
                    defaultValue={score}
                    onChange={(e) => setScore(parseFloat(e.target.value))}
                    onKeyDown={handleKeyDown}
                  />
                ) : (
                  <span className="fw-bold">{score}</span>
                )}
              </td>
            </tr>
          </tbody>
          <tfoot>
            {submit.message && (
              <tr>
                <td colSpan={2}>
                  <AlertNotif messages={submit.message} />
                </td>
              </tr>
            )}
            {widget_41 && (
              <tr>
                <td></td>
                <td className="text-start">
                  <div className="">
                    <ButtonTersier
                      className="btn-lg me-5"
                      type="button"
                      onClick={() => HandleCancel()}
                    >
                      Cancel
                    </ButtonTersier>
                    <ButtonPrimary
                      className="btn-lg"
                      type="submit"
                      disabled={submit.loading}
                    >
                      {submit.loading ? "Processing..." : "Save Changes"}
                    </ButtonPrimary>
                  </div>
                </td>
              </tr>
            )}
          </tfoot>
        </table>
      </div>
    </form>
  );
};

export { FormScoreOnly };
