import React, { useMemo, useState } from 'react'
import { SGUCardFlush } from '../../../../../../_layouts/components/cards/CardUI'
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LinearScale,
    Title,
} from "chart.js";
import Chart from 'chart.js/auto';
import { Line, } from "react-chartjs-2";

ChartJS.register(LineElement, PointElement, LinearScale, Title);

export default function GraphicsGPA({ data }) {
    const listdata = data ? data.semester_status : [];
    const [dataBar, setDataBar] = useState();

    useMemo(() => {
        var computeData = [];
        if (listdata) {
            computeData = listdata.sort((a, b) => (a.semester > b.semester ? 1 : -1));
            var arrLabel = []; var arrGPA = []; var arrGPAMid = [];
            computeData.map((v) => {
                arrLabel.push("Semester " + v.semester);
                arrGPA.push((v.cgpa) ? v.cgpa : 0);
                arrGPAMid.push((v.gpa) ? v.gpa : 0);
            })
            var labels = arrLabel;
            const data = {
                labels,
                datasets: [
                    {
                        label: 'CGPA',
                        data: arrGPA,
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                    {
                        label: 'GPA',
                        data: arrGPAMid,
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    }
                ],
            };

            setDataBar(data);
        }

        return computeData;
    }, [listdata])
    return (
        <SGUCardFlush>
            <SGUCardFlush.Body className={"p-0"}>
                {dataBar ? (
                    <div className="fs-7 py-5">
                        <Line data={dataBar} />
                    </div>
                ) : ""}
            </SGUCardFlush.Body>
        </SGUCardFlush>
    )
}
