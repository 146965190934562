import TableAttd from './components/TableAttd'
import HeaderAttd from './components/HeaderAttd'
import { useOutletContext, useParams } from 'react-router-dom';
import { Error404 } from '../../errors/components/Error404';
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';
import { AccessInvalid } from '../../../../_layouts/_core/ErrorMessage';

export function DetailAttdLect() {
  const context = useOutletContext();
  const widget_31 = context.widgets.find(
      (item) => item.widget_id === 31 && item.is_enabled === 1
  );
  const params = useParams();
  const token = params.token;
  try {
    const decode = atob(token);
    return widget_31 ? <DetailPage decode={decode} /> : <AlertNotif messages={AccessInvalid} />
  } catch (error) {
    return <Error404 />
  }
}

const DetailPage = ({ decode }) => {
  const data = JSON.parse(decode);
  const section_id = data.section_id;
  const context = useOutletContext();
  const widget_33 = context.widgets.find(
    (item) => item.widget_id === 33 && item.is_enabled === 1
  );

  return (
    <div id="detail-attd">
      <div className="mb-5">
        <HeaderAttd data={data} />
      </div>
      <div className="mb-5">
        <TableAttd data={data} section_id={section_id} widget_33={widget_33} />
      </div>
    </div>
  )
}

