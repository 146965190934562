export {AdmissionLayout} from "./AdmissionLayout"

export {ADMDashboard} from "./dashboard"

export {Enrollments} from "./enrollments/Enrollments"
export {FromEnrollments} from "./enrollments/FromEnrollments"
export {DetailEnrollments} from "./enrollments/DetailEnrollments"

export {Documents} from "./documents"

export {Coupons} from "./coupons"

export {AcademicAdministrations} from "./academic-administrations/AcademicAdministrations"