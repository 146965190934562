import React, { createContext, useContext } from 'react';

const LayoutContext = createContext();

const UMSLayoutProvider = ({ children, env, token, account, routes }) => {
    return (
        <LayoutContext.Provider value={{ env, token, account, routes }}>
            {children}
        </LayoutContext.Provider>
    );
};

const useProvider = () => {
    return useContext(LayoutContext);
};

export { UMSLayoutProvider, useProvider };