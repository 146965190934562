import React, { useCallback, useEffect, useState } from "react";
import { debounce } from "lodash";
import { LoadingSkeleton } from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../../_layouts/components/alerts/AlertsUI";
import { GET_EMPLOYEE_LOOK_UP } from "../../../../apis/node-target-data/HRAPI";
import TableDataEmp from "./TableDataEmp";
import { ButtonPrimary } from "../../../../../../_layouts/components/buttons/ButtonUI";
import { InputKeyword } from "../components/InputText";

export default function FindStaff() {
  const minimKeyword = 3;
  const [result, setResult] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const ReloadData = async (value) => {
    setResult({ message: "", data: [], loading: true, });
    const param = { search: value };
    const result = await GET_EMPLOYEE_LOOK_UP(param);

    setResult(result);
  };

  const [search, setSearch] = useState("");
  const FindDataDebounced = useCallback(
    debounce((param) => {
      ReloadData(param);
    }, 500),
    []
  );

  const HandlerSearch = (value) => {
    if (value.length >= minimKeyword) {
      setSearch(value);
      FindDataDebounced(value);
    } else {
      setResult({ loading: false, message: "", data: [] });
    }
  };

  return (
    <div className="find-staff">
        <div className="d-flex align-items-center justify-content-between">
          <div style={{ width: "100%" }}>
            <InputKeyword
              title={"Name"}
              defaultValue={search}
              onChange={(e) => HandlerSearch(e.target.value)}
              results={result}
              autoComplete="off"
            />
          </div>
        </div>

      {result.loading ? (
        <LoadingSkeleton />
      ) : result.message ? (
        <AlertNotif messages={result.message} color="danger" />
      ) : (
        <TableDataEmp data={result.data} />
      )}
    </div>
  );
}
