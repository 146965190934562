import React, { useMemo, useState } from 'react'
import { ButtonItemRefresh, HeaderDatatables, SearchBarTable } from '../../../../_layouts/components/datatables/MYDatatables';
import { LectProgressRPT, RowCourse, RowLecturer } from '../../course-managements/teaching-loads/components/TeachingLoadUI';
import { LinkBtn } from '../../../../_layouts/components/buttons/LinkUI';
import { KTIcon } from '../../../../_metronic/helpers';
import { NoRecordFound } from '../../../../_layouts/components/loadbars/LoadingBars';
import { Dropdown, OverlayTrigger } from 'react-bootstrap';
import { renderTooltip } from '../../../../_layouts/components/popovers/PopOvers';

export default function TableData({ results, ReloadData }) {
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const headers = [
        { name: "Course", field: "course", sortable: true },
        { name: "Section", field: "section_id", sortable: true },
        { name: "Lecturer", field: "lecturername", sortable: true },
        { name: "Actions", field: "section_id", sortable: false },
    ];

    const ResultData = useMemo(() => {
        let computedData = [];
        if (results.data) {
            computedData = results.data.map((c) => {
                var obj = {};
                obj.cost_center = c.cost_center ? c.cost_center.name : "-";
                obj.cost_center_code = c.cost_center
                    ? c.cost_center.code
                        ? c.cost_center.code
                        : ""
                    : "-";
                obj.emp_session = c.emp_session ? c.emp_session : 0;
                obj.section_id = c.section_id;
                obj.section_session = c.section_session;
                obj.corporate = c.corporate ? (c.corporate.name || "") : "";
                obj.max_seat = c.max_seat;
                obj.lecturer_id = c.emp ? c.emp.id : "-";
                obj.lecturername = c.emp ? c.emp.fullname : "-";
                obj.course_id = c.course ? c.course.id : 0;
                obj.course = c.course ? c.course.coursetitle : "";
                obj.course_code = c.course ? c.course.code : "";
                obj.course_credit = c.course ? c.course.credit : "";
                obj.t_approved = c.t_approved;
                obj.t_assigned = c.t_assigned;
                obj.t_submitted = c.t_submitted;
                obj.session_plotting = c.session_plotting;
                obj.emp = c.emp || "";
                obj.section_pre = c.section_pre || "";
                return obj;
            });

            computedData.sort((a, b) => (a.course > b.course ? 1 : -1));
        }

        computedData = computedData.filter((prev) => {
            if (prev.section_id) {
                return prev;
            }
        });

        if (search) {
            computedData = computedData.filter((listData) => {
                return Object.keys(listData).some(
                    (key) =>
                        listData[key]
                            .toString()
                            .toLowerCase()
                            .includes(search)
                    //console.log(key,listData[key])
                );
            });
        }

        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        return computedData;
    }, [results.data, search, sorting]);

    return (
        <div>
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <SearchBarTable
                    size="w-250px"
                    onChange={(e) => setSearch(e.target.value)}
                />
                <div className="d-flex">
                    <ButtonItemRefresh
                        totalItems={totalItems}
                        onClick={(e) => ReloadData(e)}
                    />
                </div>
            </div>
            <div className="table-responsives">
                <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
                    <HeaderDatatables
                        headers={headers}
                        onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                        {results.loading ? (
                            <tr>
                                <td colSpan={4}>
                                    <LoadingSkeleton />
                                </td>
                            </tr>
                        ) : results.message ? (
                            <tr>
                                <td colSpan={4}>
                                    <AlertNotif messages={results.message} color="danger" />
                                </td>
                            </tr>
                        ) : ResultData.length > 0 ? (
                            ResultData.map((v, index) => (
                                <tr key={index}>
                                    <td width={"35%"}><RowCourse obj={v} /></td>
                                    <td width={"15%"}>
                                        <div>
                                            <span className="d-block fw-bold">{v.section_id || ""}</span>
                                            <div>
                                                {v.corporate !== "SGU" && (
                                                    <span className="badge badge-light-info fs-8">{v.corporate}</span>
                                                )}
                                                {v.section_pre && v.section_pre.map((v, index) => (
                                                    v.clu && (
                                                        <OverlayTrigger delay={{ show: 250, hide: 400 }} key={index} placement="bottom" overlay={renderTooltip({ title: (v.clu && (v.clu.name || "") + " - Semester " + v.semester) })} >
                                                            <span className="badge badge-light-primary me-1">
                                                                {(v.clu.code || "")}-{v.semester}
                                                            </span>
                                                        </OverlayTrigger>
                                                    )
                                                ))}
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <RowLecturer obj={v} />
                                        <LectProgressRPT data={v} />
                                    </td>
                                    <td> <BtnAction data={v} /> </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={4}>
                                    <NoRecordFound />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const BtnAction = ({ data }) => {
    return (
        <Dropdown>
            <Dropdown.Toggle variant="light" className='btn-sm' id="dropdown-basic">
                <KTIcon iconName='setting-2' className='fs-1' />
            </Dropdown.Toggle>

            <Dropdown.Menu>
                <LinkBtn to={"create/" + btoa(data.section_id)} target='_blank' className="text-dark dropdown-item">
                    <KTIcon iconName='calendar-add' />
                    <span className="ms-2">Create Schedule</span>
                </LinkBtn>
                <LinkBtn to={"/course-managements/section/" + data.section_id + "/timetable"} target='_blank' className="text-dark dropdown-item">
                    <KTIcon iconName='calendar-search' />
                    <span className="ms-2">View Timetable</span>
                </LinkBtn>
            </Dropdown.Menu>
        </Dropdown>
    )
}