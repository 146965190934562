// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sgu-event-calendar{
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
}
/* width */
#sgu-event-calendar::-webkit-scrollbar {
  width: 0px !important;
}

/* Track */
#sgu-event-calendar::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
#sgu-event-calendar::-webkit-scrollbar-thumb {
  background: #FF0000;
}

/* Handle on hover */
#sgu-event-calendar::-webkit-scrollbar-thumb:hover {
  background: white;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/calendar/components/EventBox.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kCAAkC;AACpC;AACA,UAAU;AACV;EACE,qBAAqB;AACvB;;AAEA,UAAU;AACV;EACE,iBAAiB;AACnB;;AAEA,WAAW;AACX;EACE,mBAAmB;AACrB;;AAEA,oBAAoB;AACpB;EACE,iBAAiB;AACnB","sourcesContent":["#sgu-event-calendar{\n  overflow: hidden;\n  overflow: -moz-hidden-unscrollable;\n}\n/* width */\n#sgu-event-calendar::-webkit-scrollbar {\n  width: 0px !important;\n}\n\n/* Track */\n#sgu-event-calendar::-webkit-scrollbar-track {\n  background: white;\n}\n\n/* Handle */\n#sgu-event-calendar::-webkit-scrollbar-thumb {\n  background: #FF0000;\n}\n\n/* Handle on hover */\n#sgu-event-calendar::-webkit-scrollbar-thumb:hover {\n  background: white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
