import React, { useMemo, useState } from "react";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  SearchBarTable,
} from "../../../../../_layouts/components/datatables/MYDatatables";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import { KTIcon } from "../../../../../_metronic/helpers";
import { OverlayTrigger } from "react-bootstrap";
import moment from "moment";
import { BadgeItem } from "../../components/EnrollmentComp";
import { ProgramDegree, YearIntake } from "../../Helper";
import { renderTooltip } from "../../../../../_layouts/components/popovers/PopOvers";
import { openModal } from "../../../../../_layouts/components/modals/Modals";
import ModalUploadDoc from "./ModalUploadDoc";
import { FeeStatus, RegisterStatus } from "../../components/Helper";
import { StudentIDFormat } from "../../../../../_layouts/_helpers/Global";
import RangeDataCounter from "../../components/RangeDataCounter";
import { DoctypeIdentifier, ScanningExistingDoc } from "./Helper";

export default function TableData({
  results,
  ReloadData,
  update,
  triggerNotification,
}) {
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const headers = [
    { name: "Name", field: "name", sortable: false },
    { name: "Reg Id", field: "candidate_id", sortable: false },
    { name: "Department", field: "department", sortable: false },
    { name: "Enroll Type", field: "enrollment_type", sortable: false },
    { name: "Tuition", field: "tuition", sortable: false },
    { name: "Status", field: "status", sortable: false },
    { name: "Doc", field: "doc", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (results) {
      computedData = results.data.map((c) => {
        var obj = {};
        obj.name = c.regstudent.fullname;
        obj.registered_id = c.regstudent.registered_id;
        obj.candidate_id = c.candidate_id;
        obj.registrationdate = c.registrationdate;
        obj.reg_date = c.registrationdate
          ? moment(c.registrationdate).format("DD MMM YYYY hh:mm A")
          : "";
        obj.enrollment_type = c.enrollment_type?.name ?? "-";
        obj.major = c.target_department.name;
        obj.yearintake = c.yearintake;
        obj.degree = c.target_department.program_id;

        obj.tuition_status =
          c.fin_tr.length > 0
            ? c.fin_tr.find(
                (x) => x.trans_type.includes("tuitionfee") && x.is_active === 1
              )?.status ?? "CANCELED"
            : "CANCELED";

        obj.reg_status = c.status;
        obj.doc_list = c.regstudent.doc_list;
        obj.doc_status = c.doc_status;
        return obj;
      });

      computedData.sort((a, b) =>
        b.registrationdate > a.registrationdate ? 1 : -1
      );
    }

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) => {
          const value = listData[key];
          return (
            value &&
            value
              .toString()
              .toLowerCase()
              .includes(search)
          );
        });
      });
    }

    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    // console.log("c-data", computedData);

    return computedData;
  }, [results, search, sorting]);

  const ListDocTooltip = ({ doc_list }) => {
    let documents = ScanningExistingDoc(doc_list);

    return (
      <div className="d-flex flex-column align-items-start">
        {documents.map((doc, index) => (
          <>
            <div
              className="fw-bold d-flex align-items-center"
              key={doc.file_id}
            >
              <KTIcon
                iconName={
                  doc.verify === 1
                    ? "check-circle"
                    : doc.verify === 0
                    ? "information-5"
                    : "cross-circle"
                }
                className={`fs-4 me-1 ${
                  doc.verify === 1
                    ? "text-primary"
                    : doc.verify === 0
                    ? "text-warning"
                    : ""
                }`}
              />{" "}
              <span className="text-muted">{doc.type}</span>
            </div>
            {/* {index === 0 && (
              <div className="d-flex align-items-center w-100 justify-content-between">
                <div className="bg-gray-500 w-100" style={{height: 1}}></div>
                <div className="mx-2 text-gray-500">or</div>
                <div className="bg-gray-500 w-100" style={{height: 1}}></div>
              </div>
            )} */}
          </>
        ))}
      </div>
    );
  };

  const Popup = ({
    index,
    doc_status,
    registered_id,
    doc_list,
    candidate_id,
  }) => {
    return (
      <OverlayTrigger
        trigger="hover"
        rootClose
        placement="top"
        overlay={renderTooltip({
          title: <ListDocTooltip doc_list={doc_list} />,
        })}
      >
        <div>
          <input
            className={`form-check-input cursor-pointer`}
            type="checkbox"
            checked={doc_status === 1 ? true : false}
            onClick={() =>
              openModal({
                message: (
                  <ModalUploadDoc
                    registered_id={registered_id}
                    triggerNotification={triggerNotification}
                    doc_list={doc_list}
                    doc_status={doc_status}
                    candidate_id={candidate_id}
                    ReloadData={ReloadData}
                  />
                ),
                header: <h4 className="modal-title">Document Management</h4>,
              })
            }
            readOnly
          />
        </div>
      </OverlayTrigger>
    );
  };

  return (
    <div>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex">
          {/* <ButtonItemRefresh
            totalItems={totalItems}
            onClick={(e) => ReloadData(e)}
          /> */}
          <RangeDataCounter
            totalItems={totalItems}
            onClick={(e) => ReloadData(e)}
          />
        </div>
      </div>
      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {results.loading ? (
              <tr>
                <td colSpan={9}>
                  <LoadingSkeleton />
                </td>
              </tr>
            ) : results.message ? (
              <tr>
                <td colSpan={9}>
                  <AlertNotif messages={results.message} color="danger" />
                </td>
              </tr>
            ) : ResultData.length > 0 ? (
              ResultData.map((v, index) => (
                <tr key={index}>
                  <td className="fs-7" style={{ width: 150 }}>
                    <span className="d-block fw-bolder">{v.name}</span>
                  </td>
                  <td className="fs-7">
                    <span className="d-block fw-bolder">
                      {StudentIDFormat(v.candidate_id)}
                    </span>
                    <BadgeItem
                      icon="calendar"
                      name={v.reg_date}
                      title="Reg Date"
                    />
                  </td>
                  <td className="fs-7">
                    <span className="d-block fw-bolder">{v.major}</span>
                    <BadgeItem
                      icon="time"
                      name={YearIntake(v.yearintake)}
                      title="Year Intake"
                      className="me-3"
                    />
                    <BadgeItem
                      icon="teacher"
                      name={ProgramDegree(v.degree)}
                      title="Program Degree"
                      className="me-3"
                    />
                  </td>
                  <td className="fs-7 fw-bold">{v.enrollment_type}</td>
                  <td>
                    <FeeStatus data={v.tuition_status} />
                  </td>
                  <td>
                    <RegisterStatus data={v.reg_status} />
                  </td>
                  <td>
                    <div className="form-check form-check-sm form-check-custom form-check-solid">
                      <Popup
                        keyName="ijazah"
                        index={index}
                        doc_status={v.doc_status}
                        registered_id={v.registered_id}
                        candidate_id={v.candidate_id}
                        doc_list={v.doc_list}
                      />
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={9}>
                  <NoRecordFound />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}
