import React, { useEffect, useRef, useState } from "react";
import {
  CREATE_UNIV_PRODI,
  DELETE_UNIV_PRODI,
  GET_UNIV_PRODI_LOOKUP,
  UPDATE_UNIV_PRODI,
} from "../../../apis/node-target-data/IdentityApi";
import {
  LoadingBars,
  SpinnerLoad,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import {
  AlertNotif,
  AlertRetry,
} from "../../../../../_layouts/components/alerts/AlertsUI";
import {
  ButtonDanger,
  ButtonIcon,
  ButtonPrimary,
  ButtonTersier,
} from "../../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../../_metronic/helpers";
import { RequiredMsg } from "../../../../../_layouts/_core/ErrorMessage";
import { OverlayTrigger, Popover } from "react-bootstrap";

export default function ProdyUniv({ univid }) {
  const [prody, setPrody] = useState({ loading: false, data: [], message: "" });
  const [name, setName] = useState("");
  const [open, setOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const ReloadData = async () => {
    setPrody({ loading: true, data: [], message: "" });
    const param = { univid: univid };
    const result = await GET_UNIV_PRODI_LOOKUP(param);
    setPrody(result);
    setOpen(false);
  };

  useEffect(() => {
    ReloadData();
  }, [univid]);

  const HandlerCancel = () => {
    setName("");
    setOpen(false);
  };

  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const SubmitHandler = async () => {
    if (name) {
      setSubmit({ loading: true, data: [], message: "" });
      const param = { name: name, university_id: univid };
      const result = await CREATE_UNIV_PRODI(param);
      if (result.data && Object.values(result.data).length > 0) {
        ReloadData();
        setName("");
        setOpen(false);
      }
      setSubmit(result);
    } else {
      setSubmit({ loading: false, data: [], message: RequiredMsg });
    }
  };

  return (
    <div className="prody-lookup">
      <div className="table-responsive">
        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
          <thead>
            <tr className="fs-7 fw-bold text-gray-500 border-bottom-0 text-uppercase">
              <th width="5%">No</th>
              <th>Name</th>
              <td></td>
            </tr>
          </thead>
          <tbody>
            {prody.loading ? (
              <tr>
                <td colSpan={3}>
                  <LoadingBars />
                </td>
              </tr>
            ) : prody.message ? (
              <tr>
                <td colSpan={3}>
                  <AlertRetry messages={prody.message} Reload={ReloadData} />
                </td>
              </tr>
            ) : (
              Object.values(prody.data).length > 0 &&
                prody.data.map((v, index) => (
                  <UpadateData
                    key={index}
                    index={index}
                    data={v}
                    univid={univid}
                    Reload={ReloadData}
                    setIsEditing={setIsEditing}
                  />
                ))
            )}

            {open && (
              <tr>
                <td>{Object.values(prody.data).length + 1}</td>
                <td colSpan={3}>
                  <FormData
                    HandlerCancel={HandlerCancel}
                    SubmitHandler={SubmitHandler}
                    name={name}
                    setName={setName}
                    submit={submit}
                  />
                </td>
              </tr>
            )}
            {submit.message && (
              <tr>
                <td colSpan={3}>
                  <AlertNotif messages={submit.message} />
                </td>
              </tr>
            )}
          </tbody>
          {(!open && !isEditing) && (
            <tfoot>
              <tr>
                <td colSpan={3} className="text-center">
                  <ButtonPrimary
                    className="btn-sm fw-bold btn-add-new"
                    disabled={prody.loading}
                    onClick={() => setOpen(true)}
                  >
                    <KTIcon iconName="plus" className="fs-2" />
                    Add New
                  </ButtonPrimary>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
    </div>
  );
}

const ActionRemove = ({ id, ReloadData }) => {
  const [show, setShow] = useState(false);
  const target = useRef(null);

  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      rootClose
      placement="right"
      trigger="click"
      show={show}
      onToggle={() => setShow(!show)}
      overlay={
        <Popover className="p-3">
          <Popover.Body className="p-0">
            <RemoveItem
              id={id}
              closePopover={() => setShow(false)}
              ReloadData={ReloadData}
            />
          </Popover.Body>
        </Popover>
      }
    >
      <button
        type="button"
        ref={target}
        className="btn btn-icon btn-sm"
        onClick={() => setShow(!show)}
      >
        <KTIcon iconName="trash" className="fs-3 text-danger" />
      </button>
    </OverlayTrigger>
  );
};

const RemoveItem = ({ id, closePopover, ReloadData }) => {
  const [remove, setRemove] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const HandlerRemove = async (id) => {
    setRemove({ loading: true, data: [], message: "" });
    const param = { id: id };
    const result = await DELETE_UNIV_PRODI(param);
    if (result.data && Object.values(result.data).length > 0) {
      ReloadData();
    }
    setRemove(result);
  };
  return (
    <div className="text-center p-2">
      <p className="fs-4 mb-3 fw-bold">Are you sure wants to remove?</p>
      <div className="btn-group">
        <ButtonTersier
          className="btn-sm fw-bold"
          onClick={closePopover}
          disabled={remove.loading}
        >
          No
        </ButtonTersier>
        <ButtonDanger
          className="btn-sm fw-bold"
          onClick={() => HandlerRemove(id)}
          disabled={remove.loading}
        >
          {remove.loading ? <SpinnerLoad /> : "Yes"}
        </ButtonDanger>
      </div>
      {remove.message && <AlertNotif messages={remove.message} />}
    </div>
  );
};

const UpadateData = ({index, univid, data, Reload, setIsEditing}) => {
  const [open, setOpen] = useState(false);
  const [nama, setNama] = useState(decodeURIComponent(data.name));
  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const UpdateHandler = async () => {
    const param = { id: data.id, name: nama, university_id: univid };
    const result = await UPDATE_UNIV_PRODI(param);
      if (result.data && Object.values(result.data).length > 0) {
        Reload();
        setOpen(false);
        setIsEditing(false);
      }else{
        alert(result.message);
      }
      setSubmit(result);
  };

  const CancelHandler = () => {
    setOpen(false);
    setIsEditing(false);
  };

  return (
    <tr className="">
      <td>{index + 1}</td>
      <td>
        {open ? (
        <FormData
          HandlerCancel={CancelHandler}
          SubmitHandler={UpdateHandler}
          name={nama}
          setName={setNama}
          submit={submit}
        />
      ) : (
        <span>{nama}</span>
      )}
      </td>
      <td>
        {!open && (
          <>
            <ButtonIcon
              type="button"
              className="btn-sm text-hover-primary"
              onClick={() => {setOpen(true); setIsEditing(true);}}
            >
              <KTIcon iconName="pencil" className="fs-3" />
            </ButtonIcon>
            <ActionRemove id={data.id} ReloadData={Reload} />
          </>
        )}
      </td>
    </tr>
  );
};

const FormData = ({ name, setName, submit, HandlerCancel, SubmitHandler }) => {
  return (
    <div className="input-group">
      <input
        type="text"
        className="form-control form-control-sm"
        defaultValue={name}
        onChange={(e) => setName(e.target.value)}
      />
      <span className="input-group-text p-0">
        <ButtonIcon
          className="btn-sm btn-light"
          type="button"
          title="Cancel"
          onClick={() => HandlerCancel()}
        >
          <KTIcon iconName="cross-circle" className="fs-3 text-danger" />
        </ButtonIcon>
        <ButtonIcon
          className="btn-sm btn-light"
          type="button"
          title="Save"
          onClick={SubmitHandler}
          disabled={submit.loading}
        >
          {submit.loading ? (
            <SpinnerLoad />
          ) : (
            <KTIcon iconName="check-circle" className="fs-3 text-primary" />
          )}
        </ButtonIcon>
      </span>
    </div>
  );
};
