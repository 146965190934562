import React, { useMemo, useState } from 'react'
import { ButtonItemRefresh, HeaderDatatables, SearchBarTable } from '../../../../_layouts/components/datatables/MYDatatables';
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';
import { LoadingSkeleton, NoRecordFound } from '../../../../_layouts/components/loadbars/LoadingBars';
import { KTIcon } from '../../../../_metronic/helpers';
import { RowCourse, RowCumulative, RowLecturer, RowStudent } from './components/ScoreUI';
import { LinkBtn } from '../../../../_layouts/components/buttons/LinkUI';

export default function TableData({ results, ReloadData, paramSC }) {
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const headers = [
        { name: "Student", field: "student_name", sortable: true },
        { name: "Course", field: "course_name", sortable: true },
        { name: "Lecturer", field: "lecturername", sortable: false },
        { name: "Summary", field: "lecturername", sortable: false },
        { name: "Detail", field: "id", sortable: false },
    ];

    const ResultData = useMemo(() => {
        let computedData;
        if (results.data) {
            computedData = results.data.map((c) => {
                var obj = {};
                obj.atpid= c.atp_term ? c.atp_term.id : "";
                obj.clu_name = c.clu ? c.clu.name : "-";
                obj.student_id = c.studentid ? c.studentid : "";
                obj.student_name = c.studentdata ? c.studentdata.fullname : "-";
                obj.student_semester = c.semester || "";
                obj.student_status = c.status || "";
                obj.id = c.id;
                obj.section_lec = c.section_lec || "";
                obj.course_id = c.course ? c.course.id : 0;
                obj.course_name = c.course ? c.course.coursetitle : "";
                obj.course_code = c.course ? c.course.code : "";
                obj.is_internship = c.course ? c.course.is_internship : "";
                obj.is_thesis = c.course ? c.course.is_thesis : "";
                obj.is_atd_calc = c.course ? c.course.is_atd_calc : "";
                obj.course_status = c.course_status ? c.course_status : "-";
                obj.course_credit = c.credit || "";
                obj.course_lesson = c.course_lesson || "";
                obj.atd = c.atd ? c.atd : 0;
                obj.section_com = c.section_com ? c.section_com : "";
                obj.remark = c.remark ? c.remark : "";
                obj.score = c.score ? c.score : 0;
                obj.score_ore = c.score_ore ? c.score_ore : 0;
                obj.repeat = c.repeat ? c.repeat : 0;
                obj.grade = c.grade ? c.grade : 0;
                obj.grade_fin = c.grade_fin ? c.grade_fin : "-";
                obj.submitfinal = c.submitfinal ? c.submitfinal : 0;
                obj.submitfinal_by = c.submitfinal_by ? c.submitfinal_by : 0;
                obj.ths_main_id = c.ths_main_id ? c.ths_main_id : "";
                return obj;
            });
        }

        computedData.sort((a, b) => (a.student_name > b.student_name ? 1 : -1));

        if (search) {
            computedData = computedData.filter((listData) => {
                return Object.keys(listData).some((key) =>
                    listData[key]
                        .toString()
                        .toLowerCase()
                        .includes(search)
                );
            });
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }
        return computedData
    }, [results.data, search, sorting]);

    return (
        <div>
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <SearchBarTable
                    size="w-250px"
                    onChange={(e) => setSearch(e.target.value)}
                />
                <div className="d-flex">
                    <ButtonItemRefresh
                        totalItems={(paramSC && paramSC.offset) +"-"+ totalItems}
                        onClick={(e) => ReloadData(e)}
                    />
                </div>
            </div>
            <div className="table-responsive">
                <table className="table table-row-dashed align-start fs-6 gy-4 my-0 pb-3 dataTable no-footer align-start">
                    <HeaderDatatables
                        headers={headers}
                        onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                        {results.loading ? (
                            <tr>
                                <td colSpan={5}>
                                    <LoadingSkeleton />
                                </td>
                            </tr>
                        ) : results.message ? (
                            <tr>
                                <td colSpan={5}>
                                    <AlertNotif messages={results.message} />
                                </td>
                            </tr>
                        ) : ResultData.length > 0 ? (
                            ResultData.map((v, index) => (
                                <tr key={index}>
                                    <td><RowStudent data={v} /></td>
                                    <td><RowCourse data={v} /></td>
                                    <td><RowLecturer data={v} /></td>
                                    <td><RowCumulative data={v} /></td>
                                    <td>
                                        {v.is_thesis ? (
                                            (v.ths_main_id && (
                                                <LinkBtn
                                                    to={"/score/thesis/detail/" + btoa(JSON.stringify({ course_id: v.course_id, studentid: v.student_id, ths_main_id:v.ths_main_id }))}
                                                    target="_blank"
                                                    className="btn btn-icon btn-sm btn-light"
                                                    title="View Score Thesis"
                                                >
                                                    <KTIcon iconName="arrow-right" className="fs-5" />
                                                </LinkBtn>
                                            ))
                                        ) : (
                                            v.student_status === "ACTIVE" && (
                                                <LinkBtn
                                                    to={"/score/student/detail/" + btoa(JSON.stringify({ course_id: v.course_id, studentid: v.student_id, atpid: v.atpid }))}
                                                    state={{ param:paramSC }}
                                                    className="btn btn-icon btn-sm btn-light"
                                                    title="View Score"
                                                >
                                                    <KTIcon iconName="arrow-right" className="fs-5" />
                                                </LinkBtn>
                                            )
                                        )}
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan={5}>
                                    <NoRecordFound />
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
