import React, { useEffect, useState } from 'react'
import { ButtonPrimary, ButtonTersier } from '../../../../../_layouts/components/buttons/ButtonUI';
import { openModal } from '../../../../../_layouts/components/modals/Modals';
import { RequiredMsg } from '../../../../../_layouts/_core/ErrorMessage';
import { AlertNotif, AlertRetry } from '../../../../../_layouts/components/alerts/AlertsUI';
import { CREATE_CITY, GET_COUNTRY_BY_ID, GET_PROVINCE_BY_ID, UPDATE_CITY } from '../../../apis/node-target-data/IdentityApi';
import { RemoveEmptyValue } from '../../../../../_layouts/_helpers/Global';
import { LoadingBars, LoadingSkeleton } from '../../../../../_layouts/components/loadbars/LoadingBars';

export default function Form({ReloadData, country_id, province_id, data}) {
    const [country, setCountry] = useState({loading:false, data:[], message:""});
    const [province, setProvince] = useState({loading:false, data:[], message:""});

    const [paramPost, setParamPost] = useState({
        country_id,
        province_id,
        code: data ? data.code : "",
        name: data ? data.name : "",
        id:data ? data.id : 0,
    });

    const ReloadDataMaster = async () =>{
        setCountry({loading:true, data:[], message:""});
        const param_country = {id:country_id};
        const result_country = await GET_COUNTRY_BY_ID(param_country);
        setCountry(result_country);
        
        if(province_id !== 0){
            setProvince({loading:true, data:[], message:""});
            const param_province = {id:province_id};
            const result_province = await GET_PROVINCE_BY_ID(param_province);
            setProvince(result_province);
        }
    }

    useEffect(()=>{
        ReloadDataMaster();
    },[])

    const [submit, setSubmit] = useState({loading:false, data:[], message:""});

    const HandlerSubmit = async (e) =>{
        e.preventDefault();
        if(paramPost.name){
            setSubmit({loading:true, data:[], message:""});
            const param = RemoveEmptyValue(paramPost);
            let result;
            if(param.id){
                result = await UPDATE_CITY(param);
            }else{
                result = await CREATE_CITY(param);
            }
            
            if(result.data && Object.values(result.data).length > 0){
                ReloadData();
                openModal({open:false});
            }else{
                setSubmit(result);
            }            
            
        }else{
            setSubmit({loading:false, data:[], message:RequiredMsg});
        }
    }
    return (
        <form method='post' autoComplete='off' onSubmit={(e)=>HandlerSubmit(e)}>
            <div className="mb-5">
                <h3>Form City</h3>
                <span className="text-muted">Please fill up the form with correctly</span>
            </div>
            {country.loading ? <LoadingBars /> : 
            country.message ? <AlertRetry messages={country.message} Reload={ReloadDataMaster} /> :
            (Object.values(country.data).length > 0) && (
                <div className="form-group mb-8">
                    <label className="fw-bold required">Country</label>
                    <input type="text" readOnly className="form-control form-control-sm" defaultValue={country.data.name} />
                </div>
            )}
            
            {province.loading ? <LoadingBars /> : 
            province.message ? <AlertRetry messages={province.message} Reload={ReloadDataMaster} /> :
            (Object.values(province.data).length > 0) && (
                <div className="form-group mb-8">
                    <label className="fw-bold required">Province</label>
                    <input type="text" readOnly className="form-control form-control-sm" defaultValue={province.data.name} />
                </div>
            )}

            <div className="form-group mb-8">
                <label className="fw-bold required">Name</label>
                <input type="text" required className="form-control form-control-sm" defaultValue={paramPost.name} onChange={(e) => setParamPost({ ...paramPost, name: e.target.value })} />
            </div>

            {submit.message && (
                <div className="mb-5">
                    <AlertNotif messages={submit.message} />
                </div>
            )}

            <div className="text-end">
                <ButtonTersier className="btn-sm fw-bold me-3" type="button" onClick={() => openModal({ open: false })}>
                    Cancel
                </ButtonTersier>
                <ButtonPrimary className="btn-sm fw-bold" type="submit" disabled={submit.loading}>
                    {submit.loading ? "Processing...":"Save Changes"}
                </ButtonPrimary>
            </div>
        </form>
    )
}
