const ProgramDegree = (d) => {
  if (d === 1) return "Bachelor";
  if (d === 2) return "Master";
};

const GenderOptons = (d) => {
  if (d.toLowerCase() === "p") return "Male";
  if (d.toLowerCase() === "w") return "Female";
};

const YearIntake = (d) => {
  if (d === 26) return 2024;
  if (d === 27) return 2025;
  if (d === 28) return 2026;
  if (d === 29) return 2027;
  if (d === 30) return 2028;
};

const ReligionList = (d) => {
  let religion_list = [
    {
      id: 1,
      text: "Islam",
    },
    {
      id: 2,
      text: "Catholic",
    },
    {
      id: 3,
      text: "Christian",
    },
    {
      id: 4,
      text: "Hindu",
    },
    {
      id: 5,
      text: "Buddist",
    },
    {
      id: 7,
      text: "Konghuchu",
    },
    {
      id: 6,
      text: "Other",
    },
  ];
  const a = religion_list.find((r) => r.id === d)?.text;
  return a;
};

function ConvertToRupiah(number) {
  return "Rp" + number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

const StatusFee = (status) => {
  return (
    <>
      {status === "DRAFT" && (
        <span className="cursor-pointer badge badge-light-secondary">
          Draft
        </span>
      )}
      {status === "UNPAID" && (
        <span className="cursor-pointer badge badge-light-warning">Unpaid</span>
      )}
      {status === "PAID" && (
        <span className="cursor-pointer badge badge-light-success">Paid</span>
      )}
    </>
  );
};

export {
  ProgramDegree,
  YearIntake,
  GenderOptons,
  ReligionList,
  ConvertToRupiah,
  StatusFee,
};
