import React, { useEffect, useState, useMemo } from "react";
import Pagination from "react-bootstrap/Pagination";
import { ButtonIcon } from "../buttons/ButtonUI";

const HeaderDatatables = ({ headers, onSorting }) => {
  const [sortingField, setSortingField] = useState("");
  const [sortingOrder, setSortingOrder] = useState("asc");

  const onSortingChange = (field) => {
    const order =
      field === sortingField && sortingOrder === "asc" ? "desc" : "asc";
    setSortingField(field);
    setSortingOrder(order);
    onSorting(field, order);
  };

  return (
    <thead>
      <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0">
        {headers.map(({ name, field, sortable }) => (
          <th
            className="fw-bolder"
            key={name}
            onClick={() => (sortable ? onSortingChange(field) : null)}
          >
            {name}

            {sortingField && sortingField === field && (
              <i
                className={
                  (sortingOrder === "asc"
                    ? "fas fa-arrow-down"
                    : "fas fa-arrow-up") + " font-size-xs ms-1 text-gray-500 "
                }
              />
            )}
          </th>
        ))}
      </tr>
    </thead>
  );
};

const PaginationComponent = ({
  total = 0,
  itemsPerPage = 10,
  currentPage = 1,
  onPageChange,
}) => {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (total > 0 && itemsPerPage > 0)
      setTotalPages(Math.ceil(total / itemsPerPage));
  }, [total, itemsPerPage]);

  const paginationItems = useMemo(() => {
    const pages = [];

    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => onPageChange(i)}
        >
          {i}
        </Pagination.Item>
      );
    }

    return pages;
  }, [totalPages, currentPage]);

  if (totalPages === 0) return null;

  return (
    <Pagination>
      <Pagination.Prev
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      {paginationItems}
      <Pagination.Next
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

const SearchBarTable = (props) => {
  const { size, ...others } = props;
  return (
    <div
      className={
        "d-flex align-items-center mb-3 mb-lg-0 bg-white border rounded " +
        (size ? size : "w-50")
      }
    >
      <input
        type="text"
        className="form-control form-control-sm form-control-flush"
        placeholder="Type here to find it"
        {...others}
      />
      <span className="svg-icon svg-icon-1 svg-icon-gray-400 me-1">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <rect
            opacity="0.5"
            x="17.0365"
            y="15.1223"
            width="8.15546"
            height="2"
            rx="1"
            transform="rotate(45 17.0365 15.1223)"
            fill="currentColor"
          ></rect>
          <path
            d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
            fill="currentColor"
          ></path>
        </svg>
      </span>
    </div>
  );
};

const ButtonItemRefresh = (props) => {
  const { totalItems, title, ...others } = props;
  return (
    <div className="btn-group">
      <button className="btn btn-sm bg-light fs-7" type="button">
        <span className="text-dark fw-bold">
          {title || 'Total'} {totalItems}
        </span>
      </button>
      <ButtonIcon className="btn-sm btn-light-primary" title="Refresh" type="button" {...others}>
        <i className="bi bi-arrow-clockwise"></i>
      </ButtonIcon>
    </div>
  );
};

export {
  HeaderDatatables,
  PaginationComponent,
  SearchBarTable,
  ButtonItemRefresh,
};
