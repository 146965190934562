import React, { useEffect, useState } from "react";
import { GET_STUDENT_ACTIVITY } from "../../../../../apis/node-target-data/AcademicApi";
import { LoadingBars } from "../../../../../../../_layouts/components/loadbars/LoadingBars";

export default function TableData({ studentid }) {
  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });
  
  const ReloadData = async () => {
    setResults({ loading: true, data: [], message: "" });
    const param = {studentid:studentid, activity_type: "asc.student.activity.leave"};
    const result = await GET_STUDENT_ACTIVITY(param);

    setResults(result);
  };

  useEffect(() => {
    ReloadData();
  }, []);

  return (
    <div className="table-responsive">
        <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
            <thead>
                <tr className="fw-bolder text-dark bg-light">
                    <th width={"8%"} className="text-center">No</th>
                    <th>LEAVE ON SEMESTER</th>
                    <th>REASON</th>
                    <th>STATUS</th>
                </tr>
            </thead>
            <tbody>
                {results.loading ? (
                    <tr>
                        <td colSpan={5}><LoadingBars /></td>
                    </tr>
                ) : results.message ? (
                    <tr>
                        <td colSpan={5}>{results.message}</td>
                    </tr>
                ) : Object.values(results.data).length > 0 ? (
                    results.data.map((v,index)=>(
                        <tr key={index}>
                            <td className="text-center">{index+1}</td>
                            <td>
                                <span className="d-block fw-bolder">Semester {v.semester}</span>
                                <span className="text-muted fw-bold">{(v.atp_term) ? v.atp_term.name : "-"}</span>
                            </td>
                            <td>
                                <span className="d-block">{v.note}</span>
                                <span className="text-muted">
                                    <i className="bi bi-box-arrow-in-right me-1"></i>
                                    {v.submit_date_formatted}
                                </span>
                            </td>
                            <td>
                                {v.status_activity === "APPROVED" ? (
                                    <span className="badge badge-info">{v.status_activity}</span>
                                ) : v.status_activity}
                            </td>
                        </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={5}>No record found</td>
                  </tr>
                ) }
            </tbody>
        </table>
    </div>
  );
}
