import React, { useState } from 'react'
import { AcadTerm, CourseOwner, Department, DepartmentSpec, EmployeeSelect } from '../../../components/forms/SelectBox';
import { CourseLookUp, LectLookUp, SectionID, Semester, StudentID } from '../../../components/forms/InputText';
import FilterUI from '../../../components/filters/FilterUI';

export default function FilterSection({ SubmitFilter, results, setResults }) {
    const [expand, setExpand] = useState(false);
    const [term_id, setTerm_id] = useState(0);
    const [const_center, setConstCenter] = useState(0);
    const [section_id, setSectionId] = useState("");
    const [course, setCourse] = useState("");
    const [course_id, setCourseID] = useState(0);
    const [emp_id, setEmp_id] = useState(0);
    const [student_id, setStudentId] = useState("");
    const [semester, setSemester] = useState("");
    const [clu_id, setCluId] = useState("");
    const [clu_spec_id, setCluSpecId] = useState("");


    const handleSubmit = (e) => {
        e.preventDefault();
        const paramFilter = {
            term_id,
            const_center,
            section_id,
            course_id,
            emp_id,
            student_id,
            semester,
            clu_id,
            clu_spec_id
        }
        SubmitFilter(paramFilter);
    };

    return (
        <FilterUI
            title="Section"
            onSubmit={handleSubmit}
            isload={results.loading}
        >
            <AcadTerm data={term_id} setData={setTerm_id} />
            <CourseOwner data={const_center} setData={setConstCenter} />
            <CourseLookUp course={course} setCourse={setCourse} setCourseID={setCourseID} />
            <SectionID data={section_id} setData={setSectionId} />
            <EmployeeSelect data={emp_id} setData={setEmp_id} title={"Lecturer"} />
            <StudentID data={student_id} setData={setStudentId} />
            <p className="text-end mb-0 cursor-pointer" onClick={() => setExpand(!expand)}>
                {!expand ? (
                    <>
                        <span className="text-primary me-2">Show more</span>
                        <i className="bi bi-chevron-down text-primary"></i>
                    </>
                ) : (
                    <>
                        <span className="text-primary me-2">Show less</span>
                        <i className="bi bi-chevron-up text-primary"></i>
                    </>
                )}
            </p>
            <div className={"expand-filter " + (!expand ? "d-none" : "")}>
                <Department data={clu_id} setData={setCluId} />
                <DepartmentSpec data={clu_spec_id} setData={setCluSpecId} />
                <Semester data={semester} setData={setSemester} />
            </div>
        </FilterUI>
    )
}
