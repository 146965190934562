import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { GET_ACAD_LOOKUP, GET_ACAD_MSTONE_BY_ACAD } from '../../../apis/node-target-data/AcademicApi';
import { SGUCardFlush } from '../../../../../_layouts/components/cards/CardUI';
import { LoadingSkeleton } from '../../../../../_layouts/components/loadbars/LoadingBars';
import PackageJSON from '../../../../../../package.json'
import { AlertNotif } from '../../../../../_layouts/components/alerts/AlertsUI';
import { openModal } from '../../../../../_layouts/components/modals/Modals';

export function ImportantDates() {
    const actTermID = PackageJSON.system_param.ActiveAcad;
    const [selectedTermID, setSelectTermID] = useState(actTermID);
    const [mstone, setMstone] = useState({
        loading: false,
        data: [],
        message: "",
    });
    const GET_ACAD_MSTONE_BY_ACAD_LOAD = async (param) => {
        setMstone({ loading: true, message: "", data: [] })

        const results = await GET_ACAD_MSTONE_BY_ACAD(param)

        if (results.data && Object.values(results.data).length > 0) {
            setMstone({ loading: false, message: "", data: results.data });
        } else {
            setMstone(results)
        }
    }

    const [acad, setAcad] = useState({ loading: false, message: "", data: [] });
    const GET_ACTIVE_ACAD_LOAD = async () => {
        setAcad({ loading: true, message: "", data: [] })
        const results = await GET_ACAD_LOOKUP()

        if (results.data && Object.values(results.data).length > 0) {
            const data = results.data.filter(item => item.atp_id_term !== 116);
            setAcad({ loading: false, message: "", data: data });
        } else {
            setAcad(results)
        }
    }

    useEffect(() => {
        GET_ACTIVE_ACAD_LOAD();
        const param = {
            atpid: actTermID,
            view_all: 1,
        }
        GET_ACAD_MSTONE_BY_ACAD_LOAD(param);
    }, []);

    const openEvent = (value) => {
        setSelectTermID(value);
        const param = {
            atpid: value,
            view_all: 1,
        }
        GET_ACAD_MSTONE_BY_ACAD_LOAD(param);
    };

    return (
        <SGUCardFlush>
            <SGUCardFlush.Header>
                <span className="card-label fw-bold text-gray-800">Important Dates</span>
            </SGUCardFlush.Header>
            <SGUCardFlush.Body>
                {acad.loading ? <LoadingSkeleton /> :
                    acad.message ? <AlertNotif messages={acad.message} color='danger' /> :
                        (Object.values(acad.data).length > 0) && (
                            <div className="mb-3">
                                <label className="form-label">Choose Academic Calendar:</label>
                                <select
                                    name="atpid"
                                    className="form-select form-select-sm"
                                    defaultValue={selectedTermID}
                                    onChange={(e) => openEvent(e.target.value)}
                                >
                                    <option value="0">Choose One</option>
                                    {acad.data.map((v, index) => (
                                        <option value={v.id} key={index}>
                                            {v.name && v.name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}


                <div className="events">
                    {mstone.loading ? <LoadingSkeleton /> :
                        mstone.message ? <AlertNotif messages={mstone.message} color='danger' /> :
                            Object.values(mstone.data).length > 0 &&
                            mstone.data.map((v, index) => (
                                <div className="d-flex align-items-center mb-6" key={index}>
                                    <span className="bullet bullet-vertical d-flex align-items-center min-h-70px mh-100 me-4 bg-info"></span>
                                    <div className="flex-grow-1 me-5">
                                        <div className="text-gray-700 fw-bold fs-3">
                                            {moment(v.start_dt).format("Do MMMM YYYY, HH:mm")}
                                        </div>
                                        <div className="text-gray-800 fw-bold fs-6">{v.name}</div>
                                    </div>
                                    <button
                                        className="btn btn-sm btn-light"
                                        type="button"
                                        onClick={() => openModal({ message: <EventDetail data={v} /> })}
                                    >
                                        View
                                    </button>
                                </div>
                            ))}
                </div>
            </SGUCardFlush.Body>
        </SGUCardFlush>
    )
}

const EventDetail = ({ data }) => {
    return (
        <div className="detail">
            <p className="fw-bolder mb-0">Event name:</p>
            <p className="name">{data.name}</p>

            <p className="fw-bolder mb-0">Event Start:</p>
            <p className="name">{data.start_dt_formatted}</p>

            <p className="fw-bolder mb-0">Event End:</p>
            <p className="name">
                {data.end_dt_formatted ? data.end_dt_formatted : "-"}
            </p>

            <p className="fw-bolder mb-0">Event Description:</p>
            <p className="name">{data.descr ? data.descr : "-"}</p>

            <p className="fw-bolder mb-0">Event Term:</p>
            <p className="name">{data.atp ? data.atp.name : "-"}</p>
        </div>
    );
};