import React, { useState } from "react";
import TableData from "./TableData";
import FormLeave from "./FormLeave";

export default function LeaveStd({ stdBio, stdAcad }) {

  const [openForm, setOpenForm] = useState(false);

  return (
    <div id="leave-semester" className="mt-8">
      {openForm ? (
        <>
          <div className="tab-nav">
            <div className="tab-head">
              <ul className="nav">
                <li
                  className="nav-item bg-light border border-bottom-0 p-3 cursor-pointer bg-hover-light-warning text-hover-warning"
                  onClick={() => setOpenForm(false)}
                  title="Back"
                >
                  <i className="bi bi-arrow-left-circle"></i>
                </li>
                <li className="nav-item bg-white border border-bottom-0 p-3">
                  <span className="fw-bolder">Form Leave Semester</span>
                </li>
              </ul>
            </div>
            <div className="tab-body border rounded-bottom p-3">
              <FormLeave
                stdBio={stdBio}
                stdAcad={stdAcad}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="mb-3 d-flex justify-content-between align-items-center">
            <div className="title">
              <h3 className="fw-bold">Request Leave Semester</h3>
              <span className="text-muted">List of leave semester</span>
            </div>
            <div className="text-end">
              <button
                className="btn btn-sm btn-primary"
                type="button"
                onClick={() => setOpenForm(!openForm)}
              >
                <i className="bi bi-plus fs-3"></i>
                <span className="ms-1">Add New</span>
              </button>
            </div>
          </div>
          <TableData studentid={stdAcad.studentid} />
        </>
      )}
    </div>
  );
}
