import React, { useState } from 'react'
import { EnrollmentStdType, StudentIDFormat } from '../../../../../../_layouts/_helpers/Global';

export default function FormOverview({ stdAcad, stdBio }) {
    let enrollment = "";
    if (stdAcad.enrollment_type_id) {
        enrollment = EnrollmentStdType(stdAcad.enrollment_type_id);
    }
    const semester_status = stdAcad.semester_status;
    const total_semes = Object.values(semester_status).length;
    const current_semester = semester_status[(total_semes - 1)];

    return (
        <div className="academic-overview">
            <div className="row">
                <div className="col-12 col-lg-6 col-xxl-6">
                    <LabelItem name={"Status"} value={stdAcad.status && (stdAcad.status.name || "-")} />
                    <LabelItem name={"Student ID"} value={stdAcad.studentid && (StudentIDFormat(stdAcad.studentid) || "-")} />
                    <LabelItem name={"Cumulative Credit Summary"} value={(stdAcad.ccredit_gpa || "0") + " SKS"} />
                    <LabelItem name={"Cumulative Earned Credit"} value={(stdAcad.ccredit_pass || "0") + " SKS"} />
                    <LabelItem name={"Cumulative GPA"} value={(stdAcad.cgpa || "0")} />
                    <LabelItem name={"Current Semester"} value={(current_semester && (current_semester.semester || "0"))} />
                    <LabelItem name={"Current Semester Credit"} value={current_semester && ((current_semester.credit_all || "0") + " SKS")} />
                    <LabelItem name={"SGU Mail"} value={(stdBio.sgumail || "-")} />
                </div>
                <div className="col-12 col-lg-6 col-xxl-6">
                    <LabelItem name={"Term Intake"} value={stdAcad.term_intake && (stdAcad.term_intake.name || "-")} />
                    <LabelItem name={"Enrollment Type"} value={enrollment} />
                    <LabelItem name={"Curriculum"} value={stdAcad.curr && (stdAcad.curr.name || "-")} />
                    <LabelItem name={"Program"} value={stdAcad.program && (stdAcad.program.name || "-")} />
                    {stdAcad.clu && (
                        <>
                            <LabelItem name={"Faculty"} value={stdAcad.clu.faculty && (stdAcad.clu.faculty.name || "-")} />
                            <LabelItem name={"Department"} value={stdAcad.clu.dept && (stdAcad.clu.dept.name || "-")} />
                        </>
                    )}
                    {stdAcad.clu_spec ? (
                        <LabelItem name={"Specialization"} value={stdAcad.clu_spec.name || "-"} />
                    ) : ""}
                    <LabelItem name={"Corporate"} value={stdAcad.corporate && (stdAcad.corporate.name || "-")} />
                </div>
            </div>
        </div>
    )
}

const LabelItem = ({ name, value }) => {
    return (
        <div className="row mb-6">
            <label className='col-lg-5 fw-semibold fs-6 text-muted'>{name}</label>
            <div className="col-lg-7">
                <span className="fw-bolder fs-6 text-gray-800 fs-6">: {value}</span>
            </div>
        </div>
    )
}