import React, { useState } from 'react'
import { ButtonPrimary, ButtonTersier } from '../../../../../_layouts/components/buttons/ButtonUI';
import { openModal } from '../../../../../_layouts/components/modals/Modals';
import { RequiredMsg } from '../../../../../_layouts/_core/ErrorMessage';
import { AlertNotif } from '../../../../../_layouts/components/alerts/AlertsUI';
import { CREATE_COUNTRY, UPDATE_COUNTRY } from '../../../apis/node-target-data/IdentityApi';
import { RemoveEmptyValue } from '../../../../../_layouts/_helpers/Global';

export default function Form({ReloadData, data}) {
    const [paramPost, setParamPost] = useState({
        citizenship: data ? data.citizenship : "",
        code: data ? data.code : "",
        name: data ? data.name : "",
        id: data ? data.id : 0,
        is_active: data ? data.is_active : 1,
    });

    const [submit, setSubmit] = useState({loading:false, data:[], message:""});

    const HandlerSubmit = async (e) =>{
        e.preventDefault();
        if(paramPost.name && paramPost.code){
            setSubmit({loading:true, data:[], message:""});
            const param = RemoveEmptyValue(paramPost);
            let result;
            if(param.id){
                result = await UPDATE_COUNTRY(param);
            }else{
                result = await CREATE_COUNTRY(param);
            }
            
            if(result.data && Object.values(result.data).length > 0){
                ReloadData({"search":encodeURIComponent(paramPost.name)});
                openModal({open:false});
            }else{
                setSubmit(result);
            }            
            
        }else{
            setSubmit({loading:false, data:[], message:RequiredMsg});
        }
    }
    return (
        <form method='post' autoComplete='off' onSubmit={(e)=>HandlerSubmit(e)}>
            <div className="mb-5">
                <h3>Form Country</h3>
                <span className="text-muted">Please fill up the form with correctly</span>
            </div>
            <div className="form-group mb-8">
                <label className="fw-bold required">Name</label>
                <input type="text" required className="form-control form-control-sm" defaultValue={paramPost.name} onChange={(e) => setParamPost({ ...paramPost, name: e.target.value })} />
            </div>

            <div className="form-group mb-8">
                <label className="fw-bold">Citizenship</label>
                <input type="text" className="form-control form-control-sm" defaultValue={paramPost.citizenship} onChange={(e) => setParamPost({ ...paramPost, citizenship: e.target.value })} />
            </div>

            <div className="form-group mb-8">
                <label className="fw-bold required">Code</label>
                <input type="text" required className="form-control form-control-sm w-100px" defaultValue={paramPost.code} onChange={(e) => setParamPost({ ...paramPost, code: e.target.value })} />
            </div>

            {submit.message && (
                <div className="mb-5">
                    <AlertNotif messages={submit.message} />
                </div>
            )}

            <div className="text-end">
                <ButtonTersier className="btn-sm fw-bold me-3" type="button" onClick={() => openModal({ open: false })}>
                    Cancel
                </ButtonTersier>
                <ButtonPrimary className="btn-sm fw-bold" type="submit" disabled={submit.loading}>
                    {submit.loading ? "Processing...":"Save Changes"}
                </ButtonPrimary>
            </div>
        </form>
    )
}
