import React from 'react'
import parse from 'html-react-parser'
import { MaintenanceLayout } from './MaintenanceLayout'
import { MaintenanceDark, MaintenanceLight } from '../../../_layouts/components/images/Maintenances'
import { WAHotline } from '../../../_layouts/_core/Core'
import { Link } from 'react-router-dom'

export function Maintenance({ data }) {

  return (
    <MaintenanceLayout>
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>{(data) ? data.Title : 'UMS is Under Maintenance'}</h1>
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>{(data) && parse(data.Description)}</div>
      <div className='mb-3'>
        <MaintenanceLight className='mw-100 mh-300px theme-light-show' />
        <MaintenanceDark className='mw-100 mh-300px theme-dark-show' />
      </div>
      <div className='mb-0'>
        <Link to={WAHotline} className='btn btn-sm btn-primary'>
          Contact us now
        </Link>
      </div>
    </MaintenanceLayout>
  )
}
