const DataProposalSample = [
    { id: 1, registration_id: "200001114", candidatename: "Febry IST Transfer", institution: "Univ Sunda Empire", total_credit: 30, status: "Draft", created_at: "2022-05-01 14:00:00", created_by: "febri.damatraseta@sgu.ac.id" },
    { id: 2, registration_id: "100001112", candidatename: "Febry IST Test", institution: "Univ Pamulang", total_credit: 35, status: "Approved", created_at: "2022-05-01 18:30:00", created_by: "febri.damatraseta@sgu.ac.id" }
]

const DataCourseSampleByStudent = [
    {id:1, student_id:"200001114", courses:[
        {id:1, course_id:"CSEA-101", coursetitle:"Course A", credit:3, score:90, course_ext:"Prev Course A",credit_ext:3,score_ext:"A"},
        {id:2, course_id:"CSEB-102", coursetitle:"Course B", credit:1, score:70, course_ext:"Prev Course B",credit_ext:1,score_ext:"B+"},
    ]},
    {id:1, student_id:"100001112", courses:[
        {id:3, course_id:"CSEC-101", coursetitle:"Course C", credit:3, score:70, course_ext:"Prev Course C",credit_ext:3,score_ext:70},
        {id:4, course_id:"CSED-102", coursetitle:"Course D", credit:1, score:80, course_ext:"Prev Course D",credit_ext:1,score_ext:80},
    ]}
]
export { DataProposalSample, DataCourseSampleByStudent }