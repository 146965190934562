import React, { useMemo, useState } from 'react'
import { SGUCard } from '../../../../../../../_layouts/components/cards/CardUI';
import { SectionItemLabel } from '../../SectionUI';
import { useOutletContext } from 'react-router-dom';

export function CardCourseOwner() {
    const context = useOutletContext();
    const data = context.data;

    let courseType = "";
    if (Object.values(data.course).length > 0) {
        let courseTypeArr = data.course.course_type.split(".");
        courseType = courseTypeArr[courseTypeArr.length - 1];
    }

    const [totalWeight, setTotalWeight] = useState(0);

    const ResultComponent = useMemo(() => {
        let computedData = data.section_com;
        const tWeight = computedData.reduce((acc, obj) => acc + obj.weight, 0);
        setTotalWeight(tWeight);


        return computedData;

    }, [data, totalWeight]);

    return (
        <SGUCard>
            <SGUCard.Body className={"mb-0"}>
                <div className='mt-8'>
                    <h3 className='mb-5'>Course Owner</h3>
                    <SectionItemLabel name={"Title"} value={data.course && (data.course.coursetitle || "-")} />
                    <div className="d-flex align-items-center justify-content-between">
                        <SectionItemLabel name={"Code"} value={data.course && (data.course.code || "-")} />
                        <SectionItemLabel name={"Credit"} value={data.course && ((data.course.credit + " SKS") || "-")} />
                        <SectionItemLabel name={"Type"} value={courseType.toUpperCase()} />
                    </div>
                </div>
                <div className="componen-score">
                    <h3 className='mb-5'>Component Score</h3>
                    <div className="table-responsive" style={{maxHeight:"250px"}}>
                        <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                            <thead>
                                <tr className='fs-7 fw-bold text-gray-500 border-bottom-0'>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Weight</th>
                                </tr>
                            </thead>
                            <tbody>
                                {ResultComponent.length > 0 ? (
                                    ResultComponent.map((v,index)=>(
                                        <tr key={index}>
                                            <td><span className="text-gray-800 fw-bold text-hover-primary mb-1 fs-6">{decodeURIComponent(v.name)}</span></td>
                                            <td>{v.wg_com && (v.wg_com.type || "")}</td>
                                            <td>{(v.weight * 100).toFixed(0)} %</td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colspan="3">No record found</td>
                                    </tr>
                                )}                                
                            </tbody>
                            <tfoot>
                                <tr className='fw-bold bg-light'>
                                    <td>Total {ResultComponent.length} item</td>
                                    <td colSpan={2} className='text-end'>
                                        Total weight {(totalWeight * 100).toFixed(0)}%
                                    </td>
                                </tr>
                            </tfoot>
                        </table>
                    </div>
                </div>
            </SGUCard.Body>
        </SGUCard>
    )
}
