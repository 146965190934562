import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { SGUCard, SGUCardFlush } from '../../../../_layouts/components/cards/CardUI';
import { LoadingSkeleton, NoRecordFound } from '../../../../_layouts/components/loadbars/LoadingBars';
import { AlertRetry } from '../../../../_layouts/components/alerts/AlertsUI';
import { GET_STUDENT_ACTIVITY, GET_STUDENT_ACTIVITY_DETAIL, GET_STUDENT_DATA_M, GET_STUDENT_STATUS } from '../../apis/node-target-data/AcademicApi';
import { ButtonPrimary } from '../../../../_layouts/components/buttons/ButtonUI';
import { KTIcon } from '../../../../_metronic/helpers';
import { StudentIDFormat } from '../../../../_layouts/_helpers/Global';

export function DetailActivity() {
  let params = useParams();
  const token = params.activity_id;
  const decodeToken = atob(token);
  const objToken = JSON.parse(decodeToken);
  const activity_id = objToken.eventid;
  const studentid = objToken.studentid;

  const [activity, setActivity] = useState({ loading: false, data: [], message: "" });
  const ReloadData = async () => {
    setActivity({ loading: true, data: [], message: "" });
    const param_my_act = { studentid: studentid }
    const result_act = await GET_STUDENT_ACTIVITY(param_my_act);

    let selected_act = null;
    if (result_act.data && Object.values(result_act.data).length > 0) {
      selected_act = result_act.data.find(item => item.id === activity_id);
    }

    const param = { eventid: activity_id }
    const result = await GET_STUDENT_ACTIVITY_DETAIL(param);

    const param_std = { studentid: studentid };
    const std_bio = await GET_STUDENT_DATA_M(param_std);
    const std_acad = await GET_STUDENT_STATUS(param_std);

    if (result.data && std_bio.data && std_acad.data) {
      setActivity({ loading: false, data: { activity: selected_act, activity_detail: result.data, std_acad: std_acad.data, std_bio: std_bio.data }, message: "" });
    } else {
      setActivity(result);
    }
  }

  useEffect(() => {
    ReloadData();
  }, []);

  let totalSKS = 0;

  return (
    <div className='row gx-5 gx-xl-10'>
      <div className="col-xl-8 mb-10">
        <SGUCard>
          <SGUCard.Header>
            {Object.values(activity.data).length > 0 && (
              <>
                <span className="card-label fw-bolder fs-3 mb-1">
                  <span>
                    {activity.data.activity && (activity.data.activity.student_activity && (activity.data.activity.student_activity.name || ""))}
                  </span>
                  <span className="mx-2">
                    Semester
                  </span>
                  <span className='text-primary'>
                    {activity.data.activity && (activity.data.activity.semester || "0")}
                  </span>
                </span>
                <span className="text-muted mt-1 fw-bold fs-7">
                  Details of the Submitted PCF
                </span>
              </>
            )}
          </SGUCard.Header>
          <SGUCard.Toolbar>
            {Object.values(activity.data).length > 0 && (
              activity.data.activity && (
                <ButtonPrimary className="btn-sm" disabled={true}>
                  {activity.data.activity.status_activity || ""}
                </ButtonPrimary>
              )
            )}
          </SGUCard.Toolbar>
          <SGUCard.Body>
            {activity.loading ? <LoadingSkeleton /> :
              activity.message ? <AlertRetry messages={activity.message} Reload={ReloadData} /> :
                Object.values(activity.data).length > 0 ? (
                  <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
                    <thead>
                      <tr className='text-start text-gray-500 fw-bold fs-7 text-uppercase gs-0'>
                        <th width="8%">No</th>
                        <th>Code</th>
                        <th>Course Title</th>
                        <th>Credit & Type</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {activity.data.activity_detail && Object.values(activity.data.activity_detail).length > 0 ? (
                        activity.data.activity_detail.map((v, index) => {
                          totalSKS = totalSKS + ((v.action_type === "asc.course.action.add" && v.course.course_type !== "asc.course.type.optional") ? (v.course) ? v.course.credit : 0 : 0);
                          let courseTypeArr = v.course.course_type.split(".");
                          let courseType = courseTypeArr[courseTypeArr.length - 1];
                          return (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>
                                <span className="fw-bold d-block">{v.course && (v.course.code || "-")}</span>
                                <span className="text-muted fs-7">{v.section_id || ""}</span>
                              </td>
                              <td>{v.course && (v.course.coursetitle || "-")}</td>
                              <td>
                                <span className="fw-bold d-block">{v.course && (v.course.credit || "-")} SKS</span>
                                <span className="text-capitalize text-muted fs-7">{courseType}</span>
                              </td>
                              <td>
                                {(v.action_type === "asc.course.action.add") ? <span className="badge badge-light-info">{v.action.name}</span> : (
                                  (v.action_type === "asc.course.action.remove") ? <span className="badge badge-light-danger">{v.action.name}</span> : ""
                                )}
                              </td>
                            </tr>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan={6}>No record found</td>
                        </tr>
                      )}
                    </tbody>
                    {totalSKS > 0 && (
                      <tfoot>
                        <tr>
                          <td colSpan={3} className='fw-bold text-end'>Cumulative Credit</td>
                          <td colSpan={2} className='fw-bold text-start'>{totalSKS} SKS</td>
                        </tr>
                      </tfoot>
                    )}
                  </table>



                ) : <NoRecordFound />}
          </SGUCard.Body>
        </SGUCard>
      </div>
      <div className="col-xl-4 mb-10">
        <SGUCardFlush>
          <SGUCardFlush.Body>
            {activity.loading ? <LoadingSkeleton /> :
              activity.message ? <AlertRetry messages={activity.message} Reload={ReloadData} /> :
                Object.values(activity.data).length > 0 ? (
                  <div className="detail mt-8">
                    {(activity.data.std_bio && activity.data.std_acad) && (
                      <div className='mb-8'>
                        <h1>{activity.data.std_bio.fullname || "-"}</h1>
                        <span className="text-gray-500 pt-1 fw-semibold fs-6">
                          {StudentIDFormat(activity.data.std_acad.studentid)}
                        </span>
                      </div>
                    )}
                    {activity.data.activity && (
                      <div>
                        <div className="mb-5">
                          <label className="text-muted">Type</label>
                          <p className="mb-0 fw-bold">
                            <span className="d-block">{activity.data.activity.student_activity.name || "-"} Semester {activity.data.activity.semester || "-"}</span>
                            {activity.data.activity.submit_date_formatted && (
                              <>
                                <KTIcon iconName='calendar' className='fs-4 me-1' />
                                <span>{activity.data.activity.submit_date_formatted}</span>
                              </>
                            )}
                          </p>
                        </div>

                        <div className="mb-5">
                          <label className="text-muted">Term</label>
                          <p className="mb-0 fw-bold">
                            {activity.data.activity.atp_term.name || "-"}
                          </p>
                        </div>

                        <div className="mb-5">
                          <label className="text-muted">Counselor</label>
                          {Object.values(activity.data.activity.counselor).length > 0 ? (
                            activity.data.activity.counselor.map((v, index) => (
                              <p className="mb-0 fw-bold" key={index}>
                                {v.emp && (v.emp.fullname || "-")}
                              </p>
                            ))
                          ) : "-"}
                        </div>

                        <div className="mb-5">
                          <label className="text-muted">Status</label>
                          <p className="mb-0 fw-bold">
                            <span className="d-block">{activity.data.activity.status_activity || "-"}</span>
                            {activity.data.activity.approve_date_formatted && (
                              <>
                                <KTIcon iconName='calendar' className='fs-4 me-1' />
                                <span>{activity.data.activity.approve_date_formatted || ""}</span>
                              </>
                            )}

                          </p>
                        </div>

                      </div>
                    )}

                  </div>
                ) : <NoRecordFound />}
          </SGUCardFlush.Body>
        </SGUCardFlush>
      </div>
    </div>

  )
}
