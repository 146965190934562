import React, { useEffect, useState } from "react";
import { GET_SYS_PARAM, POST_SYS_PARAM } from "../../apis/module/BackendRequest";
import {
  GET_ACTIVE_ACAD,
  GET_STUDENT_DATA_M,
} from "../../apis/node-target-data/AcademicApi";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import { CleansingJSON } from "../../../../_layouts/_helpers/Global";
import {
  LoadingSkeleton,
  SpinnerLoad,
} from "../../../../_layouts/components/loadbars/LoadingBars";
import {
  AlertNotif,
  AlertRetry,
} from "../../../../_layouts/components/alerts/AlertsUI";
import { useOutletContext } from "react-router-dom";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import { ButtonPrimary } from "../../../../_layouts/components/buttons/ButtonUI";
import { LinkBtn } from "../../../../_layouts/components/buttons/LinkUI";
import { KTIcon } from "../../../../_metronic/helpers";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { MessageSuccess, openModal } from "../../../../_layouts/components/modals/Modals";

export function FormStdRepresentative() {
  const context = useOutletContext();
  const widget_93 = context.widgets.find(
    (item) => item.widget_id === 93 && item.is_enabled === 1
  );
  const [selectedSemes, setSelectedSemes] = useState({ semes: 0, data: [] });
  const [stdRep, setStdRep] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const [termType, setTermType] = useState("");
  const ReloadData = async () => {
    setStdRep({ loading: true, data: [], message: "" });

    const result_acad = await GET_ACTIVE_ACAD();
    if (result_acad.data && Object.values(result_acad.data).length > 0) {
      const selectedTerm = result_acad.data[0].atp_term_next
        ? result_acad.data[0].atp_term_next.atp_type
        : "";
      if (selectedTerm) {
        //setActiveTerm(result_acad.data[0].atp_term_next);
        const termArr = selectedTerm.split(".");
        const typeName = termArr[
          Object.values(termArr).length - 1
        ].toLowerCase();
        setTermType(typeName);
        const param = { tipe: "std-representative", name: typeName };
        const result = await GET_SYS_PARAM(param);

        if (result.data && Object.values(result.data).length > 0) {
          const data_std = CleansingJSON(result.data);
          const findBySemes = data_std.find(item => item.semes === 1);
          if (findBySemes) {
            setSelectedSemes({ semes: 1, data: findBySemes.dept });
          }
          setStdRep({ loading: false, data: data_std, message: "" });
        } else {
          setStdRep({
            loading: false,
            data: [],
            message: "Failed to retrieve the student representative.",
          });
        }
      } else {
        setStdRep({
          loading: false,
          data: [],
          message: "Failed to retrieve the current active calendar",
        });
      }
    } else {
      setStdRep(result_acad);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const HandlerChangeSemester = (value) => {
    const findBySemes = stdRep.data.find(item => item.semes === value);
    if (findBySemes) {
      setSelectedSemes({ semes: value, data: findBySemes.dept });
    } else {
      alert("No record found");
    }
  }

  const handleStdIdChange = (deptIndex, newStdId) => {
    setStdRep((prevState) => {
      const newData = [...prevState.data];
      const semesIndex = newData.findIndex(item => item.semes === selectedSemes.semes);

      if (semesIndex !== -1) {
        newData[semesIndex] = {
          ...newData[semesIndex],
          dept: newData[semesIndex].dept.map((dept, index) =>
            index === deptIndex ? { ...dept, stdid: newStdId } : dept
          ),
        };
      }

      return {
        ...prevState,
        data: newData,
      };
    });

    // Update selectedSemes state
    setSelectedSemes((prevState) => ({
      ...prevState,
      data: prevState.data.map((dept, index) =>
        index === deptIndex ? { ...dept, stdid: newStdId } : dept
      ),
    }));
  };

  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const HandlerSubmit = async (e) => {
    e.preventDefault();
    setSubmit({ loading: true, data: [], message: "" });
    const postdata = { tipe: "std-representative", name: termType, value: stdRep.data };
    console.log(postdata);
    const result = await POST_SYS_PARAM(postdata);
    if (result.data && Object.values(result.data).length > 0) {
      ReloadData();
      openModal({ message: <MessageSuccess message={"Successfully updated"} /> })
      setSubmit({ loading: false, data: result.data, message: "" });
    } else {
      setSubmit(result);
    }
  };

  return widget_93 ? (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bolder text-dark">
          Update Student Representative
        </span>
        <span className="text-muted mt-1 fw-bold fs-7">
          Please fill up the form with correctly
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        <h4 className="d-flex flex-column align-items-end">
          <span className="card-label fw-bolder text-dark text-uppercase">
            {termType && (termType || "")} Semester
          </span>
          <span className="text-muted mt-1 fw-bold fs-7">
            Active Term
          </span>
        </h4>
      </SGUCard.Toolbar>
      <SGUCard.Body>
        {stdRep.loading ? (
          <LoadingSkeleton />
        ) : stdRep.message ? (
          <AlertRetry messages={stdRep.message} Reload={ReloadData} />
        ) : (
          Object.values(stdRep.data).length > 0 && (
            <form
              method="post"
              autoComplete="off"
              onSubmit={(e) => HandlerSubmit(e)}
            >
              <div className="form-group mb-5">
                <label className="fw-bold required">Choose Semester</label>
                <select name="semes" className="form-select form-select-sm w-150px" defaultValue={selectedSemes.semes} onChange={(e) => HandlerChangeSemester(parseInt(e.target.value))}>
                  <option value="0">Choose One</option>
                  {stdRep.data.map((v, index) => (
                    <option value={v.semes} key={index}>Semester {v.semes}</option>
                  ))}
                </select>
              </div>

              <div className="form-group mb-5">
                <label className="fw-bold required">Student Representative:</label>
                {Object.values(selectedSemes.data).length > 0 && (
                  <div className="table-responsive">
                    <table className="table table-bordered align-middle fs-6 gy-5 mb-0">
                      <thead>
                        <tr className="text-start text-primary fw-bold fs-7 text-uppercase gs-0 bg-light">
                          <th width="5%">No</th>
                          <th>Department</th>
                          <th>Student ID</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedSemes.data.map((d, deptIndex) => (
                          <tr key={deptIndex}>
                            <td>{deptIndex + 1}</td>
                            <td> {d.code} </td>
                            <td>
                              <div className="position-relative">
                                <input
                                  type="text"
                                  className="form-control"
                                  value={d.stdid}
                                  onChange={(e) =>
                                    handleStdIdChange(
                                      deptIndex,
                                      e.target.value
                                    )
                                  }
                                />
                                <RenderOverStudent studentid={d.stdid} />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
              </div>

              {submit.message && (
                <div className="my-5">
                  <AlertNotif messages={submit.message} color="danger" />
                </div>
              )}

              <div className="text-end">
                <LinkBtn
                  to="/calendar/study-program-timetable"
                  className="btn btn-lg btn-light fw-bold me-3"
                >
                  Cancel
                </LinkBtn>
                <ButtonPrimary
                  type="submit"
                  className="btn-lg fw-bold"
                  disabled={submit.loading}
                >
                  {submit.loading ? "Processing" : "Save Change"}
                </ButtonPrimary>
              </div>
            </form>
          )
        )}
      </SGUCard.Body>
    </SGUCard>
  ) : (
    <AlertNotif messages={AccessInvalid} />
  );
}

const RenderOverStudent = ({ studentid }) => {
  return (
    <OverlayTrigger
      delay={{ show: 250, hide: 400 }}
      rootClose
      placement="left"
      overlay={
        <Popover className="p-3">
          <Popover.Body className="p-0">
            <LoadStdData studentid={studentid} />
          </Popover.Body>
        </Popover>
      }
    >
      <div className="position-absolute translate-middle-y top-50 end-0 me-3 cursor-pointer">
        <KTIcon iconName="user-square" className="fs-1 text-hover-primary" />
      </div>
    </OverlayTrigger>
  );
};

const LoadStdData = ({ studentid }) => {
  const [studentBio, setStudentBio] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const ReloadBio = async () => {
    setStudentBio({ loading: true, data: [], message: "" });
    const param = { studentid: studentid };
    const result = await GET_STUDENT_DATA_M(param);
    setStudentBio(result);
  };

  useEffect(() => {
    if (studentid) {
      ReloadBio();
    }
  }, [studentid]);

  if (studentBio.loading) {
    return <SpinnerLoad />;
  } else if (studentBio.message) {
    return <AlertRetry messages={studentBio.message} Reload={ReloadBio} />;
  } else if (Object.values(studentBio.data).length > 0) {
    return (
      <div className="text-capitalize">{studentBio.data.fullname || "-"}</div>
    );
  }
};
