import { useEffect, useRef, useState } from "react";
import { LookUpEmp } from "./look-up-emp/InputLookUp";
import { openModal } from "../../../../_layouts/components/modals/Modals";
import InputLookUp from "./look-up-course/InputLookUp";

const SectionID = ({ data, setData, required = false, disabled = false }) => {
  const [selected, setSelected] = useState(data || "");
  const HandlerChange = (value) => {
    setSelected(value);
    setData(value);
  };
  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        Section Id
      </label>
      <input
        type="text"
        className="form-control form-control-sm"
        disabled={disabled}
        defaultValue={selected}
        onChange={(e) => HandlerChange(e.target.value)}
      />
    </div>
  );
};

const CourseLookUp = ({
  course,
  setCourse,
  setCourseID,
  required = false,
  disabled = false,
  title,
}) => {
  const [selected, setSelected] = useState(course || "");

  const chooseSubject = (value) => {
    setCourse(value);
    setSelected(value);
    setCourseID(value.id);
    openModal({ open: false });
  };

  const openCourseList = () => {
    openModal({
      header: "Select course subject",
      message: <InputLookUp chooseSubject={chooseSubject} />,
      size: "xl",
    });
  };

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Course"}
      </label>
      <div className="input-group" onClick={() => openCourseList()}>
        <input
          type="text"
          readOnly
          className="form-control form-control-sm"
          disabled={disabled}
          defaultValue={selected && selected.code + " " + selected.coursetitle}
        />
        <span className="input-group-text">
          <i className="bi bi-search"></i>
        </span>
      </div>
    </div>
  );
};

const LectLookUp = ({
  lecturer,
  setLecturer,
  setEmp_id,
  required = false,
  disabled = false,
  title,
  showtitle = true,
  placeholder = "",
}) => {
  const [selected, setSelected] = useState(lecturer || "");

  const chooseLec = (e, value) => {
    var myButton = e.target;
    var classSpinner = ["spinner", "spinner-white", "spinner-left"];
    myButton.classList.add(...classSpinner);
    setLecturer(value);
    setSelected(value);
    setEmp_id(value.id);
    openModal({ open: false });
  };
  const openLectList = () => {
    openModal({
      header: "Select lecturer",
      message: <LookUpEmp chooseLec={chooseLec} />,
    });
  };
  return (
    <div className={"form-group " + (showtitle && "mb-5")}>
      {showtitle && (
        <label className={"fw-bold " + (required && "required")}>
          {title ? title : "Lecturer"}
        </label>
      )}
      <div
        className="input-group cursor-pointer"
        onClick={() => openLectList()}
      >
        <input
          type="text"
          readOnly
          className="form-control form-control-sm"
          disabled={disabled}
          defaultValue={selected && selected.name_related}
          placeholder={placeholder}
        />
        <span className="input-group-text">
          <i className="bi bi-search"></i>
        </span>
      </div>
    </div>
  );
};

const StudentID = ({ data, setData, required = false, disabled = false }) => {
  const [selected, setSelected] = useState(data || "");
  const inputRef = useRef(null);

  const HandlerChange = (value) => {
    const numericValue = value.replace(/\D/g, '');
    setSelected(numericValue);
    setData(numericValue);
  };

  useEffect(() => {
    const input = inputRef.current;
    const handlePaste = (e) => {
      e.preventDefault();
      const pastedText = e.clipboardData.getData('text');
      const numericValue = pastedText.replace(/\D/g, '');
      document.execCommand('insertText', false, numericValue);
    };

    input.addEventListener('paste', handlePaste);
    return () => {
      input.removeEventListener('paste', handlePaste);
    };
  }, []);

  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        Student Id
      </label>
      <input
        type="text"
        maxLength={10}
        className="form-control form-control-sm"
        disabled={disabled}
        value={selected}
        onChange={(e) => HandlerChange(e.target.value)}
        onInput={(e) => {
          e.target.value = e.target.value.replace(/\D/g, '');
        }}
        ref={inputRef}
      />
    </div>
  );
};

const StudentName = ({
  data,
  setData,
  required = false,
  disabled = false,
  title,
}) => {
  const [selected, setSelected] = useState(data || "");
  const HandlerChange = (value) => {
    setSelected(value);
    setData(value);
  };
  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        {title ? title : "Student Name"}
      </label>
      <input
        type="text"
        className="form-control form-control-sm"
        disabled={disabled}
        defaultValue={selected}
        onChange={(e) => HandlerChange(e.target.value)}
      />
    </div>
  );
};

const Semester = ({ data, setData, required = false, disabled = false }) => {
  const [selected, setSelected] = useState(data || "");
  const HandlerChange = (value) => {
    setSelected(value);
    setData(value);
  };
  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>Semester</label>
      <input
        type="text"
        className="form-control form-control-sm"
        disabled={disabled}
        defaultValue={selected}
        onChange={(e) => HandlerChange(parseInt(e.target.value))}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

const CourseTitle = ({ data, setData, required = false, disabled = false }) => {
  const [selected, setSelected] = useState(data || "");
  const HandlerChange = (value) => {
    setSelected(value);
    setData(value);
  };
  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        Course Title
      </label>
      <input
        type="text"
        className="form-control form-control-sm"
        disabled={disabled}
        defaultValue={selected}
        onChange={(e) => HandlerChange(e.target.value)}
      />
    </div>
  );
};

const CourseCode = ({ data, setData, required = false, disabled = false }) => {
  const [selected, setSelected] = useState(data || "");
  const HandlerChange = (value) => {
    setSelected(value);
    setData(value);
  };
  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        Course Code
      </label>
      <input
        type="text"
        className="form-control form-control-sm"
        disabled={disabled}
        defaultValue={selected}
        onChange={(e) => HandlerChange(e.target.value)}
      />
    </div>
  );
};

const YearGraduate = ({
  data,
  setData,
  required = false,
  disabled = false,
}) => {
  const [selected, setSelected] = useState(data || "");
  const HandlerChange = (value) => {
    setSelected(value);
    setData(value);
  };
  return (
    <div className="form-group mb-5">
      <label className={"fw-bold " + (required && "required")}>
        Yudisium Year
      </label>
      <input
        type="text"
        disabled={disabled}
        className="form-control form-control-sm"
        maxLength={4}
        defaultValue={selected}
        onChange={(e) => HandlerChange(parseInt(e.target.value))}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
};

const handleKeyDown = (e) => {
  if (
    [8, 46, 9, 27, 13, 37, 38, 39, 40].indexOf(e.keyCode) !== -1 ||
    (e.keyCode === 65 && (e.ctrlKey || e.metaKey)) ||
    (e.keyCode === 67 && (e.ctrlKey || e.metaKey)) ||
    (e.keyCode === 88 && (e.ctrlKey || e.metaKey))
  ) {
    return;
  }

  if (
    (e.shiftKey || e.keyCode < 48 || e.keyCode > 57) &&
    (e.keyCode < 96 || e.keyCode > 105)
  ) {
    e.preventDefault();
  }
};

export {
  SectionID,
  CourseLookUp,
  LectLookUp,
  StudentID,
  StudentName,
  Semester,
  CourseTitle,
  CourseCode,
  YearGraduate,
  handleKeyDown,
};
