import React, { useMemo, useState } from 'react'
import { NoRecordFound } from '../../../../../_layouts/components/loadbars/LoadingBars';
import { BadgeStatus } from '../../academic-milestone/schedule-access/TableData';
import { PaginationComponent } from '../../../../../_layouts/components/datatables/MYDatatables';
import moment from 'moment';

export function ImDtCalendarTables({ data }) {
    const [totalItems, setTotalItems] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 10;
    const headers = [
        { name: "Academic Term", field: "name", sortable: false },
        { name: "Academic Access", field: "name", sortable: false },
        { name: "Current Access", field: "status", sortable: false }
    ];

    const ResultData = useMemo(() => {
        let computedData = data;

        if (data) {
            computedData = data.map(item => {
                const now = moment();
                const start = moment(item.start_dt);
                const end = moment(item.end_dt);
                
                const current_access = now.isBetween(start, end, null, '[]') ? "Open" : "Closed";
                
                return {
                    ...item,
                    current_access
                };
            });
            computedData.sort((a, b) => (a.start_dt > b.start_dt ? -1 : 1));
        }
        setTotalItems(computedData.length);

        if (computedData.length > 0) {
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        } else {
            return [];
        }
    }, [data, currentPage]);

    return (
        <div className='table-responsive mb-8'>
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
                <thead>
                    <tr className='text-gray-500 fw-bold fs-8 text-uppercase'>
                        {headers.map((v, index) => (
                            <th key={index} className='fw-bolder px-3'>{v.name}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {ResultData.length > 0 ? (
                        ResultData.map((v, index) => (
                            <tr key={index}>
                                <td className='fs-7 ps-3'>
                                    <span className="fw-bold">{v.atp && (v.atp.name || "")}</span>
                                </td>
                                <td className="fs-7">
                                    <span className="fw-bold">{v.name}</span>
                                </td>
                                <td className="fs-7">
                                    <BadgeStatus status={v.current_access} />
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan={9}>
                                <NoRecordFound />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>

            {totalItems > 0 && (
                <PaginationComponent
                    total={totalItems}
                    itemsPerPage={ITEMS_PER_PAGE}
                    currentPage={currentPage}
                    onPageChange={(page) => setCurrentPage(page)}
                />
            )}
        </div>
    )
}
