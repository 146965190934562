import React, { useEffect, useState } from 'react'
import ReactHTMLTableToExcel from 'react-html-table-to-excel-3';
import { useOutletContext } from 'react-router-dom';
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';
import { AccessInvalid } from '../../../../_layouts/_core/ErrorMessage';
import { SGUCard } from '../../../../_layouts/components/cards/CardUI';
import { ButtonDownload } from '../../../../_layouts/components/buttons/ButtonUI';
import { MessageError, openModal } from '../../../../_layouts/components/modals/Modals';
import { GET_ROOM_CONFLICT } from '../../apis/node-target-data/AcademicApi';
import { ButtonItemRefresh } from '../../../../_layouts/components/datatables/MYDatatables';
import { LoadingSkeleton } from '../../../../_layouts/components/loadbars/LoadingBars';
import TableData from './TableData';

export function RoomConflicts() {
  const context = useOutletContext();
  const widget_70 = context.widgets.find(
    (item) => item.widget_id === 70 && item.is_enabled === 1
  );
  const title = "Room Conflict";

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadData = async () => {
    setResults({ loading: true, message: "", data: [] });
    const result = await GET_ROOM_CONFLICT();
    setResults(result);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    ReloadData();
  }, [])

  return widget_70 ? (
    <div>
      <SGUCard>
        <SGUCard.Header>
          <span className="card-label fw-bolder text-dark">
            Browse {title}
          </span>
          <span className="text-muted mt-1 fw-bold fs-7">
            List of conflict room
          </span>
        </SGUCard.Header>
        <SGUCard.Toolbar>
          {Object.values(results.data).length > 0 && (
            <>
              <ButtonItemRefresh
                totalItems={Object.values(results.data).length}
                onClick={() => ReloadData()}
              />
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button btn btn-sm btn-info ms-3"
                table="table-to-xls"
                filetype="xls"
                filename="Report Room Conflict"
                sheet="Room Conflict"
                buttonText="Export to excel" />
            </>
          )}
        </SGUCard.Toolbar>
        <SGUCard.Body className={"px-0"}>
          {results.loading ? (
            <div className="px-8"><LoadingSkeleton /></div>
          ) : results.message ? (
            <div className="px-8"><AlertNotif messages={results.message} /></div>
          ) : (
            <TableData results={results} />
          )}
        </SGUCard.Body>
      </SGUCard>
    </div >
  ) : <AlertNotif messages={AccessInvalid} />
}
