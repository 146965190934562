import React, { useEffect, useState } from 'react'
import FilterUI from '../../components/filters/FilterUI'
import { GET_COURSE } from '../../apis/node-target-data/AcademicApi';
import { MessageError, openModal } from '../../../../_layouts/components/modals/Modals';
import { RequiredMsg } from '../../../../_layouts/_core/ErrorMessage';
import { CourseCode, CourseTitle } from '../../components/forms/InputText';
import { CourseOwner, Coursetype } from '../../components/forms/SelectBox';
import { ButtonPrimary } from '../../../../_layouts/components/buttons/ButtonUI';
import { RemoveEmptyValue } from '../../../../_layouts/_helpers/Global';
import { LoadingSkeleton, NoRecordFound } from '../../../../_layouts/components/loadbars/LoadingBars';
import { SGUCard } from '../../../../_layouts/components/cards/CardUI';
import { useLocation, useOutletContext } from 'react-router-dom';
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';
import { TableData } from './TableData';

export function MCourse() {
    const context = useOutletContext();
    const widget_21 = context.widgets.find(
        (item) => item.widget_id === 21 && item.is_enabled === 1
    );
    const location = useLocation();
    const [coursecode, setCoursecode] = useState( (location.state ? (location.state.param.coursecode || "") : "") );
    const [coursetitle, setCoursetitle] = useState((location.state ? (location.state.param.coursetitle || "") : ""));
    const [courseowner, setCourseowner] = useState((location.state ? (location.state.param.courseowner || 0) : 0));
    const [coursetype, setCoursetype] = useState((location.state ? (location.state.param.coursetype || "") : ""));

    const postParam = {
        coursecode, coursetitle, courseowner, coursetype
    }

    const [results, setResults] = useState({
        loading: false,
        message: "",
        data: [],
    });

    useEffect(()=>{
        if(location.state){
            ReloadData();
        }
    },[])

    const ReloadData = async () => {
        setResults({ loading: true, message: "", data: [] });
        const param = RemoveEmptyValue(postParam);
        const result = await GET_COURSE(param);

        setResults(result);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
    };
    const SubmitFilter = (e) => {
        e.preventDefault();
        const param = RemoveEmptyValue(postParam);
        if (Object.keys(param).length > 0) {
            ReloadData();
        } else {
            openModal({
                message: <MessageError message={RequiredMsg} />,
            });
        }
    };

    return (
        <div className="row">
            <div className="col-lg-3">
                <FilterUI
                    title="Master Course"
                    onSubmit={SubmitFilter}
                    isload={results.loading}
                >
                    <CourseCode data={coursecode} setData={setCoursecode} />
                    <CourseTitle data={coursetitle} setData={setCoursetitle} />
                    <CourseOwner data={courseowner} setData={setCourseowner} />
                    <Coursetype data={coursetype} setData={setCoursetype} />
                </FilterUI>
            </div>
            <div className="col-lg-9">
                <SGUCard>
                    <SGUCard.Header>
                        <span className="card-label fw-bold text-gray-800">Master Course Overview</span>
                    </SGUCard.Header>
                    {widget_21 && (
                        <SGUCard.Toolbar>
                            <ButtonPrimary className="btn-sm fw-bold" type="button" onClick={() => alert("Comming Soon")}>
                                <i className={"ki-outline ki-plus-circle fs-3"}></i>
                                Create New Course
                            </ButtonPrimary>
                        </SGUCard.Toolbar>
                    )}
                    <SGUCard.Body>
                        {results.loading ? <LoadingSkeleton /> :
                            results.message ? <AlertNotif messages={results.message} /> :
                                Object.values(results.data).length > 0 ? (
                                    <TableData data={results.data} ReloadData={ReloadData} paramTL={RemoveEmptyValue(postParam)} />
                                ) : <NoRecordFound />}
                    </SGUCard.Body>
                </SGUCard>

            </div>
        </div>
    )
}
