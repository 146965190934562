import { useEffect, useState } from "react";
import { GET_COURSE } from "../../../apis/node-target-data/AcademicApi";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import { LoadingSkeleton } from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../../_layouts/components/alerts/AlertsUI";

const CourseInfo = ({ course_id, title }) => {
  const [courses, setCoureses] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const Get_course_by_id = async () => {
    setCoureses({ loading: true, data: [], message: "" });
    const param = { courseid: course_id };
    const course = await GET_COURSE(param);

    if (course.data && Object.values(course.data).length > 0) {
      const result = course.data.find(
        (item) => item.id === course_id
      );
      setCoureses({ loading: false, data: result, message: "" });
    } else {
      setCoureses(course);
    }
  };

  useEffect(() => {
    Get_course_by_id();
  }, []);

  let course_type = "";
  if (Object.values(courses.data).length > 0) {
    const course_type_arr = courses.data.course_type.split(".");
    course_type = course_type_arr[course_type_arr.length - 1];
    course_type = course_type.toLocaleUpperCase();
  }

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bold fs-3 mb-1">
          {title ? title : "Course Information"}
        </span>
        {/* <span className="text-muted mt-1 fw-semibold fs-7">
          -
        </span> */}
      </SGUCard.Header>
      <SGUCard.Body>
        {courses.loading ? (
          <LoadingSkeleton />
        ) : courses.message ? (
          <AlertRetry messages={courses.message} Reload={Get_course_by_id()} />
        ) : (
          Object.values(courses.data).length > 0 && (
            <div className="detail-course ">
              <div className="row">
                <div className="col-auto">
                  <LabelItem
                    name={"Course Title"}
                    value={courses.data.coursetitle || "-"}
                  />
                </div>
                <div className="col-auto">
                  <LabelItem
                    name={"Course Code"}
                    value={courses.data.code || "-"}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-5">
                  <LabelItem
                    name={"Credit"}
                    value={(courses.data.credit || "0") + " SKS"}
                  />
                </div>
                <div className="col-7">
                  <LabelItem
                    name={"Max Session"}
                    value={courses.data.course_lesson || "-"}
                  />
                </div>
                <div className="col-5">
                  <LabelItem
                    name={"Status"}
                    value={courses.data.status || "-"}
                  />
                </div>
                <div className="col-7">
                  <LabelItem name={"Type"} value={course_type || "-"} />
                </div>
                
                <div className="col-12 col-lg-6 col-xl-12">
                  <LabelItem
                    name={"Program Offerings"}
                    value={
                      (courses.data.program && courses.data.program.name) || "-"
                    }
                  />
                </div>

                <div className={"col-12"}>
                  <LabelItem
                    name={"Academic Term"}
                    value={
                      (courses.data.atp_start && courses.data.atp_start.name) ||
                      "-"
                    }
                  />
                </div>

                {courses.data.atp_end && (
                  <div className="col-6">
                    <LabelItem
                      name={"End Term"}
                      value={
                        (courses.data.atp_end &&
                          courses.data.atp_end.name) ||
                        "-"
                      }
                    />
                  </div>
                )}
              </div>
            </div>
          )
        )}
      </SGUCard.Body>
    </SGUCard>
  );
};

const LabelItem = ({ name, value }) => {
  return (
    <div className="d-flex flex-column mb-5 me-2">
      <label className="text-muted">{name}</label>
      <span className="fw-bold fs-4">{value}</span>
    </div>
  );
};

export { CourseInfo };
