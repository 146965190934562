import ReactDOMServer from 'react-dom/server';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { BlobImg } from '../../../../_layouts/components/images/Logos';

export default async function Export2PDF(filename, jsxContent) {
    const htmlContent = ReactDOMServer.renderToStaticMarkup(jsxContent);
    const doc = new jsPDF('portrait', 'pt', 'a4');
    doc.setFontSize(10); doc.setFont("Helvetica");
    await doc.html(htmlContent, {
        callback: async function (doc) {
            await addHeaderFooter(doc, <HeaderComponent />);
            doc.save(filename + ".pdf");
        },
        x: 30,
        y: 70,
        autoPaging: true,
    });
}

const HeaderComponent = () => {
    const LogoSGU = BlobImg();
    return (
        <div style={styles.container}>
            <table style={styles.table}>
                <tbody>
                    <tr>
                        <td style={{ ...styles.logo, textAlign: "left" }}>
                            <img src={LogoSGU.imglogo} style={{ width: "140px", height: "50px" }} alt="Logo SGU" />
                        </td>
                        <td style={{ ...styles.logo, textAlign: "right" }}>
                            <img src={LogoSGU.imglogoName} style={{ width: "350px", height: "30px" }} alt="Logo SGU" />
                        </td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <div style={styles.border_line}></div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    )
}

const styles = {
    container: { width: "90%", margin: "auto", marginTop: "30px" },
    table: { width: "100%", borderCollapse: "unset" },
    logo: { width: "50%", verticalAlign: "middle" },
    border_line: { border: "1px solid #32499c", marginTop: "8px", marginBottom: "8px" },
    footer: { width: "50%", verticalAlign: "middle", fontSize: "5px", padding: 0 },
    link: { color: "#1B84FF" }
}

const jsxToImage = async (jsxComponent) => {
    const html = ReactDOMServer.renderToStaticMarkup(jsxComponent);
    const container = document.createElement('div');
    container.style.position = 'absolute';
    container.style.left = '-9999px';
    container.innerHTML = html;
    document.body.appendChild(container);

    const canvas = await html2canvas(container, { scale: 3, useCORS: true });
    document.body.removeChild(container);

    return canvas.toDataURL('image/jpeg', 0.5);
};

const addHeaderFooter = async (doc, headerJsx) => {
    //HEADER
    const pageWidth = doc.internal.pageSize.width;
    const headerHeight = 70;
    const headerImage = await jsxToImage(headerJsx);

    doc.addImage(headerImage, 'JPEG', 0, 0, pageWidth, headerHeight);
    //END HEADER

    //FOOTER
    const footerWidth = doc.internal.pageSize.getWidth();
    const footerHeight = doc.internal.pageSize.getHeight() - 30;

    doc.setFontSize(10);

    doc.setDrawColor(50, 73, 156);
    doc.setLineWidth(1);
    doc.line(20, footerHeight - 50, footerWidth - 20, footerHeight - 50);
    doc.setFont('helvetica', 'normal');
    doc.text("SWISS GERMAN UNIVERSITY", 20, footerHeight - 40);
    doc.text("The Prominence Tower", 20, footerHeight - 30);
    doc.text("Jalan Jalur Sutera Barat No.15, Alam Sutera", 20, footerHeight - 20);
    doc.text("Tangerang, Banten 15143 - Indonesia", 20, footerHeight - 10);

    const rightTextX = footerWidth - 20;
    const telText = "Tel. +62 21 2977 9596/9597";
    const faxText = "Fax. +62 21 2977 9598";
    const emailText = "academic@sgu.ac.id";
    const websiteText = "www.sgu.ac.id";

    doc.text(telText, rightTextX - doc.getTextWidth(telText), footerHeight - 40);
    doc.text(faxText, rightTextX - doc.getTextWidth(faxText), footerHeight - 30);
    doc.text(emailText, rightTextX - doc.getTextWidth(emailText), footerHeight - 20);
    doc.text(websiteText, rightTextX - doc.getTextWidth(websiteText), footerHeight - 10);
    //END FOOTER
};