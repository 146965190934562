import React, { useEffect, useMemo, useState } from 'react'
import { SGUCard } from '../../../../../_layouts/components/cards/CardUI'
import { KTIcon } from '../../../../../_metronic/helpers'
import { ButtonIcon, ButtonPrimary } from '../../../../../_layouts/components/buttons/ButtonUI'
import { ButtonItemRefresh, PaginationComponent, SearchBarTable } from '../../../../../_layouts/components/datatables/MYDatatables';
import { LoadingBars } from '../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../../_layouts/components/alerts/AlertsUI';
import { openModal } from '../../../../../_layouts/components/modals/Modals';
import FormUpdate from './FormUpdate';
import { useOutletContext } from 'react-router-dom';
import { GET_GROUPS } from '../../../apis/module/BackendRequest';

export default function GroupList({ activeGroup, setActiveGroup }) {
    const context = useOutletContext();
    const widget_2 = context.widgets.find(
        (item) => item.widget_id === 2 && item.is_enabled === 1
    );
    const widget_3 = context.widgets.find(
        (item) => item.widget_id === 3 && item.is_enabled === 1
    );

    const [groups, setGroups] = useState({ loading: false, data: [], message: "" });
    const ReloadData = async () => {
        setGroups({ loading: true, message: "", data: [] });
        const result = await GET_GROUPS();
        if (result.data && Object.values(result.data).length > 0) {
            const data_group = buildGroupHierarchy(result.data);
            setGroups({ loading: false, message: "", data: data_group });
        } else {
            setGroups(result);
        }
    }

    useEffect(() => {
        ReloadData();
    }, [])

    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 10;

    const ResultData = useMemo(() => {
        const filterByName = (data, searchTerm) => {
            return data.filter(item => {
                const nameMatch = item.name.toLowerCase().includes(searchTerm.toLowerCase());

                const childrenMatch = item.children && item.children.length > 0
                    ? filterByName(item.children, searchTerm).length > 0
                    : false;

                return nameMatch || childrenMatch;
            }).map(item => ({
                ...item,
                children: item.children ? filterByName(item.children, searchTerm) : []
            }));
        };

        let computedData = [];
        if (Object.values(groups.data).length > 0) {
            computedData = groups.data.map(item => ({
                ...item,
                description: item.description || ""
            }));
        }

        // if (search) {
        //     computedData = filterByName(computedData, search);
        //     setActiveGroup();
        // }
        if (search) {
            computedData = computedData.filter((listData) => {
              return Object.keys(listData).some((key) =>
                listData[key]
                  .toString()
                  .toLowerCase()
                  .includes(search)
              );
            });
          }
        setTotalItems(computedData.length);

        //Current Page slice
        if (computedData.length > 0) {
            setActiveGroup();
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        } else {
            return [];
        }
    }, [groups.data, search, currentPage])

    return (
        <div>
            <SGUCard>
                <SGUCard.Header>
                    <span className="card-label fw-bold fs-3 mb-1">Master Groups</span>
                    <span className="text-muted mt-1 fw-semibold fs-7">Over {totalItems} groups</span>
                </SGUCard.Header>
                {widget_2 && (
                    <SGUCard.Toolbar>

                        <ButtonPrimary className="btn-sm" onClick={() => openModal({ header: "Form Group", message: <FormUpdate ReloadData={ReloadData} /> })}>
                            <KTIcon iconName='plus' className='fs-3' />
                            Create New
                        </ButtonPrimary>
                    </SGUCard.Toolbar>
                )}
                <SGUCard.Body>
                    <div className="list-data">
                        <div className="mb-3 d-flex justify-content-between align-items-center">
                            <SearchBarTable size="w-250px" onChange={(e) => setSearch(e.target.value)} />
                            <ButtonItemRefresh totalItems={totalItems} onClick={() => ReloadData()} />
                        </div>

                        <div className="table-responsives">
                            {groups.loading ? <LoadingBars /> :
                                groups.message ? <AlertNotif messages={groups.message} color='danger' /> :
                                    ResultData.length > 0 && (
                                        <div className="scroll-y me-n5 pe-5 h-200px h-lg-auto">
                                            {ResultData.map((v, index) => (
                                                Object.values(v.children).length > 0 ? (
                                                    <GItemGroup data={v} key={index} widget_3={widget_3} ReloadData={ReloadData} setActiveGroup={setActiveGroup} is_selected={activeGroup} />
                                                ) : (
                                                    <GItem data={v} key={index} widget_3={widget_3} ReloadData={ReloadData} setActiveGroup={setActiveGroup} is_selected={activeGroup && activeGroup.group_id === v.group_id && "bg-light-primary rounded"} />
                                                )
                                            ))}

                                            <PaginationComponent
                                                total={totalItems}
                                                itemsPerPage={ITEMS_PER_PAGE}
                                                currentPage={currentPage}
                                                onPageChange={(page) => setCurrentPage(page)}
                                            />
                                        </div>
                                    )}
                        </div>

                    </div>
                </SGUCard.Body>
            </SGUCard>
        </div>
    )
}

const buildGroupHierarchy = (groupItems) => {
    const groupMap = {};

    groupItems.forEach(item => {
        if (!item.children) {
            item.children = [];
        }
        groupMap[item.group_id] = item;
    });

    const hierarchy = [];
    groupItems.forEach(item => {
        if (item.parent_id === 0) {
            hierarchy.push(item);
        } else if (groupMap[item.parent_id]) {
            groupMap[item.parent_id].children.push(item);
        }
    });

    return hierarchy;
}


const GItem = ({ data, widget_3, ReloadData, setActiveGroup, is_selected }) => {
    const HandlerChoose = () => {
        setActiveGroup(data);
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    }
    return (
        <div className={"d-flex flex-stack p-4 " + is_selected}>
            <div className="d-flex align-items-center">
                <div className="symbol symbol-45px symbol-circle">
                    {data.is_active === 1 ? (
                        <span className="symbol-label fw-bolder bg-light-success">
                            <KTIcon iconName='shield-tick' className='text-success fs-2x' />
                        </span>
                    ) : (
                        <span className="symbol-label fw-bolder bg-light-danger">
                            <KTIcon iconName='shield-cross' className='text-danger fs-2x' />
                        </span>
                    )}

                </div>
                <div className="ms-5">
                    <span className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2">
                        {data.name}
                    </span>
                    <div className="fw-bold text-gray-500">
                        {data.description}
                    </div>
                </div>
            </div>
            <div className="btn-group ms-2">
                {widget_3 && (
                    <ButtonIcon className="btn-sm btn-light fw-bolder" type="button" title="Update" onClick={() => openModal({ header: "Form Update Group", message: <FormUpdate data={data} ReloadData={ReloadData} /> })}>
                        <KTIcon iconName='pencil' className='text-primary fs-2x' />
                    </ButtonIcon>
                )}
                <ButtonIcon className="btn-sm btn-light fw-bolder" type="button" title="View" onClick={() => HandlerChoose()}>
                    <KTIcon iconName='arrow-right' className='text-primary fs-2x' />
                </ButtonIcon>
            </div>
        </div>
    )
}

const GItemGroup = ({ data, widget_3, ReloadData, setActiveGroup, is_selected }) => {
    const [open, setOpen] = useState(false);
    return (
        <div className='p-4'>
            <div className={"d-flex flex-stack " + (open ? "bg-light py-3 rounded-top" : "")}>
                <div className="d-flex align-items-center">
                    <div className="symbol symbol-45px symbol-circle">
                        <span className="symbol-label fw-bolder bg-light-warning">
                            <KTIcon iconName='security-user' className='text-warning fs-2x' />
                        </span>
                    </div>
                    <div className="ms-5">
                        <span className="fs-5 fw-bolder text-gray-900 text-hover-primary mb-2">
                            {data.name}
                        </span>
                        <div className="fw-bold text-gray-500">
                            {data.description}
                        </div>
                    </div>
                </div>

                <ButtonIcon type="button" className="btn-sm btn-light fw-bolder" onClick={() => setOpen(!open)}>
                    <KTIcon iconName={open ? 'up' : 'down'} className='fs-2x text-primary' />
                </ButtonIcon>
            </div>
            {open && (
                <div className="ps-10 border rounded-bottom border-top-0">
                    {data.children.map((v, index) => (
                        <GItem data={v} key={index} widget_3={widget_3} ReloadData={ReloadData} setActiveGroup={setActiveGroup} is_selected={is_selected && is_selected.group_id === v.group_id && "bg-light-primary rounded"} />
                    ))}
                </div>
            )}
        </div>
    )
}
