import React, { useMemo, useState } from "react";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  PaginationComponent,
  SearchBarTable,
} from "../../../../../_layouts/components/datatables/MYDatatables";
import { ButtonAdd2Filter } from "./components/CoursesUI";

export function TableData({data, ReloadData, HandlerAddCourse}) {

  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 5;

  const headers = [
    { name: "Course", field: "coursetitle", sortable: true },
    { name: "Credit", field: "credit", sortable: false },
    { name: "Status", field: "credit", sortable: false },
    { name: "Action", field: "id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = data;

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some((key) =>
          //(listData[key]) ? listData[key].includes(search) : ''
          listData[key].includes(search)
        );
      });
    }
    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    //Current Page slice
    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [data, currentPage, search, sorting]);

  return (
    <div className="list-courses">
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <ButtonItemRefresh
          totalItems={totalItems}
          onClick={() => ReloadData()}
        />
      </div>
      <div className="table-responsive">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {ResultData.length > 0 ? (
              ResultData.map((listData, index) => {
                let courseTypeArr = listData.course_type.split(".");
                let courseType = courseTypeArr[courseTypeArr.length - 1];
                return (
                  <tr key={index} className={index % 2 === 0 ? "odd" : "even"}>
                    <td>
                      <span className="d-block text-dark fw-bolder">
                        {listData.coursetitle}
                      </span>
                      <span className="d-block text-muted">
                        {listData.code}
                      </span>
                    </td>
                    <td>
                      <span className="d-block text-dark fw-bolder">
                        Credit {listData.credit}
                      </span>
                      <span className="d-block text-muted text-capitalize">
                        {courseType}
                      </span>
                    </td>
                    <td>
                      <span className={"badge badge-light-"+(listData.status === "ACTIVE" ? "primary":"danger")}>
                        {listData.status}
                      </span>
                    </td>
                    <td>
                      {listData.status === "ACTIVE" && (
                      <ButtonAdd2Filter param={listData} HandlerAddCourse={HandlerAddCourse} />
                    )}
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  <p>No record found</p>
                </td>
              </tr>
            )}
          </tbody>
        </table>

        {ResultData.length > 0 && (
          <div className="footer">
            <PaginationComponent
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        )}
      </div>
    </div>
  );
}
