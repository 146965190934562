import React, { useEffect, useMemo, useState } from 'react'
import { AlertNotif } from '../../../../../_layouts/components/alerts/AlertsUI';
import { RequestData } from '../../../apis/ApiBackend';
import { ButtonItemRefresh, HeaderDatatables, SearchBarTable } from '../../../../../_layouts/components/datatables/MYDatatables';
import { LoadingBars } from '../../../../../_layouts/components/loadbars/LoadingBars';
import { ButtonIcon } from '../../../../../_layouts/components/buttons/ButtonUI';
import { KTIcon } from '../../../../../_metronic/helpers';
import { GET_MEMBER_SYS, POST_ADD_GROUP_BY_USER } from '../../../apis/module/BackendRequest';

export default function FormUpdate({ group_id, ReloadData, HandlerRemove }) {
    const [users, setUsers] = useState({ loading: false, message: "", data: [] });
    const FetchAllUser = async () => {
        setUsers({ loading: true, data: [], message: "" })
        const result = await GET_MEMBER_SYS();
        setUsers(result);
    }

    useEffect(() => {
        FetchAllUser();
    }, [])

    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });
    const headers = [
        { name: "No", field: "user_id", sortable: false },
        { name: "Email", field: "email", sortable: true },
        { name: "Group", field: "group_name", sortable: true },
        { name: "Action", field: "user_id", sortable: false },
    ];

    const ResultData = useMemo(() => {
        let computedData = [];
        if (Object.values(users.data).length > 0) {
            computedData = users.data.map(item => ({
                ...item,
                group_name: item.group_name || "",
                group_id: item.group_id || "",
            }));
        }

        if (search) {
            computedData = computedData.filter((listData) => {
                return Object.keys(listData).some((key) =>
                    listData[key].toString().toLowerCase().includes(search)
                );
            });
        }
        setTotalItems(computedData.length);

        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }

        return computedData;
    }, [users.data, search, sorting]);

    const [submit, setSubmit] = useState({ loading: false, message: "", data: [] });
    const handlerUpdate = (e) => {
        const is_checked = e.target.checked;
        const user_id = e.target.value;
        const param = { group_id: parseInt(group_id), user_id: parseInt(user_id) };
        if (is_checked) {
            SubmitUserFromGroup(param, setSubmit);
            setTimeout(() => {
                FetchAllUser();
                ReloadData();
            }, 1000);
        } else {
            RemoveUserFromGroup(param, setSubmit);
            setTimeout(() => {
                FetchAllUser();
                ReloadData();
            }, 1000);
        }
    }

    const HandlerRemoveGroup = async (user_id)=>{
        const result = await HandlerRemove(user_id, group_id, FetchAllUser, false)
        if(result.data && Object.values(result.data).length > 0){
            setTimeout(() => {
                ReloadData();
            }, 1000);
        }
    }
    return (
        <div>
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <SearchBarTable size="w-250px" onChange={(e) => setSearch(e.target.value)} />
                <ButtonItemRefresh totalItems={totalItems} onClick={() => FetchAllUser()} />
            </div>
            <div className='table-responsive' style={{ maxHeight: "350px" }}>
                <table className="table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4">
                    <HeaderDatatables
                        headers={headers}
                        onSorting={(field, order) => setSorting({ field, order })}
                    />
                    <tbody>
                        {users.loading ? <LoadingBars /> :
                            users.message ? <AlertNotif messages={users.message} /> :
                                ResultData.length > 0 ? (
                                    ResultData.map((v, index) => (
                                        <tr key={index} className={v.is_active === 0 && "bg-light-danger"}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <span className="d-block fw-bold">{v.email}</span>
                                                <span className="text-muted">{v.employeeid}</span>
                                            </td>
                                            <td>
                                                <span className="d-block fw-bold">
                                                    {v.group_name}
                                                </span>
                                                <span className="text-muted">{v.is_active === 1 ? "Active" : "Not Active"}</span>
                                            </td>
                                            <td className="text-center">
                                                {(v.is_active === 1 && (
                                                (!v.group_id) ? (
                                                    <div className="form-check form-switch">
                                                        <input
                                                            type="checkbox"
                                                            name="is_group"
                                                            className="form-check-input"
                                                            defaultValue={v.user_id}
                                                            onChange={(e) => handlerUpdate(e)}
                                                        />
                                                    </div>
                                                ):(
                                                    <ButtonIcon className="btn-sm" onClick={()=>HandlerRemoveGroup(v.user_id)} >
                                                        <KTIcon iconName='trash' className='text-danger fs-2x' />
                                                    </ButtonIcon>
                                                )
                                            ))}
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td>No record found</td>
                                    </tr>
                                )}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const SubmitUserFromGroup = async (param, setSubmit) => {

    const result = await POST_ADD_GROUP_BY_USER(param);
    
    if (result.data && Object.values(result.data).length > 0) {
        setSubmit(result.data)
    } else {
        alert("Failed change. Try again later");
    }
}

const RemoveUserFromGroup = async (param, setSubmit) => {

    const config = {
        method: "post",
        pathuri: "api/group/member/remove",
        payload: param
    }
    const result = await RequestData(config);

    if (result.data && Object.values(result.data).length > 0) {
        setSubmit(result.data)
    } else {
        alert("Failed change. Try again later");
    }
}

