import React, { useEffect, useRef, useState } from "react";
import "./TableStudentScore.css";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import { ButtonItemRefresh } from "../../../../../_layouts/components/datatables/MYDatatables";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import {
  ButtonDownload,
  ButtonPrimary,
  ButtonTersier,
} from "../../../../../_layouts/components/buttons/ButtonUI";
import { MessageError, MessageSuccess, openModal } from "../../../../../_layouts/components/modals/Modals";
import { TableBody, TableHeader } from "./score-numeric";
import { SUBMIT_SCR_STD_BY_LEC_SCR_COM } from "../../../apis/node-target-data/AcademicApi";
import { DownloadScore } from "../DownloadScore";

export default function TableScoreNumeric({ scores, section, ReloadData, widget_38 }) {
  const scrollbarHolderRef = useRef(null);
  const tableWrapperRef = useRef(null);
  const tableRef = useRef(null);

  const [selectedColumn, setSelectedColumn] = useState();

  const score_component = scores.section_com;
  const student = scores.student;
  const isreadonly = scores.allowed === 1 ? false : true;
  //const isreadonly = false;
  const is_thesis = section.course ? section.course.is_thesis : false;

  const [studentScore, setStudentScore] = useState([...student]);

  let totalWeight = score_component.reduce((total, component) => {
    return total + component.weight * 100;
  }, 0);
  totalWeight.toFixed(0);

  useEffect(() => {
    const scrollbarHolder = scrollbarHolderRef.current;
    const tableWrapper = tableWrapperRef.current;
    const table = tableRef.current;

    if (table) {
      const handleScroll = () => {
        if (tableWrapper) {
          tableWrapper.scrollLeft = scrollbarHolder.scrollLeft;
        }
      };

      const updateDummyWidth = () => {
        if (scrollbarHolder.firstChild) {
          scrollbarHolder.firstChild.style.width = `${table.offsetWidth}px`;
        }
      };

      if (!scrollbarHolder.firstChild) {
        const dummyDiv = document.createElement("div");
        scrollbarHolder.appendChild(dummyDiv);
      }

      updateDummyWidth();

      scrollbarHolder.addEventListener("scroll", handleScroll);
      window.addEventListener("resize", updateDummyWidth);

      return () => {
        scrollbarHolder.removeEventListener("scroll", handleScroll);
        window.removeEventListener("resize", updateDummyWidth);
      };
    }
  }, []);

  const HandlerSubmit = () => {
    const com_id = selectedColumn;
    if (com_id) {
      var objScr = [];
      studentScore.map((v) => {
        if (v.atd < 25) {
          if (Object.values(v.student_course_com).length > 0) {
            const getScore = v.student_course_com.find(
              (prev) => prev.wg_com.id === com_id
            );
            objScr.push({
              studentid: v.studentid.toString(),
              score: typeof 0 === getScore.score ? 0 : getScore.score,
            });
          }
        }
      });
      if (Object.values(objScr).length > 0) {
        let objParam = {
          section_id: section.section_id,
          wg_com_id: com_id,
          student: objScr,
        };
        const isScoreNull = objParam.student.find((item) => item.score === "");
        if (isScoreNull) {
          openModal({
            message: (
              <MessageError message={"Score cannot be empty. All fields must be filled before saving."}/>
            ),
          });
        } else {
          SUBMIT_SCORE(objParam);
        }
      } else {
        openModal({
          message: <MessageAlertScore message={"No data post"} />,
        });
      }
    } else {
      openModal({
        header: "Error",
        message: <MessageAlertScore message={"Component id is not defined"} />,
      });
    }
  };

  const [results, setResults] = useState({loading:false, data:[], message:""});

  const SUBMIT_SCORE = async (param) =>{
    setResults({loading:true, data:[], message:""});
    const result = await SUBMIT_SCR_STD_BY_LEC_SCR_COM(param);
    if(result.data && Object.values(result.data).length > 0){
      openModal({message:<MessageSuccess message={"Data has been successfully saved"} />});
      ReloadData();
    }else{
      const iserror = result.message.includes("02001");
      let msg = "";
      if(iserror){
        msg = "Failed to submit grades due to a data error. Error:"+result.message;
      }else{
        msg = result.message;
      }
      openModal({message:<MessageError message={msg} />});
    }
    setResults(result);
  }

  const [isDownload, setIsDownload] = useState(false);
  const HandlerDownload = () =>{
    DownloadScore(section.section_id, setIsDownload)
  } 

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bold fs-3 mb-1">
          Submit Score Student
        </span>
        <span className="text-muted mt-1 fw-semibold fs-7">
          Student list for section {section.section_id}
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        <div className="d-flex">
          <div className="me-2">
            <ButtonItemRefresh
              totalItems={Object.values(student).length}
              onClick={() => ReloadData()}
            />
          </div>
          {Object.values(student).length > 0 && (
            <ButtonDownload disabled={isDownload} type="button" className="btn-sm" onClick={()=>HandlerDownload()}>
              {isDownload ? "Processing...":"Export to excel"}
            </ButtonDownload>
          )}
        </div>
      </SGUCard.Toolbar>
      <SGUCard.Body className={"px-0"}>
        {isreadonly && (
          <div className="px-8 mt-5">
            <AlertNotif
              title={"Grading Period Closed"}
              messages={"Grade submission has been closed. No further entries or edits can be made. Please contact the administration if you have any questions or require assistance."}
            />
          </div>
        )}

        {totalWeight !== 100 && (
          <div className="px-8 mt-5">
            <AlertNotif
              title={"Attention"}
              messages={
                "Total weight " +
                totalWeight +
                "% is " +
                (totalWeight < 1 ? "less than" : "over") +
                " 100%, please update the weights of components"
              }
            />
          </div>
        )}

        <div
          className="table-responsive"
          style={{ width: "100%", overflow: "hidden" }}
        >
          <div
            className="scrollbar-holder"
            ref={scrollbarHolderRef}
            style={{ overflowX: "auto", overflowY: "hidden", height: "20px" }}
          ></div>
          <div
            className="table-wrapper"
            ref={tableWrapperRef}
            style={{ overflowX: "auto", overflowY: "hidden" }}
          >
            <table
              className="table table-row-bordered gy-4 gs-9 align-middle "
              id="table-component-score"
              style={{ minWidth: "600px", width: "100%" }}
              ref={tableRef}
            >
              <TableHeader
                score_component={score_component}
                selectedColumn={selectedColumn}
              />
              <tbody>
                <TableBody
                  score_component={score_component}
                  selected={selectedColumn}
                  setSelected={setSelectedColumn}
                  studentScore={studentScore}
                  setStudentScore={setStudentScore}
                  SUBMIT_SCORE={SUBMIT_SCORE}
                  isreadonly={isreadonly}
                  is_thesis={is_thesis}
                  section_id={section.section_id}
                />
              </tbody>
              <tfoot>
                <tr>
                  <td className="freeze-column"></td>
                  {score_component.map((v, index) => (
                    <td
                      key={index}
                      className={selectedColumn === v.wg_com_id && "active"}
                    >
                      {widget_38 && selectedColumn === v.wg_com_id && (
                        <div className="d-grid gap-2">
                          <ButtonPrimary
                            className="btn-sm btn-primary"
                            type="button"
                            disabled={results.loading}
                            onClick={() => HandlerSubmit()}
                          >
                            {results.loading ? (
                              <>
                                <span
                                  className="spinner-border spinner-border-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                <span className="sr-only">Processing...</span>
                              </>
                            ) : (
                              "Save"
                            )}
                          </ButtonPrimary>
                          <ButtonTersier
                            className="btn-sm btn-block"
                            type="button"
                            onClick={() => {
                              setSelectedColumn(null);
                            }}
                          >
                            Cancel
                          </ButtonTersier>
                        </div>
                      )}
                    </td>
                  ))}
                  <td colSpan={5}></td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </SGUCard.Body>
    </SGUCard>
  );
}
