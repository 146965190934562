import React from 'react'
import { ButtonPrimary } from '../../../../_layouts/components/buttons/ButtonUI';
import { SpinnerLoad } from '../../../../_layouts/components/loadbars/LoadingBars';

export function FilterCalendar(props) {
  const { children, onSubmit, isload } = props;
  return (
    <form method="post" autoComplete="off" onSubmit={(e) => onSubmit(e)}>
      <div className="d-flex justify-content-start align-items-center">
        {children}

        <div className="filter-item">
          <ButtonPrimary type="submit" className="btn-sm fw-bold" disabled={isload}>
            {isload ? <SpinnerLoad /> : (
              <div className="d-flex align-items-center">
                <i className="bi bi-search fs-1x"></i>
                <span className="ms-2">Search</span> 
              </div>
            )}
          </ButtonPrimary>
        </div>
      </div>
    </form>
  )
}
