import React from "react";
import ReactDOM from "react-dom/client";
import { persistStore } from "redux-persist";
import { createStore } from "redux";
import reportWebVitals from './reportWebVitals';
import App from "./app/App";
import { authReducer } from "./app/reducer/AuthRedux";

// import { QueryClient, QueryClientProvider } from 'react-query'
// import { ReactQueryDevtools } from 'react-query/devtools'

import { MetronicI18nProvider } from './_metronic/i18n/Metronici18n'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/fonticon/fonticon.css'
import './_metronic/assets/keenicons/duotone/style.css'
import './_metronic/assets/keenicons/outline/style.css'
import './_metronic/assets/keenicons/solid/style.css'
import './_metronic/assets/sass/style.scss'
// import { GET_ENVIRONMENT } from "./_layouts/_core/Core";

const { PUBLIC_URL } = "./";

const store = createStore(
  authReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);


const persistor = persistStore(store);
// const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById("root"));
// const TYPE_ENV = GET_ENVIRONMENT();
// const baseUri = TYPE_ENV.backenduri;



root.render(
  // <QueryClientProvider client={queryClient}>
    <MetronicI18nProvider>
      <App store={store} persistor={persistor} basename={PUBLIC_URL} />
    </MetronicI18nProvider>
  //   {baseUri.includes("localhost") && ( <ReactQueryDevtools initialIsOpen={false} /> )}
  // </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
