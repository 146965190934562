import React, { useEffect, useState } from 'react'
import { AlertNotif, AlertRetry } from '../../../../_layouts/components/alerts/AlertsUI';
import { AccessInvalid } from '../../../../_layouts/_core/ErrorMessage';
import { useOutletContext, useParams } from 'react-router-dom';
import TableScoreLetter from './components/TableScoreLetter';
import TableScoreNumeric from './components/TableScoreNumeric';
import { GET_SCR_LECTURER_SECTION, GET_SECTION } from '../../apis/node-target-data/AcademicApi';
import { LoadingSkeleton, NoRecordFound } from '../../../../_layouts/components/loadbars/LoadingBars';
import { SGUCardFlush } from '../../../../_layouts/components/cards/CardUI';

export function DetailClassroomScore() {
  const context = useOutletContext();
  const widget_37 = context.widgets.find(
    (item) => item.widget_id === 37 && item.is_enabled === 1
  );
  

  const params = useParams();
  const token = params.token;
  try {
    const decode = atob(token);
    return widget_37 ? (
      <DetailPage section_id={decode} />
    ) : (
      <AlertNotif messages={AccessInvalid} />
    );
  } catch (error) {
    return <Error404 />;
  }
}

const DetailPage = ({ section_id }) => {
  const context = useOutletContext();
  const widget_38 = context.widgets.find(
    (item) => item.widget_id === 38 && item.is_enabled === 1
  );
  const [result, setResult] = useState({ loading: false, data: [], message: "" });
  const [scores, setScores] = useState();
  const ReloadData = async () => {
    setResult({ loading: true, message: "", data: [] });
    const param = { section_id: section_id };
    const result = await GET_SECTION(param);

    if (result.data && Object.values(result.data).length > 0) {
      const result_score = await GET_SCR_LECTURER_SECTION(param);
      if (result_score.data && Object.values(result_score.data).length > 0) {
        setScores(result_score.data);
      }

      setResult({ loading: false, message: "", data: result.data[0] });
    } else {
      setResult(result);
    }

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  useEffect(() => {
    ReloadData();
  }, [])

  return (
    result.loading ? <LoadingSkeleton /> :
      result.message ? <AlertRetry messages={result.message} Reload={ReloadData} /> :
        Object.values(result.data).length > 0 && Object.values(scores).length > 0 ? (
          <div className="detail-score">
            <div className="info-class row">
              <div className="col-auto">
                <CardClass name={"Course Title"} value={result.data.course && (result.data.course.coursetitle || "")} />
              </div>
              <div className="col-auto">
                <CardClass name={"Course Code"} value={result.data.course && (result.data.course.code || "")} />
              </div>
              <div className="col-auto">
                <CardClass name={"Course Credit"} value={result.data.course && (result.data.course.credit || "-") + " SKS"} />
              </div>
            </div>
            <div className="">
              {scores.score_type === 1 ? (
                <TableScoreNumeric scores={scores} section={result.data} ReloadData={ReloadData} widget_38={widget_38} />
              ) : (
                <TableScoreLetter scores={scores} section={result.data} ReloadData={ReloadData} widget_38={widget_38} />
              )}
            </div>
          </div>
        ) : <NoRecordFound />
  )
}

const CardClass = ({ name, value }) => {
  return (
    <SGUCardFlush>
      <SGUCardFlush.Body className={"pt-8"}>
        <div className="d-flex flex-column">
          <span className="fs-2hx fw-bold text-gray-900 me-2 lh-1 ls-n2">{value}</span>
          <span className="text-gray-500 pt-1 fw-semibold fs-6">{name}</span>
        </div>
      </SGUCardFlush.Body>
    </SGUCardFlush>
  )
}