import ExcelJs from "exceljs";

const SheetAttd = (wb, result, param) => {
    const tableHeader = [
        { key: "A", title: "No", width: 5 },
        { key: "B", title: "StudentID", width: 15 },
        { key: "C", title: "Student Name", width: 35 },
        { key: "D", title: "Study Program", width: 20 },
        { key: "E", title: "Department", width: 40 },
        { key: "F", title: "Specialization", width: 40 },
    ];

    const headers = tableHeader;
    const ws = wb.addWorksheet("Section " + param.section_id);
    const cloneHeader = [...headers];

    const newHeader = cloneHeader.map((obj) => {
        var x = {};
        x.key = obj.key;
        x.width = obj.width;
        return x;
    });
    ws.columns = newHeader;

    ws.getRow(1).getCell("A").value = "Section Id " + param.section_id;
    ws.getRow(1).getCell("A").font = {
        bold: true,
        size: 14,
    };

    ws.getRow(2).getCell("A").value = "Course";
    ws.getRow(2).getCell("B").value = ": " + ((param.course) ? param.course.coursetitle : "");
    ws.getRow(3).getCell("A").value = "Credit";
    ws.getRow(3).getCell("B").value = ": " + ((param.course) ? param.course.credit : "");
    let actType = "";
    if(param.course){
        let actTypeArr = param.course.course_type.split(".");
        actType = actTypeArr[actTypeArr.length - 1];
    }    
    ws.getRow(4).getCell("A").value = "Type ";
    ws.getRow(4).getCell("B").value = ": " + actType;
    
    let rowNumber = 6;
    const rowHeader = ws.getRow(rowNumber);
    tableHeader.map((v, index) => {
        var i = index + 1;
        rowHeader.getCell(i).value = v.title;
        rowHeader.getCell(i).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
        };
        rowHeader.getCell(i).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "191970" },
        };
        rowHeader.getCell(i).alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        rowHeader.getCell(i).font = {
            bold: true,
            size: 11,
            color: { argb: "FFFFFF" },
        };
    });

    rowNumber = rowNumber + 1;
    var num = 1;
    result.sort((a, b) => {
        if (a.studentstatusdata !== null && b.studentstatusdata !== null) {
            if (a.studentstatusdata.fullname < b.studentstatusdata.fullname) {
                return -1;
            }
            if (a.studentstatusdata.fullname > b.studentstatusdata.fullname) {
                return 1;
            }
        }
        return 0;
    });
    result.map((v) => {
        const row = ws.getRow(rowNumber);
        row.getCell(1).value = num++;
        row.getCell(2).value = (v.studentid) ? v.studentid : "-";
        row.getCell(3).value = (v.studentstatusdata) ? (v.studentstatusdata.fullname ? v.studentstatusdata.fullname : "-"): "-";
        row.getCell(4).value = (v.clu) ? (v.clu.program ? v.clu.program.name : "-") : "-";
        row.getCell(5).value = (v.clu) ? v.clu.name : "-";
        row.getCell(6).value = (v.clu_spec) ? v.clu_spec.name : "";
        rowNumber++;
    });
};

const DownloadStudentList = (data, param) => {
    const wb = new ExcelJs.Workbook();
    wb.creator = "UMS-SGU";
    wb.lastModifiedBy = "UMS-SGU-System";
    wb.created = new Date();
    wb.modified = new Date();
    wb.lastPrinted = new Date();
    //body here
    SheetAttd(wb, data, param);
    //end body here

    const writeFile = (fileName, content) => {
        const link = document.createElement("a");
        const blob = new Blob([content], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        link.download = fileName;
        link.href = URL.createObjectURL(blob);
        link.click();
    };

    wb.xlsx.writeBuffer().then((buffer) => {
        writeFile(
            "Studentlist Section -" + param.section_id,
            buffer
        );
    });
};

export default DownloadStudentList;
