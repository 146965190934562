import React, { useEffect, useMemo, useState } from "react";
import moment from "moment";
import {
  MessageError,
  openModal,
} from "../../../../../../_layouts/components/modals/Modals";
import { ConfirmLeavePage, ConfirmStatusScore } from "../MessageConfirm";
import { LinkBtn } from "../../../../../../_layouts/components/buttons/LinkUI";
import { StudentIDFormat } from "../../../../../../_layouts/_helpers/Global";

export function TableBody(props) {
  const { score_component, selected, setSelected, studentScore, setStudentScore, isreadonly, is_thesis, SUBMIT_SCORE, section_id } = props;
  const [scrStudent, setScrStudent] = useState([]);
  const [total_weight, setTotalWeight] = useState(0);
  const [selectedColumn, setSelectedColumn] = useState(selected);

  useEffect(() => {
    setSelectedColumn(selected || null);
  }, [selected])

  useEffect(() => {
    const hasStudentData = studentScore.some((item) => item.studentdata === null);
    if (hasStudentData) {
      setScrStudent(studentScore);
    } else {
      const ascStudent = studentScore
        .filter((item) => item.studentdata && item.studentdata.fullname)
        .sort((a, b) =>
          a.studentdata.fullname > b.studentdata.fullname ? 1 : -1
        );
      setScrStudent(ascStudent);
    }
  }, [studentScore]);

  useEffect(() => {
    let totalWeight = score_component.reduce((total, component) => {
      return total + component.weight * 100;
    }, 0);
    totalWeight.toFixed(0);
    setTotalWeight(totalWeight);
  }, [score_component]);

  const ResultData = useMemo(() => {
    const studentsWithScores = [...scrStudent];
    studentsWithScores.forEach((student) => {
      score_component.forEach((scItem) => {
        const alreadyExists = student.student_course_com.some(
          (item) => item.wg_com.id === scItem.wg_com.id
        );
        if (!alreadyExists) {
          student.student_course_com.push({
            id: Object.values(student.student_course_com).length + 1,
            score: "",
            submitted: "",
            submittedby: "",
            wg_com: scItem.wg_com,
          });
        }
      });
    });

    return studentsWithScores;
  }, [scrStudent, score_component]);

  const handleColumnClick = (wg_com_id, studentid) => {
    if (!isreadonly) {
      if (!is_thesis) {
        if (total_weight.toFixed(0) === 100) {
          openModal({
            header: "Information",
            message: (
              <p>
                Total weight ({total_weight}%) is{" "}
                {total_weight < 1 ? "less than" : "over"} 100%, please update
                the weights of components
              </p>
            ),
          });
        } else {
          if (wg_com_id) {
            if (selectedColumn && selectedColumn !== wg_com_id) {
              var objScr = [];
              scrStudent.map((v) => {
                if (v.atd < 25) {
                  if (Object.values(v.student_course_com).length > 0) {
                    const getScore = v.student_course_com.find(
                      (prev) => prev.wg_com.id === selectedColumn
                    );
                    objScr.push({
                      studentid: v.studentid.toString(),
                      score: typeof 0 === getScore.score ? 0 : getScore.score,
                    });
                  }
                }
              });

              const FindNullScore = objScr.every((item) => item.score === "");
              //console.log(FindNullScore);
              const IsHalfFillScore = objScr.some((item) => item.score !== "");
              //console.log(IsHalfFillScore);
              if (FindNullScore) {
                //setSelectedColumn(wg_com_id);
                setSelected(wg_com_id);

                setTimeout(() => {
                  const inputToFocus = document.querySelector(
                    `input[data-comp-id="${wg_com_id}"][data-student-id="${studentid}"]`
                  );
                  if (inputToFocus) {
                    inputToFocus.focus();
                  }
                }, 0);
              } else if (IsHalfFillScore) {
                openModal({
                  header: "Confirmation",
                  message: <ConfirmLeavePage SUBMIT_SCORE={SUBMIT_SCORE} param={scrStudent} wg_com_id={selected} section_id={section_id} />,
                });
                return;
              } else {
                alert("unknown");
              }
            } else {
              //setSelectedColumn(wg_com_id);
              setSelected(wg_com_id);

              setTimeout(() => {
                const inputToFocus = document.querySelector(
                  `input[data-comp-id="${wg_com_id}"][data-student-id="${studentid}"]`
                );
                if (inputToFocus) {
                  inputToFocus.focus();
                }
              }, 0);
            }
          }
        }
      }
    }
  };

  const HandlerInputScore = (studentId, id, compId, value) => {
    if (value > 100) {
      openModal({
        message: (
          <MessageError
            message={
              "The score cannot exceed 100. Please input a value between 0 and 100."
            }
          />
        ),
      });
      return;
    }
    if (value || Number(value) === 0) {
      const updatedScores = scrStudent.map((student) => {
        if (student.studentid === studentId) {
          const scoreIndex = student.student_course_com.findIndex(
            (scc) => scc.wg_com.id === compId && scc.id === id
          );
          if (scoreIndex > -1) {
            student.student_course_com[scoreIndex].score = Number(value) || 0;
          }
        }
        return student;
      });
      setScrStudent(updatedScores);
      setStudentScore(updatedScores);
    }
  };

  return ResultData.length > 0 ? (
    ResultData.map((v, index) => (
      <tr key={index} data-student-id={v.studentid}>
        <td
          className={"freeze-column " + (v.atd > 25 ? "bg-light-danger" : "")}
        >
          <div className="d-flex flex-stack">
            <div className="symbol symbol-40px me-4">
              <div className="symbol-label fs-2 fw-semibold bg-light-primary text-primary">
                {index + 1}
              </div>
            </div>
            <div className="w-250px d-flex align-items-center flex-row-fluid flex-wrap">
              <LinkBtn to={"/students/search/" + v.studentid} target="_blank">
                <span className="d-block fw-bolder h5 text-dark text-hover-primary">
                  {v.studentdata
                    ? v.studentdata.fullname
                      ? v.studentdata.fullname
                      : "-"
                    : "-"}
                </span>
                <span className="text-muted">{StudentIDFormat(v.studentid)}</span>
              </LinkBtn>
            </div>
          </div>
        </td>
        {score_component.map((comp) => {
          const compScore = v.student_course_com.find(
            (sc) => sc.wg_com.id === comp.wg_com.id
          );
          return (
            <td
              key={comp.wg_com.id}
              className={
                (selectedColumn === comp.wg_com_id ? "active" : "") +
                " " +
                (v.atd > 25 ? "bg-light-danger" : "")
              }
              onClick={() => handleColumnClick(comp.wg_com_id, v.studentid)}
            >
              <div className="d-flex justify-content-center cursor-pointer">
                {selectedColumn === comp.wg_com_id ? (
                  <input
                    type="text"
                    data-comp-id={comp.wg_com_id}
                    data-student-id={v.studentid}
                    readOnly={v.atd > 25 ? true : false}
                    className="form-control text-center w-60px"
                    value={compScore ? compScore.score.toString() : ""}
                    tabIndex={index + 1}
                    onChange={(e) =>
                      HandlerInputScore(
                        v.studentid,
                        compScore.id,
                        compScore.wg_com.id,
                        e.target.value
                      )
                    }
                  />
                ) : (
                  <input
                    type="text"
                    readOnly
                    className="form-control cursor-pointer w-60px text-center"
                    value={compScore ? compScore.score.toString() : ""}
                    title={
                      compScore && compScore.submitted
                        ? "Last updated by " +
                        compScore.submittedby.fullname +
                        " at " +
                        moment(compScore.submitted).format(
                          "DD MMM YYYY hh:mm a"
                        )
                        : "Click to open form"
                    }
                  />
                )}
              </div>
            </td>
          );
        })}
        <td className={"text-center "+(v.atd > 25 ? "bg-light-danger" : "")}>{v.score}</td>
        <td className={"text-center "+(v.atd > 25 ? "bg-light-danger" : "")}>
          {v.grade_fin ? v.grade_fin : ""}
        </td>
        <td className={"text-center "+(v.atd > 25 ? "bg-light-danger" : "")}>{v.atd}%</td>
        <td className={"text-center "+(v.atd > 25 ? "bg-light-danger" : "")}>
          {isreadonly ? (
            v.course_status
          ) : (
            v.course_status !== "INCOMPLETE" ? (
              <select
                name="status_std"
                className="form-select w-100px"
                value={"0"}
                onChange={(e) =>
                  openModal({
                    header: "Confirmation",
                    message: <ConfirmStatusScore student={v.studentdata} id={e.target.value} />,
                  })
                }
              >
                <option value="0">
                  {v.course_status ? v.course_status : ""}
                </option>
                <option value={v.id}>INCOMPLETE</option>
              </select>
            ) : (
              v.course_status
            )
          )}
        </td>
      </tr>
    ))
  ) : (
    <tr>
      <td colSpan={Object.values(score_component).length + 7}>
        No record found
      </td>
    </tr>
  );
}
