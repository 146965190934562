import React, { useState } from "react";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import { ButtonIcon } from "../../../../../_layouts/components/buttons/ButtonUI";
import { CourseOwner } from "../../../components/forms/SelectBox";
import moment from "moment";

export default function FormCourse({ data }) {
  let course_type = "";
  const course_type_arr = data.course_type.split(".");
  course_type = course_type_arr[course_type_arr.length - 1];
  course_type = course_type.toLocaleUpperCase();
  const [postParam, setPostParam] = useState({
    coursetitle : data.coursetitle || "",
    coursetranscript: data.coursetranscript || "",
    code : data.code || "",
    ects : data.ects || "",
    credit : data.credit || "",
    atp_start_id : data.atp_start && (data.atp_start.name || ""),
    atp_end_id : data.atp_start && (data.atp_start.name || ""),
    course_owner: data.course_owner || "",
    course_type: data.course_type || "",
    program: data.program.name || "",
    score_type: data.score_type ?  (data.score_type === 1 ? "Numeric":"Letter") : "",
    status: data.status || "",
    is_internship: data.is_internship || "",
    is_thesis: data.is_thesis || "",
    course_lesson: data.course_lesson || "",
  });

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bold text-gray-800">
          Course Overview
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar>
        <ButtonIcon
          type="button"
          className="btn-sm btn-light btn-active-light-primary"
        >
          <i className={"ki-outline ki-notepad-edit fs-3"}></i>
        </ButtonIcon>
      </SGUCard.Toolbar>
      <SGUCard.Body>
        <form method="post" autoComplete="off">
          <div className="form-group mb-5">
            <label className="fw-bold">Course Title</label>
            <input
              type="text"
              className="form-control form-control-sm"
              readOnly
              defaultValue={postParam.coursetitle}
            />
          </div>
          <div className="form-group mb-5">
            <label className="fw-bold">Course Transcript</label>
            <input
              type="text"
              className="form-control form-control-sm"
              readOnly
              defaultValue={postParam.coursetranscript}
            />
          </div>
          <div className="row">
            <div className="col-3">
                <div className="form-group mb-5">
                    <label className="fw-bold">Course Code</label>
                    <input
                    type="text"
                    className="form-control form-control-sm"
                    readOnly
                    defaultValue={postParam.code}
                    />
                </div>
            </div>
            <div className="col-2">
                <div className="form-group mb-5">
                    <label className="fw-bold">Credit</label>
                    <input
                    type="text"
                    className="form-control form-control-sm"
                    readOnly
                    defaultValue={postParam.credit}
                    />
                </div>
            </div>
            <div className="col-2">
                <div className="form-group mb-5">
                    <label className="fw-bold">ECTS</label>
                    <input
                    type="text"
                    className="form-control form-control-sm"
                    readOnly
                    defaultValue={postParam.ects}
                    />
                </div>
            </div>
            <div className="col-2">
                <div className="form-group mb-5">
                    <label className="fw-bold">Max Session</label>
                    <input
                    type="text"
                    className="form-control form-control-sm"
                    readOnly
                    defaultValue={postParam.course_lesson}
                    />
                </div>
            </div>
            <div className="col-3">
                <div className="form-group mb-5">
                    <label className="fw-bold">Type</label>
                    <input
                    type="text"
                    className="form-control form-control-sm"
                    readOnly
                    defaultValue={course_type}
                    />
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-5">
                <CourseOwner data={postParam.course_owner} disabled={true} />
            </div>
            <div className="col-4">
                <div className="form-group mb-5">
                    <label className="fw-bold">Program</label>
                    <input
                    type="text"
                    className="form-control form-control-sm"
                    readOnly
                    defaultValue={postParam.program}
                    />
                </div>
            </div>
            <div className="col-5">
                <div className="form-group mb-5">
                    <label className="fw-bold">Start Term</label>
                    <input
                    type="text"
                    className="form-control form-control-sm"
                    readOnly
                    defaultValue={postParam.atp_start_id}
                    />
                </div>
            </div>
            <div className="col-4">
                <div className="form-group mb-5">
                    <label className="fw-bold">End Term</label>
                    <input
                    type="text"
                    className="form-control form-control-sm"
                    readOnly
                    defaultValue={postParam.atp_end_id}
                    />
                </div>
            </div>
          </div>
          <div className="row">
            <div className="col-2">
                <div className="form-group mb-5">
                    <label className="fw-bolder">Score Type</label>
                    <input type="text" className="form-control form-control-sm" defaultValue={postParam.score_type} />
                </div>
            </div>
            <div className="col-2">
                <div className="form-group mb-5">
                    <label className="fw-bolder">Status</label>
                    <input type="text" className="form-control form-control-sm" defaultValue={postParam.status} />
                </div>
            </div>
          </div>

          <div className="notice d-flex bg-light-primary rounded border-primary border border-dashed  p-6">
            <i className="ki-duotone ki-bank fs-2tx text-primary me-4"></i>
            <div className="d-flex flex-stack flex-grow-1 flex-wrap flex-md-nowrap">
                <div className="mb-3 mb-md-0 fw-semibold">
                    <h4 className="text-gray-900 fw-bold">Version {data.ver_nbr}</h4>
                    <div className="d-flex align-items-center">
                        <div className="fs-6 text-gray-700">
                            <p className="mb-0">Created By: <span className="fw-bolder">{data.audit_createdby || "-"}</span></p>
                            <p className="mb-0"><span className="fw-bolder">{data.audit_created || ""}</span></p>
                        </div>
                        <div className="fs-6 text-gray-700 ms-5">
                            <p className="mb-0">Updated By: <span className="fw-bolder">{data.audit_editedby || "-"}</span></p>
                            <p className="mb-0"><span className="fw-bolder">{data.audit_edited && moment(data.audit_edited).format("DD MMM YYYY h:mm A") || "-"}</span></p>
                        </div>
                    </div>
                </div>
                <span className="bg-primary text-white rounded px-6 py-3 fw-bold align-self-center text-nowrap">
                    {data.doc_status}
                </span>
            </div>
          </div>
        </form>
      </SGUCard.Body>
    </SGUCard>
  );
}
