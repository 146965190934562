import ExcelJs from "exceljs";
import { MessageError, openModal } from "../../../../_layouts/components/modals/Modals";
import { GET_THS } from "../../apis/node-target-data/AcademicApi";
import { BlobImg } from "../../../../_layouts/components/images/Logos";
import moment from "moment";

const DownloadThesisSchedule = async (param, filename, setStatus) =>{
    try {
        setStatus(true);
        const result = await GET_THS(param);

        if (result.data && Object.values(result.data).length > 0) {
            ScheduleDownload(result.data, filename)
        }else{
            openModal({ message: <MessageError message={result.message} /> })
        }
        setStatus(false);
    } catch (error) {
        console.log(error);
        openModal({message:<MessageError message={"Failed to download. Please try again later."} />})
        setStatus(false);
    }
}

const ScheduleDownload = (data, filename) => {
    const wb = new ExcelJs.Workbook();
    wb.creator = "UMS-SGU";
    wb.lastModifiedBy = "UMS-SGU-System";
    wb.created = new Date();
    wb.modified = new Date();
    wb.lastPrinted = new Date();

    //body here
    SheetSchedule(wb, data, filename);
    //end body here

    const writeFile = (fileName, content) => {
        const link = document.createElement("a");
        const blob = new Blob([content], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
        });
        link.download = fileName;
        link.href = URL.createObjectURL(blob);
        link.click();
    };

    wb.xlsx.writeBuffer().then((buffer) => {
        writeFile(
            "Report Thesis Score Periode "+decodeURIComponent(filename),
            buffer
        );
    });
};

const SheetSchedule = (wb, data, termname) =>{
    const term_name = decodeURIComponent(termname);
    const ws = wb.addWorksheet(decodeURIComponent(termname));
    const HeaderXLS = BlobImg();
    const imageId1 = wb.addImage({
        fileName: "logo-1",
        extension: "png",
        base64: HeaderXLS.imglogo,
    });
    const imageId2 = wb.addImage({
        fileName: "logo-2",
        extension: "png",
        base64: HeaderXLS.imglogoName,
    });

    ws.addImage(imageId1, "A1:B2");
    ws.addImage(imageId2, "E2:F2");
    let rowNumber = 4;

    ws.getRow(rowNumber).getCell("A").value = "Thesis Evaluation Report for Term Period " + term_name;
    ws.getRow(rowNumber).getCell("A").font = {
        bold: true,
        size: 16,
    };
    ws.getRow(rowNumber).getCell("A").border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
    };
    ws.getRow(rowNumber).getCell("A").alignment = { horizontal: "center" };
    ws.mergeCells("A"+rowNumber+":F"+rowNumber);
    rowNumber = rowNumber + 2;

    const tableHeader = [
        { key: "A", title: "No" },
        { key: "B", title: "Student ID" },
        { key: "C", title: "Student Name" },
        { key: "D", title: "Department" },
        { key: "E", title: "Specialization" },
        { key: "F", title: "Section ID" },
        { key: "G", title: "Title" },
        { key: "H", title: "Advisor" },
        { key: "I", title: "Co-Advisor" },
        { key: "J", title: "Defense Date"},
        { key: "K", title: "Due Date"},
        { key: "L", title: "Room"},
        { key: "M", title: "Examiner"}
    ];

    const rowHeader = ws.getRow(rowNumber);
    tableHeader.map((v, index) => {
        var i = index + 1;
        RowHeader(rowHeader, i, v.title)
    });
    rowNumber = rowNumber + 1;

    if(Object.values(data).length > 0){
        data.map((v,index)=>{
            const row = ws.getRow(rowNumber);
            let curCell = 1;
            row.getCell(curCell++).value = (index + 1);
            row.getCell(curCell++).value = v.studentid;
            row.getCell(curCell++).value = v.studentdata ? (v.studentdata.fullname || "") : "";
            row.getCell(curCell++).value = v.studentdata ? ((v.studentdata.clu && v.studentdata.clu.name) || "") : "";
            row.getCell(curCell++).value = v.studentdata ? ((v.studentdata.clu_spec && v.studentdata.clu_spec.name) || "") : "";
            row.getCell(curCell++).value = v.section_id;
            row.getCell(curCell++).value = v.final_title;
            row.getCell(curCell++).value = v.advisor ? (v.advisor.fullname || "") : "";
            row.getCell(curCell++).value = v.coadvisor ? (v.coadvisor.fullname || "") : "";
            let defense_date = "";
            if(v.defensedate){
                defense_date = moment(v.defensedate+" "+v.defensetime).format("DD MMM YYYY, hh:mm A");
            }
            row.getCell(curCell++).value = defense_date;
            let duedate = "";
            if(v.duedate){
                duedate = moment(v.duedate).format("DD MMM YYYY");
            }
            row.getCell(curCell++).value = duedate;
            row.getCell(curCell++).value = v.room ? (v.room.name || "" ) : "";
            const TotalExaminer = Object.values(v.ths_main_ex).length;
            let examiner = "";
            if(TotalExaminer > 0){
                v.ths_main_ex.map((v, index)=>{
                    examiner += (index+1)+". "+(v.emp && (v.emp.fullname || ""))+"\n";    
                });
            }
            row.getCell(curCell++).value = examiner;
            row.eachCell({ includeEmpty: true }, (cell) => {
                cell.border = {
                  top: { style: "thin" },
                  left: { style: "thin" },
                  bottom: { style: "thin" },
                  right: { style: "thin" },
                };
                cell.alignment = {
                    vertical: "top",
                    horizontal: "left",
                    wrapText: true,
                };
            });
            rowNumber = rowNumber + 1;
        })

        ws.columns.forEach((column, index) => {
            if (index === 0) {
              column.width = 5;
            } else if (index === 1 || index === 5 || index === 13) {
              column.width = 15;
            } else if (index === 3 || index === 4 || index === 9 || index === 10 || index === 11 || index === 12) {
                column.width = 20;
            } else if (index === 6) {
                column.width = 30;
            } else {
              let maxColumnLength = 0;
              column.eachCell({ includeEmpty: true }, cell => {
                let cellLength = cell.value ? cell.value.toString().length : 0;
                if (cellLength > maxColumnLength) {
                  maxColumnLength = cellLength;
                }
              });
              column.width = maxColumnLength < 10 ? 10 : maxColumnLength;
            }
        });
    }



}

const RowHeader = (rowHeader, i, value) => {
    rowHeader.getCell(i).value = value;
    rowHeader.getCell(i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
    };
    rowHeader.getCell(i).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "191970" },
    };
    rowHeader.getCell(i).alignment = {
        vertical: "middle",
        horizontal: "center",
    };
    rowHeader.getCell(i).font = {
        bold: true,
        size: 11,
        color: { argb: "FFFFFF" },
    };
}

export {DownloadThesisSchedule}