import { FC } from 'react'
import { Link } from 'react-router-dom'
import { Error404Dark, Error404Light } from '../../../../_layouts/components/images/Error404'
import { ErrorsLayout } from '../ErrorsLayout'

const Error404: FC = () => {
  return (
    <ErrorsLayout>
      {/* begin::Title */}
      <h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>
      {/* end::Title */}

      {/* begin::Text */}
      <div className='fw-semibold fs-6 text-gray-500 mb-7'>We can't find that page.</div>
      {/* end::Text */}

      {/* begin::Illustration */}
      <div className='mb-3'>
        <Error404Light className='mw-100 mh-300px theme-light-show' />
        <Error404Dark className='mw-100 mh-300px theme-dark-show' />
      </div>
      {/* end::Illustration */}

      {/* begin::Link */}
      <div className='mb-0'>
        <Link to='/dashboard' className='btn btn-sm btn-primary'>
          Return Home
        </Link>
      </div>
      {/* end::Link */}
    </ErrorsLayout>
  )
}

export { Error404 }
