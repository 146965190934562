import React, { useState } from 'react'
import { MessageError, openModal } from '../../../../_layouts/components/modals/Modals';
import { LoadingSkeleton, NoRecordFound } from '../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';
import { RenderSectionList } from './components/SectionUI';
import { RemoveEmptyValue } from '../../../../_layouts/_helpers/Global';
import { GET_SECTION } from '../../apis/node-target-data/AcademicApi';
import FilterSection from './components/FilterSection';

export function BrowseSection() {
  const [results, setResults] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const ReloadData = async (param) => {
    setResults({ loading: true, message: "", data: [] });
    const result = await GET_SECTION(param);

    setResults(result);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };


  const SubmitFilter = (filterparam) => {
    const param = RemoveEmptyValue(filterparam);
    if (param) {
      ReloadData(param);
    } else {
      openModal({
        message: <MessageError message={RequiredMsg} />,
      });
    }
  };



  return (
    <div className='section-browse row'>
      <div className="col-xl-3 mb-xl-10">
        <FilterSection SubmitFilter={SubmitFilter} results={results} setResults={setResults} />
      </div>
      <div className="col-xl-9 mb-5 mb-xl-10">
        {results.loading ? <div className="bg-white p-5 ronded"><LoadingSkeleton /></div> : (
          results.message ? <AlertNotif messages={results.message} /> :
            Object.values(results.data).length > 0 ? (
              <div className='section-list'>
                <h3>Section List ({Object.values(results.data).length})</h3>
                {results.data.map((v, index) => (
                  <RenderSectionList data={v} key={index} />
                ))}
              </div>
            ) :  <NoRecordFound />
        )}
      </div>
    </div>
  );
}
