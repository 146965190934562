import React from "react";
import moment from "moment";
import ExcelJs from "exceljs";
import { GET_ATD_LECTURER_BY_SECTION_WITH_STUDENT, GET_SECTION } from "../apis/node-target-data/AcademicApi";
import { MessageError, openModal } from "../../../_layouts/components/modals/Modals";
import { BlobImg } from "../../../_layouts/components/images/Logos";
import { StudentIDFormat } from "../../../_layouts/_helpers/Global";

const DownloadAtdSummary = async (section_id, setStatus) => {
  try {
    setStatus(true);
    const param = { section_id: section_id };
    const result = await GET_SECTION(param);

    if (result.data && Object.values(result.data).length > 0) {

      const result_attd = await GET_ATD_LECTURER_BY_SECTION_WITH_STUDENT(param);
      if (result_attd.data && Object.values(result_attd.data).length > 0) {
        const results = result_attd.data.map((v) => {
          var obj = {};
          obj.atdid = v.id;
          obj.start_dt = v.start_dt;
          obj.end_dt = v.end_dt;
          obj.approved_time = v.approved_time;
          obj.submit_formatted = v.submit_formatted;
          obj.status = v.status;
          obj.teaching_topic = v.teaching_topic ? v.teaching_topic : "-";
          obj.course = v.course;
          obj.emp = v.emp;
          obj.section_id = v.section_id;
          obj.weight = v.weight;

          if (Object.values(v.atd_student).length > 0) {
            v.atd_student.sort((a, b) =>
              a.studentstatusdata.fullname > b.studentstatusdata.fullname ? 1 : -1
            );

            const studentLst = v.atd_student.map((s) => {
              var obj2 = {};
              obj2.remark = s.remark ? s.remark : "";
              obj2.status = s.status;
              obj2.studentid = s.studentid;
              obj2.studentname = s.studentstatusdata
                ? s.studentstatusdata.fullname
                : "-";
              return obj2;
            });
            obj.studets = studentLst;
          } else {
            obj.studets = [];
          }

          return obj;
        })
        AttdDownload(results, result.data[0]);
      } else {
        openModal({ message: <MessageError message={result_attd.message} /> })
      }
    } else {
      openModal({ message: <MessageError message={"Norecord found"} /> })
    }

    setStatus(false);
  } catch (error) {
    openModal({ message: <MessageError message={"Failed to download. Please try again later."} /> })
    console.log(error);
    setStatus(false);
  }
};


const AttdDownload = (data, section) => {
  const wb = new ExcelJs.Workbook();
  wb.creator = "UMS-SGU";
  wb.lastModifiedBy = "UMS-SGU-System";
  wb.created = new Date();
  wb.modified = new Date();
  wb.lastPrinted = new Date();
  //body here
  SheetAttd(wb, data, section);
  SheetAttdLect(wb, data, section);
  //end body here
  const fileName = "Report Attendance SectionID " + section.section_id;

  const writeFile = (fileName, content) => {
    const link = document.createElement("a");
    const blob = new Blob([content], {
      type:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
    });
    link.download = fileName;
    link.href = URL.createObjectURL(blob);
    link.click();
  };

  wb.xlsx.writeBuffer().then((buffer) => {
    writeFile(fileName, buffer);
  });
};

const RowHeader = (rowHeader, i, value) => {
  rowHeader.getCell(i).value = value;
  rowHeader.getCell(i).border = {
    top: { style: "thin" },
    left: { style: "thin" },
    bottom: { style: "thin" },
    right: { style: "thin" },
  };
  rowHeader.getCell(i).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "191970" },
  };
  rowHeader.getCell(i).alignment = {
    vertical: "middle",
    horizontal: "center",
    wrapText: true,
  };
  rowHeader.getCell(i).font = {
    bold: true,
    size: 11,
    color: { argb: "FFFFFF" },
  };
}

const SheetAttd = (wb, data, section) => {
  const tableHeader = [
    { key: "A", title: "No", width: 5 },
    { key: "B", title: "Student ID", width: 15 },
    { key: "C", title: "Student Name", width: 35 },
  ];

  const course = section.course;
  const course_title = course.coursetitle.replace(/[^a-zA-Z0-9 ]/g, '');
  const ws = wb.addWorksheet("Attendance Student ");

  const HeaderXLS = BlobImg();
  const imageId1 = wb.addImage({
    fileName: "logo-1",
    extension: "png",
    base64: HeaderXLS.imglogo,
  });
  const imageId2 = wb.addImage({
    fileName: "logo-2",
    extension: "png",
    base64: HeaderXLS.imglogoName,
  });

  ws.addImage(imageId1, "A1:B2");
  ws.addImage(imageId2, "D1:E2");

  let rowNumber = 4;
  ws.getRow(rowNumber).getCell("A").value = "Attendance Report for Section " + section.section_id;
  ws.getRow(rowNumber).getCell("A").font = {
    bold: true,
    size: 16,
  };
  ws.getRow(rowNumber).getCell("A").border = {
    top: { style: "thin" },
    bottom: { style: "thin" },
  };
  ws.getRow(rowNumber).getCell("A").alignment = { horizontal: "center" };
  ws.mergeCells("A" + rowNumber + ":E" + rowNumber);
  rowNumber = rowNumber + 2;

  ws.getRow(rowNumber).getCell("A").value = "Academic Term: " + (section.atp_term && (section.atp_term.name || "-"));
  ws.mergeCells("A" + rowNumber + ":C" + rowNumber);
  rowNumber = rowNumber + 1;

  ws.getRow(rowNumber).getCell("A").value = "Course Title: " + course_title;
  ws.mergeCells("A" + rowNumber + ":C" + rowNumber);
  rowNumber = rowNumber + 1;

  ws.getRow(rowNumber).getCell("A").value = "Course Code: " + course.code;
  ws.mergeCells("A" + rowNumber + ":C" + rowNumber);
  rowNumber = rowNumber + 1;

  ws.getRow(rowNumber).getCell("A").value = "Credit: " + course.credit + " SKS";
  ws.mergeCells("A" + rowNumber + ":C" + rowNumber);
  rowNumber = rowNumber + 1;

  const totalLecturer = Object.values(section.lecturer).length;
  let lecturerName = "";
  if (totalLecturer > 0) {
    section.lecturer.map(item => {
      lecturerName += (item.emp && item.emp.fullname || "-") + "; ";
    })
  }

  ws.getRow(rowNumber).getCell("A").value = "Lecturer: " + lecturerName;
  ws.mergeCells("A" + rowNumber + ":C" + rowNumber);
  rowNumber = rowNumber + 2;

  const rowHeader = ws.getRow(12);
  tableHeader.map((v, index) => {
    var i = index + 1;
    RowHeader(rowHeader, i, v.title)
  });
  rowNumber = rowNumber + 1;

  if (Object.values(data[0].studets).length > 0) {
    //column student
    const startRow = 14;
    let rowStudent = startRow;
    var num = 1;
    data[0].studets.map((v) => {
      const row = ws.getRow(rowStudent);
      let curCell = 1;
      row.getCell(curCell++).value = num++;
      row.getCell(curCell++).value = StudentIDFormat(v.studentid);
      row.getCell(curCell++).value = v.studentname;
      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });
      rowStudent++;
    });
    ws.mergeCells("A12:A13");
    ws.mergeCells("B12:B13");
    ws.mergeCells("C12:C13");
    //end column student

    //column attendance schd
    const startRowSchd = 12;
    const rowSchd = startRowSchd;
    let columnSchd = 4;
    data.map((v) => {
      const row = ws.getRow(rowSchd);
      RowHeader(row, columnSchd++, (moment(v.start_dt).format("DD MMM YYYY") + "\n" + moment(v.start_dt).format("hh:mm a") + "-" + moment(v.end_dt).format("hh:mm a")))
    });
    //end column attendance schd

    //column teaching topic
    const startRowTopic = 13;
    const rowTopic = startRowTopic;
    columnSchd = 4;
    data.map((v) => {
      ws.getColumn(columnSchd).width = 35;
      const row = ws.getRow(rowTopic);
      RowHeader(row, columnSchd++, "Topic: \n" + v.teaching_topic)
    });
    //end column teaching topic

    //column attendance each student
    const startRowAtd = 14;
    let rowAtd = startRowAtd;
    columnSchd = 4;
    data[0].studets.map((s) => {
      const row = ws.getRow(rowAtd);
      data.map((a) => {
        const cell = row.getCell(columnSchd++);
        const selectedStd = a.studets.find(
          (item) => item.studentid === s.studentid
        );
        cell.value = selectedStd && (selectedStd.status || "-");
        cell.alignment = {
          vertical: "middle",
          horizontal: "center",
        };
      });

      row.eachCell({ includeEmpty: true }, (cell) => {
        cell.border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      });

      columnSchd = 4;
      rowAtd++;
    });
    //end column attendance each student

    // column status attendance
    const startStatusLabel = rowAtd;
    columnSchd = 1;
    const rowStatusLabel = ws.getRow(startStatusLabel);
    const cellStatusLabel = rowStatusLabel.getCell(columnSchd);
    cellStatusLabel.value = "Status Attendance";
    cellStatusLabel.border = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };
    cellStatusLabel.alignment = {
      vertical: "middle",
      horizontal: "right",
      wrapText: true,
    };
    cellStatusLabel.font = {
      bold: true,
      size: 11,
    };
    ws.mergeCells("A" + startStatusLabel + ":C" + startStatusLabel);

    const startStatusLabelDate = startStatusLabel + 1;
    columnSchd = 1;
    const rowStatusLabelDate = ws.getRow(startStatusLabelDate);
    const cellStatusLabelDate = rowStatusLabelDate.getCell(columnSchd);
    cellStatusLabelDate.value = "Approved At ";
    cellStatusLabelDate.border = {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    };
    cellStatusLabelDate.alignment = {
      vertical: "middle",
      horizontal: "right",
      wrapText: true,
    };
    cellStatusLabelDate.font = {
      bold: true,
      size: 11,
    };
    ws.mergeCells("A" + startStatusLabelDate + ":C" + startStatusLabelDate);

    const rowStatus = rowAtd;
    columnSchd = 4;
    data.map((v) => {
      const row = ws.getRow(rowStatus);
      const cell = row.getCell(columnSchd++);
      cell.value = v.status;
      cell.border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      cell.font = {
        bold: true,
        size: 11,
      };
    });

    const rowStatusDate = rowStatus + 1;
    columnSchd = 4;
    data.map((v) => {
      const row = ws.getRow(rowStatusDate);
      const cell = row.getCell(columnSchd++);
      cell.value = v.approved_time
        ? moment(v.approved_time).format("DD MMM YYYY hh:mm a")
        : "-";
      cell.border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      };
      cell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      };
      cell.font = {
        bold: true,
        size: 11,
      };
    });
  }

  ws.columns.forEach((column, index) => {
    if (index === 0) {
      column.width = 5;
    } else if (index === 1) {
      column.width = 15;
    } else {
      let maxColumnLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        let cellLength = cell.value ? cell.value.toString().length : 0;
        if (cellLength > maxColumnLength) {
          maxColumnLength = cellLength;
        }
      });
      column.width = maxColumnLength < 10 ? 10 : maxColumnLength;
    }
  });
};

const SheetAttdLect = (wb, data, section) => {
  const schedules = data;
  const tableHeader = [
    { key: "A", title: "No", width: 5 },
    { key: "B", title: "Start Date", width: 30 },
    { key: "C", title: "End Date", width: 30 },
    { key: "D", title: "Lecturer", width: 30 },
    { key: "E", title: "Teaching Topic", width: 30 },
    { key: "F", title: "Session", width: 15 },
    { key: "G", title: "Submited Date", width: 30 },
    { key: "H", title: "Approved Date", width: 10 },
    { key: "I", title: "Status", width: 20 },
  ];

  const course = section.course;
  const course_title = course.coursetitle.replace(/[^a-zA-Z0-9 ]/g, '');
  const ws = wb.addWorksheet("Attendance Lecturer");

  const HeaderXLS = BlobImg();
  const imageId1 = wb.addImage({
    fileName: "logo-1",
    extension: "png",
    base64: HeaderXLS.imglogo,
  });
  const imageId2 = wb.addImage({
    fileName: "logo-2",
    extension: "png",
    base64: HeaderXLS.imglogoName,
  });

  ws.addImage(imageId1, "A1:B2");
  ws.addImage(imageId2, "E1:F2");

  let rowNumber = 4;
  ws.getRow(rowNumber).getCell("A").value = "Attendance Report for Section " + section.section_id;
  ws.getRow(rowNumber).getCell("A").font = {
    bold: true,
    size: 16,
  };
  ws.getRow(rowNumber).getCell("A").border = {
    top: { style: "thin" },
    bottom: { style: "thin" },
  };
  ws.getRow(rowNumber).getCell("A").alignment = { horizontal: "center" };
  ws.mergeCells("A" + rowNumber + ":F" + rowNumber);
  rowNumber = rowNumber + 1;

  ws.getRow(rowNumber).getCell("A").value = "Academic Term: " + (section.atp_term && (section.atp_term.name || "-"));
  ws.mergeCells("A" + rowNumber + ":D" + rowNumber);
  rowNumber = rowNumber + 1;

  ws.getRow(rowNumber).getCell("A").value = "Course Title: " + course_title;
  ws.mergeCells("A" + rowNumber + ":C" + rowNumber);
  rowNumber = rowNumber + 1;

  ws.getRow(rowNumber).getCell("A").value = "Course Code: " + course.code;
  ws.mergeCells("A" + rowNumber + ":C" + rowNumber);
  rowNumber = rowNumber + 1;

  ws.getRow(rowNumber).getCell("A").value = "Credit: " + course.credit + " SKS";
  ws.mergeCells("A" + rowNumber + ":C" + rowNumber);
  rowNumber = rowNumber + 1;

  const totalLecturer = Object.values(section.lecturer).length;
  let lecturerName = "";
  if (totalLecturer > 0) {
    section.lecturer.map(item => {
      lecturerName += (item.emp && item.emp.fullname || "-") + "; ";
    })
  }

  ws.getRow(rowNumber).getCell("A").value = "Lecturer: " + lecturerName;
  ws.mergeCells("A" + rowNumber + ":C" + rowNumber);
  rowNumber = rowNumber + 2;

  const rowHeader = ws.getRow(rowNumber);
  tableHeader.map((v, index) => {
    var i = index + 1;
    RowHeader(rowHeader, i, v.title)
  });
  rowNumber = rowNumber + 1;

  if (Object.values(schedules).length > 0) {
    schedules.sort((a, b) => a.start_dt > b.end_dt ? 1 : -1);
    let myRow = rowNumber;
    schedules.map((v, index) => {
      const rowTBody = ws.getRow(myRow);
      rowTBody.getCell(1).value = index + 1;
      rowTBody.getCell(2).value = v.start_dt ? moment(v.start_dt).format("DD MMM YYYY hh:mm A") : "";
      rowTBody.getCell(3).value = v.end_dt ? moment(v.end_dt).format("DD MMM YYYY hh:mm A") : "";
      rowTBody.getCell(4).value = v.emp ? v.emp.fullname && v.emp.fullname : "";
      rowTBody.getCell(5).value = v.teaching_topic ? v.teaching_topic : "";
      rowTBody.getCell(6).value = v.weight ? v.weight : "";
      rowTBody.getCell(7).value = v.submit_formatted ? v.submit_formatted : "";
      rowTBody.getCell(8).value = v.approved_time ? moment(v.approved_time).format("DD MMM YYYY hh:mm A") : "";
      rowTBody.getCell(9).value = v.status ? v.status : "";

      myRow++;
    })
  }

  ws.columns.forEach((column, index) => {
    if (index === 0) {
      column.width = 5;
    } else if(index === 1 || index === 2) {
      column.width = 20;
    }  else {
      let maxColumnLength = 0;
      column.eachCell({ includeEmpty: true }, cell => {
        let cellLength = cell.value ? cell.value.toString().length : 0;
        if (cellLength > maxColumnLength) {
          maxColumnLength = cellLength;
        }
      });
      column.width = maxColumnLength < 10 ? 10 : maxColumnLength;
    }
  });
};

export { DownloadAtdSummary };
