import React, { useMemo } from "react";
import { SGUCard } from "../../../../../../_layouts/components/cards/CardUI";
import { NoRecordFound } from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { handleKeyDown } from "../../../../components/forms/InputText";
import { ButtonIcon, ButtonPrimary, ButtonTersier } from "../../../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { InfoCourse } from "./MatriculationUI";

export default function MYPCF({ mycourses, setMyCourses, RemoveCourse }) {
  const handleInputChange = (index, field, value) => {
    const updatedCourses = [...mycourses];
    updatedCourses[index][field] = value;
    setMyCourses(updatedCourses);
  };

  const totalCredit = useMemo(() => {
    //return mycourses.reduce((sum, course) => sum + (Number(course.credit) || 0), 0);
    return mycourses.reduce((sum, course) => {
      if (course.course_type !== 'asc.course.type.optional') {
        return sum + (Number(course.credit) || 0);
      }
      return sum;
    }, 0);
  }, [mycourses]);

  return (
    <div className="table-responsive">
      <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
        <thead>
          <tr className="fs-7 fw-bold text-gray-500 border-bottom-0 text-uppercase">
            <th>Course Name</th>
            <th className="d-none">Section ID</th>
            <th width="10%">Score</th>
            <th>Ext Course</th>
            <th width="10%">Ext Credit</th>
            <th width="10%"></th>
          </tr>
        </thead>
        <tbody>
          {Object.values(mycourses).length > 0 ? (
            mycourses.map((v, index) => (
              <tr key={index}>
                <td>
                  <InfoCourse data={v} />
                </td>
                <td className="d-none">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    placeholder="format: 2024-1-0001"
                    name="section_id"
                    
                    disabled={v.score ? true : false}
                    value={v.section_id}
                    onChange={(e) => handleInputChange(index, 'section_id', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    onKeyDown={handleKeyDown}
                    className="form-control form-control-sm"
                    name="score"
                    tabIndex={1}
                    disabled={v.section_id ? true : false}
                    value={v.score}
                    onChange={(e) => handleInputChange(index, 'score', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    name="course_ori"
                    tabIndex={2}
                    value={v.course_ext}
                    onChange={(e) => handleInputChange(index, 'course_ext', e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    onKeyDown={handleKeyDown}
                    className="form-control form-control-sm"
                    name="score"
                    tabIndex={3}
                    disabled={v.section_id ? true : false}
                    value={v.credit_ext}
                    onChange={(e) => handleInputChange(index, 'credit_ext', e.target.value)}
                  />
                </td>
                <td>
                  <ButtonIcon
                    type="button"
                    className="btn-sm ms-2"
                    title="Remove"
                    onClick={() => RemoveCourse(v.id)}
                  >
                    <KTIcon iconName="trash" className="fs-2 text-danger" />
                  </ButtonIcon>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4}>
                <NoRecordFound />
              </td>
            </tr>
          )}
        </tbody>
        {Object.values(mycourses).length > 0 && (
          <tfoot>
            <tr>
              <td colSpan={4}>
                <span className="fw-bold">Total Credit {totalCredit} SKS</span>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
}
