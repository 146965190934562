import ExcelJs from "exceljs";

const SheetActivityDetail = (wb, param, myfilename) => {
    const tableHeader = [
    { key: "A", title: "No", width: 10 },
    { key: "B", title: "Student ID", width: 15 },
    { key: "C", title: "Student Fullname", width: 35 },
    { key: "D", title: "Department", width: 35 },
    { key: "E", title: "Specialization", width: 35 },
    { key: "F", title: "Counselor", width: 35 },
    { key: "G", title: "Activity", width: 20 },
    { key: "H", title: "Semester", width: 10 },
    { key: "I", title: "Status", width: 20 },
    { key: "J", title: "Submited Date", width: 20 },
    { key: "K", title: "Approved Date", width: 20 }];

    const headers = tableHeader;
    const ws = wb.addWorksheet("Teaching Load");
    const cloneHeader = [...headers];
    const newHeader = cloneHeader.map((obj) => {
        //delete obj.title;
        var x = {};
        x.key = obj.key;
        x.width = obj.width;
        return x;
    });
    ws.columns = newHeader;

    ws.getRow(1).getCell("A").value = "Academic Student Activity "+myfilename;
    ws.getRow(1).getCell("A").font = {
        bold: true,
        size: 16,
    };

    let rowNumber = 3;
    const rowHeader = ws.getRow(rowNumber);
    tableHeader.map((v, index) => {
        var i = index + 1;
        rowHeader.getCell(i).value = v.title;
        rowHeader.getCell(i).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
        }
        rowHeader.getCell(i).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "191970" },
        };
        rowHeader.getCell(i).alignment = {
            vertical: "middle",
            horizontal: "center",
        };
        rowHeader.getCell(i).font = {
            bold: true,
            size: 11,
            color: { argb: "FFFFFF" },
        };
    })

    //BODY - PARAMETER
    rowNumber = rowNumber + 1;
    var num = 1;
    param.map((v) => {
        let counselors = "";
        if(v.counselor){
            v.counselor.map((v)=>{
                counselors += (v.emp) ? v.emp.fullname+"\n" : "";
            });
        }
        const row = ws.getRow(rowNumber);
        row.getCell(1).value = (num++);
        row.getCell(2).value = (v.studentid) ? v.studentid : '';
        row.getCell(3).value = (v.studentdata) ? v.studentdata.fullname : '';
        row.getCell(4).value = (v.clu) ? v.clu.name : '';
        row.getCell(5).value = (v.clu_spec) ? v.clu_spec.name : '';
        row.getCell(6).value = counselors;
        row.getCell(7).value = (v.student_activity) ? v.student_activity.name : '';
        row.getCell(8).value = v.semester;
        row.getCell(9).value = v.status_activity;
        row.getCell(10).value = (v.submit_date_formatted) ? v.submit_date_formatted : '';
        row.getCell(11).value = (v.approve_date_formatted) ? v.approve_date_formatted : '';
        rowNumber++;
    });
    //END BODY - PARAMETER
}

const DownloadActivity = (obj, myfilename) => {
    const dataActivity = obj;

    const wb = new ExcelJs.Workbook();
    wb.creator = "UMS-SGU";
    wb.lastModifiedBy = "UMS-SGU-System";
    wb.created = new Date();
    wb.modified = new Date();
    wb.lastPrinted = new Date();

    SheetActivityDetail(wb, dataActivity,myfilename);

    const writeFile = (fileName, content) => {
        const link = document.createElement("a");
        const blob = new Blob([content], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8'
        });
        link.download = fileName;
        link.href = URL.createObjectURL(blob);
        link.click();
    };

    wb.xlsx.writeBuffer().then((buffer) => {
        writeFile("Report Academic Student Activity "+myfilename, buffer);
    });
}

export default DownloadActivity;