import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { SpinnerLoad } from "../../../../../_layouts/components/loadbars/LoadingBars";
import {
  ButtonPrimary,
  ButtonTersier,
} from "../../../../../_layouts/components/buttons/ButtonUI";
import { openModal } from "../../../../../_layouts/components/modals/Modals";
import useToaster from "../../components/use-toaster";
import { CREATE_MKT_COUPON } from "../../../apis/node-target-data/MarketingAPI";
import { filterOnlyNumbers } from "../../components/Helper";
import moment from "moment";

const ModalNewCoupon = ({ handleReload }) => {
  const { ToasterComponent, triggerNotification } = useToaster();
  const [couponQty, setCouponQty] = useState(1);
  const [expiredDate, setExpiredDate] = useState(
    new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
  );
  const [type, setType] = useState("nominal");
  const [couponType, setCouponType] = useState("regfee");
  const [amount, setAmount] = useState(0);

  const [generateCoupon, setGenerateCoupon] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const handleCouponNumber = (evt, incoming_num) => {
    const limit = 100;
    const num = filterOnlyNumbers(incoming_num)
    if (num || evt !== "var") {
      if (evt === "minus" && couponQty > 1) {
        setCouponQty(couponQty - 1);
      } else if (evt === "plus" && couponQty < limit) {
        setCouponQty(couponQty + 1);
      } else if (evt === "var") {
        if (num * 1 > limit) {
          setCouponQty(limit);
        } else if (num * 1 < 1) {
          setCouponQty(1);
        } else {
          setCouponQty(num * 1);
        }
      }
    }
  };

  const setLimitationAmount = (value) => {
    const trueInt = filterOnlyNumbers(value) * 1;
    if (type === "nominal") {
      if (trueInt > 500000) {
        setAmount(500000);
      } else {
        setAmount(trueInt);
      }
    } else {
      if (trueInt > 100) {
        setAmount(100);
      } else {
        setAmount(trueInt);
      }
    }
  };

  useEffect(() => {
    setLimitationAmount(amount);
  }, [type]);

  const CreateCoupon = async () => {
    setGenerateCoupon({ loading: true, message: "", data: [] });
    const param = {
      qty: couponQty,
      expiration_date: moment(expiredDate).format("YYYY-MM-DD"),
      discount_type:
        type === "nominal"
        ? "mkt.coupon.amount.type.nominal"
        : "mkt.coupon.amount.type.percentage",
      amount: amount,
      coupon_type:
      couponType === "regfee"
      ? "mkt.coupon.type.regfee"
      : "mkt.coupon.type.tuitionfee",
    };
    console.log("param ", param)
    const res = await CREATE_MKT_COUPON(param);
    console.log("res coupon ", res)
    if (res.message === "") {
      triggerNotification({
        type: "success",
        message: "Success create coupon!",
        duration: 3000,
      });
      setGenerateCoupon(res);
      setTimeout(() => {
        handleReload();
        setGenerateCoupon(res);
      }, 2000);
    } else {
      triggerNotification({
        type: "danger",
        message: res.message,
        duration: 3000,
      });
      setGenerateCoupon(res);
      setTimeout(() => {
        setGenerateCoupon({ loading: false, message: "", data: [] });
      }, 2000);
    }
  };

  return (
    <>
      {ToasterComponent}
      <div className="mb-5">
        <div className="row">
          <div className="col-12 mb-2">
            <div
              className={`btn btn-outline btn-outline-dashed btn-active-light-primary p-3 d-flex align-items-center mb-5 ${couponType ===
                "regfee" && "active"}`}
              onClick={() => setCouponType("regfee")}
            >
              <i class="ki-outline ki-tag fs-2x"></i>

              <span className="d-block fw-semibold text-start">
                <span className="text-gray-900 fw-bold d-block fs-5">
                  Registration Fee
                </span>
                <span className="text-muted fw-semibold fs-6">
                  Create coupon for registration fee
                </span>
              </span>
            </div>
          </div>
          {/* <div className="col-6">
            <div
              className={`btn btn-outline btn-outline-dashed btn-active-light-primary p-3 d-flex align-items-center mb-5 ${couponType ===
                "tuitionfee" && "active"}`}
              // onClick={() => setCouponType("tuitionfee")}
            >
              <i class="ki-outline ki-teacher fs-2x"></i>

              <span className="d-block fw-semibold text-start">
                <span className="text-gray-900 fw-bold d-block fs-5">
                  Tuition Fee
                </span>
                <span className="text-muted fw-semibold fs-6">
                  Create coupon for tuition fee
                </span>
              </span>
            </div>
          </div> */}
        </div>

        <div className="d-flex justify-content-between">
          <div>
            <div className="mb-5">
              <label>Quantity</label>
              <div
                className="d-flex align-items-center z-3"
                style={{
                  width: 100,
                }}
              >
                <i
                  className="ki-solid ki-minus-square fs-1 cursor-pointer text-hover-primary"
                  onClick={() => handleCouponNumber("minus")}
                ></i>
                <input
                  value={couponQty}
                  onChange={(e) => handleCouponNumber("var", e.target.value)}
                  className="form-control form-control-sm text-center fw-bold"
                />
                <i
                  className="ki-solid ki-plus-square fs-1 cursor-pointer text-hover-primary"
                  onClick={() => handleCouponNumber("plus")}
                ></i>
              </div>
            </div>
            <div>
              <label>Type</label>
              <div>
                <div
                  className="form-check form-check-custom form-check-solid form-check-sm mb-2 mt-1"
                  onClick={() => setType("nominal")}
                >
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    checked={type === "nominal"}
                    name="box1"
                    readOnly
                  />
                  <label className="form-check-label cursor-pointer" for="box1">
                    Nominal
                  </label>
                </div>
                <div
                  className="form-check form-check-custom form-check-solid form-check-sm"
                  onClick={() => setType("percentage")}
                >
                  <input
                    className="form-check-input cursor-pointer"
                    type="checkbox"
                    checked={type === "percentage"}
                    name="box2"
                    readOnly
                  />
                  <label className="form-check-label cursor-pointer" for="box2">
                    Percentage
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="mb-5">
              <label>Expired</label>
              <div>
                <DatePicker
                  className="form-control form-control-sm w-100 me-5"
                  selected={expiredDate}
                  minDate={new Date()}
                  onChange={(dt) => setExpiredDate(dt)}
                  dateFormat={"dd MMM yyyy"}
                />
              </div>
            </div>
            <div>
              <label>Amount</label>
              <div className="input-group input-group-sm mb-5 d-flex">
                {/* <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement="bottom"
              overlay={renderTooltip({
                title:
                  type === "nominal"
                    ? "Change to percentage"
                    : "Change to nominal",
              })}
            > */}
                <span
                  className="input-group-text cursor-pointer"
                  // onClick={() =>
                  //   type === "nominal"
                  //     ? setType("percentage")
                  //     : setType("nominal")
                  // }
                >
                  {type === "nominal" ? "Rp" : "%"}
                </span>
                {/* </OverlayTrigger> */}
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Amount"
                  value={amount}
                  onChange={(e) => setLimitationAmount(e.target.value)}
                />
              </div>
            </div>
            {/* btn generate */}
            <div className="d-flex justify-content-end">
              <ButtonPrimary
                title="Create Coupon"
                className="d-flex align-items-center"
                onClick={() => CreateCoupon()}
              >
                {generateCoupon.loading ? (
                  <SpinnerLoad />
                ) : (
                  <>
                    <i class="ki-outline ki-glass fs-3"></i>
                    <span>Generate</span>
                  </>
                )}
              </ButtonPrimary>
            </div>
          </div>
        </div>
      </div>
      <div class="separator my-10"></div>
      <div className="d-flex justify-content-end">
        <ButtonTersier onClick={() => openModal({ open: false })}>
          Close
        </ButtonTersier>
      </div>
    </>
  );
};

export { ModalNewCoupon };
