import React, { useState } from 'react'
import { SGUCardFlush } from '../../../_layouts/components/cards/CardUI'
import { AvaBlank, AvaBoy, AvaGirl } from '../../../_layouts/components/images/Avatars'
import { CalculateDate } from '../../../_layouts/_helpers/Global'
import moment from 'moment'
import { KTIcon } from '../../../_metronic/helpers'

export default function CardProfile({ profile }) {
    const [expand, setExpand] = useState(false);
    return (
        <SGUCardFlush>
            <SGUCardFlush.Body className={"pt-8"}>
                <div className="d-flex flex-column text-center mb-9 px-9">
                    <div className="symbol symbol-80px symbol-lg-150px mb-4">
                        {profile.gender === "M" ? <AvaBoy className="bg-light-primary rounded" /> : <AvaGirl className="bg-light-primary rounded" />}
                    </div>
                    <div className="text-center">
                        <span className="text-gray-800 fw-bold fs-4">{profile.fullname}</span>
                        <span className="text-muted d-block fw-semibold">{profile.employee_id}</span>
                    </div>
                </div>

                <div className="row mb-4">
                    <CardJJA name={"Homebase"} value={profile.org && (profile.org.code || "-")} />
                    <CardJJA name={"Education"} value={profile.rate || "-"} />
                    <CardJJA name={"Worked"} value={profile.join_date && (CalculateDate(profile.join_date) + " Yrs" || "-")} />
                </div>

                <div className="separator separator-dashed my-4"></div>

                <div className='biodata'>
                    <LabelItem name={"Position"} value={profile.group_name || "-"} />
                    {profile.emp_type && <LabelItem name={"Employee Type"} value={profile.emp_type.name || "-"} /> }
                    {profile.nidn && (
                        <LabelItem name={"NIDN"} value={profile.nidn} />
                    )}
                    {profile.org && <LabelItem name={"Homebase"} value={profile.org.name || "-"} />}
                    <LabelItem name={"SGU Mail"} value={profile.sgumail || "-"} />
                    <p className="text-end mb-0 fs-7 text-primary cursor-pointer" onClick={() => setExpand(!expand)}>
                        <span className="me-1">Show {expand ? "less" : "more"}</span>
                        <KTIcon iconName={'double-' + (expand ? "down" : "up")} className='text-primary' />
                    </p>
                    {expand && (
                        <div>
                            <LabelItem name={"Phone Ext"} value={profile.ext || "-"} />
                            <LabelItem name={"Mobile Phone"} value={profile.phone1 || "-"} />
                            <LabelItem name={"Birthdate"} value={profile.birthdate && moment(profile.birthdate).format("DD MMM YYYY")} />
                        </div>
                    )}
                </div>
            </SGUCardFlush.Body>
        </SGUCardFlush>
    )
}

const LabelItem = ({ name, value }) => {
    return (
        <div className="mb-7">
            <label className="fw-bold text-muted">{name}</label>
            <div className="">
                <span className="fw-bolder fs-6 text-gray-800">
                    {value}
                </span>
            </div>
        </div>
    );
};

const CardJJA = ({ name, value }) => {
    return (
        <div className="col-md-4 text-center">
            <div className="text-gray-800 fw-bold fs-3">
                <span className="m-0 counted">{value}</span>
            </div>
            <span className="text-gray-500 fs-8 d-block fw-bold">{name}</span>
        </div>
    )
}