const doc_type = [
    {id:"strg.doc.type.ktp", name:"KTP"}, 
    {id:"strg.doc.type.pasfoto", name:"Pass Photo"}, 
    {id:"strg.doc.type.passport", name:"Passport"},
    {id:"strg.doc.type.reportcards'", name:"Report Cards"},
    {id:"strg.doc.type.profilepic'", name:"Profile Picture"},
    {id:"strg.doc.type.ijazah", name:"Ijazah"},
    {id:"strg.doc.type.familycertificate", name:"Family Certificate"},
    {id:"strg.doc.type.birthcertificate", name:"Birth Certificate"},
    {id:"strg.doc.type.recommendationletter", name:"Recommendation Letter"},
    {id:"strg.doc.type.certificate", name:"Certification"},
    {id:"strg.doc.type.decreetransfercredit", name:"Decree Transfer Credit"},
];

export {doc_type}