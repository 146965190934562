import React, { useEffect, useMemo, useRef, useState } from 'react'
import "./TableTeachingLoads.css";
import { ButtonItemRefresh, HeaderDatatables, SearchBarTable } from '../../../../_layouts/components/datatables/MYDatatables';
import { LoadingSkeleton, NoRecordFound } from '../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';
import { LecturerTypeName } from './components/Helper';
import { FormatRupiah } from '../../../../_layouts/_helpers/Global';
import { ButtonDownload } from '../../../../_layouts/components/buttons/ButtonUI';
import { DownloadTLRate } from './components/DownloadTLRate';
import { MessageError, openModal } from '../../../../_layouts/components/modals/Modals';

export default function TLRateTableData({ results, ReloadData }) {
    const scrollbarHolderRef = useRef(null);
    const tableWrapperRef = useRef(null);
    const tableRef = useRef(null);

    useEffect(() => {
        const scrollbarHolder = scrollbarHolderRef.current;
        const tableWrapper = tableWrapperRef.current;
        const table = tableRef.current;

        if (table) {
            const handleScroll = () => {
                if (tableWrapper) {
                    tableWrapper.scrollLeft = scrollbarHolder.scrollLeft;
                }
            };

            const updateDummyWidth = () => {
                if (scrollbarHolder.firstChild) {
                    scrollbarHolder.firstChild.style.width = `${table.offsetWidth}px`;
                }
            };

            if (!scrollbarHolder.firstChild) {
                const dummyDiv = document.createElement("div");
                scrollbarHolder.appendChild(dummyDiv);
            }

            updateDummyWidth();

            scrollbarHolder.addEventListener("scroll", handleScroll);
            window.addEventListener("resize", updateDummyWidth);

            return () => {
                scrollbarHolder.removeEventListener("scroll", handleScroll);
                window.removeEventListener("resize", updateDummyWidth);
            };
        }
    }, []);

    const [totalItems, setTotalItems] = useState(0);
    const [totalBudget, setTotalBudget] = useState(0);
    const [search, setSearch] = useState("");
    const [sorting, setSorting] = useState({ field: "", order: "" });

    const headers = [
        { name: "Name", field: "lecturername", sortable: false },
        { name: "Course", field: "course", sortable: false },
        { name: "Credit", field: "course_credit", sortable: false },
        { name: "Section ID", field: "section_id", sortable: false },
        { name: "Lecturer's Session", field: "section_session", sortable: false },
        { name: "Course Session", field: "emp_session", sortable: false },
        { name: "Lecturer's Status", field: "lecturername", sortable: false },
        { name: "Lecturer's Credit", field: "lec_session", sortable: false },
        { name: "Rate", field: "rate_lecture_amount", sortable: false },
        { name: "Budget", field: "budget_lecture", sortable: false },
    ];

    const ResultData = useMemo(() => {
        let computedData = []; let totalBudget = 0;
        if (Object.values(results.data).length > 0) {
            computedData = results.data.map((c) => {
                var obj = {}; var lec_session = 0;
                let lectType = "-";
                if (c.emp) {
                    if (c.emp.emp_type_id) {
                        let lectTypeArr = c.emp.emp_type_id.split(".");
                        lectType = lectTypeArr[lectTypeArr.length - 1];
                    }
                }
                lec_session = c.emp_session / 14;
                obj.cost_center = (c.cost_center) ? c.cost_center.name : "-";
                obj.emp_session = (c.emp_session) ? c.emp_session : 0;
                obj.section_id = c.section_id;
                obj.section_session = c.section_session;
                obj.lecturername = (c.emp) ? c.emp.fullname : '';
                obj.budget_lecture = (lectType !== "parttime") ? 0 : ((c.emp) ? c.emp.budget_lecture : 0);
                obj.lecture_type_id = LecturerTypeName(lectType);
                obj.rate_lecture_amount = (c.emp) ? c.emp.rate_lecture_amount : '';
                obj.course = (c.course) ? c.course.coursetitle : '';
                obj.course_code = (c.course) ? c.course.code : '';
                obj.course_credit = (c.course) ? c.course.credit : '';
                obj.atp_term = (c.atp_term) ? c.atp_term.name : "";
                obj.lec_session = (lec_session % 1 != 0) ? lec_session.toFixed(2) : lec_session;
                return obj;
            })

            computedData = computedData.filter((prev) => {
                if (prev.section_id) {
                    return prev;
                }
            });

            computedData.sort((a, b) => (a.section_id > b.section_id) ? 1 : -1);
            totalBudget = computedData.reduce((a, v) => a = a + v.budget_lecture, 0);
            setTotalBudget(totalBudget);
        }
        if (search) {
            computedData = computedData.filter((listData) => {
                return Object.keys(listData).some((key) =>
                    listData[key]
                        .toString()
                        .toLowerCase()
                        .includes(search)
                );
            });
        }
        setTotalItems(computedData.length);

        //Sorting listDatas
        if (sorting.field) {
            const reversed = sorting.order === "asc" ? 1 : -1;
            computedData = computedData.sort(
                (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
            );
        }
        return computedData
    }, [results.data, search, sorting]);

    const HandlerDownload = () =>{
        if(totalItems > 0){
            DownloadTLRate(results.data);
        }else{
            openModal({message:<MessageError message={"No data available for download."} />})
        }        
    }
    return (
        <div>
            <div className="mb-3 d-flex justify-content-between align-items-center">
                <SearchBarTable
                    size="w-250px"
                    onChange={(e) => setSearch(e.target.value)}
                />
                <div className="d-flex align-items-center">
                    <ButtonItemRefresh
                        totalItems={totalItems}
                        onClick={(e) => ReloadData(e)}
                    />
                    <ButtonDownload className="btn-sm fw-bold mx-3" type="button" onClick={()=>HandlerDownload()}>
                        Export to excel
                    </ButtonDownload>
                    <div className="">
                        <span className="d-block">Total Budget:</span>
                        <span className="fw-bold text-success">IDR {FormatRupiah(totalBudget)}</span>
                    </div>
                    
                </div>
            </div>
            <div className="table-responsive" style={{ width: "100%", overflow: "hidden" }}>
                <div
                    className="scrollbar-holder"
                    ref={scrollbarHolderRef}
                    style={{ overflowX: "auto", overflowY: "hidden", height: "20px" }}
                ></div>
                <div
                    className="table-wrapper"
                    ref={tableWrapperRef}
                    style={{ overflowX: "auto", overflowY: "hidden" }}
                >
                    <table className="table table-row-dashed align-start fs-7 gy-4 my-0 pb-3 dataTable no-footer align-start"
                        id="table-reporting-tl"
                        style={{ minWidth: "600px", tableLayout: "auto" }}
                        ref={tableRef}>
                        <HeaderDatatables
                            headers={headers}
                            onSorting={(field, order) => setSorting({ field, order })}
                        />
                        <tbody>
                            {results.loading ? (
                                <tr>
                                    <td colSpan={10}>
                                        <LoadingSkeleton />
                                    </td>
                                </tr>
                            ) : results.message ? (
                                <tr>
                                    <td colSpan={10}>
                                        <AlertNotif messages={results.message} />
                                    </td>
                                </tr>
                            ) : ResultData.length > 0 ? (
                                ResultData.map((listData, index) => (
                                    <tr key={index} className={`fs-8`}>
                                        <td style={{ whiteSpace: "nowrap" }}>{listData.lecturername}</td>
                                        <td>
                                            <span className="d-block">{listData.course}</span>
                                            <span className="text-dark-300">{listData.course_code}</span>
                                        </td>
                                        <td style={{ whiteSpace: "nowrap" }}>{listData.course_credit} SKS</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{listData.section_id}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{listData.emp_session}</td>
                                        <td className="text-capitalize" style={{ whiteSpace: "nowrap" }}>{listData.section_session}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{listData.lecture_type_id}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>{listData.lec_session}</td>
                                        <td style={{ whiteSpace: "nowrap" }}>
                                            {(listData.rate_lecture_amount) ? 'IDR ' + FormatRupiah(listData.rate_lecture_amount, '') : 0}
                                        </td>
                                        <td style={{ whiteSpace: "nowrap" }}>
                                            {(listData.budget_lecture) ? 'IDR ' + FormatRupiah(listData.budget_lecture, '') : 0}
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan={10}>
                                        <NoRecordFound />
                                    </td>
                                </tr>
                            )}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colSpan={10} className='fw-bold text-end'>
                                    Total
                                    <span className="text-success ms-1">
                                        IDR {FormatRupiah(totalBudget)}
                                    </span>
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
        </div>
    )
}
