import { useEffect, useRef } from "react";
import ApexCharts from "apexcharts";
import {
  getCSS,
  getCSSVariableValue,
} from "../../../../../_metronic/assets/ts/_utils";

const Bar = ({ className }) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const chart = refreshChart();

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef]);

  const refreshChart = () => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(chartRef.current, getChartOptions(height));
    if (chart) {
      chart.render();
    }

    return chart;
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        {/* begin::Title */}
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">Annual Appliant</span>
          <span className="text-muted fw-semibold fs-7">
            More than 400 new members
          </span>
        </h3>
        {/* end::Title */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body">
        {/* begin::Chart */}
        <div
          ref={chartRef}
          id="kt_charts_widget_1_chart"
          style={{ height: "350px" }}
        />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export { Bar };

function getChartOptions(height) {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");
  const bachelor = getCSSVariableValue("--bs-primary");
  const master = getCSSVariableValue("--bs-info");

  const color1 = "#279FFA",
    color2 = "#26E7A5",
    color3 = "#FEBB3B",
    color4 = "#FE6177",
    color5 = "#8A74D7",
    color6 = "#6D828C",
    color7 = "#47B3A8";

  const generateRandomData = (length, min, max) => {
    return Array.from(
      { length },
      () => Math.floor(Math.random() * (max - min + 1)) + min
    );
  };

  return {
    series: [
      {
        name: "Education Consultan",
        data: generateRandomData(6, 20, 100), // Generate 6 random values between 20 and 100
      },
      {
        name: "School Information",
        data: generateRandomData(6, 20, 100),
      },
      {
        name: "Website",
        data: generateRandomData(6, 20, 100),
      },
      {
        name: "Instagram",
        data: generateRandomData(6, 20, 100),
      },
      {
        name: "LinkedIn",
        data: generateRandomData(6, 20, 100),
      },
      {
        name: "Google",
        data: generateRandomData(6, 20, 100),
      },
      {
        name: "Others",
        data: generateRandomData(6, 20, 100),
      },
    ],

    chart: {
      fontFamily: "inherit",
      type: "bar",
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "80%",
        borderRadius: 5,
      },
    },
    legend: {
      show: false,
    },
    legend: {
      show: true,
      position: "top", // Position the legend at the top
      horizontalAlign: "center", // Align it to the right
      floating: false,
      offsetY: 0, 
      offsetX: 0,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["2020", "2021", "2022", "2023", "2024", "2025"],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function(val) {
          return val;
        },
      },
    },
    colors: [color1, color2, color3, color4, color5, color6, color7],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}
