import { useEffect, useState } from "react";
import { GET_EMP_BIO_BY_IDENID } from "../../../apis/node-target-data/HRAPI";
import {
  LoadingBars,
  NoRecordFound,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif, AlertRetry } from "../../../../../_layouts/components/alerts/AlertsUI";
import { ButtonPrimary, ButtonTersier } from "../../../../../_layouts/components/buttons/ButtonUI";
import { MessageError, MessageSuccess, MessageSuccessConfirmLeave, openModal } from "../../../../../_layouts/components/modals/Modals";
import { UPDATE_SCR_STUDENT_COM } from "../../../apis/node-target-data/AcademicApi";
import { RequiredMsg } from "../../../../../_layouts/_core/ErrorMessage";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import moment from "moment";

const FormScoreCompLetter = ({ data, ReloadData }) => {
  const context = useOutletContext();
  const widget_41 = context.widgets.find(
      (item) => item.widget_id === 41 && item.is_enabled === 1
  );
  const navigate = useNavigate();
  const location = useLocation();
  const state_data = location.state;

  const [stdScoreLetter, setStdScoreLetter] = useState(data.score_letter);
  const [emp, setEmp] = useState({ loading: false, data: [], message: "" });
  const GET_EMP = async () => {
    setEmp({ loading: true, data: [], message: "" })
    const param = { empid: data.submitfinal_by };
    const result = await GET_EMP_BIO_BY_IDENID(param);
    setEmp(result);
  };
  useEffect(() => {
    GET_EMP();
  }, [data]);

  const [submit, setSubmit] = useState({ loading: false, data: [], message: "" });

  const HandlerSubmit = async (e) =>{
    e.preventDefault();
    if(stdScoreLetter){
        setSubmit({ loading: true, data: [], message: "" })
    
    const param = {
        student: [
          {
            score_type: data.course.score_type,
            scr_std_id: data.id,
            studentid: data.studentid,
            section_id: data.section_id,
            score_letter:stdScoreLetter
          },
        ],
    };
    const result = await UPDATE_SCR_STUDENT_COM(param);

      if (result.data && Object.values(result.data).length > 0) {
        openModal({
          message: (
            <MessageSuccess
              message={
                "Successfully updated."
              }
            />
          ),
        });
        ReloadData();
        setSubmit({ loading: false, message: "", data: result.data });
        if(state_data){
          navigate("/score/student", {
            state: {param:state_data.param},
          });
        }else{
          navigate("/score/student");
        }
      } else {
        openModal({ message: <MessageError message={result.message} /> });
        setSubmit(result);
      }
    }else{
        openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  }

  const HandleCancel = () => {
    if (confirm("Are you sure want to leave this page?") === true) {
      if(state_data){
        navigate("/score/student", {
          state: {param:state_data.param},
        });
      }else{
        navigate("/score/student");
      }
    }
  };

  return (
    <form method="post" autoComplete="off" onSubmit={(e)=>HandlerSubmit(e)}>
      <div className="table-responsive">
        <table className="table table-row-bordered table-row-dashed  gy-4 gs-9  align-middle fw-bold dataTable">
          <thead className="fs-7 text-gray-500 text-uppercase bg-light">
            <tr>
              <th>Type</th>
              <th colSpan={2}>Score</th>
              <th>Last Updated</th>
            </tr>
          </thead>
          <tbody>
            {emp.loading ? (
              <tr>
                <td colSpan={4}>
                  <LoadingBars />
                </td>
              </tr>
            ) : emp.message ? (
              <tr>
                <td colSpan={4}>
                  <AlertRetry messages={emp.message} Reload={GET_EMP} />
                </td>
              </tr>
            ) : Object.values(emp.data).length > 0 ? (
              <tr>
                <td>Score Letter</td>
                {widget_41 ? (
                  <>
                    <td>
                      <label>
                        <input
                          type="radio"
                          name={"score_letter"}
                          value={"PASS"}
                          checked={stdScoreLetter === "PASS"}
                          onChange={(e) => setStdScoreLetter(e.target.value)}
                        />{" "}
                        PASS
                      </label>
                    </td>
                    <td>
                      <label>
                        <input
                          type="radio"
                          name={"score_letter"}
                          value={"FAIL"}
                          checked={stdScoreLetter === "FAIL"}
                          onChange={(e) => setStdScoreLetter(e.target.value)}
                        />{" "}
                        FAIL
                      </label>
                    </td>
                  </>
                ) : (
                  <td colSpan={2}>{stdScoreLetter}</td>
                )}
                <td>
                    <span className="d-block fw-bold">{emp.data.fullname || data.submitfinal_by}</span>
                    <span className="text-muted">{data.submitfinal && moment(data.submitfinal).format("DD MMM YYYY, hh:mm A")}</span>
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan={3}>
                  <NoRecordFound />
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            {submit.message && (
                <tr>
                    <td colSpan={4}>
                        <AlertNotif messages={submit.message} />
                    </td>
                </tr>
            )}
            <tr>
                <td colSpan={4}>
                  {widget_41 && (
                    <div className="text-end">
                        <ButtonTersier className="btn-lg fw-bold" type="button" onClick={()=>HandleCancel()}>
                            Cancel
                        </ButtonTersier>
                        <ButtonPrimary type="submit" className="btn-lg fw-bold ms-2" disabled={submit.loading}>
                            {submit.loading ? "Processing...":"Save Changes"}
                        </ButtonPrimary>
                    </div>
                    )}
                </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </form>
  );
};

export { FormScoreCompLetter };
