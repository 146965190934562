import forge from "node-forge";
import { OverlayTrigger } from "react-bootstrap";
import { GET_MKT_COUPON } from "../../apis/node-target-data/MarketingAPI";
import { renderTooltip } from "../../../../_layouts/components/popovers/PopOvers";

export const SetControlStateFeeStatus = (
  pos,
  setControlStateLoading,
  index,
  value
) => {
  console.log("pos ", pos, index);
  if (pos === "regfee") {
    setControlStateLoading((prev) =>
      prev.map((item, o) => {
        if (o === index) {
          return { ...item, isLoadingFee: value };
        }
        return item;
      })
    );
  } else if (pos === "tuitionfee") {
    setControlStateLoading((prev) =>
      prev.map((item, o) => {
        if (o === index) {
          return { ...item, isLoadingTuition: value };
        }
        return item;
      })
    );
  } else if (pos === "remove_coupon") {
    setControlStateLoading((prev) =>
      prev.map((item, o) => {
        if (o === index) {
          return { ...item, isLoadingCoupon: value };
        }
        return item;
      })
    );
  } else if (pos === "change_coupon") {
    setControlStateLoading((prev) =>
      prev.map((item, o) => {
        if (o === index) {
          return { ...item, isLoadingCoupon: value };
        }
        return item;
      })
    );
  }
};

export const RegisterStatus = ({ data }) => {
  return (
    <>
      {data === "DRAFT" && (
        <span className="badge badge-light-secondary">Draft</span>
      )}
      {data === "ON PROCESS" && (
        <span className="badge badge-light-primary">On Process</span>
      )}
      {data === "COMPLETE" && (
        <span className="badge badge-light-success">Complete</span>
      )}
      {data === "CANCELED" && (
        <span className="badge badge-light-danger">Canceled</span>
      )}
      {data === "FINISHED" && (
        <span className="badge badge-light-success">Complete</span>
      )}
    </>
  );
};

export const FeeStatus = ({ data }) => {
  return (
    <>
      {(data === "UNPAID" || data === "DRAFT") && (
        <>
          <span className="cursor-pointer badge badge-light-warning">
            Unpaid
          </span>
          {data === "DRAFT" && (
            <OverlayTrigger
              delay={{ show: 250, hide: 400 }}
              placement="top"
              overlay={renderTooltip({ title: "Invoice not send yet" })}
            >
              <div className="text-hover-danger fs-5 fw-bolder ms-1 cursor-default">
                !
              </div>
            </OverlayTrigger>
          )}
        </>
      )}
      {data === "PAID" && (
        <span className="cursor-pointer badge badge-light-success">Paid</span>
      )}
      {data === "CANCELED" && (
        <span className="cursor-pointer badge badge-light-danger">
          Canceled
        </span>
      )}
      {data === "EMPTY" && (
        <span className="cursor-pointer badge badge-dark">No Record</span>
      )}
    </>
  );
};

export const StatusColor = ({ data }) => {
  return (
    <>
      {data === "DRAFT" && <span className="ms-2 text-gray-500">DRAFT</span>}
      {data === "UNPAID" && <span className="ms-2 text-warning">UNPAID</span>}
      {data === "PAID" && <span className="ms-2 text-success">PAID</span>}
      {data === "CANCELED" && (
        <span className="ms-2 text-danger">CANCELED</span>
      )}
      {data === "ON PROCESS" && (
        <span className="ms-2 text-primary">ON PROCESS</span>
      )}
      {data === "EMPTY" && <span className="ms-2 text-dark">NO RECORD</span>}
      {data === "FINISHED" && (
        <span className="ms-2 text-success">COMPLETE</span>
      )}
    </>
  );
};

export const FormateDate = (dateString) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const date = new Date(dateString);

  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  return `${day} ${month} ${year}`;
};

export const ValidatePhoneNumber = (value) => {
  const phoneRegex = /^[0-9+]*$/;
  return phoneRegex.test(value);
};

export const formatRibuan = (number) => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  } else {
    return number;
  }
};

export const filterOnlyNumbers = (str) => {
  return String(str).replace(/\D/g, "");
};

export const ExpiredDate = (expirationDate) => {
  const today = new Date(); // Tanggal hari ini
  const expiration = new Date(expirationDate); // Tanggal expiration

  if (expiration >= today) {
    return false;
  } else {
    return true;
  }
};

export const SeeCoupon = async () => {
  const res = await GET_MKT_COUPON({ coupon_no: "" });
  if (res.message === "" && res.data.length > 0) {
    const active = res.data.filter(
      (x) =>
        (x.trans_id === null || x.trans_id === "DRAFT") &&
        ExpiredDate(x.expiration_date) === false
    );
    return { ...res, data: active };
  } else {
    return res;
  }
};

export const StatusCoupon = (trans_id, expiration_date) => {
  if (trans_id === null || trans_id === "DRAFT") {
    if (ExpiredDate(expiration_date) === false) {
      return "Available";
    } else {
      return "Expired";
    }
  } else {
    return "Used";
  }
};

export const Encryptor = (datadict) => {
  const publicKeyPEM = `
      -----BEGIN PUBLIC KEY-----
${process.env.REACT_APP_RSA_PUBLIC_KEY}
      -----END PUBLIC KEY-----
    `;

  const objData = JSON.stringify(datadict);

  const encryptData = (message) => {
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPEM);

    const encrypted = publicKey.encrypt(
      forge.util.encodeUtf8(message),
      "RSA-OAEP",
      {
        md: forge.md.sha256.create(),
        mgf1: {
          md: forge.md.sha256.create(),
        },
      }
    );
    const encryptedBase64 = forge.util.encode64(encrypted);
    return encryptedBase64;
  };

  const encrypted = encryptData(objData);
  return encrypted;
};
