import React, { useEffect, useState } from "react";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import TableData from "./components/TableData";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { useLocation, useOutletContext } from "react-router-dom";
import { AccessInvalid } from "../../../../_layouts/_core/ErrorMessage";
import { LoadingSkeleton } from "../../../../_layouts/components/loadbars/LoadingBars";
import { GET_ENROLLMENT_PROCESS } from "../../apis/node-target-data/MarketingAPI";
import Filter from "./components/Filter";
import useToaster from "../components/use-toaster";
import { ButtonDownload } from "../../../../_layouts/components/buttons/ButtonUI";
import { DownloadAcceptance } from "./components/DownloadAcceptance";
import { GET_ACAD_LOOKUP } from "../../apis/node-target-data/AcademicApi";
import { ButtonAcceptanceLetter } from "../components/EnrollmentComp";
import { openModal } from "../../../../_layouts/components/modals/Modals";
import { AcceptanceLetterModal } from "./components/AcceptanceLetterModal";

export function AcademicAdministrations() {
  const context = useOutletContext();
  const widget_90 = context.widgets.find(
    (item) => item.widget_id === 90 && item.is_enabled === 1
  );
  const widget_91 = context.widgets.find(
    (item) => item.widget_id === 91 && item.is_enabled === 1
  );

  const { ToasterComponent, triggerNotification } = useToaster();
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);
  const [previousFilter, setPreviousFilter] = useState({});
  const [isDownload, setIsDownload] = useState(false);

  const [results, setResults] = useState({
    loading: true,
    message: "",
    data: [],
  });

  const [acadCal, setAcadCal] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const ReloadData = async (param) => {
    setResults({ loading: true, message: "", data: [] });
    console.log(param);
    const result = await GET_ENROLLMENT_PROCESS(param);
    console.log("--->", result);
    setResults(result);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const SubmitFilter = (filterparam) => {
    setLimit(filterparam.limit);
    const param = RemoveEmptyValue(filterparam);

    param.status = "FINISHED";

    setPreviousFilter(param);
    sessionStorage.setItem("previousFilter", JSON.stringify(param));
    if (param) {
      if (param.offset === undefined) setOffset(1);
      ReloadData(param);
    } else {
      openModal({
        message: <MessageError message={RequiredMsg} />,
      });
    }
  };

  const HandlerPage = (type) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let current_offset = offset;
    if (type == 1) {
      current_offset = offset - 1;
    } else {
      current_offset = offset + 1;
    }
    setOffset(current_offset);
    const param = {
      ...previousFilter,
    };
    param.offset = current_offset;
    param.limit = limit;
    SubmitFilter(RemoveEmptyValue(param));
  };

  const HandlerDownload = () => {
    let param = { ...previousFilter };
    param.limit = 1000;
    DownloadAcceptance(param, setIsDownload);
  };

  const getAcadLookUp = async () => {
    console.log("first");
    setAcadCal({ loading: true, message: "", data: [] });
    const List = await GET_ACAD_LOOKUP({ type: "asc.atp.type.term" });
    setAcadCal(List);
  };

  useEffect(() => {
    // get data 1st open
    getAcadLookUp();
    SubmitFilter({
      yearintake: 27,
      offset: offset,
      limit: limit,
    });
  }, []);

  return widget_90 ? (
    <div className="section-browse row">
      {ToasterComponent}
      <div className="col-xl-3 mb-xl-10">
        <Filter
          SubmitFilter={SubmitFilter}
          results={results}
          setResults={setResults}
          title="Acceptance"
        />
      </div>
      <div className="col-lg-9 mb-5">
        <SGUCard>
          <SGUCard.Header>
            <span className="card-label fw-bolder text-dark">
              Acceptance Data
            </span>
            <span className="text-muted mt-1 fw-bold fs-7">
              List of the registration data by filter
            </span>
          </SGUCard.Header>
          <SGUCard.Toolbar>
            <div className="d-flex">
              <ButtonAcceptanceLetter
                className="btn-sm ms-3"
                onClick={() =>
                  openModal({
                    message: <AcceptanceLetterModal triggerNotification={triggerNotification} />,
                    header: "Acceptance Letter Editor",
                    size: "xl",
                  })
                }
                // disabled={isDownload}
              >
                Acceptance Letter Editor
              </ButtonAcceptanceLetter>
              {Object.values(results.data).length > 0 && (
                <ButtonDownload
                  className="btn-sm ms-3"
                  onClick={() => HandlerDownload()}
                  // disabled={isDownload}
                >
                  {isDownload ? "Processing..." : "Export to Excel"}
                </ButtonDownload>
              )}
            </div>
          </SGUCard.Toolbar>
          <SGUCard.Body>
            {results.loading || acadCal.loading ? (
              <LoadingSkeleton />
            ) : results.message || acadCal.message ? (
              <AlertNotif messages={results.message || acadCal.message} />
            ) : (
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="w-100">
                  <TableData
                    results={results}
                    ReloadData={() => SubmitFilter(previousFilter)}
                    widget={widget_91}
                    triggerNotification={triggerNotification}
                    acadCal={acadCal}
                  />
                </div>
                {/* paginasi */}
                <div className="w-100">
                  <div className="footer">
                    <ul className="pagination">
                      <li
                        className={
                          "page-item " +
                          (offset !== 1 ? "cursor-pointer" : "disabled")
                        }
                      >
                        <span
                          className="page-link"
                          onClick={() => HandlerPage(1)}
                        >
                          <span>‹ Prev</span>
                        </span>
                      </li>
                      <li className="page-item active">
                        <span className="page-link text-white">
                          Page {offset}
                        </span>
                      </li>
                      <li
                        className={
                          "page-item " +
                          (Object.values(results.data)?.length === limit
                            ? "cursor-pointer"
                            : "disabled")
                        }
                      >
                        <span
                          className="page-link"
                          onClick={() => HandlerPage(2)}
                        >
                          <span>Next ›</span>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            )}
          </SGUCard.Body>
        </SGUCard>
      </div>
    </div>
  ) : (
    <AlertNotif messages={AccessInvalid} />
  );
}
