import { StudentIDFormat } from "../../../../../_layouts/_helpers/Global";
import { MessageError, openModal } from "../../../../../_layouts/components/modals/Modals";
import Export2PDF from "../../../components/export-pdf/Export2PDF";
import PreviewProposal from "../proposal-form/componensts/PreviewProposal";

const ExportProposalPDF = (setStatus, data, candidate, univ, univprodi, target_dept, total_credit, doc_date) => {
    return new Promise(async (resolve, reject) => {
        try {
            setStatus(true);
            
            const jsxContent = <PreviewProposal data={data} candidate={candidate} univ={univ} univprodi={univprodi} target_dept={target_dept} total_credit={total_credit} doc_date={doc_date} />;
            await Export2PDF(`Transfer Credit Proposal ${candidate &&
                (candidate.data
                    ? StudentIDFormat(candidate.data.candidate_id)
                    : "")}`, jsxContent, false);

            setStatus(false);
            resolve(); 
        } catch (error) {
            openModal({
                message: (
                    <MessageError message={"Failed to download. Please try again later."} />
                ),
            });
            setStatus(false);
            reject(error);
        }
    });
}

export { ExportProposalPDF }