import React, { useEffect, useState } from "react";
import { RemoveEmptyValue } from "../../../../../../_layouts/_helpers/Global";
import { GET_COURSE } from "../../../../apis/node-target-data/AcademicApi";
import { SGUCardFlush } from "../../../../../../_layouts/components/cards/CardUI";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../../_layouts/components/alerts/AlertsUI";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { ButtonIcon } from "../../../../../../_layouts/components/buttons/ButtonUI";
import { InfoCourse, SearchBarTable } from "./MatriculationUI";
import { MessageError, openModal } from "../../../../../../_layouts/components/modals/Modals";

export default function CourseOffering({ courseData, setCourses }) {
  const [postParam, setPostParam] = useState({offset:1, limit:10});

  const [results, setResults] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const ReloadData = async () => {
    setResults({ loading: true, message: "", data: [] });
    const param = RemoveEmptyValue(postParam);
    const result = await GET_COURSE(param);
    if(result.data && Object.values(result.data).length > 0){
        //const data_active = result.data.filter(item => item.status === "ACTIVE")
        setResults({loading:false, message:"", data:result.data});
    }else{
        setResults(result);
    }
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const HandleSearch = (inputValue) =>{
    const format_course_code = /^[A-Z0-9]{4}-[A-Z0-9]{5,}$/;
    if(inputValue && inputValue.length >= 4){
        let param = {};
        if(format_course_code.test(inputValue)){
            param = {coursecode:inputValue}
        }else{
            param = {coursetitle: encodeURIComponent(inputValue)}
        }
        param.offset = 1;
        param.limit = 100;
        setPostParam(param);
    }
  }

  useEffect(()=>{
    ReloadData();
  },[postParam])

  const HandlerAdd2PCF = (data) =>{
    const is_same = courseData.filter(item => item.id === data.id);
    if(Object.values(is_same).length > 0){
        openModal({message:<MessageError message={"This course is already in the list"} />})
    }else{
        setCourses(prev => [...prev, data]);
    }
  }

  return (
    <SGUCardFlush>
      <SGUCardFlush.Header>
        <span className="card-label fw-bold text-gray-800">Master Course</span>
      </SGUCardFlush.Header>
      <SGUCardFlush.Body>
        <div className="mb-5">
            <div className="d-flex align-items-center">
                <SearchBarTable size="w-100" onChange={(e) => HandleSearch(e.target.value)} />
                <div className="ms-3">
                    <ButtonIcon type="button" className="btn-sm fw-bold btn-light" title="Refresh" onClick={()=>ReloadData()}>
                        <i className="bi bi-arrow-clockwise"></i>
                    </ButtonIcon>
                </div>
            </div>
        </div>

        <div style={{ maxHeight:"300px", overflowX:"hidden", overflowY:"auto" }}>
            <div className="table-responsive">
            <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                <thead>
                <tr className="fs-7 fw-bold text-gray-500 border-bottom-0 text-uppercase">
                    <th>Course Name</th>
                    <th className="text-center">Add</th>
                </tr>
                </thead>
                <tbody>
                {results.loading ? (
                    <tr>
                    <td colSpan={2}>
                        <LoadingSkeleton />
                    </td>
                    </tr>
                ) : results.message ? (
                    <tr>
                    <td colSpan={2}>
                        <AlertNotif
                        messages={results.message}
                        />
                    </td>
                    </tr>
                ) : Object.values(results.data).length > 0 ? (
                    results.data.map((v, index) => (
                    <tr key={index}>
                        <td>
                            <InfoCourse data={v} />
                        </td>
                        <td className="text-center">
                            {v.status === "ACTIVE" ? (
                            <ButtonIcon className="btn-sm btn-light" type="button" title="Add to list" onClick={()=>HandlerAdd2PCF(v)}>
                                <KTIcon iconName="plus" className="fs-2 text-primary" />
                            </ButtonIcon>
                            ):(
                                <span className="badge badge-warning">
                                    {v.status}
                                </span>
                            )}
                        </td>
                    </tr>
                    ))
                ) : (
                    <tr>
                    <td colSpan={2}>
                        <NoRecordFound />
                    </td>
                    </tr>
                )}
                </tbody>
            </table>
            </div>
        </div>
      </SGUCardFlush.Body>
    </SGUCardFlush>
  );
}
