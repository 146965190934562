import React, { useEffect, useMemo, useState } from 'react'
import { SGUCard } from '../../../../../../../_layouts/components/cards/CardUI'
import { ButtonDownload, ButtonIcon, ButtonPrimary } from '../../../../../../../_layouts/components/buttons/ButtonUI'
import { useOutletContext } from 'react-router-dom';
import { ButtonItemRefresh, PaginationComponent, SearchBarTable } from '../../../../../../../_layouts/components/datatables/MYDatatables';
import { GET_SECTION_STUDENT, UPDATE_SECTION_STUDENT } from '../../../../../apis/node-target-data/AcademicApi';
import { LoadingBars, NoRecordFound } from '../../../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../../../../_layouts/components/alerts/AlertsUI';
import DownloadStudentList from './DownloadStudentList';
import { openModal } from '../../../../../../../_layouts/components/modals/Modals';
import InputLookUpStd from '../../../../../components/forms/look-up-students/InputLookUpStd';

export function CardStudentList() {
    const context = useOutletContext();
    const data = context.data;
    const widgets = context.widget;
    const widget_14 = widgets.find(
        (item) => item.widget_id === 14 && item.is_enabled === 1
    );

    const [results, setResults] = useState({ loading: false, message: "", data: [] });
    const [submit, setSubmit] = useState({ loading: false, message: "", data: [] });

    const ReloadData = async () => {
        setResults({ loading: true, message: "", data: [] });
        const param = { section_id: data.section_id };
        const result = await GET_SECTION_STUDENT(param);

        setResults(result);
    }

    useEffect(() => {
        ReloadData();
    }, []);

    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const ITEMS_PER_PAGE = 10;

    const ResultData = useMemo(() => {
        let computedData = [];
        if (Object.values(results.data).length > 0) {
            computedData = results.data.map((v) => {
                var obj = {};
                obj.studentname = v.studentstatusdata ? v.studentstatusdata.fullname : "-";
                obj.studentid = v.studentid;
                obj.cluname = v.clu ? v.clu.name : "";
                obj.clu_spec = v.clu_spec ? v.clu_spec.name : "";
                obj.program = v.clu ? (v.clu.program ? v.clu.program.name : "-") : "-";
                return obj;
            })

            computedData.sort((a, b) => (a.studentname < b.studentname ? -1 : 1));
        }

        if (search) {
            computedData = computedData.filter((listData) => {
                return Object.keys(listData).some((key) =>
                    listData[key].toString().toLowerCase().includes(search)
                );
            });
        }
        setTotalItems(computedData.length);

        //Current Page slice
        if (computedData.length > 0) {
            return computedData.slice(
                (currentPage - 1) * ITEMS_PER_PAGE,
                (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
            );
        } else {
            return [];
        }
    }, [results.data, search, currentPage]);

    const [is_download, setIsDownload] = useState(false);
    const DownloadStdList = async () => {
        setIsDownload(true);
        const param = { section_id: data.section_id, course: data.course }
        const dwl = await DownloadStudentList(results.data, param);

        setIsDownload(false);
    }

    const HandlerAddStudent = async (studentid) => {
        openModal({ open: false });
        setSubmit({ loading: true, message: "", data: [] })
        const param = {
            action_type: "add",
            studentid: studentid,
            section_id: data.section_id,
        };
        const result = await UPDATE_SECTION_STUDENT(param);
        setSubmit(result);
        ReloadData();

        // const msg = result.message.includes("02026");
        // if (msg) {
        //     setSubmit({ loading: false, data: [], message: "Student can't be added because the course is not registered in the student's PCF" });
        // } else {
        //     setSubmit(result);
        //     ReloadData();
        // }

        
    }

    const HandlerRemoveStudent = async (studentid) => {
        if (confirm("Are you sure want to remove this student?") === true) {
            setSubmit({ loading: true, message: "", data: [] })
            const param = {
                action_type: "remove",
                studentid: studentid,
                section_id: data.section_id,
            };
            const result = await UPDATE_SECTION_STUDENT(param);

            setSubmit(result);
            ReloadData();
        }
    }

    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bold text-gray-800">Student List</span>
                <span className="text-muted mt-1 fw-bold fs-7">List of student has been PCF</span>
            </SGUCard.Header>
            {widget_14 && (
                <SGUCard.Toolbar>
                    <div className="btn-group">
                        <ButtonPrimary className="btn-sm" onClick={() => openModal({ message: <InputLookUpStd HandlerAddStudent={HandlerAddStudent} /> })}>
                            <i className={"ki-outline ki-plus-circle fs-3"}></i>
                            Add Student
                        </ButtonPrimary>
                        {totalItems > 0 && (
                            <ButtonDownload className="btn-sm" onClick={() => DownloadStdList()}>
                                {is_download ? "Processing..." : "Export to excel"}
                            </ButtonDownload>
                        )}
                    </div>
                </SGUCard.Toolbar>
            )}
            <SGUCard.Body>
                <div className="mb-3 d-flex justify-content-between align-items-center">
                    <SearchBarTable size="w-250px" onChange={(e) => setSearch(e.target.value)} />
                    <ButtonItemRefresh totalItems={totalItems} onClick={() => ReloadData()} />
                </div>
                {submit.loading ? <LoadingBars /> : 
                submit.message && (
                    <div className="my-8">
                        <AlertNotif messages={submit.message} />
                    </div>
                ) }
                <div className="table-responsive">
                    <table className="table table-row-dashed align-middle gs-0 gy-3 my-0">
                        <thead>
                            <tr className='fs-7 fw-bold text-gray-500 border-bottom-0 text-uppercase'>
                                <th>Student</th>
                                <th>Study Program</th>
                                <th>Remove</th>
                            </tr>
                        </thead>
                        <tbody>
                            {results.loading ? (
                                <tr>
                                    <td colSpan={3}><LoadingBars /></td>
                                </tr>
                            ) : results.message ? (
                                <tr>
                                    <td colSpan={3}><AlertNotif messages={results.message} /></td>
                                </tr>
                            ) : (
                                ResultData.length > 0 ? (
                                    ResultData.map((v, index) => (
                                        <tr key={index}>
                                            <td>
                                                <span className="d-block text-dark fw-bold">
                                                    {v.studentname}
                                                </span>
                                                <span className="text-muted">{v.studentid}</span>
                                            </td>
                                            <td>
                                                <span className="d-block text-dark fw-bold">
                                                    {v.cluname}
                                                </span>
                                                {v.clu_spec && (
                                                    <span className="text-muted">
                                                        {v.clu_spec}
                                                    </span>
                                                )}
                                            </td>
                                            <td>
                                                <ButtonIcon title="Remove" className="btn-sm btn-light btn-active-light-danger" type="button" onClick={() => HandlerRemoveStudent(v.studentid)}>
                                                    <i className="ki-outline ki-trash fs-5 m-0"></i>
                                                </ButtonIcon>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan={4}>
                                            <span className="d-block"><NoRecordFound /></span>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                {totalItems > 0 && (
                    <PaginationComponent
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={(page) => setCurrentPage(page)}
                    />
                )}
            </SGUCard.Body>
        </SGUCard>
    )
}
