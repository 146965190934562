import React, { useEffect, useState } from "react";
import {
  AccessInvalid,
  RequiredMsg,
} from "../../../../../_layouts/_core/ErrorMessage";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import { LinkBtn } from "../../../../../_layouts/components/buttons/LinkUI";
import { KTIcon } from "../../../../../_metronic/helpers";
import CourseOffering from "./componensts/CourseOffering";
import MatriculationCourse from "./componensts/MatriculationCourse";
import CandidateData from "./componensts/CandidateData";
import { ButtonPrimary } from "../../../../../_layouts/components/buttons/ButtonUI";
import {
  MessageError,
  openModal,
} from "../../../../../_layouts/components/modals/Modals";
import { CREATE_TRANSFER_PROPOSAL, UPDATE_TRANSFER_PROPOSAL } from "../../../apis/node-target-data/AcademicApi";
import moment from "moment";
import { UPDATE_CANDIDATE } from "../../../apis/node-target-data/MarketingAPI";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import { StudentIDFormat } from "../../../../../_layouts/_helpers/Global";
import { SyncInstituteData } from "./componensts/PropControllers";
import { CREATE_INSTITUTIONHIST, UPDATE_INSTITUTIONHIST } from "../../../apis/node-target-data/IdentityApi";

export function FormProposalCredit() {
  const navigate = useNavigate();
  const location = useLocation();

  const context = useOutletContext();
  const widget_108 = context.widgets.find(
    (item) => item.widget_id === 108 && item.is_enabled === 1
  );
  const isUpdate = location.state ? true : false;

  const [courseData, setCourseData] = useState(
    location.state ? location.state.courseData : []
  );
  const [mycourses, setMyCourses] = useState(
    location.state ? location.state.mycourses : []
  );
  const [dcandidate, setDcandidate] = useState("");
  const [candidates, setCandidates] = useState(
    location.state ? location.state.data.candidates : ""
  );
  const [doc_date, setDoc_date] = useState(
    moment(location.state?.data?.doc_date || undefined).toDate()
  );
  const [target_dept, setTargetDept] = useState(location.state ? location.state.data.target_dept : "");
  const [univ, setUniv] = useState(() => {
    const initialValue = location.state?.data?.university_id;
    return initialValue ?? 0;
  });
  const [univProdi, setUnivProdi] = useState(() => {
    const initialValue = location.state?.data?.university_prodi;
    return initialValue ?? 0;
  });
  const [univLastSemes, setUnivLastSemes] = useState("");
  const [objIns, setObjIns] = useState({ univ_name: "", univ_prodi: "", univ_last_semes: univLastSemes });

  const [isExist, setIsExist] = useState(false);
  const [isExistStd, setIsExistStd] = useState(false);

  useEffect(() => {
    if (courseData.length > 0) {
      const datapcf = courseData.map((v) => {
        const existingCourse = mycourses.find((course) => course.id === v.id);
        return {
          ...v,
          action_type: existingCourse?.action_type || "add",
          is_transfer: existingCourse?.is_transfer || 1,
          score: existingCourse?.score || "",
          section_id: existingCourse?.section_id || "",
          course_ext: existingCourse?.course_ext || "",
          credit_ext: existingCourse?.credit_ext || "",
          score_ext: existingCourse?.score_ext || "",
        };
      });

      const updatedMyCourses = mycourses.filter(course =>
        courseData.some(v => v.id === course.id)
      );

      const mergedCourses = [...updatedMyCourses, ...datapcf.filter(course =>
        !updatedMyCourses.some(existingCourse => existingCourse.id === course.id)
      )];

      if (JSON.stringify(mergedCourses) !== JSON.stringify(mycourses)) {
        setMyCourses(mergedCourses);
      }
    } else {
      setMyCourses([]);
    }
  }, [courseData]);

  const RemoveCourse = (id) => {
    setCourseData((prevData) => prevData.filter((course) => course.id !== id));
    setMyCourses((prevMyCourses) => prevMyCourses.filter((course) => course.id !== id));
  };

  const [submit, setSubmit] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const SubmitHandler = async (e) => {
    e.preventDefault();

    if (candidates && univ && univProdi && univLastSemes && Object.values(mycourses).length > 0 && doc_date) {
      console.log("Submitting...");
      setSubmit({ loading: true, data: [], message: "" });

      const invalidScores = mycourses.reduce((acc, course) => {
        if (
          !course.score ||
          course.score === "" ||
          course.score === null ||
          course.score === undefined ||

          !course.score_ext ||
          course.score_ext === "" ||
          course.score_ext === null ||
          course.score_ext === undefined ||

          !course.course_ext ||
          course.course_ext === "" ||
          course.course_ext === null ||
          course.course_ext === undefined ||

          !course.credit_ext ||
          course.credit_ext === "" ||
          course.credit_ext === null ||
          course.credit_ext === undefined

        ) {
          //console.log(course.coursetitle, course.score_ext);
          acc.push({ id: course.coursetitle, message: "required mandatory" });
        } else {
          const score = parseInt(course.score);
          //const score_ext = course.score_ext;
          if (isNaN(score) || score < 0) {
            acc.push({ id: course.coursetitle, message: "invalid score" });
          } else if (score > 100) {
            acc.push({ id: course.coursetitle, message: "score more than 100" });
          }
        }
        return acc;
      }, []);

      if (invalidScores.length > 0) {
        //console.log(invalidScores);
        const errorMessages = invalidScores
          .map((error) => `Course ${error.id} has ${error.message};`)
          .join("\n");
        openModal({ message: <MessageError message={errorMessages} /> });
        setSubmit({ loading: false, data: [], message: "" });
        return;
      }

      const courses = mycourses.map((course) => ({
        ...course,
        credit_ext: (course.credit_ext && course.credit_ext !== null ? parseInt(course.credit_ext) : 0),
        score_ext: course.score_ext && course.score_ext !== null ? course.score_ext : "",
        course_id: parseInt(course.id)
      }));

      const totalCredit = courses.reduce(
        (sum, course) => sum + (parseInt(course.credit) || 0),
        0
      );
      const totalCreditExt = courses.reduce(
        (sum, course) => sum + (parseInt(course.credit_ext) || 0),
        0
      );

      // mapping univ and prodi
      let syc_ins = { univid: univ, univprodiid: univProdi };
      if (univ === 9999 || univProdi === 9999) {
        syc_ins = await SyncInstituteData(univ, univProdi, objIns);
      }
      //end mapping univ and prodi

      //update data candidate
      if (!isExistStd && dcandidate && (dcandidate.regstudent.university_id !== syc_ins.univid || dcandidate.regstudent.university_prodi_id != syc_ins.univprodiid || dcandidate.regstudent.university_last_semester != univLastSemes)) {
        console.log("Candidate Student");
        const param_candidate = { id: dcandidate.regstudent.id, university_id: syc_ins.univid, university_prodi_id: syc_ins.univprodiid, university_last_semester: univLastSemes };
        const result_update = await UPDATE_CANDIDATE(param_candidate);
      }
      //end update data candidate

      //update data student institution
      if (isExistStd) {
        console.log("Existing Student");
        const reff_ins_id = dcandidate.reff_ins_id;
        if (Object.values(dcandidate.reff_ins).length > 0) {
          const find_data_univ = dcandidate.reff_ins.find(item => Object.values(item.university).length > 0);
          //check if student already registered in other university
          if (find_data_univ.university_id !== syc_ins.univid || find_data_univ.university_prodi_id !== syc_ins.univprodiid || find_data_univ.lastsemester !== univLastSemes) {
            //update data student prev univ
            const param_student_inst = { id: find_data_univ.id, university_id: syc_ins.univid, university_prodi_id: syc_ins.univprodiid, lastsemester: univLastSemes };
            const result_update = await UPDATE_INSTITUTIONHIST(param_student_inst);
            console.log(result_update);
            //end update data student prev univ
          }
        } else {
          //insert data student prev univ
          const param_student_inst = { reff_id: reff_ins_id, university_id: syc_ins.univid, university_prodi_id: syc_ins.univprodiid, lastsemester: univLastSemes, institution_type: "identity.ins.type.university" };
          const result_insert = await CREATE_INSTITUTIONHIST(param_student_inst);
          console.log(result_insert);
          //end insert data student prev univ 010324175014-insth-7 010324175014-insth-7
        }
      }
      //end of update data student institution

      const dataParam = {
        candidates: candidates,
        doc_date: doc_date,
        target_dept: target_dept,
        university_id: syc_ins.univid,
        university_prodi: syc_ins.univprodiid,
        courses: courses,
        total_credit: { totalCredit, totalCreditExt },
      };

      let param_post = {
        candidate_id: dataParam.candidates,
        doc_date: moment(doc_date).format('YYYY-MM-DD'),
      };

      let result = { loading: true, data: [], message: "" };
      const proposal_id = location.state?.proposal_id;
      if (proposal_id) {
        param_post.proposal_id = proposal_id;
        param_post.courses = dataParam.courses.map(v => {
          let obj = {};
          if (v.course_dt_id) {
            obj.id = v.course_dt_id;
          }
          obj.course_id = v.course_id;
          obj.course_ext = v.course_ext,
            obj.credit_ext = v.credit_ext.toString(),
            obj.score = parseFloat(v.score),
            obj.score_ext = v.score_ext
          return obj;
        });
        result = await UPDATE_TRANSFER_PROPOSAL(param_post);
      } else {
        param_post.courses = dataParam.courses.map(v => {
          let obj = {};
          obj.course_id = v.course_id;
          obj.course_ext = v.course_ext,
            obj.credit_ext = v.credit_ext.toString(),
            obj.score = parseFloat(v.score),
            obj.score_ext = v.score_ext
          return obj;
        });
        result = await CREATE_TRANSFER_PROPOSAL(param_post);
      }

      if (result.data && Object.values(result.data).length > 0) {
        let payload = {
          data: dataParam,
          mycourses: mycourses,
          courseData: courseData,
          result_draft: result.data
        }
        if(proposal_id) {
          payload.proposal_id = proposal_id;
        }
        navigate('/course-managements/transfer-credit-proposal/preview', {
          state: payload
        });
      } else {
        openModal({ message: <MessageError message={result.message} /> });
      }
      setSubmit(result);
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  };

  return widget_108 ? (
    <div className="form-proposal">
      <form method="post" autoComplete="off" onSubmit={(e) => SubmitHandler(e)}>
        <div className="page-title d-flex flex-column justify-content-center flex-wrap mb-8 ">
          <h1 className="page-heading d-flex text-gray-900 fw-bold fs-3 flex-column justify-content-center my-0">
            Transfer Credit Proposal Form
          </h1>

          <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 pt-1">
            <li className="breadcrumb-item text-muted">
              <LinkBtn
                to={"/course-managements/transfer-credit-proposal"}
                className="d-flex align-items-center justify-content-center text-muted text-hover-primary"
              >
                <KTIcon iconName="arrow-left" className="fs-3 me-1" />
                Back
              </LinkBtn>
            </li>
          </ul>
        </div>

        <div className="row">
          <div className="col-12 mb-5">
            <CandidateData
              objIns={objIns}
              setObjIns={setObjIns}
              isUpdate={isUpdate}
              cadidates={candidates}
              setDcandidate={setDcandidate}
              setCandidates={setCandidates}
              univ={univ}
              setUniv={setUniv}
              setUnivProdi={setUnivProdi}
              target_dept={target_dept}
              setTargetDept={setTargetDept}
              doc_date={doc_date}
              setDoc_date={setDoc_date}
              setIsExist={setIsExist}
              univLastSemes={univLastSemes}
              setUnivLastSemes={setUnivLastSemes}
              isExistStd={isExistStd}
              setIsExistStd={setIsExistStd}
            />
          </div>
          <div className="col-lg-4 mb-5">
            <CourseOffering
              courseData={courseData}
              setCourses={setCourseData}
            />
          </div>
          <div className="col-lg-8 mb-5">
            <MatriculationCourse
              mycourses={mycourses}
              setMyCourses={setMyCourses}
              RemoveCourse={RemoveCourse}
              setCourses={setCourseData}
            />
          </div>
        </div>

        {isExist ? (
          <div className="mb-5">
            <AlertNotif title={"Attention"} messages={"This candidate has already been created. Please select another candidate."} />
          </div>
        ) : (
          <div className="rounded bg-white border px-8 py-5">
            <div className="d-flex align-items-center justify-content-between">
              <LinkBtn
                to={"/course-managements/transfer-credit-proposal"}
                className="btn btn-sm btn-light fw-bold"
              >
                Cancel
              </LinkBtn>
              <ButtonPrimary
                type="submit"
                className="btn-sm px-10"
                disabled={submit.loading}
              >
                {submit.loading ? "Processing..." : "Save"}
              </ButtonPrimary>
            </div>
          </div>
        )}
      </form>
    </div>
  ) : (
    <AccessInvalid />
  );
}
