import moment from "moment";
import { ButtonDanger, ButtonTersier } from "../../../../_layouts/components/buttons/ButtonUI";
import { MessageError, MessageSuccess, openModal } from "../../../../_layouts/components/modals/Modals";
import { DELETE_ACAD_MILESTONE } from "../../apis/node-target-data/AcademicApi";

const addStatus = (items) => {
  const now = moment();

  return items.map((item) => {
    const startDt = moment(item.start_dt, "DD MMM YYYY, hh:mm A");
    const endDt = moment(item.end_dt, "DD MMM YYYY, hh:mm A");
    let status = "";

    if (now.isBefore(startDt)) {
      status = "Upcoming";
    } else if (now.isBetween(startDt, endDt, null, "[]")) {
      status = "Scheduled";
    } else if (now.isAfter(endDt)) {
      status = "Done";
    }

    return { ...item, status };
  });
};

const addStatusCallMonth = (items) => {
  const now = moment();

  return items.map((item) => {
    const startDt = moment(item.start_dt_formatted, "DD MMM YYYY, hh:mm A");
    const endDt = moment(item.end_dt_formatted, "DD MMM YYYY, hh:mm A");
    let status = "", status_id = 0;

    if (now.isBefore(startDt)) {
      status = "Upcoming";
      status_id = 3;
    } else if (now.isBetween(startDt, endDt, null, "[]")) {
      status = "Scheduled";
      status_id = 2;
    } else if (now.isAfter(endDt)) {
      status = "Done";
      status_id = 1;
    }

    return { ...item, status, status_id };
  });
};

const ColorStatus = (status) => {
  let labels = { color: "", text: status };
  if (["Scheduled", "Open"].includes(status)) {
    labels = { color: "success", text: "Open" };
  } else if (["Done", "Closed"].includes(status)) {
    labels = { color: "danger", text: "Closed" };
  } else if (status === "Upcoming") {
    labels = { color: "primary", text: "Upcoming" };
  } else {
    labels = { color: "light", text: status };
  }

  return labels;
};

const ConfirmRemoveItem = ({ id, ReloadData }) => {
  const RemoveItem = async () => {
    const param = { mstone_id: id };
    const result = await DELETE_ACAD_MILESTONE(param);
    console.log(result);
    if(result.message !== ""){
        openModal({ message: <MessageError message={result.message} /> });
    }else{
        openModal({ message: <MessageSuccess message={"Successfully saved"} /> });
        ReloadData();
    }
  };

  return (
    <div className="text-center">
      <p className="fs-2">Are you sure wants to remove this item?</p>

      <ButtonTersier
        type="button"
        className="btn-lg fw-bold me-3"
        onClick={() => openModal({ open: false })}
      >
        No
      </ButtonTersier>
      <ButtonDanger
        type="button"
        className="btn-lg fw-bold"
        onClick={() => RemoveItem()}
      >
        Remove please
      </ButtonDanger>
    </div>
  );
};

const NameOfMonth = ({start_dt, end_dt}) =>{
  const month_start = moment(start_dt).format("MMMM YYYY");
  console.log(month_start);
  const month_end = moment(end_dt).format("MMMM YYYY");
  console.log(month_end);

  if(month_start === month_end){
    return moment(end_dt).format("MMM YYYY");
  }else{
    return moment(start_dt).format("MMM") + "-" + moment(end_dt).format("MMM YYYY");
  }
}

export { addStatus, addStatusCallMonth, ColorStatus, ConfirmRemoveItem, NameOfMonth };
