const ConvertEventItem = (data) => {
    let events = [];
    if (data) {
        let hasConflict = ConflictSchedule(data);
        hasConflict.map((v) => {
            var objItem = {};
            objItem.title = v.label ? v.label : (v.section_id ? (v.course ? v.course.coursetitle : "-") : v.label);
            objItem.id = v.section_id ? v.section_id : "";
            objItem.start = v.start_dt;
            objItem.end = v.end_dt;
            objItem.groupId = JSON.stringify(v);
            objItem.event_room = (v.event_room) ? v.event_room : "";
            events.push(objItem);
        })
    }

    return events;
}

const ConflictSchedule = (dataArr) => {
    const updatedEvents = [...dataArr];

    for (let i = 0; i < updatedEvents.length; i++) {
        for (let j = i + 1; j < updatedEvents.length; j++) {
            const eventA = updatedEvents[i];
            const eventB = updatedEvents[j];

            const startA = new Date(eventA.start_dt);
            const endA = new Date(eventA.end_dt);
            const startB = new Date(eventB.start_dt);
            const endB = new Date(eventB.end_dt);

            if (startA < endB && endA > startB) {
                eventA.conflict = true;
                eventB.conflict = true;
            }
        }
    }

    return updatedEvents;
}

export { ConvertEventItem }