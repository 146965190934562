import React, { useState } from 'react';
import { LookUpEmp } from '../../../components/forms/look-up-emp/InputLookUp';
import { openModal } from '../../../../../_layouts/components/modals/Modals';
import { ButtonIcon, ButtonPrimary, ButtonTersier } from '../../../../../_layouts/components/buttons/ButtonUI';

export function FormLecturer({ wizardActv, setWizardActv, paramSection, setParamSection }) {
    let rowSelected = 0;
    const lectParam = { emp_id: 0, emp_session: 0, emp_name: "" };
    const [rowLect, setRowLect] = useState([lectParam]);
    const chooseLec = (e, value) => {
        var myButton = e.target;
        var classSpinner = ['spinner', 'spinner-white', 'spinner-left'];
        myButton.classList.add(...classSpinner);
        const newData = [...rowLect];
        newData[rowSelected].emp_id = value.id;
        newData[rowSelected].emp_name = value.name_related;
        setRowLect(newData);
        openModal({ open: false });
    }

    const openLectList = (idx) => {
        rowSelected = idx;
        openModal({ header: "Find Lecturer", message: <LookUpEmp chooseLec={chooseLec} /> })
    }

    const FormRowLecturer = () => {
        return (
            (Object.values(rowLect).length > 0) ? (
                rowLect.map((v, index) => (
                    (v) ? (
                        <tr key={index}>
                            <td>
                                <div className="input-group cursor-pointer" onClick={() => openLectList(index)} >
                                    <input type="text" readOnly className="form-control form-control-lg mb-3 mb-lg-0" placeholder="click here to find a lecturer" value={(v.emp_name) ? v.emp_name : ''} />
                                    <span className="input-group-text" title='find subject'>
                                        <i className="bi bi-search"></i>
                                    </span>
                                </div>
                            </td>
                            <td>
                                <input type="text" name="emp_session" className='form-control form-control-lg mb-3 mb-lg-0' defaultValue={v.emp_session} onMouseLeave={(e) => setRowLect((prev) => {
                                    const newData = [...prev]
                                    newData[index].emp_session = parseFloat(e.target.value)
                                    return newData
                                })} />
                            </td>
                            <td>
                                {(index !== 0) && (
                                    <ButtonIcon className="btn-sm btn-light-danger" type="button" onClick={() => setRowLect((prev) => {
                                        const newData = [...prev]
                                        delete newData[index]
                                        return newData
                                    })}>
                                        <i className="bi bi-trash"></i>
                                    </ButtonIcon>
                                )}
                            </td>
                        </tr>
                    ) : ''
                ))
            ) : ''
        )
    }

    const handlerNext = () => {
        var currLect = rowLect.filter((item) => {
            if (item)
                return item
        });
        setParamSection({ ...paramSection, emp: [] })
        let arrObj = []; let totalSession = 0;
        currLect.map((v) => {
            totalSession = totalSession + v.emp_session;
            var obj = {};
            obj.emp_id = v.emp_id;
            obj.emp_session = v.emp_session;
            arrObj.push(obj);
        })
        totalSession = totalSession.toFixed(2);
        
        const isemptydata = currLect.filter((item) => {
            if (item) {
                //return (item.emp_id === 0 || item.emp_session === 0)
                return (item.emp_id === 0 )
            }
        });

        if (currLect.length > 0) {
            if (isemptydata.length === 0) { //keisi            
                if (totalSession > paramSection.section_session) {
                    openModal({ header: "Please check again your field", message: <p className="text-dark">Total lecturer session <span className="text-danger">[{totalSession}]</span> more than maximum section session <span className="text-success">[{paramSection.section_session}]</span> </p>, open: true });
                } else {
                    setParamSection({ ...paramSection, emp: currLect });
                    setWizardActv(wizardActv + 1);
                }
            } else {
                openModal({ header: "Please check again your field", message: <p className="text-dark">Please fill up the form of Lecturer with correctly</p>, open: true });
            }
        } else {
            const isFillEmp = currLect.filter((item) => {
                if (item) {
                    return (item.emp_id !== 0)
                }
            });
            if (isFillEmp.length > 0) {
                if (totalSession === 0) {
                    openModal({ header: "Please check again your field", message: <p className="text-dark">Please fill up the form of Lecturer with correctly</p>, open: true });
                } else if (totalSession > paramSection.section_session) {
                    openModal({ header: "Please check again your field", message: <p className="text-dark">Total lecturer session <span className="text-danger">[{totalSession}]</span> more than maximum section session <span className="text-success">[{paramSection.section_session}]</span> </p>, open: true });
                } else {
                    setParamSection({ ...paramSection, emp: currLect });
                    setWizardActv(wizardActv + 1);
                }
            } else {
                setParamSection({ ...paramSection, emp: [] });
                setWizardActv(wizardActv + 1);
            }
        }
    }

    return (
        <div className="field-lecturer">
            <div id='section-lecturers' className='table-responsive'>
                <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                    <thead>
                        <tr className="fw-bolder text-dark">
                            <th><label className="required">Fullname</label></th>
                            <th width="10%"><label className="required">Session</label></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {<FormRowLecturer />}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={3}>
                                <ButtonPrimary className="btn-sm fw-bold btn-light-primary btn-hover-light" type='button' onClick={() => setRowLect(prev => { return [...prev, lectParam] })}>
                                    <i className="bi bi-plus"></i>
                                    Add new Row
                                </ButtonPrimary>
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>

            <div className="text-end">
                <div className="btn-group">
                    <ButtonTersier className="btn-lg btn-secondary" type="button" onClick={() => setWizardActv(wizardActv - 1)}>Back</ButtonTersier>
                    <ButtonPrimary className="btn-lg fw-bold" type="button" onClick={() => handlerNext()}>
                        <span className="me-2">Next </span>
                        <i className="bi bi-arrow-right"></i>
                    </ButtonPrimary>
                </div>
            </div>
        </div>
    )
}
