import React, { useState } from 'react'
import { ButtonPrimary, ButtonTersier } from '../../../../../../../_layouts/components/buttons/ButtonUI'
import { RemoveEmptyValue } from '../../../../../../../_layouts/_helpers/Global';
import { CREATE_UNIV } from '../../../../../apis/node-target-data/IdentityApi';
import { openModal } from '../../../../../../../_layouts/components/modals/Modals';

export function FormUniv({ setData }) {
    const [paramPost, setParamPost] = useState({
        name: "",
        edu_level: 0,
        isMadrasah: 0,
        isSMK: 0,
        isSwasta: 0
    });

    const [submit, setSubmit] = useState({ loading: false, data: [], message: "" });

    const HandlerSubmit = async (e) => {
        e.preventDefault();
        if (paramPost.name) {
            setSubmit({ loading: true, data: [], message: "" });
            const param = RemoveEmptyValue(paramPost);
            const result = await CREATE_UNIV(param);

            if (result.data && Object.values(result.data).length > 0) {
                setData(result.data.id);
                openModal({ open: false });
            } else {
                setSubmit(result);
            }

        } else {
            setSubmit({ loading: false, data: [], message: RequiredMsg });
        }
    }
    return (
        <form method='post' autoComplete='off' onSubmit={(e) => HandlerSubmit(e)}>
            <div className="mb-5">
                <h3>Form University</h3>
                <span className="text-muted">Please fill up the form with correctly</span>
            </div>

            <div className="form-group mb-8">
                <label className="fw-bold required">Name</label>
                <input type="text" required className="form-control form-control-sm" defaultValue={paramPost.name} onChange={(e) => setParamPost({ ...paramPost, name: encodeURIComponent(e.target.value) })} />
            </div>

            {submit.message && (
                <div className="mb-5">
                    <AlertNotif messages={submit.message} />
                </div>
            )}

            <div className="text-end">
                <ButtonTersier className="btn-sm fw-bold me-3" type="button" onClick={() => openModal({ open: false })}>
                    Cancel
                </ButtonTersier>
                <ButtonPrimary className="btn-sm fw-bold" type="submit" disabled={submit.loading}>
                    {submit.loading ? "Processing..." : "Save Changes"}
                </ButtonPrimary>
            </div>
        </form>
    )
}
