import React, { useState } from 'react'
import { SGUCard } from '../../../../../_layouts/components/cards/CardUI'
import { AcadTerm, Corporate, CourseOwner, Department, DepartmentSpec } from '../../../components/forms/SelectBox'
import { ButtonPrimary, ButtonTersier } from '../../../../../_layouts/components/buttons/ButtonUI'
import { RemoveEmptyValue } from '../../../../../_layouts/_helpers/Global';
import { AlertNotif } from '../../../../../_layouts/components/alerts/AlertsUI';
import { MessageSuccess, openModal } from '../../../../../_layouts/components/modals/Modals';
import { CREATE_SECTION } from '../../../apis/node-target-data/AcademicApi';

export function PreviewSection({ data, setIsPrev, Back2Page }) {
    const [submit, setSubmit] = useState({ loading: false, message: "", data: [] });
    const SubmitSection = async () => {

        setSubmit({ loading: true, message: "", data: [] })
        const param = RemoveEmptyValue(data);
        const result = await CREATE_SECTION(param);

        if (result.data && Object.values(result.data).length > 0) {
            openModal({ message: <MessageSuccess message={"The section has been successfully created."} /> })
            setTimeout(() => {
                Back2Page();
            }, 1000);
        } else {
            setSubmit(result)
        }
    }

    return (
        <div>
            <h1 className="my-4">Preview Section</h1>
            <SGUCard>
                <SGUCard.Body>
                    <div className="pt-8">
                        <div className="section-atribute">
                            <h3 className='text-primary'>Section Attribute:</h3>
                            <div className="row mb-6">
                                <div className="col-6">
                                    <CourseOwner disabled={true} data={data.cost_center} />
                                </div>
                                <div className="col-6">
                                    <AcadTerm disabled={true} data={data.term_id} />
                                </div>
                                <div className="col-4">
                                    <div className="form-group mb-5">
                                        <label className="fw-bolder">Max Number of Student</label>
                                        <input type="text" className="form-control form-control-sm" disabled={true} value={data.max_seat} />
                                    </div>
                                </div>
                                <div className="col-4">
                                    <Corporate disabled={true} data={data.corporate_id} />
                                </div>
                            </div>
                        </div>
                        <div className="lecturer-assign">
                            <h3 className='text-primary'>Lecturer Assignment:</h3>
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                    <tr className="fw-bolder text-dark">
                                        <th>Fullname</th>
                                        <th>Session</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.emp.map((v, index) => (
                                        <tr key={index}>
                                            <td><input type="text" className="form-control form-control-sm" value={v.emp_name} disabled={true} /></td>
                                            <td width={"15%"}><input type="text" className="form-control form-control-sm" value={v.emp_session} disabled={true} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="lecturer-assign">
                            <h3 className='text-primary'>Study Program Qualification:</h3>
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <tbody>
                                    {Object.values(data.prereq).length > 0 ? (
                                        data.prereq.map((v, index) => (
                                            <tr key={index}>
                                                <td>
                                                    <Department data={v.clu_id} disabled={true} />
                                                </td>
                                                <td>
                                                    <DepartmentSpec data={v.clu_spec_id} disabled={true} />
                                                </td>
                                                <td>
                                                    <div className="form-group mb-5">
                                                        <label className="fw-bolder">Semester</label>
                                                        <input type="text" className="form-control form-control-sm" disabled={true} value={v.semester} />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="form-group mb-5">
                                                        <label className="fw-bolder">Class Group</label>
                                                        <input type="text" className="form-control form-control-sm" disabled={true} value={v.std_class} />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={4}>
                                                <p className="text-dark mb-0">No selected Prody</p>
                                            </td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                        <div className="text-center">
                            <ButtonTersier className="btn-lg fw-bold me-3" type="button" onClick={() => setIsPrev(false)}>
                                Cancel
                            </ButtonTersier>
                            <ButtonPrimary className="btn-lg fw-bold" type="button" disabled={submit.loading} onClick={() => SubmitSection()}>
                                {submit.loading ? "Processing..." : "Submit"}
                            </ButtonPrimary>
                        </div>

                        {submit.message && (
                            <div className='mt-5'>
                                <AlertNotif messages={submit.message} />
                            </div>
                        )}
                    </div>
                </SGUCard.Body>
            </SGUCard>
        </div>
    )
}
