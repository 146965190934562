import React, { useEffect, useState } from "react";
import moment from "moment";
import { GET_SCHD_CLASS_LEC_NEXT } from "../../apis/node-target-data/AcademicApi";
import { LoadingSkeleton } from "../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif, AlertRetry } from "../../../../_layouts/components/alerts/AlertsUI";
import FullCalendarSchd from "./FullCalendarLectSchd";
import { SGUCardFlush } from "../../../../_layouts/components/cards/CardUI";
import { ButtonIcon, ButtonTersier } from "../../../../_layouts/components/buttons/ButtonUI";

export default function EmpTimetable({ employee_id }) {
  const startOfMonth = moment()
    .startOf("month")
    .format("YYYY-MM-DD");
  const endOfMonth = moment()
    .endOf("month")
    .add(7, "days")
    .format("YYYY-MM-DD");

  const objPost = {
    empid: employee_id,
    tgl: startOfMonth,
    tglend: endOfMonth,
  };
  const [postParam, setPostParam] = useState(objPost);

  const [schd, setSchd] = useState({
    message: "",
    data: [],
    loading: false
  });

  const GET_SCHD_LOOK_UP = async (param) => {
    setSchd({ loading: true, message: "", data: [] });
    const result = await GET_SCHD_CLASS_LEC_NEXT(param)
    if (result.data && Object.values(result.data).length > 0) {
      var eventItem = convertEventItem(result.data);
      setSchd({ loading: false, message: "", data: eventItem });
    } else {
      setSchd(result);
    }
  }



  useEffect(() => {
    GET_SCHD_LOOK_UP(postParam);
  }, []);

  const handlerPrevNextMonth = (type) => {
    if (type === 1) {
      //prev
      const startOfMonth = moment(postParam.tgl)
        .subtract(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = moment(postParam.tgl)
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      setPostParam({ ...postParam, tgl: startOfMonth, tglend: endOfMonth });
      var paramSchd = {
        empid: employee_id,
        tgl: startOfMonth,
        tglend: endOfMonth,
      };
      GET_SCHD_LOOK_UP(paramSchd);
    } else if (type === 2) {
      //next
      const startOfMonth = moment(postParam.tgl)
        .add(1, "months")
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = moment(postParam.tgl)
        .add(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      setPostParam({ ...postParam, tgl: startOfMonth, tglend: endOfMonth });
      var paramSchd = {
        empid: employee_id,
        tgl: startOfMonth,
        tglend: endOfMonth,
      };
      GET_SCHD_LOOK_UP(paramSchd);
    } else {
      //today
      const startOfMonth = moment(new Date())
        .startOf("month")
        .format("YYYY-MM-DD");
      const endOfMonth = moment(new Date())
        .endOf("month")
        .format("YYYY-MM-DD");
      setPostParam({ ...postParam, tgl: startOfMonth, tglend: endOfMonth });
      var paramSchd = {
        empid: employee_id,
        tgl: startOfMonth,
        tglend: endOfMonth,
      };
      GET_SCHD_LOOK_UP(paramSchd);
    }
  };

  return (
    <SGUCardFlush>
      <SGUCardFlush.Header>
        <span className="card-label fw-bold text-gray-900">Timetables</span>
      </SGUCardFlush.Header>
      <SGUCardFlush.Toolbar>
        <div className="btn-group">
          <ButtonIcon
            className="btn-lg btn-light"
            type="button"
            disabled={schd.loading}
            onClick={() => handlerPrevNextMonth(1)}
          >
            <i className="ki-outline ki-left fs-5 m-0"></i>
          </ButtonIcon>
          <ButtonTersier
            className="btn-lg"
            type="button"
            disabled={schd.loading}
            onClick={() => handlerPrevNextMonth(3)}
          >
            <span className="fw-bolder">
              {moment(postParam.tgl).format("MMMM YYYY")}
            </span>
          </ButtonTersier>
          <ButtonIcon
            className="btn-lg btn-light"
            type="button"
            disabled={schd.loading}
            onClick={() => handlerPrevNextMonth(2)}
          >
            <i className="ki-outline ki-right fs-5 m-0"></i>
          </ButtonIcon>
        </div>
      </SGUCardFlush.Toolbar>
      <SGUCardFlush.Body>
        {schd.loading ? <LoadingSkeleton /> :
          schd.message ? <AlertRetry messages={schd.message} Reload={GET_SCHD_LOOK_UP(postParam)} /> :
            <FullCalendarSchd
              data={Object.values(schd.data).length > 0 ? schd.data : []}
              postParam={postParam}
              actionDetail={true}
            />
        }
      </SGUCardFlush.Body>
    </SGUCardFlush>

  );
}

const getDate = (dayString) => {
  const today = new Date();
  const year = today.getFullYear().toString();
  let month = (today.getMonth() + 1).toString();

  if (month.length === 1) {
    month = "0" + month;
  }

  return dayString.replace("YEAR", year).replace("MONTH", month);
};

const convertEventItem = (data) => {
  let events = [];
  if (data) {
    data.map((v) => {
      var objItem = {};
      objItem.title = v.section_id ? (v.course ? v.course.coursetitle : "-") : v.label;
      objItem.id = v.section_id ? v.section_id : "";
      objItem.start = getDate(v.start_dt);
      objItem.end = getDate(v.end_dt);
      objItem.groupId = JSON.stringify(v);
      events.push(objItem);
    });
  }

  return events;
};
