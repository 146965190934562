import { useEffect, useMemo, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import {
  ButtonIcon,
  ButtonPrimary,
} from "../../../../../../_layouts/components/buttons/ButtonUI";
import {
  CANCEL_MKT_FIN_TRANS,
  CREATE_MKT_COUPON,
  CREATE_MKT_FIN_TRANS,
  DELETE_MKT_COUPON,
  GET_MKT_COUPON,
} from "../../../../apis/node-target-data/MarketingAPI";
import {
  LoadingSkeleton,
  NoRecordFound,
  SpinnerLoad,
} from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif } from "../../../../../../_layouts/components/alerts/AlertsUI";
import {
  ExpiredDate,
  filterOnlyNumbers,
  formatRibuan,
} from "../../../components/Helper";
import {
  BadgeItem,
  ButtonAccept,
  ButtonCetakCoupon,
  ButtonCoupon,
} from "../../../components/EnrollmentComp";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { CetakCoupon } from "./CetakCoupon";
import { renderTooltip } from "../../../../../../_layouts/components/popovers/PopOvers";

const ModalCoupon = ({
  id = 9999,
  typeModal = "main",
  candidate_id,
  trans_id_regfee,
  HandlerPage,
  enroll_id,
}) => {
  const [couponQty, setCouponQty] = useState(1);
  const [expiredDate, setExpiredDate] = useState(
    new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000)
  );
  const [type, setType] = useState("nominal");
  const [couponType, setCouponType] = useState("regfee");
  const [amount, setAmount] = useState(0);
  const [isCetak, setIsCetak] = useState(false);
  const [anyUpdate, setAnyUpdate] = useState(false);
  const [copyStatus, setCopyStatus] = useState("Copy coupon");

  const [couponList, setCouponList] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const [generateCoupon, setGenerateCoupon] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const [deleteCoupon, setDeleteCoupon] = useState({
    loading: false,
    message: "",
    data: [],
  });
  const [setCoupon, setSetCoupon] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const handleCouponNumber = (evt, incoming_num) => {
    const limit = 100;
    const num = filterOnlyNumbers(incoming_num);
    if (num || evt !== "var") {
      if (evt === "minus" && couponQty > 1) {
        setCouponQty(couponQty - 1);
      } else if (evt === "plus" && couponQty < limit) {
        setCouponQty(couponQty + 1);
      } else if (evt === "var") {
        if (filterOnlyNumbers(num)) {
          if (num * 1 > limit) {
            setCouponQty(limit);
          } else if (num * 1 < 1) {
            setCouponQty(1);
          } else {
            setCouponQty(num * 1);
          }
        }
      }
    }
  };

  const SeeCoupon = async () => {
    setCouponList({ loading: true, message: "", data: [] });
    const res = await GET_MKT_COUPON({ status: "unused" });
    console.log("coupon ", res);
    setCouponList(res);
  };

  const CoockedData = useMemo(() => {
    let computedData = [];
    if (couponList) {
      computedData = couponList.data.map((c) => {
        var obj = {};
        obj.coupon_type = c.coupon_type?.includes("regfee")
          ? "Reg Fee"
          : "Tuition Fee";
        obj.coupon_no = c.coupon_no;
        obj.discount_type = c.discount_type;
        obj.coupon_discount = formatRibuan(c.coupon_discount);
        obj.coupon_created = moment(c.coupon_created).format(
          "DD MMM YYYY HH:mm"
        );
        obj.expiration_date = moment(c.expiration_date).format("DD MMM YYYY");
        obj.desc = c.coupon_created;
        obj.real = c;
        return obj;
      });

      computedData.sort((a, b) => (b.desc > a.desc ? 1 : -1));

      return computedData;
    }
  }, [couponList]);

  const setLimitationAmount = (value) => {
    const trueInt = filterOnlyNumbers(value) * 1;
    if (type === "nominal") {
      if (trueInt > 500000) {
        setAmount(500000);
      } else {
        setAmount(trueInt);
      }
    } else {
      if (trueInt > 100) {
        setAmount(100);
      } else {
        setAmount(trueInt);
      }
    }
  };

  useEffect(() => {
    setLimitationAmount(amount);
  }, [type]);

  const CreateCoupon = async () => {
    setGenerateCoupon({ loading: true, message: "", data: [] });
    const param = {
      qty: couponQty,
      expiration_date: moment(expiredDate).format("YYYY-MM-DD"),
      discount_type:
        type === "nominal"
          ? "mkt.coupon.amount.type.nominal"
          : "mkt.coupon.amount.type.percentage",
      amount: amount,
      coupon_type:
        couponType === "regfee"
          ? "mkt.coupon.type.regfee"
          : "mkt.coupon.type.tuitionfee",
    };
    const res = await CREATE_MKT_COUPON(param);
    if (res.message === "") {
      res.message = "success";
      setGenerateCoupon(res);
      setTimeout(() => {
        res.message = "";
        setGenerateCoupon(res);
        SeeCoupon();
      }, 2000);
    } else {
      setGenerateCoupon(res);
      setTimeout(() => {
        setGenerateCoupon({ loading: false, message: "", data: [] });
      }, 2000);
    }
  };

  const DeleteCoupon = async (coupon_no) => {
    setDeleteCoupon({ loading: true, message: "", data: [] });
    const del = await DELETE_MKT_COUPON({ coupon_no: coupon_no });
    if (del.message === "") {
      del.message = "success";
      setDeleteCoupon(del);
      setTimeout(() => {
        del.message = "";
        setDeleteCoupon(del);
        SeeCoupon();
      }, 2000);
    } else {
      setDeleteCoupon(del);
      setTimeout(() => {
        setDeleteCoupon({ loading: false, message: "", data: [] });
      }, 2000);
    }
  };

  const SetCouponFunc = async (coupon_no) => {
    setSetCoupon({ loading: true, message: "", data: [] });
    // cancel existing record
    const cancel = await CANCEL_MKT_FIN_TRANS({ trans_id: trans_id_regfee });
    if (cancel.message === "") {
      const set = await CREATE_MKT_FIN_TRANS({
        enroll_id: enroll_id,
        candidate_id: candidate_id,
        trans_type: "mkt.fin.trans.type.regfee", // mkt.fin.trans.type.regfee | mkt.fin.trans.type.tuitionfee
        coupon_no: coupon_no,
      });
      if (set.message === "") {
        set.message = "success";
        setSetCoupon(set);
        setTimeout(() => {
          set.message = "";
          setSetCoupon(set);
          SeeCoupon();
          setAnyUpdate(true);
        }, 2000);
      } else {
        setSetCoupon(set);
        setTimeout(() => {
          setSetCoupon({ loading: false, message: "", data: [] });
        }, 2000);
      }
    } else {
      setSetCoupon(cancel);
      setTimeout(() => {
        setSetCoupon({ loading: false, message: "", data: [] });
      }, 2000);
    }

    // candidate id
    // trans type
    // coupon number
  };

  const HandleCloseUpdate = () => {
    if (anyUpdate) {
      HandlerPage(0);
      setAnyUpdate(false);
    }
  };

  const PopUp = ({ coupon_no }) => {
    return (
      <OverlayTrigger
        trigger="click"
        rootClose
        placement="right"
        overlay={
          <Popover className="p-3">
            <Popover.Body className="p-0">
              <>
                {typeModal !== "main" && (
                  <div
                    className="cursor-pointer bg-hover-light-primary text-hover-primary ps-3 pe-5 py-3 rounded d-flex align-items-center"
                    onClick={() => SetCouponFunc(coupon_no)}
                  >
                    <KTIcon iconName="shield-tick" className="fs-3 me-2" />
                    <span>Set coupon</span>
                  </div>
                )}
                <div
                  className="cursor-pointer bg-hover-light-danger text-hover-danger ps-3 pe-5 py-3 rounded d-flex align-items-center"
                  onClick={() => DeleteCoupon(coupon_no)}
                >
                  <KTIcon iconName="trash" className="fs-3 me-2" />
                  <span>Delete coupon</span>
                </div>
              </>
            </Popover.Body>
          </Popover>
        }
      >
        <i className="ki-duotone ki-burger-menu-5 fs-3 cursor-pointer"></i>
      </OverlayTrigger>
    );
  };

  const HandleAlert = ({ action, job }) => {
    if (action.loading) {
      return <AlertCoupon type="primary" job={job} />;
    }
    if (action.message !== "success" && action.message !== "") {
      return <AlertCoupon type="danger" job={job} />;
    }
    if (action.message === "success") {
      return <AlertCoupon type="success" job={job} />;
    }
    return null;
  };

  const AlertCoupon = ({ type = "success", job = "create" }) => {
    // type => success | failed | loading =<>= success | danger | primary
    // job => ceate | delete
    return (
      <div className={`alert alert-${type} d-flex align-items-center p-5`}>
        {type === "success" && (
          <KTIcon
            iconName="check-circle"
            className={`fs-3x me-2 text-${type}`}
          />
        )}
        {type === "danger" && (
          <KTIcon
            iconName="cross-circle"
            className={`fs-3x me-2 text-${type}`}
          />
        )}
        {type === "primary" && (
          <KTIcon iconName="ocean" className={`fs-3x me-2 text-${type}`} />
        )}

        <div className="d-flex flex-column">
          <h4 className="mb-1 text-dark">
            {type === "success"
              ? "Success"
              : type === "danger"
              ? "Failed"
              : "Loading"}
          </h4>

          <span>
            {job === "create" && generateCoupon.message === "success"
              ? "Success create coupon"
              : generateCoupon.message}
            {job === "delete" && deleteCoupon.message === "success"
              ? "Success delete coupon"
              : deleteCoupon.message}
            {job === "set" && setCoupon.message === "success"
              ? "Success set coupon"
              : setCoupon.message}
          </span>
        </div>
      </div>
    );
  };

  const handleCopy = async (cpn) => {
    try {
      await navigator.clipboard.writeText(cpn);
      setCopyStatus("Copied");
      setTimeout(() => {
        setCopyStatus("Copy coupon");
      }, 3000);
    } catch (err) {
      setCopyStatus("Failed to copy text.");
    }
  };

  const CopyCoupon = ({ name }) => {
    return (
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement="bottom"
        overlay={renderTooltip({ title: copyStatus })}
      >
        <div>
          <span className="fs-bold" onClick={() => handleCopy(name)}>
            {name}
          </span>
        </div>
      </OverlayTrigger>
    );
  };

  return (
    <>
      {typeModal === "main" ? (
        <ButtonCoupon
          type="button"
          // className="btn btn-primary"
          className="btn-sm ms-3"
          data-bs-toggle="modal"
          data-bs-target={"#kt_modal_3" + id}
          onClick={() => SeeCoupon()}
        >
          Coupon
        </ButtonCoupon>
      ) : (
        <ButtonIcon
          title="Set a coupon"
          className="d-flex align-items-center"
          data-bs-toggle="modal"
          data-bs-target={"#kt_modal_3" + id}
          onClick={() => SeeCoupon(candidate_id)}
        >
          <KTIcon
            iconName="discount"
            className="fs-1 me-2 text-hover-primary"
          />
        </ButtonIcon>
      )}
      <div className="modal fade" tabIndex="-1" id={"kt_modal_3" + id}>
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content position-absolute">
            <div className="modal-header">
              <h4 className="modal-title">Manage Coupon</h4>

              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                title="close"
                onClick={() => HandleCloseUpdate()}
              >
                <i className="ki-duotone ki-cross fs-2x">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </i>
              </div>
            </div>

            <div className="modal-body">
              <HandleAlert action={deleteCoupon} job="delete" />
              <HandleAlert action={generateCoupon} job="create" />
              <HandleAlert action={setCoupon} job="set" />
              <div className="d-flex justify-content-between mb-2">
                <h5>Available coupons</h5>
                <ButtonCetakCoupon
                  className="btn-sm ms-3"
                  onClick={() => CetakCoupon(CoockedData, setIsCetak)}
                  disabled={isCetak}
                >
                  {isCetak ? "Downloading..." : "Download Coupon"}
                </ButtonCetakCoupon>
              </div>
              <div style={{ height: 200, overflow: "auto" }}>
                <table className="w-100 table table-hover table-row-bordered table-row-gray-300 gy-3 position-relative">
                  <thead
                    className="position-sticky top-0 bg-white"
                    style={{ zIndex: 999 }}
                  >
                    <tr className="text-start text-gray-500 fw-bold fs-7 text-uppercase">
                      {/* <th>Type</th> */}
                      <th>code</th>
                      <th className="ps-2">Discount</th>
                      <th className="ps-2">created / expired</th>
                      {/* <th className="ps-2">expired</th> */}
                      <th className="ps-2">action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {couponList.loading ? (
                      <tr>
                        <td colSpan={5}>
                          <LoadingSkeleton />
                        </td>
                      </tr>
                    ) : couponList.message ? (
                      <tr>
                        <td colSpan={5}>
                          <AlertNotif
                            messages={couponList.message}
                            color="danger"
                          />
                        </td>
                      </tr>
                    ) : CoockedData.length > 0 ? (
                      CoockedData.map((d, i) => (
                        <tr key={i}>
                          {/* <td className="text-nowrap">{d.coupon_type}</td>
                          <td className="text-nowrap ps-2">{d.coupon_no}</td> */}
                          <td className="text-nowrap">
                            <span className="d-block cursor-pointer">
                              <CopyCoupon name={d.coupon_no} />
                            </span>
                            <BadgeItem
                              icon="dollar"
                              name={d.coupon_type}
                              title="Coupon type"
                            />
                          </td>
                          <td className="ps-2 text-nowrap">
                            {d.discount_type?.includes("nominal") && "Rp"}
                            {d.coupon_discount}
                            {d.discount_type?.includes("percentage") && "%"}
                          </td>
                          {/* <td className="ps-2 text-nowrap">
                            {d.coupon_created}
                          </td>
                          <td className="ps-2 text-nowrap">
                            {d.expiration_date}
                          </td> */}
                          <td className="text-nowrap">
                            <span className="d-block">{d.coupon_created}</span>
                            <span className="d-block">{d.expiration_date}</span>
                          </td>
                          {typeModal !== "main" ? (
                            <td className="ps-6 pt-7">
                              <PopUp coupon_no={d.coupon_no} />
                            </td>
                          ) : (
                            <td className="ps-6 pt-7">
                              {/* <KTIcon iconName="trash" className="fs-3 me-2 cursor-pointer text-hover-danger" /> */}
                              <PopUp coupon_no={d.coupon_no} />
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={10}>
                          <NoRecordFound />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>

              <div className="separator mt-10 mb-5"></div>

              <div
                className="accordion accordion-icon-collapse"
                id="kt_accordion_3"
              >
                <div className="mb-5">
                  <div
                    className="accordion-header py-3 d-flex collapsed"
                    data-bs-toggle="collapse"
                    data-bs-target="#kt_accordion_3_item_1"
                  >
                    <span className="accordion-icon">
                      <i className="ki-duotone ki-plus-square fs-3 accordion-icon-off">
                        <span className="path1"></span>
                        <span className="path2"></span>
                        <span className="path3"></span>
                      </i>
                      <i className="ki-duotone ki-minus-square fs-3 accordion-icon-on">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </i>
                    </span>
                    <h3 className="fs-4 fw-semibold mb-0 ms-4">
                      Create Coupon
                    </h3>
                  </div>

                  <div
                    id="kt_accordion_3_item_1"
                    className="fs-6 collapse ps-10"
                    data-bs-parent="#kt_accordion_3"
                  >
                    <div className="row">
                      <div className="col-12 mb-5">
                        <div
                          className={`btn btn-outline btn-outline-dashed btn-active-light-primary p-3 d-flex align-items-center mb-5 ${couponType ===
                            "regfee" && "active"}`}
                          onClick={() => setCouponType("regfee")}
                        >
                          <i className="ki-outline ki-tag fs-2x"></i>

                          <span className="d-block fw-semibold text-start">
                            <span className="text-gray-900 fw-bold d-block fs-5">
                              Registration Fee
                            </span>
                            <span className="text-muted fw-semibold fs-6">
                              Create coupon for registration fee
                            </span>
                          </span>
                        </div>
                      </div>
                      {/* <div className="col-6">
                        <div
                          className={`btn btn-outline btn-outline-dashed btn-active-light-primary p-3 d-flex align-items-center mb-5 ${couponType ===
                            "tuitionfee" && "active"}`}
                          // onClick={() => setCouponType("tuitionfee")}
                        >
                          <i className="ki-outline ki-teacher fs-2x"></i>

                          <span className="d-block fw-semibold text-start">
                            <span className="text-gray-900 fw-bold d-block fs-5">
                              Tuition Fee
                            </span>
                            <span className="text-muted fw-semibold fs-6">
                              Create coupon for tuition fee
                            </span>
                          </span>
                        </div>
                      </div> */}
                    </div>

                    <div className="d-flex justify-content-between">
                      <div>
                        <div className="mb-5">
                          <label>Quantity</label>
                          <div
                            className="d-flex align-items-center z-3"
                            style={{
                              width: 100,
                            }}
                          >
                            <i
                              className="ki-solid ki-minus-square fs-1 cursor-pointer text-hover-primary"
                              onClick={() => handleCouponNumber("minus")}
                            ></i>
                            <input
                              value={couponQty}
                              onChange={(e) =>
                                handleCouponNumber("var", e.target.value)
                              }
                              className="form-control form-control-sm text-center fw-bold"
                            />
                            <i
                              className="ki-solid ki-plus-square fs-1 cursor-pointer text-hover-primary"
                              onClick={() => handleCouponNumber("plus")}
                            ></i>
                          </div>
                        </div>
                        <div>
                          <label>Type</label>
                          <div>
                            <div
                              className="form-check form-check-custom form-check-solid form-check-sm mb-2 mt-1"
                              onClick={() => setType("nominal")}
                            >
                              <input
                                className="form-check-input cursor-pointer"
                                type="checkbox"
                                checked={type === "nominal"}
                                name="box1"
                                readOnly
                              />
                              <label
                                className="form-check-label cursor-pointer"
                                htmlFor="box1"
                              >
                                Nominal
                              </label>
                            </div>
                            <div
                              className="form-check form-check-custom form-check-solid form-check-sm"
                              onClick={() => setType("percentage")}
                            >
                              <input
                                className="form-check-input cursor-pointer"
                                type="checkbox"
                                checked={type === "percentage"}
                                name="box2"
                                readOnly
                              />
                              <label
                                className="form-check-label cursor-pointer"
                                htmlFor="box2"
                              >
                                Percentage
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="mb-5">
                          <label>Expired</label>
                          <div>
                            <DatePicker
                              className="form-control form-control-sm w-100 me-5"
                              selected={expiredDate}
                              minDate={new Date()}
                              onChange={(dt) => setExpiredDate(dt)}
                              dateFormat={"dd MMM yyyy"}
                            />
                          </div>
                        </div>
                        <div>
                          <label>Amount</label>
                          <div className="input-group input-group-sm mb-5 d-flex">
                            {/* <OverlayTrigger
                              delay={{ show: 250, hide: 400 }}
                              placement="bottom"
                              overlay={renderTooltip({
                                title:
                                  type === "nominal"
                                    ? "Change to percentage"
                                    : "Change to nominal",
                              })}
                            > */}
                            <span
                              className="input-group-text cursor-pointer"
                              // onClick={() =>
                              //   type === "nominal"
                              //     ? setType("percentage")
                              //     : setType("nominal")
                              // }
                            >
                              {type === "nominal" ? "Rp" : "%"}
                            </span>
                            {/* </OverlayTrigger> */}
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              placeholder="Amount"
                              value={amount}
                              onChange={(e) =>
                                setLimitationAmount(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        {/* btn generate */}
                        <div className="d-flex justify-content-end">
                          <ButtonPrimary
                            title="Create Coupon"
                            className="d-flex align-items-center"
                            onClick={() => CreateCoupon()}
                          >
                            {generateCoupon.loading ? (
                              <SpinnerLoad />
                            ) : (
                              <>
                                <i className="ki-outline ki-glass fs-3"></i>
                                <span>Generate</span>
                              </>
                            )}
                          </ButtonPrimary>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* <div className="d-flex" style={{ gap: 10 }}>
                  <div>
                    <label>Quantity</label>
                    <div
                      className="d-flex align-items-center z-3"
                      style={{
                        width: true ? 100 : getWidth(couponQty),
                      }}
                    >
                      <i
                        className="ki-solid ki-minus-square fs-1 cursor-pointer text-hover-primary"
                        onClick={() => handleCouponNumber("minus")}
                      ></i>
                      <input
                        value={couponQty}
                        onChange={(e) =>
                          handleCouponNumber("var", e.target.value)
                        }
                        className="form-control form-control-sm text-center fw-bold"
                      />
                      <i
                        className="ki-solid ki-plus-square fs-1 cursor-pointer text-hover-primary"
                        onClick={() => handleCouponNumber("plus")}
                      ></i>
                    </div>
                  </div>

                  <div>
                    <label>Expired</label>
                    <div>
                      <DatePicker
                        className="form-control form-control-sm"
                        selected={expiredDate}
                        onChange={(dt) => setExpiredDate(dt)}
                        dateFormat={"dd MMM yyyy"}
                      />
                    </div>
                  </div>
                  <div>
                    <div>
                      <label>Amount</label>
                      <div className="input-group input-group-sm mb-5 d-flex">
                        <OverlayTrigger
                          delay={{ show: 250, hide: 400 }}
                          placement="bottom"
                          overlay={renderTooltip({
                            title:
                              type === "nominal"
                                ? "Change to percentage"
                                : "Change to nominal",
                          })}
                        >
                          <span
                            className="input-group-text cursor-pointer"
                            onClick={() =>
                              type === "nominal"
                                ? setType("percentage")
                                : setType("nominal")
                            }
                          >
                            {type === "nominal" ? "Rp" : "%"}
                          </span>
                        </OverlayTrigger>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          placeholder="Amount"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-end">
                      <ButtonPrimary
                        title="Create Coupon"
                        className="d-flex align-items-center"
                      >
                        <i className="ki-solid ki-abstract-12 fs-3"></i>
                        <span>Generate</span>
                      </ButtonPrimary>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-light"
                data-bs-dismiss="modal"
                onClick={() => HandleCloseUpdate()}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { ModalCoupon };
