import React, { useMemo, useState } from "react";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  SearchBarTable,
} from "../../../../../../_layouts/components/datatables/MYDatatables";
import { ButtonDanger, ButtonIcon, ButtonTersier } from "../../../../../../_layouts/components/buttons/ButtonUI";
import { KTIcon } from "../../../../../../_metronic/helpers";
import { AlertNotif } from "../../../../../../_layouts/components/alerts/AlertsUI";
import { LoadingSkeleton, NoRecordFound } from "../../../../../../_layouts/components/loadbars/LoadingBars";
import { FormExaminers } from "./FormExaminers";
import { MessageError, openModal } from "../../../../../../_layouts/components/modals/Modals";
import { DELETE_THS } from "../../../../apis/node-target-data/AcademicApi";
import { useOutletContext } from "react-router-dom";

export default function TableData({ results, ReloadData, addNew, setAddNew }) {
  const context_data = useOutletContext();
  const std_ths = context_data.data.student_course_com ? context_data.data.student_course_com : [];
  const widgets = context_data.widgets;

  const components = results.data && (results.data.component || []);

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const headers = [
    { name: "No", field: "id", sortable: false },
    { name: "Examiner", field: "student_name", sortable: true },
    { name: "Privileges", field: "final_title", sortable: false },
    { name: "Actions", field: "id", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = [];
    if (results.data) {
      const examiners = results.data.examiner;
      if (results.data.examiner && Object.values(examiners).length > 0) {
        computedData = examiners.map((c) => {
          var obj = {};
          obj.emp_id = c.emp_id || 0;
          obj.emp_name = c.emp && (c.emp.fullname || "");
          obj.id = c.id || 0;
          obj.permission = c.permission && (c.permission || "");
          const check_score = std_ths.filter(item => item.submittedby_id === c.emp_id);
          obj.has_score = (check_score.length > 0) ? true : false;
          return obj;
        });
        computedData.sort((a, b) => (a.emp_name > b.emp_name ? 1 : -1));
      }

    }


    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some(
          (key) =>
            listData[key]
              .toString()
              .toLowerCase()
              .includes(search)
          //console.log(key,listData[key])
        );
      });
    }

    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    return computedData;
  }, [results.data, search, sorting]);

  return (
    <div>
      <div className="mb-3 d-flex justify-content-between align-items-center">
        <SearchBarTable
          size="w-250px"
          onChange={(e) => setSearch(e.target.value)}
        />
        <div className="d-flex">
          <ButtonItemRefresh
            totalItems={totalItems}
            onClick={() => ReloadData()}
          />
        </div>
      </div>

      <div className="table-responsives">
        <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
          <HeaderDatatables
            headers={headers}
            onSorting={(field, order) => setSorting({ field, order })}
          />
          <tbody>
            {results.loading ? (
              <tr>
                <td colSpan={4}>
                  <LoadingSkeleton />
                </td>
              </tr>
            ) : results.message ? (
              <tr>
                <td colSpan={4}>
                  <AlertNotif messages={results.message} color="danger" />
                </td>
              </tr>
            ) : (
              <>
                {(addNew.open && widgets.widget_77) && (
                  <FormExaminers components={components} dataCallback={addNew} setCallback={setAddNew} ReloadData={ReloadData} />
                )}
                {ResultData.length > 0 ? (
                  ResultData.map((v, index) => (
                    <tr key={index} className={addNew.data && (addNew.data.emp_id === v.emp_id ? "d-none" : "")}>
                      <td width={"8%"}>{index + 1}</td>
                      <td>{v.emp_name}</td>
                      <td><RowComponent data={v} /></td>
                      <td> {!v.has_score && (
                        <div className='btn-group'>
                          {widgets.widget_79 && (
                            <ButtonIcon className="btn-sm" type="button" title="Remove" onClick={() => openModal({ message: <ConfirmRemove ReloadData={ReloadData} data={v} /> })} >
                              <KTIcon iconName="trash" className="text-danger fs-2" />
                            </ButtonIcon>
                          )}
                          {widgets.widget_78 && (
                            <ButtonIcon className="btn-sm" type="button" title="Update" onClick={() => setAddNew({ open: true, data: v })} >
                              <KTIcon iconName="pencil" className="text-dark-500 fs-2" />
                            </ButtonIcon>
                          )}
                        </div>
                      )}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <NoRecordFound />
                    </td>
                  </tr>
                )}
              </>)}
          </tbody>
        </table>
      </div>
    </div>
  );
}

const RowComponent = ({ data }) => {
  return Object.values(data.permission).length > 0 && (
    data.permission.map((v, index) => (
      <div className={'badge fs-6 mx-2 ' + (v.has_access === 1 ? "badge-primary" : "badge-light")} key={index}>
        {v.name} ({v.weight * 100}%)
      </div>
    ))
  )
}

const ConfirmRemove = ({ ReloadData, data }) => {
  const HandlerRemove = async (id) => {
    const param = { reff_id: id, deletetype: "examiner" };
    const result = await DELETE_THS(param);

    if (result.message) {
      openModal({ message: <MessageError message={result.message} /> });
    }
    ReloadData();
  }

  return (
    <div className="text-center">
      <h3 className='mb-5'>Are you sure you want to remove?</h3>
      <ButtonTersier className="btn-lg fw-bold" type="button" onClick={() => openModal({ open: false })}>
        No
      </ButtonTersier>
      <ButtonDanger className="btn-lg fw-bold ms-5" type="button" onClick={() => HandlerRemove(data.id)}>
        Yes
      </ButtonDanger>
    </div>
  )
}