// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#sgu-calendar-week .fc-col-header-cell-cushion{
  color: black;
}
#sgu-calendar-week .fc-timegrid-slot-label-cushion{
  font-weight: bolder;
}
#sgu-calendar-week th{
  border: 1px solid #656564 !important;
  border-bottom: 0px !important;  
}

#sgu-calendar-week td {
  border: 1px solid #656564 !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/calendar/components/FullCalendar.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;AACA;EACE,mBAAmB;AACrB;AACA;EACE,oCAAoC;EACpC,6BAA6B;AAC/B;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":["#sgu-calendar-week .fc-col-header-cell-cushion{\n  color: black;\n}\n#sgu-calendar-week .fc-timegrid-slot-label-cushion{\n  font-weight: bolder;\n}\n#sgu-calendar-week th{\n  border: 1px solid #656564 !important;\n  border-bottom: 0px !important;  \n}\n\n#sgu-calendar-week td {\n  border: 1px solid #656564 !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
