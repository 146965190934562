import {
  AvaBoy,
  AvaGirl,
} from "../../../../../_layouts/components/images/Avatars";
import { KTIcon } from "../../../../../_metronic/helpers";
import {
  EnrollmentStdType,
  StudentIDFormat,
} from "../../../../../_layouts/_helpers/Global";
import { BoxDashed } from "../../find-student/components/CardHeaderStd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { OverlayTrigger } from "react-bootstrap";
import { renderTooltip } from "../../../../../_layouts/components/popovers/PopOvers";

export default function HeaderStd({
  stdStatus,
  stdBio,
  is_transfer,
  setIs_transfer,
}) {
  return (
    <div className="pt-9 px-8">
      <div className="d-flex flex-wrap flex-sm-nowrap">
        <div className="me-7 mb-4">
          <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative">
            {stdBio.data.gender === "p" ? (
              <AvaBoy className="bg-light-primary" />
            ) : (
              <AvaGirl className="bg-light-primary" />
            )}
            <div className="position-absolute translate-middle bottom-0 start-100 mb-6 bg-success rounded-circle border border-4 border-body h-20px w-20px"></div>
          </div>
        </div>

        <div className="flex-grow-1">
          <div className="d-flex justify-content-between align-items-start flex-wrap mb-2 flex-wrap mb-2">
            <BoxTitle bio={stdBio.data} status={stdStatus.data} />
            <BoxEnd
              data={stdStatus.data}
              is_transfer={is_transfer}
              setIs_transfer={setIs_transfer}
            />
          </div>
          <div className="d-flex flex-column flex-grow-1 pe-8">
            <div className="d-flex flex-wrap">
              <BoxDashed
                title={stdStatus.data.program && stdStatus.data.program.name}
                value={stdStatus.data.clu && stdStatus.data.clu.name}
                selector={"me-3"}
              />
              {stdStatus.data.clu_spec && (
                <BoxDashed
                  title={"Specialization"}
                  value={stdStatus.data.clu_spec.name || "-"}
                  selector={"me-3"}
                />
              )}
              <BoxDashed
                title={"Intake"}
                value={
                  stdStatus.data.term_intake
                    ? stdStatus.data.term_intake.name || "-"
                    : ""
                }
                selector={"me-3"}
              />
              {stdStatus.data.corporate &&
                stdStatus.data.corporate.name !== "SGU" && (
                  <BoxDashed
                    title={"Corporate"}
                    value={stdStatus.data.corporate.name || "-"}
                    selector={"me-3"}
                  />
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const BoxTitle = ({ bio, status }) => {
  return (
    <div>
      <div className="d-flex align-items-center mb-2">
        <Link to={"/students/search/" + status.studentid} className="text-gray-900 text-hover-primary fs-1 fw-bold me-1">
          {bio.fullname}
        </Link>
        <KTIcon iconName="verify" className="fs-1 text-primary" />
      </div>
      <div className="d-flex flex-wrap fw-semibold fs-6 mb-4 pe-2">
        <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
          <KTIcon iconName="profile-circle" className="fs-4 me-1" />
          {StudentIDFormat(status.studentid)}
        </span>
        <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
          <KTIcon iconName="sms" className="fs-4 me-1" />
          {bio.sgumail}
        </span>
        <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
          <KTIcon iconName="whatsapp" className="fs-4 me-1" />
          {bio.cellular}
        </span>
        <span className="d-flex align-items-center text-gray-500 text-hover-primary me-5 mb-2">
          <KTIcon iconName="teacher" className="fs-4 me-1" />
          Semester {status.semester}
        </span>
      </div>
    </div>
  );
};

const BoxEnd = ({ data, is_transfer, setIs_transfer }) => {
  let enrollType = "";
  if (data.enrollment_type_id) {
    enrollType = EnrollmentStdType(data.enrollment_type_id);
  }
  const [isenabled, setIsenabled] = useState(is_transfer === 1 ? true : false);
  const handlerTick = (e) => {
    setIsenabled(e.target.checked);
    setIs_transfer(e.target.checked ? 1 : 0);
  };
  return (
    <div className="bg-light p-5 rounded">
      <OverlayTrigger
        delay={{ show: 250, hide: 400 }}
        placement="left"
        overlay={renderTooltip({ title: (isenabled ? "Cannot switch to reguler":"Switch to transfer") })}
      >
        <div className="form-check form-switch">
          <input
            type="checkbox"
            name="is_transfer"
            className="form-check-input"
            disabled={enrollType.includes("Transfer") ? true : false}
            defaultChecked={isenabled}
            defaultValue={is_transfer}
            onChange={(e) => handlerTick(e)}
          />
          <span className="text-dark fw-bold text-capitalize">
            Student {isenabled ? "Transfer" : enrollType}
          </span>
        </div>
      </OverlayTrigger>
    </div>
  );
};
