import React, { useEffect, useState } from "react";

import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import {
  ButtonIcon,
  ButtonPrimary,
  ButtonTersier,
} from "../../../../_layouts/components/buttons/ButtonUI";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";

import {
  LoadingSkeleton,
  SpinnerLoad,
} from "../../../../_layouts/components/loadbars/LoadingBars";
import {
  CountryCitizenshipList,
  DepartmentEnrollment,
  GenderList,
  MonthIntakeList,
  ProdiList,
  ProvinceList,
  ReligionList,
  SchoolCurrList,
  UnivList,
  YearIntakeList,
} from "../components/Dropdown";
import { KTIcon } from "../../../../_metronic/helpers";
import {
  RemoveEmptyValue,
  StudentIDFormat,
} from "../../../../_layouts/_helpers/Global";
import {
  GET_ENROLLMENT_PROCESS,
  GET_SCHOOL_CURR,
  UPDATE_CANDIDATE,
  UPDATE_ENROLLMENT,
} from "../../apis/node-target-data/MarketingAPI";
import { SchoolLookUp } from "../../components/forms/SelectBox";
import { ValidatePhoneNumber } from "../components/Helper";
import useToaster from "../components/use-toaster";

const DetailEnrollments = () => {
  const context = useOutletContext();
  const hak_akses_update_boiodata = context.widgets.find(
    (item) => item.widget_id === 61 && item.is_enabled === 1
  );
  const [update_biodata, setUpdateBiodata] = useState(undefined);
  const { ToasterComponent, triggerNotification } = useToaster();

  const { register_id } = useParams();

  const navigation = useNavigate();

  const bachelorWithPrevEdu = ["external", "learingprogram"]; // leraningprogram, transfer

  const [idUpdateBio, setIdUpdateBio] = useState("");

  const [isreadonly, setIsreadonly] = useState({
    biodata: true,
    candidate: true,
    education: true,
    cp: true,
    identity: true,
    parent: true,
    schoolCurriculum: true,
  });

  const [biodataLoading, setBiodataLoading] = useState({
    loading: false,
    error: "",
  });
  const [candidateLoading, setCandidateLoading] = useState({
    loading: false,
    error: "",
  });
  const [educationLoading, setEducationLoading] = useState({
    loading: false,
    error: "",
  });
  const [cpLoading, setCpLoading] = useState({
    loading: false,
    error: "",
  });
  const [identityLoading, setIdentityLoading] = useState({
    loading: false,
    error: "",
  });
  const [parentLoading, setParentLoading] = useState({
    loading: false,
    error: "",
  });
  const [schoolCurLoading, setSchoolCurLoading] = useState({
    loading: false,
    error: "",
  });

  const [results, setResults] = useState({
    loading: true,
    message: "",
    data: [],
  });

  const [biodata, setBiodata] = useState({
    firstname: "",
    middlename: "",
    lastname: "",
    birthcountry: "",
    birthplace: "",
    birthdate: "",
    citizenship: "",
    citizenship_name: "",
    religion: "",
    gender: "",
  });
  const [candidateData, setCandidateData] = useState({
    candidate_id: "",
    target_department_id: "",
    month_intake: "",
    yearintake: "",
  });
  const [contactPerson, setContactPerson] = useState({
    mobile: "",
    whatsapp: "",
    email: "",
  });
  const [identityAddress, setIdentityAddress] = useState({
    idtype_ktp: "",
    idtype_passport: "",
    postalcode: "",
    city: "",
    country: "",
    address: "",
  });
  const [education, setEducation] = useState({
    school: "",
    school_id: "",
    school_name: "",
    school_year_graduate: "",

    university: "",
    university_id: "",
    university_name: "",
    university_prodi: "",
    university_prodi_id: "",
    university_prodi_name: "",
    university_year_graduate: "",
    university_gpa: "",
    university_last_semester: "",

    company_name: "",
    company_email: "",
    company_position: "",
    company_city: "",
    company_postalcode: "",
    company_address: "",
  });
  const [schoolCurriculum, setSchoolCurriculum] = useState({
    school_curr_id: null,
    name: "",
  });
  const [questionnaire, setQuestionnaire] = useState("");
  const [parent, setParent] = useState({
    parent_type: "",
    parent_name: "",
    parent_email: "",
    // parent_profession: "",
    parent_phone: "",
    parent_addr: "",
    parent_province_name: "",
    parent_country: "",
    parent_country_name: "",
  });

  const HandlerChange = (x) => {
    console.log("handle change ", x);
    const { e, state } = x;

    if (state === "biodata") {
      setBiodata((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    } else if (state === "candidate") {
      setCandidateData((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    } else if (state === "cp") {
      // if (e.target.name === "whatsapp" || e.target.name === "mobile") {
      // if (!ValidatePhoneNumber(e.target.value)) {
      //   return;
      // }
      // }
      setContactPerson((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    } else if (state === "identity") {
      setIdentityAddress((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    } else if (state === "education") {
      setEducation((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    } else if (state === "parent") {
      setParent((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    } else if (state === "parent") {
      setSchoolCurriculum((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
    }
  };

  const getCandidate = async () => {
    setResults({ loading: true, message: "", data: [] });

    try {
      const param = {
        candidate_id: JSON.parse(atob(register_id)),
      };

      const result = await GET_ENROLLMENT_PROCESS(param);
      const x = result.data[0];
      console.log("result ", result);
      setResults(result);
      if (result.message === "") {
        if (x !== undefined) {
          // jika sudah jadi student tidak bisa update data
          if (x.create_student === 1) {
            setUpdateBiodata(undefined);
          } else {
            setUpdateBiodata(hak_akses_update_boiodata);
          }

          setIdUpdateBio(x.regstudent.id);
          setBiodata({
            firstname: x.regstudent.firstname ?? "",
            middlename: x.regstudent.middlename ?? "",
            lastname: x.regstudent.lastname ?? "",
            birthcountry: x.regstudent.birthcountry ?? "",
            birthplace: x.regstudent.birthplace ?? "",
            birthdate: x.regstudent.birthdate ?? "",
            citizenship: x.regstudent.citizenship ?? "",
            citizenship_name: x.regstudent.citizenship_obj.citizenship ?? "",
            religion: x.regstudent.religion ?? "",
            gender: x.regstudent.gender ?? "",
          });
          setCandidateData({
            id: x.id,
            candidate_id: x.candidate_id ?? "",
            target_department_id: x.target_department.id ?? "",
            month_intake: x.month_intake ?? "",
            yearintake: x.yearintake ?? "",
          });
          setContactPerson({
            mobile: x.regstudent.mobile ?? "",
            whatsapp: x.regstudent.whatsapp ?? "",
            email: x.regstudent.email ?? "",
          });
          setIdentityAddress({
            idtype_ktp: x.regstudent.idtype_ktp ?? "",
            idtype_passport: x.regstudent.idtype_passport ?? "",
            postalcode: x.regstudent.postalcode ?? "",
            city: x.regstudent.city ?? "",
            country_id: x.regstudent.country_id ?? "",
            country_name: x.regstudent.country_obj?.name ?? "",
            address:
              x.regstudent.address === null
                ? ""
                : decodeURIComponent(x.regstudent.address.replace(/%$/, "")),
          });
          setEducation({
            enrollment_type_id: x.enrollment_type_id,
            school: x.regstudent.school,
            school_id: x.regstudent.school_id,
            school_name: x.regstudent.school_obj?.name,
            school_year_graduate: x.regstudent.school_year_graduate ?? "",

            isMaster: x.target_department.program_id ?? 0,
            university: x.regstudent.university ?? "",
            university_id: x.regstudent.university_id,
            university_name: x.regstudent.university_obj?.name
              ? x.regstudent.university_obj?.name
              : x.regstudent.university ?? "",
            university_prodi: x.regstudent.university_prodi ?? "",
            university_prodi_id: x.regstudent.university_prodi_id ?? "",
            university_prodi_name:
              x.regstudent.university_prodi_obj?.name ?? "",
            university_year_graduate:
              x.regstudent.university_year_graduate ?? "",
            university_gpa: x.regstudent.university_gpa ?? "",
            university_last_semester:
              x.regstudent.university_last_semester ?? "",

            company_name: x.regstudent.company_name ?? "",
            company_email: x.regstudent.company_email ?? "",
            company_position: x.regstudent.company_position ?? "",
            company_city: x.regstudent.company_city ?? "",
            company_postalcode: x.regstudent.company_postalcode ?? "",
            company_address:
              x.regstudent.company_address === null
                ? ""
                : decodeURIComponent(
                    x.regstudent.company_address.replace(/%$/, "")
                  ),
          });
          setQuestionnaire(x.regstudent.questionnaire ?? "");
          setParent({
            parent_type: x.regstudent.fm_type ?? "",
            parent_name: x.regstudent.parent_name ?? "",
            parent_email: x.regstudent.parent_email ?? "",
            // parent_profession: x.regstudent.parent_profession ?? "",
            parent_phone: x.regstudent.parent_phone ?? "",
            parent_country: x.regstudent.parent_country_id ?? "",
            parent_country_name:
              x.regstudent.parent_country_id !== null
                ? x.regstudent.parent_country_obj?.name
                : "",
            parent_province: x.regstudent.parent_province ?? "",
            parent_province_name:
              x.regstudent.parent_province !== null
                ? x.regstudent.parent_province_obj?.name
                : "",
            parent_addr:
              x.regstudent.parent_addr === null
                ? ""
                : decodeURIComponent(
                    x.regstudent.parent_addr.replace(/%$/, "")
                  ),
          });
        }
      }

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } catch (error) {
      setResults({ loading: false, message: error.message, data: [] });
    }
  };

  const HandleUpdateCandidate = async (e) => {
    if (e === "biodata") {
      if (isreadonly.biodata === true) {
        // active editing
        setIsreadonly((prev) => ({
          ...prev,
          biodata: !prev.biodata,
        }));
      } else {
        // save editing & upload data
        setBiodataLoading((prev) => ({
          ...prev,
          loading: !prev.loading,
          error: "",
        }));

        const sent = await UPDATE_CANDIDATE({
          id: idUpdateBio,
          ...RemoveEmptyValue(biodata),
        });

        if (sent.message === "") {
          setTimeout(() => {
            setBiodataLoading((prev) => ({
              ...prev,
              loading: !prev.loading,
              error: "",
            }));
            setIsreadonly((prev) => ({
              ...prev,
              biodata: !prev.biodata,
            }));
            // after success uploading, re-fetching
            triggerNotification({
              title: "Success",
              type: "success",
              message: "Success update data",
            });
            getCandidate();
          }, 1000);
        } else {
          setBiodataLoading((prev) => ({
            ...prev,
            loading: !prev.loading,
            error: sent.message,
          }));
        }
      }
    } else if (e === "candidate") {
      if (isreadonly.candidate === true) {
        // active editing
        setIsreadonly((prev) => ({
          ...prev,
          candidate: !prev.candidate,
        }));
      } else {
        // save editing & upload data
        setCandidateLoading((prev) => ({
          ...prev,
          loading: !prev.loading,
          error: "",
        }));

        const sent = await UPDATE_ENROLLMENT({
          id: candidateData.id, //enroll id
          target_department_id: candidateData.target_department_id,
          month_intake: candidateData.month_intake,
        });

        console.log(sent);

        if (sent.message === "") {
          setTimeout(() => {
            setCandidateLoading((prev) => ({
              ...prev,
              loading: !prev.loading,
              error: "",
            }));
            setIsreadonly((prev) => ({
              ...prev,
              candidate: !prev.candidate,
            }));
            // after success uploading, re-fetching
            triggerNotification({
              title: "Success",
              type: "success",
              message: "Success update data",
            });
            getCandidate();
          }, 1000);
        } else {
          setCandidateLoading((prev) => ({
            ...prev,
            loading: !prev.loading,
            error: sent.message,
          }));
        }
      }
    } else if (e === "education") {
      if (isreadonly.education === true) {
        // active editing
        setIsreadonly((prev) => ({
          ...prev,
          education: !prev.education,
        }));
      } else {
        // save editing & upload data
        setEducationLoading((prev) => ({
          ...prev,
          loading: !prev.loading,
          error: "",
        }));

        const sent = await UPDATE_CANDIDATE({
          id: idUpdateBio,
          ...RemoveEmptyValue(education),
        });
        console.log("sent update ", sent);

        if (sent.message === "") {
          setTimeout(() => {
            setEducationLoading((prev) => ({
              ...prev,
              loading: !prev.loading,
              error: "",
            }));
            setIsreadonly((prev) => ({
              ...prev,
              education: !prev.education,
            }));
            // after success uploading, re-fetching
            triggerNotification({
              title: "Success",
              type: "success",
              message: "Success update data",
            });
            getCandidate();
          }, 1000);
        } else {
          setEducationLoading((prev) => ({
            ...prev,
            loading: !prev.loading,
            error: sent.message,
          }));
        }
      }
    } else if (e === "cp") {
      if (isreadonly.cp === true) {
        // active editing
        setIsreadonly((prev) => ({
          ...prev,
          cp: !prev.cp,
        }));
      } else {
        // save editing & upload data
        setCpLoading((prev) => ({
          ...prev,
          loading: !prev.loading,
          error: "",
        }));

        const sent = await UPDATE_CANDIDATE({
          id: idUpdateBio,
          ...RemoveEmptyValue(contactPerson),
        });
        console.log("sent update ", sent);

        if (sent.message === "") {
          setTimeout(() => {
            setCpLoading((prev) => ({
              ...prev,
              loading: !prev.loading,
              error: "",
            }));
            setIsreadonly((prev) => ({
              ...prev,
              cp: !prev.cp,
            }));
            // after success uploading, re-fetching
            triggerNotification({
              title: "Success",
              type: "success",
              message: "Success update data",
            });
            getCandidate();
          }, 1000);
        } else {
          setCpLoading((prev) => ({
            ...prev,
            loading: !prev.loading,
            error: sent.message,
          }));
        }
      }
    } else if (e === "identity") {
      if (isreadonly.identity === true) {
        // active editing
        setIsreadonly((prev) => ({
          ...prev,
          identity: !prev.identity,
        }));
      } else {
        // save editing & upload data
        setIdentityLoading((prev) => ({
          ...prev,
          loading: !prev.loading,
          error: "",
        }));

        const sent = await UPDATE_CANDIDATE({
          id: idUpdateBio,
          ...RemoveEmptyValue(identityAddress),
        });
        console.log("sent update ", sent);

        if (sent.message === "") {
          setTimeout(() => {
            setIdentityLoading((prev) => ({
              ...prev,
              loading: !prev.loading,
              error: "",
            }));
            setIsreadonly((prev) => ({
              ...prev,
              identity: !prev.identity,
            }));
            // after success uploading, re-fetching
            triggerNotification({
              title: "Success",
              type: "success",
              message: "Success update data",
            });
            getCandidate();
          }, 1000);
        } else {
          setIdentityLoading((prev) => ({
            ...prev,
            loading: !prev.loading,
            error: sent.message,
          }));
        }
      }
    } else if (e === "parent") {
      if (isreadonly.parent === true) {
        // active editing
        setIsreadonly((prev) => ({
          ...prev,
          parent: !prev.parent,
        }));
      } else {
        // save editing & upload data
        setParentLoading((prev) => ({
          ...prev,
          loading: !prev.loading,
          error: "",
        }));

        const sent = await UPDATE_CANDIDATE({
          id: idUpdateBio,
          ...RemoveEmptyValue(parent),
        });

        if (sent.message === "") {
          setTimeout(() => {
            setParentLoading((prev) => ({
              ...prev,
              loading: !prev.loading,
              error: "",
            }));
            setIsreadonly((prev) => ({
              ...prev,
              parent: !prev.parent,
            }));
            // after success uploading, re-fetching
            triggerNotification({
              title: "Success",
              type: "success",
              message: "Success update data",
            });
            getCandidate();
          }, 1000);
        } else {
          setParentLoading((prev) => ({
            ...prev,
            loading: !prev.loading,
            error: sent.message,
          }));
        }
      }
    } else if (e === "curriculum") {
      if (isreadonly.schoolCurriculum === true) {
        // active editing
        setIsreadonly((prev) => ({
          ...prev,
          schoolCurriculum: !prev.schoolCurriculum,
        }));
      } else {
        // save editing & upload data
        setSchoolCurLoading((prev) => ({
          ...prev,
          loading: !prev.loading,
          error: "",
        }));

        const sent = await UPDATE_CANDIDATE({
          id: idUpdateBio,
          ...RemoveEmptyValue(schoolCurriculum),
        });

        if (sent.message === "") {
          setTimeout(() => {
            setSchoolCurLoading((prev) => ({
              ...prev,
              loading: !prev.loading,
              error: "",
            }));
            setIsreadonly((prev) => ({
              ...prev,
              schoolCurriculum: !prev.schoolCurriculum,
            }));
            // after success uploading, re-fetching
            triggerNotification({
              title: "Success",
              type: "success",
              message: "Success update data",
            });
            getCandidate();
          }, 1000);
        } else {
          setSchoolCurLoading((prev) => ({
            ...prev,
            loading: !prev.loading,
            error: sent.message,
          }));
        }
      }
    }
  };

  // const getSchoolCurr = async () => {
  //   try {
  //     const get = await GET_SCHOOL_CURR();
  //     console.log("get -> ", get);
  //     if (get.message === "") {
  //       setSchoolCurriculum({
  //         id: get.data.id,
  //         name: get.data.name,
  //       });
  //     }
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  useEffect(() => {
    sessionStorage.setItem("lastVisit", "true");
    getCandidate();
    // getSchoolCurr();
  }, []);

  return (
    <>
      {ToasterComponent}
      {results.loading ? (
        <LoadingSkeleton />
      ) : results.message ? (
        <AlertNotif messages={results.message} />
      ) : (
        <>
          <div className="mb-5 ms-3 d-flex justify-content-between">
            <ButtonIcon
              type="button"
              className="btn-sm btn-clear text-hover-primary text-muted p-1"
              onClick={() => navigation(-1)}
              title="Back"
            >
              <KTIcon iconName="arrow-left" className="fs-1" />
              <span className="fs-3">Back</span>
            </ButtonIcon>
            {/* {update_biodata ? (
              <ButtonTersier
                type="button"
                title="Goto Billing"
                className="d-flex align-items-center btn-outline"
                onClick={() =>
                  navigation("/admissions/billings", {
                    state: { candidate_id: candidateData.candidate_id },
                  })
                }
              >
                <KTIcon iconName="bill" className="fs-3 me-2" />
                <span>Billing</span>
              </ButtonTersier>
            ) : (
              <></>
            )} */}
          </div>
          <div className="w-100 d-flex gap-7 flex-wrap">
            <div
              style={{ flex: "1 1 300px", minWidth: 300 }}
              className="flex-grow"
            >
              {/* biodata */}
              <div className="h-auto w-100">
                <SGUCard>
                  <SGUCard.Header>
                    <span className="card-label fw-bold fs-3 mb-1">
                      Biodata
                    </span>
                  </SGUCard.Header>
                  {update_biodata ? (
                    <SGUCard.Toolbar>
                      <div className="d-flex">
                        {!isreadonly.biodata && (
                          <ButtonTersier
                            type="button"
                            className="btn-sm btn-clear me-2"
                            title="Cancel Edit"
                            onClick={() => {
                              setIsreadonly((prev) => ({
                                ...prev,
                                biodata: !prev.biodata,
                              }));
                              getCandidate();
                            }}
                          >
                            Cancel
                          </ButtonTersier>
                        )}

                        <ButtonPrimary
                          type="button"
                          className="btn-sm btn-clear"
                          title={isreadonly.biodata ? "Edit Data" : "Save Data"}
                          onClick={() => HandleUpdateCandidate("biodata")}
                        >
                          {biodataLoading.loading ? (
                            <SpinnerLoad />
                          ) : isreadonly.biodata ? (
                            "Edit"
                          ) : (
                            "Save"
                          )}
                        </ButtonPrimary>
                      </div>
                    </SGUCard.Toolbar>
                  ) : (
                    <></>
                  )}
                  <SGUCard.Body className={"px-0"}>
                    {biodataLoading.error && (
                      <div className="px-9 mt-5">
                        <AlertNotif
                          title="Failed update data"
                          messages={
                            biodataLoading.error + " " + `(try re-Save)`
                          }
                        />
                      </div>
                    )}
                    <div className="px-9 mt-5">
                      <div className="form-group mb-5">
                        <label className="fw-bold">
                          {isreadonly.biodata ? "Name" : "First Name"}
                        </label>
                        <input
                          type="text"
                          name="firstname"
                          className="form-control form-control-sm"
                          disabled={isreadonly.biodata}
                          value={
                            isreadonly.biodata
                              ? `${biodata.firstname} ${biodata.middlename} ${biodata.lastname}`
                              : biodata.firstname
                          }
                          onChange={(e) =>
                            HandlerChange({ e, state: "biodata" })
                          }
                        />
                      </div>
                      {!isreadonly.biodata && (
                        <div className="form-group mb-5">
                          <label className="fw-bold">Middle Name</label>
                          <input
                            type="text"
                            name="middlename"
                            className="form-control form-control-sm"
                            disabled={isreadonly.biodata}
                            value={biodata.middlename}
                            onChange={(e) =>
                              HandlerChange({ e, state: "biodata" })
                            }
                          />
                        </div>
                      )}
                      {!isreadonly.biodata && (
                        <div className="form-group mb-5">
                          <label className="fw-bold">Last Name</label>
                          <input
                            type="text"
                            name="lastname"
                            className="form-control form-control-sm"
                            disabled={isreadonly.biodata}
                            value={biodata.lastname}
                            onChange={(e) =>
                              HandlerChange({ e, state: "biodata" })
                            }
                          />
                        </div>
                      )}
                      {!isreadonly.biodata && (
                        <div className="form-group mb-5">
                          <CountryCitizenshipList
                            data={biodata.birthcountry}
                            setData={(e) =>
                              HandlerChange({
                                e: {
                                  target: { name: "birthcountry", value: e },
                                },
                                state: "biodata",
                              })
                            }
                            type="label"
                            title="Birth Country"
                            disabled={isreadonly.biodata}
                          />
                        </div>
                      )}
                      <div className="form-group mb-5">
                        <label className="fw-bold">Birth Place</label>
                        <input
                          type="text"
                          name="birthplace"
                          className="form-control form-control-sm"
                          disabled={isreadonly.biodata}
                          value={
                            !isreadonly.biodata
                              ? biodata.birthplace
                              : `${biodata.birthplace}, ${biodata.birthcountry}`
                          }
                          onChange={(e) =>
                            HandlerChange({ e, state: "biodata" })
                          }
                        />
                      </div>
                      <div className="form-group mb-5">
                        <label className="fw-bold">Birth Date</label>
                        <input
                          type="date"
                          name="birthdate"
                          className="form-control form-control-sm"
                          disabled={isreadonly.biodata}
                          value={biodata.birthdate}
                          onChange={(e) => {
                            console.log("--->", e.target.value);
                            HandlerChange({ e, state: "biodata" });
                          }}
                          max={new Date().toISOString().split("T")[0]} // membatasi maksimal hari ini
                          min="1945-01-01"
                        />
                      </div>
                      <div className="form-group mb-5">
                        <label className="fw-bold">Gender</label>
                        <GenderList
                          data={biodata.gender.toLowerCase()}
                          setData={HandlerChange}
                          disabled={isreadonly.biodata}
                        />
                      </div>
                      <div className="form-group mb-5">
                        <label className="fw-bold">Religion</label>
                        <ReligionList
                          data={biodata.religion}
                          setData={HandlerChange}
                          disabled={isreadonly.biodata}
                        />
                      </div>
                      <div className="form-group mb-5">
                        <CountryCitizenshipList
                          data={biodata.citizenship_name}
                          setData={(e) =>
                            HandlerChange({
                              e: { target: { name: "citizenship", value: e } },
                              state: "biodata",
                            })
                          }
                          title="Citizenship"
                          disabled={isreadonly.biodata}
                        />
                      </div>
                    </div>
                  </SGUCard.Body>
                </SGUCard>
              </div>
              {/* identity */}
              <div className="h-auto w-100">
                <SGUCard>
                  <SGUCard.Header>
                    <span className="card-label fw-bold fs-3 mb-1">
                      Identity and Address
                    </span>
                  </SGUCard.Header>
                  {update_biodata ? (
                    <SGUCard.Toolbar>
                      <div className="d-flex">
                        {!isreadonly.identity && (
                          <ButtonTersier
                            type="button"
                            className="btn-sm btn-clear me-2"
                            title="Cancel Edit"
                            onClick={() => {
                              setIsreadonly((prev) => ({
                                ...prev,
                                identity: !prev.identity,
                              }));
                              getCandidate();
                            }}
                          >
                            Cancel
                          </ButtonTersier>
                        )}

                        <ButtonPrimary
                          type="button"
                          className="btn-sm btn-clear"
                          title={
                            isreadonly.identity ? "Edit Data" : "Save Data"
                          }
                          onClick={() => HandleUpdateCandidate("identity")}
                        >
                          {identityLoading.loading ? (
                            <SpinnerLoad />
                          ) : isreadonly.identity ? (
                            "Edit"
                          ) : (
                            "Save"
                          )}
                        </ButtonPrimary>
                      </div>
                    </SGUCard.Toolbar>
                  ) : (
                    <></>
                  )}
                  <SGUCard.Body className={"px-0"}>
                    {identityLoading.error && (
                      <div className="px-9 mt-5">
                        <AlertNotif
                          title="Failed update data"
                          messages={
                            identityLoading.error + " " + `(try re-Save)`
                          }
                        />
                      </div>
                    )}
                    <div className="px-9 mt-5">
                      <div className="form-group mb-5">
                        <label className="fw-bold">KTP (Indonesian)</label>
                        <input
                          type="text"
                          name="idtype_ktp"
                          className="form-control form-control-sm"
                          disabled={isreadonly.identity}
                          value={identityAddress.idtype_ktp}
                          onChange={(e) =>
                            HandlerChange({ e, state: "identity" })
                          }
                        />
                      </div>
                      <div className="form-group mb-5">
                        <label className="fw-bold">Passport</label>
                        <input
                          type="text"
                          name="idtype_passport"
                          className="form-control form-control-sm"
                          disabled={isreadonly.identity}
                          value={identityAddress.idtype_passport}
                          onChange={(e) =>
                            HandlerChange({ e, state: "identity" })
                          }
                        />
                      </div>
                      <div className="form-group mb-5">
                        <label className="fw-bold">Postalcode</label>
                        <input
                          type="text"
                          name="postalcode"
                          className="form-control form-control-sm"
                          disabled={isreadonly.identity}
                          value={identityAddress.postalcode}
                          onChange={(e) =>
                            HandlerChange({ e, state: "identity" })
                          }
                        />
                      </div>
                      <div className="form-group mb-5">
                        <label className="fw-bold">City</label>
                        <input
                          type="text"
                          name="city"
                          className="form-control form-control-sm"
                          disabled={isreadonly.identity}
                          value={identityAddress.city}
                          onChange={(e) =>
                            HandlerChange({ e, state: "identity" })
                          }
                        />
                      </div>
                      <div className="form-group mb-5">
                        <CountryCitizenshipList
                          data={identityAddress.country_name}
                          setData={(e) =>
                            HandlerChange({
                              e: { target: { name: "country_id", value: e } },
                              state: "identity",
                            })
                          }
                          title="Country"
                          disabled={isreadonly.identity}
                        />
                      </div>
                      <div className="form-group mb-5">
                        <label className="fw-bold">Address</label>
                        {/* {isreadonly.identity ? ( */}
                        <textarea
                          type="text"
                          name="address"
                          className="form-control form-control-sm"
                          disabled={isreadonly.identity}
                          value={identityAddress.address}
                          onChange={(e) =>
                            HandlerChange({ e, state: "identity" })
                          }
                        />
                        {/* ) : (
                          <input
                            type="text"
                            name="address"
                            className="form-control form-control-sm"
                            disabled={isreadonly.identity}
                            value={identityAddress.address}
                            onChange={(e) =>
                              HandlerChange({ e, state: "identity" })
                            }
                          />
                        )} */}
                      </div>
                    </div>
                  </SGUCard.Body>
                </SGUCard>
              </div>
            </div>
            <div
              style={{ flex: "1 1 300px", minWidth: 300 }}
              className="flex-grow"
            >
              {/* candidate data */}
              <div className="h-auto w-100">
                <SGUCard>
                  <SGUCard.Header>
                    <span className="card-label fw-bold fs-3 mb-1">
                      Candidate Data
                    </span>
                  </SGUCard.Header>
                  {update_biodata ? (
                    <SGUCard.Toolbar>
                      <div className="d-flex">
                        {!isreadonly.candidate && (
                          <ButtonTersier
                            type="button"
                            className="btn-sm btn-clear me-2"
                            title="Cancel Edit"
                            onClick={() => {
                              setIsreadonly((prev) => ({
                                ...prev,
                                candidate: !prev.candidate,
                              }));
                              getCandidate();
                            }}
                          >
                            Cancel
                          </ButtonTersier>
                        )}

                        <ButtonPrimary
                          type="button"
                          className="btn-sm btn-clear"
                          title="Edit Data"
                          onClick={() => HandleUpdateCandidate("candidate")}
                        >
                          {candidateLoading.loading ? (
                            <SpinnerLoad />
                          ) : isreadonly.candidate ? (
                            "Edit"
                          ) : (
                            "Save"
                          )}
                        </ButtonPrimary>
                      </div>
                    </SGUCard.Toolbar>
                  ) : (
                    <></>
                  )}
                  <SGUCard.Body className={"px-0"}>
                    {candidateLoading.error && (
                      <div className="px-9 mt-5">
                        <AlertNotif
                          title="Failed update data"
                          messages={
                            candidateLoading.error + " " + `(try re-Save)`
                          }
                        />
                      </div>
                    )}
                    <div className="px-9 mt-5">
                      <div className="form-group mb-5">
                        <label className="fw-bold">Candidate ID</label>
                        <input
                          type="text"
                          name="candidate_id"
                          className="form-control form-control-sm"
                          disabled
                          value={StudentIDFormat(candidateData.candidate_id)}
                        />
                      </div>
                      <div className="form-group mb-5">
                        <DepartmentEnrollment
                          data={candidateData.target_department_id}
                          setData={(e) =>
                            HandlerChange({
                              e: {
                                target: {
                                  name: "target_department_id",
                                  value: e,
                                },
                              },
                              state: "candidate",
                            })
                          }
                          title="SGU Study Program"
                          disabled={isreadonly.candidate}
                        />
                      </div>
                      <div className="form-group mb-5">
                        <MonthIntakeList
                          data={candidateData.month_intake}
                          setData={(e) =>
                            HandlerChange({
                              e: {
                                target: {
                                  name: "month_intake",
                                  value: e,
                                },
                              },
                              state: "candidate",
                            })
                          }
                          title="Month Intake"
                          disabled={isreadonly.candidate}
                        />
                      </div>
                      <div className="form-group mb-5">
                        <YearIntakeList
                          data={candidateData.yearintake}
                          setData={(e) =>
                            HandlerChange({
                              e: { target: { name: "yearintake", value: e } },
                              state: "candidate",
                            })
                          }
                          disabled
                        />
                      </div>
                    </div>
                  </SGUCard.Body>
                </SGUCard>
              </div>
              {/* education */}
              <div className="h-auto w-100">
                <SGUCard>
                  <SGUCard.Header>
                    <span className="card-label fw-bold fs-3 mb-1">
                      Prior Education
                    </span>
                  </SGUCard.Header>
                  {update_biodata ? (
                    <SGUCard.Toolbar>
                      <div className="d-flex">
                        {!isreadonly.education && (
                          <ButtonTersier
                            type="button"
                            className="btn-sm btn-clear me-2"
                            title="Cancel Edit"
                            onClick={() => {
                              setIsreadonly((prev) => ({
                                ...prev,
                                education: !prev.education,
                              }));
                              getCandidate();
                            }}
                          >
                            Cancel
                          </ButtonTersier>
                        )}

                        <ButtonPrimary
                          type="button"
                          className="btn-sm btn-clear"
                          title={
                            isreadonly.education ? "Edit Data" : "Save Data"
                          }
                          onClick={() => HandleUpdateCandidate("education")}
                        >
                          {educationLoading.loading ? (
                            <SpinnerLoad />
                          ) : isreadonly.education ? (
                            "Edit"
                          ) : (
                            "Save"
                          )}
                        </ButtonPrimary>
                      </div>
                    </SGUCard.Toolbar>
                  ) : (
                    <></>
                  )}
                  <SGUCard.Body className={"px-0"}>
                    {educationLoading.error && (
                      <div className="px-9 mt-5">
                        <AlertNotif
                          title="Failed update data"
                          messages={
                            educationLoading.error + " " + `(try re-Save)`
                          }
                        />
                      </div>
                    )}
                    <div className="px-9 mt-5">
                      {/* univ */}
                      {education.isMaster === 2 && (
                        <div className="form-group mb-5">
                          <UnivList
                            data={education.university_name}
                            setData={(e) =>
                              HandlerChange({
                                e: {
                                  target: {
                                    name: "university_id",
                                    value: e,
                                  },
                                },
                                state: "education",
                              })
                            }
                            title="University"
                            disabled={isreadonly.education}
                          />
                        </div>
                      )}
                      {education.isMaster === 2 &&
                        (education.university_id === null ||
                          education.university_id === 9999) && (
                          <div className="form-group mb-5">
                            <label className="fw-bold">
                              University Inputed
                            </label>
                            <input
                              type="text"
                              name="university"
                              className="form-control form-control-sm"
                              disabled
                              value={education.university}
                              onChange={(e) =>
                                HandlerChange({ e, state: "education" })
                              }
                            />
                          </div>
                        )}

                      {/* high school */}
                      {education.isMaster === 1 && (
                        <div className="form-group mb-5">
                          <SchoolLookUp
                            data={education.school_name}
                            setData={(e) =>
                              HandlerChange({
                                e: {
                                  target: {
                                    name: "school_id",
                                    value: e,
                                  },
                                },
                                state: "education",
                              })
                            }
                            title="School Name"
                            disabled={isreadonly.education}
                          />
                        </div>
                      )}

                      {education.isMaster === 1 &&
                        (education.school_id === null ||
                          education.school_id === 9999) && (
                          <div className="form-group mb-5">
                            <label className="fw-bold">
                              High School Inputed
                            </label>
                            <input
                              type="text"
                              name="school"
                              className="form-control form-control-sm"
                              disabled
                              value={decodeURIComponent(education.school)}
                              onChange={(e) =>
                                HandlerChange({ e, state: "education" })
                              }
                            />
                          </div>
                        )}

                      {/* major */}
                      {education.isMaster === 2 && (
                        <div className="form-group mb-5">
                          <label className="fw-bold">Major</label>
                          <input
                            type="text"
                            name="university_prodi"
                            className="form-control form-control-sm"
                            disabled={isreadonly.education}
                            value={education.university_prodi}
                            onChange={(e) =>
                              HandlerChange({ e, state: "education" })
                            }
                          />
                        </div>
                      )}

                      <div className="form-group mb-5">
                        <label className="fw-bold">Graduation Year</label>
                        <input
                          type="text"
                          name={
                            education.isMaster === 2
                              ? "university_year_graduate"
                              : "school_year_graduate"
                          }
                          className="form-control form-control-sm"
                          disabled={isreadonly.education}
                          value={
                            education.isMaster === 2
                              ? education.university_year_graduate
                              : education.school_year_graduate
                          }
                          onChange={(e) =>
                            HandlerChange({ e, state: "education" })
                          }
                        />
                      </div>

                      {education.isMaster === 2 && (
                        <div className="form-group mb-5">
                          <label className="fw-bold">GPA</label>
                          <input
                            type="text"
                            name="university_gpa"
                            className="form-control form-control-sm"
                            disabled={isreadonly.education}
                            value={education.university_gpa}
                            onChange={(e) =>
                              HandlerChange({ e, state: "education" })
                            }
                          />
                        </div>
                      )}

                      {/* transfer/learning program student */}
                      {education.isMaster === 1 &&
                        bachelorWithPrevEdu.some((edu) =>
                          education.enrollment_type_id.includes(edu)
                        ) && (
                          <div className="form-group mb-5">
                            <UnivList
                              data={education.university_name}
                              setData={(e) =>
                                HandlerChange({
                                  e: {
                                    target: {
                                      name: "university_id",
                                      value: e,
                                    },
                                  },
                                  state: "education",
                                })
                              }
                              title="Previous University/Institution Name"
                              disabled={isreadonly.education}
                            />
                          </div>
                        )}

                      {education.isMaster === 1 &&
                        bachelorWithPrevEdu.some((edu) =>
                          education.enrollment_type_id.includes(edu)
                        ) &&
                        (education.university_id === null ||
                          education.university_id === 9999) && (
                          <div className="form-group mb-5">
                            <label className="fw-bold">
                              Previous University Inputed
                            </label>
                            <input
                              type="text"
                              name="university"
                              className="form-control form-control-sm"
                              disabled={isreadonly.education}
                              value={education.university}
                              onChange={(e) =>
                                HandlerChange({ e, state: "education" })
                              }
                            />
                          </div>
                        )}

                      {/* previous prodi */}
                      {education.isMaster === 1 &&
                        education.university_prodi_id !== null &&
                        bachelorWithPrevEdu.some((edu) =>
                          education.enrollment_type_id.includes(edu)
                        ) && (
                          <div className="form-group mb-5">
                            <ProdiList
                              data={education.university_prodi_id}
                              univid={education.university_id}
                              setData={(e) =>
                                HandlerChange({
                                  e: {
                                    target: {
                                      name: "university_prodi_id",
                                      value: e,
                                    },
                                  },
                                  state: "education",
                                })
                              }
                              title="Major/Field of Study"
                              disabled={isreadonly.education}
                            />
                          </div>
                        )}

                      {/* previous prodi inputed */}
                      {education.isMaster === 1 &&
                        education.university_prodi_id !== null &&
                        education.university_prodi_id === 9999 &&
                        bachelorWithPrevEdu.some((edu) =>
                          education.enrollment_type_id.includes(edu)
                        ) && (
                          <div className="form-group mb-5">
                            <label className="fw-bold">
                              Major/Field of Study Inputed
                            </label>
                            <input
                              type="text"
                              name="university_prodi"
                              className="form-control form-control-sm"
                              disabled={isreadonly.education}
                              value={education.university_prodi}
                              onChange={(e) =>
                                HandlerChange({ e, state: "education" })
                              }
                            />
                          </div>
                          // <div className="form-group mb-5">
                          //   <ProdiList
                          //     data={education.university_prodi_id}
                          //     univid={education.university_id}
                          //     setData={(e) =>
                          //       HandlerChange({
                          //         e: {
                          //           target: {
                          //             name: "university_prodi_id",
                          //             value: e,
                          //           },
                          //         },
                          //         state: "education",
                          //       })
                          //     }
                          //     title="Major/Field of Study"
                          //     disabled={isreadonly.education}
                          //   />
                          // </div>
                        )}

                      {/* previous semester */}
                      {education.isMaster === 1 &&
                        education.university_prodi_id !== null &&
                        bachelorWithPrevEdu.some((edu) =>
                          education.enrollment_type_id.includes(edu)
                        ) && (
                          <div className="form-group mb-5">
                            <label className="fw-bold">
                              Number of Semester Passed
                            </label>
                            <input
                              type="text"
                              name={"university_last_semester"}
                              className="form-control form-control-sm"
                              disabled={isreadonly.education}
                              value={education.university_last_semester}
                              onChange={(e) =>
                                HandlerChange({ e, state: "education" })
                              }
                            />
                          </div>
                        )}
                    </div>

                    {/* job */}
                    {education.company_name !== "" && (
                      <>
                        <div className="px-9 mt-15 mb-5">
                          <h4>Job</h4>
                        </div>
                        <div className="px-9 mt-5">
                          <div className="form-group mb-5">
                            <label className="fw-bold">Company</label>
                            <input
                              type="text"
                              name="company_name"
                              className="form-control form-control-sm"
                              disabled={isreadonly.education}
                              value={education.company_name}
                              onChange={(e) =>
                                HandlerChange({ e, state: "education" })
                              }
                            />
                          </div>
                          <div className="form-group mb-5">
                            <label className="fw-bold">Position</label>
                            <input
                              type="text"
                              name="company_position"
                              className="form-control form-control-sm"
                              disabled={isreadonly.education}
                              value={education.company_position}
                              onChange={(e) =>
                                HandlerChange({ e, state: "education" })
                              }
                            />
                          </div>
                          <div className="form-group mb-5">
                            <label className="fw-bold">Email</label>
                            <input
                              type="text"
                              name="company_email"
                              className="form-control form-control-sm"
                              disabled={isreadonly.education}
                              value={education.company_email}
                              onChange={(e) =>
                                HandlerChange({ e, state: "education" })
                              }
                            />
                          </div>
                          <div className="form-group mb-5">
                            <label className="fw-bold">City</label>
                            <input
                              type="text"
                              name="company_city"
                              className="form-control form-control-sm"
                              disabled={isreadonly.education}
                              value={education.company_city}
                              onChange={(e) =>
                                HandlerChange({ e, state: "education" })
                              }
                            />
                          </div>
                          <div className="form-group mb-5">
                            <label className="fw-bold">Postalcode</label>
                            <input
                              type="text"
                              name="company_postalcode"
                              className="form-control form-control-sm"
                              disabled={isreadonly.education}
                              value={education.company_postalcode}
                              onChange={(e) =>
                                HandlerChange({ e, state: "education" })
                              }
                            />
                          </div>
                          <div className="form-group mb-5">
                            <label className="fw-bold">Address</label>
                            <textarea
                              type="text"
                              name="address"
                              className="form-control form-control-sm"
                              disabled={isreadonly.education}
                              value={education.company_address}
                              onChange={(e) =>
                                HandlerChange({ e, state: "education" })
                              }
                            />
                            {/* <input
                              type="text"
                              name="company_address"
                              className="form-control form-control-sm"
                              disabled={isreadonly.education}
                              value={education.company_address}
                              onChange={(e) =>
                                HandlerChange({ e, state: "education" })
                              }
                            /> */}
                          </div>
                        </div>
                      </>
                    )}
                  </SGUCard.Body>
                </SGUCard>
              </div>
              {/* School Curriculum */}
              <div className="h-auto w-100">
                <SGUCard>
                  <SGUCard.Header>
                    <span className="card-label fw-bold fs-3 mb-1">
                      School Curriculum
                    </span>
                  </SGUCard.Header>
                  {/* {update_biodata ? (
                    <SGUCard.Toolbar>
                      <div className="d-flex">
                        {!isreadonly.schoolCurriculum && (
                          <ButtonTersier
                            type="button"
                            className="btn-sm btn-clear me-2"
                            title="Cancel Edit"
                            onClick={() => {
                              setIsreadonly((prev) => ({
                                ...prev,
                                schoolCurriculum: !prev.schoolCurriculum,
                              }));
                              getCandidate();
                            }}
                          >
                            Cancel
                          </ButtonTersier>
                        )}

                        <ButtonPrimary
                          type="button"
                          className="btn-sm btn-clear"
                          title={
                            isreadonly.schoolCurriculum
                              ? "Edit Data"
                              : "Save Data"
                          }
                          onClick={() => HandleUpdateCandidate("curriculum")}
                        >
                          {schoolCurLoading.loading ? (
                            <SpinnerLoad />
                          ) : isreadonly.schoolCurriculum ? (
                            "Edit"
                          ) : (
                            "Save"
                          )}
                        </ButtonPrimary>
                      </div>
                    </SGUCard.Toolbar>
                  ) : (
                    <></>
                  )} */}
                  <SGUCard.Body className={"px-0"}>
                    <div className="px-9 mt-5">
                      <div className="form-group mb-5">
                        <SchoolCurrList
                          data={schoolCurriculum.school_curr_id}
                          setData={(e) =>
                            HandlerChange({
                              e: {
                                target: { name: "school_curr_id", value: e },
                              },
                              state: "curriculum",
                            })
                          }
                          disabled={isreadonly.schoolCurriculum}
                        />
                      </div>
                    </div>
                  </SGUCard.Body>
                </SGUCard>
              </div>
            </div>
            <div
              style={{ flex: "1 1 300px", minWidth: 300 }}
              className="flex-grow"
            >
              {/* cp */}
              <div className="h-auto w-100">
                <SGUCard>
                  <SGUCard.Header>
                    <span className="card-label fw-bold fs-3 mb-1">
                      Contact Person
                    </span>
                  </SGUCard.Header>
                  {update_biodata ? (
                    <SGUCard.Toolbar>
                      <div className="d-flex">
                        {!isreadonly.cp && (
                          <ButtonTersier
                            type="button"
                            className="btn-sm btn-clear me-2"
                            title="Cancel Edit"
                            onClick={() => {
                              setIsreadonly((prev) => ({
                                ...prev,
                                cp: !prev.cp,
                              }));
                              getCandidate();
                            }}
                          >
                            Cancel
                          </ButtonTersier>
                        )}

                        <ButtonPrimary
                          type="button"
                          className="btn-sm btn-clear"
                          title={
                            isreadonly.cp
                              ? "Edit Data"
                              : isNaN(contactPerson.mobile) ||
                                isNaN(contactPerson.whatsapp)
                              ? "Data must be numeric"
                              : "Save Data"
                          }
                          onClick={() =>
                            !isNaN(contactPerson.mobile) &&
                            !isNaN(contactPerson.whatsapp) &&
                            HandleUpdateCandidate("cp")
                          }
                        >
                          {cpLoading.loading ? (
                            <SpinnerLoad />
                          ) : isreadonly.cp ? (
                            "Edit"
                          ) : (
                            "Save"
                          )}
                        </ButtonPrimary>
                      </div>
                    </SGUCard.Toolbar>
                  ) : (
                    <></>
                  )}
                  <SGUCard.Body className={"px-0"}>
                    {cpLoading.error && (
                      <div className="px-9 mt-5">
                        <AlertNotif
                          title="Failed update data"
                          messages={cpLoading.error + " " + `(try re-Save)`}
                        />
                      </div>
                    )}
                    <div className="px-9 mt-5">
                      <div className="form-group mb-5">
                        <label className="fw-bold">Phone</label>
                        <input
                          type="text"
                          name="mobile"
                          className="form-control form-control-sm"
                          disabled={isreadonly.cp}
                          value={contactPerson.mobile}
                          onChange={(e) => HandlerChange({ e, state: "cp" })}
                        />
                      </div>
                      <div className="form-group mb-5">
                        <label className="fw-bold">Whatsapp</label>
                        <input
                          type="text"
                          name="whatsapp"
                          className="form-control form-control-sm"
                          disabled={isreadonly.cp}
                          value={contactPerson.whatsapp}
                          onChange={(e) => HandlerChange({ e, state: "cp" })}
                        />
                      </div>
                      <div className="form-group mb-5">
                        <label className="fw-bold">Email</label>
                        <input
                          type="text"
                          name="email"
                          className="form-control form-control-sm"
                          disabled
                          value={contactPerson.email}
                        />
                      </div>
                    </div>
                  </SGUCard.Body>
                </SGUCard>
              </div>
              {/* parent */}
              <div className="h-auto w-100">
                <SGUCard>
                  <SGUCard.Header>
                    <span className="card-label fw-bold fs-3 mb-1">Parent</span>
                  </SGUCard.Header>
                  {update_biodata ? (
                    <SGUCard.Toolbar>
                      <div className="d-flex">
                        {!isreadonly.parent && (
                          <ButtonTersier
                            type="button"
                            className="btn-sm btn-clear me-2"
                            title="Cancel Edit"
                            onClick={() => {
                              setIsreadonly((prev) => ({
                                ...prev,
                                parent: !prev.parent,
                              }));
                              getCandidate();
                            }}
                          >
                            Cancel
                          </ButtonTersier>
                        )}

                        <ButtonPrimary
                          type="button"
                          className="btn-sm btn-clear"
                          title={isreadonly.parent ? "Edit Data" : "Save Data"}
                          onClick={() => HandleUpdateCandidate("parent")}
                        >
                          {parentLoading.loading ? (
                            <SpinnerLoad />
                          ) : isreadonly.parent ? (
                            "Edit"
                          ) : (
                            "Save"
                          )}
                        </ButtonPrimary>
                      </div>
                    </SGUCard.Toolbar>
                  ) : (
                    <></>
                  )}
                  <SGUCard.Body className={"px-0"}>
                    {parentLoading.error && (
                      <div className="px-9 mt-5">
                        <AlertNotif
                          title="Failed update data"
                          messages={parentLoading.error + " " + `(try re-Save)`}
                        />
                      </div>
                    )}
                    <div className="px-9 mt-5">
                      <div className="form-group mb-5">
                        <label className="fw-bold">Parent Type</label>
                        <input
                          type="text"
                          name="parent_type"
                          className="form-control form-control-sm"
                          disabled={isreadonly.parent}
                          value={parent.parent_type}
                          onChange={(e) =>
                            HandlerChange({ e, state: "parent" })
                          }
                        />
                      </div>
                      <div className="form-group mb-5">
                        <label className="fw-bold">Parent Name</label>
                        <input
                          type="text"
                          name="parent_name"
                          className="form-control form-control-sm"
                          disabled={isreadonly.parent}
                          value={parent.parent_name}
                          onChange={(e) =>
                            HandlerChange({ e, state: "parent" })
                          }
                        />
                      </div>
                      {/* <div className="form-group mb-5">
                        <label className="fw-bold">Profession</label>
                        <input
                          type="text"
                          name="parent_profession"
                          className="form-control form-control-sm"
                          disabled={isreadonly.parent}
                          value={parent.parent_profession}
                          onChange={(e) =>
                            HandlerChange({ e, state: "parent" })
                          }
                        />
                      </div> */}
                      <div className="form-group mb-5">
                        <label className="fw-bold">Parent Email</label>
                        <input
                          type="text"
                          name="parent_email"
                          className="form-control form-control-sm"
                          disabled={isreadonly.parent}
                          value={parent.parent_email}
                          onChange={(e) =>
                            HandlerChange({ e, state: "parent" })
                          }
                        />
                      </div>
                      <div className="form-group mb-5">
                        <label className="fw-bold">Parent Mobile</label>
                        <input
                          type="text"
                          name="parent_phone"
                          className="form-control form-control-sm"
                          disabled={isreadonly.parent}
                          value={parent.parent_phone}
                          onChange={(e) =>
                            HandlerChange({ e, state: "parent" })
                          }
                        />
                      </div>
                      <div className="form-group mb-5">
                        <label className="fw-bold">Address</label>
                        <input
                          type="text"
                          name="parent_addr"
                          className="form-control form-control-sm"
                          disabled={isreadonly.parent}
                          value={parent.parent_addr}
                          onChange={(e) =>
                            HandlerChange({ e, state: "parent" })
                          }
                        />
                      </div>
                      <div className="form-group mb-5">
                        <ProvinceList
                          title={"Parent Province"}
                          data={parent?.parent_province_name}
                          setData={(e) =>
                            HandlerChange({
                              e: {
                                target: {
                                  name: "parent_province",
                                  value: e,
                                },
                              },
                              state: "parent",
                            })
                          }
                          disabled={isreadonly.parent}
                        />
                      </div>
                      <div className="form-group mb-5">
                        <CountryCitizenshipList
                          title={"Parent Country"}
                          data={parent?.parent_country_name}
                          setData={(e) =>
                            HandlerChange({
                              e: {
                                target: {
                                  name: "parent_country_id",
                                  value: e,
                                },
                              },
                              state: "parent",
                            })
                          }
                          disabled={isreadonly.parent}
                        />
                      </div>
                    </div>
                  </SGUCard.Body>
                </SGUCard>
              </div>
              {/* questionnaire */}
              <div className="h-auto w-100">
                <SGUCard>
                  <SGUCard.Header>
                    <span className="card-label fw-bold fs-3 mb-1">
                      Questionnaire
                    </span>
                  </SGUCard.Header>
                  <SGUCard.Body className={"px-0"}>
                    <div className="px-9 mt-5">
                      <div className="form-group mb-5">
                        <label className="fw-bold">Know SGU from</label>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          disabled={isreadonly.education}
                          value={questionnaire}
                          readOnly
                        />
                      </div>
                    </div>
                  </SGUCard.Body>
                </SGUCard>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export { DetailEnrollments };
