import PackageJSON from "../../../package.json"
import CryptoJS from "crypto-js";

const VersionApp = PackageJSON.version;
const WAHotline = "https://wa.me/message/NNSYT7PGEZM5E1";

const Private_key = PackageJSON.system_param.public_key;

const GetToken = localStorage.getItem("token");
const APPEnv = process.env.REACT_APP_ESTES_APP_ENV;
const APPID = GetToken;

const URIEstesLogin = process.env.REACT_APP_ESTES_API_PATH_LOGIN;
const URIEstesReq = process.env.REACT_APP_ESTES_API_PATH_REQUEST;

const ConfigEnv = {
    development : {
        baseuri     : process.env.REACT_APP_BASE_URL_DEV,
        estesuri    : process.env.REACT_APP_ESTES_API_DEV,
        backenduri  : process.env.REACT_APP_BACKEND_API_DEV,
        appenv      : APPEnv,
        privatekey  : Private_key,
        estesauth   : URIEstesLogin,
        estesreq    : URIEstesReq
    },
    production : {
        baseuri     : process.env.REACT_APP_BASE_URL,
        estesuri    : process.env.REACT_APP_ESTES_API,
        backenduri  : process.env.REACT_APP_BACKEND_API,
        appenv      : APPEnv,
        privatekey  : Private_key,
        estesauth   : URIEstesLogin,
        estesreq    : URIEstesReq
    }
}

const GET_ENVIRONMENT = () =>{
    const Node_ENV = process.env.REACT_APP_NODE_ENV;
    return ConfigEnv[Node_ENV];
}

const GenerateAppID = length => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}

const GenerateSignature = (payloadTmp) => {
    var d = new Date();
    var tstamp = d.getTime() / 1000;
    var trimmed_payload = JSON.stringify(payloadTmp).replace(/\s+/g, '');
    var base64 = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(trimmed_payload));
    //console.log(base64);
    var signature_raw = tstamp + '|<->|' + base64;
    //console.log(signature_raw);
    var signature_bytes = CryptoJS.HmacSHA256(signature_raw, Private_key);
    //console.log(signature_bytes);
    var signature = CryptoJS.enc.Base64.stringify(signature_bytes);

    return {tstamp, signature};
}

export { VersionApp, WAHotline, APPID, GenerateAppID, GetToken, GenerateSignature, GET_ENVIRONMENT, Private_key }