import ExcelJs from "exceljs";
import moment from "moment";
import { GET_SCHD, GET_SECTION } from "../../apis/node-target-data/AcademicApi";
import { MessageError, openModal } from "../../../../_layouts/components/modals/Modals";
import { BlobImg } from "../../../../_layouts/components/images/Logos";

const DownloadTimetable = async (section_id, setStatus) => {
    try {
        setStatus(true);
        const param = { section_id: section_id };
        const result = await GET_SECTION(param);

        if (result.data && Object.values(result.data).length > 0) {
            const result_schd = await GET_SCHD(param);
            if (result_schd.data && Object.values(result_schd.data).length > 0) {
                const results = result_schd.data.map((c) => {
                    var obj = {};
                    obj.start_dt = c.start_dt ? c.start_dt : "";
                    obj.start_dt_formatted = c.start_dt_formatted ? c.start_dt_formatted : "";
                    obj.end_dt_formatted = c.end_dt_formatted ? c.end_dt_formatted : "";
                    obj.event_room = (c.event_room) ? c.event_room : "";
                    obj.is_makeup = c.is_makeup ? c.is_makeup : "";
                    obj.activity_type_id = c.activity_type ? c.activity_type.id : "";
                    obj.activity_type = c.activity_type ? c.activity_type.name : "";
                    obj.atd_lecturer = c.atd_lecturer ? c.atd_lecturer : "";
                    obj.atd_lect_name = c.atd_lecturer ? ((c.atd_lecturer.emp) ? c.atd_lecturer.emp.fullname : "") : "";
                    obj.atd_lect_id = c.atd_lecturer ? ((c.atd_lecturer.emp) ? c.atd_lecturer.emp.id : "") : "";
                    obj.atd_teaching_topic = c.atd_lecturer ? c.atd_lecturer.teaching_topic : "";
                    obj.atd_status = c.atd_lecturer ? c.atd_lecturer.status : "";
                    obj.atd_lec_time = c.atd_lecturer ? c.atd_lecturer.lec_tap_time : "";
                    obj.weight = c.weight ? c.weight : "";
                    obj.id = c.id ? c.id : "";
                    obj.event_calendar = c.event_calendar ? c.event_calendar : "";
                    obj.section = (c.section) ? c.section : "";
                    obj.courses = (c.course) ? c.course : "";
                    return obj;
                })
                TimetableSection(results, result.data[0]);
            } else {
                openModal({ message: <MessageError message={result_schd.message} /> })
            }
        } else {
            openModal({ message: <MessageError message={"Norecord found"} /> })
        }

        setStatus(false);
    } catch (error) {
        openModal({ message: <MessageError message={"Failed to download. Please try again later."} /> })
        console.log(error);
        setStatus(false);
    }
};


const TimetableSection = (data, section) => {
    const wb = new ExcelJs.Workbook();
    wb.creator = "UMS-SGU";
    wb.lastModifiedBy = "UMS-SGU-System";
    wb.created = new Date();
    wb.modified = new Date();
    wb.lastPrinted = new Date();
    //body here
    SheetTimetable(wb, data, section);
    //end body here
    const fileName = "Timetable SectionID " + section.section_id;

    const writeFile = (fileName, content) => {
        const link = document.createElement("a");
        const blob = new Blob([content], {
            type:
                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8",
        });
        link.download = fileName;
        link.href = URL.createObjectURL(blob);
        link.click();
    };

    wb.xlsx.writeBuffer().then((buffer) => {
        writeFile(fileName, buffer);
    });
};

const RowHeader = (rowHeader, i, value) => {
    rowHeader.getCell(i).value = value;
    rowHeader.getCell(i).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
    };
    rowHeader.getCell(i).fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "191970" },
    };
    rowHeader.getCell(i).alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
    };
    rowHeader.getCell(i).font = {
        bold: true,
        size: 11,
        color: { argb: "FFFFFF" },
    };
}

const SheetTimetable = (wb, data, section) => {
    console.log(data);
    const tableHeader = [
        { key: "A", title: "No", width: 5 },
        { key: "B", title: "Start Date", width: 15 },
        { key: "C", title: "End Date", width: 15 },
        { key: "D", title: "Lecturer", width: 25 },
        { key: "E", title: "Teaching Topic", width: 35 },
        { key: "F", title: "Lecturer Tap Time", width: 15 },
        { key: "G", title: "Status", width: 15 },
        { key: "H", title: "Room", width: 15 },
        { key: "I", title: "Activity", width: 15 },
    ];

    const course = section.course;
    const course_title = course.coursetitle.replace(/[^a-zA-Z0-9 ]/g, '');
    const ws = wb.addWorksheet("Timetable "+section.section_id);

    const HeaderXLS = BlobImg();
    const imageId1 = wb.addImage({
        fileName: "logo-1",
        extension: "png",
        base64: HeaderXLS.imglogo,
    });
    const imageId2 = wb.addImage({
        fileName: "logo-2",
        extension: "png",
        base64: HeaderXLS.imglogoName,
    });

    ws.addImage(imageId1, "A1:B2");
    ws.addImage(imageId2, "E1:G2");

    let rowNumber = 4;
    ws.getRow(rowNumber).getCell("A").value = "Report Timetable for Section " + section.section_id;
    ws.getRow(rowNumber).getCell("A").font = {
        bold: true,
        size: 16,
    };
    ws.getRow(rowNumber).getCell("A").border = {
        top: { style: "thin" },
        bottom: { style: "thin" },
    };
    ws.getRow(rowNumber).getCell("A").alignment = { horizontal: "center" };
    ws.mergeCells("A" + rowNumber + ":E" + rowNumber);
    rowNumber = rowNumber + 2;

    ws.getRow(rowNumber).getCell("A").value = "Academic Term: " + (section.atp_term && (section.atp_term.name || "-"));
    ws.mergeCells("A" + rowNumber + ":C" + rowNumber);
    rowNumber = rowNumber + 1;

    ws.getRow(rowNumber).getCell("A").value = "Course Title: " + course_title;
    ws.mergeCells("A" + rowNumber + ":C" + rowNumber);
    rowNumber = rowNumber + 1;

    ws.getRow(rowNumber).getCell("A").value = "Course Code: " + course.code;
    ws.mergeCells("A" + rowNumber + ":C" + rowNumber);
    rowNumber = rowNumber + 1;

    ws.getRow(rowNumber).getCell("A").value = "Credit: " + course.credit + " SKS";
    ws.mergeCells("A" + rowNumber + ":C" + rowNumber);
    rowNumber = rowNumber + 1;

    const totalLecturer = Object.values(section.lecturer).length;
    let lecturerName = "";
    if (totalLecturer > 0) {
        section.lecturer.map(item => {
            lecturerName += (item.emp && item.emp.fullname || "-") + "; ";
        })
    }

    ws.getRow(rowNumber).getCell("A").value = "Lecturer: " + lecturerName;
    ws.mergeCells("A" + rowNumber + ":C" + rowNumber);
    rowNumber = rowNumber + 2;

    const rowHeader = ws.getRow(12);
    tableHeader.map((v, index) => {
        var i = index + 1;
        RowHeader(rowHeader, i, v.title)
    });
    rowNumber = rowNumber + 1;

    if (Object.values(data).length > 0) {
        const startRow = 13;
        let rowStudent = startRow;
        var num = 1;
        data.map((v) => {
            const row = ws.getRow(rowStudent);
            let curCell = 1;
            row.getCell(curCell++).value = num++;
            row.getCell(curCell++).value = v.start_dt_formatted;
            row.getCell(curCell++).value = v.end_dt_formatted;
            row.getCell(curCell++).value = v.atd_lect_name;
            row.getCell(curCell++).value = v.atd_teaching_topic;
            row.getCell(curCell++).value = v.atd_lec_time ? moment(v.atd_lec_time).format("DD MMM YYYY, hh:mm A") : "";
            row.getCell(curCell++).value = v.atd_status;
            let rooms = "";
            if(Object.values(v.event_room).length > 0){
                v.event_room.map(item => {
                    rooms += (item.room && ( item.room.g_resourceName || "")) + "; ";
                })
            }
            row.getCell(curCell++).value = rooms;
            row.getCell(curCell++).value = v.activity_type;
            row.eachCell({ includeEmpty: true }, (cell) => {
                cell.border = {
                    top: { style: "thin" },
                    left: { style: "thin" },
                    bottom: { style: "thin" },
                    right: { style: "thin" },
                };
            });
            rowStudent++;
        });        
    }

    ws.columns.forEach((column, index) => {
        if (index === 0) {
            column.width = 5;
        } else if (index === 1 || index === 2 || index === 5) {
            column.width = 25;
        } else {
            let maxColumnLength = 0;
            column.eachCell({ includeEmpty: true }, cell => {
                let cellLength = cell.value ? cell.value.toString().length : 0;
                if (cellLength > maxColumnLength) {
                    maxColumnLength = cellLength;
                }
            });
            column.width = maxColumnLength < 10 ? 10 : maxColumnLength;
        }
    });
};

export { DownloadTimetable }