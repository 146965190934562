import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl';
import { SidebarMenuItem } from '../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItem';
import { SidebarMenuItemWithSub } from '../../../_metronic/layout/components/sidebar/sidebar-menu/SidebarMenuItemWithSub';
import { useProvider } from '../../UMSLayoutProvider';
import { GET_MENU_BY_GROUP } from '../../../app/modules/apis/module/BackendRequest';

export default function SidebarMenuDinamic() {
    const { account } = useProvider();
    const intl = useIntl()
    const filterRoutesPublic = (routes) => {
        if (account.typeCK === "production") {
            return routes
                .filter(route => route.menu_is_active === 1 && (route.menu_type === "" || route.menu_type == null))
                .map(route => ({
                    ...route,
                    children: filterRoutesPublic(route.children),
                }));
        } else {
            return routes
                .filter(route => route.menu_is_active === 1 && ((route.menu_type === "" || route.menu_type == null) || route.menu_type === 2))
                .map(route => ({
                    ...route,
                    children: filterRoutesPublic(route.children),
                }));
        }

    };

    const filterRoutesPrivate = (routes) => {
        return routes
            .filter(route => route.menu_is_active === 1 && route.menu_type === 1)
            .map(route => ({
                ...route,
                children: filterRoutesPublic(route.children),
            }));
    };

    const [menu, setMenu] = useState([]);
    const [menuPrivate, setMenuPrivate] = useState([]);


    const ReloadMenu = async () =>{
        const param = {group_id:account.identity.group_id};
        const result = await GET_MENU_BY_GROUP(param);
        if(result.data && Object.values(result.data).length > 0){
            const menu_public = filterRoutesPublic(result.data);
            const sorted_menu = sortMenuByPosition(menu_public);
            setMenu(sorted_menu);
            const menu_private = filterRoutesPrivate(result.data);
            setMenuPrivate(menu_private);
        }
    }
    useEffect(() => {
        ReloadMenu();
    }, [])

    const sortMenuByPosition = (menuArray) => {
        const sortedMenu = menuArray.sort((a, b) => a.menu_position - b.menu_position);
      
        sortedMenu.forEach(item => {
          if (item.children && item.children.length > 0) {
            item.children = sortMenuByPosition(item.children);
          }
        });
      
        return sortedMenu;
    };

    return (
        <>
            <SidebarMenuItem
                to='/dashboard'
                icon='element-11'
                title={intl.formatMessage({ id: 'MENU.DASHBOARD' })}
                fontIcon='bi-app-indicator'
            />
            {(menu) && (
                menu.map((v, index) => (
                    <MappingSideber data={v} key={index} />
                ))
            )}

            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Configurations</span>
                </div>
            </div>
            {(menuPrivate) && (
                menuPrivate.map((v, index) => (
                    <MappingSideber data={v} key={index} />
                ))
            )}
        </>
    )
}

const MappingSideber = ({ data }) => {
    const totalChild = Object.values(data.children).length;
    if (totalChild > 0) {
        return <SidebarMenuGroup data={data} />
    } else {
        return <SidebarMenuItem to={data.menu_uri} icon={data.menu_icon ? data.menu_icon : ""} title={data.menu_name} fontIcon='bi-layers' isStaging={data.menu_type} />
    }
}

const SidebarMenuGroup = ({ data }) => {
    return (
        <SidebarMenuItemWithSub
            to={data.menu_uri}
            title={data.menu_name}
            fontIcon='bi-archive'
            icon={data.menu_icon ? data.menu_icon : ""}
        >
            {data.children.map((m, index) => (
                <MappingSideber data={m} key={index} />
            ))}
        </SidebarMenuItemWithSub>
    )
}
