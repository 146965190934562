import React, { useState } from "react";
import { ButtonPrimary, ButtonTersier } from "../../../../../_layouts/components/buttons/ButtonUI";
import { AlertNotif } from "../../../../../_layouts/components/alerts/AlertsUI";
import moment from "moment";
import { handleKeyDown } from "../../../components/forms/InputText";
import { MessageError, MessageSuccess, MessageSuccessConfirmLeave, openModal } from "../../../../../_layouts/components/modals/Modals";
import { UPDATE_SCR_STUDENT_COM } from "../../../apis/node-target-data/AcademicApi";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";

export function FormScoreCompNumeric({ data, ReloadData }) {
  const context = useOutletContext();
  const widget_41 = context.widgets.find(
      (item) => item.widget_id === 41 && item.is_enabled === 1
  );
  const navigate = useNavigate();
  const location = useLocation();
  const state_data = location.state;
  
  const section_component = data.section_com;
  const totalComp = Object.values(section_component).length;
  let totalWeight = section_component.reduce((total, component) => {
    return total + component.weight * 100;
  }, 0);
  totalWeight.toFixed(0);
  const [scoreStudent, setScoreStudent] = useState(section_component);
  const handlerScore = (wg_com_id, newScore) => {
    if (newScore > 100) {
      openModal({
        message: (
          <MessageError
            message={
              "The score cannot exceed 100. Please input a value between 0 and 100."
            }
          />
        ),
      });
      return;
    }

    if (newScore || Number(newScore) === 0) {
      const updatedScores = scoreStudent.map((item) =>
        item.wg_com_id === wg_com_id
          ? { ...item, score: Number(newScore) || 0 }
          : item
      );
      setScoreStudent(updatedScores);
    }
  };

  const [submit, setSubmit] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const HandlerSubmit = async (e) => {
    e.preventDefault();
    const isScoreNull = scoreStudent.some(item => item.score === undefined || item.score === "");

    if (isScoreNull) {
      openModal({
        message: (
          <MessageError
            message={
              "Score cannot be empty. All fields must be filled before saving."
            }
          />
        ),
      });
    } else {
      const section_com = scoreStudent.map((v) => {
        var obj = {};
        obj.score = v.score;
        obj.wg_com_id = v.wg_com_id;
        return obj;
      });
      const param = {
        student: [
          {
            section_com: section_com,
            score_type: data.course.score_type,
            scr_std_id: data.id,
            studentid: data.studentid,
            section_id: data.section_id,
          },
        ],
      };
      setSubmit({ loading: true, message: "", data: [] });

      const result = await UPDATE_SCR_STUDENT_COM(param);

      if (result.data && Object.values(result.data).length > 0) {
        openModal({
          message: (
            <MessageSuccess
              message={
                "Successfully updated."
              }
            />
          ),
        });
        ReloadData();
        setSubmit({ loading: false, message: "", data: result.data });
        if(state_data){
          navigate("/score/student", {
            state: {param:state_data.param},
          });
        }else{
          navigate("/score/student");
        }
      } else {
        openModal({ message: <MessageError message={result.message} /> });
        setSubmit(result);
      }
    }
  };

  const HandleCancel = () => {
    if (confirm("Are you sure want to leave this page?") === true) {
      if(state_data){
        navigate("/score/student", {
          state: {param:state_data.param},
        });
      }else{
        navigate("/score/student");
      }
    }
  };

  return (
    <form method="post" autoComplete="off" onSubmit={(e) => HandlerSubmit(e)}>
      {totalWeight !== 100 && (
        <div className="px-8 mt-5">
          <AlertNotif
            title={"Attention"}
            messages={
              "Total weight " +
              totalWeight +
              "% is " +
              (totalWeight < 1 ? "less than" : "over") +
              " 100%, please update the weights of components"
            }
          />
        </div>
      )}
      <table className="table table-row-bordered table-row-dashed  gy-4 gs-9  align-middle fw-bold dataTable">
        <thead className="fs-7 text-gray-500 text-uppercase bg-light">
          <tr>
            <th width={"20%"}>Component</th>
            <th width={"15%"}>Type</th>
            <th width={"10%"}>Weight</th>
            <th>Score</th>
            <th>Last Updated</th>
          </tr>
        </thead>
        <tbody>
          {totalComp > 0 &&
            section_component.map((v, index) => (
              <tr key={index}>
                <td>{decodeURIComponent(v.name)}</td>
                <td>{v.wg_com ? v.wg_com.type : ""}</td>
                <td>{(v.weight * 100).toFixed(0)}%</td>
                <td>
                  {widget_41 ? (
                  <input
                    type="text"
                    className="form-control w-80px text-center"
                    defaultValue={v.score}
                    onChange={(e) => handlerScore(v.wg_com_id, e.target.value)}
                    tabIndex={v.id}
                    onKeyDown={handleKeyDown}
                  />
                ) : <span className="fw-bold">{v.score}</span> }
                </td>
                <td>
                  <span className="d-block">
                    {v.submittedby ? v.submittedby.fullname : ""}
                  </span>
                  <span className="text-muted">
                    {v.submitted
                      ? moment(v.submitted).format("DD MMM YYYY, hh:mm a")
                      : ""}
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          {submit.message && (
            <tr>
              <td colSpan={5}>
                <AlertNotif messages={submit.message} />
              </td>
            </tr>
          )}
          {widget_41 && (
          <tr>
            <td colSpan={5}>
              <div className="text-end">
                <ButtonTersier
                  className="btn-lg me-5"
                  type="button"
                  onClick={() => HandleCancel()}
                >
                  Cancel
                </ButtonTersier>
                <ButtonPrimary
                  className="btn-lg"
                  type="submit"
                  disabled={submit.loading}
                >
                  {submit.loading ? "Processing..." : "Save Changes"}
                </ButtonPrimary>
              </div>
            </td>
          </tr>
          )}
        </tfoot>
      </table>
    </form>
  );
}
