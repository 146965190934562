import { useState } from "react";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import TableData from "./TableData";
import { GET_EMPLOYEE_LOOK_UP } from "../../../apis/node-target-data/HRAPI";

const LookUpEmp = ({ chooseLec }) => {
  const [empList, setEmpList] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const [paramEmp, setParamEmp] = useState({
    key: "",
    message: "",
  });

  const ReloadEmp = async (param) => {
    setEmpList({ loading: true, message: "", data: [] });
    const result = await GET_EMPLOYEE_LOOK_UP(param);
    setEmpList(result);
  };

  const handlerFindEmp = (value) => {
    if (value.length >= 3) {
      setParamEmp({ ...paramEmp, key: value, message: "" });
      const param_emp = { search: value };
      ReloadEmp(param_emp);
    } else if (value.length <= 3) {
      setParamEmp({ ...paramEmp, message: "Please type minimum 3 character" });
      setEmpList({
        message:"",
        loading: false,
        data: [],
      });
    } else {
      setParamEmp({ ...paramEmp, message: "Please fill up this field" });
      setEmpList({
        message:"",
        loading: false,
        data: [],
      });
    }
  };

  return (
    <div className="">
      <div className="form-group mb-8">
        <label className="required fw-bold">Lecturer name:</label>
        <input
          type="text"
          name="name_related"
          autoComplete="off"
          className="form-control required"
          placeholder="Enter name of lecturer here"
          onChange={(e) => handlerFindEmp(e.target.value)}
        />
        {paramEmp.message && (
          <small className="text-danger">{paramEmp.message}</small>
        )}
      </div>
      <TableData lecturerData={empList} chooseLec={chooseLec} />
    </div>
  );
};

const InputLookUpEmp = ({ value }) => {
  return (
    <div className="input-group">
      <input
        type="text"
        readOnly
        className="form-control mb-3 mb-lg-0"
        placeholder="click here to find a lecturer"
        value={value}
      />
      <span className="input-group-text border-0" title="find subject">
        <i className="bi bi-search"></i>
      </span>
    </div>
  );
};

export { LookUpEmp, InputLookUpEmp };
