import { useEffect, useState } from "react";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import { ButtonIcon, ButtonLong } from "../../../../../_layouts/components/buttons/ButtonUI";
import { LoadingBars } from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertNotif, AlertRetry } from "../../../../../_layouts/components/alerts/AlertsUI";
import moment from "moment";
import { KTIcon } from "../../../../../_metronic/helpers";
import { ColorStatus } from "../ThesisUI";
import { GET_ACAD_TERM } from "../../../apis/node-target-data/AcademicApi";
import { StudentIDFormat } from "../../../../../_layouts/_helpers/Global";

const CardStudentInfo = ({ data }) => {
    const [termSelected, setTermSelected] = useState({
      loading: false,
      data: [],
      message: "",
    });
    const ReloadTerm = async () => {
      const param = { atpid: data.atp_id_term };
      const result = await GET_ACAD_TERM(param);
      setTermSelected(result);
    };
    useEffect(() => {
      ReloadTerm();
    }, [data]);
    return (
      <SGUCard>
        <SGUCard.Body className={"p-0"}>
          <ButtonIcon
            type="button"
            className="btn-sm btn-clear"
            onClick={() => window.close()}
            title="Close window"
          >
            <i className="bi bi-x-square fs-1"></i>
          </ButtonIcon>
          <div className="p-8 pt-2">
            <div className="d-flex flex-column text-center mb-9 px-9">
              <div className="text-center">
                <span className="text-gray-800 fw-bold fs-4">
                  {data.studentdata && (data.studentdata.fullname || "")}
                </span>
                <div>
                  <span className="text-muted fw-semibold">{StudentIDFormat(data.studentid)}</span>
                </div>
              </div>
            </div>
            <div className="row mb-4">
              <CardInfo
                name={"Score"}
                value={data.student_course && (data.student_course.score || "-")}
              />
              <CardInfo
                name={"Final Result"}
                value={
                  data.student_course &&
                  (data.student_course.course_status || "-")
                }
              />
              <CardInfo
                name={"Grade"}
                value={
                  data.student_course && (data.student_course.grade_fin || "-")
                }
              />
            </div>
  
            <div className="separator separator-dashed my-4"></div>
            <div className="mb-8">
              <h3>Department:</h3>
              {data.studentdata.clu && (
                <p className="mb-0">
                  {(data.studentdata.clu && data.studentdata.clu.name) || ""}
                  {data.studentdata.clu_spec && (
                    <span className="mx-1">
                      / {data.studentdata.clu_spec.name}
                    </span>
                  )}
                </p>
              )}
            </div>
  
            <div className="d-flex justify-content-between align-items-center">
              <div className="mb-8">
                <h3>Academic Calendar:</h3>
                {termSelected.loading ? (
                  <LoadingBars />
                ) : termSelected.message ? (
                  <AlertRetry
                    messages={termSelected.message}
                    Reload={ReloadTerm}
                  />
                ) : (
                  Object.values(termSelected.data).length > 0 && (
                    <p className="mb-0">{termSelected.data.name}</p>
                  )
                )}
              </div>
  
              <div className="mb-8">
                <h3>Section:</h3>
                <p className="mb-0">{data.section_id}</p>
              </div>
            </div>
  
            {!data.advisor && (
              <AlertNotif messages={"No advisor assigned."} color="info" />
            )}
  
            {data.defensetime && (
              <div className="d-flex justify-content-between align-items-center">
                <div className="mb-8">
                  <h3>Defense Date:</h3>
                  <p className="mb-0">
                    {moment(data.defensedate + " " + data.defensetime).format(
                      "DD MMM YYYY, hh:mm A"
                    )}
                  </p>
                </div>
                <div className="mb-8">
                  <h3>Due Date:</h3>
                  {data.duedate && (
                    <p className="mb-0 d-flex align-items-center">
                      <KTIcon iconName="information" className="fs-3 text-info" />
                      <span className="ms-2  text-info">
                        {moment(data.duedate).format("DD MMM YYYY")}
                      </span>
                    </p>
                  )}
                </div>
              </div>
            )}
  
            {data.room && (
              <div className="mb-8">
                <h3>Defense Room:</h3>
                <p className="mb-0">{data.room.name}</p>
              </div>
            )}
  
            <div className="mb-8">
              <ButtonLong type="button" disabled className={"btn-lg btn-light-"+ColorStatus(data.status)}>
                {data.status}
              </ButtonLong>
            </div>
          </div>
        </SGUCard.Body>
      </SGUCard>
    );
  };
  
  const CardInfo = ({ name, value }) => {
    return (
      <div className="col-md-4 text-center">
        <div className="text-gray-800 fw-bold fs-3">
          <span className="m-0 counted">{value}</span>
        </div>
        <span className="text-gray-500 fs-8 d-block fw-bold">{name}</span>
      </div>
    );
  };
  

  export {CardStudentInfo}