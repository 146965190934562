import React from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { MasterLayout } from "../_metronic/layout/MasterLayout";
import { AuthLayout, AuthLogin } from "./modules/authentications";
import { AuthDecodeToken, GetToken } from "./reducer/AuthRedux";
import { UMSLayoutProvider } from "../_layouts/UMSLayoutProvider";
import { GET_ENVIRONMENT } from "../_layouts/_core/Core";
import BaseRoutes from "./BaseRoutes";
import { Error404 } from "./modules/errors/components/Error404";
import { useEffect, useState } from "react";
import { AxiosLibEstes } from "../_layouts/_helpers/Helpers";
import { Maintenance } from "./modules/maintenances/Maintenance";
import { GET_ROUTES } from "./modules/apis/module/BackendRequest";

export default function Routed() {
  const current_env = GET_ENVIRONMENT();
  const isAuthorized = GetToken;
  const dispatch = useDispatch();
  const LogOut = () => {
    dispatch({ type: "SIGN_OUT" });
  };

  const [maintenance, setMaintenance] = useState();
  const fetchingMaintenance = () => {
    AxiosLibEstes(
      { PortalID: 5, CurrentDate: new Date() },
      "mc/api/maintenance-portal"
    )
      .then((response) => {
        if (response.data) {
          setMaintenance(response.data[0]);
        } else if (response.error) {
          setMaintenance({
            Description: response.error,
            Title: "Failed Connection",
          });
        } else {
          setMaintenance();
        }
      })
      .catch((error) => {
        console.log("errror", error);
      });
  };

  const [routes, setRoutes] = useState();
  const FetchRoute = async () => {
    const result = await GET_ROUTES();
    setRoutes(result);
  };

  useEffect(() => {
    fetchingMaintenance();
    FetchRoute();
  }, []);

  return (
    <Routes>
      <Route path="/error/*" element={<Error404 />} />
      <Route path="/logout" element={<LogOut />} />

      {maintenance ? (
        <Route path="/*" element={<Maintenance data={maintenance} />} />
      ) : (
        routes &&
        (routes.message ? (
          <Route
            path="/*"
            element={
              <Maintenance
                data={{
                  Title: "Server is down",
                  Description: routes.message
                    ? routes.message
                    : "Failed to read data or connect to the server.",
                }}
              />
            }
          />
        ) : (
          Object.values(routes.data).length > 0 &&
          (isAuthorized && AuthDecodeToken() ? (
            <Route
              element={
                <UMSLayoutProvider
                  env={current_env}
                  token={GetToken}
                  account={AuthDecodeToken()}
                  routes={routes.data}
                >
                  <MasterLayout />
                </UMSLayoutProvider>
              }
            >
              <Route path="/*" element={<BaseRoutes />} />
              <Route index element={<Navigate to="/dashboard" />} />
            </Route>
          ) : (
            <Route element={<AuthLayout />}>
              <Route path="/login" element={<AuthLogin />} />
              <Route path="*" element={<Navigate to="/login" />} />
            </Route>
          ))
        ))
      )}

      {/* <Route path='*' element={<Navigate to='/error' />} /> */}
    </Routes>
  );
}
