import React, { useEffect, useState } from 'react'
import FilterUI from '../../components/filters/FilterUI'
import { MessageError, openModal } from '../../../../_layouts/components/modals/Modals';
import { RemoveEmptyValue } from '../../../../_layouts/_helpers/Global';
import { GET_THS } from '../../apis/node-target-data/AcademicApi';
import { AcadTerm, Department, EmployeeSelect } from '../../components/forms/SelectBox';
import { LectLookUp, StudentID, StudentName } from '../../components/forms/InputText';
import PackageJSON from "../../../../../package.json";
import { SGUCard } from '../../../../_layouts/components/cards/CardUI';
import TableData from './TableData';
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';
import { LoadingSkeleton } from '../../../../_layouts/components/loadbars/LoadingBars';
import { AccessInvalid, RequiredMsg } from '../../../../_layouts/_core/ErrorMessage';
import { useOutletContext } from 'react-router-dom';

export function ThesisScore() {
  const context = useOutletContext();
  const widget_42 = context.widgets.find(
    (item) => item.widget_id === 42 && item.is_enabled === 1
  );
  const [studentname, setStudentname] = useState("");
  const [studentid, setStudentid] = useState("");
  const [clu_id, setClu_id] = useState("");
  const [emp_id, setEmp_id] = useState(0);
  const [term_id, setTerm_id] = useState(PackageJSON.system_param.ActiveAcad);

  const postParam = {
    studentname,
    studentid,
    clu_id,
    emp_id,
    term_id
  }

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadData = async (postParam) => {
    setResults({ loading: true, data: [], message: "" });

    const param = RemoveEmptyValue(postParam);
    const result = await GET_THS(param);
    setResults(result);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(()=>{
    ReloadData(postParam);
  },[])

  const SubmitFilter = (e) => {
    e.preventDefault();
    if (postParam.term_id) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      let param = { ...postParam };
      param.offset = 1;
      param.limit = 10;
      ReloadData(param);
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  };

  return widget_42 ? (
    <div className="row">
      <div className="col-lg-3 mb-5">
        <FilterUI title="Thesis Score" onSubmit={SubmitFilter} isload={results.loading}>
          <AcadTerm data={term_id} setData={setTerm_id} required />
          <StudentName data={studentname} setData={setStudentname} />
          <StudentID data={studentid} setData={setStudentid} />
          <Department data={clu_id} setData={setClu_id} />
          <EmployeeSelect data={emp_id} setData={setEmp_id} title={"Advisor"} />
        </FilterUI>
      </div>
      <div className="col-lg-9 mb-5">
        <SGUCard>
          <SGUCard.Header>
            <span className="card-label fw-bolder text-dark">
              Browse Thesis Scores
            </span>
            <span className="text-muted mt-1 fw-bold fs-7">
              List of thesis score by filter
            </span>
          </SGUCard.Header>
          <SGUCard.Body>
            {results.loading ? (
              <LoadingSkeleton />
            ) : results.message ? (
              <AlertNotif messages={results.message} />
            ) : (
              <TableData results={results} ReloadData={SubmitFilter} postParam={postParam} />
            )}
          </SGUCard.Body>
        </SGUCard>
      </div>
    </div>
  ) : <AlertNotif messages={AccessInvalid} />
}
