import React, { useEffect, useState } from 'react'
import { SGUCard } from '../../../../_layouts/components/cards/CardUI'
import { NavLink, Outlet, useOutletContext, useParams } from 'react-router-dom';
import { GET_SECTION } from '../../apis/node-target-data/AcademicApi';
import { LoadingSkeleton } from '../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../_layouts/components/alerts/AlertsUI';
import { Error404 } from '../../errors/components/Error404';
import { HeaderSection } from './components/SectionUI';

export function DetailSection() {
    const context = useOutletContext();
    let params = useParams();
    var section_id = params.section_id;

    const [data, setData] = useState({ loading: false, data: [], message: "" });

    const ReloadData = async () => {
        setData({ loading: true, message: "", data: [] });
        const param = { section_id: section_id }
        const result = await GET_SECTION(param);

        if (result.data && Object.values(result.data).length > 0) {
            setData({ loading: false, message: "", data: result.data[0] });
        } else {
            setData(result);
        }

    };
    useEffect(() => {
        ReloadData();
    }, [section_id])

    if (data.loading) {
        return (
            <SGUCard>
                <SGUCard.Body>
                    <LoadingSkeleton />
                </SGUCard.Body>
            </SGUCard>
        )
    } else if (data.message) {
        return (
            <AlertNotif messages={data.message} title={"Information"} />
        )
    } else if (Object.values(data.data).length > 0) {
        return (
            <div id="detail-section">
                <SGUCard>
                    <SGUCard.Body className={"px-0 pb-0"}>
                        <div className="mt-8 px-8">
                            <HeaderSection data={data.data} />
                        </div>
                        <div className="separator"></div>
                        <div className="px-8">
                            <ul className="nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold">
                                <li className="nav-item">
                                    <NavLink to={`detail`} className="nav-link text-active-primary py-5 me-6">
                                        Section Information
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={"timetable"} className="nav-link text-active-primary py-5 me-6">
                                        Timetable
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={"attendance"} className="nav-link text-active-primary py-5 me-6">
                                        Attendance
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink to={"score"} className="nav-link text-active-primary py-5 me-6">
                                        Score
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </SGUCard.Body>
                </SGUCard>

                <div className="my-5">
                    <Outlet context={{ data: data.data, widget: context.widgets }} />
                </div>
            </div>
        )
    } else {
        return <Error404 />;
    }
}
