import React, { useState } from 'react'
import MCountry from './country'
import MProvince from './province'
import MCity from './city';

export function RegionalManagement() {
  const [country_id, setCountry_id] = useState(0);
  const [province_id, setProvince_id] = useState(0);
  const [city_id, setCity_id] = useState(0);
  return (
    <div className='row'>
      <div className="col-lg-4">
        <MCountry country_id={country_id} setCountry_id={setCountry_id} setProvince_id={setProvince_id} setCity_id={setCity_id} />
      </div>
      {(country_id !== 0) && (
        <>
          {country_id === 103 && (
            <div className="col-lg-4">
              <MProvince country_id={country_id} province_id={province_id} setProvince_id={setProvince_id} />
            </div>
          )}
          {country_id !== 103 && (
            <div className="col-lg-4">
              <MCity country_id={country_id} province_id={province_id} setCity_id={setCity_id} />
            </div>
          )}
          {province_id !== 0 && (
            <div className="col-lg-4">
              <MCity country_id={country_id} province_id={province_id} setCity_id={setCity_id} />
            </div>
          )}
        </>
      )}
    </div>
  )
}
