import React, { useEffect, useMemo, useState } from "react";
import { SGUCard } from "../../../../../_layouts/components/cards/CardUI";
import {
    GET_ROOM,
} from "../../../apis/node-target-data/AcademicApi";
import {
  LoadingSkeleton,
  NoRecordFound,
} from "../../../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../../../_layouts/components/alerts/AlertsUI";
import {
  ButtonItemRefresh,
  HeaderDatatables,
  PaginationComponent,
  SearchBarTable,
} from "../../../../../_layouts/components/datatables/MYDatatables";

export default function GRooms() {
  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const ReloadData = async () => {
    setResults({ loading: true, data: [], message: "" });
    const result = await GET_ROOM();
    setResults(result);
  };

  useEffect(() => {
    ReloadData();
  }, []);

  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;

  const headers = [
    { name: "No", field: "id", sortable: false },
    { name: "Name", field: "name", sortable: true },
    { name: "Floor", field: "g_floorName", sortable: true },
    { name: "Building", field: "g_buildingId", sortable: true },
    { name: "Type", field: "g_resourceType", sortable: false },
    { name: "Capacity", field: "member", sortable: false },
  ];

  const ResultData = useMemo(() => {
    let computedData = results.data;

    if (search) {
      computedData = computedData.filter((listData) => {
        return Object.keys(listData).some(
          (key) =>
            listData[key]
              .toString()
              .toLowerCase()
              .includes(search)
          //console.log(key,listData[key])
        );
      });
    }

    setTotalItems(computedData.length);

    //Sorting listDatas
    if (sorting.field) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedData = computedData.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (computedData.length > 0) {
      return computedData.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    } else {
      return [];
    }
  }, [results.data, search, sorting, currentPage]);

  return (
    <SGUCard>
      <SGUCard.Header>
        <span className="card-label fw-bolder text-dark">Google Room Capacity</span>
        <span className="text-muted mt-1 fw-bold fs-7">
          list of active google rooms
        </span>
      </SGUCard.Header>
      <SGUCard.Toolbar></SGUCard.Toolbar>
      <SGUCard.Body>
        <div className="mb-3 d-flex justify-content-between align-items-center">
          <SearchBarTable
            size="w-250px"
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="d-flex">
            <ButtonItemRefresh
              totalItems={totalItems}
              onClick={() => ReloadData()}
            />
          </div>
        </div>
        {results.loading ? (
          <LoadingSkeleton />
        ) : results.message ? (
          <AlertRetry messages={results.message} Reload={ReloadData} />
        ) : (
          <div className="table-responsive">
            <table className="table align-middle table-row-dashed fs-6 gy-5 dataTable">
              <HeaderDatatables
                headers={headers}
                onSorting={(field, order) => setSorting({ field, order })}
              />
              <tbody>
                {ResultData.length > 0 ? (
                  ResultData.map((v, index) => (
                    <tr key={index}>
                      <td width={"8%"} className="fs-7">
                        {index + 1}
                      </td>
                      <td className="fs-7">Room {v.name}</td>
                      <td className="fs-7">Floor {v.g_floorName}</td>
                      <td className="fs-7">{v.g_buildingId}</td>
                      <td className="fs-7">{v.g_resourceType}</td>
                      <td className="fs-7">{v.g_capacity}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <NoRecordFound />
                    </td>
                  </tr>
                )}
              </tbody>
            </table>

            {ResultData.length > 0 && (
              <PaginationComponent
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
          </div>
        )}
      </SGUCard.Body>
    </SGUCard>
  );
}
