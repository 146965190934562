import React, { useState, Suspense, useEffect } from "react";
import FilterUI from "../../components/filters/FilterUI";
import {
  AcadProgram,
  AcadTerm,
  Corporate,
  CourseOwner,
  Department,
  DepartmentSpec,
} from "../../components/forms/SelectBox";
import {
  MessageError,
  openModal,
} from "../../../../_layouts/components/modals/Modals";
import TableData from "./TableData";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";
import { GET_TEACHINGLOAD } from "../../apis/node-target-data/AcademicApi";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { useLocation, useOutletContext } from "react-router-dom";
import {
  AccessInvalid,
  RequiredMsg,
} from "../../../../_layouts/_core/ErrorMessage";
import { LookUpCourseCatalog } from "../master-courses/components/LookUpCourseCatalog";
import { Semester } from "../../components/forms/InputText";

export function TeachingLoads() {
  const location = useLocation();
  const context = useOutletContext();
  const widget_18 = context.widgets.find(
    (item) => item.widget_id === 18 && item.is_enabled === 1
  );
  const widget_8 = context.widgets.find(
    (item) => item.widget_id === 8 && item.is_enabled === 1
  );

  const [org_id, setOrg_id] = useState(
    location.state
      ? (location.state.param && location.state.param.org_id) || 0
      : 0
  );
  const [curr_group, setCurr_group] = useState(
    location.state
      ? (location.state.param && location.state.param.curr_group) || ""
      : ""
  );
  const [term_id, setTerm_id] = useState(
    location.state
      ? (location.state.param && location.state.param.term_id) || 0
      : 0
  );
  const [clu_id, setClu_id] = useState(
    location.state
      ? (location.state.param && location.state.param.clu_id) || 0
      : 0
  );
  const [clu_spec_id, setClu_spec_id] = useState(
    location.state
      ? (location.state.param && location.state.param.clu_spec_id) || 0
      : 0
  );
  const [semester, setSemester] = useState(
    location.state
      ? (location.state.param && location.state.param.semester) || ""
      : ""
  );
  const [corporate_id, setCorporate_id] = useState(
    location.state
      ? (location.state.param && location.state.param.corporate_id) || 0
      : 0
  );

  const postParam = {
    org_id,
    curr_group,
    term_id,
    clu_id,
    clu_spec_id,
    semester,
    corporate_id,
  };

  const [results, setResults] = useState({
    loading: false,
    message: "",
    data: [],
  });

  const ReloadData = async (param) => {
    setResults({ loading: true, message: "", data: [] });
    const result = await GET_TEACHINGLOAD(param);

    setResults(result);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (location.state && location.state.param) {
      const paramTL = location.state.param;
      const param = RemoveEmptyValue(paramTL);
      ReloadData(param);
    }
  }, []);

  const SubmitFilter = (e) => {
    e.preventDefault();
    if (postParam.org_id && postParam.curr_group && postParam.term_id) {
      const param = RemoveEmptyValue(postParam);
      ReloadData(param);
    } else {
      openModal({
        message: <MessageError message={RequiredMsg} />,
      });
    }
  };

  const [subLayout, setSubLayout] = useState({ open: false, param: postParam });
  const ChangeLayout = (component) => {
    if (postParam.org_id && postParam.curr_group && postParam.term_id) {
      setSubLayout({ ...subLayout, open: true, component: component });
    } else {
      openModal({ message: <MessageError message={"Please fill out the required fields in the filter first"} /> });
    }
  };

  const RenderSubLayout = ({ element }) => {
    const componentMap = {
      LookUpCourseCatalog: LookUpCourseCatalog,
    };

    const DynamicComponent = componentMap[element];
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <DynamicComponent
          param={postParam}
          setSubLayout={setSubLayout}
          is_create={widget_8 ? widget_8 : false}
        />
      </Suspense>
    );
  };

  return widget_18 ? (
    <div className="teaching-load">
      {subLayout.open ? (
        <RenderSubLayout element={subLayout.component} />
      ) : (
        <div className="row">
          <div className="col-lg-3">
            <FilterUI
              title="Course Section"
              onSubmit={SubmitFilter}
              isload={results.loading}
            >
              <CourseOwner data={org_id} setData={setOrg_id} required={true} />
              <AcadProgram
                data={curr_group}
                setData={setCurr_group}
                required={true}
              />
              <AcadTerm data={term_id} setData={setTerm_id} required={true} />
              <Department data={clu_id} setData={setClu_id} />
              <DepartmentSpec data={clu_spec_id} setData={setClu_spec_id} />
              <Corporate data={corporate_id} setData={setCorporate_id} />
              <Semester data={semester} setData={setSemester} />
            </FilterUI>
          </div>
          <div className="col-lg-9">
            <TableData
              results={results}
              paramTL={postParam}
              ReloadData={ReloadData}
              ChangeLayout={ChangeLayout}
            />
          </div>
        </div>
      )}
    </div>
  ) : (
    <AlertNotif messages={AccessInvalid} />
  );
}
