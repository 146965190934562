import React, { useMemo, useState } from 'react'
import { ButtonItemRefresh, SearchBarTable } from '../../../../_layouts/components/datatables/MYDatatables';
import { Link } from 'react-router-dom';

export default function TableStudent({ data, ReloadData }) {
    const [totalItems, setTotalItems] = useState(0);
    const [search, setSearch] = useState("");
    const ResultData = useMemo(() => {
        let computedData = data;
        //computedData.sort((a, b) => (a.student_status > b.student_status ? 1 : -1));
        setTotalItems(computedData.length)
        if (search) {
            computedData = computedData.filter((listData) => {
                return Object.keys(listData).some(
                    (key) =>
                        listData[key]
                            .toString()
                            .toLowerCase()
                            .includes(search)
                );
            });
        }

        return computedData;
    }, [data, search]);

    return (
        <div className="row">
            <div className="col-12">
                <div className="mb-3 d-flex justify-content-between align-items-center">
                    <SearchBarTable size="w-250px" onChange={(e) => setSearch(e.target.value)} />
                    <ButtonItemRefresh totalItems={totalItems} onClick={() => ReloadData()} />
                </div>
            </div>
            {ResultData.length > 0
                ? ResultData.map((v, index) => (
                    <div className="col-12 col-lg-4 my-3" key={index}>
                        <Link to={"/students/search/"+v.studentid} target='_blank'>
                            <div className={"d-flex flex-stack "}>
                                <div className="symbol symbol-30px me-3">
                                    <div className={"symbol-label fs-3 fw-bold " + (v.student_status !== "asc.student.status.active" ? "bg-danger text-inverse-danger" : "bg-primary text-inverse-primary")}>
                                        {v.student_name ? v.student_name.charAt(0) : "X"}
                                    </div>
                                </div>
                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                    <div className="flex-grow-1 me-2">
                                        <span className={"fs-6 fw-bolder " + (v.student_status !== "asc.student.status.active" ? "text-danger " : "text-gray-800 text-hover-primary ")}>
                                            {v.student_name ? v.student_name : v.studentid}
                                        </span>

                                        {v.student_name ? (
                                            <span className="text-muted fw-bold d-block fs-7">
                                                {v.studentid}
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))
                : ""}
        </div>
    )
}
