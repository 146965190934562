import React, { useState } from "react";
import { FilterCalendar } from "../components";
import {
  MessageError,
  openModal,
} from "../../../../_layouts/components/modals/Modals";
import { RequiredMsg } from "../../../../_layouts/_core/ErrorMessage";
import moment from "moment";
import { RoomSingle } from "../../components/forms/SelectBox";

export default function Filter({ isload, setPostParam }) {
  const EventTypes = [
    { id: "asc.event.type.class", name: "Class", isActive: 1 },
    { id: "asc.event.type.event", name: "Event", isActive: 1 },
    { id: "asc.event.type.finalexam", name: "Final Exam", isActive: 0 },
    { id: "asc.event.type.guestlecturer", name: "Guest Lecturer", isActive: 0 },
    { id: "asc.event.type.holiday", name: "Holiday", isActive: 0 },
    {
      id: "asc.event.type.internshipbriefing",
      name: "Internship Briefing",
      isActive: 0,
    },
    {
      id: "asc.event.type.internshippresentation",
      name: "Internship Presentation",
      isActive: 0,
    },
    { id: "asc.event.type.meeting", name: "Meeting", isActive: 0 },
    { id: "asc.event.type.ofse", name: "OFSE", isActive: 0 },
    { id: "asc.event.type.seminar", name: "Seminar", isActive: 0 },
    {
      id: "asc.event.type.studentproject",
      name: "studentproject",
      isActive: 0,
    },
  ];

  const [event_type, setEvent_type] = useState("");
  const [room_id, setRoom_id] = useState(0);

  const HandlerFilter = (e) => {
    e.preventDefault();

    const monday = moment()
      .startOf("weeks")
      .format("YYYY-MM-DD");
    const sunday = moment()
      .endOf("weeks")
      .format("YYYY-MM-DD");
    setPostParam((prev) => {
      const data = { ...prev };
      if(room_id){
        data.room_id = room_id;
      }else{
        data.room_id = 0;
      }
      if(event_type){
        data.event_type = event_type;
      }else{
        data.event_type = "";
      }
      
      data.start_dt = monday;
      data.end_dt = sunday;
      return data;
    });
  };

  return (
    <FilterCalendar onSubmit={HandlerFilter} isload={isload}>
      <div className="w-300px">
        <RoomSingle
          data={room_id}
          setData={setRoom_id}
          showtitle={false}
          required
          placeholder="Find Room"
        />
      </div>
      <div className="form-group mx-5">
        <select
          name="event_type"
          className="form-select form-select-sm py-3 pe-10"
          defaultValue={event_type}
          onChange={(e) => setEvent_type(e.target.value)}
        >
          <option value="">Choose event type</option>
          {EventTypes.map(
            (v, index) =>
              v.isActive === 1 && (
                <option key={index} value={v.id}>
                  {v.name}
                </option>
              )
          )}
        </select>
      </div>
    </FilterCalendar>
  );
}
