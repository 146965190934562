import { GET_ACAD_MSTONE_BY_ACAD } from "../../../apis/node-target-data/AcademicApi";
import { addStatusCallMonth } from "../helpers";
import { ConvertSchdItem } from "./components/CalendarCompImDt";

const ScheduleAccessStatus = async () => {
    const param = { view_all: 1, is_active: 1 };
    const result = await GET_ACAD_MSTONE_BY_ACAD(param);

    if (result.data && Object.values(result.data).length > 0) {
        const w_status = addStatusCallMonth(result.data);
        const table_data = filterAndDeduplicateID(w_status);
        const month_data = ConvertSchdItem(table_data);
        return {table_data, month_data}
    }else{
        return result;
    }
}

const filterAndDeduplicateID = (data) => {
    const filteredData = data.filter(item => item.status_id !== 1);

    const uniqueData = filteredData.reduce((acc, current) => {
        const key = `${current.mstone_type_id}-${current.atp_id}`;
        const existingItem = acc[key];

        if (!existingItem || current.id > existingItem.id) {
            acc[key] = current;
        }

        return acc;
    }, {});

    return Object.values(uniqueData);
};

export {ScheduleAccessStatus}