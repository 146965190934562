import React, { useState } from "react";
import {
  AccessInvalid,
  RequiredMsg,
} from "../../../../_layouts/_core/ErrorMessage";
import { AlertNotif } from "../../../../_layouts/components/alerts/AlertsUI";
import { useOutletContext } from "react-router-dom";
import { SGUCard } from "../../../../_layouts/components/cards/CardUI";
import FilterUI from "../../components/filters/FilterUI";
import {
  CourseLookUp,
  LectLookUp,
  SectionID,
} from "../../components/forms/InputText";
import PackageJSON from "../../../../../package.json";
import { AcadTerm, CourseOwner, EmployeeSelect } from "../../components/forms/SelectBox";
import { RemoveEmptyValue } from "../../../../_layouts/_helpers/Global";
import {
  MessageError,
  openModal,
} from "../../../../_layouts/components/modals/Modals";
import { LoadingSkeleton } from "../../../../_layouts/components/loadbars/LoadingBars";
import TableData from "./TableData";
import { GET_RPT_TEACHINGLOAD } from "../../apis/node-target-data/AcademicApi";

export function ClassroomScore() {
  const context = useOutletContext();
  const widget_36 = context.widgets.find(
    (item) => item.widget_id === 36 && item.is_enabled === 1
  );

  const [term_id, setTerm_id] = useState(PackageJSON.system_param.ActiveAcad);
  const [emp_id, setEmp_id] = useState(0);
  const [cost_center, setCost_center] = useState(0);
  const [section_id, setSection_id] = useState("");
  const [course, setCourse] = useState("");
  const [course_id, setCourseID] = useState(0);
  const [offset, setOffset] = useState(1);
  const [limit, setLimit] = useState(10);

  const postParam = {
    cost_center,
    emp_id,
    section_id,
    term_id,
    course_id,
  };

  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });
  const ReloadData = async (postParam) => {
    setResults({ loading: true, data: [], message: "" });

    const param = RemoveEmptyValue(postParam);
    const result = await GET_RPT_TEACHINGLOAD(param);
    setResults(result);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const SubmitFilter = (e) => {
    e.preventDefault();
    if (postParam.term_id) {
      let param = { ...postParam };
      param.offset = 1;
      param.limit = 10;
      ReloadData(param);
    } else {
      openModal({ message: <MessageError message={RequiredMsg} /> });
    }
  };

  const HandlerPage = (type) => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    let current_offset = offset;
    if (type == 1) {
      current_offset = offset - 1;
    } else {
      current_offset = offset + 1;
    }
    setOffset(current_offset);
    const param = { ...postParam };
    param.offset = current_offset;
    param.limit = 10;
    ReloadData(param);
  };
  
  return widget_36 ? (
    <div className="row">
      <div className="col-lg-3 mb-5">
        <FilterUI title="Classroom Score" onSubmit={SubmitFilter} isload={results.loading}>
          <AcadTerm data={term_id} setData={setTerm_id} />
          <CourseOwner data={cost_center} setData={setCost_center} />
          <SectionID data={section_id} setData={setSection_id} />
          <EmployeeSelect data={emp_id} setData={setEmp_id} title={"Lecturer"} />
          <CourseLookUp
            course={course}
            setCourse={setCourse}
            setCourseID={setCourseID}
          />
        </FilterUI>
      </div>
      <div className="col-lg-9 mb-5">
        <SGUCard>
          <SGUCard.Header>
            <span className="card-label fw-bolder text-dark">
              Browse Classroom Scores
            </span>
            <span className="text-muted mt-1 fw-bold fs-7">
              List of score by filter
            </span>
          </SGUCard.Header>
          <SGUCard.Body>
            {results.loading ? (
              <LoadingSkeleton />
            ) : results.message ? (
              <AlertNotif messages={results.message} />
            ) : (
              <div className="d-flex flex-column justify-content-between h-100">
                <div className="w-100">
                  <TableData results={results} ReloadData={SubmitFilter} />
                </div>
                <div className="w-100">
                  {Object.values(results.data).length > 0 ? (
                    <div className="footer">
                      <ul className="pagination">
                        <li
                          className={
                            "page-item " +
                            (offset !== 1 ? "cursor-pointer" : "disabled")
                          }
                        >
                          <span
                            className="page-link"
                            onClick={() => HandlerPage(1)}
                          >
                            <span>‹ Prev</span>
                          </span>
                        </li>
                        <li className="page-item active">
                          <span className="page-link text-white">
                            Page {offset}
                          </span>
                        </li>
                        <li
                          className={
                            "page-item " +
                            (Object.values(results.data).length === limit
                              ? "cursor-pointer"
                              : "disabled")
                          }
                        >
                          <span
                            className="page-link"
                            onClick={() => HandlerPage(2)}
                          >
                            <span>Next ›</span>
                          </span>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </SGUCard.Body>
        </SGUCard>
      </div>
    </div>
  ) : (
    <AlertNotif messages={AccessInvalid} />
  );
}
