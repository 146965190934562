import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import { SGUCard } from '../../../../../_layouts/components/cards/CardUI'
import { GET_ACTIVE_ACAD } from '../../../apis/node-target-data/AcademicApi';
import { LoadingSkeleton } from '../../../../../_layouts/components/loadbars/LoadingBars';
import { AlertNotif } from '../../../../../_layouts/components/alerts/AlertsUI';

export function WAcadActive({type}) {
    const [acad, setAcad] = useState({ loading: false, message: "", data: [] });
    const ReloadData = async () => {
        setAcad({ loading: true, message: "", data: [] })
        const results = await GET_ACTIVE_ACAD()

        if (results.data && Object.values(results.data).length > 0) {
            const data = results.data.filter(item => item.atp_id_term !== 116);
            const map_by_type = data.filter(item => item.atp_group_type.id.includes(type));
            setAcad({ loading: false, message: "", data: map_by_type });
        } else {
            setAcad(results)
        }
    }
    useEffect(() => {
        ReloadData()
    }, [])
    return (
        <SGUCard>
            <SGUCard.Header>
                <span className="card-label fw-bold text-gray-800 text-capitalize">Active Calendar <span className="text-primary">{type}</span></span>
                <span className="text-gray-500 mt-1 fw-semibold fs-6">{Object.values(acad.data).length} active calendar</span>
            </SGUCard.Header>
            <SGUCard.Body className={"pb-0"}>
                {acad.loading ? <LoadingSkeleton /> :
                    acad.message ? <AlertNotif messages={acad.message} color='danger' /> :
                        Object.values(acad.data).length > 0 && (
                            <Carousel>
                                {acad.data.map((v, index) => (
                                    <Carousel.Item key={index}>
                                        <div className="m-0">
                                            <div className="d-flex align-items-sm-center mb-5">
                                                <div className="symbol symbol-35px me-4">
                                                    <span className="symbol-label bg-primary">
                                                        <i className="ki-outline ki-teacher text-inverse-primary fs-1"></i>
                                                    </span>
                                                </div>
                                                <div className="d-flex align-items-center flex-row-fluid flex-wrap">
                                                    <div className="flex-grow-1 me-2">
                                                        <span className="text-gray-500 fs-6 fw-semibold">
                                                            {v.atp_group_type && (v.atp_group_type.name || "")}
                                                        </span>
                                                        <span className="text-gray-800 fw-bold d-block fs-6">
                                                            {v.atp_term && (v.atp_term.name || "")}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="timeline">
                                                <div className="timeline-item align-items-center mb-7">
                                                    <div className="timeline-line mt-1 mb-n6 mb-sm-n7"></div>
                                                    <div className="timeline-icon">
                                                        <i className="ki-outline ki-cd fs-2 text-primary"></i>
                                                    </div>
                                                    <div className="timeline-content m-0">
                                                        <span className="fs-6 text-gray-500 fw-semibold d-block">Start From</span>
                                                        <span className="fs-6 fw-bold text-gray-800">{v.atp_term && (v.atp_term.start_dt_formatted || "")}</span>
                                                    </div>
                                                </div>
                                                <div className="timeline-item align-items-center mb-7">
                                                    <div className="timeline-icon">
                                                        <i className="ki-outline ki-cd fs-2 text-danger"></i>
                                                    </div>
                                                    <div className="timeline-content m-0">
                                                        <span className="fs-6 text-gray-500 fw-semibold d-block">End From</span>
                                                        <span className="fs-6 fw-bold text-gray-800">{v.atp_term && (v.atp_term.end_dt_formatted || "")}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        )}
            </SGUCard.Body>
        </SGUCard>
    )
}
