import React, { useEffect, useState } from "react";
import { GET_EMP_BIO_BY_IDENID } from "../apis/node-target-data/HRAPI";
import { GET_USER_GROUP } from "../apis/module/BackendRequest";
import { useProvider } from "../../../_layouts/UMSLayoutProvider";
import { LoadingSkeleton } from "../../../_layouts/components/loadbars/LoadingBars";
import { AlertRetry } from "../../../_layouts/components/alerts/AlertsUI";
import CardProfile from "../employees/CardProfile";
import { Content } from "../../../_metronic/layout/components/content";
import { LinkBtn } from "../../../_layouts/components/buttons/LinkUI";
import { KTIcon } from "../../../_metronic/helpers";

export default function MyProfiles() {
  const { account } = useProvider();
  const [results, setResults] = useState({
    loading: false,
    data: [],
    message: "",
  });

  const ReloadData = async () => {
    setResults({ loading: true, data: [], message: "" });
    const param = { employeeid: account.identity.entityid };
    const result = await GET_EMP_BIO_BY_IDENID(param);

    if (result.data && Object.values(result.data).length > 0) {
      const param = {
        employeeid: result.data.employee_id,
        email: result.data.sgumail,
      };
      const result_emp = await GET_USER_GROUP(param);

      if (result_emp.data && Object.values(result_emp.data).length > 0) {
        let final_result = { ...result.data };
        final_result.group_name = result_emp.data.group_name;
        setResults({ loading: false, data: final_result, message: "" });
      } else {
        setResults(result);
      }
    } else {
      setResults(result);
    }
  };

  useEffect(() => {
    ReloadData();
  }, []);

  return (
    <Content>
      <div id="my-profile" className="row">
        {results.loading ? (
          <LoadingSkeleton />
        ) : results.message ? (
          <AlertRetry messages={results.message} Reload={ReloadData} />
        ) : (
          Object.values(results.data).length > 0 && (
            <>
              <div className="col-lg-4 m-auto">
                <CardProfile profile={results.data} />
                <div className="mt-8 d-flex justify-content-between">
                  <a
                    href="https://wa.me/message/NNSYT7PGEZM5E1"
                    className="btn btn-primary btn-lg"
                  >
                    <KTIcon iconName="whatsapp" className="fs-2" />
                    Change Password
                  </a>
                  <LinkBtn to={"logout"} className="btn btn-lg bg-white">
                    <KTIcon
                      iconName="exit-right"
                      className="fs-2 text-danger"
                    />
                    Logout
                  </LinkBtn>
                </div>
              </div>

              {/* <div className="col-lg-4">
                <TDocumentFileUpload />
              </div> */}
            </>
          )
        )}
      </div>
    </Content>
  );
}
